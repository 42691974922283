import * as React from 'react';
import equal from 'fast-deep-equal/react';
import ContainerPityTimer from '~/components/ContainerPityTimer/ContainerPityTimer';
import { useAppSelector } from '~/Store';

interface IInventoryCounterContainer {
    identifier: number;
    autoDescriptionSettings: IAutoDescriptionSettings;
    title: string;
    className?: string;
    fallback?: React.ReactNode;
}

const PityTimerContainer = ({ identifier, autoDescriptionSettings, title, className, fallback = null }: IInventoryCounterContainer) => {
    const lootboxes = useAppSelector((state) => state.lootbox.lootboxes, equal);

    if (!identifier) {
        return fallback;
    }

    const lootbox = lootboxes[identifier];
    if (!lootbox || (!lootbox.savePoint && !lootbox.savePointForValuableGroup?.savePoint)) {
        return fallback;
    }

    const isDroprateHidden = autoDescriptionSettings.lootboxSettings?.[lootbox.id]?.isDroprateHidden;
    if (isDroprateHidden) {
        return fallback;
    }

    return (
        <div className={className}>
            <ContainerPityTimer containerId={identifier} lootbox={lootbox} bundleName={title} />
        </div>
    );
};

export default PityTimerContainer;
