import equal from 'fast-deep-equal/react';
import { useAppSelector } from '~/Store';
import { getSoundKeyForCurrentPage, isEnabledSounds } from '~/utils/sounds/settings';

export function useAvailableSound(category: ICategory, bundle: IBundle) {
    const soundStatus = useAppSelector((state) => state.app.soundStatus, equal);
    const soundKey = getSoundKeyForCurrentPage(category, bundle);
    if (!soundKey) {
        return false;
    }
    return isEnabledSounds(soundKey) || soundStatus[soundKey];
}
