export enum CategoryType {
    PREMIUM = 'premium',
    DOCKYARD = 'dockyard',
    FEATURED = 'featured',
    AUCTION = 'auction',
    SEA_BATTLE = 'seabattle',
    TRADEIN = 'tradein',
    SALVAGE = 'salvage',
    ENROTH = 'enroth',
    FORECAST = 'forecast',
    PROGRESSION_REWARD = 'progression_reward',
}

export enum PRESETS {
    ALL = 'all',
    RECOMMENDED = 'recommended',
}

export enum SortNames {
    name = 'name',
    price = 'price',
    default = 'default',
}

export enum ISortMethods {
    asc = 'asc',
    desc = 'desc',
}

export enum FilterPosition {
    TOP = 'top',
    RIGHT = 'right',
}

export enum CategoryLabelAboutTypes {
    IFRAME = 'iframe',
    POPUP = 'popup',
    LINK = 'link',
}

declare global {
    type FILTER_INFO_NAME =
        | 'class'
        | 'tier'
        | 'nation'
        | 'currency'
        | 'ship_type'
        | 'crew_category'
        | 'crew_type'
        | 'doll_type'
        | 'discount_type'
        | 'camouflage_type'
        | 'signal_type'
        | 'boost_type'
        | 'allowed_bundles';

    type CATEGORY_PARAGONS = 'paragons';
    type CATEGORY_PREM_SHIPS = 'premium';
    type CATEGORY_PREM_ACCOUNT = 'premium_account';
    type CATEGORY_MENU = 'menu';
    type CATEGORY_COUPONS = 'coupons';
    type CATEGORY_WALLET = 'wallet';
    type CATEGORY_PURCHASE_POPUP = 'purchase-popup';
    type CATEGORY_SHIPS = 'ships';
    type CATEGORY_DOCKYARD = 'dockyard';
    type CATEGORY_AUCTION = 'auction';
    type CATEGORY_FEATURED = 'featured';
    type CATEGORY_EXTERIOR = 'exterior';
    type CATEGORY_EVENTS = 'event';
    type CATEGORY_EVENTS_3 = 'event_3';
    type NY_CONTAINERS = 'new_year_containers';
    type NY_CERTIFICATES = 'new_year_certificates';
    type NY_CERTIFICATES_ONLY = 'new_year_certificates_only';

    type ICategoryList =
        | CATEGORY_EVENTS
        | CATEGORY_EVENTS_3
        | CATEGORY_FEATURED
        | CATEGORY_PREM_ACCOUNT
        | CATEGORY_PREM_SHIPS
        | CATEGORY_SHIPS
        | CATEGORY_MENU
        | CATEGORY_COUPONS
        | CATEGORY_WALLET
        | CATEGORY_PURCHASE_POPUP
        | CATEGORY_DOCKYARD
        | CATEGORY_PARAGONS
        | CATEGORY_AUCTION
        | CATEGORY_EXTERIOR
        | NY_CONTAINERS
        | NY_CERTIFICATES
        | string;

    type ICategories = Partial<Record<ICategoryList, ICategory>>;

    type ICategoriesAnimationStatuses = Partial<Record<ICategoryList, boolean>>;

    interface IPreviewCategorySettings {
        bundlesList: PreviewCategoryBundlesList[];
    }

    type PreviewCategoryBundlesList = {
        id: string;
        image: Nullable<string>;
        width: Nullable<string>;
    };

    type ICategoryPresetRules = Partial<Record<FILTER_INFO_NAME, string[] | number[]>>;

    type IBundleCategory = Record<string, number[]>;

    interface ICurrentPage {
        title?: string;
        name?: ICategoryList;
        isBundlePage?: boolean;
        bundleId?: string | number;
        lotId?: string;
        currencies?: string[];
        bgGallerySlideIndex?: number;
    }

    interface ICategoryPresets {
        name: string;
        title: string;
        description: string;
        bundlesPreviewLimit: number;
        type: PRESETS;
        bundles: number[];
        rules?: ICategoryPresetRules;
        excludeBundles?: number[];
    }

    interface ICategoryFilters {
        settings: FILTER_INFO_NAME[];
        presets: ICategoryPresets[];
    }

    interface CategoryLabel extends IDisplayRestrictedOptions {
        title: Nullable<string>;
        type: CategoryLabelAboutTypes;
        value: string;
        tooltipText: Nullable<string>;
    }

    interface CategoryPromoTimer {
        title: Nullable<string>;
        description: Nullable<string>;
        timerTitle?: string;
        timerActiveTill: string;
        timerTooltipDescription?: Nullable<string>;
        showBundles: boolean;
    }

    interface IProgressionRewardSettings {
        bundles: number[];
        currency: string;
    }

    interface ICategory extends IDisplayRestrictedOptions {
        type: CategoryType;
        name: string;
        externalCode: Nullable<string>;
        title: string;
        tabTitle: Nullable<string>;
        description: string;
        icon: string;
        parentIconClassName: string;
        background: string;
        backgroundColor: string;
        audioTrack: IAudioTrack;
        videoBackground: IVideo;
        entryVideo: IVideo;
        denyReorder: boolean;
        activeTill: string;
        showCountdown: boolean;
        bundles: number[];
        subCategories: string[];
        theme: string;
        labelNewActiveTill: string;
        filters: ICategoryFilters;
        sortSettings: SortNames[];
        parentCategory: string;
        parentCategoryData: Partial<ICategory>;
        previewCategorySettings: IPreviewCategorySettings;
        labelNewAllowed?: boolean;
        label: Nullable<CategoryLabel>;
        isContainBundlesForReal: Nullable<boolean>;
        promoTimer: Nullable<CategoryPromoTimer>;
        isHidden?: boolean;
        url?: string;
        useSmallTimer: Nullable<boolean>;
        progressionRewardSettings: Nullable<IProgressionRewardSettings>;
    }

    type ISortCategories = Partial<
        Record<
            ICategoryList,
            {
                name: SortNames;
                method: ISortMethods;
            }
        >
    >;
}

export default global;
