import { armoryState } from '~/utils/settings';
import store from '~/Store';
import { getAccountInfo } from '~/api/account';
import { logError, logInfo } from '~/utils/logging';
import AuctionStorage from '~/core/AuctionStorage';
import { prepareCouponsFromState } from '~/utils/coupons';
import Account from '~/account/Account';
import equal from 'fast-deep-equal/es6';
import { prepareBalanceFromState } from '~/utils/balance';
import TradeInPreloader from './core/AppInit/TradeInPreloader';
import { syncContentState } from './api/content';
import { accountActions } from './Store/accountSlice';
import { appActions } from './Store/appSlice';
import { arrayToObjectByKey } from './utils/utils';

const updateAccountBalanceEvery = 10000;
const skipSyncMaxCount = 3;

class IdleTracker {
    private _isActive = true;

    start() {
        if (!('visibilityState' in document)) {
            console.log('[sync]Visibility api is not supported, skip');
            return;
        }
        document.addEventListener('visibilitychange', this._onVisibilityChange.bind(this), false);
    }

    stop() {
        document.removeEventListener('visibilitychange', this._onVisibilityChange.bind(this), false);
    }

    private _onVisibilityChange() {
        this._isActive = document.visibilityState !== 'hidden';
    }

    isUserActive() {
        return this._isActive;
    }
}

export const idleTracker = new IdleTracker();

export const loadingLogger = () => {
    const period = 5000;
    const maxCount = 15;
    let currentCount = 0;

    (function run() {
        const appState = store.getState().app;
        if (appState.isFinishedRequest) {
            logInfo('Armory loaded and working', currentCount);
        } else {
            logInfo('Armory not loaded', currentCount);
        }
        setTimeout(async () => {
            if (currentCount < maxCount) {
                currentCount++;
                run();
            }
        }, period);
    })();
};

export const syncAccountInfo = async () => {
    const data = await getAccountInfo();

    if (!data) {
        return;
    }

    const account = store.getState().account;

    if (account.ts > data.ts) {
        return;
    }

    const options: IAccountUpdateData = {
        bundlesInTransaction: data.bundlesInTransaction,
        balance: arrayToObjectByKey(prepareBalanceFromState(data.balance), 'currency'),
        deniedBundlesByUniqueItems: data.deniedBundlesByUniqueItems,
        purchasedLimitedBundles: data.purchasedLimitedBundles,
        usedCoupons: data.usedCoupons,
        selectedRandomBundles: data.selectedRandomBundles,
        randomBundlesHistory: data.randomBundlesHistory,
        openedRandomBundles: data.openedRandomBundles,
        restrictedLootboxesPurchaseCount: data.restrictedLootboxesPurchaseCount,
        ts: data.ts,
    };

    const coupons = prepareCouponsFromState(account.coupons || [], data.usedCoupons);

    const isEqualPurchasedLimitedBundles = equal(options.purchasedLimitedBundles, account.purchasedLimitedBundles);
    const isEqualCoupons = equal(account.coupons, coupons);
    const isEqualUsedCoupons = equal(options.usedCoupons, account.usedCoupons);
    const deniedBundlesByUniqueItems = [...(account.deniedBundlesByUniqueItems || [])];
    const isEqualDeniedBundlesByUniqueItems = equal(options.deniedBundlesByUniqueItems?.sort(), deniedBundlesByUniqueItems.sort());

    store.dispatch(accountActions.updateAccountState({ data: options, coupons }));

    if (!isEqualCoupons || !isEqualUsedCoupons || !isEqualDeniedBundlesByUniqueItems || !isEqualPurchasedLimitedBundles) {
        store.dispatch(
            appActions.updateBundles({
                coupons,
                deniedBundlesByUniqueItems: options.deniedBundlesByUniqueItems,
                purchasedLimitedBundles: options.purchasedLimitedBundles,
            }),
        );
    }
};

const syncCouponsContent = async () => {
    const { coupons } = await syncContentState();
    const { categories } = store.getState().app;
    const account = store.getState().account;
    if (coupons?.length > 0) {
        store.dispatch(accountActions.setCoupons({ categories, coupons }));
        if (account?.coupons?.length !== coupons.length) {
            store.dispatch(
                appActions.updateBundles({
                    coupons: prepareCouponsFromState(coupons || [], account.usedCoupons),
                    deniedBundlesByUniqueItems: account.deniedBundlesByUniqueItems,
                    purchasedLimitedBundles: account.purchasedLimitedBundles,
                }),
            );
        }
    }
};

const sync = () => {
    if (!armoryState.account) {
        return;
    }

    let skippedSyncsCount = 0;

    (function run() {
        setTimeout(async () => {
            const { app, account } = store.getState();

            if (!app.isFinishedRequest) {
                return run();
            }

            if (idleTracker.isUserActive() && (!account.purchasingBundleId || skippedSyncsCount >= skipSyncMaxCount)) {
                skippedSyncsCount = 0;

                try {
                    await syncAccountInfo();
                    await new TradeInPreloader().load();
                    await syncCouponsContent();
                    if (AuctionStorage.isAuctionCategoryEnabled) {
                        await AuctionStorage.preloadedState();
                    }
                    Account.loadAccountInventoryFromVrtx();
                } catch (e) {
                    logError('Account sync error', e);
                }
            } else {
                skippedSyncsCount++;
            }
            run();
        }, updateAccountBalanceEvery);
    })();
};

export default sync;
