import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { appActions } from '~/Store/appSlice';
import styles from '~/components/TopPanel/TopPanel.scss';
import Volume from '~/components/Volume/Volume';
import { changeVolumeSoundByKey, getDefaultValueBySoundKey, getSoundKeyForCurrentPage } from '~/utils/sounds/settings';
import { isMobileOrTabletWindow } from '~/utils/utils';

interface ISoundControl {
    category: ICategory;
    bundle: IBundle;
    isStartedVideo: boolean;
    isTrusted: boolean;
    className?: string;
}

const SoundControl = ({ category, bundle, isStartedVideo, isTrusted, className }: ISoundControl) => {
    const dispatch = useDispatch();
    const soundKey = getSoundKeyForCurrentPage(category, bundle);
    const defaultValue = getDefaultValueBySoundKey(soundKey, isTrusted);

    if (isMobileOrTabletWindow || !soundKey) {
        return null;
    }

    return (
        <div className={classNames(styles.iconWrapper, { [styles.disabled]: isStartedVideo })}>
            <Volume
                key={`volume_${soundKey}_${isTrusted}`}
                onChange={(value) => {
                    changeVolumeSoundByKey(soundKey, value);
                    dispatch(appActions.updateSoundStatus({ soundKey, status: value }));
                }}
                defaultValue={defaultValue}
                className={className}
            />
        </div>
    );
};

export default SoundControl;
