import classNames from 'classnames';
import equal from 'fast-deep-equal/react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { openCategoryByName } from '~/utils/category';
import { t } from '~/utils/localization';
import { settings } from '~/utils/settings';
import styles from './EventumCnRedirectButton.scss';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface IEventumCnRedirectButton {
    withIcon?: boolean;
    className?: string;
}

const stateSelector = (state: RootState) => {
    return {
        categories: state.app.categories,
        popupActive: state.app.popupActive,
    };
};

const EventumCnRedirectButton = ({ withIcon, className }: IEventumCnRedirectButton) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);

    const redirect = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (!!state.popupActive) {
            dispatch(appActions.changeVisibilityPopup({ name: null }));
        }
        openCategoryByName(settings.eventumCNCategoryName);
    };

    if (!settings.eventumCNCategoryName || !state.categories?.[settings.eventumCNCategoryName]) {
        return null;
    }

    const classes = classNames(styles.wrapper, className, {
        [styles.withIcon]: withIcon,
    });

    return (
        <div className={classes} onClick={redirect}>
            {t('购买补给兑换券')}
        </div>
    );
};

export default EventumCnRedirectButton;
