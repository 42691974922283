import classNames from 'classnames';
import Currency from '../Currency/Currency';
import styles from './ProgressigonRewardsBonus.scss';
import { Interpolate } from '@wg/wows-react-uikit';
import { t } from '~/utils/localization';

interface IProgressigonRewardsBonus {
    value: number;
    currency: string;
    className?: string;
}

const ProgressigonRewardsBonus = ({ currency, value, className }: IProgressigonRewardsBonus) => {
    return (
        <div className={classNames(styles.wrapper, className)}>
            <Interpolate str={t('+%(component)s')} component={<Currency currency={currency} amount={value} withoutAnimation={true} showDescriptionTooltip />} />
        </div>
    );
};

export default ProgressigonRewardsBonus;
