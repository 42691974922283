import equal from 'fast-deep-equal/react';
import styles from './ContainerPityTimer.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import { interpolate, t } from '~/utils/localization';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { getTextAboutSavePointForTooltip } from '~/Layouts/Lootboxes/utils/texts';
import { useDispatch } from 'react-redux';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import { POPUPS_NAME } from '~/components/PopupManager';
import Info, { InfoSize } from '~/components/Info/Info';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface IContainerPityTimer {
    containerId: number;
    bundleName: string;
    lootbox: ILootboxStateItem;
}

const stateSelector = (state: RootState) => {
    return {
        lootboxes: state.account.inventory?.lootboxes,
        lootboxes_without_dropped_rewards: state.account.inventory?.lootboxes_without_dropped_rewards,
    };
};

const ContainerPityTimer = ({ lootbox }: IContainerPityTimer) => {
    const state = useAppSelector(stateSelector, equal);
    const dispatch = useDispatch();
    const { savePoint, savePointForValuableGroup } = lootbox;
    const descriptionText = getTextAboutSavePointForTooltip(lootbox);
    const pityTimer = savePoint || savePointForValuableGroup?.savePoint;
    let amountToReward = pityTimer;

    const tooltipBody = [descriptionText];

    if (state.lootboxes && state.lootboxes_without_dropped_rewards) {
        const amountObtained = state.lootboxes[lootbox.id] || 0;
        const amountOpened = (state.lootboxes_without_dropped_rewards[lootbox.id] || 0) % pityTimer;
        amountToReward -= amountOpened;

        tooltipBody.push(
            interpolate(t('Открыто контейнеров: {amount}'), { amount: amountOpened.toString() }),
            interpolate(t('В наличии в Порту: {amount}'), { amount: amountObtained.toString() }),
            t('Информация о количестве может обновляться с задержкой'),
        );
    }

    const text = t('Гарантированное выпадение: {number}').replace('{number}', amountToReward.toString());

    return (
        <DivTooltip
            className={styles.wrapper}
            onClick={() => {
                if (!isMobileOrTabletDeviceByAgent()) {
                    return;
                }
                dispatch(
                    appActions.changeVisibilityPopup({
                        name: POPUPS_NAME.MOBILE_TOOLTIP_POPUP,
                        isVisible: true,
                        data: {
                            contents: tooltipBody,
                        },
                    }),
                );
            }}
            tooltipBody={<DefaultTooltip text={tooltipBody} />}
        >
            {text}
            <Info size={InfoSize.SMALL} className={styles.label} />
        </DivTooltip>
    );
};

export default ContainerPityTimer;
