import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { isPeriodicBundle } from '~/utils/bundles';
import { scrollTop } from '~/utils/scrollTop';
import { t } from '~/utils/localization';
import Cron from '~/utils/Cron';
import store, { RootState, useAppSelector } from '~/Store';
import RandomBundleView from '~/Layouts/RandomBundlePage/RandomBundleView';
import { isSingleBundleCategory } from '~/utils/category';
import useBackground from '~/hooks/useBackground';
import { getBundleInnerBackground } from '~/utils/background';
import { ErrorBoundary } from '@sentry/react';
import useEntryVideo from '~/hooks/useEntryVideo';
import { CATEGORIES } from '~/const';
import BundlePage from '~/Layouts/BundlePage/BundlePage';
import SerialBundlePage from '~/Layouts/SerialBundlePage/SerialBundlePage';
import History from '~/utils/history';
import { urlsMap } from '~/utils/menu';
import { isValidDate } from '~/utils/time';
import { BUNDLE_TYPES } from '~/types/bundle';
import { appActions } from '~/Store/appSlice';
import equal from 'fast-deep-equal/react';
import { accountActions } from '~/Store/accountSlice';

interface IItemCardContainerProps {
    params: {
        categoryName: string;
        id: number;
    };
}

const createScheduledTaskIfNeeded = (categoryName: string, _activeTill: string) => {
    const nowTime = Math.floor(Date.now() / 1000);
    const date = new Date(_activeTill).getTime();
    const activeTill = Math.floor(date / 1000);
    const diff = activeTill - nowTime;

    if (diff > 86400) {
        return;
    }

    const callback = () => store.dispatch(appActions.disableCategory(categoryName));

    Cron.newScheduledTask(`category_${categoryName}_active_till`, callback, date);
};

const stateSelector = (state: RootState) => {
    return {
        bundles: state.app.bundles,
        categories: state.app.categories,
        activeCouponId: state.account.activeCouponId,
    };
};

const ViewCardContainer = function (props: IItemCardContainerProps): any {
    const dispatch = useDispatch();
    const { bundles, categories, activeCouponId } = useAppSelector(stateSelector, equal);
    const categoryName = props.params.categoryName || CATEGORIES.FEATURED;
    const itemId = props.params.id;
    const currentBundle = bundles[itemId];
    let category = categories[categoryName];

    if (currentBundle.categories.length && !currentBundle.categories.includes(categoryName)) {
        category = categories[currentBundle.categories[0]];
    }

    useEffect(() => {
        scrollTop();

        dispatch(
            appActions.changeCurrentPage({
                name: (category?.name || CATEGORIES.FEATURED) as ICategoryList,
                title: category?.title || t('Рекомендованное: главная'),
                isBundlePage: !isSingleBundleCategory(category),
                bundleId: itemId,
            }),
        );

        return () => {
            if (activeCouponId) {
                dispatch(accountActions.resetCoupon());
            }
        };
    }, [category?.name, categoryName, itemId]);

    const innerBackground = getBundleInnerBackground(currentBundle, category?.name);

    useBackground(innerBackground, [category?.name, currentBundle?.id]);

    useEntryVideo(
        {
            videoUrlsMap: category?.entryVideo,
            isAllowedVideoForMobile: false,
            categoryName: category?.name,
            isViewAccountSpecific: true,
            videoName: category?.theme,
        },
        [category?.name],
    );

    useEffect(() => {
        if (category && category.activeTill) {
            createScheduledTaskIfNeeded(category?.name, category.activeTill);
        }
    }, []);

    if (category?.promoTimer && isValidDate(category?.promoTimer?.timerActiveTill) && !category.promoTimer.showBundles) {
        History.navigate(urlsMap.home, { replace: true });
        return;
    }

    if (currentBundle.type === BUNDLE_TYPES.random || isPeriodicBundle(currentBundle)) {
        return (
            <ErrorBoundary>
                <RandomBundleView bundle={currentBundle} key={itemId} />
            </ErrorBoundary>
        );
    }

    if (currentBundle.serialPurchase) {
        return (
            <ErrorBoundary>
                <SerialBundlePage bundle={currentBundle} />
            </ErrorBoundary>
        );
    }

    return (
        <ErrorBoundary>
            <BundlePage bundle={currentBundle} />
        </ErrorBoundary>
    );
};

export default ViewCardContainer;
