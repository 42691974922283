import * as React from 'react';
import Label from '~/components/Label/Label';

interface ILabelContainer {
    className?: string;
    text?: string;
    isVisible?: boolean;
}

const LabelContainer = (props: ILabelContainer) => {
    return (
        <div className={props.className}>
            <Label text={props.text} isVisible={props.isVisible} />
        </div>
    );
};

export default LabelContainer;
