import { get } from '~/utils/ajax';
import { settings } from '~/utils/settings';
import Account from '~/account/Account';
import { logError } from '~/utils/logging';

export const isDockyardEnabledForAccount = async () => {
    let isEnabled = false;

    const currentAccountId = Account.getAccount()?.id;
    let url = settings.urls.dockyardAccountCheck;

    if (currentAccountId && url) {
        url = url.replace('{accountId}', currentAccountId.toString());
        try {
            const result = await get(url, null, false, true, true, {
                credentials: 'include',
            });
            isEnabled = !!result?.data['show_category'];
        } catch (e) {
            logError('dockyard is unavailable', e);
        }
    }

    return isEnabled;
};
