import * as React from 'react';
import styles from './Popup.scss';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import useClickAway from '~/hooks/useClickAway';
import { ZoomInDiv } from '~/components/Transitions';
import classNames from 'classnames';
import useDisableMobileBodyScroll from '~/hooks/useDisableMobileBodyScroll';
import { playExchangeSound, playButtonDialogClickSound } from '~/api/WoWsClient';
import { IPopupSoundset } from '~/types/soundsets';
import { playButtonClickSound } from '~/api/WoWsClient';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';

interface IPopup {
    children: React.ReactChild | React.ReactChild[];
    renderEscButton?: boolean;
    onClose: (event?: React.MouseEvent | React.TouchEvent) => void;
    outsideClick?: boolean;
    className?: string;
    popupClassName?: string;
    closeIconClassName?: string;
    style?: React.CSSProperties;
    withoutZoomIn?: boolean;
    BeforePopupComponent?: React.ReactChild;
    AfterPopupComponent?: React.FunctionComponent;
    soundset?: IPopupSoundset;
}

const soundsetsMap: Record<IPopupSoundset, (() => void) | null> = {
    [IPopupSoundset.MUTED]: null,
    [IPopupSoundset.DEFAULT]: () => playButtonDialogClickSound(),
    [IPopupSoundset.EXCHANGE]: () => playExchangeSound(),
};

const playSoundset = (soundsetName: IPopupSoundset) => {
    soundsetsMap[soundsetName || IPopupSoundset.DEFAULT]?.();
};

export const Popup = (props: IPopup) => {
    const { isPopupActive } = React.useContext(PopupManagerContext);
    const popupRef = React.useRef<HTMLDivElement>();
    const ref = React.useRef<HTMLDivElement>();

    const close = (event?: React.MouseEvent | React.TouchEvent | any) => {
        props.onClose?.(event);
    };

    React.useEffect(() => {
        playSoundset(props.soundset);
    }, []);

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }

        window?.tooltipProvider?.hide?.();

        playButtonClickSound();
        close();
    }, [KEYS_CODE.ESC]);

    useDisableMobileBodyScroll();

    useClickAway(
        ref,
        () => {
            if (isPopupActive && props.outsideClick) {
                close();
            }
        },
        [isPopupActive],
    );

    const setPopupRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            popupRef.current = _ref;
        }
    };

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            ref.current = _ref;
        }
    };

    const Div = React.forwardRef(({ children, className }: { children: any; className?: string }, ref: React.RefObject<HTMLDivElement>) => {
        return (
            <div className={className} ref={ref}>
                {children}
            </div>
        );
    });

    const Wrapper = !props.withoutZoomIn ? ZoomInDiv : Div;

    return (
        <div className={classNames(styles.popup, props.popupClassName)} ref={setPopupRef} style={props.style}>
            {props.BeforePopupComponent}
            <Wrapper className={classNames(styles.content, props.className)} ref={setRef}>
                {props.renderEscButton && (
                    <div className={classNames(styles.close, props.closeIconClassName)}>
                        <ButtonEsc onClick={close} className={styles.closeIconButton} />
                    </div>
                )}
                {props.children}
            </Wrapper>
            {props.AfterPopupComponent && <props.AfterPopupComponent />}
        </div>
    );
};

interface IPopupHeader {
    title: React.ReactChild | string;
    className?: string;
}

export const PopupHeader = (props: IPopupHeader) => {
    return <div className={classNames(styles.header, props.className)}>{props.title}</div>;
};

interface IPopupFooter {
    children: React.ReactChild | React.ReactChild[];
    className?: string;
}

export const PopupFooter = (props: IPopupFooter) => {
    return <div className={classNames(styles.footer, props.className)}>{props.children}</div>;
};

interface IPopupBody {
    children: React.ReactChild | React.ReactChild[];
    className?: string;
}

export const PopupBody = (props: IPopupBody) => {
    return <div className={classNames(styles.body, props.className)}>{props.children}</div>;
};
