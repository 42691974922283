import * as React from 'react';
import styles from './WsmartDoubloonsPopup.scss';
import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { t } from '~/utils/localization';
import { openCategoryByName } from '~/utils/category';
import { detectPlatform, PLATFORMS } from '@wg/wows-commerce';
import FastGoldProduct from '~/components/WSMart/FastGoldProduct/FastGoldProduct';
import { arrayToObjectByKey } from '~/utils/utils';
import { closePort as closeClientPort } from '~/api/WoWsClient';
import { changeBackground } from '~/containers/PortContainer/PortContainer';
import { CATEGORIES, DWH_EVENTS } from '~/const';
import PurchaseBlock from '~/components/WSMart/PurchaseBlock/PurchaseBlock';
import LegalFooter from '~/components/WSMart/Footer/LegalFooter';
import PurchaseFooter from '~/components/WSMart/Footer/PurchaseFooter';
import classNames from 'classnames';
import { CheckboxWithLabel } from '@wg/wows-react-uikit';
import Account from '~/account/Account';
import Currency from '~/components/Currency/Currency';
import { assets } from '@wg/wows-entities/const';
import dwhExport from '~/api/dwhExport';
import { settings } from '~/utils/settings';
import { WoWsCommerceStorefrontNames } from '~/core/WowsCommerce/WoWsCommerceHelper';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { RootState, useAppSelector } from '~/Store';
import { HIDE_ALL_POPUPS, appActions } from '~/Store/appSlice';
import { accountActions } from '~/Store/accountSlice';
import { wsmartActions } from '~/Store/wsmartSlice';

interface IProps {
    data: {
        goldShortage?: number;
        groupName?: string;
    };
}

const stateSelector = (state: RootState) => {
    return {
        accountId: state.account.id,
        fastGoldProductWrappers: state.wsmart.fastGoldProducts,
        fastGoldDefaultProductsWrappers: state.wsmart.fastGoldDefaultProducts,
        wsmartProducts: state.wsmart.products,
        fastGold: state.wsmart.fastGold,
        WowsCommerce: state.wsmart.wowsCommerce,
        port: state.app.port,
        isAdult: state.account.isAdult,
        wallet: state.account?.balance,
        paymentMethod: state.wsmart.paymentMethod,
        currentProductId: state.wsmart.currentProductId,
        categories: state.app.categories,
    };
};

const WsmartDoubloonsPopup = (props: IProps) => {
    const state = useAppSelector(stateSelector, equal);
    const dispatch = useDispatch();
    const productsInitital = [...state.fastGoldProductWrappers] || [];
    const defaultProducts = [...state.fastGoldDefaultProductsWrappers] || [];
    const platform = detectPlatform();

    const isAvailableImmediateDeliveryValidation = settings.isImmediateDeliveryValidation[settings.realm] && platform === PLATFORMS.WEB;

    React.useEffect(() => {
        dispatch(appActions.blurView(true));
        state.WowsCommerce.checkPurchaseRestrictionByCountryMismatch();
        state.WowsCommerce.checkPurchaseRestrictionByUserCountryIsSet(true);
    }, []);

    const close = () => {
        dispatch(appActions.changeVisibilityPopup({ name: HIDE_ALL_POPUPS }));
    };

    const goToPremiumShop = () => {
        if (state.port?.isVisible) {
            changeBackground(false);
            closeClientPort();
            dispatch(appActions.changeVisibilityPort({ isVisible: false }));
        }
        close();
        dwhExport.send(DWH_EVENTS.FASTGOLD_GO_PREMIUM_SHOP);
        openCategoryByName(CATEGORIES.WSMART);
    };

    let products: WsmartGoldProductWrapper[];

    if (props?.data?.goldShortage) {
        products = productsInitital.filter((value) => value.totalGoldAmount >= props.data.goldShortage);

        if (products.length < 4) {
            products = productsInitital.slice(-4);
        }
    } else {
        products = defaultProducts;
    }

    products = products.splice(0, 4).reverse();

    const isProductsAvailable = products.length < 1;

    React.useEffect(() => {
        if (!isProductsAvailable) {
            const defaultSelected = products.reverse().find((product) => product.totalGoldAmount >= props?.data?.goldShortage) || products[products.length - 1];
            dispatch(wsmartActions.setWsmartPaymentMethod(undefined));
            state.WowsCommerce.handleChangeCurrentProduct(defaultSelected.product, WoWsCommerceStorefrontNames.FASTGOLD);
        }
    }, []);

    const changeUserAdultFlag = () => {
        Account.updateAccountSettings({ is_adult: !state.isAdult });
        dispatch(accountActions.updateUserAdultsFlag());
    };

    const Wallet = () => {
        return (
            <React.Fragment>
                <Currency amount={state.wallet?.[assets.GOLD].value} currency={assets.GOLD} className={styles.miniWalletCurrency} withoutAnimation />
            </React.Fragment>
        );
    };

    const style: React.CSSProperties = {
        overflowY: 'auto',
        alignItems: 'flex-start',
    };

    return (
        <Popup
            onClose={close}
            className={classNames(styles.popup, styles.popupWithFooter)}
            closeIconClassName={styles.closeIcon}
            outsideClick={false}
            renderEscButton={isProductsAvailable}
            style={style}
        >
            {isProductsAvailable ? (
                <div className={styles.storeUnavailable}>{t('Произошла ошибка. Повторите попытку позже.')}</div>
            ) : (
                <>
                    <div className={classNames(styles.popupContainer)}>
                        <div className={styles.popupHeader}>
                            <PopupHeader title={t('Покупка дублонов')} />
                            <div className={styles.miniWallet}>
                                <Wallet />
                            </div>
                        </div>
                        <PopupBody className={styles.content}>
                            <div className={styles.contentTitle}>
                                {t('Выберите желаемое количество дублонов')}
                                {state.categories?.[CATEGORIES.WSMART] && (
                                    <span className={classNames('external-link_gold', styles.contentTitleLink)} onClick={goToPremiumShop}>
                                        {t('Больше наборов')}
                                    </span>
                                )}
                            </div>
                            <div className={styles.products}>
                                {products.map((productWrapper) => {
                                    return <FastGoldProduct key={productWrapper.product.id} product={productWrapper.product} />;
                                })}
                            </div>
                            {platform === PLATFORMS.WEB && <PurchaseBlock />}
                            {isAvailableImmediateDeliveryValidation && (
                                <div
                                    className={styles.agreement}
                                    ref={(_ref) => {
                                        RefManager.setRef(RefManagerKeys.WsmartAgreement, _ref);
                                    }}
                                >
                                    <CheckboxWithLabel
                                        isChecked={state.isAdult}
                                        className={classNames({
                                            [styles.warn]: !state.isAdult,
                                        })}
                                        labelText={
                                            <span className={styles.ageRestrictionLabel}>
                                                {t(
                                                    'I hereby agree to immediate delivery of a purchased order. I will lose my right to withdraw this order since such delivery process has started except for the order to purchase Premium account.',
                                                )}
                                            </span>
                                        }
                                        onChange={changeUserAdultFlag}
                                    />
                                </div>
                            )}
                        </PopupBody>
                        <PopupFooter className={styles.footer}>
                            <PurchaseFooter isAvailableImmediateDeliveryValidation={isAvailableImmediateDeliveryValidation} />
                        </PopupFooter>
                    </div>
                    <LegalFooter />
                </>
            )}
        </Popup>
    );
};

export default WsmartDoubloonsPopup;
