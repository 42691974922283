import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AUCTION_STATUS } from '~/const';
import { finishedStatuses } from '~/utils/auction';

export interface IAuctionState {
    activeAuctions: IAuction[];
    account: IAuctionAccountState;
    activeBackgroundLotUrl4K?: string;
    activeBackgroundLotUrl?: string;
    activeBackgroundColorLot?: string;
    activeBids: Record<string, number>;
    minimalWonBids: IAuctionMinimalWonBids[];
    zoomLotBackground: boolean;
}

const initialState: Partial<IAuctionState> = {
    activeAuctions: [],
    activeBackgroundLotUrl4K: null,
    activeBackgroundLotUrl: null,
    activeBackgroundColorLot: null,
    account: {
        placedBids: [],
        activeBids: [],
        transactions: [],
        completedActions: [],
        uniqueLotsInInventory: [],
        isStaff: false,
    },
    activeBids: {},
    minimalWonBids: [],
    zoomLotBackground: false,
};

export const auctionSlice = createSlice({
    name: 'auction',
    initialState,
    reducers: {
        startAuction(state, action: PayloadAction<string>) {
            state.activeAuctions = state.activeAuctions.map((auction) => {
                if (auction.id === action.payload) {
                    auction.isNeedToShowPromoPage = false;
                }
                return auction;
            });
        },

        updateAuctionState(state, action: PayloadAction<{ auctions: IAuction[]; account: IAuctionAccountState; minimalWonBids: IAuctionMinimalWonBids[] }>) {
            state.activeAuctions = action.payload.auctions;
            state.account = action.payload.account
                ? {
                      ...action.payload.account,
                      transactions: state.account?.transactions || [],
                  }
                : action.payload.account;
            state.minimalWonBids = action.payload.minimalWonBids;
        },

        updateAuctionAccountState(state, action: PayloadAction<IAuctionAccountState>) {
            state.account = action.payload
                ? {
                      ...action.payload,
                      transactions: state.account?.transactions || [],
                  }
                : action.payload;
        },

        addLotInTransaction(state, action: PayloadAction<string>) {
            const transactions: string[] = [...(state.account?.transactions || [])];
            if (Array.isArray(transactions) && !transactions.includes(action.payload)) {
                transactions.push(action.payload);
            }

            state.account = {
                ...state.account,
                transactions,
            };
        },

        removeLotFromTransaction(state, action: PayloadAction<string>) {
            state.account = {
                ...state.account,
                transactions: [...(state.account?.transactions || [])].filter((id) => id !== action.payload),
            };
        },

        changeLotBackground(state, action: PayloadAction<{ backgroundUrl: string; backgroundColor?: string; backgroundUrl4K?: string }>) {
            state.activeBackgroundLotUrl4K = action.payload.backgroundUrl4K;
            state.activeBackgroundLotUrl = action.payload.backgroundUrl;
            state.activeBackgroundColorLot = action.payload.backgroundColor;
        },

        setBidLot(state, action: PayloadAction<{ lotId: string; bid: number }>) {
            const activeBids = { ...state.activeBids };
            if (action.payload.bid) {
                activeBids[action.payload.lotId] = action.payload.bid;
            } else {
                delete activeBids[action.payload.lotId];
            }
            state.activeBids = activeBids;
        },

        finishAuction(state, action: PayloadAction<string>) {
            state.activeAuctions = state.activeAuctions.map((auction) => {
                if (auction.id === action.payload && !finishedStatuses.includes(auction.status)) {
                    auction.status = AUCTION_STATUS.CHOOSING_WINNERS as AUCTION_STATUSES;
                }
                return auction;
            });
        },

        zoomLotBackground(state, action: PayloadAction<boolean>) {
            state.zoomLotBackground = action.payload;
        },
    },
});

export const auctionActions = auctionSlice.actions;

export default auctionSlice.reducer;
