import wowsCommerce, { EVENTS, LOG_LEVELS } from '@wg/wows-commerce';
import { settings } from '~/utils/settings';
import Account from '~/account/Account';
import store from '~/Store';
import { Settings } from '@wg/wows-commerce/settings';
import { mergeBundles } from '~/utils/bundles';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import { CATEGORIES } from '~/const';
import { appActions } from '~/Store/appSlice';
import { wsmartActions } from '~/Store/wsmartSlice';

export enum WoWsCommerceStorefrontNames {
    METASHOP = 'metashop',
    FASTGOLD = 'fastgold',
}

export default class WoWsCommerceHelper {
    private wowsCommerce: wowsCommerce = null;

    private defaultWowsCommerceSettings: Settings = {
        productsValidationEnabled: false,
        logLevel: window.metashop?.isStaff ? LOG_LEVELS.DEBUG : LOG_LEVELS.ERROR,
        backendUrl: settings.urls.wsmartUrl,
        language: settings.languageCode,
        country: Account.getAccount()?.country || settings.country,
        showDevPanel: !!window.metashop?.isStaff,
        storefrontName: WoWsCommerceStorefrontNames.METASHOP,
        dwhExportUrl: settings.urls.dwhExport,
        userSupportUrl: settings.urls.supportUrl,
        userSetCountryUrl: settings.urls.setCountryUrl,
        fraudDetectionClosableByPlatform: true,
        fraudDetectionDialogEnabled: false,
        userAuthUrl: settings.urls.loginUrl,
        webPurchaseConfirmationIframeStyles: { 'background-color': 'transparent' },
        customPopupStyles: { 'background-color': 'transparent', 'backdrop-filter': 'blur(30px)' },
        analytics: {
            projectName: WoWsCommerceStorefrontNames.METASHOP,
            sendInterval: 3,
            maxBatchSize: 5,
        },
        realm: settings.realm,
        appVersion: settings.appVersion,
        staticVersion: settings.staticVersion,
        isMaintenanceMode: settings.isMaintenanceMode,
        accountCompletionEnable: settings.accountCompletionEnabled,
    };

    private mergeProducts(products: ProductEntity[]) {
        if (!products?.length) {
            return;
        }
        const mergedBundles = mergeBundles(store.getState().app.bundles, products);
        store.dispatch(appActions.updateBundleList(mergedBundles));
    }

    public async initialization() {
        if (!!this.defaultWowsCommerceSettings.backendUrl) {
            this.wowsCommerce = new wowsCommerce(this.defaultWowsCommerceSettings);

            this.wowsCommerce.subscribe(EVENTS.SHOP_UNAVAILABLE, ({ storefrontName }) => {
                if (storefrontName === WoWsCommerceStorefrontNames.METASHOP) {
                    store.dispatch(wsmartActions.shopUnavailable());
                    store.dispatch(appActions.hiddenMenuItem(CATEGORIES.WSMART));
                } else {
                    store.dispatch(wsmartActions.setFastGoldProducts([]));
                }
            });

            this.wowsCommerce.subscribe(EVENTS.OVERLAY_VISIBILITY, ({ visible }) => {
                store.dispatch(appActions.changeVisibilityPopup({ name: null, isVisible: visible }));
            });

            this.wowsCommerce.subscribe(EVENTS.ACCOUNT_UPDATE, ({ user }) => {
                if (user.billingAddress) {
                    store.dispatch(wsmartActions.setBillingAddressSettings(user.billingAddress));
                }
                store.dispatch(wsmartActions.setRegionalInfo(user.regional));
            });

            this.wowsCommerce.subscribe(EVENTS.CHANGE_CURRENT_PRODUCT, ({ currentProductId }) => {
                store.dispatch(wsmartActions.setWsmartCurrentProduct(currentProductId));
            });

            this.wowsCommerce.subscribe(EVENTS.SET_FETCHING, ({ visibility }) => {
                store.dispatch(appActions.setFetching(visibility));
            });

            this.wowsCommerce.subscribe(EVENTS.PRODUCTS_UPDATE, ({ products, storefrontName }) => {
                if (storefrontName === WoWsCommerceStorefrontNames.METASHOP) {
                    this.mergeProducts(products);
                } else if (storefrontName === settings.fastGoldStorefrontName) {
                    store.dispatch(wsmartActions.setFastGoldProducts(products));
                }
            });

            this.wowsCommerce.subscribe(EVENTS.PRODUCT_UPDATE, ({ product, storefrontName }) => {
                let products = store.getState().wsmart.products;

                if (storefrontName === settings.fastGoldStorefrontName) {
                    products = store.getState().wsmart.fastGoldProducts.map((fastFoldProduct) => fastFoldProduct.product);
                }

                products.forEach(function (part, index) {
                    if (this[index].id === product.id) {
                        this[index] = product;
                    }
                }, products);

                if (storefrontName === WoWsCommerceStorefrontNames.METASHOP) {
                    this.mergeProducts(products);
                } else if (storefrontName === settings.fastGoldStorefrontName) {
                    store.dispatch(wsmartActions.setFastGoldProducts(products));
                }
            });

            this.wowsCommerce.subscribe(EVENTS.ACTIVE_PAYMENT_METHOD, ({ paymentMethod }) => {
                store.dispatch(wsmartActions.setWsmartPaymentMethod(paymentMethod));
            });

            this.wowsCommerce.subscribe(EVENTS.TSV_MANAGEMENT_URL, ({ managementUrl }) => {
                store.dispatch(wsmartActions.setTsvManagementUrl(managementUrl));
            });

            this.wowsCommerce.subscribe(EVENTS.SET_TSV, ({ tsv }) => {
                store.dispatch(wsmartActions.setTsv(tsv));
            });

            store.dispatch(wsmartActions.setWowsCommerce(this.wowsCommerce));

            await Promise.all([this.wowsCommerce.products(), this.wowsCommerce.categories(), this.wowsCommerce.user()]).then(([products, , user]) => {
                this.mergeProducts(products);
                if (user.billingAddress) {
                    store.dispatch(wsmartActions.setBillingAddressSettings(user.billingAddress));
                }
                store.dispatch(wsmartActions.setRegionalInfo(user.regional));
            });

            if (settings.fastGoldStorefrontName) {
                const products = await this.wowsCommerce.products(settings.fastGoldStorefrontName);
                store.dispatch(wsmartActions.setFastGoldProducts(products));
            }
        }
    }
}
