import gql from 'graphql-tag';

export const features = gql`
    query Features($lang: String) {
        features(lang: $lang) {
            id
            mainFeatures {
                category
                description
                title
                key
            }
            categories {
                category {
                    icons {
                        default
                        localDefault
                    }
                    title
                }
                features {
                    category
                    description
                    title
                    key
                }
            }
        }
    }
`;
