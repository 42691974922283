import equal from 'fast-deep-equal/react';
import styles from './CategoryFilter.scss';
import classNames from 'classnames';
import { getFiltersInfo } from '~/settings/filtersMap';
import { getLevelFromRomanFormat, isMobileOrTabletWindow } from '~/utils/utils';
import { getCurrency } from '~/utils/currencies';
import Checkbox from '~/components/Checkbox/Checkbox';
import { FILTER_CURRENCY_NAME, FILTER_NATION_NAME, FILTER_SHIP_CLASS_NAME, FILTER_SHIP_TIER_NAME } from '~/const';
import { useDispatch } from 'react-redux';
import store, { RootState, useAppSelector } from '~/Store';
import CurrencyInputsRange from '~/components/CategoryFilter/CurrencyInputsRange';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { getAvailableFilters } from '~/utils/filters';
import { FilterPosition } from '~/types/category';
import { appActions, updateCategoryByFilters } from '~/Store/appSlice';

const stateSelector = (state: RootState) => {
    return {
        diapason: state.app.filtersDiapasonCurrency,
        bundlesInfo: state.app.defaultCategoryBundlesPricesInfo,
        pricesInfo: state.app.categoryBundlesPricesInfo,
        facet: state.app.facetState,
        facetCurrenciesState: state.app.facetCurrenciesState,
    };
};

interface ICurrencyRangeFilter {
    categoryName: ICategoryList;
    currencyName: string;
    isDisabled: boolean;
    isChecked: boolean;
}

const CurrencyInputRangeFilter = ({ categoryName, currencyName, isDisabled, isChecked }: ICurrencyRangeFilter) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const bundlesInfo = state.bundlesInfo[categoryName]?.[currencyName];
    const facetCurrenciesDiapason = (state.facetCurrenciesState?.[categoryName]?.[currencyName] || {}) as IRange;
    const { min, max } = state.pricesInfo[categoryName]?.[currencyName] || { min: null, max: null };

    if (bundlesInfo.defaultMin === bundlesInfo.defaultMax) {
        return null;
    }

    const currentMin = state.diapason?.[categoryName]?.[currencyName]?.min;
    const currentMax = state.diapason?.[categoryName]?.[currencyName]?.max;

    return (
        <CurrencyInputsRange
            key={`range_${facetCurrenciesDiapason.min}_${facetCurrenciesDiapason.max}_${currentMin}_${currentMax}_${min}_${max}`}
            isChecked={isChecked}
            isDisabled={isDisabled}
            max={facetCurrenciesDiapason.max || max}
            min={facetCurrenciesDiapason.min || min}
            currentMin={currentMin}
            currentMax={currentMax}
            onFilterApply={(value) => {
                dispatch(appActions.changeCurrenciesDiapasonFilter({ category: categoryName, currency: currencyName, max: value.max, min: value.min }));
                dispatch(appActions.changeFilter({ category: categoryName, name: FILTER_CURRENCY_NAME, value: currencyName }));
                dispatch(updateCategoryByFilters(categoryName, FILTER_CURRENCY_NAME, currencyName));
            }}
            onChangeComplete={(value) => {
                dispatch(appActions.changeCurrenciesDiapasonFilter({ category: categoryName, currency: currencyName, max: value.max, min: value.min }));
                dispatch(updateCategoryByFilters(categoryName, FILTER_CURRENCY_NAME, currencyName));
            }}
        />
    );
};

const prepareFiltersItems = (categoryName: ICategoryList, name: FILTER_INFO_NAME, callback: (name: string, value: string | IRange) => void, activeFiltersState: Record<string, string[]>) => {
    const app = store.getState().app;
    const availableItems = getAvailableFilters(categoryName, name);
    // @ts-ignore
    const disabledFilters = app.facetState?.[categoryName];
    const items = getFiltersInfo()[name]?.items;
    // @ts-ignore
    const filters = app.filters?.[categoryName];

    if (!items || !items.length) {
        return null;
    }

    const itemsList = items.reduce((state: any, item: any, index: number) => {
        if (!availableItems.includes(item.value)) {
            return state;
        }

        let content = name === FILTER_SHIP_TIER_NAME ? getLevelFromRomanFormat(item.value) : item.title;

        if (name === FILTER_NATION_NAME && app.nations[item.value]) {
            const icon = app.nations[item.value].icons.tiny || app.nations[item.value].icons.default;

            content = (
                <DivTooltip
                    className={styles.iconNation}
                    tooltipBody={<DefaultTooltip text={app.nations[item.value].title} />}
                    style={{
                        backgroundImage: `url(${icon})`,
                        cursor: 'pointer',
                    }}
                />
            );
        } else if (name === FILTER_SHIP_CLASS_NAME && app.vehicleTypes[item.value.toLowerCase()]) {
            const vehicleType = app.vehicleTypes[item.value.toLowerCase()];
            const icon = vehicleType.icons.default;

            content = (
                <DivTooltip
                    className={styles.iconClass}
                    tooltipBody={<DefaultTooltip text={vehicleType.title} />}
                    style={{
                        backgroundImage: `url(${icon})`,
                        cursor: 'pointer',
                    }}
                />
            );
        } else if (name === FILTER_CURRENCY_NAME) {
            const currencyConfig = getCurrency(item.value);
            const icon = currencyConfig?.icons?.default;

            content = (
                <DivTooltip
                    className={styles.currencyIcon}
                    tooltipBody={<DefaultTooltip text={currencyConfig.title} />}
                    style={{
                        backgroundImage: `url(${icon})`,
                        cursor: 'pointer',
                    }}
                />
            );
        }

        const isChecked = filters?.[name]?.includes(item.value);
        const isDisabled = disabledFilters?.[name]?.includes?.(item.value);

        state.push(
            <div key={item.value} className={classNames(styles.filterItem)}>
                <Checkbox
                    onChange={(isChecked) => {
                        callback(name, item.value);
                    }}
                    isDisabled={isDisabled}
                    isChecked={isChecked}
                    label={content}
                    className={styles.checkboxLabel}
                />
                {!isDisabled && name === FILTER_CURRENCY_NAME && <CurrencyInputRangeFilter currencyName={item.value} categoryName={categoryName} isDisabled={isDisabled} isChecked={isChecked} />}
            </div>,
        );

        return state;
    }, []);

    if (isMobileOrTabletWindow) {
        return <div className={styles.filterColumnBodyRow}>{itemsList}</div>;
    }

    const itemsColumns = [];
    let limit = 6;

    if (app.filterPosition === FilterPosition.RIGHT) {
        if (name === FILTER_SHIP_TIER_NAME) {
            limit = 3;
        } else if (name === FILTER_SHIP_CLASS_NAME) {
            limit = 2;
        } else if (name === FILTER_NATION_NAME) {
            limit = 4;
        }
    }

    for (let i = 0; i < itemsList.length; i += limit) {
        const array = itemsList.slice(i, i + limit);
        itemsColumns.push(
            <div className={styles.filterColumnBodyRow} key={i}>
                {array}
            </div>,
        );
    }

    return itemsColumns;
};

export default prepareFiltersItems;
