import * as React from 'react';
import styles from './RandomBundlesPurchaseSuccess.scss';
import { useDispatch } from 'react-redux';
import { Popup, PopupHeader, PopupFooter, PopupBody } from '~/components/Popup/Popup';
import { t, interpolate } from '~/utils/localization';
import Button, { ButtonVariant } from '~/components/Button/Button';
import equal from 'fast-deep-equal/react';
import { getSumFromArray } from '~/utils/number';
import { arrayToObjectByKey } from '~/utils/utils';
import RandomBundleItem from '~/components/RandomBundleItem/RandomBundleItem';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import classNames from 'classnames';
import { sort } from 'fast-sort';
import { IPopupSoundset } from '~/types/soundsets';
import { useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import { POPUPS_NAME } from '~/components/PopupManager';

interface IRandomBundlePurchase {
    data: {
        parentBundleId: number;
        bundleIds: PurchaseRandomBundleIds;
        closeCallback: () => void;
    };
}

const RandomBundlesPurchaseSuccess = ({ data }: IRandomBundlePurchase) => {
    const dispatch = useDispatch();
    const { bundleIds, parentBundleId } = data;
    const bundles = useAppSelector((state) => state.app.bundles, equal);
    const parentRandomBundle = bundles[parentBundleId];
    const randomBundleIds = Object.keys(bundleIds);
    const randomBundleMap = arrayToObjectByKey(parentRandomBundle.randomBundleChildren, 'id');
    const preparedBundleIds = sort(randomBundleIds).asc((id) => {
        return parentRandomBundle.randomBundleChildren.findIndex((item) => item.id === +id);
    });
    const refScrollableContent = React.useRef<HTMLDivElement>(null);

    const close = () => {
        dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.PURCHASE_RANDOM_BUNDLE_SUCCESS }));
    };

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            refScrollableContent.current = _ref;
        }
    };

    useMaskScrollEffect(refScrollableContent);

    React.useEffect(() => {
        dispatch(appActions.updateQuantity({ bundleId: parentRandomBundle.id }));
    }, []);

    return (
        <Popup
            onClose={close}
            outsideClick={false}
            renderEscButton={true}
            popupClassName={styles.popupWrapper}
            className={classNames(styles.popup, 'armory__auto--success-purchase-popup')}
            soundset={IPopupSoundset.EXCHANGE}
        >
            <PopupHeader title={t('Спасибо за покупку!')} />
            <PopupBody className={styles.body}>
                <div className={styles.scrollableContent} ref={setRef}>
                    <div className={styles.items}>
                        {preparedBundleIds.map((bundleId, index) => {
                            const delay = Math.random() + (Math.random() % 0.5);
                            const flipDelay = 2 + delay + (Math.random() % (index + 0.8));
                            return (
                                <RandomBundleItem
                                    key={`random_bundle_item_${bundleId}`}
                                    className={styles.item}
                                    bundle={randomBundleMap[bundleId] as IBundle}
                                    parentRandomBundle={parentRandomBundle}
                                    amount={bundleIds[+bundleId]}
                                    animationStyles={{
                                        transitionDelay: `${delay}s`,
                                        opacity: 1,
                                        transform: 'scale(1)',
                                    }}
                                    flipAnimationStyles={{
                                        transitionDelay: `${flipDelay}s`,
                                        transform: 'rotateY(180deg)',
                                    }}
                                    flipFrontCardStyles={{
                                        transitionDelay: `${flipDelay}s`,
                                        opacity: '0',
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className={styles.info}>
                    <div className={styles.infoTitle} dangerouslySetInnerHTML={{ __html: parentRandomBundle.title }} />
                    <div className={styles.itemSubTitle}>{interpolate(t('Количество наборов: {amount}'), { amount: getSumFromArray(Object.values(bundleIds)) })}</div>
                </div>
            </PopupBody>
            <PopupFooter>
                <Button variant={ButtonVariant.orange} label={t('Закрыть')} onClick={close} className={classNames('armory__auto--success_purchase_button')} />
            </PopupFooter>
        </Popup>
    );
};

export default RandomBundlesPurchaseSuccess;
