import { LocalStorageHelper } from '~/utils/storage';
import { LOCAL_STORAGE_WOWS_CLIENT_DEV } from '~/utils/keys';

export const devModeKeyMap: Record<string, string> = {
    disableWelcomePage: 'disable-welcome-page',
    disableTooltip: 'disable-tooltip',
    devMode: 'god-mode',
    mockAuction: 'mock-auction',
    disableVideo: 'disable-video',
};

const disableTooltip = () => {
    const style = document.createElement('style');
    style.innerHTML = `
        #wows-react-tooltip-body {
            display: none !important;
            pointer-events: none !important;
        }
    `;

    document.head.appendChild(style);
};

export const initDevModeIfNeeded = () => {
    const params = new URLSearchParams(document.location.search.substring(1));

    Object.keys(devModeKeyMap).forEach((key) => {
        const value = params.get(devModeKeyMap[key]);
        const isTrue = value === 'true';
        const isFalse = value === 'false';

        if (isFalse) {
            LocalStorageHelper.remove(devModeKeyMap[key]);
        } else {
            if (isTrue || LocalStorageHelper.get(devModeKeyMap[key])) {
                LocalStorageHelper.set(devModeKeyMap[key], 1);
                if (devModeKeyMap[key] === devModeKeyMap.disableTooltip) {
                    disableTooltip();
                }
            }
        }
    });
};

export const isDevWoWSClient = () => {
    return LocalStorageHelper.get(LOCAL_STORAGE_WOWS_CLIENT_DEV);
};
