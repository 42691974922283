import gql from 'graphql-tag';

const crews = gql`
    query Crews($crewId: String, $lang: String) {
        crews(crewId: $crewId, lang: $lang) {
            talents {
                title
            }
            hasSampleVO
            skills {
                title
            }
        }
    }
`;

export const crewsIds = gql`
    query Crews($ids: [String], $lang: String) {
        crews(crewIds: $ids, lang: $lang) {
            id
            category
            isUnique
            isPerson
            type
            title
            hasSampleVo
            nation {
                name
            }
            talents {
                title
            }
            skills {
                typeId
            }
            unlocks {
                id
            }
        }
    }
`;

export default crews;
