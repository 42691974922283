import * as React from 'react';
import { getStepByName, GUIDE_NAMES, IGuideStep, type IGuidePlacement } from '~/components/WelcomePage/steps';
import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import Guide from '~/components/WelcomePage/Guide';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { CategoryType } from '~/types/category';
import { RootState, useAppSelector } from '~/Store';
import { accountActions } from '~/Store/accountSlice';

interface IGuideDecorator {
    children: React.ReactChild | React.ReactChild[];
    names: GUIDE_NAMES[];
    className?: string;
    onClick?: (event: React.MouseEvent) => void;
    placement?: IGuidePlacement;
    centerBeacon?: boolean;
    content?: string | React.ReactNode;
}

const stateSelector = (state: RootState) => {
    return {
        activeGuideName: state.app.guide?.name,
        steps: state.account?.guideSteps,
        popupActive: state.app.popupActive,
        port: state.app.port,
        infoScreenIsVisible: state.app.infoScreen?.isVisible,
        currentNotificationVisibleName: state.app.currentNotificationVisibleName,
        devMode: state.app.devMode,
        isFinishedRequest: state.app.isFinishedRequest,
        isFullscreen: state.app.isFullscreen,
        categories: state.app.categories,
        currentPage: state.app.currentPage,
    };
};

function isNeedToHiddenForSpecialCategories(type: CategoryType) {
    return [CategoryType.ENROTH].includes(type);
}

function isAllowedInFullscreenCategory(type: CategoryType, step: IGuideStep) {
    if (CategoryType.SALVAGE === type && step && [GUIDE_NAMES.guide_salvage_resources].includes(step.name)) {
        return true;
    }

    return false;
}

const GuideDecorator = ({ children, names, className, onClick, placement, centerBeacon, content }: IGuideDecorator) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const [isStarted, setStart] = React.useState<boolean>(false);
    const [target, setTarget] = React.useState<HTMLDivElement>(null);
    const ref = React.useRef<HTMLDivElement>(null);
    const timingRef = React.useRef<{ delay: NodeJS.Timeout; duration: NodeJS.Timeout }>({ delay: null, duration: null });

    const getStep = () => {
        if (!Array.isArray(names)) {
            return null;
        }

        if (names.includes(state.steps?.[0]?.name)) {
            return getStepByName(state.steps[0]?.name);
        }

        const stepForCurrentCategory = state.steps?.find((step) => step.category === state.categories[state.currentPage?.name]?.type);
        if (stepForCurrentCategory && names.includes(stepForCurrentCategory.name)) {
            return getStepByName(stepForCurrentCategory.name);
        }

        return null;
    };

    const step = getStep();

    const isSpecialCategory = isNeedToHiddenForSpecialCategories(state.categories[state.currentPage?.name]?.type);
    const isNeedToHiddenInFullscreen = state.isFullscreen && !isAllowedInFullscreenCategory(state.categories[state.currentPage?.name]?.type, step);
    const isNeedToHidden = isSpecialCategory || isNeedToHiddenInFullscreen || !!state.popupActive || state.port?.isVisible || state.infoScreenIsVisible || state.currentNotificationVisibleName;

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            ref.current = _ref;
            setTarget(ref.current);
        }
    };

    const finish = () => dispatch(accountActions.hideGuideStep(step));

    const stop = () => {
        if (!isStarted) return;
        setStart(false);
        step.afterShown?.();
        finish();
    };

    const _onClick = (event: React.MouseEvent) => {
        stop();
        onClick?.(event);
    };

    const show = () => {
        if (isStarted) {
            return;
        }

        timingRef.current.delay = setTimeout(
            () => {
                setStart(true);
                if (step.duration) {
                    timingRef.current.duration = setTimeout(stop, step.duration * 1000);
                }
            },
            (step.delay || 1) * 1000,
        );
    };

    const showIfNeeded = () => {
        if (getStep()) {
            show();
        }
    };

    const clearTimings = () => {
        clearTimeout(timingRef.current.delay);
        clearTimeout(timingRef.current.duration);
    };

    React.useEffect(() => {
        if (!Array.isArray(names) || !state.steps?.length || !ref.current) {
            return;
        }
        if (!isNeedToHidden) {
            showIfNeeded();
        }
    }, [state.steps]);

    React.useEffect(() => {
        if (isNeedToHidden || !state.isFinishedRequest) {
            setStart(false);
            clearTimings();
        } else {
            showIfNeeded();
        }
    }, [isNeedToHidden, state.isFinishedRequest]);

    React.useEffect(() => {
        return clearTimings;
    }, []);

    if (!Array.isArray(names) || !step || state.devMode?.disableWelcomePage) {
        return (
            <div className={className} onClick={onClick}>
                {children}
            </div>
        );
    }

    return (
        <React.Fragment>
            <div ref={setRef} className={className} onClick={_onClick}>
                {children}
            </div>
            {!isMobileOrTabletWindow && isStarted && target && !isNeedToHidden && (
                <Guide content={content} step={step} run={isStarted} onFinishGuide={stop} target={target} placement={placement} centerBeacon={centerBeacon} />
            )}
        </React.Fragment>
    );
};

export default GuideDecorator;
