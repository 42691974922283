import styles from './ValuableRewardsLayout.scss';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import ChanceLabel from '~/components/ChanceLabel/ChanceLabel';
import { POPUPS_NAME } from '~/components/PopupManager';
import Info, { InfoSize } from '~/components/Info/Info';
import { getFeaturedRewardsByIndex, getSortOrderRewardsBySlotAndGroup } from '~/Layouts/Lootboxes/utils';
import { getStringForFullItemsListByGroup, getTextsTooltipAboutGroup } from '~/Layouts/Lootboxes/utils/texts';
import LootboxRewardItemCard from '~/components/LootboxRewardItemCard/LootboxRewardItemCard';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import equal from 'fast-deep-equal/react';
import LootboxRerollIcon from '~/components/LootboxRerollIcon/LootboxRerollIcon';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface IValuableRewardsLayout {
    rewards: ILootboxStateItemGroupRewards<ILootboxRewardGroup>;
    slotIndex: number;
    className?: string;
    lootbox: ILootboxStateItem;
    continuousRewardsTooltipText?: string[];
    autoDescriptionSettings: ILootboxAutoDescriptionSettings;
    rewardClassName?: string;
}

const stateSelector = (state: RootState) => {
    return {
        inventory: state.account.inventory,
        currentPage: state.app.currentPage,
    };
};

const ValuableRewardsLayout = ({ rewards, slotIndex, className, lootbox, continuousRewardsTooltipText, autoDescriptionSettings, rewardClassName }: IValuableRewardsLayout) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const groups = Object.keys(rewards).sort();
    let isNeedToShowLinkOpenPopup = true;
    const isNeedToRenderBranch = groups.length > 1;

    const openPopup = (groupName?: string) => {
        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.AUTO_DESCRIPTION_POPUP,
                isVisible: true,
                data: {
                    groupName,
                    containerId: lootbox.id,
                    slotIndex,
                    autoDescriptionSettings,
                },
            }),
        );
    };

    const classesContent = classNames(
        styles.content,
        {
            [styles.withBranch]: isNeedToRenderBranch,
        },
        className,
    );

    const showTooltipPopup = (contents: string[]) => {
        if (!isMobileOrTabletDeviceByAgent()) {
            return;
        }
        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.MOBILE_TOOLTIP_POPUP,
                isVisible: true,
                data: {
                    contents,
                },
            }),
        );
    };

    const continuousRewards = lootbox.slots[slotIndex].continuousRewards;

    return (
        <div className={classesContent}>
            {groups.map((groupKey: string, index) => {
                const group = rewards[groupKey];
                const promotionRewards = getSortOrderRewardsBySlotAndGroup(autoDescriptionSettings?.promotionRewardsInsideSlots, slotIndex + 1, index + 1);
                const previewItems = getFeaturedRewardsByIndex(group.rewards, promotionRewards);
                isNeedToShowLinkOpenPopup = previewItems.length !== group.rewards.length;

                const footerLinkText = getStringForFullItemsListByGroup(group.commonRewardType, group.rewards.length);
                const copiedTextsTooltipAboutGroup = getTextsTooltipAboutGroup();

                const classesGroup = classNames(styles.group, {
                    [styles.lastGroup]: index === groups.length - 1,
                    [styles.prevLastGroup]: index === groups.length - 2,
                    [styles.fullList]: !isNeedToShowLinkOpenPopup,
                });

                const classesItem = classNames(styles.items, {
                    [styles.fullList]: !isNeedToShowLinkOpenPopup,
                });

                let showRerollTooltip = group.hasUniqueRewards || group.rerollNonUniqueCrews;
                if (!group.rerollNonUniqueCrews && group.hasUniqueRewards && previewItems.length === 1) {
                    showRerollTooltip = false;
                }

                return (
                    <div className={classesGroup} key={`group_${groupKey}_${index}`}>
                        {isNeedToRenderBranch && <div className={styles.rewardsBranch}>{!index && <div className={styles.rewardsBranchStartPoint} />}</div>}
                        <div className={styles.groupHeader}>
                            <div className={styles.groupTitle}>{group.title}</div>
                            {showRerollTooltip && <LootboxRerollIcon enable={group.rerollNonUniqueCrews} groupName={group.title} />}
                            <div className={styles.groupChangeLabel}>
                                <ChanceLabel chance={group.probabilityDisplayed} isContinuousRewards={continuousRewards} tooltipContent={continuousRewardsTooltipText} />
                            </div>
                            {previewItems.length > 1 && (
                                <div
                                    className={styles.groupInfoLabel}
                                    onClick={() => {
                                        showTooltipPopup(copiedTextsTooltipAboutGroup);
                                    }}
                                >
                                    <Info
                                        size={InfoSize.SMALL}
                                        tooltip={{
                                            text: copiedTextsTooltipAboutGroup,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={classesItem}>
                            {previewItems.map((item, index) => {
                                return (
                                    <LootboxRewardItemCard
                                        key={`group_${groupKey}_${item.id}_${index}`}
                                        inventory={state.inventory}
                                        className={classNames(styles.rewardItem, rewardClassName)}
                                        reward={item}
                                        isUniqueGroup={group.hasUniqueRewards}
                                        bundleId={+state.currentPage?.bundleId}
                                        lootboxId={lootbox.id}
                                        autoDescriptionSettings={autoDescriptionSettings}
                                    />
                                );
                            })}
                        </div>
                        {isNeedToShowLinkOpenPopup && (
                            <div className={styles.groupFooter}>
                                <span
                                    className={styles.openPopupLink}
                                    onClick={() => {
                                        openPopup(groupKey);
                                    }}
                                >
                                    {footerLinkText}
                                </span>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default ValuableRewardsLayout;
