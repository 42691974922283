import * as React from 'react';
import client from '~/client';
import { ManagerData, SectionNames } from '~/core/ManagerData';
import VortexDataStorage from '~/core/VortexDataStorage';
import { GRAPHQL_QUERIES_MAP } from '~/queries/helpers';
import { settings } from '~/utils/settings';

export async function loadPrimaryItemData<T>(type: string, id: number): Promise<Nullable<T>> {
    if (!GRAPHQL_QUERIES_MAP[type]) {
        return null;
    }
    const response = await client.get().query({
        query: GRAPHQL_QUERIES_MAP[type],
        variables: {
            ids: [id],
            lang: settings.languageCode,
        },
    });
    if (response?.data) {
        const firstKey = Object.keys(response.data)[0];
        VortexDataStorage.merge(response.data);
        return response.data[firstKey][0] as T;
    }

    return null;
}

export default function useLoadPrimaryItemData<T>(type: string, id: number, sectionName: SectionNames) {
    const dataFromStorage = ManagerData.getData(sectionName, id, true);
    const [itemData, setItemData] = React.useState<T>(dataFromStorage);

    React.useEffect(() => {
        if (!type || !id) {
            return;
        }

        if (itemData) {
            return;
        }

        async function loadData() {
            const data = await loadPrimaryItemData<T>(type, id);
            if (data) {
                setItemData(data);
            }
        }

        loadData();
    }, [type, id]);

    return itemData;
}
