import styles from './MobileTooltip.scss';
import { Popup, PopupBody, PopupFooter } from '~/components/Popup/Popup';
import { useDispatch } from 'react-redux';
import Button from '~/components/Button/Button';
import { t } from '~/utils/localization';
import { appActions } from '~/Store/appSlice';

interface IMobileTooltip {
    data: { contents: string[] };
}

const MobileTooltip = ({ data }: IMobileTooltip) => {
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(appActions.changeVisibilityPopup({ name: null }));
    };

    return (
        <Popup onClose={onClose} renderEscButton={false}>
            <PopupBody className={styles.body}>
                {data.contents.map((content, index) => {
                    return (
                        <div className={styles.contentBlock} key={`content_block_${index}`}>
                            {content}
                        </div>
                    );
                })}
            </PopupBody>
            <PopupFooter className={styles.footer}>
                <Button label={t('Закрыть')} className={styles.buttonClose} onClick={onClose} />
            </PopupFooter>
        </Popup>
    );
};

export default MobileTooltip;
