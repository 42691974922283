import * as React from 'react';
import styles from './BundlePage.scss';
import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import classNames from 'classnames';
import BundlePageImage from '~/Layouts/BundlePage/BundlePageImage/BundlePageImage';
import { canBoughtContainer, REASON_FAIL_PURCHASE_CONTAINER } from '~/components/BundleLimitWidget/settings';
import PurchaseButton, { PurchaseButtonTheme } from '~/components/Button/PurchaseButton';
import { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import { t } from '~/utils/localization';
import AttentionLimitWidget from '~/components/BundleLimitWidget/Attention/AttentionLimitWidget';
import { isAvailableBigArt, isHiddenBundleImage, isScrollableTemplate } from '~/Layouts/BundlePage/helpers';
import { isEnabledPortByBundle } from '~/settings/port';
import Account from '~/account/Account';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import { getBundleLimitedInteractionStatus, isAvailableFullscreenTemplateWithoutGallery, isFreeBundle, isMultiplePurchaseAvailable } from '~/utils/bundles';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import MobilePurchaseWrapper from '~/components/PurchaseWrapper/MobilePurchaseWrapper';
import { FadeInDiv } from '~/components/Transitions';
import { default as commonStyles } from '~/assets/styles/common.scss';
import Customization from '~/Layouts/BundlePage/Customization/Customization';
import BundlePageLeftContent from '~/Layouts/BundlePage/BundlePageLeftContent/BundlePageLeftContent';
import Expander from '~/components/Expander/Expander';
import useDelegateContainerScroll from '~/hooks/useDelegateContainerScroll';
import RefManager, { RefManagerKeys } from '~/RefManager';
import BundlePagePortPreview from '~/Layouts/BundlePage/BundlePagePortPreview/BundlePagePortPreview';
import { isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import { getLevelingRestrictionForBundle, getLevelingRestrictionTextForBundle } from '~/utils/levelingResctrictions';
import Attention from '~/components/Attention/Attention';
import RealPurchaseButton from '~/components/Button/RealPurchaseButton';
import { BundleLimitedInteraction, BundlePurchaseTypes } from '~/types/bundle';
import { ArmoryFooter, WsmartFooter } from './BundlePageFooter';
import { PurchasedItem } from '~/components/Purchased/Purchased';
import CrewVoiceButtonContainer from '~/containers/CrewVoiceButtonContainer/CrewVoiceButtonContainer';
import { isSantaBundle } from '../Themes/SantaPage/settings';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import { accountActions } from '~/Store/accountSlice';

interface IBundlePage {
    bundle: IBundle;
}

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        accountId: state.account?.id,
        restrictedLootboxesPurchaseCount: state.account.restrictedLootboxesPurchaseCount,
        bundles: state.app.bundles,
        activeCouponId: state.account.activeCouponId,
        coupons: state.account.coupons,
        disabledCategories: state.app.disabledCategories,
        purchasedLimitedBundles: state.account.purchasedLimitedBundles,
        port: state.app.port,
        categories: state.app.categories,
        balance: state.account.balance,
        wowsCommerce: state.wsmart.wowsCommerce,
        bundlesInTransaction: state.account.bundlesInTransaction,
    };
};

const BundlePage = ({ bundle }: IBundlePage) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const [isDisabledPurchaseButton, setDisablingPurchaseButton] = React.useState(false);
    const [quantity, setQuantity] = React.useState(1);
    const refLeftContent = React.useRef<HTMLDivElement>(null);
    const isAlreadyPurchased = bundle.isPurchased;
    const _canBoughtContainer = canBoughtContainer(bundle, state.restrictedLootboxesPurchaseCount);
    const isAvailablePort = !isAlreadyPurchased && isEnabledPortByBundle(bundle, false, true);
    const isEnabledCoupon = Account.isEnabledCouponsFromBundle(state.coupons, bundle);
    const activeCoupon = Account.getActiveCoupon(state.activeCouponId, state.coupons);
    const isDisabledCategory = state.disabledCategories.includes(state.currentPage?.name);
    const canChangeQuantity = state.accountId && !bundle.isLoading && isMultiplePurchaseAvailable(bundle);
    const _isScrollableTemplateWithoutGallery = isAvailableFullscreenTemplateWithoutGallery(bundle);
    const _isScrollableTemplate = isScrollableTemplate(bundle) || _isScrollableTemplateWithoutGallery;
    const _isFreeBundle = isFreeBundle(bundle);
    const isHiddenGallery = isAvailableBigArt(bundle) || _isScrollableTemplate || _isScrollableTemplateWithoutGallery || isHiddenBundleImage(bundle);
    const hasGallery = !!bundle.gallery?.length && !isHiddenGallery;
    const [isExpanded, toExpand] = React.useState<boolean>(!_isScrollableTemplate);
    const currentCategory = state.categories[state.currentPage?.name];
    const isAllowedInteraction = getBundleLimitedInteractionStatus(bundle) === BundleLimitedInteraction.none;
    const isAvailablePurchase = !isAlreadyPurchased && isAllowedInteraction;
    const isBundleForRealCurrency = bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY;
    const isActiveTransaction = state.bundlesInTransaction?.includes(bundle.id);

    React.useEffect(() => {
        return () => {
            dispatch(accountActions.resetCoupon());
            dispatch(appActions.removeClientSource());
        };
    }, []);

    React.useEffect(() => {
        if (state.activeCouponId) {
            dispatch(accountActions.resetCoupon());
        }
    }, [bundle.id]);

    useMaskScrollEffect(refLeftContent);
    useDelegateContainerScroll(refLeftContent, [], [bundle.id]);

    const purchase = () => {
        if (_isFreeBundle) {
            new PurchaseProcessor(bundle).purchase();
        } else {
            new PurchaseProcessor(bundle).purchaseBundleFromPopup(bundle, null);
        }
    };

    const toggleExpand = () => toExpand(!isExpanded);

    if (isDisabledCategory) {
        return (
            <div className={commonStyles.bigNotice}>
                <div className={commonStyles.bigNoticeText}>{t('Товары в этой категории более недоступны')}</div>
            </div>
        );
    }

    const classesContent = classNames(styles.content, {
        [styles.scrollable]: _isScrollableTemplate && !isExpanded,
    });

    const classesPurchaseContent = classNames(styles.purchaseContent, {
        [styles.withExpander]: _isScrollableTemplate,
        [styles.isVariablePriceProduct]: bundle.originalProduct?.isVariablePriceProduct,
    });

    const classesRightContent = classNames(styles.rightContent, {
        [styles.withGallery]: hasGallery,
        [styles.withFullscreenGallery]: !isMobileOrTabletWindow && bundle.isFullscreenGallery,
    });

    const levelingResctrictions = getLevelingRestrictionForBundle(bundle);

    const FooterContent = () => {
        if (!isAllowedInteraction) {
            return null;
        }

        if (bundle.purchaseType !== BundlePurchaseTypes.REAL_CURRENCY && bundle.price === undefined) {
            return;
        }

        if (bundle.isPurchased) {
            return <PurchasedItem bundle={bundle} />;
        }

        const _isSantaBundle = isSantaBundle(bundle);

        if (Number(_canBoughtContainer) >= 0) {
            if (isBundleForRealCurrency) {
                return (
                    <RealPurchaseButton
                        bundle={bundle}
                        quantity={quantity}
                        isDisabled={isDisabledPurchaseButton}
                        buttonClassName={classNames(styles.purchaseButton, 'armory__auto--bundle_button_purchase')}
                        purchaseButtonTheme={_isSantaBundle && PurchaseButtonTheme.cookie}
                    />
                );
            }

            return (
                <PurchaseButton
                    bundle={bundle}
                    coupon={activeCoupon}
                    isActiveCoupon={state.activeCouponId && isEnabledCoupon}
                    buttonClassName={classNames(styles.purchaseButton, 'armory__auto--bundle_button_purchase')}
                    onClick={purchase}
                    classNameFastGoldLink={styles.fastGoldLink}
                    classNameChinaFastGoldLink={styles.cnFastGold}
                    place={FAST_GOLD_PLACE.BUNDLE_VIEW_PAGE}
                    purchaseButtonTheme={_isSantaBundle && PurchaseButtonTheme.cookie}
                />
            );
        }

        return <AttentionLimitWidget reason={_canBoughtContainer as REASON_FAIL_PURCHASE_CONTAINER} bundle={bundle} />;
    };

    return (
        <React.Fragment>
            <Customization bundle={bundle} theme={currentCategory?.theme} />
            <FadeInDiv className={classNames(styles.wrapper, 'armory__auto--bundle-page_wrapper')} data-bundle-id={bundle.id}>
                <div
                    className={classesContent}
                    ref={(_ref: HTMLDivElement) => {
                        if (_ref) {
                            RefManager.setRef(RefManagerKeys.BundlePageLeftContent, _ref);
                        }
                    }}
                >
                    <BundlePageLeftContent
                        key={`bundle_content_${bundle.id}`}
                        bundle={bundle}
                        bundles={state.bundles}
                        ref={refLeftContent}
                        expanded={isExpanded}
                        isScrollableTemplate={_isScrollableTemplate}
                        onChangeExpand={(val) => toExpand(val)}
                        className={classNames({ [styles.withPort]: isAvailablePort })}
                    />
                    {!isHiddenGallery && (
                        <React.Fragment>
                            <div className={classesRightContent}>
                                <BundlePageImage bundle={bundle} />
                                {isAvailablePort && <BundlePagePortPreview bundle={bundle} />}
                                {isInGameBrowser && <CrewVoiceButtonContainer className={styles.crewVoiceButton} primaryItem={bundle.primaryItem} />}
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className={classesPurchaseContent}>
                    {_isScrollableTemplate && (
                        <div className={styles.expanderContent}>
                            <Expander isExpanded={isExpanded} label={isExpanded ? t('Cвернуть описание') : t('Развернуть описание')} className={styles.expander} onClick={toggleExpand} />
                        </div>
                    )}
                    {isAvailablePurchase &&
                        !_isFreeBundle &&
                        (!isBundleForRealCurrency ? (
                            <ArmoryFooter
                                bundle={bundle}
                                activeCoupon={activeCoupon}
                                balance={state.balance}
                                canChangeQuantity={canChangeQuantity}
                                isEnabledCoupon={isEnabledCoupon}
                                reasonCantBoughtCode={Number(_canBoughtContainer)}
                                isActiveTransaction={isActiveTransaction}
                            />
                        ) : (
                            bundle.originalProduct && <WsmartFooter bundle={bundle} onDisabledHandler={setDisablingPurchaseButton} onQuantityHandler={setQuantity} />
                        ))}
                    {!!levelingResctrictions?.length && (
                        <div className={styles.restrictionText}>
                            <Attention label={getLevelingRestrictionTextForBundle(bundle, levelingResctrictions)} level="info" isInline />
                        </div>
                    )}
                    <div className={styles.purchaseButtonsWrapper}>
                        <div className={styles.purchaseButtons}>{FooterContent()}</div>
                    </div>
                </div>
                {!bundle.originalProduct?.isVariablePriceProduct && <div className={styles.mobilePurchaseWrapper}>{<MobilePurchaseWrapper bundle={bundle} />}</div>}
            </FadeInDiv>
        </React.Fragment>
    );
};

export default BundlePage;
