import { IMercatorContentState } from '~/types/mercator';
import { get } from '~/utils/ajax';
import { settings } from '~/utils/settings';

export default async function loadMercatorContent(): Promise<IMercatorContentState | 1> {
    if (!settings.urls.mercator.contentStateUrl) {
        return Promise.resolve(1);
    }
    return await get(`${settings.urls.mercator.contentStateUrl}?lang=${settings.languageCode}`, null, false, true, true, {
        credentials: 'include',
    });
}
