import gql from 'graphql-tag';

const items = gql`
    query Items($ids: [String], $lang: String) {
        items(itemIds: $ids, lang: $lang) {
            id
            title
            slot
            typeName
            tags
            description
            ttc {
                name
                value
                title
            }
            restrictions {
                levels
            }
        }
    }
`;

export default items;
