import * as React from 'react';
import { interpolate } from '~/utils/localization';
import styles from './CommonRewardsLayout.scss';
import classNames from 'classnames';
import ChanceLabel from '~/components/ChanceLabel/ChanceLabel';
import { GroupedRewardsTypes } from '~/Layouts/Lootboxes/utils';
import { DivTooltip } from '@wg/wows-react-uikit';
import GroupedLootboxRewardsTooltip from './GroupedLootboxRewardsTooltip';
import { openPortByItem } from '~/settings/port';
import { settings } from '~/utils/settings';
import { PortPreviewType } from '~/components/Port/settings';
import PortPreviewButton from '~/components/PortPreviewButton/PortPreviewButton';

export const wowsEntityClassesMapByType = {
    [GroupedRewardsTypes.SIGNAL]: 'ensigns',
    [GroupedRewardsTypes.CAMOUFLAFE]: 'permoflages',
    [GroupedRewardsTypes.CAMOBOOST]: 'all-boosts',
};

interface IGroupedRewardItem {
    amount: string;
    type: keyof typeof wowsEntityClassesMapByType;
    chance: number;
    str: string;
    rewards: ILootboxReward[];
    bundleId?: number;
    isAvailablePortPreview?: boolean;
    className?: string;
    lootboxId: number;
}

const GroupedRewardItem = ({ amount, type, chance, str, rewards, bundleId, isAvailablePortPreview, className, lootboxId }: IGroupedRewardItem) => {
    str = interpolate(str, { amount });
    const weType = wowsEntityClassesMapByType[type];

    const gropedTooltip = <GroupedLootboxRewardsTooltip rewards={rewards} renderTextAboutChance={Number.getCountNumbersAfterComma(chance) === 2} />;

    const openPort = () => {
        openPortByItem({
            item: {
                shipId: settings.camouflagePreviewDefaultShip,
                type: type,
                identifier: rewards[0].id,
            },
            bundleId: bundleId,
            portPreviewType: PortPreviewType.CONTAINER,
        });
    };

    return (
        <DivTooltip tooltipBody={gropedTooltip} className={classNames(styles.item, className)}>
            {isAvailablePortPreview && <PortPreviewButton onlyIcon className={styles.portPreviewButton} onClick={openPort} />}
            <div className={classNames(styles.weGroupedItem, `we-${weType}__title with-icon`)}>{str}</div>
            <ChanceLabel chance={chance} className={styles.changeLabel} tooltipContent={[<GroupedLootboxRewardsTooltip rewards={rewards} />]} withoutTooltip />
        </DivTooltip>
    );
};

export default GroupedRewardItem;
