import * as React from 'react';

const localCache: Record<string, string> = {};

export default function useResourceLoader(urls: string[], isDisabledBlob = false, isDisableLoading = false) {
    const [isLoaded, setState] = React.useState(false);
    const [response, setResponse] = React.useState<any>({});

    async function load(filteredUrls: typeof urls) {
        const caches: Record<string, string> = {};

        for (let i = 0; i < filteredUrls.length; i++) {
            const url = filteredUrls[i];
            if (localCache[url]) {
                caches[url] = localCache[url];
            } else if (isDisabledBlob) {
                caches[url] = url;
            } else {
                const response = await fetch(url);
                const blob = await response.blob();
                const blobUrl = (webkitURL || URL).createObjectURL(blob);
                caches[url] = blobUrl;
                localCache[url] = blobUrl;
            }
        }

        if (Object.keys(caches).length === urls.length) {
            setResponse(caches);
            setState(true);
        }
    }

    React.useEffect(() => {
        const filteredUrls = urls.filter((url) => !!url);
        if (!filteredUrls?.length) {
            return;
        }
        !isDisableLoading && load(filteredUrls);
    }, []);

    return [isLoaded, response];
}
