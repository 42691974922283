import * as React from 'react';
import classNames from 'classnames';
import styles from '~/Layouts/Layouts.scss';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { items, PresentationStyles } from '@wg/wows-entities/const';
import { openBundleByUrl } from '~/utils/category';
import { BUNDLE_GRID_SIZE, CATEGORIES } from '~/const';
import { DivTooltip, Interpolate } from '@wg/wows-react-uikit';
import { isMobileOrTabletWindow } from '~/utils/utils';
import store, { RootState, useAppSelector } from '~/Store';
import { shallowEqual } from 'react-redux';
import Account from '~/account/Account';
import LabelDecorator, { LABEL_TYPE } from '~/decorators/LabelDecorator/LabelDecorator';
import { isAllowedNewLabelFromBundle } from '~/utils/labels';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import CrewPoints from '~/components/CrewPoints/CrewPoints';
import GlowEffectDecorator from '~/components/GlowEffect/GlowEffectDecorator';
import BundleHeaderContent from '~/components/Bundle/Default/BundleHeaderContent';
import BundleFooterContent from '~/components/Bundle/Default/BundleFooterContent';
import BundleDecoration from '~/components/Bundle/Decoration/BundleDecoration';
import { isAdmiralPack, isCommanderPack, getBundleLimitedInteractionStatus, isEnabledTooltipFromItemByType, isFreeBundle, isOnlyShipBundle } from '~/utils/bundles';
import SpecialBundleFooterContent from '~/components/Bundle/Content/SpecialBundleFooterContent/SpecialBundleFooterContent';
import HoverVideo from '~/customization/HoverVideo/HoverVideo';
import { BUNDLE_TYPES, BundleLimitedInteraction, BundlePurchaseTypes } from '~/types/bundle';
import { playCardClickSound } from '~/api/WoWsClient';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { t } from '~/utils/localization';
import CountDown from '~/components/CountDown/CountDown';

interface IBundleItemProps {
    bundle: IBundle;
    fromCategory?: string;
    templateLayout?: string;
    image?: string;
    sizeInGrid?: string;
    isLazilyRendered?: boolean;
    isAlreadyPurchased?: boolean;
    preset?: ICategoryPresets;
    disableRedirectToBundle?: boolean;
}

export const BUNDLE_WIDTH_MAP: Record<string, string> = {
    [BUNDLE_GRID_SIZE.FULL]: styles.full,
    [BUNDLE_GRID_SIZE.THREE_SECOND]: styles.threeSecond,
    [BUNDLE_GRID_SIZE.HALF]: styles.half,
    [BUNDLE_GRID_SIZE.ONE_THIRD]: styles.third,
    [BUNDLE_GRID_SIZE.ONE_FOUR]: styles.default,
    [BUNDLE_GRID_SIZE.THIRD_QUARTER]: styles.threeDefault,
    [BUNDLE_GRID_SIZE.TWO_THIRDS]: styles.twoThirds,
};

export const getLayoutClassName = (width: string, bundle: IBundle, isShipsCategory: boolean) => {
    if (width) {
        return BUNDLE_WIDTH_MAP[width];
    }

    if (isShipsCategory) {
        return BUNDLE_WIDTH_MAP[BUNDLE_GRID_SIZE.ONE_THIRD];
    }

    const primaryItem = bundle.primaryItem;
    if (!bundle.sizeInGrid && primaryItem && primaryItem.type === items.VEHICLES) {
        return BUNDLE_WIDTH_MAP[BUNDLE_GRID_SIZE.ONE_THIRD];
    }

    return BUNDLE_WIDTH_MAP[bundle.sizeInGrid || BUNDLE_GRID_SIZE.ONE_FOUR];
};

const SpecialContentByPrimaryType = (props: { primaryItem: IBundleEntity }) => {
    if (!props.primaryItem) {
        return null;
    }

    switch (props.primaryItem.type) {
        case items.CREWS:
            return (
                <div className={styles.crewsPoints}>
                    <CrewPoints points={props.primaryItem.customisation.points} />
                </div>
            );
    }

    return null;
};

export const getBundleByType = (bundle: IBundle) => {
    const accountState = store.getState().account;

    switch (bundle.type) {
        case BUNDLE_TYPES.random:
            return Account.getRandomBundleChild(accountState.selectedRandomBundles || {}, bundle);

        default:
            return bundle;
    }
};

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        inventory: state.account.inventory,
        viewedLabels: state.account.viewedLabels,
        shipFeatureMap: state.features.shipFeatureMap,
        isTrusted: state.app.isTrusted,
        bundles: state.app.bundles,
    };
};

const Bundle = React.forwardRef((props: IBundleItemProps, ref: React.RefObject<HTMLDivElement>) => {
    const state = useAppSelector(stateSelector, shallowEqual);
    const bundle = getBundleByType(state.bundles[props.bundle.id]);
    const currentPageName = state.currentPage?.name;
    const bundleInteractionStatus = getBundleLimitedInteractionStatus(bundle);
    const isFullLimitedInteraction = bundleInteractionStatus === BundleLimitedInteraction.full;
    const disableRedirectToBundle = props.disableRedirectToBundle || isFullLimitedInteraction;

    const onClick = () => {
        if (disableRedirectToBundle) {
            return;
        }

        playCardClickSound();

        let categoryLinkName = currentPageName;

        if (bundle.categories.length && !bundle.categories.includes(currentPageName)) {
            categoryLinkName = bundle.categories[0];
        }

        openBundleByUrl(categoryLinkName || CATEGORIES.FEATURED, bundle.id, props.preset);
    };

    const isShipsCategory = bundle.categories.includes(CATEGORIES.SHIPS) || bundle.categories.includes(CATEGORIES.PREM_SHIPS);

    const _commanderPack = isCommanderPack(bundle);
    const _isAdmiralPack = isAdmiralPack(bundle);

    const primaryItem = bundle.primaryItem;

    const offerWrapperClassName = classNames(
        {
            [styles.offerItem]: true,
            [styles.alreadyPurchased]: bundle.isPurchased,
            [styles.commander]: _commanderPack,
            'armory__auto--bundle_default': !bundle.purchaseType || bundle.purchaseType === BundlePurchaseTypes.INGAME_CURRENCY,
            'armory__auto--bundle_real_purchase': bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY,
        },
        getLayoutClassName(props.sizeInGrid, bundle, isShipsCategory),
        styles[primaryItem?.type],
        styles[`w${props.sizeInGrid?.split('/').join('-')}`],
        ...(bundle.decoration || []),
    );

    let backgroundImage = props.image || bundle.icons.medium;
    if (isMobileOrTabletWindow) {
        backgroundImage = bundle.icons.small;
    }

    const isAvailableContainsWidget = bundle.entitlements?.length > 1;

    const _isFreeBundle = isFreeBundle(bundle);
    const isAllowedNewLabel = isAllowedNewLabelFromBundle(bundle);

    const isEnableGlowEffect = _isFreeBundle && !bundle.isPurchased;

    const isAvailableShipFeatures = !!state.shipFeatureMap[bundle?.primaryItem?.identifier] && isOnlyShipBundle(bundle.entitlements);

    const contentClassNames = classNames(
        styles.offerContent,
        {
            [styles.offerItemHover]: !isFullLimitedInteraction && !bundle.isPurchased,
            [styles.disabledInteraction]: isFullLimitedInteraction,
            [styles.isLoading]: bundle.isLoading,
            [styles.containsHover]: isAvailableContainsWidget,
            [styles.purchasedShipFeatures]: bundle.isPurchased && isAvailableShipFeatures,
        },
        'lazy',
        styles[props.image],
        styles[props.bundle.categories.join(' ')],
        ...(bundle.decoration || []),
    );

    const classesBundleImage = classNames(styles.bundleImage, ...(bundle.decoration || []));

    const admiralClassNames = classNames({
        [styles.admiralHalf]: _isAdmiralPack && bundle.sizeInGrid === BUNDLE_GRID_SIZE.HALF,
        [styles.admiralFull]: _isAdmiralPack && bundle.sizeInGrid === BUNDLE_GRID_SIZE.FULL,
    });

    const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(primaryItem?.type, primaryItem?.identifier, primaryItem.customisation?.shipId);

    const otherProps: Record<string, any> = {};
    if (bundle.primaryItem?.type) {
        otherProps['data-primary-type'] = bundle.primaryItem.type;
    }

    let style: React.CSSProperties = {};
    if (bundle.bundleContentUnderlayColor) {
        style = Object.assign(style, { '--underlay-color': bundle.bundleContentUnderlayColor });
    }

    return (
        <div
            className={offerWrapperClassName}
            onClick={onClick}
            ref={ref}
            data-id={bundle.id}
            data-is-unique={bundle.primaryItem?.isUnique}
            data-lazy-id={`${bundle.id}_${props.preset?.name}`}
            style={style}
            {...otherProps}
        >
            {props.isLazilyRendered && (
                <LabelDecorator id={bundle.name} labelTill={bundle.labelNewActiveTill} isEnabled={isAllowedNewLabel} byHover type={LABEL_TYPE.bundle}>
                    <GlowEffectDecorator isEnabled={isEnableGlowEffect} size={bundle.sizeInGrid} decorations={bundle.decoration}>
                        <LabelContainer className={styles.bundleLabel} isVisible={isAllowedNewLabel} />
                        {_isAdmiralPack && <div className={admiralClassNames} />}
                        <div className={contentClassNames}>
                            <BundleDecoration bundle={bundle}>
                                <DivTooltip
                                    className={styles.tooltipContentWrapper}
                                    style={{ cursor: isFullLimitedInteraction ? 'initial' : 'pointer' }}
                                    tooltipBody={
                                        isFullLimitedInteraction ? (
                                            <DefaultTooltip text={<Interpolate str={`${t('Starts in:')} %(component)s`} component={<CountDown date={bundle.purchasableFrom} withoutIcon />} />} />
                                        ) : isEnabledTooltipFromItemByType(primaryItem?.type) && bundle.entitlements?.length === 1 ? (
                                            <WoWSEntity
                                                id={primaryItem.identifier}
                                                type={primaryItem.type}
                                                amount={primaryItem.amount}
                                                presentation={{
                                                    style: PresentationStyles.TOOLTIP_WIDGET,
                                                    withTooltip: true,
                                                }}
                                                customisation={{
                                                    points: primaryItem?.customisation?.points,
                                                    shipId: primaryItem?.customisation?.shipId,
                                                    inventoryCount: !primaryItem?.isUnique && inventoryCount,
                                                    isExistsInInventory: primaryItem?.isUnique && isExistsItemInInventory,
                                                }}
                                            />
                                        ) : null
                                    }
                                >
                                    <HoverVideo muted={!state.isTrusted} video={bundle.bundleCardHoverVideo} isDisableAnimation={bundle.isPurchased} parentClassName={styles.offerItem}>
                                        <img className={classesBundleImage} src={backgroundImage} alt={bundle.title} />
                                    </HoverVideo>
                                    <div className={styles.itemContent}>
                                        <BundleHeaderContent bundle={bundle} primaryItem={primaryItem} />
                                        <SpecialContentByPrimaryType primaryItem={primaryItem} />
                                        {bundleInteractionStatus === BundleLimitedInteraction.none && <BundleFooterContent bundle={bundle} isFreeBundle={_isFreeBundle} primaryItem={primaryItem} />}
                                        <SpecialBundleFooterContent
                                            primaryItem={primaryItem}
                                            className={classNames({
                                                [styles.isPurchased]: bundle.isPurchased,
                                            })}
                                        />
                                    </div>
                                </DivTooltip>
                            </BundleDecoration>
                        </div>
                    </GlowEffectDecorator>
                </LabelDecorator>
            )}
        </div>
    );
});

export default Bundle;
