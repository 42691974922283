import { items as ITEM_TYPES } from '@wg/wows-entities/const';
import { getPrimaryItem } from '~/utils/bundles';
import styles from './PrimaryContent.scss';
import getCrewsContent from '~/Layouts/BundlePage/Contents/CrewsContent';
import ShipsContent from '~/Layouts/BundlePage/Contents/ShipsContent';
import LootboxContent from '~/Layouts/Lootboxes/Views/LootboxContent/LootboxContent';
import CamouflageContent from '~/Layouts/BundlePage/Contents/CamouflageContent';
import SignalContent from '~/Layouts/BundlePage/Contents/SignalContent';
import ModernizationContent from '~/Layouts/BundlePage/Contents/ModernizationContent';

interface IPrimaryContent {
    bundle: IBundle;
}

function getContentForPrimaryItem(bundle: IBundle, primaryItem: IBundleEntity) {
    switch (primaryItem?.type) {
        case ITEM_TYPES.CREWS:
            return getCrewsContent({ item: primaryItem, renderSpoiler: false });

        case ITEM_TYPES.VEHICLES:
            return <ShipsContent item={primaryItem} renderSpoiler={false} renderFeatures={true} />;

        case ITEM_TYPES.LOOTBOX:
            return <LootboxContent bundle={bundle} item={primaryItem} renderSpoiler={false} />;

        case ITEM_TYPES.PERMOFLAGES:
        case ITEM_TYPES.SKIN:
        case ITEM_TYPES.CAMOUFLAGE:
        case ITEM_TYPES.STYLE:
            return <CamouflageContent item={primaryItem} renderSpoiler={false} />;

        case ITEM_TYPES.SIGNAL:
            return <SignalContent item={primaryItem} renderSpoiler={false} />;

        case ITEM_TYPES.MODERNIZATION:
            return <ModernizationContent item={primaryItem} renderSpoiler={false} />;

        default:
            return null;
    }
}

const PrimaryContent = ({ bundle }: IPrimaryContent) => {
    const primaryItem = getPrimaryItem(bundle.entitlements);
    const primaryContent = getContentForPrimaryItem(bundle, primaryItem as IBundleEntity);

    if (!primaryItem) {
        return null;
    }

    return <div className={styles.contentBlock}>{primaryContent}</div>;
};

export default PrimaryContent;
