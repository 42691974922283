import { Processing } from '@wg/wows-react-uikit';
import { memo, lazy, Suspense } from 'react';
import { IForecastContainer } from './ForecastContainer';

const LazyContainer = lazy(
    () =>
        import(
            /* webpackChunkName: "forecast" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: true */
            './ForecastContainer'
        ),
);

const LazyForecastContainer = ({ category }: IForecastContainer) => {
    return (
        <Suspense fallback={<Processing isFetching={true} />}>
            <LazyContainer category={category} />
        </Suspense>
    );
};

export default memo(LazyForecastContainer);
