import * as React from 'react';
import styles from './BundlePageHeader.scss';
import { isObject } from '~/utils/utils';
import { getBundleDisclaimers, getSerialBundlesById } from '~/utils/bundles';
import SerialBundleTitle from '~/components/SerialBundleTitle/SerialBundleTitle';
import getAdditionalHeaderContent from '~/Layouts/BundlePage/BundlePageHeader/content';

interface IBundlePageHeader {
    bundle: IBundle;
    bundles: IBundleList;
    renderPreviewContent?: boolean;
}

const SerialBundlePageHeader = ({ bundle, bundles }: IBundlePageHeader) => {
    if (!bundle.serialPurchase) {
        return null;
    }

    const serialBundles = getSerialBundlesById(bundles, bundle.id);

    return (
        <div className={styles.serialChainWidget}>
            <SerialBundleTitle step={bundle.serialIndex + 1} totalCount={serialBundles.length} />
        </div>
    );
};

const BundlePageHeader = ({ bundle, bundles, renderPreviewContent }: IBundlePageHeader) => {
    const title: string | React.ReactChild = bundle.title;
    const additionalContent = getAdditionalHeaderContent(bundle, renderPreviewContent);
    const disclaimers = getBundleDisclaimers(bundle);

    return (
        <div className={styles.headerWrapper}>
            {isObject(title) ? <div className={styles.title}>{title}</div> : <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: String(title) }} />}
            {(!!additionalContent || !!bundle.serialPurchase) && (
                <div className={styles.content}>
                    {additionalContent}
                    {bundle.serialPurchase && <SerialBundlePageHeader bundle={bundle} bundles={bundles} />}
                </div>
            )}
            {!!disclaimers.length && <div className={styles.disclaimer}>{disclaimers.join(' ')}</div>}
        </div>
    );
};

export default BundlePageHeader;
