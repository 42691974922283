import Account from '~/account/Account';
import { POPUPS_NAME } from '~/components/PopupManager';
import store from '~/Store';
import { LocalStorageHelper } from '~/utils/storage';
import { getActivateAccountLSKey } from '~/components/Popups/DownloadGamePopup';
import { appActions } from '~/Store/appSlice';

export const isNeedToShowDownloadGamePopup = () => Account.getAccount() && Account.isDeactivated();

export const isNeedToFirstShowDownloadGamePopup = () => {
    return !LocalStorageHelper.get(getActivateAccountLSKey()) && isNeedToShowDownloadGamePopup();
};

export const getInitialPopup = (): IPopup => {
    if (isNeedToFirstShowDownloadGamePopup()) {
        return {
            name: POPUPS_NAME.DOWNLOAD_GAME_POPUP,
        };
    }

    return null;
};

export interface IShowDownloadGamePopupOptions {
    onClose: () => void;
}

export const showDownloadGamePopup = (options?: IShowDownloadGamePopupOptions): void => {
    store.dispatch(
        appActions.changeVisibilityPopup({
            name: POPUPS_NAME.DOWNLOAD_GAME_POPUP,
            isVisible: true,
            data: options,
        }),
    );
};
