export enum Realms {
    eu = 'eu',
    sg = 'sg',
    us = 'us',
    cn360 = 'cn360',
}

declare global {
    interface IRealmConfig {
        realm: Realms;
        title: string;
        shortTitle: string;
        url: string;
        changeLanguageUrl: string;
        languageCodes: string[];
    }

    type GlobalStore = Record<string, any>;

    interface IArmoryGlobalContent {
        name: string;
        background: Nullable<string>;
        theme: Nullable<string>;
    }

    interface ArmoryContent {
        bundles: Record<number, any>;
        categories: ICategory[];
        currencies: IArmoryStateCurrency[];
        coupons: ICoupon[];
        featuring: IFeature[];
        notifications: INotification[];
        newCouponsCount: number;
        global: IArmoryGlobalContent;
    }
}

export default global;
