import * as React from 'react';
import styles from './SpoilerContent.scss';
import Spoiler from '~/components/Spoiler/Spoiler';
import { getRandomBundleContent } from '~/Layouts/BundlePage/Contents/RandomBundleContent';
import classNames from 'classnames';

interface ISpoilerContent {
    bundle: IBundle;
    onChange?: () => void;
    className?: string;
}

const SpoilerContent = ({ bundle, onChange, className }: ISpoilerContent) => {
    const spoilerContent = React.useMemo(() => {
        return getRandomBundleContent(bundle);
    }, []);

    return (
        <div className={classNames(styles.content, className)}>
            <Spoiler
                title={<div className={styles.title}>{spoilerContent.title}</div>}
                content={spoilerContent.content}
                onChange={() => {
                    onChange?.();
                }}
            />
        </div>
    );
};

export default React.memo(SpoilerContent);
