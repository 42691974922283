import store from '~/Store';
import Account from '~/account/Account';
import { FEATURING_TEMPLATES } from '~/Layouts/FeaturingLayout';
import { isEnableOpenBundle, isFreeBundle, isPeriodicBundle, isPeriodicBundlePurchaseDisabled } from '~/utils/bundles';
import { CATEGORIES } from '~/const';
import { BUNDLE_DECORATION } from '~/types/bundle';
import { FEATURING_DESTINATION } from '~/types/contents';

export const hasWithoutGlowAnimationDecoration = (bundle: IBundle): boolean => {
    return bundle?.decoration?.includes(BUNDLE_DECORATION.WITHOUT_GLOW_ANIMATION);
};

export const isEnableShinyForBundle = (bundle: IBundle, fromCategory = false): boolean => {
    if (!bundle || !isFreeBundle(bundle)) {
        return false;
    }

    if (hasWithoutGlowAnimationDecoration(bundle) && !fromCategory) {
        return false;
    }

    const accountState = store.getState().account;

    const isAlreadyPurchased = bundle.isPurchased;

    if (isAlreadyPurchased) {
        return false;
    }

    if (bundle.serialPurchase || (bundle.isRandom && bundle.serialPurchase)) {
        return !Account.isDisabledSerialBundle(accountState.purchasedLimitedBundles, bundle);
    }

    if (isPeriodicBundle(bundle)) {
        if (!accountState.id) {
            return true;
        }
        return !isPeriodicBundlePurchaseDisabled(bundle) || isEnableOpenBundle(bundle);
    }

    return true;
};

export const isShinyFeatured = () => {
    const appState = store.getState().app;
    const featuring = (appState.featuring as IFeature[]).filter((featuring: IFeature) => featuring.destination === FEATURING_DESTINATION.MAIN)[0] as IFeature;

    if (!featuring) {
        return false;
    }

    return featuring.contentBlocks.some((contentBlock) => {
        const data = contentBlock.data as IContentBlockData;
        switch (contentBlock.type) {
            case FEATURING_TEMPLATES.BUNDLE_LIST: {
                return data.bundles.some((bundle) => {
                    return appState.bundles[bundle.id] && isEnableShinyForBundle(appState.bundles[bundle.id], true);
                });
            }

            case FEATURING_TEMPLATES.CATEGORY_PREVIEW: {
                const categoryName = data.categoryName as ICategoryList;
                const category = appState.categories[categoryName];
                if (!category) {
                    return false;
                }
                let categoryBundles = Object.values(category.bundles) as any;
                categoryBundles = data.bundlesCount ? categoryBundles.splice(0, data.bundlesCount) : categoryBundles;
                return categoryBundles.some((bundle: IBundle) => isEnableShinyForBundle(bundle, true));
            }
        }

        return false;
    });
};

export const isShinyCategory = (categoryName: ICategoryList, bundleSpliceLength?: number, isNotMarkedParentCategory = false) => {
    const appState = store.getState().app;
    const category = appState.categories?.[categoryName];
    const bundles = appState.bundles;

    if (!category) {
        return false;
    }

    if (category.name === CATEGORIES.FEATURED) {
        return isShinyFeatured();
    }

    const isEnabled = (_category: ICategory): boolean => {
        let categoryBundles = _category.bundles?.map((bundleId) => bundles[bundleId]) || [];

        if (bundleSpliceLength) {
            categoryBundles = categoryBundles.splice(0, bundleSpliceLength);
        }

        return categoryBundles.some((bundle: IBundle & { id: string }) => isEnableShinyForBundle(bundle, true));
    };

    if (category.subCategories?.length) {
        return category.subCategories.some((_category: ICategoryList) => {
            const categoryConfig = appState.categories?.[_category];
            if (categoryConfig) {
                return isEnabled(categoryConfig);
            }

            return false;
        });
    }

    return isEnabled(category);
};
