import * as React from 'react';

const useScroll = (ref: React.RefObject<any>, onScroll?: (event?: React.UIEvent) => void, onScrollEnd?: () => void, deps: React.DependencyList = []) => {
    let isScrolling = false;
    let interval: any;

    const __onScroll = () => {
        isScrolling = true;
        onScroll && onScroll();
        clearInterval(interval);

        interval = setInterval(() => {
            isScrolling = false;
            clearInterval(interval);
            onScrollEnd && onScrollEnd();
        }, 100);
    };

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        ref.current?.removeEventListener('scroll', __onScroll);
        ref.current.addEventListener('scroll', __onScroll);

        return () => {
            ref.current?.removeEventListener('scroll', __onScroll);
        };
    }, deps);
};

export default useScroll;
