import { items } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import classNames from 'classnames';
import equal from 'fast-deep-equal/react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Account from '~/account/Account';
import Attention from '~/components/Attention/Attention';
import BundleLimitContainer from '~/components/BundleLimitWidget/BundleLimitContainer';
import { isEnabledPurchaseLimitToContainer } from '~/components/BundleLimitWidget/settings';
import BundleType from '~/components/BundleType/BundleType';
import PurchaseButton from '~/components/Button/PurchaseButton';
import GlowEffectDecorator from '~/components/GlowEffect/GlowEffectDecorator';
import { POPUPS_NAME } from '~/components/PopupManager';
import PriceWrapper from '~/components/PriceWrapper/PriceWrapper';
import Purchased from '~/components/Purchased/Purchased';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import HoverVideo from '~/customization/HoverVideo/HoverVideo';
import LabelDecorator, { LABEL_TYPE } from '~/decorators/LabelDecorator/LabelDecorator';
import { getAmountString } from '~/Layouts/BundlePage/helpers';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import { isAvailablePreviewAmountOnBundleCard, isFreeBundle } from '~/utils/bundles';
import { openBundleFromCurrentPage } from '~/utils/category';
import { isAllowedNewLabelFromBundle } from '~/utils/labels';
import { t } from '~/utils/localization';
import { getSerialPurchaseBlockedLabel } from '~/utils/purchase';
import ShortBundleContains from '~/components/Bundle/Contains/ShortBundleContains';
import BundleDecoration from '~/components/Bundle/Decoration/BundleDecoration';
import styles from './SerialBundleCarouselItem.scss';
import Link, { LinkColors } from '~/components/Link/Link';
import SerialBundleIndex from '~/components/SerialBundleIndex/SerialBundleIndex';
import OfferMark from '~/components/OfferMark/OfferMark';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { playCardClickSound } from '~/api/WoWsClient';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface ISerialBundleCarouselItem {
    bundle: IBundle;
    serialSequence: number[];
    goToNextAvailableBundle: (index: number) => void;
}

const stateSelector = (state: RootState) => {
    return {
        accountId: state.account.id,
        purchasedLimitedBundles: state.account.purchasedLimitedBundles,
        deniedBundlesByUniqueItems: state.account.deniedBundlesByUniqueItems,
        currentPage: state.app.currentPage,
        bundlesInTransaction: state.account.bundlesInTransaction,
        viewedLabels: state.account.viewedLabels,
        isTrusted: state.app.isTrusted,
    };
};

const SerialBundleCarouselItem = ({ bundle, serialSequence, goToNextAvailableBundle }: ISerialBundleCarouselItem) => {
    const dispatch = useDispatch();
    const { purchasedLimitedBundles, currentPage, accountId, bundlesInTransaction, isTrusted } = useAppSelector(stateSelector, equal);
    const isFree = isFreeBundle(bundle);
    const goToBundle = () => {
        playCardClickSound();
        openBundleFromCurrentPage(currentPage?.name, bundle);
    };
    const isAllowedLabel = isAllowedNewLabelFromBundle(bundle);
    const isDisabledPurchase = Account.isDisabledSerialBundle(purchasedLimitedBundles, bundle);
    const _isEnabledPurchaseLimitToContainer = isEnabledPurchaseLimitToContainer(bundle);
    const nextAvailableFromPurchaseSerialBundle = Account.getNextAvailableFromPurchaseSerialBundle(purchasedLimitedBundles, serialSequence);
    const amountText = isAvailablePreviewAmountOnBundleCard(bundle.primaryItem?.type) && bundle.primaryItem?.amount > 1 && getAmountString(bundle.primaryItem?.amount, '<b>', '</b>');
    const isActiveTransaction = serialSequence.some((id) => bundlesInTransaction?.includes(id));

    const classesItem = classNames(
        styles.item,
        ...bundle.decoration,
        {
            [styles.purchased]: bundle.isPurchased,
            [styles.isMobile]: isMobileOrTabletWindow,
        },
        'armory__auto--bundle_serial_item',
    );

    const goToNextBundle = (event: React.MouseEvent) => {
        event.stopPropagation();

        if (nextAvailableFromPurchaseSerialBundle) {
            goToNextAvailableBundle(nextAvailableFromPurchaseSerialBundle || serialSequence?.[0]);
        }
    };

    const purchaseProcessor = new PurchaseProcessor(bundle);
    const purchase = (event: React.MouseEvent) => {
        event.stopPropagation();

        if (!purchaseProcessor.isPurchaseAvailable()) {
            return;
        }

        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.CONFIRM_PURCHASE,
                isVisible: !isFree,
                data: { bundleId: bundle.id },
            }),
        );

        if (isFree) {
            purchaseProcessor.purchase(bundle);
        }
    };

    const hasTimer = bundle.promoTimerActiveTill && bundle.showCountdown;
    const hasPromoLabel = bundle.promoLabel;

    let style: React.CSSProperties = {};
    if (bundle.bundleContentUnderlayColor) {
        style = Object.assign(style, { '--underlay-color': bundle.bundleContentUnderlayColor });
    }

    return (
        <LabelDecorator id={bundle.name} labelTill={bundle.labelNewActiveTill} isEnabled={isAllowedLabel} byHover type={LABEL_TYPE.bundle} className={styles.itemWrapper}>
            <GlowEffectDecorator isEnabled={false}>
                <BundleDecoration bundle={bundle}>
                    <LabelContainer className={styles.label} isVisible={isAllowedLabel} />
                    <HoverVideo
                        video={bundle.bundleCardHoverVideo}
                        className={classesItem}
                        onClick={goToBundle}
                        data-id={bundle.id}
                        isDisableAnimation={bundle.isPurchased}
                        data-bundle-index={bundle.serialIndex + 1}
                        muted={!isTrusted}
                        parentClassName={styles.itemWrapper}
                        style={style}
                    >
                        <img src={bundle.icons.big} className={styles.img} alt={bundle.title} />
                        <div className={styles.content}>
                            <div className={styles.header}>
                                <div className={styles.title}>
                                    {!bundle.primaryItem || bundle.primaryItem?.type !== items.VEHICLES || bundle.denyTitleModification ? (
                                        <div dangerouslySetInnerHTML={{ __html: bundle.title }} className={classNames(styles.entityWrapper)} />
                                    ) : (
                                        <WoWSEntity
                                            key={`${bundle.categories.join('_')}_${bundle.primaryItem?.identifier || bundle.id}`}
                                            type={bundle.primaryItem.type}
                                            id={bundle.primaryItem.identifier}
                                            className={styles.entityWrapper}
                                            presentation={{ renderWithoutFade: true }}
                                            fallback={(element: Element) => {
                                                element.textContent = bundle.title;
                                            }}
                                        />
                                    )}
                                    <div className={styles.additionalIcons}>
                                        {_isEnabledPurchaseLimitToContainer && <BundleLimitContainer className={styles.bundleLimitWrapper} bundle={bundle} />}
                                        <SerialBundleIndex index={bundle.serialIndex} className={styles.serialTip} total={serialSequence.length} />
                                    </div>
                                </div>
                                {bundle.primaryItem?.type && (
                                    <div className={styles.additionalInfo}>
                                        <BundleType item={bundle.primaryItem} className={styles.serialBundleInfoBlock} />
                                        {amountText && <div className={styles.serialBundleInfoBlock} dangerouslySetInnerHTML={{ __html: amountText }} />}
                                    </div>
                                )}
                                {!!bundle.entitlements.length && <ShortBundleContains className={styles.previewBundleContent} bundle={bundle} limit={3} />}
                            </div>
                            <div className={styles.footer}>
                                <div className={styles.purchaseInfo}>
                                    {!bundle.isPurchased && (hasTimer || hasPromoLabel) && (
                                        <div className={styles.labels}>
                                            {hasPromoLabel && <OfferMark label={bundle.promoLabel} className={styles.bundleLabel} />}
                                            {hasTimer && <OfferMark expiredTime={bundle.promoTimerActiveTill} className={styles.bundleLabel} />}
                                        </div>
                                    )}
                                    {bundle.isPurchased && !isDisabledPurchase ? (
                                        <div className={styles.purchasedInfo}>
                                            <div className={styles.previewBundlePurchased}>
                                                <Purchased />
                                            </div>
                                            {!!nextAvailableFromPurchaseSerialBundle && (
                                                <div className={styles.previewBundleNext}>
                                                    <Link label={t('Перейти к доступному')} onClick={goToNextBundle} color={LinkColors.white} className={styles.goToNextButtonLink} />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className={styles.priceWrapper}>
                                            <PriceWrapper bundle={bundle} discountClassName={styles.discount} useInitialPrice withoutAnimation />
                                        </div>
                                    )}
                                    {!bundle.isPurchased && (
                                        <div className={styles.buttons}>
                                            {accountId ? (
                                                !bundle.isPurchased &&
                                                !isDisabledPurchase && (
                                                    <PurchaseButton
                                                        label={isFree ? t('Получить') : isActiveTransaction ? t('Обработка') : t('Купить')}
                                                        onClick={purchase}
                                                        bundle={bundle}
                                                        isFetching={isActiveTransaction}
                                                        isDisabled={isActiveTransaction}
                                                        classNameFastGoldLink={styles.fastGoldLink}
                                                        isForceRenderPurchaseButton
                                                        buttonClassName={['armory__auto--category_button_purchase']}
                                                    />
                                                )
                                            ) : (
                                                <PurchaseButton
                                                    onClick={purchase}
                                                    bundle={bundle}
                                                    onClickOnSerialAttention={goToNextBundle}
                                                    classNameFastGoldLink={styles.fastGoldLink}
                                                    buttonClassName={['armory__auto--category_button_purchase']}
                                                />
                                            )}
                                            {isDisabledPurchase && (
                                                <div className={styles.buttonsWrapper}>
                                                    <Attention
                                                        label={getSerialPurchaseBlockedLabel(bundle.serialIndex)}
                                                        className={styles.attentionWrapper}
                                                        buttonText={!!nextAvailableFromPurchaseSerialBundle && !isMobileOrTabletWindow && t('Перейти к доступному')}
                                                        onButtonClick={goToNextBundle}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </HoverVideo>
                </BundleDecoration>
            </GlowEffectDecorator>
        </LabelDecorator>
    );
};

export default SerialBundleCarouselItem;
