import * as React from 'react';
import { useDispatch } from 'react-redux';
import { markLabelAsViewed } from '~/api/account';
import Account from '~/account/Account';
import { LocalStorageHelper } from '~/utils/storage';
import { LOCAL_STORAGE_LABELS } from '~/utils/keys';
import { armoryState } from '~/utils/settings';
import { useAppSelector } from '~/Store';
import { accountActions } from '~/Store/accountSlice';
import equal from 'fast-deep-equal/react';

interface ILabelDecorator {
    isEnabled?: boolean;
    onClick?: () => void;
    onClickDecorator?: () => void;
    byHover?: boolean;
    children: React.ReactChild | React.ReactChild[];
    className?: string;
    id?: string;
    labelTill?: string;
    isCategory?: boolean;
    type: string;
}

export const getLocalStorageLabelsKey = () => LOCAL_STORAGE_LABELS.split('{}').join(armoryState?.account?.id?.toString());

export const saveLabelToLocalStorage = (name: string) => {
    const key = getLocalStorageLabelsKey();
    let data = LocalStorageHelper.get(key);

    if (data) {
        data.names.push(name);
    } else {
        data = {
            names: [name],
        };
    }

    LocalStorageHelper.set(key, data);
};

export const LABEL_TYPE = {
    bundle: 'bundles',
    category: 'categories',
    coupon: 'coupon',
};

const LabelDecorator = (props: ILabelDecorator) => {
    const currentPage = useAppSelector((state) => state.app.currentPage, equal);
    const [isAlreadyViewed, setFlag] = React.useState<boolean>(false);
    const dispatch = useDispatch();

    const markLabel = () => {
        props.onClickDecorator?.();

        if (!props.id) {
            return;
        }

        if (!props.isEnabled) {
            return;
        }

        if (isAlreadyViewed) {
            return;
        }

        if (!Account.getAccount()) {
            return;
        }

        dispatch(accountActions.markLabelAsRead(props.id));

        markLabelAsViewed(props.id, props.type);

        props.onClick?.();

        setFlag(true);
    };

    const mouseEnter = () => props.byHover && markLabel();

    React.useEffect(() => {
        if (props.type === LABEL_TYPE.category && currentPage?.name === props.id) {
            markLabel();
        }
    }, [currentPage?.name]);

    return (
        <div onClick={markLabel} onMouseEnter={mouseEnter.bind(this)} className={props.className}>
            {props.children}
        </div>
    );
};

export default React.memo(LabelDecorator);
