import * as React from 'react';
import styles from '../Port.scss';
import AbstractPort from '~/components/Port/AbstractPort';
import PurchaseWrapper from '~/components/PurchaseWrapper/PurchaseWrapper';
import PurchaseButton from '~/components/Button/PurchaseButton';
import { ManagerData } from '~/core/ManagerData';
import { isEnoughCurrencyWithBestCoupon } from '~/utils/purchase';
import { BundlePurchaseTypes } from '~/types/bundle';

export default class BundlePort extends AbstractPort {
    protected isAvailableContainsWidget = this.state.containsItems?.length > 1;

    protected renderTitle(): React.ReactChild {
        return <div className={styles.title} dangerouslySetInnerHTML={{ __html: this.props.bundle.title }} />;
    }

    protected getCamoTitle(): string {
        if (this.props.exteriorId < 0) {
            return null;
        }

        const camoTitle = ManagerData.getPermoflage(this.props.exteriorId)?.title || ManagerData.getItem(this.props.exteriorId)?.title;
        return camoTitle || this.props.bundle.title;
    }

    protected renderFooterContent(): React.ReactChild {
        if (this.props.isHiddenPurchaseButton || this.props.bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY) {
            return super.renderFooterContent();
        }

        const isEnoughCurrencyWithCoupon = isEnoughCurrencyWithBestCoupon(this.props.balance, this.props.bundle, this.props.coupons);

        return (
            <div className={styles.footer}>
                <div className={styles.price}>
                    <PurchaseWrapper bundle={this.props.bundle} withoutCoupon={true} withoutAnimation={true} useInitialPrice={true} showExchangePrices={true} withoutQuantityWidget={true} />
                </div>
                {!this.props.isDisabledPurchase && (
                    <div className={styles.purchaseFlexWrapper}>
                        <PurchaseButton
                            bundle={this.props.bundle}
                            onClick={this.props.onPurchaseBundle}
                            buttonClassName={styles.purchaseButton}
                            classNameFastGoldLink={styles.fastGoldLink}
                            isForceRenderPurchaseButton={isEnoughCurrencyWithCoupon}
                        />
                    </div>
                )}
            </div>
        );
    }
}
