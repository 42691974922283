import * as React from 'react';
import classNames from 'classnames';
import Spoiler from '~/components/Spoiler/Spoiler';
import styles from '../common.scss';
import { t } from '~/utils/localization';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import MainFeaturesLayout from '~/Layouts/Features/Views/MainFeaturesLayout/MainFeaturesLayout';
import FeaturesLayout from '~/Layouts/Features/Views/FeaturesLayout/FeaturesLayout';
import equal from 'fast-deep-equal/react';
import ShipConsumables from '~/components/ShipConsumables/ShipConsumables';
import { RootState, useAppSelector } from '~/Store';

interface IShipsContent {
    item: IItemCommonData;
    renderSpoiler?: boolean;
    renderFeatures?: boolean;
}

export const MainFeatures = ({ shipId, className }: { shipId: number; className?: string }) => {
    const shipFeatureMap = useAppSelector((state) => state.features.shipFeatureMap, equal);
    const shipFeatures = shipFeatureMap[shipId];
    if (!shipFeatures) {
        return null;
    }

    return (
        <div className={classNames(styles.mainFeatures, className)}>
            <div className={styles.contentBlockTitle}>{t('Ключевые особенности корабля:')}</div>
            <MainFeaturesLayout shipId={shipId} className={styles.mainFeatureLine} detailedFeatures={true} />
        </div>
    );
};

export const AdditionalFeatures = ({ shipId, className }: { shipId: number; className?: string }) => {
    const shipFeatureMap = useAppSelector((state) => state.features.shipFeatureMap, equal);
    const shipFeatures = shipFeatureMap[shipId];
    if (!shipFeatures?.categories?.length) {
        return null;
    }

    return (
        <div className={classNames(styles.contentBlock, styles.borderBottom, className)}>
            <div className={styles.contentBlockTitle}>{t('Полный список особенностей корабля:')}</div>
            <div className={styles.contentBlockSubTitle}>{t('Основные отличия от кораблей того же класса и уровня')}</div>
            <FeaturesLayout shipId={shipId} />
        </div>
    );
};

const ShipsContent = (props: IShipsContent) => {
    const classesContent = classNames({
        [styles.contentAdditionalBlock]: props.renderSpoiler,
    });
    return (
        <div className={classesContent}>
            {props.renderSpoiler ? (
                <Spoiler
                    title={
                        <div className={styles.contentSpoilerTitle}>
                            <WoWSEntity key={props.item.identifier} type={props.item.type} id={props.item.identifier} />
                        </div>
                    }
                    content={
                        <React.Fragment>
                            <MainFeatures shipId={props.item.identifier} />
                            <AdditionalFeatures shipId={props.item.identifier} />
                            <ShipConsumables shipId={props.item.identifier} />
                        </React.Fragment>
                    }
                />
            ) : (
                <React.Fragment>
                    {props.renderFeatures && (
                        <React.Fragment>
                            <MainFeatures shipId={props.item.identifier} />
                            <AdditionalFeatures shipId={props.item.identifier} />
                        </React.Fragment>
                    )}
                    <ShipConsumables shipId={props.item.identifier} />
                </React.Fragment>
            )}
        </div>
    );
};

export default ShipsContent;
