import * as React from 'react';
import styles from './Tooltip.scss';
import classNames from 'classnames';
import { getCurrency } from '~/utils/currencies';
import { t } from '~/utils/localization';
import Currency from '~/components/Currency/Currency';
import { arrayToObjectByKey } from '~/utils/utils';
import equal from 'fast-deep-equal/react';
import { useAppSelector } from '~/Store';

interface ICurrencyTooltip {
    currency: string;
    isVisibleTitle?: boolean;
}

const CurrencyTooltip = (props: ICurrencyTooltip): React.ReactChild | any => {
    const showcaseCurrencies = arrayToObjectByKey(window.metashop.state.content.currencies, 'name');
    const balance = useAppSelector((state) => state.account.balance, equal);
    const currencyData = getCurrency(props.currency);
    const total = balance?.[props.currency]?.value;
    const paidPart = balance?.[props.currency]?.paidPart;
    const isNeedToShowFooter = total > 0 && paidPart > 0;

    const isNeedToShowDetails = !showcaseCurrencies[props.currency]?.description && currencyData.tooltipGetDescription && currencyData.tooltipDescription;

    const TooltipTitle = <div className={styles.defaultTooltipHeader}>{currencyData.title}</div>;

    const TooltipTitleWithIcon = (
        <div className={styles.headerWithIcon}>
            <div className={styles.headerTooltipIcon} style={{ backgroundImage: `url(${currencyData.icons.large})` }}></div>
            <div className={styles.headerTitle}>{currencyData.title}</div>
        </div>
    );

    return (
        <div className={styles.defaultTooltip}>
            {props.isVisibleTitle && currencyData.icons?.large ? TooltipTitleWithIcon : TooltipTitle}
            {isNeedToShowDetails ? (
                <>
                    {currencyData.tooltipStatusInfo && (
                        <>
                            <div className={styles.defaultTooltipBody}>{currencyData.tooltipStatusInfo}</div>
                            <div className={styles.border} />
                        </>
                    )}
                    <div className={styles.defaultTooltipBody}>
                        <div className={styles.defaultTooltipDescriptionTitle}>{t('На что потратить')}</div>
                        {currencyData.tooltipDescription}
                    </div>
                    <div className={styles.border} />
                    <div className={styles.defaultTooltipBody}>
                        <div className={styles.defaultTooltipDescriptionTitle}>{t('Как получить')}</div>
                        {currencyData.tooltipGetDescription}
                    </div>
                </>
            ) : (
                <div className={styles.defaultTooltipBody} dangerouslySetInnerHTML={{ __html: currencyData.description }} />
            )}
            {isNeedToShowFooter && (
                <div className={classNames(styles.border, styles.footerText)}>
                    <div className={styles.currencyItem}>
                        <div className={styles.title}>{t('Куплено за реальные деньги:')}</div>
                        <div className={styles.delimiter}>&nbsp;</div>
                        <div className={styles.currencyAmount}>
                            <Currency currency={props.currency} amount={paidPart} withoutAnimation withoutTooltip />
                        </div>
                    </div>
                    <div className={styles.currencyItem}>
                        <div className={styles.title}>{t('Заработано в игре:')}</div>
                        <div className={styles.delimiter}>&nbsp;</div>
                        <div className={styles.currencyAmount}>
                            <Currency currency={props.currency} amount={total - paidPart} withoutAnimation withoutTooltip />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CurrencyTooltip;
