import styles from '~/Layouts/Themes/SantaPage/SantaPage.scss';
import { shallowEqual } from 'react-redux';
import classNames from 'classnames';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { SANTA_VIDEO_BACKGROUNDS, isGoldShowcase } from '~/Layouts/Themes/SantaPage/settings';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { useAvailableSound } from '~/hooks/useAvailableSound';
import isDisabledAnimation from '~/hooks/isDisabledAnimation';
import { SANTA_PAGE_THEME } from '../ThemeManager';
import { RootState, useAppSelector } from '~/Store';

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        categories: state.app.categories,
        categoriesAnimationStatuses: state.app.categoriesAnimationStatuses,
        isTrusted: state.app.isTrusted,
    };
};

const SantaBackground = () => {
    const state = useAppSelector(stateSelector, shallowEqual);
    const { isBundlePage } = state.currentPage || {};
    const _isDisabledAnimation = isDisabledAnimation(SANTA_PAGE_THEME);
    const _isGoldShowcase = isGoldShowcase(state.currentPage?.name);
    const isAvailableSoundEffect = useAvailableSound(state.categories[state.currentPage?.name], null);

    const classesBackground = classNames(styles.background, {
        [styles.real]: !_isGoldShowcase,
    });

    const classesWrapper = classNames(styles.backgroundWrapper, {
        [styles.real]: !_isGoldShowcase,
    });

    if (isBundlePage) {
        if (isMobileOrTabletWindow) {
            return (
                <div className={styles.backgroundWrapper}>
                    <div className={classesBackground}></div>
                </div>
            );
        }

        return null;
    }

    const video = SANTA_VIDEO_BACKGROUNDS[state.currentPage?.name];

    let muted = !isAvailableSoundEffect;
    if (!state.isTrusted) {
        muted = true;
    }

    return (
        <div className={classNames(classesWrapper)}>
            {isMobileOrTabletWindow || _isDisabledAnimation ? (
                <div className={classesBackground}>
                    <div className={styles.videoBackgroundMask}></div>
                </div>
            ) : (
                <VideoBackground
                    poster={null}
                    video={video}
                    muted={muted}
                    className={styles.videoBackgroundWrapper}
                    volume={0.5}
                    posterClassName={classNames(styles.background, {
                        [styles.real]: !_isGoldShowcase,
                    })}
                    videoClassName={styles.videoBackground}
                >
                    <div className={styles.videoBackgroundMask}></div>
                </VideoBackground>
            )}
        </div>
    );
};

export default SantaBackground;
