import { detectPlatform } from '@wg/wows-commerce';
import equal from 'fast-deep-equal/react';
import * as React from 'react';
import { logInfo } from '~/utils/logging';
import { interpolate, t } from '~/utils/localization';

import styles from './AccountCompletion.scss';
import { ItemTypes } from '@wg/wows-entities/const';
import { WoWSEntities } from '@wg/wows-entities';
import { completeAccount } from '@wg/web2clientapi/browser/completeAccount';
import { IVReward } from '@wg/wows-entities/types/vortex';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { useAppSelector } from '~/Store';
import Button, { ButtonVariant } from '~/components/Button/Button';

interface AccountCompletionRewardItemsState {
    rewards: IVReward[];
}

const AccountCompletion = () => {
    const wowsCommerce = useAppSelector((state) => state.wsmart.wowsCommerce, equal);
    const [isAccountCompletionAvailable, setIsAccountCompletionAvailable] = React.useState<boolean>(false);
    const [accountCompletionRewardItemsState, SetAccountCompletionRewardItemsState] = React.useState<AccountCompletionRewardItemsState>({
        rewards: [],
    });

    React.useEffect(() => {
        const platform = detectPlatform();
        const loadInfo = async () => {
            const accountRewards = await WoWSEntities.get().getStorage().getAccountCompletionRewards(platform);
            if (accountRewards) {
                SetAccountCompletionRewardItemsState({
                    ...accountCompletionRewardItemsState,
                    rewards: accountRewards,
                });
            }
        };
        loadInfo();

        async function isNeedShowAccountCompletionMessage() {
            const result = await wowsCommerce.needShowAccountCompletionMessage();
            setIsAccountCompletionAvailable(result);
        }
        isNeedShowAccountCompletionMessage();
    }, []);

    const localizationStr = interpolate(
        t('Возможны проблемы с оплатой. Чтобы их избежать,&#160;{openLink}создайте аккаунт Wargaming{closeLink}&#160;с сохранением прогресса и имущества. В качестве подарка вы получите:'),
        { openLink: '', closeLink: '' },
    );

    function accountCompleted() {
        logInfo('call  accountCompleted');
        setIsAccountCompletionAvailable(false);
    }
    async function callCompleteAccountWindow(event?: React.MouseEvent) {
        logInfo('Start request to completeAccount...');
        completeAccount();
        logInfo('Success send request to completeAccount');

        await wowsCommerce.startCheckAccountCompletion(accountCompleted);
    }

    if (!isAccountCompletionAvailable) {
        return null;
    }
    return (
        <React.Fragment>
            <div className={styles.accountCompletionAvailable}>
                <div className={styles.accountCompletionBody}>
                    <div className={styles.accountCompletionTitle}>{t('Создайте аккаунт и получите награды')}</div>
                    <div className={styles.accountCompletionDescription}>{localizationStr}</div>
                    <span className={styles.accountCompletionRewardsSpan}>
                        <div className={styles.accountCompletionRewards}>
                            {accountCompletionRewardItemsState.rewards.map((reward) => {
                                return (
                                    <WoWSEntity
                                        key={reward.id}
                                        id={reward.id}
                                        type={reward.type}
                                        amount={reward.amount}
                                        className={reward.type === ItemTypes.LOOTBOX ? styles.lootboxReward : styles.rewardStyle}
                                        presentation={{ withTooltip: false, iconPosition: 'left', withText: true }}
                                        customisation={{
                                            shipId: reward.shipId,
                                            points: reward.crewLevel,
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </span>
                    <div className={styles.createAccount}>
                        <Button variant={ButtonVariant.orange} onClick={callCompleteAccountWindow} label={t('СОЗДАТЬ АККАУНТ')} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AccountCompletion;
