import * as React from 'react';
import { COMPENSATION_TEXT_PLACEHOLDER, openUrl, settings } from '~/utils/settings';
import { interpolate, t } from '~/utils/localization';
import { isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import md_parser from '@wg/text-editor/md_parser';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import { items as ITEM_TYPES } from '@wg/wows-entities/const';
import { parseLootboxRulesPlaceholder } from '~/Layouts/Lootboxes/utils';
import { isAvailableScrollableView } from '~/utils/bundles';
import { openExternalUrl } from '@wg/web2clientapi/browser/openExternalUrl';
import { BUNDLE_DECORATION } from '~/types/bundle';
import { ManagerData } from '~/core/ManagerData';

export const getCompensationText = () => {
    if (!settings.urls.pageAboutGameMechanicsUrl) {
        return '';
    }

    const text = interpolate(t('Подробнее о правилах компенсации, возможности применения балансных изменений и механизме работы контейнеров вы можете прочитать {open_tag}здесь{close_tag}.'), {
        open_tag: `<a class="external-link" href="${settings.urls.pageAboutGameMechanicsUrl}" target="_blank"><span>`,
        close_tag: `</span></a>`,
    });

    return `<span>${text}</span>`;
};

export const getAboutLootboxText = () => {
    const pageAboutGameMechanicsUrl = settings.urls.pageAboutGameMechanicsUrl;
    if (!pageAboutGameMechanicsUrl) {
        return;
    }
    return interpolate(t('Подробнее о правилах компенсации, возможности применения балансных изменений и механизме работы контейнеров вы можете прочитать {open_tag}здесь{close_tag}.'), {
        open_tag: `<a class="external-link with-border" href="${pageAboutGameMechanicsUrl}" target="_blank"><span>`,
        close_tag: `</span></a>`,
    });
};

export const parsePlaceholderInBundleDescription = (description: string): string => {
    description = description.split(COMPENSATION_TEXT_PLACEHOLDER).join(getCompensationText());
    description = description.split('<br>').join('');
    description = parseLootboxRulesPlaceholder(description);
    description = description.split('[nowrap]').join('<span class="armory-global_description_nowrap">');
    description = description.split('[nowrap-end]').join('</span>');

    return md_parser(description);
};

export const clickByLinkInDescription = (event: React.MouseEvent) => {
    let target = event.target as HTMLElement;

    if (target.tagName.toLowerCase() === 'span') {
        target = target.parentNode as HTMLElement;
    }

    if (target.getAttribute('href') === settings.urls.pageAboutGameMechanicsUrl) {
        event.preventDefault();
        if (isInGameBrowser) {
            openExternalUrl(settings.urls.pageAboutGameMechanicsUrl);
            return;
        }
    }

    const href = target.getAttribute('href');
    if (!href) {
        return;
    }

    openUrl(href);
};

export const getAmountString = (amount: number, tag: string, closeTag: string): string => {
    return interpolate(t('Количество: {tag}{amount}{close_tag}'), {
        amount: amount,
        tag: tag,
        close_tag: closeTag,
    });
};

export const getFullAmountString = (amount: number, tag: string, closeTag: string): string => {
    return interpolate(t('Количество наборов: {tag}{amount}{close_tag}'), {
        amount: amount,
        tag: tag,
        close_tag: closeTag,
    });
};

export const getIdFromButtonToPort = (bundle: IBundle): GUIDE_NAMES[] => {
    const primaryItem = bundle.primaryItem;

    if (primaryItem?.type === ITEM_TYPES.VEHICLES) {
        return [GUIDE_NAMES.guide_ship_port];
    }

    return [GUIDE_NAMES.guide_camouflage_port];
};

export function isScrollableTemplate(bundle: IBundle) {
    return !isMobileOrTabletWindow && isAvailableScrollableView(bundle);
}

export function isAvailableBigArt(bundle: IBundle) {
    return bundle?.decoration?.includes(BUNDLE_DECORATION.BUNDLE_BIG_ICON);
}

export function isHiddenBundleImage(bundle: IBundle) {
    return !isMobileOrTabletWindow && bundle?.decoration?.includes(BUNDLE_DECORATION.HIDE_BUNDLE_IMAGE);
}

export const getAvailableTypesForAdditionalContent = () => {
    return [ITEM_TYPES.CREWS, ITEM_TYPES.VEHICLES, ITEM_TYPES.LOOTBOX] as string[];
};

export const isAvailableAdditionalContentForType = (type: string, id: number) => {
    const isAvailable = getAvailableTypesForAdditionalContent().includes(type);

    if (isAvailable && type === ITEM_TYPES.CREWS) {
        const crew = ManagerData.getCrew(id) || {};
        const isEmpty = !crew.talents?.length && !crew.skills?.length && !crew?.unlocks?.length;
        return !isEmpty;
    }

    return isAvailable;
};
