import store from '~/Store';
import { isDisplayRestricted } from '~/utils/bundles';
import { openCategoryByName } from '~/utils/category';
import History from '~/utils/history';
import { settings } from '~/utils/settings';
import { parseFiltersInQueryParams } from '~/utils/filters';

export const getUnreadNotifications = () => {
    const accountState = store.getState().account;
    const arrayNotifications = accountState.notifications;

    if (!Array.isArray(arrayNotifications)) {
        return null;
    }

    return arrayNotifications.reduce((count: number, notification: INotification) => {
        if (!(accountState.viewedNotifications || []).includes(notification.name)) {
            count++;
        }
        return count;
    }, 0);
};

export const prepareNotifications = (_notifications: INotification[], categories: ICategories, bundles: IBundleList): INotification[] => {
    return [..._notifications]
        .filter((notification) => {
            if (isDisplayRestricted(notification)) {
                return false;
            }
            const categoryName = notification.redirectToCategory as ICategoryList;
            if (!categoryName?.length) {
                return true;
            }
            const category = categories[categoryName];
            if (category?.parentCategory) {
                const parentCategory = categories[category.parentCategory as ICategoryList];
                return parentCategory && category;
            }
            return !!category;
        })
        .sort((a: INotification, b: INotification) => {
            const firstDateCreated = new Date(a.dateCreated).getTime();
            const secondDateCreated = new Date(b.dateCreated).getTime();
            if (firstDateCreated > secondDateCreated) {
                return -1;
            } else if (firstDateCreated < secondDateCreated) {
                return 1;
            } else {
                return 0;
            }
        });
};

export const redirectTo = (notification: INotification) => {
    if (notification.redirectToCategory) {
        return openCategoryByName(notification.redirectToCategory);
    }

    if (notification.linkHref) {
        History.navigate(`/${settings.languageCode}${notification.linkHref}`);
        parseFiltersInQueryParams();
        return;
    }
};
