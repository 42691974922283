import RandomBundle from '~/components/Bundle/Random/RandomBundle';
import LazyRenderingBundle from '~/components/LazyRendering/LazyRenderingBundle';
import { ErrorBoundary } from '@sentry/react';
import SerialBundleCarousel from '~/components/Bundle/Serial/SerialBundleCarousel';
import LazyGachaBannerContainer from '~/Layouts/GachaBanner/Container/LazyGachaBannerContainer';
import { BUNDLE_TYPES } from '~/types/bundle';
import BundleSkeleton from './Skeleton/BundleSkeleton';

export interface IBundleManager {
    bundle: IBundle;
    className?: string;
    image?: string;
    width?: string;
    isLazy?: boolean;
    preset?: ICategoryPresets;
    disableRedirectToBundle?: boolean;
}

const BundleManager = (props: IBundleManager): any => {
    switch (props.bundle.type) {
        case BUNDLE_TYPES.mystery:
            return (
                <ErrorBoundary>
                    <LazyGachaBannerContainer key={`gacha_banner_${props.bundle.id}`} bundle={props.bundle} />
                </ErrorBoundary>
            );

        case BUNDLE_TYPES.serial:
            return (
                <ErrorBoundary>
                    <SerialBundleCarousel key={`bundle_${props.bundle.id}_${props.preset?.name || 'no_preset'}`} bundle={props.bundle} {...props} />
                </ErrorBoundary>
            );

        case BUNDLE_TYPES.random:
        case BUNDLE_TYPES.periodic:
            return (
                <ErrorBoundary>
                    <RandomBundle key={`bundle_${props.bundle.id}_${props.preset?.name || 'no_preset'}`} bundle={props.bundle} width={props.width} {...props} />
                </ErrorBoundary>
            );

        default:
            return (
                <ErrorBoundary>
                    <BundleSkeleton bundle={props.bundle}>
                        <LazyRenderingBundle {...props} />
                    </BundleSkeleton>
                </ErrorBoundary>
            );
    }
};

export default BundleManager;
