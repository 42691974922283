import { settings } from '~/utils/settings';
import { items } from '@wg/wows-entities/const';
import { getSumFromArray } from '~/utils/number';
import { IBundleLimitInfo } from '~/components/BundleLimitWidget/BundleLimitWidget';
import { ManagerData } from '~/core/ManagerData';
import { isFreeBundle } from '~/utils/bundles';

export const isEnabledContainerPurchaseLimit = () => {
    return settings.lootboxesPurchaseLimit?.enabled;
};

export const getLimitedCurrencies = () => {
    return settings.lootboxesPurchaseLimit?.limitedCurrencies || [];
};

export const getTotalLimit = () => {
    return settings.lootboxesPurchaseLimit?.limitTotal;
};

export const getLimitPerType = () => {
    return settings.lootboxesPurchaseLimit?.limitPerType;
};

export const getExpireTime = () => {
    const [hour, minutes, seconds] = settings.lootboxesPurchaseLimit?.expireTime || [];
    if (typeof hour === 'undefined') {
        return null;
    }

    const diffHours = new Date().getTimezoneOffset() / 60;
    const now = Date.now();
    const expireLocalDate = new Date();

    expireLocalDate.setHours(hour - diffHours);
    expireLocalDate.setMinutes(minutes);
    expireLocalDate.setSeconds(seconds);

    if (now > expireLocalDate.getTime()) {
        return expireLocalDate.getTime() + 86400000;
    }

    return expireLocalDate.getTime();
};

const limitedItemsTypes: string[] = [items.LOOTBOX];
export const isEnabledPurchaseLimitToContainer = (bundle: IBundle) => {
    if (bundle && isFreeBundle(bundle)) {
        return false;
    }

    const limitedCurrencies = getLimitedCurrencies();
    const isCurrencyLimit = limitedCurrencies?.includes(bundle.currency) || limitedCurrencies?.includes(bundle.additionalCurrency);
    const isItemTypeLimit = bundle?.entitlements?.some((item) => limitedItemsTypes.includes(item.type));

    return isCurrencyLimit && isItemTypeLimit;
};

export const hasRestrictionPurchaseForRandomBundle = (bundle: IBundle) => {
    if (isEnabledPurchaseLimitToContainer(bundle)) {
        return true;
    }

    const limitedCurrencies = getLimitedCurrencies();
    const isCurrencyLimit = limitedCurrencies?.includes?.(bundle.currency) || limitedCurrencies?.includes?.(bundle.additionalCurrency);

    return (
        isCurrencyLimit &&
        bundle.randomBundleChildren?.some((child) => {
            return child?.entitlements?.some((item) => limitedItemsTypes.includes(item.type));
        })
    );
};

export const getLootboxesMap = (bundlesObject: IBundleList) => {
    const bundles = Object.values(bundlesObject) as IBundle[];
    return bundles.reduce((state: ILootboxesBundlesMap, bundle) => {
        if (!isEnabledPurchaseLimitToContainer(bundle)) {
            return state;
        }
        bundle.entitlements?.forEach((item) => {
            if (item.type !== items.LOOTBOX) {
                return;
            }
            if (!state[item.identifier]) {
                state[item.identifier] = [];
            }
            if (!state[item.identifier].includes(bundle.id)) {
                state[item.identifier].push(bundle.id);
            }
        });
        return state;
    }, {});
};

export const getTotalSumContainersInsideBundle = (bundle: IBundle): number => {
    return (
        bundle?.entitlements?.reduce((sum: number, item) => {
            if (item.type === items.LOOTBOX) {
                sum += item.amount;
            }
            return sum;
        }, 0) || 0
    );
};

export enum REASON_FAIL_PURCHASE_CONTAINER {
    ACHIEVED_TOTAL_LIMIT = -1,
    ACHIEVED_PER_TYPE_LIMIT = -2,
    ACHIEVED_PER_LIMIT_AFTER_PURCHASE = -3,
    ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE = -4,
}

export const canBoughtContainer = (bundle: IBundle, restrictedLootboxesPurchaseCount?: AccountRestrictedLootboxesPurchaseCount): boolean | REASON_FAIL_PURCHASE_CONTAINER => {
    if (!isEnabledPurchaseLimitToContainer(bundle)) {
        return true;
    }

    const totalLimit = getTotalLimit();
    const limitPerType = getLimitPerType();

    const quantity = bundle?.quantityData?.quantity || 1;
    const alreadyPurchasedContainersCount = getSumFromArray(Object.values(restrictedLootboxesPurchaseCount || [])) || 0;
    const totalSumContainersInsideBundle = getTotalSumContainersInsideBundle(bundle);

    if (alreadyPurchasedContainersCount >= totalLimit) {
        return REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT;
    }

    if (totalSumContainersInsideBundle > totalLimit) {
        return REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE;
    }

    for (const i in bundle.entitlements) {
        const item = bundle.entitlements[i];

        if (item.type !== items.LOOTBOX) {
            continue;
        }

        const alreadyPurchasedContainerCount = restrictedLootboxesPurchaseCount?.[item.identifier] || 0;

        if (alreadyPurchasedContainerCount >= limitPerType) {
            return REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_PER_TYPE_LIMIT;
        }

        const amount = quantity * item.amount;
        const isExceedPerLimit = amount + alreadyPurchasedContainerCount > limitPerType;
        const isExceedTotalLimit = amount > totalLimit || amount + alreadyPurchasedContainersCount > totalLimit;

        if (isExceedTotalLimit) {
            return REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE;
        }

        if (isExceedPerLimit) {
            return REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_PER_LIMIT_AFTER_PURCHASE;
        }
    }

    return true;
};

export const getContainerIdsFromBundle = (bundle: IBundle): number[] => {
    return bundle?.entitlements
        ?.map((item) => {
            if (item.type === items.LOOTBOX) {
                return item.identifier;
            }
        })
        .filter((id) => !!id);
};

export const getContainerInfoFromTooltip = (bundle: IBundle, restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount): IBundleLimitInfo[] => {
    const containerIds = getContainerIdsFromBundle(bundle);
    return containerIds.reduce((result: IBundleLimitInfo[], containerId: number) => {
        const container = ManagerData.getLootbox(containerId);
        if (container && restrictedLootboxesPurchaseCount?.[containerId] > 0) {
            result.push({ amount: restrictedLootboxesPurchaseCount[containerId], title: container.shortTitle });
        }
        return result;
    }, []);
};
