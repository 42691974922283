import { isNotAvailableBundleByCategory, isParagonBundle } from '~/utils/bundles';
import { sort } from 'fast-sort';
import { clearString, normalizeString } from '~/utils/normalization';
import { BUNDLE_TYPES } from '~/types/bundle';

export const getSearchResults = (value: string, bundles: IBundleList, categories: ICategories): ISearchResultItem[] => {
    const results: ISearchResultItem[] = [];
    const normalizedValue = normalizeString(clearString((value || '').toLowerCase()));

    Object.values(bundles).forEach((bundle: IBundle) => {
        if (isNotAvailableBundleByCategory(bundle, categories)) {
            return;
        }
        if (bundle.subType === BUNDLE_TYPES.progression_reward || bundle.type === BUNDLE_TYPES.progression_reward) {
            return;
        }
        if (bundle.normalizedTitle && bundle.normalizedTitle.includes(normalizedValue)) {
            results.push({
                bundleId: bundle.id,
                category: bundle.categories[0],
                isParagonBundle: isParagonBundle(bundle),
                title: bundle.title,
                normalizedTitle: bundle.normalizedTitle,
            });
        }
    });

    return sort(results).asc((item) => item.normalizedTitle);
};
