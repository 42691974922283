import styles from './TradeInHeader.scss';
import MainFeaturesLayout from '~/Layouts/Features/Views/MainFeaturesLayout/MainFeaturesLayout';
import { t } from '~/utils/localization';
import classNames from 'classnames';
import { ITradeItem } from '~/types/tradein';
import { items } from '@wg/wows-entities/const';
import { useDispatch } from 'react-redux';
import { POPUPS_NAME } from '~/components/PopupManager';
import PortPreviewButton, { PortPreviewTemplate } from '~/components/PortPreviewButton/PortPreviewButton';
import { openPort } from '~/api/WoWsClient';
import { PortPreviewType } from '~/components/Port/settings';
import { isInGameBrowser } from '~/utils/utils';
import equal from 'fast-deep-equal/react';
import { useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import TradeInWeWidget from '../TradeInWeWidget/TradeInWeWidget';

interface ITradeInHeader {
    item: ITradeItem;
    className?: string;
}

const HeaderContent = ({ item }: ITradeInHeader) => {
    const dispatch = useDispatch();
    const shipFeatureMap = useAppSelector((state) => state.features.shipFeatureMap, equal);

    switch (item?.type) {
        case items.VEHICLES:
            const onClick = () =>
                dispatch(
                    appActions.changeVisibilityPopup({
                        name: POPUPS_NAME.ITEM_DESCRIPTION_POPUP,
                        isVisible: true,
                        data: { item },
                    }),
                );
            if (!shipFeatureMap?.[item.identifier]) {
                return null;
            }
            return (
                <div className={styles.featured}>
                    <div className={styles.featuredTitle}>{t('Ключевые особенности корабля')}</div>
                    <MainFeaturesLayout shipId={item.identifier} detailedFeatures={true} />
                    <div className={styles.additionalLink}>
                        <span onClick={onClick}>{t('Подробнее')}</span>
                    </div>
                </div>
            );
    }

    return null;
};

const getSubContent = (item: ITradeItem) => {
    switch (item?.type) {
        case items.VEHICLES:
            return (
                <>
                    {isInGameBrowser && (
                        <div className={styles.subContentBlock}>
                            <PortPreviewButton
                                template={PortPreviewTemplate.flat}
                                onClick={() => {
                                    openPort(item.identifier, -1, item.type, PortPreviewType.DEFAULT, -1);
                                }}
                            />
                        </div>
                    )}
                </>
            );
    }

    return null;
};

const TradeInHeader = ({ item, className }: ITradeInHeader) => {
    const classesContent = classNames(
        styles.content,
        {
            [styles.hidden]: !item?.identifier,
        },
        className,
    );

    return (
        <div className={classesContent}>
            <div className={styles.header}>
                <div className={styles.title}>{!!item && <TradeInWeWidget key={`tradein-item-${item.type}-${item.identifier}`} item={item} />}</div>
                <div className={styles.subContent}>{getSubContent(item)}</div>
            </div>
            {item && <HeaderContent item={item} />}
        </div>
    );
};

export default TradeInHeader;
