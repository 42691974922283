import * as React from 'react';
import styles from './BundlePageLabels.scss';
import Account from '~/account/Account';
import { getPurchaseLimit, hasUniqueItems } from '~/utils/bundles';
import OfferMark from '~/components/OfferMark/OfferMark';

interface IBundlePageLabels {
    bundle: IBundle;
}

const BundlePageLabels = ({ bundle }: IBundlePageLabels) => {
    if (bundle.isPurchased) {
        return null;
    }

    const isNeedToShowTimer = bundle.showCountdown && bundle.promoTimerActiveTill;
    const limit = Account.getAccount()?.id ? getPurchaseLimit(bundle) : bundle.limitedQuantity?.personalLimit;
    const isNeedToShowLimit = limit && !bundle.isRandom && !bundle.serialPurchase && !hasUniqueItems(bundle.entitlements);

    if (!isNeedToShowTimer && !isNeedToShowLimit && !bundle.promoLabel) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            {!!bundle.promoLabel && <OfferMark label={bundle.promoLabel} className={styles.label} />}
            {isNeedToShowTimer && <OfferMark expiredTime={bundle.promoTimerActiveTill} className={styles.label} />}
            {isNeedToShowLimit && <OfferMark limit={limit} className={styles.label} />}
        </div>
    );
};

export default BundlePageLabels;
