import Account from '~/account/Account';
import { WELCOME_GIFT_LS_KEY } from '~/const';
import { LocalStorageHelper } from './storage';

export function markShownWelcomeGiftScreen(name: string) {
    LocalStorageHelper.set(Account.getLSKey(WELCOME_GIFT_LS_KEY.replace('{}', name)), 1);
}

export function isNeedToShowWelcomeGiftScreen(name: string) {
    return !LocalStorageHelper.get(Account.getLSKey(WELCOME_GIFT_LS_KEY.replace('{}', name)));
}
