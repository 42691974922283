import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { getAmountString } from '~/Layouts/BundlePage/helpers';
import classNames from 'classnames';
import styles from '~/Layouts/BundlePage/BundlePageHeader/BundlePageHeader.scss';
import { t } from '~/utils/localization';
import { BundleLimitContainerWidget, IAdditionalContent } from '~/Layouts/BundlePage/BundlePageHeader/content';
import { InventoryCounterSize } from '~/components/InventoryCounter/InventoryCounter';
import InventoryCounterContainer from '~/containers/InventoryCounterContainer/InventoryCounterContainer';
import PityTimerContainer from '~/containers/PityTimerContainer/PityTimerContainer';
import { useAppSelector } from '~/Store';

const ContainerAdditionalHeader = ({ bundle }: IAdditionalContent) => {
    const lootboxes = useAppSelector((state) => state.lootbox.lootboxes, equal);
    const primaryItem = bundle.primaryItem;
    const lootbox = lootboxes?.[primaryItem?.identifier];
    const amount = primaryItem.amount * (bundle.quantityData?.quantity || 1);
    const text = getAmountString(amount, '<b>', '</b>');

    return (
        <React.Fragment>
            <div className={styles.flex}>
                <div className={classNames(styles.preBundleTitle)}>{lootbox?.isPremium ? t('Премиум контейнер') : t('Контейнер')}</div>
                <div className={classNames(styles.preBundleTitle)} dangerouslySetInnerHTML={{ __html: text }} />
                <PityTimerContainer
                    identifier={bundle.primaryItem?.identifier}
                    autoDescriptionSettings={bundle.autoDescriptionSettings}
                    title={bundle.title}
                    className={styles.preBundleTitle}
                    fallback={
                        <InventoryCounterContainer
                            item={primaryItem}
                            className={classNames(styles.preBundleTitle, styles.inventoryWidget, styles.inventoryWidgetContainer)}
                            size={InventoryCounterSize.FULL}
                        />
                    }
                />
            </div>
            {!bundle.serialPurchase && <BundleLimitContainerWidget bundle={bundle} wrapperClassName={styles.limitWidgetBlock} />}
        </React.Fragment>
    );
};

export default ContainerAdditionalHeader;
