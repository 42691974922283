import classNames from 'classnames';
import * as React from 'react';
import styles from '~/Layouts/Layouts.scss';
import Skeleton from '~/components/Skeleton/Skeleton';
import { getLayoutClassName } from '../Default/Bundle';

interface IBundleSkeleton {
    isLoading?: boolean;
    children: React.ReactNode;
    className?: string;
    bundle: IBundle;
    sizeInGrid?: string;
    isShipsCategory?: boolean;
}

const BundleSkeleton = ({ sizeInGrid, bundle, isLoading, children, isShipsCategory, className }: IBundleSkeleton) => {
    const classesSkeleton = classNames(styles.offerItem, getLayoutClassName(sizeInGrid, bundle, isShipsCategory), className);

    if (isLoading) {
        return (
            <div className={classesSkeleton}>
                <Skeleton />
            </div>
        );
    }

    return <>{children}</>;
};

export default BundleSkeleton;
