import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { useDispatch } from 'react-redux';
import { POPUPS_NAME } from '~/components/PopupManager';
import FastGoldLink from '~/components/FastGoldLink/FastGoldLink';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { isRealMoneyPurchaseDisabledBySettings } from '~/utils/category';
import { isChina, openUrl, settings } from '~/utils/settings';
import classNames from 'classnames';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

export enum FAST_GOLD_PLACE {
    AUCTION_BID_PLACING_POPUP = 'auction_bid_placing_popup',
    AUCTION_LOT_VIEW = 'auction_lot_view',
    BUNDLE_PURCHASE_POPUP = 'bundle_purchase_popup',
    BUNDLE_VIEW_PAGE = 'bundle_view_page',
}

interface IFastGoldLinkContainer {
    withIcon?: boolean;
    className?: string;
    place?: FAST_GOLD_PLACE;
    goldShortage: number;
    isSantaBundle?: boolean;
    groupName?: string;
}

const stateSelector = (state: RootState) => {
    return {
        accountId: state.account.id,
        products: state.wsmart.fastGoldProducts,
    };
};

const FastGoldLinkContainer = ({ withIcon, className, place, goldShortage, groupName }: IFastGoldLinkContainer) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);

    const click = (event?: React.MouseEvent) => {
        event?.stopPropagation();
        if (place) {
            dwhExport.send(DWH_EVENTS.OPEN_FAST_GOLD_POPUP, { place });
        }
        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.WSMART_DOUBLOONS_POPUP,
                isVisible: true,
                data: { goldShortage, groupName },
            }),
        );
    };

    const chinaOnClick = (event?: React.MouseEvent) => {
        event?.stopPropagation();
        openUrl(settings.urls.cnWoWsShopFastFoldUrl);
    };

    if (isChina()) {
        return <FastGoldLink onClick={chinaOnClick} className={classNames(className, 'external-link external-link_gold')} withIcon={withIcon} />;
    }

    if (!state.products?.length || isRealMoneyPurchaseDisabledBySettings()) {
        return null;
    }

    return <FastGoldLink onClick={click} className={className} withIcon={withIcon} />;
};

export default FastGoldLinkContainer;
