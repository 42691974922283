import * as React from 'react';
import Currency from '~/components/Currency/Currency';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { t } from '~/utils/localization';
import { localizedDate } from '~/utils/utils';

interface IActiveBid {
    amount: number;
    currency: string;
    placedAt?: string;
    className?: string;
    withoutTooltip?: boolean;
}

const ActiveBid = ({ amount, currency, placedAt, className, withoutTooltip }: IActiveBid) => {
    const string = placedAt ? t('Ставка сделана: {time}').replace('{time}', localizedDate(new Date(placedAt))) : null;

    return (
        <DivTooltip tooltipBody={placedAt ? <DefaultTooltip text={string} /> : null}>
            <Currency withoutAnimation withoutTooltip={withoutTooltip} currency={currency} amount={amount} className={className} />
        </DivTooltip>
    );
};

export default ActiveBid;
