import { arrayToObjectByKey, isEmptyObject } from '~/utils/utils';
import Mock from '~/core/mock';
import VortexDataStorage from '~/core/VortexDataStorage';
import { Crew, Doll, Item, LootBox, Permoflages, Vehicle, VehicleType } from '~/vortex';
import { items } from '@wg/wows-entities/const';

export enum SectionNames {
    items = 'items',
    vehicles = 'vehicles',
    crews = 'crews',
    dolls = 'dogTagComponents',
    permoflages = 'permoflages',
    lootbox = 'lootbox',
    currency = 'currency',
    class = 'class',
}

export function getSectionName(type: string) {
    switch (type) {
        case items.VEHICLES:
            return SectionNames.vehicles;
        case items.CREWS:
            return SectionNames.crews;
        case items.LOOTBOX:
            return SectionNames.lootbox;
        case items.ITEMS:
            return SectionNames.items;
        case items.DOLLS:
            return SectionNames.dolls;
        case items.PERMOFLAGES:
            return SectionNames.permoflages;
    }
}

class VortexDataManager {
    public getDataBySection(section: SectionNames, key = 'id'): any {
        const data = VortexDataStorage.getDataBySection(section);
        if (!data) {
            return Mock.getMockByType(section);
        }

        if (!Object.keys(data).length) {
            return Mock.getMockByType(section);
        }

        return arrayToObjectByKey(data as any, key) || Mock.getMockByType(section);
    }

    public getData(section: SectionNames, id: number, withoutMock = false) {
        const result = this.getDataBySection(section)?.[id];
        if (!withoutMock) {
            return result || Mock.getMockByType(section);
        }
        return result;
    }

    public getClass(name: string): Nullable<VehicleType> {
        const data = VortexDataStorage.getDataBySection(SectionNames.class) as VehicleType[];
        if (!data || isEmptyObject(data)) {
            return null;
        }
        return data.find((item: VehicleType) => item.name == name);
    }

    public getAll() {
        return VortexDataStorage.getAll();
    }

    public getItem(id: number): Item {
        return this.getData(SectionNames.items, id);
    }

    public getShip(id: number): Vehicle {
        return this.getData(SectionNames.vehicles, id);
    }

    public getCrew(id: number): Crew {
        return this.getData(SectionNames.crews, id);
    }

    public getDoll(id: number): Doll {
        return this.getData(SectionNames.dolls, id);
    }

    public getPermoflage(id: number): Permoflages {
        return this.getData(SectionNames.permoflages, id);
    }

    public getLootbox(id: number): LootBox {
        return this.getData(SectionNames.lootbox, id);
    }
}

export const ManagerData = new VortexDataManager();
