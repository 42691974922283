import * as React from 'react';
import classNames from 'classnames';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import { playButtonClickSound } from '~/api/WoWsClient';
import { KEYS_CODE } from '~/hooks/useKeyDown';
import styles from './WSMartQuantity.scss';
import { formatNumber, isMobileOrTabletWindow, toInt } from '~/utils/utils';
import { getCurrency } from '~/utils/currencies';
import { assets } from '@wg/wows-entities/const';
import { NumberFormatBase, OnValueChange } from 'react-number-format';
import Currency from '~/components/Currency/Currency';
import useForceUpdate from '~/hooks/useForceUpdate';

interface IWSMartQuantity {
    product: ProductEntity;
    fromPopup?: boolean;
    isDisabled?: boolean;
    disableInput?: boolean;
    onChangeValue?: (value: number) => void;
    onDebouncedChangeValue?: (value: number) => void;
    onInvalidQuantity?: () => void;
    showErrorMessage?: boolean;
    hideControls?: boolean;
    setRef?: (input: HTMLInputElement) => void;
}

const WSMartQuantity = (props: IWSMartQuantity): any => {
    const product = props.product;
    const maxValue = Number(product?.variablePriceSettings?.max);
    const minValue = Number(product?.variablePriceSettings?.min) || 0;
    const step = Number(product?.variablePriceSettings?.step) || 1;
    const btnStep = Number(product?.variablePriceSettings?.recommendedStep) || 100;
    const defaultValue = Number(product?.variablePriceSettings?.default) || minValue || 1;

    const [inputValue, setInputValue] = React.useState<number>(defaultValue);
    const [isShaking, setIsShaking] = React.useState<boolean>(false);
    const disableControlUp = maxValue && inputValue >= maxValue;
    const disableControlDown = inputValue <= minValue;

    const timeoutId = React.useRef<NodeJS.Timeout>(null);
    const forceUpdate = useForceUpdate();

    const data = getCurrency(assets.GOLD);
    const style: React.CSSProperties = {};
    style['backgroundImage'] = `url(${data.icons.default})`;

    React.useEffect(() => {
        setInputValue(defaultValue);
    }, [defaultValue]);

    function applyMaximum(val: number) {
        return maxValue ? Math.min(val, maxValue) : val;
    }

    function applyMinimum(val: number) {
        return Math.max(val, minValue);
    }

    function applyStep(val: number) {
        return val % step && step > 1 ? val - (val % step) + step : val;
    }

    const UpValue = () => {
        if (props.isDisabled || disableControlUp) {
            return;
        }
        playButtonClickSound();
        updateValue(applyMaximum(inputValue + btnStep));
    };

    const DownValue = () => {
        if (props.isDisabled || disableControlDown) {
            return;
        }
        playButtonClickSound();
        updateValue(applyMinimum(inputValue - btnStep));
    };

    const onKeyDown = (event?: React.KeyboardEvent<HTMLInputElement>) => {
        if ([KEYS_CODE.UP, KEYS_CODE.DOWN].includes(event.keyCode)) {
            event.preventDefault();

            if (KEYS_CODE.UP === event.keyCode) {
                UpValue();
            } else if (KEYS_CODE.DOWN === event.keyCode) {
                DownValue();
            }
        }
    };

    const updateValue = (val: number) => {
        if (props.isDisabled) {
            return;
        }

        const isValidValue = val <= maxValue && val >= minValue && val % step === 0;
        const isNew = val !== inputValue;

        if (!isNew) {
            return;
        }
        setInputValue(val);

        clearTimeout(timeoutId.current);

        if (!isValidValue) {
            props.onInvalidQuantity?.();
            return;
        }

        props.onChangeValue?.(val);
        timeoutId.current = setTimeout(() => {
            isValidValue && props.onDebouncedChangeValue?.(val);
        }, 700);
    };

    const inputClasses = classNames(styles.valueInput, styles.gold, { [styles.isShaking]: isShaking });
    const onInput: OnValueChange = (values) => {
        const currentValue = toInt(values.value);
        if (currentValue !== inputValue) {
            updateValue(applyMaximum(currentValue));
            forceUpdate();
        }
    };

    const onBlur = (event?: React.FocusEvent<HTMLInputElement>) => {
        const newVal = !inputValue || inputValue < 0 ? defaultValue : applyStep(applyMaximum(applyMinimum(inputValue)));
        if (newVal !== inputValue) {
            updateValue(newVal);
            if (!isShaking) {
                setIsShaking(true);
                setTimeout(() => setIsShaking(false), 900);
            }
        }
    };

    return (
        <div className={styles.inputWrapper}>
            <NumberFormatBase
                getInputRef={(_ref: HTMLInputElement) => {
                    if (_ref && props.setRef) {
                        props.setRef(_ref);
                    }
                }}
                value={inputValue}
                className={inputClasses}
                onValueChange={onInput}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                disabled={false}
                autoComplete={'off'}
                spellCheck={false}
                autoFocus={!isMobileOrTabletWindow}
                format={(value: string) => {
                    return formatNumber(+value);
                }}
                // @ts-ignore
                noimesupport={'true'}
            />
            {!props.hideControls ? (
                <div className={styles.controls}>
                    <div
                        onMouseDown={() => UpValue()}
                        onTouchStart={(event) => {
                            if (!isMobileOrTabletWindow) {
                                event.preventDefault();
                                event.stopPropagation();
                                UpValue();
                            }
                        }}
                        className={classNames(styles.controlUp, {
                            [styles.disabled]: disableControlUp,
                        })}
                    />
                    <div
                        onMouseDown={() => DownValue()}
                        onTouchStart={(event) => {
                            if (!isMobileOrTabletWindow) {
                                event.preventDefault();
                                event.stopPropagation();
                                DownValue();
                            }
                        }}
                        className={classNames(styles.controlDown, {
                            [styles.disabled]: disableControlDown,
                        })}
                    />
                </div>
            ) : null}

            <Currency iconClassName={styles.currencyIcon} currency={'gold'} withoutAnimation />
        </div>
    );
};

export default WSMartQuantity;
