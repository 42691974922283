import * as React from 'react';
import styles from './SignalPerks.scss';
import classNames from 'classnames';
import { t } from '~/utils/localization';

interface ISignalPerks {
    signalId: number;
}

export const SIGNAL_BATTLE_TAG = 'Battle';
export const SIGNAL_ECO_TAG = 'Eco';

const SignalPerks = (props: ISignalPerks): any => {
    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.signal, styles.icon)}>
                <span>{t('Сигнал')}</span>
            </div>
        </div>
    );
};

export default SignalPerks;
