import store from '~/Store';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { isEmptyObject } from '../utils';
import { getSupportedVideo } from '~/utils/video';
import { appActions } from '~/Store/appSlice';

export const isEnabledCategoryVideo = (category: ICategory) => {
    if (!category) {
        return false;
    }

    switch (category.theme) {
        default:
            switch (category.name) {
                default:
                    return !isEmptyObject(category?.entryVideo);
            }
    }
};

const startVideoByCategory = (category: ICategory) => {
    dwhExport.send(DWH_EVENTS.START_VIDEO_FOR_CATEGORY, { category_name: category.name });

    switch (category.theme) {
        default:
            switch (category.name) {
                default:
                    const videoUrl = getSupportedVideo(category.entryVideo);
                    if (videoUrl) {
                        store.dispatch(
                            appActions.startVideo({
                                name: `category_video_replay_${category.name}`,
                                url: videoUrl,
                            }),
                        );
                    }
            }
    }
};

const startVideoByBundle = (bundle: IBundle) => {
    dwhExport.send(DWH_EVENTS.START_VIDEO_FOR_BUNDLE, { bundle_id: bundle.name });

    const videoUrl = getSupportedVideo(bundle.entryVideo);
    if (videoUrl) {
        store.dispatch(appActions.startVideo({ name: `bundle_video_replay_${bundle.id}`, url: videoUrl }));
    }
};

export const startCategoryVideo = (category: ICategory, bundle: IBundle) => {
    if (!category && !bundle) {
        return;
    }

    if (bundle && !isEmptyObject(bundle.entryVideo)) {
        return startVideoByBundle(bundle);
    }

    startVideoByCategory(category);
};
