import * as React from 'react';
import styles from './ScrollableBundleBackground.scss';
import RefManager, { RefManagerKeys } from '~/RefManager';

interface IBundleBackground {
    bundle: IBundle;
}

export const changeScrollableBundleBackgroundSize = (isScrolled = false) => {
    const backgroundRef = RefManager.getRef(RefManagerKeys.ScrollableBundleBackground);
    if (!backgroundRef) {
        return;
    }

    if (isScrolled) {
        backgroundRef.classList.add(styles.bundleBackgroundIsScrolling);
    } else {
        backgroundRef.classList.remove(styles.bundleBackgroundIsScrolling);
    }
};

const ScrollableBundleBackground = ({ bundle }: IBundleBackground) => {
    const background = bundle?.icons?.innerBackground;
    if (!background) {
        return null;
    }

    return (
        <div className={styles.background}>
            <div
                ref={(_ref) => {
                    RefManager.setRef(RefManagerKeys.ScrollableBundleBackground, _ref);
                }}
                className={styles.innerBackground}
                style={{ backgroundImage: `url(${background})` }}
            />
        </div>
    );
};

export default React.memo(ScrollableBundleBackground);
