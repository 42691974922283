import store from '~/Store';
import Account from '~/account/Account';
import { armoryState } from '~/utils/settings';
import { isValidDate } from '~/utils/time';
import { FEATURING_TEMPLATES } from '~/Layouts/FeaturingLayout';
import { FEATURING_DESTINATION } from '~/types/contents';
import { CATEGORIES } from '~/const';

export const isAllowedNewLabelFromCoupons = (): boolean => {
    if (!Account.getAccount()) {
        return false;
    }

    const accountState = store.getState().account;
    const coupons = accountState.coupons;

    return coupons.some((coupon: ICoupon) => isValidDate(coupon.labelNewActiveTill) && !Account.isViewedLabel(coupon.name));
};

export const getCountCouponsWithLabelNew = (): number => {
    if (!Account.getAccount()) {
        return 0;
    }

    const accountState = store.getState().account;
    const coupons = accountState.coupons;

    return coupons.reduce((count: number, coupon: ICoupon) => {
        if (isValidDate(coupon.labelNewActiveTill) && !Account.isViewedLabel(coupon.name) && !coupon.isDisabled) {
            count++;
        }

        return count;
    }, 0);
};

export const isAllowedNewLabelFromFeatured = (): boolean => {
    if (!Account.getAccount()) {
        return false;
    }

    const featuring = armoryState.content.featuring.filter((featuring) => featuring.destination === FEATURING_DESTINATION.MAIN);
    const appState = store.getState().app;
    const bundles = appState.bundles;

    return featuring.some((contentBlock) => {
        const content = contentBlock.contentBlocks;
        let flag = false;
        content.forEach((block) => {
            if (block.type === FEATURING_TEMPLATES.BUNDLE_LIST) {
                (block.data as IContentBlockData).bundles.forEach((bundle) => {
                    const currentBundle = bundles[bundle.id];
                    const isAllowedLabel = isAllowedNewLabelFromBundle(currentBundle);
                    if (!flag && isAllowedLabel) {
                        flag = true;
                    }
                });
            } else if (block.type === FEATURING_TEMPLATES.CATEGORY_PREVIEW) {
                if (!flag) {
                    const data = block.data as IContentBlockData;
                    const category = appState.categories[data.categoryName];
                    if (category) {
                        if (!category.labelNewAllowed) {
                            flag = isLabelNewShownForCategory(data.categoryName, data.bundlesCount);
                        } else {
                            let categoryBundles = Object.values(bundles).filter((bundle: IBundle) => category.bundles.includes(bundle.id));
                            categoryBundles = data.bundlesCount ? categoryBundles.splice(0, data.bundlesCount) : categoryBundles;
                            flag = categoryBundles.some((bundle: IBundle) => isAllowedNewLabelFromBundle(bundle));
                        }
                    }
                }
            }
        });

        return flag;
    });
};

export const isLabelNewShownForCategory = (name: string, bundleSpliceLength?: number, isNotMarkedParentCategory = false): boolean => {
    if (!Account.getAccount()) {
        return false;
    }

    const { categories } = store.getState().app;
    const category = categories?.[name];

    if (!category) {
        return false;
    }

    if (name === CATEGORIES.FEATURED) {
        const hasCategoryLabel = isValidDate(category.labelNewActiveTill) && !Account.isViewedLabel(category.name);
        return hasCategoryLabel || isAllowedNewLabelFromFeatured();
    }

    const appState = store.getState().app;
    const bundles = appState.bundles;

    const isEnabledLabel = (_category: ICategory): boolean => {
        let isAllowedLabelFromBundle = false;
        for (let i = 0; i < (bundleSpliceLength || _category.bundles.length); i++) {
            const bundleId = _category.bundles[i];
            const bundle = bundles[bundleId] as IBundle & { id: string };
            isAllowedLabelFromBundle = isAllowedNewLabelFromBundle(bundle);
            if (isAllowedLabelFromBundle) break;
        }

        if (isAllowedLabelFromBundle) {
            return true;
        }

        if (isNotMarkedParentCategory) {
            return false;
        }

        return isValidDate(_category.labelNewActiveTill) && !Account.isViewedLabel(_category.name);
    };

    if (category.subCategories?.length) {
        return category.subCategories.some((_category: string) => {
            const categoryConfig = categories[_category];
            if (categoryConfig) {
                return isEnabledLabel(categoryConfig);
            }

            return false;
        });
    }

    return isEnabledLabel(category);
};

export const isAllowedNewLabelFromBundle = (bundle: IBundle) => {
    if (!Account.getAccount() || !bundle) {
        return false;
    }

    if (bundle.isPurchased) {
        return false;
    }

    return isValidDate(bundle.labelNewActiveTill) && !Account.isViewedLabel(bundle.name);
};
