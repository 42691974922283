import * as React from 'react';
import { assets, items as ITEMS } from '@wg/wows-entities/const';
import {
    renderAdditionalDescription,
    renderContainsBlock,
    renderContentBlocks,
    renderDescriptionBlock,
    renderLegalDescription,
    renderShipAdditionalFeatures,
    renderShipMainFeatures,
} from '~/Layouts/BundlePage/BundlePageDescription/handlers';

export enum BUNDLE_PAGE_CONTENT_MAP {
    CONTAINS_BLOCK = 'contains_block',
    DESCRIPTION_BLOCK = 'description_block',
    CONTENT_BLOCK = 'content_block',
    ADDITIONAL_DESCRIPTION_BLOCK = 'additional_description_block',
    MAIN_SHIP_FEATURES_BLOCK = 'main_ship_features_block',
    ADDITIONAL_SHIP_FEATURES_BLOCK = 'additional_ship_features_block',
    LEGAL_DESCRIPTION = 'legal_description',
}

export function getContentMapForPrimaryItem(type: string): BUNDLE_PAGE_CONTENT_MAP[] {
    switch (type) {
        case ITEMS.VEHICLES:
            return [
                BUNDLE_PAGE_CONTENT_MAP.CONTAINS_BLOCK,
                BUNDLE_PAGE_CONTENT_MAP.MAIN_SHIP_FEATURES_BLOCK,
                BUNDLE_PAGE_CONTENT_MAP.DESCRIPTION_BLOCK,
                BUNDLE_PAGE_CONTENT_MAP.ADDITIONAL_SHIP_FEATURES_BLOCK,
                BUNDLE_PAGE_CONTENT_MAP.CONTENT_BLOCK,
                BUNDLE_PAGE_CONTENT_MAP.ADDITIONAL_DESCRIPTION_BLOCK,
                BUNDLE_PAGE_CONTENT_MAP.LEGAL_DESCRIPTION,
            ];

        case ITEMS.SIGNAL:

        case ITEMS.CREWS:

        case ITEMS.PERMOFLAGES:
        case ITEMS.CAMOUFLAGE:
        case ITEMS.STYLE:
        case ITEMS.SKIN:

        case ITEMS.MODERNIZATION:

        case ITEMS.DOG_TAG:

        case ITEMS.LOOTBOX:

        case assets.SLOTS:
        case assets.SLOT:

        case ITEMS.GLOBAL_BOOST:
        case ITEMS.MULTI_BOOST:
        case ITEMS.CAMO_BOOST:
            return [
                BUNDLE_PAGE_CONTENT_MAP.CONTAINS_BLOCK,
                BUNDLE_PAGE_CONTENT_MAP.DESCRIPTION_BLOCK,
                BUNDLE_PAGE_CONTENT_MAP.CONTENT_BLOCK,
                BUNDLE_PAGE_CONTENT_MAP.ADDITIONAL_DESCRIPTION_BLOCK,
                BUNDLE_PAGE_CONTENT_MAP.LEGAL_DESCRIPTION,
            ];

        default:
            return [BUNDLE_PAGE_CONTENT_MAP.CONTAINS_BLOCK, BUNDLE_PAGE_CONTENT_MAP.DESCRIPTION_BLOCK, BUNDLE_PAGE_CONTENT_MAP.ADDITIONAL_DESCRIPTION_BLOCK, BUNDLE_PAGE_CONTENT_MAP.LEGAL_DESCRIPTION];
    }
}

type ContentMapHandler = (bundle: IBundle) => Nullable<React.ReactNode>;
const contentMapHandler: Partial<Record<BUNDLE_PAGE_CONTENT_MAP, ContentMapHandler>> = {
    [BUNDLE_PAGE_CONTENT_MAP.CONTAINS_BLOCK]: renderContainsBlock,
    [BUNDLE_PAGE_CONTENT_MAP.DESCRIPTION_BLOCK]: renderDescriptionBlock,
    [BUNDLE_PAGE_CONTENT_MAP.CONTENT_BLOCK]: renderContentBlocks,
    [BUNDLE_PAGE_CONTENT_MAP.ADDITIONAL_DESCRIPTION_BLOCK]: renderAdditionalDescription,
    [BUNDLE_PAGE_CONTENT_MAP.MAIN_SHIP_FEATURES_BLOCK]: renderShipMainFeatures,
    [BUNDLE_PAGE_CONTENT_MAP.ADDITIONAL_SHIP_FEATURES_BLOCK]: renderShipAdditionalFeatures,
    [BUNDLE_PAGE_CONTENT_MAP.LEGAL_DESCRIPTION]: renderLegalDescription,
};

export function getDescriptionContentByType(bundle: IBundle): React.ReactNode[] {
    const content: React.ReactNode[] = [];
    const primaryItem = bundle.primaryItem;
    const maps = getContentMapForPrimaryItem(primaryItem?.type);
    maps.forEach((callbackName) => {
        const contentBlock = contentMapHandler[callbackName](bundle);
        if (contentBlock) {
            content.push(<React.Fragment key={`description_${callbackName}`}>{contentBlock}</React.Fragment>);
        }
    });
    return content;
}
