import * as React from 'react';

export const KEYS_CODE = {
    ESC: 27,
    UP: 38,
    DOWN: 40,
    ENTER: 13,
    F5: 116,
};

const useKeyDown = function (callback: (keyCode: number, event: React.KeyboardEvent) => void, keyCodes: number[] = []) {
    function _callback(event?: React.KeyboardEvent) {
        if (keyCodes.includes(event.keyCode) || !keyCodes.length) {
            callback && callback(event.keyCode, event);
        }
    }

    const call = _callback.bind(this);

    React.useEffect(() => {
        document.addEventListener('keydown', call);

        return () => {
            document.removeEventListener('keydown', call);
        };
    });
};

export default useKeyDown;
