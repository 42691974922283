import styles from './BundleContains.scss';
import equal from 'fast-deep-equal/react';
import Contains, { IContainsItem } from '~/components/ItemContains/Contains';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { items as ITEMS_TYPES } from '@wg/wows-entities/const';
import { isEnabledPortForItemInsideBundle, openPortByItem } from '~/settings/port';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import Account from '~/account/Account';
import classNames from 'classnames';
import store, { RootState, useAppSelector } from '~/Store';
import { POPUPS_NAME } from '~/components/PopupManager';
import { isAvailableAdditionalContentForType } from '../helpers';
import { getSectionName, ManagerData } from '~/core/ManagerData';
import { appActions } from '~/Store/appSlice';

interface IBundleContains {
    bundle: IBundle;
    className?: string;
    title?: string;
    titleClassName?: string;
    limit?: number;
}

export const prepareItems = (bundle: IBundle, inventory: InventoryState): IContainsItem[] => {
    return bundle.entitlements.map((item, index) => {
        let content;

        const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(item.type, item.identifier, item.customisation?.shipId);

        if (item.customTitle) {
            content = (
                <DivTooltip tooltipBody={<DefaultTooltip text={item.customDescription || item.customTitle} />}>
                    <div dangerouslySetInnerHTML={{ __html: item.customTitle }} />
                </DivTooltip>
            );
        } else {
            const isAvailableAdditionalContent = !item.isPrimary && isAvailableAdditionalContentForType(item.type, item.identifier);
            const data = ManagerData.getData(getSectionName(item.type), item.identifier, true);
            content = (
                <div
                    className={classNames({
                        [styles.WoWSEntityWrapper]: isAvailableAdditionalContent,
                        [styles.gold]: (item.type !== ITEMS_TYPES.LOOTBOX && data?.isPremium) || data?.isSpecial || data?.isUnique,
                    })}
                    onClick={() => {
                        if (!isAvailableAdditionalContent) {
                            return;
                        }

                        store.dispatch(
                            appActions.changeVisibilityPopup({
                                name: POPUPS_NAME.ITEM_DESCRIPTION_POPUP,
                                isVisible: true,
                                data: {
                                    item: item,
                                    autoDescriptionSettings: bundle.autoDescriptionSettings,
                                },
                            }),
                        );
                    }}
                >
                    <WoWSEntity
                        key={`item_${item.identifier}_${inventory ? 'loaded' : 'not_loaded'}_${inventoryCount}`}
                        type={item.type}
                        id={item.identifier}
                        amount={item.amount}
                        customisation={{
                            ...(item.customisation || {}),
                            inventoryCount: !item.isUnique && inventoryCount,
                            isExistsInInventory: item.isUnique && isExistsItemInInventory,
                        }}
                        presentation={{
                            withText: true,
                            withTooltip: true,
                            iconPosition: 'left',
                            renderWithoutFade: true,
                            hiddenInlineShip: item.type === ITEMS_TYPES.CREWS,
                        }}
                    />
                </div>
            );
        }

        return {
            id: (item.identifier || `${index}_${item.type}`)?.toString(),
            type: item.type as any,
            isBonus: item.isBonus,
            isExistsInInventory: true,
            isPortPreviewEnabled: isEnabledPortForItemInsideBundle(bundle, item),
            portPreviewOnClick: () => {
                openPortByItem({
                    item: { identifier: item.identifier, type: item.type, shipId: item.customisation?.shipId },
                    bundleId: bundle.id,
                });
            },
            content,
        } as IContainsItem;
    });
};

const stateSelector = (state: RootState) => {
    return {
        accountId: state.account.id,
        inventory: state.account.inventory,
    };
};

const BundleContains = ({ bundle, className, title, titleClassName, limit }: IBundleContains) => {
    const state = useAppSelector(stateSelector, equal);
    const items = prepareItems(bundle, state.inventory);

    return (
        <div className={className}>
            <Contains items={items} isLarge={true} title={title} titleClassName={titleClassName} limit={limit} />
        </div>
    );
};

export default BundleContains;
