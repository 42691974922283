import type { NavigateFunction, NavigateOptions, To } from 'react-router-dom';
import { urlsMap } from '~/utils/menu';
import { scrollTop } from '~/utils/scrollTop';

interface IHistoryItem {
    url: string;
    scrollTop?: number;
}

class History {
    private historyUrls: IHistoryItem[] = [];
    private routerNavigate: NavigateFunction = () => {};

    public initRouterNavigate(navigate: NavigateFunction): void {
        this.routerNavigate = navigate;
    }

    public navigate(delta: number): void;
    public navigate(to: To, options?: NavigateOptions): void;
    public navigate(to: To | number, options?: NavigateOptions): void {
        if (typeof to === 'number') {
            this.routerNavigate(to);
        } else {
            if (options?.replace) {
                this.historyUrls.pop();
            }
            this.routerNavigate(to, options);
        }
    }

    public back(): void {
        if (this.historyUrls.length <= 1) {
            this.routerNavigate(urlsMap.home);
            return;
        }

        const currentUrl = this.historyUrls.pop();
        let prevUrl = this.historyUrls.pop();
        if (prevUrl && prevUrl?.url === currentUrl?.url) {
            prevUrl = this.historyUrls.pop();
        }

        if (!prevUrl) {
            this.routerNavigate(urlsMap.home);
            return;
        }

        this.routerNavigate(prevUrl.url);

        setTimeout(() => {
            prevUrl.scrollTop && scrollTop(prevUrl.scrollTop, true);
        }, 0);
    }

    public backHome(): void {
        if (!this.hasHistory()) {
            this.routerNavigate(urlsMap.home);
        } else {
            this.back();
        }
    }

    public getHistory(): IHistoryItem[] {
        return this.historyUrls;
    }

    public hasHistory(): boolean {
        return this.historyUrls.length > 1;
    }

    public addToHistory(location: string): void {
        if (location.includes('port')) {
            return;
        }

        if (location === this.historyUrls[this.historyUrls.length - 1]?.url) {
            return;
        }

        this.historyUrls.push({
            url: location,
        });
    }

    public setScrollTop(scrollTop: number): void {
        if (this.historyUrls.length) {
            this.historyUrls[this.historyUrls.length - 1].scrollTop = scrollTop;
        }
    }
}

export default new History();
