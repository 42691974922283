import * as React from 'react';
import styles from './Notifications.scss';
import Button, { ButtonVariant } from '~/components/Button/Button';
import { t } from '~/utils/localization';
import { useDispatch } from 'react-redux';
import { LocalStorageHelper } from '~/utils/storage';
import { getNotificationNameFromLocalStorage, getNotificationToShowIfNeeded } from '~/components/Notifications/NotificationManager';
import classNames from 'classnames';
import { POPUPS_NAME } from '~/components/PopupManager';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import useClickAway from '~/hooks/useClickAway';
import { markNotificationAsViewed } from '~/api/account';
import { FadeInDiv } from '~/components/Transitions';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import equal from 'fast-deep-equal/react';
import { redirectTo } from '~/utils/notifications';
import { playButtonClickSound } from '~/api/WoWsClient';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { arrayToObjectByKey } from '~/utils/utils';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import { accountActions } from '~/Store/accountSlice';

interface INotificationPopup {
    data: {
        name: string;
    };
    isInfoscreen?: boolean;
}

const stateSelector = (state: RootState) => {
    return {
        accountId: state.account.id,
        viewedNotifications: state.account.viewedNotifications,
        currentPage: state.app.currentPage,
        notifications: state.account.notifications,
    };
};

export const NotificationPopup = ({ data, isInfoscreen }: INotificationPopup) => {
    const state = useAppSelector(stateSelector, equal);
    const notifications = arrayToObjectByKey(state.notifications || [], 'name');
    const notification = notifications[data.name];
    const { isPopupActive } = React.useContext(PopupManagerContext);
    const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
    const descriptionRef: React.RefObject<HTMLDivElement> = React.useRef(null);
    const dispatch = useDispatch();

    const close = (isDwhSendEvent = true) => {
        dispatch(accountActions.updateViewNotification(notification.name));
        const isNeedToShownNextInfoScreen = !!getNotificationToShowIfNeeded();
        dispatch(appActions.setVisibleInfoScreen({ name: null, isVisible: false, isNotTriggerClientEvent: isNeedToShownNextInfoScreen }));
        if (!isNeedToShownNextInfoScreen) {
            dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.INFO_SCREEN_POPUP }));
        }

        if (isDwhSendEvent) {
            dwhExport.send(DWH_EVENTS.CLOSE_NOTIFICATION, {
                notification_name: notification.name,
            });
        }
    };

    const openCategory = () => {
        close(false);
        dwhExport.send(DWH_EVENTS.OPEN_CATEGORY_FROM_NOTIFICATION, {
            notification_name: notification.name,
        });
        redirectTo(notification);
    };

    useClickAway(
        ref,
        () => {
            if (!isPopupActive) {
                return;
            }

            close.bind(this)();
        },
        [isPopupActive],
    );

    useKeyDown(() => {
        if (!isPopupActive && !isInfoscreen) {
            return;
        }
        playButtonClickSound();
        close();
    }, [KEYS_CODE.ESC]);

    const onScroll = () => {
        const descriptions = descriptionRef.current;
        if (!descriptions) {
            return;
        }

        if (descriptions.scrollHeight <= Math.ceil(descriptions.scrollTop + descriptions.offsetHeight)) {
            descriptions.classList.remove(styles.mask);
        } else {
            descriptions.classList.add(styles.mask);
        }
    };

    React.useEffect(() => {
        dispatch(appActions.setVisibleInfoScreen({ isVisible: true, name: notification.name }));

        if (descriptionRef.current?.scrollHeight > descriptionRef.current?.offsetHeight) {
            descriptionRef.current?.classList.add(styles.mask);
        }

        if (state.accountId && !state.viewedNotifications?.includes(notification.name)) {
            markNotificationAsViewed(notification.name);
        }
    }, [notification.name]);

    const isNeedToShownRedirectButton = (notification.redirectToCategory && state.currentPage?.name !== notification.redirectToCategory) || notification.linkHref;

    return (
        <div className={styles.wrapper}>
            <FadeInDiv className={styles.popup} ref={ref}>
                <img className={styles.popupImage} src={notification.background} alt={notification.title} />
                <div className={styles.about}>
                    <div className={styles.title} dangerouslySetInnerHTML={{ __html: notification.title }} />
                    <div className={styles.description} onScroll={onScroll} ref={descriptionRef} dangerouslySetInnerHTML={{ __html: notification.description }} />
                    <div className={styles.footer}>
                        {isNeedToShownRedirectButton && <Button variant={ButtonVariant.orange} label={t('Перейти')} onClick={openCategory} />}
                        <Button label={t('Закрыть')} onClick={() => close()} className={'armory__auto--popup_close'} />
                    </div>
                </div>
            </FadeInDiv>
        </div>
    );
};

interface INotificationProps {
    data: INotification;
}

export const NotificationDefault = (props: INotificationProps) => {
    const dispatch = useDispatch();
    const popupActive = useAppSelector((state) => state.app.popupActive, equal);
    const [isAnimation, setAnimation] = React.useState(false);

    const close = (event: React.MouseEvent) => {
        event.stopPropagation();
        setAnimation(false);

        setTimeout(() => {
            LocalStorageHelper.set(getNotificationNameFromLocalStorage(props.data.name), props.data.name);
            dispatch(appActions.setVisibleInfoScreen({ isVisible: false }));
        }, 1000);
    };

    const showPopup = () => {
        setAnimation(false);
        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.INFO_SCREEN_POPUP,
                isVisible: true,
                data: {
                    name: props.data.name,
                },
            }),
        );

        setTimeout(() => {
            LocalStorageHelper.set(getNotificationNameFromLocalStorage(props.data.name), props.data.name);
            dispatch(appActions.setVisibleInfoScreen({ isVisible: false }));
        }, 1000);
    };

    const showNotification = () => {
        setTimeout(() => {
            setAnimation(true);
        }, 500);
    };

    React.useEffect(() => {
        if (!!popupActive) {
            return;
        }
        showNotification();
    }, [props.data.name, popupActive]);

    React.useEffect(() => {
        if (!!popupActive) {
            return;
        }
        showNotification();
    }, []);

    const notificationsClassNames = classNames(styles.notificationInline, {
        [styles.isActive]: isAnimation,
    });

    const style = {
        backgroundImage: `url(${props.data.previewImage})`,
    };

    if (!!popupActive) {
        return null;
    }

    return (
        <div className={notificationsClassNames} onClick={showPopup} style={style}>
            <div className={styles.notificationInlineContent}>
                <div className={styles.notificationInlineClose} onClick={close} />
                <div className={styles.notificationInlineTitle} dangerouslySetInnerHTML={{ __html: props.data.title }} />
                <div className={styles.notificationInlineAbout} dangerouslySetInnerHTML={{ __html: props.data.description }} />
            </div>
        </div>
    );
};
