import * as React from 'react';
import styles from './TradeInLayout.scss';
import TradeInItemWidget from '~/Layouts/TradeIn/components/TradeInItemWidget/TradeInItemWidget';
import classNames from 'classnames';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { useDispatch } from 'react-redux';
import TradeInHeader from '~/Layouts/TradeIn/components/TradeInHeader/TradeInHeader';
import TradeInContent from '~/Layouts/TradeIn/components/TradeInContent/TradeInContent';
import { ChooseItemFor, ITradeItem } from '~/types/tradein';
import TradeInLayoutBackground from './TradeInLayoutBackground';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import { TRADEIN_TEXTS, isRareItem } from '~/utils/tradein';
import { FILTERS_TYPE } from '~/components/Filters/Filters';
import { interpolate, t } from '~/utils/localization';
import { scrollTop } from '~/utils/scrollTop';
import { isEmptyObject, isMobileOrTabletWindow, redirectToLogin } from '~/utils/utils';
import { POPUPS_NAME } from '~/components/PopupManager';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import Account from '~/account/Account';
import { appActions } from '~/Store/appSlice';
import { tradeinActions } from '~/Store/tradeinSlice';

interface ITradeInLayout {
    selectedTargetItem: ITradeItem;
    selectedSourceItem: ITradeItem;
    availableItems: ITradeItem[];
    choosenItemFor: ChooseItemFor;
    availableFilters: FILTERS_TYPE;
    activeFilters: FILTERS_TYPE;
    type: string;
    balance: IBalance;
    category: ICategory;
}

const TradeInLayout = ({ selectedTargetItem, selectedSourceItem, availableItems, choosenItemFor, type, availableFilters, activeFilters, balance, category }: ITradeInLayout) => {
    const dispatch = useDispatch();
    const traidInContentRef = React.useRef<HTMLDivElement>(null);
    const backgroundRef = React.useRef<HTMLDivElement>(null);
    const [isSticky, setSticky] = React.useState(false);
    const [isVibislePopup, toggleVisibilityListsPopup] = React.useState<boolean>(false);
    const previewContainerHeight = React.useRef<number>();
    const k = 150;

    const resetPositionAndScroll = () => {
        scrollTop(0);
        setSticky(false);
        backgroundRef.current.style.transform = `translate3d(0, 0, 0)`;
    };

    const onScroll = React.useCallback(() => {
        const mainContent = RefManager.getRef(RefManagerKeys.MainContainer);
        const scrollValue = Math.max(-mainContent.scrollTop, -1 * (window.screen.availHeight - window.screen.availHeight / 3));

        backgroundRef.current.style.transform = `translate3d(0, ${scrollValue}px, 0)`;
        setSticky(mainContent.scrollTop > previewContainerHeight.current + k);
    }, [isSticky]);

    const onChangeFilter = React.useCallback(() => {
        const height = previewContainerHeight.current;
        if (isSticky) {
            scrollTop(height);
        }
    }, [previewContainerHeight.current, isSticky]);

    const rulesClick = (event: React.MouseEvent) => {
        if ((event.target as HTMLElement).tagName.toLowerCase() === 'span') {
            dispatch(
                appActions.changeVisibilityPopup({
                    name: POPUPS_NAME.TRADEIN_RULES,
                    isVisible: true,
                }),
            );
        }
    };

    React.useEffect(() => {
        const parentElement = traidInContentRef.current.parentElement;
        previewContainerHeight.current = parentElement.offsetTop + parentElement.offsetHeight;

        const mainContent = RefManager.getRef(RefManagerKeys.MainContainer);
        if (!isMobileOrTabletWindow) {
            mainContent.addEventListener('scroll', onScroll);
        }
        return () => {
            mainContent.removeEventListener('scroll', onScroll);
        };
    }, []);

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            traidInContentRef.current = _ref;
        }
    };

    const onItemClick = (type: ChooseItemFor) => {
        resetPositionAndScroll();
        disableBodyScroll(document.body);
        toggleVisibilityListsPopup(true);
    };

    const closePopup = () => {
        clearAllBodyScrollLocks();
        toggleVisibilityListsPopup(false);
    };

    const classesBackground = classNames(styles.background, {
        [styles.sticky]: isSticky,
    });

    const classesPreview = classNames(styles.preview, {
        [styles.sticky]: isSticky,
    });

    const hasntItems = isEmptyObject(activeFilters) && !availableItems?.length;

    const classesListsWrapper = classNames(styles.listsWrapper, {
        [styles.popupVisible]: isVibislePopup,
    });

    const classesWrapper = classNames(styles.wrapper, {
        [styles.popupVisible]: isVibislePopup,
    });

    const stylesBackgroundColor = { '--background-color': category?.backgroundColor || '#02121f' } as React.CSSProperties;
    const stylesBackground = category && ({ backgroundImage: `url(${category.background})` } as React.CSSProperties);

    return (
        <React.Fragment>
            <div className={styles.backgroundColor} style={stylesBackgroundColor} />
            <div className={classesWrapper} style={stylesBackgroundColor}>
                <TradeInHeader item={selectedTargetItem} className={styles.headerContent} />
                <div className={classesPreview}>
                    <div className={classesBackground} ref={backgroundRef} style={stylesBackground}>
                        <TradeInLayoutBackground identifier={selectedTargetItem?.identifier} image={selectedTargetItem?.identifier && selectedTargetItem.images.big} />
                        <div className={styles.backgroundMask} />
                    </div>
                    <div className={styles.tradeInContentWrapper} ref={setRef}>
                        <TradeInContent
                            type={type}
                            selectedTargetItem={selectedTargetItem}
                            selectedSourceItem={selectedSourceItem}
                            choosenItemFor={choosenItemFor}
                            availableFilters={!hasntItems && availableFilters}
                            activeFilters={activeFilters}
                            balance={balance}
                            category={category}
                            onChangeFilter={onChangeFilter}
                            onItemClick={onItemClick}
                        />
                    </div>
                </div>

                {availableItems.length > 0 ? (
                    <div className={classesListsWrapper}>
                        <div className={styles.closeButton} onClick={closePopup} />
                        <div className={styles.listsContent}>
                            <GuideDecorator
                                key={`guide_${choosenItemFor}`}
                                names={[choosenItemFor === ChooseItemFor.GET ? GUIDE_NAMES.guide_trade_in_get_item : GUIDE_NAMES.guide_trade_in_item]}
                                className={styles.listsTitle}
                            >
                                {TRADEIN_TEXTS[type]?.[choosenItemFor].title}
                            </GuideDecorator>
                            <div className={styles.lists}>
                                {availableItems.map((item, index) => {
                                    const showPurchasedInfo = choosenItemFor === ChooseItemFor.GET && item.isReceived;
                                    let isOpacity = false;
                                    let isSelected = false;
                                    if (choosenItemFor === ChooseItemFor.TRADE && selectedSourceItem) {
                                        isOpacity = item.identifier !== selectedSourceItem.identifier;
                                        isSelected = !isOpacity;
                                    } else if (choosenItemFor === ChooseItemFor.GET && selectedTargetItem) {
                                        isOpacity = item.identifier !== selectedTargetItem.identifier;
                                        isSelected = !isOpacity;
                                    }
                                    const stylesWrapper = { animationDelay: `${Math.min(0.01 * index, 0.8)}s` };
                                    return (
                                        <div className={styles.itemWrapper} key={`trade_item_${choosenItemFor}_${item.identifier}_${item.type}_${index}`} style={stylesWrapper}>
                                            <TradeInItemWidget
                                                withTooltip={true}
                                                className={classNames(styles.item, {
                                                    [styles.opacity]: isOpacity,
                                                })}
                                                item={item}
                                                isTrade={choosenItemFor === ChooseItemFor.TRADE}
                                                isNotClickable={showPurchasedInfo}
                                                showPurchasedInfo={showPurchasedInfo}
                                                isSelected={isSelected}
                                                isRareItem={isRareItem(item.additionalData?.tags || [])}
                                                onClick={() => {
                                                    if (!Account.getAccount()?.id) {
                                                        return redirectToLogin();
                                                    }
                                                    toggleVisibilityListsPopup(false);
                                                    clearAllBodyScrollLocks();
                                                    if (choosenItemFor === ChooseItemFor.GET) {
                                                        resetPositionAndScroll();
                                                        !selectedSourceItem && dispatch(tradeinActions.chooseItemFor(ChooseItemFor.TRADE));
                                                        dispatch(tradeinActions.selectTargetItem(item));
                                                    } else if (choosenItemFor === ChooseItemFor.TRADE) {
                                                        !selectedTargetItem && dispatch(tradeinActions.chooseItemFor(ChooseItemFor.GET));
                                                        dispatch(tradeinActions.selectSourceItem(item));
                                                    }
                                                }}
                                                price={{ price: item.price, currency: item.currency }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        {isEmptyObject(activeFilters) ? (
                            <div className={styles.notMatchItems}>
                                <div className={styles.notMatchItemsTitle}>{t('У вас нет подходящих для обмена кораблей')}</div>
                                <div
                                    className={styles.aboutRulesText}
                                    onClick={rulesClick}
                                    dangerouslySetInnerHTML={{
                                        __html: interpolate(t('Список кораблей, участвующих в обмене, вы можете посмотреть на странице с {span}правилами Trade-in{span_end}.'), {
                                            span: `<span>`,
                                            span_end: `</span>`,
                                        }),
                                    }}
                                />
                            </div>
                        ) : (
                            <div className={styles.emptySearch}>{t('Ничего не найдено')}</div>
                        )}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default TradeInLayout;
