import * as React from 'react';
import Account from '~/account/Account';
import VideoViewBackground from '~/components/ViewBackground/VideoViewBackground';
import GalleryViewBackground from '~/components/ViewBackground/GalleryViewBackground';
import { isDisabledCategoryVideoForBundlePage } from '~/utils/bundles';
import { arrayToObjectByKey, isEmptyObject, isMobileOrTabletWindow } from '~/utils/utils';
import styles from './ViewBackground.scss';
import classNames from 'classnames';
import { getGlobalContent, settings } from '~/utils/settings';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';
import SantaBackground from '~/Layouts/Themes/SantaPage/SantaBackground';
import AuctionBackground from '~/Layouts/AuctionLotPage/AuctionBackground';
import SnowfallBackground from '~/Layouts/Themes/Snowfall/SnowfallBackground';
import RefManager, { RefManagerKeys } from '~/RefManager';
import Anniversary from '~/Layouts/Themes/Anniversary/Anniversary';
import equal from 'fast-deep-equal/react';
import { ANNIVERSARY_PROMO_THEME, AUCTION_PAGE_THEME, SANTA_PAGE_THEME, SNOWFALL } from '~/Layouts/Themes/ThemeManager';
import { CATEGORIES } from '~/const';
import { BUNDLE_DECORATION } from '~/types/bundle';
import isDisabledAnimation from '~/hooks/isDisabledAnimation';
import { useAvailableSound } from '~/hooks/useAvailableSound';
import { shipyardSoundOff, shipyardSoundOn } from '@wg/web2clientapi/sound';
import { RootState, useAppSelector } from '~/Store';

type Theme = Nullable<ALLOWED_THEMES>;

interface IThemeBackground {
    theme: Theme;
}

const ThemeBackground = React.memo((props: IThemeBackground) => {
    switch (props.theme) {
        case SANTA_PAGE_THEME:
            return <SantaBackground />;

        case AUCTION_PAGE_THEME:
            return <AuctionBackground />;

        case ANNIVERSARY_PROMO_THEME:
            return <Anniversary />;

        case SNOWFALL:
            return <SnowfallBackground />;
    }

    return null;
});

interface IViewBackground {
    isBlur?: boolean;
}

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        viewBackground: state.app.viewBackground,
        categories: state.app.categories,
        menu: state.app.menu,
        bundles: state.app.bundles,
        selectedRandomBundles: state.account.selectedRandomBundles,
        activeAuctions: state.auction.activeAuctions,
        isFinishedRequest: state.app.isFinishedRequest,
        isFullscreen: state.app.isFullscreen,
        zoomLotBackground: state.auction.zoomLotBackground,
        shipFeatureMap: state.features.shipFeatureMap,
        isTrusted: state.app.isTrusted,
    };
};

const ViewBackground = (props: IViewBackground): React.ReactElement => {
    const state = useAppSelector(stateSelector, equal);
    const category = state.categories?.[state.currentPage?.name];
    let bundle = state.bundles?.[state.currentPage?.bundleId];
    const configCategory = state.menu?.[state.currentPage?.name];

    if (bundle && bundle.isRandom && !isEmptyObject(state.selectedRandomBundles)) {
        const selectedRandomBundleId = state.selectedRandomBundles[bundle.id];
        const randomBundlesChildren = arrayToObjectByKey(bundle.randomBundleChildren, 'id');
        if (randomBundlesChildren[selectedRandomBundleId]) {
            bundle = Account.getRandomBundleChild(state.selectedRandomBundles, bundle);
        }
    }

    let bundleVideoBackground = bundle?.videoBackground;
    let hasBundleVideoBackground = !isEmptyObject(bundleVideoBackground);
    let animationName = hasBundleVideoBackground ? bundle.name : category?.name;
    if (!hasBundleVideoBackground && bundle?.parentBundleId) {
        const { name, videoBackground } = state.bundles[bundle.parentBundleId];
        bundleVideoBackground = videoBackground;
        hasBundleVideoBackground = !isEmptyObject(bundleVideoBackground);
        if (hasBundleVideoBackground) {
            animationName = name;
        }
    }
    const _isDisabledAnimation = isDisabledAnimation(animationName);
    const isAvailableSoundEffect = useAvailableSound(category, bundle);

    React.useEffect(() => {
        let isAvailableSound = state.isTrusted ? isAvailableSoundEffect : false;
        if (_isDisabledAnimation) {
            isAvailableSound = false;
        }
        isAvailableSound ? shipyardSoundOff() : shipyardSoundOn();
    }, [isAvailableSoundEffect, _isDisabledAnimation, state.isTrusted]);

    if (!state.isFinishedRequest) {
        return <div className={styles.background} />;
    }

    const setRef = (ref: HTMLDivElement) => {
        if (ref) {
            RefManager.setRef(RefManagerKeys.ViewBackground, ref);
        }
    };

    const setPortalRef = (ref: HTMLDivElement) => {
        if (ref) {
            RefManager.setRef(RefManagerKeys.ViewBackgroundPortal, ref);
        }
    };

    const theme = (category?.theme || configCategory?.theme || getGlobalContent()?.theme) as Theme;

    const hasCategoryVideoBackground = !isEmptyObject(category?.videoBackground);
    let hasVideoBackground = false;
    if (!isMobileOrTabletWindow) {
        if (bundle) {
            hasVideoBackground = hasBundleVideoBackground;
            const disableCategoryVideoBack = bundle.decoration?.includes(BUNDLE_DECORATION.DISABLE_CATEGORY_VIDEO_BACK);
            if (!bundle.serialPurchase && !disableCategoryVideoBack && !hasVideoBackground && !isDisabledCategoryVideoForBundlePage(bundle)) {
                hasVideoBackground = hasCategoryVideoBackground;
            }
        } else {
            hasVideoBackground = hasCategoryVideoBackground;
        }
    }

    // Fullscreen gallery [images or video slider]
    if (!isMobileOrTabletWindow && bundle?.isFullscreenGallery) {
        return <GalleryViewBackground isBlur={props.isBlur} bundle={bundle} category={category} />;
    }

    if (configCategory?.theme === SANTA_PAGE_THEME && state.currentPage?.isBundlePage) {
        return <div className={styles.background} />;
    }

    if (!hasVideoBackground || _isDisabledAnimation) {
        let backgroundColor = bundle?.backgroundColor || category?.backgroundColor;
        const hiddenAuctionMask = false;
        if (state.currentPage?.name === CATEGORIES.AUCTION) {
            if (state.currentPage?.isBundlePage) {
                let lot: ILot;
                state.activeAuctions.find((auction) => {
                    const currentLot = auction.lots.find((lot) => state.currentPage?.lotId === lot.id);
                    lot = currentLot;
                    if (lot) {
                        return currentLot;
                    }
                });
                backgroundColor = lot?.backgroundColor;
            } else {
                if (state.activeAuctions[0]?.isNeedToShowPromoPage) {
                    backgroundColor = state.activeAuctions[0]?.promotionBackgroundColor;
                }
            }
        }

        const hasBackgroundMask = !!backgroundColor;
        const mergedStyles: React.CSSProperties = {};
        if (state.viewBackground) {
            mergedStyles.backgroundImage = `url(${state.viewBackground})`;
        }

        const style = hasBackgroundMask ? ({ '--bundle-background-color': backgroundColor, ...mergedStyles } as React.CSSProperties) : mergedStyles;

        return (
            <div
                ref={setRef}
                style={style}
                key={`background_${category?.name}`}
                className={classNames(
                    styles.background,
                    state.currentPage?.name,
                    styles[state.currentPage?.name],
                    styles[configCategory?.backgroundName],
                    styles[settings.realm],
                    styles[configCategory?.theme],
                    {
                        [styles.blur]: props.isBlur,
                        [styles.isBundlePage]: state.currentPage?.isBundlePage,
                        [styles[`auction_${state.activeAuctions[0]?.kind}`]]: state.currentPage?.name === CATEGORIES.AUCTION,
                        [styles.backgroundWithMask]: hasBackgroundMask,
                        [styles.isFullscreen]: state.isFullscreen,
                    },
                )}
            >
                <div className={classNames(styles.backgroundMask, { [styles.visibleMask]: hasBackgroundMask, [styles.auctionMask]: hiddenAuctionMask })} />
                <ThemeBackground theme={theme} key={category?.name} />
                <div ref={setPortalRef} />
            </div>
        );
    }

    const videoBackgroundKey = hasBundleVideoBackground ? `background_${category?.name}_${bundle.id}` : `background_${category?.videoBackground?.webm}`;

    return (
        <VideoViewBackground isBlur={props.isBlur} key={videoBackgroundKey} bundle={bundle} category={category}>
            {theme === SNOWFALL ? <ThemeBackground theme={theme} key={category?.name} /> : null}
            <div ref={setPortalRef} />
        </VideoViewBackground>
    );
};

export default ViewBackground;
