import * as React from 'react';
import styles from './Tag.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import className from 'classnames';
import { playButtonClickSound } from '~/api/WoWsClient';

interface ITag {
    label: string | React.ReactChild;
    tooltipBody?: string | React.ReactChild;
    showCloseIcon?: boolean;
    onClick?: (event?: React.MouseEvent) => void;
    onClose?: (event?: React.MouseEvent) => void;
    isActive?: boolean;
    tooltipStyles?: React.CSSProperties;
    classNames?: {
        tag?: string;
        activeTag?: string;
        closeIcon?: string;
    };
}

const Tag = ({ label, showCloseIcon, onClick, tooltipBody, isActive, classNames, onClose, tooltipStyles }: ITag) => {
    const classes = className(styles.wrapper, classNames?.tag, {
        [classNames?.activeTag]: isActive,
    });

    return (
        <DivTooltip tooltipBody={tooltipBody} style={tooltipStyles || { cursor: 'pointer' }}>
            <div
                className={classes}
                onClick={(event) => {
                    playButtonClickSound();
                    onClick?.(event);
                }}
            >
                <div className={styles.label}>{label}</div>
                {showCloseIcon && (
                    <div
                        className={className(styles.closeIcon, classNames?.closeIcon)}
                        onClick={(event) => {
                            onClose?.(event);
                        }}
                    />
                )}
            </div>
        </DivTooltip>
    );
};

export default Tag;
