import * as React from 'react';
import styles from './Discount.scss';
import classNames from 'classnames';
import OfferMark from '~/components/OfferMark/OfferMark';
import { DivTooltip } from '@wg/wows-react-uikit';
import { interpolate, t } from '~/utils/localization';

export enum DiscountSize {
    DEFAULT = 'default',
    SMALL = 'small',
}

interface IDiscount {
    className?: string;
    withTimer?: boolean;
    activeTill?: string;
    discount?: number;
    size?: DiscountSize;
    withFade?: boolean;
    tooltipBody?: React.ReactChild;
    formattedDiscount?: string;
}

const Discount = (props: IDiscount) => {
    const classes = classNames(
        styles.wrapper,
        {
            [styles.withTimer]: props.withTimer,
            [styles.withFade]: props.withFade,
        },
        styles[props.size],
        props.className,
    );

    const strDiscount = props.formattedDiscount || interpolate(t('-{percent}%'), { percent: props.discount });

    return (
        <DivTooltip className={classes} tooltipBody={props.tooltipBody}>
            {props.withTimer ? (
                <React.Fragment>
                    <div className={styles.timerContent}>
                        <OfferMark expiredTime={props.activeTill} />
                    </div>
                    <div className={styles.discountContent}>{strDiscount}</div>
                </React.Fragment>
            ) : (
                <span className={styles.discountValue}>{strDiscount}</span>
            )}
        </DivTooltip>
    );
};

export default Discount;
