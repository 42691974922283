import * as React from 'react';
import History from '~/utils/history';
import styles from './EnrothContainer.scss';
import { settings } from '~/utils/settings';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import { redirectToLogin } from '~/utils/utils';
import Account from '~/account/Account';
import { showDownloadGamePopup } from '~/components/Popups/settings';
import { Processing } from '@wg/wows-react-uikit';
import BackButton from '~/components/BackButton/BackButton';
import { t } from '~/utils/localization';
import { WoWSEntities } from '@wg/wows-entities';
import { isRealMoneyPurchaseDisabledBySettings } from '~/utils/category';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';

const Enroth = React.lazy(() => import('@wg/enroth/App'));

const EnrothContainer = () => {
    const [cssLoaded, setCssLoaded] = React.useState(false);
    const [enrothLoaded, setEnrothLoaded] = React.useState(false);
    const enrothPopupVisibiluty = React.useRef(false);

    const goBack = () => {
        History.backHome();
        updateBrowserControlState(false);
    };

    React.useEffect(() => {
        if (!Account.getAccount()?.id) {
            return redirectToLogin();
        }
        if (Account.isDeactivated()) {
            showDownloadGamePopup();
            return goBack();
        }
        import('@wg/enroth/style.css').then(() => {
            setCssLoaded(true);
        });
        updateBrowserControlState(true);
    }, []);

    useKeyDown(() => {
        const isLoading = !cssLoaded || !enrothLoaded;
        if (!enrothPopupVisibiluty.current || isLoading) {
            goBack();
        }
    }, [KEYS_CODE.ESC]);

    return (
        <React.Suspense fallback={<></>}>
            <div className={styles.container}>
                <BackButton label={t('Назад')} onClick={goBack} className={styles.backButton} />
                <ButtonEsc onClick={goBack} className={styles.escButton} />
                {cssLoaded && (
                    <Enroth
                        staticPath={settings.urls.static + 'assets/images/'}
                        language={settings.languageCode}
                        host={settings.urls.enroth}
                        vortexHost={settings.urls.vortex}
                        version={WoWSEntities.getGameVersion()}
                        wsmartBackendUrl={settings.urls.wsmartUrl}
                        isAllowPurchase={!isRealMoneyPurchaseDisabledBySettings()}
                        onPopupVisibility={(isVisible: boolean) => {
                            enrothPopupVisibiluty.current = isVisible;
                        }}
                        onLoad={() => {
                            setEnrothLoaded(true);
                        }}
                    />
                )}
                {(!cssLoaded || !enrothLoaded) && <Processing isFetching={true} />}
            </div>
        </React.Suspense>
    );
};

export default EnrothContainer;
