import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './BidPlacingPopup.scss';
import { useDispatch } from 'react-redux';
import { t } from '~/utils/localization';
import { Interpolate } from '@wg/wows-react-uikit';
import Currency from '~/components/Currency/Currency';
import BidInput from '~/components/BidInput/BidInput';
import AuctionProcessor from '~/processors/AuctionProcessor';
import AuctionLotHeader from '~/components/Auction/AuctionHeader';
import AuctionBidButton from '~/components/AuctionBidButton/AuctionBidButton';
import { getNoticeByKind, isPremiumCurrency, startHeadShakeAnimation } from '~/utils/auction';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { formatNumber } from '~/utils/utils';
import AuctionCancelButton from '~/components/AuctionBidButton/AuctionCancelButton';
import ActiveBid from '~/components/Popups/Auction/ActiveBid/ActiveBid';
import BidPlacingBackground from '~/components/Popups/Auction/BidPlacingPopup/BidPlacingBackground/BidPlacingBackground';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import { auctionActions } from '~/Store/auctionSlide';

interface IProps {
    data: {
        lot: ILot;
        fromLotPage?: boolean;
    };
    closeCallback?: () => void;
}

const stateSelector = (state: RootState) => {
    return {
        auctionAccount: state.auction.account,
        activeAuctions: state.auction.activeAuctions,
        activeBids: state.auction.activeBids,
        wallet: state.account?.balance,
        popupActive: state.app.popupActive,
    };
};

const BidPlacingPopup = ({ data, closeCallback }: IProps) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const bidInputWrapper = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const currentAuction = state.activeAuctions?.filter((auction: IAuction) => {
        return auction.lots.some((lot) => lot.id === data.lot.id);
    })?.[0] as IAuction;

    const [isDisabledBidButton, setDisabledBidButton] = React.useState<boolean>(false);
    const [isProcessing, setProcessing] = React.useState<boolean>(false);
    const auctionProcessor = new AuctionProcessor(currentAuction, data.lot, state.auctionAccount);
    const isBidAlreadyPlaced = auctionProcessor.isBidAlreadyPlaced(data.lot.id);
    const stateBid = state.activeBids?.[data.lot.id];
    const currentBid = auctionProcessor.getActiveBidByLotId(data.lot.id)?.bid.amount || 0;
    const isBidCanceling = auctionProcessor.isBidCanceling();
    const isCurrentBidCanceling = auctionProcessor.isBidCanceling(true);

    const [isFinishedAuction, setFinishFlagAuction] = React.useState<boolean>(auctionProcessor.isFinishedLot());
    const [realBid, setRealBid] = React.useState<number>(stateBid || currentBid || data.lot.minimalBid.amount);

    const close = () => {
        dispatch(appActions.changeVisibilityPopup({ name: null }));
    };

    const placeBet = async () => {
        setProcessing(true);
        await auctionProcessor.placedBit(data.lot.id, data.lot.minimalBid.currency, realBid, close, data.fromLotPage);
    };

    const style = data.lot.purchasePreviewImageUrl
        ? {
              backgroundImage: `url(${data.lot.purchasePreviewImageUrl})`,
          }
        : {};

    const handleBidChange = React.useCallback(
        (bid: number) => {
            setDisabledBidButton(false);
            setRealBid(bid);
            dispatch(auctionActions.setBidLot({ lotId: data.lot.id, bid }));
        },
        [data.lot.id],
    );

    const handleBidInput = React.useCallback(
        (bid: number) => {
            setDisabledBidButton(bid < data.lot.minimalBid.amount || bid === currentBid);
            setRealBid(bid);
        },
        [data.lot.id],
    );

    React.useEffect(() => {
        return () => {
            closeCallback?.();
            if (!data.fromLotPage) {
                dispatch(auctionActions.setBidLot({ lotId: data.lot.id, bid: null }));
            }
        };
    }, []);

    React.useEffect(() => {
        if (isFinishedAuction) {
            close();
        }
    }, [isFinishedAuction]);

    React.useEffect(() => {
        setFinishFlagAuction(auctionProcessor.isFinishedLot());
    }, [auctionProcessor.isFinishedLot()]);

    const currentAmount = () => {
        const currencyName = data.lot.minimalBid.currency;

        if (isPremiumCurrency(currencyName)) {
            return Math.floor(state.wallet?.[data.lot.minimalBid.currency]?.value / 86400);
        }

        return state.wallet?.[data.lot.minimalBid.currency]?.value || 0;
    };

    const Wallet = () => {
        return (
            <React.Fragment>
                <Currency amount={currentAmount()} currency={data.lot.minimalBid.currency} className={styles.walletCurrency} withoutAnimation />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className={styles.miniWalletWrapper}>
                <div className={styles.miniWallet}>
                    <Wallet />
                </div>
            </div>
            <Popup
                onClose={close}
                className={styles.popup}
                popupClassName={styles.popupWrapper}
                renderEscButton={true}
                BeforePopupComponent={<BidPlacingBackground background={data.lot?.bidPlacingPopupBackground} />}
            >
                <PopupHeader title={isBidAlreadyPlaced ? t('Изменение ставки') : t('Подтверждение ставки')} />
                <PopupBody>
                    <div className={styles.mobileMiniWalletWrapper}>
                        <div className={styles.miniWallet}>
                            <Wallet />
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.info}>
                            <div className={styles.infoHeader}>
                                <div className={styles.title}>
                                    <AuctionLotHeader {...data.lot} />
                                </div>
                            </div>
                            <div className={styles.betContent}>
                                <div className={styles.amount}>
                                    {t('Количество победителей:')}&nbsp;<b>{formatNumber(data.lot.winnersCount)}</b>
                                </div>
                                <div className={styles.betInfo}>
                                    <Interpolate
                                        str={t('Минимальная ставка: %(bet)s')}
                                        bet={<Currency currency={data.lot.minimalBid.currency} className={styles.betCurrency} amount={data.lot.minimalBid.amount} withoutAnimation />}
                                    />
                                </div>
                                {isBidAlreadyPlaced && (
                                    <div className={styles.betInfo}>
                                        <div>{t('Ваша ставка:')}&nbsp;</div>
                                        <ActiveBid
                                            currency={data.lot.minimalBid.currency}
                                            className={styles.betCurrency}
                                            amount={currentBid}
                                            placedAt={auctionProcessor.getActiveBidByLotId(data.lot.id)?.placedAt}
                                        />
                                        {auctionProcessor.canCanceledBid(data.lot.id) && (
                                            <AuctionCancelButton
                                                isProcessing={isProcessing}
                                                auction={currentAuction}
                                                isFetching={isCurrentBidCanceling}
                                                lot={data.lot}
                                                className={styles.cancelBidButton}
                                            />
                                        )}
                                    </div>
                                )}
                                <div className={styles.betForm}>
                                    <div className={styles.betLabel}>{isBidAlreadyPlaced ? t('Новая ставка:') : t('Ваша ставка:')}</div>
                                    <div ref={bidInputWrapper}>
                                        <BidInput
                                            activeBid={currentBid}
                                            bidCancellationsAllowed={currentAuction?.bidCancellationsAllowed}
                                            bidRaisingAllowed={currentAuction?.bidRaisingAllowed}
                                            hasTransaction={isProcessing || isBidCanceling}
                                            bidDropAllowed={currentAuction?.bidDropAllowed}
                                            currency={data.lot.minimalBid.currency}
                                            minLotBid={data.lot.minimalBid.amount}
                                            bid={stateBid || currentBid}
                                            lotId={data.lot.id}
                                            maxBidAmount={data.lot.maxBidAmount}
                                            inputClassName={styles.inputBid}
                                            changeBid={handleBidChange}
                                            onInput={handleBidInput}
                                            setRef={(_ref) => {
                                                if (_ref) {
                                                    inputRef.current = _ref;
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.footer}>{getNoticeByKind(currentAuction?.kind)}</div>
                        </div>
                        <div className={styles.imageWrapper}>
                            <div className={styles.image} style={style} />
                        </div>
                    </div>
                </PopupBody>
                <PopupFooter className={styles.popupFooter}>
                    <div>
                        <AuctionBidButton
                            isProcessing={isProcessing || isBidCanceling}
                            isDisabled={(isBidAlreadyPlaced && realBid === currentBid) || isDisabledBidButton || isFinishedAuction}
                            isBidAlreadyPlaced={isBidAlreadyPlaced}
                            minimalBid={data.lot.minimalBid}
                            lotId={data.lot.id}
                            onClick={placeBet}
                            renderFastGoldLink={true}
                            onFallbackClick={() => {
                                startHeadShakeAnimation(bidInputWrapper.current, styles.headShakeAnim);
                                inputRef.current?.focus();
                            }}
                        />
                    </div>
                </PopupFooter>
            </Popup>
        </React.Fragment>
    );
};

export default BidPlacingPopup;
