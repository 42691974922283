import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { settings } from '~/utils/settings';
import store from '.';
import { CATEGORIES } from '~/const';

export interface ISeaBattleState {
    isEnabled: boolean;
    isError: boolean;
    isActivatedUser: boolean;
    hasActiveSeason: boolean;
}

const initialState: Partial<ISeaBattleState> = {
    isEnabled: false,
    isError: false,
    isActivatedUser: true,
    hasActiveSeason: true,
};

export const fetchSeaBattleSettings = createAsyncThunk('seabattle/load_settings', async () => {
    const settingsUrl = settings.urls.seaBattleSettings;
    const hasActiveCategory = !!store.getState().app?.menu?.[CATEGORIES.SEA_BATTLE];
    if (!settingsUrl || !hasActiveCategory) {
        return null;
    }

    const { loadSettings } = await import('@wg/seabattle/settings');
    const seaBattleSettings = await loadSettings(settingsUrl, { languageCode: settings.languageCode });

    return {
        isError: false,
        isEnabled: seaBattleSettings.isEnabled,
        isActivatedUser: seaBattleSettings.isActivatedUser,
        hasActiveSeason: seaBattleSettings.hasActiveSeason,
    };
});

export const seabattleSlice = createSlice({
    name: 'seabattle',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSeaBattleSettings.fulfilled, (state, action: PayloadAction<Nullable<ISeaBattleState>>) => {
            if (action.payload) {
                state.isError = false;
                state.isEnabled = action.payload.isEnabled;
                state.isActivatedUser = action.payload.isActivatedUser;
                state.hasActiveSeason = action.payload.hasActiveSeason;
            }
        });
    },
});

export const seabattleActions = seabattleSlice.actions;

export default seabattleSlice.reducer;
