import styles from './SerialItemsSmallCarousel.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import { ItemsContainsTooltip } from '../Tooltip/Tooltips';
import Purchased from '~/components/Purchased/Purchased';
import SerialBundleIndex from '~/components/SerialBundleIndex/SerialBundleIndex';
import classNames from 'classnames';
import { getBundleDiscount } from '~/utils/bundles';
import Discount, { DiscountSize } from '../Discount/Discount';
import { playTabClickSound } from '~/api/WoWsClient';

interface ISerialItemsSmallCarouselItem {
    bundle: IBundle;
    className?: string;
    onClick?: (bundle: IBundle) => void;
    showTitle?: boolean;
    showDiscount?: boolean;
    isShiny?: boolean;
}

const SerialItemsSmallCarouselItem = ({ bundle, onClick, className, showTitle, isShiny, showDiscount }: ISerialItemsSmallCarouselItem) => {
    const bundleDiscount = getBundleDiscount(bundle);

    return (
        <DivTooltip
            className={classNames(styles.itemWrapper, className)}
            onClick={() => {
                playTabClickSound();
                onClick?.(bundle);
            }}
            tooltipBody={<ItemsContainsTooltip bundle={bundle} limit={3} />}
            style={{ cursor: 'pointer' }}
        >
            {isShiny && (
                <div className={styles.animationWrapper}>
                    <div className={styles.shinyAnimation} />
                </div>
            )}
            <div className={styles.itemInfo}>{bundle.isPurchased ? <Purchased onlyIcon /> : <SerialBundleIndex index={bundle.serialIndex} />}</div>
            <div className={styles.itemIconWrapper}>
                <img
                    className={classNames(styles.itemIcon, {
                        [styles.isShiny]: isShiny,
                    })}
                    src={bundle.icons.small}
                    alt={bundle.title}
                />
                {showDiscount && bundleDiscount && (
                    <div className={styles.discountWrapper}>
                        <Discount discount={bundleDiscount} size={DiscountSize.SMALL} className={styles.discount} />
                    </div>
                )}
            </div>
            {showTitle && <div className={styles.itemTitle} dangerouslySetInnerHTML={{ __html: bundle.title }} />}
        </DivTooltip>
    );
};

export default SerialItemsSmallCarouselItem;
