import * as React from 'react';

const useMaskScrollEffect = (ref: React.RefObject<HTMLElement>) => {
    const handleScroll = () => {
        if (ref.current.scrollHeight <= Math.ceil(ref.current.scrollTop + ref.current.offsetHeight)) {
            ref.current.style.webkitMaskImage = 'none';
        } else {
            ref.current.style.webkitMaskImage = 'linear-gradient(to top, transparent 0%, rgba(255, 255, 255, 1) 30px)';
        }
    };

    React.useEffect(() => {
        if (!ref || !ref.current) {
            return;
        }

        handleScroll();

        ref.current.addEventListener('scroll', handleScroll);

        return () => ref.current?.removeEventListener('scroll', handleScroll);
    });
};

export default useMaskScrollEffect;
