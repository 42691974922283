import * as React from 'react';
import equal from 'fast-deep-equal/react';
import SoundControl from '~/components/TopPanel/SoundControl';
import styles from './TopPanel.scss';
import { t } from '~/utils/localization';
import { useDispatch } from 'react-redux';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { getPresetByName, openCategoryByName } from '~/utils/category';
import History from '~/utils/history';
import Account from '~/account/Account';
import classNames from 'classnames';
import { isMobileOrTabletWindow } from '~/utils/utils';
import AnimationIcon from '~/components/AnimationIcon/AnimationIcon';
import { isEnabledCategoryVideo, startCategoryVideo } from '~/utils/video/settings';
import { SINGLE_BUNDLE_PAGE } from '~/Layouts/Themes/ThemeManager';
import PlayIcon from '~/components/PlayIcon/PlayIcon';
import { getAnimationName, getAnimationStatusByName, isEnabledAnimationByCategory, updateAnimationStatusByName } from '~/utils/animations/settings';
import GuideWrapper from '~/components/TopPanel/GuideWrapper';
import { playButtonClickSound } from '~/api/WoWsClient';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

const stateSelector = (state: RootState) => {
    return {
        isStartedVideo: state.app.isStartedVideo,
        currentPage: state.app.currentPage,
        coupons: state.account.coupons,
        activePreset: state.app.activePreset,
        categories: state.app.categories,
        isTrusted: state.app.isTrusted,
        menu: state.app.menu,
        bundles: state.app.bundles,
        categoriesAnimationStatuses: state.app.categoriesAnimationStatuses,
    };
};

interface ICategoryNavigation {
    onClickCategory: () => void;
    isEnabledGoToCategory: boolean;
    preset: ICategoryPresets;
    currentPage: ICurrentPage;
    categoryConfig: ICategory;
}

const CategoryNavigation = ({ onClickCategory, preset, currentPage, isEnabledGoToCategory, categoryConfig }: ICategoryNavigation) => {
    const dispatch = useDispatch();

    const titleCategoryClassNames = classNames(styles.titleCategory, styles[categoryConfig?.theme], styles[currentPage?.name], {
        [styles.pointer]: isEnabledGoToCategory || preset,
        [styles.isBundlePage]: currentPage?.isBundlePage,
    });

    const subTitleCategoryClassNames = classNames(styles.subCategoryTitle, {
        [styles.pointer]: preset && currentPage.isBundlePage,
    });

    return (
        <React.Fragment>
            <h1
                className={titleCategoryClassNames}
                onClick={() => {
                    if (isEnabledGoToCategory || preset) {
                        dispatch(appActions.updateActiveFilterPreset({ preset: null }));
                    }
                    if (isEnabledGoToCategory) {
                        playButtonClickSound();
                        onClickCategory();
                    }
                }}
            >
                {currentPage.title}
            </h1>
            {preset && (
                <React.Fragment>
                    <span className={styles.navigationArrow} />
                    <span
                        className={subTitleCategoryClassNames}
                        onClick={() => {
                            if (isEnabledGoToCategory) {
                                playButtonClickSound();
                                onClickCategory();
                            }
                        }}
                    >
                        {preset.title}
                    </span>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

interface ISubCategoryNavigation {
    onClickCategory: () => void;
    goToParentCategory: () => void;
    isEnabledGoToCategory: boolean;
    isEnabledGoToParentCategory: boolean;
    preset: ICategoryPresets;
    currentPage: ICurrentPage;
    parentCategory: ICategory;
}

const SubCategoryNavigation = ({ onClickCategory, goToParentCategory, preset, currentPage, isEnabledGoToCategory, isEnabledGoToParentCategory, parentCategory }: ISubCategoryNavigation) => {
    const dispatch = useDispatch();

    const titleCategoryClassNames = classNames(styles.titleCategory, styles[currentPage?.name], {
        [styles.pointer]: isEnabledGoToCategory || preset,
    });

    const subTitleCategoryClassNames = classNames(styles.subCategoryTitle, {
        [styles.pointer]: preset || currentPage.isBundlePage,
    });

    const presetTitleCategoryClassNames = classNames(styles.subCategoryTitle, {
        [styles.pointer]: preset && currentPage.isBundlePage,
    });

    return (
        <React.Fragment>
            <h1
                className={titleCategoryClassNames}
                onClick={() => {
                    if (preset) {
                        dispatch(appActions.updateActiveFilterPreset({ preset: null }));
                    }
                    if (isEnabledGoToParentCategory) {
                        playButtonClickSound();
                        goToParentCategory();
                    }
                }}
            >
                {parentCategory.title}
            </h1>
            <span className={styles.navigationArrow} />
            <h2
                className={subTitleCategoryClassNames}
                onClick={() => {
                    if (preset) {
                        dispatch(appActions.updateActiveFilterPreset({ preset: null }));
                    }
                    if (isEnabledGoToCategory || preset) {
                        playButtonClickSound();
                        onClickCategory();
                    }
                }}
            >
                {currentPage?.title}
            </h2>
            {preset && (
                <React.Fragment>
                    <span className={styles.navigationArrow} />
                    <span
                        className={presetTitleCategoryClassNames}
                        onClick={() => {
                            if (isEnabledGoToCategory) {
                                playButtonClickSound();
                                onClickCategory();
                            }
                        }}
                    >
                        {preset.title}
                    </span>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const TopPanel = function () {
    const state = useAppSelector(stateSelector, equal);
    const refMobileTopPanel: React.RefObject<HTMLDivElement> = React.useRef(null);
    const currentPage = state.currentPage;
    const category = state.categories[currentPage?.name];
    const activePreset = state.activePreset ? getPresetByName(category?.filters?.presets || [], state.activePreset) : null;
    const isEnabledCoupon = !currentPage?.isBundlePage && Account.hasCouponFromCategory(state.coupons, currentPage?.name);
    const parentCategoryName = state.categories[currentPage?.name]?.parentCategory as ICategoryList;
    const parentCategory = state.categories[parentCategoryName];
    const currentCategoryConfig = state.menu[currentPage?.name];
    const isSingleBundlePage = category.theme === SINGLE_BUNDLE_PAGE;
    const currentBundle = state.bundles[state.currentPage?.bundleId];
    const activeCategory = currentCategoryConfig || category;

    React.useEffect(() => {
        if (isMobileOrTabletWindow) {
            let pos = 0;
            let timeout: any;

            const changePosition = (isFixed?: boolean) => {
                clearTimeout(timeout);

                if (!isFixed) {
                    refMobileTopPanel.current?.classList.remove(styles.fixed, styles.visible);
                } else {
                    refMobileTopPanel.current?.classList.add(styles.fixed);
                    timeout = setTimeout(() => {
                        clearTimeout(timeout);
                        refMobileTopPanel.current?.classList.add(styles.visible);
                    }, 0);
                }
            };

            document.addEventListener('scroll', () => {
                const top: number = document.documentElement.scrollTop;
                if (!pos) {
                    if (top >= 160) {
                        pos = top;
                        changePosition();
                    }
                } else {
                    if (top < pos) {
                        changePosition(true);
                        pos = top;
                    } else {
                        changePosition();
                        pos = top;
                    }
                }

                if (top <= 160) {
                    changePosition();
                    pos = top;
                }
            });
        }
    }, []);

    const goToParentCategory = () => {
        openCategoryByName(parentCategory.name);
    };

    const goToCategory = () => {
        openCategoryByName(currentPage.name);
    };

    const historyBack = () => {
        playButtonClickSound();
        if (isMobileOrTabletWindow) {
            if (currentPage?.isBundlePage) {
                openCategoryByName(currentPage.name);
                return;
            } else {
                History.back();
                return;
            }
        }

        if (!History.hasHistory()) {
            openCategoryByName(state.currentPage?.name);
        } else {
            History.back();
        }
    };

    let isEnabledGoToParentCategory = false;
    let isEnabledGoToCategory = false;
    if (currentPage?.isBundlePage) {
        isEnabledGoToCategory = true;
        isEnabledGoToParentCategory = true;
    } else if (parentCategory && !parentCategory.progressionRewardSettings) {
        isEnabledGoToParentCategory = true;
    }

    const isEnabledButtonBack = state.currentPage?.isBundlePage || ['coupons', 'wallet'].includes(state.currentPage?.name);

    if (!state.currentPage) {
        return null;
    }

    const isMobile = isMobileOrTabletWindow;

    let isBackgroundGallery = false;
    if (currentPage?.isBundlePage && currentBundle?.isFullscreenGallery) {
        isBackgroundGallery = true;
    }

    return (
        <div className={styles.topPanelHeader}>
            <div className={styles.topPanelControls}>
                {isEnabledCoupon && (
                    <DivTooltip tooltipBody={<DefaultTooltip text={t('У вас есть купон на эту категорию')} />}>
                        <div className={styles.coupon} id={'guideCoupon'} />
                    </DivTooltip>
                )}
                <div className={styles.title}>
                    {isEnabledButtonBack && <span className={styles.backIcon} onClick={historyBack} />}
                    {!parentCategory ? (
                        <CategoryNavigation
                            currentPage={state.currentPage}
                            onClickCategory={goToCategory}
                            isEnabledGoToCategory={isEnabledGoToCategory && !isSingleBundlePage}
                            preset={activePreset}
                            categoryConfig={currentCategoryConfig || category}
                        />
                    ) : (
                        <SubCategoryNavigation
                            currentPage={state.currentPage}
                            onClickCategory={goToCategory}
                            isEnabledGoToCategory={isEnabledGoToCategory}
                            isEnabledGoToParentCategory={isEnabledGoToParentCategory}
                            goToParentCategory={goToParentCategory}
                            parentCategory={parentCategory}
                            preset={activePreset}
                        />
                    )}
                </div>
                <SoundControl
                    bundle={currentBundle}
                    category={currentCategoryConfig || category}
                    isStartedVideo={state.isStartedVideo}
                    isTrusted={state.isTrusted}
                    className={classNames(styles.control, styles[currentCategoryConfig?.theme], styles[currentPage?.name], {
                        [styles.isBundlePage]: state.currentPage?.isBundlePage,
                    })}
                />
                {!isMobile && isEnabledAnimationByCategory(activeCategory, currentBundle) && !isBackgroundGallery && (
                    <div className={styles.iconWrapper}>
                        <AnimationIcon
                            onChange={(isTurnOn) => {
                                const animationName = getAnimationName(activeCategory, currentBundle);
                                if (activeCategory) {
                                    updateAnimationStatusByName(animationName, !isTurnOn);
                                }
                            }}
                            defaultValue={getAnimationStatusByName(activeCategory, currentBundle)}
                            className={classNames(styles.control, styles[activeCategory?.theme], styles[currentPage?.name], {
                                [styles.isBundlePage]: state.currentPage?.isBundlePage,
                            })}
                        />
                    </div>
                )}
                {!isMobile && isEnabledCategoryVideo(currentCategoryConfig || category) && (
                    <div className={classNames(styles.iconWrapper, { [styles.disabled]: state.isStartedVideo })}>
                        <PlayIcon
                            className={classNames(styles.control, styles[currentCategoryConfig?.theme], styles[currentPage?.name], {
                                [styles.isBundlePage]: state.currentPage?.isBundlePage,
                            })}
                            onClick={() => {
                                startCategoryVideo(currentCategoryConfig || category, currentBundle);
                            }}
                        />
                    </div>
                )}
            </div>
            <GuideWrapper />
        </div>
    );
};

export default TopPanel;
