import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { prepareMysteryBundlesForState } from '~/Layouts/GachaBanner/utils/rewards';
import { GachaAccountState, IGachaAccountStateResponse } from '~/types/gacha';
import { MercatorMysteryContentState } from '~/types/mercator';

export interface IGachaState {
    banners: Nullable<MercatorMysteryContentState>;
    accountState: Nullable<GachaAccountState>;
    ts: number;
    activeTransactions: Record<string, string>;
    isStartedAnimation: boolean;
}

const initialState: Partial<IGachaState> = {
    banners: null,
    activeTransactions: {},
    isStartedAnimation: false,
    accountState: {},
    ts: 0,
};

export const gachaSlice = createSlice({
    name: 'gacha',
    initialState,
    reducers: {
        initGachaBanners(state, action: PayloadAction<MercatorMysteryContentState>) {
            state.banners = prepareMysteryBundlesForState(action.payload);
        },

        updateAccountState(state, action: PayloadAction<IGachaAccountStateResponse>) {
            state.accountState = action.payload.state;
            state.ts = action.payload.ts;
        },

        updateTransactions(state, action: PayloadAction<{ bundleName: string; transactionId: Nullable<string> }>) {
            const activeTransactions = { ...state.activeTransactions };
            activeTransactions[action.payload.bundleName] = action.payload.transactionId;

            state.activeTransactions = activeTransactions;
        },

        updateAnimationState(state, action: PayloadAction<boolean>) {
            state.isStartedAnimation = action.payload;
        },
    },
});

export const gachaActions = gachaSlice.actions;

export default gachaSlice.reducer;
