import * as React from 'react';
import { useDispatch } from 'react-redux';
import GiftIntroWidget from '~/components/GiftPromo/GiftIntroWidget';
import equal from 'fast-deep-equal/react';
import { markShownWelcomeGiftScreen } from '~/utils/gift';
import { POPUPS_NAME } from '../PopupManager';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

const GiftPromo = () => {
    const dispatch = useDispatch();
    const gifts = useAppSelector((state) => state.account.gifts, equal);
    const currentGift = gifts.filter((gift) => !gift.isSecretGift)[0];

    React.useEffect(() => {
        if (isMobileOrTabletWindow && currentGift) {
            dispatch(
                appActions.changeVisibilityPopup({
                    name: POPUPS_NAME.GIFT,
                    isVisible: true,
                    data: { gift: currentGift },
                }),
            );
        }
    }, [currentGift]);

    if (currentGift && !isMobileOrTabletWindow) {
        return (
            <GiftIntroWidget
                gift={currentGift}
                onClick={() => {
                    markShownWelcomeGiftScreen(currentGift.name);
                    dispatch(
                        appActions.changeVisibilityPopup({
                            name: POPUPS_NAME.GIFT,
                            isVisible: true,
                            data: { gift: currentGift },
                        }),
                    );
                }}
            />
        );
    }

    return <></>;
};

export default GiftPromo;
