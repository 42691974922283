import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { Player, PlayerState } from 'video-react';
import styles from './VideoPlayer.scss';
import 'video-react/dist/video-react.css';
import { useDispatch } from 'react-redux';
import { VIDEO_MAP } from '~/components/VideoPlayer/videoMap';
import History from '~/utils/history';
import { isInGameBrowser } from '~/utils/utils';
import VideoSkipLabel from '~/components/VideoPlayer/VideoSkipLabel';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

const stateSelector = (state: RootState) => {
    return {
        isStartedVideo: state.app.isStartedVideo,
        currentVideoName: state.app.currentVideoName,
        currentVideoUrl: state.app.currentVideoUrl,
        onFinishVideoCallback: state.app.onFinishVideoCallback,
        isTrusted: state.app.isTrusted,
        fadeOutVideoInSeconds: state.app.fadeOutVideoInSeconds,
        volume: state.app.volume,
    };
};

const VideoPlayer = () => {
    const dispatch = useDispatch();
    const appState = useAppSelector(stateSelector, equal);
    const [player, setPlayer] = React.useState(null);
    const ref: React.RefObject<HTMLDivElement> = React.useRef(null);

    const finish = () => {
        dispatch(appActions.finishVideo());
        appState.onFinishVideoCallback?.();
    };

    const handleStateChange = (state: PlayerState) => {
        if (appState.fadeOutVideoInSeconds && state.currentTime >= state.duration - appState.fadeOutVideoInSeconds) {
            ref?.current?.classList.remove(styles.active);
        }
        if (state.currentTime >= state.duration) {
            ref?.current?.classList.remove(styles.active);
            finish();
        }
    };

    React.useEffect(() => {
        appState.isStartedVideo &&
            setTimeout(() => {
                ref?.current?.classList.add(styles.active);
                if (!isInGameBrowser && player && History.getHistory()?.length <= 1) {
                    try {
                        player.muted = !appState.isTrusted;
                    } catch (e) {
                        // autoplay police
                    }
                }
                try {
                    player?.play();
                    player?.subscribeToStateChange(handleStateChange.bind(this));
                } catch (e) {
                    console.warn('VideoPlayer error', e);
                }
            }, 200);
    });

    if (!appState.currentVideoName && !appState.currentVideoUrl) {
        return null;
    }

    return (
        <div className={styles.wrapper} ref={ref}>
            <div className={styles.player}>
                <Player
                    ref={(player: any) => {
                        setPlayer(player);
                    }}
                    volume={appState.volume || 100}
                    autoPlay={appState.isStartedVideo}
                    fluid
                >
                    <source src={appState.currentVideoName && appState.currentVideoName in VIDEO_MAP ? VIDEO_MAP[appState.currentVideoName] : appState.currentVideoUrl} type="video/mp4" />
                </Player>
                <VideoSkipLabel callback={finish} />
            </div>
        </div>
    );
};

export default VideoPlayer;
