import { memo, lazy, Suspense } from 'react';
import GachaBannerLoader from '../Components/GachaBannerLoader/GachaBannerLoader';

const LazyContainer = lazy(
    () =>
        import(
            /* webpackChunkName: "gacha" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: true */
            '~/Layouts/GachaBanner/Container/GachaBannerContainer'
        ),
);

interface ILazyGachaBannerContainer {
    bundle: IBundle;
}

const LazyGachaBannerContainer = ({ bundle }: ILazyGachaBannerContainer) => {
    return (
        <Suspense fallback={<GachaBannerLoader />}>
            <LazyContainer bundle={bundle} />
        </Suspense>
    );
};

export default memo(LazyGachaBannerContainer);
