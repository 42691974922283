import * as React from 'react';
import styles from './SubCategoryMenu.scss';
import classNames from 'classnames';
import History from '~/utils/history';
import { getUrl, urlsMap } from '~/utils/menu';
import { t } from '~/utils/localization';
import useClickAway from '~/hooks/useClickAway';
import LabelDecorator, { LABEL_TYPE } from '~/decorators/LabelDecorator/LabelDecorator';
import { isLabelNewShownForCategory } from '~/utils/labels';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import GlowEffectDecorator, { ParticlesAnimation } from '~/components/GlowEffect/GlowEffectDecorator';
import { isShinyCategory } from '~/utils/shiny/shiny';
import { getAvailableSubCategories } from '~/utils/category';
import equal from 'fast-deep-equal/react';
import { playTabClickSound } from '~/api/WoWsClient';
import { RootState, useAppSelector } from '~/Store';
import { CategoryType } from '~/types/category';
import CountDown from '../CountDown/CountDown';
import { isValidDate } from '~/utils/time';

interface ISubCategoryMenu {
    category: ICategoryList;
    onChangeVisible?: (isVisible: boolean) => void;
}

const stateSelector = (state: RootState) => {
    return {
        viewedLabels: state.account.viewedLabels,
        port: state.app.port,
        currentPage: state.app.currentPage,
        categories: state.app.categories,
    };
};

const SubCategoryMenu = (props: ISubCategoryMenu) => {
    const state = useAppSelector(stateSelector, equal);
    const [isVisible, setVisible] = React.useState(false);
    const refMobileSubMenu: React.RefObject<HTMLDivElement> = React.useRef(null);

    useClickAway(refMobileSubMenu, () => {
        if (!props.category) {
            return null;
        }

        if (isVisible) {
            props.onChangeVisible && props.onChangeVisible(false);
        }

        setVisible(false);
    });

    if (state.currentPage?.isBundlePage || state.port?.isVisible) {
        return null;
    }

    const category = state.categories[props.category];

    if (category.type === CategoryType.FORECAST) {
        return null;
    }

    const parentCategory = category.parentCategoryData || category;
    let subCategories = [];

    if (category?.subCategories.length) {
        subCategories = category.subCategories || [];
    } else {
        subCategories = parentCategory?.subCategories || [];
    }

    subCategories = [...new Set(subCategories)];

    const availableSubCategories = getAvailableSubCategories(subCategories as ICategoryList[], state.categories);

    if (!subCategories.length || availableSubCategories?.length < 2) {
        return null;
    }

    const oldActiveElement = document.querySelectorAll(`.${styles.menuItem}.${styles.isActive}`)[0];
    oldActiveElement?.classList.remove(styles.isActive);

    const activeItemElement = document.querySelectorAll(`[data-sub-menu-category="${props.category}"]`)[0];
    activeItemElement?.classList.add(styles.isActive);

    const changeCategory = (category: string) => {
        if (props.category === category) {
            return;
        }

        playTabClickSound();

        let url = getUrl(urlsMap.subCategory, {
            categoryName: parentCategory?.name || props.category,
            subCategoryName: category,
        });

        if (category === parentCategory?.name) {
            url = getUrl(urlsMap.categories, {
                categoryName: category,
            });
        }

        History.navigate(url);
    };

    const currentCategory = (state.categories[state.currentPage.name] || {}) as ICategory;

    const mobileClassNames = classNames(styles.mobileWrapperItems, {
        [styles.isVisible]: !isVisible,
    });

    const changeMobileCategory = (category: string) => {
        changeCategory(category);
        setVisible(false);
        props.onChangeVisible && props.onChangeVisible(false);
    };

    const mobileWrapperClassNames = classNames(styles.mobileWrapper, {
        [styles.active]: isVisible,
    });

    const mobileAvailableSubCategories = subCategories.filter((categoryName: ICategoryList) => {
        const subCategory = state.categories[categoryName];
        return !(!subCategory || (parentCategory?.name !== currentCategory?.name && !subCategory?.bundles?.length));
    });

    const isAvailablePromoTimer = currentCategory.promoTimer?.timerActiveTill && isValidDate(currentCategory.promoTimer.timerActiveTill);
    const timer = isAvailablePromoTimer ? currentCategory.promoTimer?.timerActiveTill : currentCategory.activeTill;
    const timerLabel = isAvailablePromoTimer ? currentCategory.promoTimer.timerTitle || t('Starts in:') : t('Ends in:');

    const getTimer = () => {
        return (
            <>
                {timer && currentCategory.useSmallTimer && (
                    <div className={styles.timerContent}>
                        <div className={styles.timerTitle}>{timerLabel}</div>
                        <div className={styles.timer}>
                            <CountDown date={timer} withoutIcon key={`subcategory_timer_${currentCategory.name}`} />
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        <React.Fragment>
            {mobileAvailableSubCategories?.length > 1 && (
                <div className={mobileWrapperClassNames} ref={refMobileSubMenu}>
                    <div className={styles.mobileWrapperContent}>
                        <div
                            className={styles.mobileWrapperTitle}
                            onClick={() => {
                                setVisible(!isVisible);
                                props.onChangeVisible && props.onChangeVisible(!isVisible);
                            }}
                        >
                            {parentCategory?.name === currentCategory?.name ? t('Рекомендованное') : currentCategory.title}
                        </div>
                        {!isVisible && getTimer()}
                    </div>
                    <div className={mobileClassNames}>
                        {subCategories.map((categoryName: ICategoryList) => {
                            if (state.currentPage?.name === categoryName) {
                                return null;
                            }

                            const subCategory = state.categories[categoryName];
                            const className = classNames(styles.mobileItem);

                            const isEnabledLabelNew = isLabelNewShownForCategory(subCategory.name);
                            const _changeCategory = () => {
                                changeMobileCategory(categoryName);
                            };

                            return (
                                <LabelDecorator
                                    id={subCategory.name}
                                    isEnabled={isEnabledLabelNew}
                                    type={LABEL_TYPE.category}
                                    className={className}
                                    key={subCategory.name}
                                    onClickDecorator={_changeCategory}
                                >
                                    <div className={className}>
                                        <div className={styles.mobileItemTitleWrapper}>
                                            <div className={styles.mobileItemTitle}>{parentCategory?.name === categoryName ? parentCategory.tabTitle || t('Рекомендованное') : subCategory.title}</div>
                                            <LabelContainer className={styles.categoryMobileLabel} isVisible={isEnabledLabelNew} />
                                        </div>
                                    </div>
                                </LabelDecorator>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className={styles.wrapper}>
                <div className={styles.tabs}>
                    {subCategories.map((categoryName: ICategoryList) => {
                        const isFeatured = parentCategory?.name === categoryName;
                        const bundleSpliceLength = isFeatured ? 4 : null;
                        const subCategory = state.categories[categoryName] as ICategory;

                        if (!subCategory) {
                            return null;
                        }

                        const isShiny = isShinyCategory(subCategory.name as ICategoryList, bundleSpliceLength, isFeatured);
                        const className = classNames(
                            styles.menuItem,
                            {
                                [styles.isActive]: category.name === categoryName,
                                [styles.isShiny]: isShiny,
                            },
                            `armory__auto--submenu_item`,
                        );

                        if (!isFeatured && !subCategory?.bundles?.length) {
                            return null;
                        }

                        const isEnabledLabelNew = isLabelNewShownForCategory(subCategory.name, bundleSpliceLength, isFeatured);
                        const _changeCategory = () => {
                            changeCategory(categoryName);
                        };

                        return (
                            <LabelDecorator
                                id={subCategory.name}
                                isEnabled={isEnabledLabelNew}
                                type={LABEL_TYPE.category}
                                key={subCategory.name}
                                className={styles.menuItemWrapper}
                                onClickDecorator={_changeCategory}
                            >
                                <GlowEffectDecorator
                                    isEnabled={isShiny}
                                    withoutSun={true}
                                    className={styles.particles}
                                    particles={{
                                        value: 20,
                                        config: ParticlesAnimation.CIRCLE,
                                    }}
                                >
                                    <div className={className} data-sub-menu-category={categoryName}>
                                        <div className={styles.categoryTitle}>
                                            <span>{parentCategory?.name === categoryName ? parentCategory.tabTitle || t('Рекомендованное') : subCategory.title}</span>
                                            <LabelContainer className={styles.categoryLabel} isVisible={isEnabledLabelNew} />
                                        </div>
                                    </div>
                                </GlowEffectDecorator>
                            </LabelDecorator>
                        );
                    })}
                </div>
                {getTimer()}
            </div>
        </React.Fragment>
    );
};

export default SubCategoryMenu;
