import * as React from 'react';
import { useDispatch, shallowEqual } from 'react-redux';
import IframeProxy from '~/api/IframeProxy';
import styles from '../../components/View/View.scss';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { settings } from '~/utils/settings';
import Account from '~/account/Account';
import History from '~/utils/history';
import { urlsMap } from '~/utils/menu';
import classNames from 'classnames';
import { useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

const DockyardContainer = () => {
    const dispatch = useDispatch();
    const [isVisible, setVisible] = React.useState<boolean>(false);
    const isBlurView = useAppSelector((state) => state.app.isBlurView, shallowEqual);
    const ref: React.RefObject<HTMLIFrameElement> = React.createRef();

    const style: any = {
        width: '100%',
        height: !isMobileOrTabletWindow ? `100%` : 'calc(100% - 40px)',
        position: 'relative',
        top: isMobileOrTabletWindow ? '35px' : '-13px',
        display: isVisible ? 'block' : 'none',
    };

    React.useEffect(() => {
        if (!Account.getAccount()) {
            History.navigate(urlsMap.home);
            return;
        }

        dispatch(appActions.setVisibleTopPanel());
        dispatch(appActions.setFetching(true));

        const Proxy = new IframeProxy(dispatch, ref.current, () => {
            setVisible(true);
            dispatch(appActions.setFetching(false));
        });

        Proxy.listen();

        return () => {
            dispatch(appActions.setVisibleTopPanel(true));
            Proxy.remove();
        };
    }, []);

    const url = settings.urls.dockyard.replace('{lang}', settings.languageCode);

    return (
        <>
            <div
                className={classNames(styles.realPurchase, {
                    [styles.fullView]: isBlurView,
                })}
            >
                <iframe src={url} ref={ref} style={style} />
            </div>
        </>
    );
};

export default DockyardContainer;
