import { getCDNUrl, getStaticPathFromClientOrWeb } from '~/utils/utils';
import { CATEGORIES } from '~/const';
import store from '~/Store';

export const SANTA_SIZE: Record<string, string> = {
    santa_1: 'small',
    santa_2: 'medium',
    santa_3: 'big',
};

export const DEFAULT_SELECTED_INDEX_BY_GROUP: Record<string, number> = {
    santa_1: 2,
    santa_2: 2,
    santa_3: 2,
};

export const isSantaBundle = (bundle: IBundle): boolean => {
    const availableTypes = Object.keys(SANTA_SIZE) || [];

    return availableTypes.includes(bundle?.groupName);
};

export const SANTA_BUNDLES_AUDIO_MAP: any = {
    small: getCDNUrl('armory/1211/hover_effect_small_v4.mp3'),
    medium: getCDNUrl('armory/1211/hover_effect_medium_v4.mp3'),
    big: getCDNUrl('armory/1211/hover_effect_big_v1.mp3'),
};

export const SANTA_CONFIRM_PURCHASE_VIDEO: Record<'global' | 'cn', Record<string, IVideo>> = {
    cn: {
        small: {
            mp4: getCDNUrl('armory/1211/confirmation_small_santa_cn_v1.mp4'),
            webm: getCDNUrl('armory/1211/confirmation_small_santa_cn_v1.webm'),
            clientVideoName: 'ny24/confirmation_small_santa_v1.webm',
        },
        medium: {
            mp4: getCDNUrl('armory/1211/confirmation_medium_santa_cn_v1.mp4'),
            webm: getCDNUrl('armory/1211/confirmation_medium_santa_cn_v1.webm'),
            clientVideoName: 'ny24/confirmation_medium_santa_v1.webm',
        },
        big: {
            mp4: getCDNUrl('armory/1211/confirmation_big_santa_cn_v1.mp4'),
            webm: getCDNUrl('armory/1211/confirmation_big_santa_cn_v1.webm'),
            clientVideoName: 'ny24/confirmation_big_santa_v1.webm',
        },
    },
    global: {
        small: {
            mp4: getCDNUrl('armory/1211/confirmation_small_santa_v1.mp4'),
            webm: getCDNUrl('armory/1211/confirmation_small_santa_v1.webm'),
            clientVideoName: 'ny24/confirmation_small_santa_v1.webm',
        },
        medium: {
            mp4: getCDNUrl('armory/1211/confirmation_medium_santa_v1.mp4'),
            webm: getCDNUrl('armory/1211/confirmation_medium_santa_v1.webm'),
            clientVideoName: 'ny24/confirmation_medium_santa_v1.webm',
        },
        big: {
            mp4: getCDNUrl('armory/1211/confirmation_big_santa_v1.mp4'),
            webm: getCDNUrl('armory/1211/confirmation_big_santa_v1.webm'),
            clientVideoName: 'ny24/confirmation_big_santa_v1.webm',
        },
    },
};

export const SANTA_VIDEO_BACKGROUNDS: Record<string, IVideo> = {
    [CATEGORIES.SANTA_DOUBLOONS_CONTAINERS]: {
        mp4: getCDNUrl('armory/1211/gold_background_v3.mp4'),
        webm: getCDNUrl('armory/1211/gold_background_v3.webm'),
        clientVideoName: 'ny24/gold_background_v3.webm',
    },
    [CATEGORIES.SANTA_CONTAINERS]: {
        mp4: getCDNUrl('armory/1211/real_background_v2.mp4'),
        webm: getCDNUrl('armory/1211/real_background_v2.webm'),
        clientVideoName: 'ny24/real_background_v2.webm',
    },
};

type VideoAnimation = {
    idle: ReturnType<typeof getStaticPathFromClientOrWeb>;
    hover: ReturnType<typeof getStaticPathFromClientOrWeb>;
};
export const SANTA_BUNDLE_CARD_VIDEOS: Record<'global' | 'cn', Record<string, VideoAnimation>> = {
    global: {
        small: {
            idle: getStaticPathFromClientOrWeb({
                client: 'armory/ny24/SantaSmall_idle_v11.webm',
                cdn: getCDNUrl('armory/1211/SantaSmall_idle_v11.webm', true),
            }),
            hover: getStaticPathFromClientOrWeb({
                client: 'armory/ny24/SantaSmall_hover_v11.webm',
                cdn: getCDNUrl('armory/1211/SantaSmall_hover_v11.webm', true),
            }),
        },
        medium: {
            idle: getStaticPathFromClientOrWeb({
                cdn: getCDNUrl('armory/1211/SantaMiddle_idle_v11.webm', true),
                client: 'armory/ny24/SantaMiddle_idle_v11.webm',
            }),
            hover: getStaticPathFromClientOrWeb({
                client: 'armory/ny24/SantaMiddle_hover_v11.webm',
                cdn: getCDNUrl('armory/1211/SantaMiddle_hover_v11.webm', true),
            }),
        },
        big: {
            idle: getStaticPathFromClientOrWeb({
                client: 'armory/ny24/SantaLarge_idle_v12.webm',
                cdn: getCDNUrl('armory/1211/SantaLarge_idle_v12.webm', true),
            }),
            hover: getStaticPathFromClientOrWeb({
                client: 'armory/ny24/SantaLarge_hover_v12.webm',
                cdn: getCDNUrl('armory/1211/SantaLarge_hover_v12.webm', true),
            }),
        },
    },
    cn: {
        small: {
            idle: getStaticPathFromClientOrWeb({
                client: 'armory/ny24/SantaSmall_idle_cn_v11.webm',
                cdn: getCDNUrl('armory/1211/SantaSmall_cn_idle_v13.webm', true),
            }),
            hover: getStaticPathFromClientOrWeb({
                client: 'armory/ny24/SantaSmall_hover_cn_v11.webm',
                cdn: getCDNUrl('armory/1211/SantaSmall_cn_hover_v13.webm', true),
            }),
        },
        medium: {
            idle: getStaticPathFromClientOrWeb({
                client: 'armory/ny24/SantaMiddle_idle_cn_v11.webm',
                cdn: getCDNUrl('armory/1211/SantaMiddle_cn_idle_v12.webm', true),
            }),
            hover: getStaticPathFromClientOrWeb({
                client: 'armory/ny24/SantaMiddle_hover_cn_v11.webm',
                cdn: getCDNUrl('armory/1211/SantaMiddle_cn_hover_v12.webm', true),
            }),
        },
        big: {
            idle: getStaticPathFromClientOrWeb({
                client: 'armory/ny24/SantaLarge_idle_cn_v12.webm',
                cdn: getCDNUrl('armory/1211/SantaLarge_cn_idle_v13.webm', true),
            }),
            hover: getStaticPathFromClientOrWeb({
                client: 'armory/ny24/SantaLarge_hover_cn_v12.webm',
                cdn: getCDNUrl('armory/1211/SantaLarge_cn_hover_v13.webm', true),
            }),
        },
    },
};

export const isAvailableSwitcherBetweenSantaCategories = () => {
    const { categories } = store.getState().app;
    const categoriesNames = Object.keys(categories);
    const hasBothCategories = categoriesNames?.includes(CATEGORIES.SANTA_CONTAINERS) && categoriesNames?.includes(CATEGORIES.SANTA_DOUBLOONS_CONTAINERS);

    return hasBothCategories;
};

export const isGoldShowcase = (categoryName: string) => {
    return [CATEGORIES.SANTA_DOUBLOONS_CONTAINERS].includes(categoryName);
};
