import { useDispatch } from 'react-redux';
import { appActions } from '~/Store/appSlice';
import Button from '~/components/Button/Button';
import { Popup, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { t } from '~/utils/localization';

const ErrorPopup = () => {
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(appActions.changeVisibilityPopup({ name: null }));
    };

    return (
        <Popup onClose={onClose} renderEscButton={true}>
            <PopupHeader title={t('Произошла ошибка. Повторите попытку позже')} />
            <PopupFooter>
                <Button label={t('Закрыть')} onClick={onClose} />
            </PopupFooter>
        </Popup>
    );
};

export default ErrorPopup;
