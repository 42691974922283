import * as React from 'react';
import styles from './BundleLimitPopup.scss';
import { Popup, PopupFooter } from '~/components/Popup/Popup';
import BundleLimitTooltip from '~/components/BundleLimitWidget/Tooltip/BundleLimitTooltip';
import { useDispatch } from 'react-redux';
import Button from '~/components/Button/Button';
import { t } from '~/utils/localization';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { appActions } from '~/Store/appSlice';
import { POPUPS_NAME } from '~/components/PopupManager';

const BundleLimitPopup = () => {
    const { popup } = React.useContext(PopupManagerContext);
    const dispatch = useDispatch();
    const totalPurchased = popup?.data?.total || 0;
    const bundlesInfo = popup?.data?.bundlesInfo || [];

    const onClose = () => {
        dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.BUNDLE_LIMIT_INFO }));
    };

    return (
        <Popup onClose={onClose} renderEscButton={false}>
            <BundleLimitTooltip total={totalPurchased} bundlesInfo={bundlesInfo} />
            <PopupFooter className={styles.footer}>
                <Button label={t('Закрыть')} className={styles.buttonClose} onClick={onClose} />
            </PopupFooter>
        </Popup>
    );
};

export default BundleLimitPopup;
