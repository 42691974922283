import * as React from 'react';
import styles from './ScrollToTopButton.scss';
import RefManager, { RefManagerKeys } from '~/RefManager';
import store from '~/Store';
import { FilterPosition } from '~/types/category';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { scrollTop } from '~/utils/scrollTop';
import debounce from '~/utils/debounce';

interface IButtonToTop {
    currentPage: ICurrentPage;
}

const onScroll = () => {
    const button = RefManager.getRef(RefManagerKeys.ButtonToTop);
    const mainContainerElement = RefManager.getRef(RefManagerKeys.MainContainer);
    const topPanelElement = RefManager.getRef(RefManagerKeys.TopPanel);
    const filtersElement = RefManager.getRef(RefManagerKeys.Filters);
    const { filterPosition } = store.getState().app;
    let padding = 300;

    if (filtersElement && filterPosition === FilterPosition.RIGHT) {
        padding = filtersElement?.getBoundingClientRect().top + filtersElement.offsetHeight + 100;
    }

    if (mainContainerElement.scrollTop > padding) {
        button.classList.add(styles.buttonToTopActive);
    } else {
        button.classList.remove(styles.buttonToTopActive);
    }

    if (mainContainerElement.scrollTop > 10) {
        topPanelElement?.classList.add('shadow');
    } else {
        topPanelElement?.classList.remove('shadow');
    }
};

const ScrollToTopButton = ({ currentPage }: IButtonToTop) => {
    const setRef = (ref: HTMLDivElement) => {
        if (ref) {
            RefManager.setRef(RefManagerKeys.ButtonToTop, ref);
        }
    };

    const onClick = () => {
        playButtonClickSound();
        scrollTop(0, true);

        if (currentPage) {
            dwhExport.send(DWH_EVENTS.SCROLL_TO_TOP, { category: currentPage.name });
        }
    };

    React.useEffect(() => {
        const mainContainerElement = RefManager.getRef(RefManagerKeys.MainContainer);
        if (!mainContainerElement) {
            return;
        }
        const callback = debounce(onScroll, 25);
        mainContainerElement.addEventListener('scroll', callback);
        return () => {
            mainContainerElement.removeEventListener('scroll', callback);
        };
    }, []);

    return <div className={styles.button} onClick={onClick} ref={setRef} />;
};

export default ScrollToTopButton;
