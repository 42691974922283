import { createSlice } from '@reduxjs/toolkit';
import type { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { getLootboxesMap } from '~/components/BundleLimitWidget/settings';
import { getInitialPopup } from '~/components/Popups/settings';
import { PortPreviewType } from '~/components/Port/settings';
import { VIDEO_NAMES } from '~/components/VideoPlayer/videoMap';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import { CLIENT_BUTTON_COPYRIGHT_BY_ROUTE, FILTER_CURRENCY_NAME, GET_PARAM_CLIENT_SOURCE } from '~/const';
import { FilterPosition, ISortMethods, PRESETS, SortNames } from '~/types/category';
import { getBundlesAndCategoriesMap, prepareBundlesForState, prepareCategoryBundlesPricesInfo, prepareFiltersByBundles } from '~/utils/bundles';
import { getCategoryCurrencies, getPresetByName } from '~/utils/category';
import { isDisabledCoupon } from '~/utils/coupons';
import {
    getStateAfterChangedPreset,
    getStateAfterChangedCurrencyDiapason,
    getStateFromSetFiltersByUrl,
    getStateAfterResetFilter,
    getFacetPresetsFilters,
    getFilterPositionFromAppInit,
    getStateAfterChangeMultipleFilters,
    getStateAfterChangedFilter,
    prepareFiltersPresetsFromState,
    filteredByFilters,
    addFiltersToLocation,
    FILTERS,
    ChangeMultipleDiapasonOfCurrencies,
    FiltersByQuery,
} from '~/utils/filters';
import { getArmoryContentFromSettings, prepareArmoryBundles } from '~/utils/global_content';
import { getMenuForState } from '~/utils/menu';
import { armoryState } from '~/utils/settings';
import { arrayToObjectByKey, getSearchParam } from '~/utils/utils';
import { RootState } from '.';
import { POPUPS_NAME } from '~/components/PopupManager';
import { changeTTXVisibility } from '@wg/web2clientapi/port/changeTTXVisibility';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import { getSearchResults } from '~/search/search';
import { IDevModeParams } from './accountSlice';

export type BundleCategoryType = Record<string, number[]>;
export type VehicleClassType = Record<string, IVehicleClass>;
export type NationsType = Record<string, INation>;
export interface IInfoScreen {
    isVisible: boolean;
    name?: string;
}

export type GroupBundlesType = Record<string, number>;

export interface IInitApp {
    response: {
        vehicleTypes: any;
        nations: any;
        data: IBundleList;
        currencies: any;
    };
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    deniedBundlesByUniqueItems: number[];
}

export interface IChangeVisibilityPort {
    isVisible: boolean;
    shipId?: number | null;
    exteriorId?: number | null;
    portPreviewType?: PortPreviewType;
    id?: number | string;
    isLoading?: boolean;
    sourceUrl?: string;
    showTTX?: boolean;
    itemType?: string;
    additionalData?: Record<string, any>;
}

export interface IUpdateCategoryByFilters {
    category: ICategoryList;
    bundles?: number[];
    bundlesList?: IBundleList;
    filterName?: FILTER_INFO_NAME;
    filterValue?: string;
}

export interface IChangeMultiplyFilters {
    category?: ICategoryList;
    triggerFilterName?: FILTER_INFO_NAME;
    data: Record<string, string[] | number[]>;
}

export const HIDE_ALL_POPUPS = 'all';

export interface IAppState {
    currentPage?: ICurrentPage;
    isFinishedRequest?: boolean;
    bundles: IBundleList;
    categoryBundlesPricesInfo?: IBundlePricesInfo;
    defaultCategoryBundlesPricesInfo?: IBundlePricesInfo;
    featuring: IFeature[];
    currencies: Record<string, Currency>;
    bundleCategory?: BundleCategoryType;
    port?: IPort;
    popups: IPopup[];
    popupActive: IPopup;
    vehicleTypes?: VehicleClassType;
    nations?: NationsType;
    filters?: FiltersTypes;
    facetState?: FiltersTypes;
    facetCurrenciesState?: FacetCurrenciesState;
    filterPosition: FilterPosition;
    sortedBy?: ISortCategories;
    activePreset?: string;
    filtersDiapasonCurrency?: FiltersDiapasonCurrencies;
    disabledCategories?: string[];
    isFetching: boolean;
    visibleCategoryCurrencies: string[];
    categoryCurrencies: Record<string, string[]>;
    infoScreen: IInfoScreen;
    currentNotificationVisibleName?: string;
    viewClassName: string;
    viewBackground: string;
    isVisibleTopPanel: boolean;
    isVisibleMenu: boolean;
    isFullscreen: boolean;
    parallaxAnimationShip: number;
    searchResults: ISearchResultItem[];
    isStartedVideo: boolean;
    fadeOutVideoInSeconds?: number;
    volume: number;
    guide?: {
        name: GUIDE_NAMES;
    };
    isBlurView: boolean;
    isHiddenMobileNavigate: boolean;
    devMode: IDevModeParams;
    groupBundles?: GroupBundlesType;
    menu?: IMenuMap;
    clientSource: keyof typeof CLIENT_BUTTON_COPYRIGHT_BY_ROUTE;
    currentVideoName?: VIDEO_NAMES | string;
    currentVideoUrl?: string;
    onFinishVideoCallback?: () => void;
    categories: ICategories;
    iframePopupName?: string;
    isTrusted: boolean;
    categoriesAnimationStatuses: ICategoriesAnimationStatuses;
    soundStatus: SoundStatus;
    lootboxesBundlesMap?: ILootboxesBundlesMap;
    focusPreset?: ICategoryPresets;
}

const initialState: Partial<IAppState> = {
    featuring: [],
    popups: [],
    popupActive: null,
    disabledCategories: [],
    visibleCategoryCurrencies: [],
    categoryCurrencies: {},
    isFetching: false,
    filtersDiapasonCurrency: {},
    viewClassName: null,
    viewBackground: null,
    isVisibleTopPanel: true,
    isVisibleMenu: true,
    isFullscreen: false,
    parallaxAnimationShip: null,
    searchResults: null,
    isStartedVideo: false,
    devMode: {},
    isBlurView: false,
    isHiddenMobileNavigate: false,
    categories: {},
    clientSource: null,
    activePreset: null,
    filterPosition: FilterPosition.TOP,
    iframePopupName: null,
    isTrusted: false,
    categoriesAnimationStatuses: {},
    soundStatus: {},
    lootboxesBundlesMap: null,
    volume: null,
    port: {
        isVisible: false,
    },
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        changeCurrentPage(state, action: PayloadAction<ICurrentPage>) {
            state.currentPage = action.payload;
            state.visibleCategoryCurrencies = action.payload.currencies ? action.payload.currencies : [];
        },

        finishLoadingResource(state) {
            state.isFinishedRequest = true;
        },

        init(state, action: PayloadAction<IInitApp>) {
            const armoryContent = getArmoryContentFromSettings(action.payload.response.data, action.payload.response.currencies);
            const preparedBundles = prepareArmoryBundles(action.payload.response.data, armoryContent.categories);
            const preparedCoupons = armoryState.account?.usedCoupons
                ? armoryContent.coupons.map((coupon) => {
                      return { ...coupon, isDisabled: isDisabledCoupon(coupon, armoryState.account.usedCoupons) };
                  })
                : armoryContent.coupons;
            const bundles = prepareBundlesForState(preparedBundles, action.payload.purchasedLimitedBundles, action.payload.deniedBundlesByUniqueItems, preparedCoupons);
            prepareFiltersByBundles(bundles, armoryContent.categories);

            const nations =
                action.payload.response.nations?.reduce((result: any, item: INation) => {
                    result[item.name] = item;
                    return result;
                }, {}) || [];

            const vehicleTypes =
                action.payload.response.vehicleTypes?.reduce((result: any, item: IVehicleClass) => {
                    result[item.name] = item;
                    return result;
                }, {}) || [];

            const bundlesCategory = getBundlesAndCategoriesMap(bundles, armoryContent.categories);
            const initialPopup = getInitialPopup();

            state.featuring = armoryContent.featuring;
            state.categories = prepareFiltersPresetsFromState(armoryContent.categories, bundles, bundlesCategory);
            state.isFinishedRequest = false;
            state.bundles = bundles;
            state.categoryBundlesPricesInfo = prepareCategoryBundlesPricesInfo(bundles);
            state.defaultCategoryBundlesPricesInfo = prepareCategoryBundlesPricesInfo(bundles, true);
            state.vehicleTypes = vehicleTypes;
            state.nations = nations;
            state.bundleCategory = bundlesCategory;
            state.currencies = arrayToObjectByKey(armoryContent.currencies, 'name');
            state.popups = initialPopup ? [initialPopup] : [];
            state.popupActive = initialPopup;
            state.clientSource = getSearchParam(GET_PARAM_CLIENT_SOURCE) as keyof typeof CLIENT_BUTTON_COPYRIGHT_BY_ROUTE;
            state.filterPosition = getFilterPositionFromAppInit();
            state.lootboxesBundlesMap = getLootboxesMap(bundles);
            state.menu = getMenuForState(armoryContent.categories, bundles, armoryContent.featuring);
            state.categoryCurrencies = getCategoryCurrencies(bundles, state.categories);
        },

        updateBundlePricesInfo(state, action: PayloadAction<{ category: ICategoryList; bundles: IBundleList }>) {
            state.categoryBundlesPricesInfo = {
                ...(state.categoryBundlesPricesInfo || {}),
                [action.payload.category]: prepareCategoryBundlesPricesInfo(action.payload.bundles)[action.payload.category],
            };
        },

        updateBundles(state: IAppState, action: PayloadAction<{ purchasedLimitedBundles: AccountPurchasedLimitedBundles; deniedBundlesByUniqueItems: number[]; coupons: ICoupon[] }>) {
            const updateBundles = prepareBundlesForState(state.bundles, action.payload.purchasedLimitedBundles, action.payload.deniedBundlesByUniqueItems, action.payload.coupons);
            prepareFiltersByBundles(updateBundles, state.categories);
            state.bundles = updateBundles;
        },

        changeVisibilityPort(state, action: PayloadAction<IChangeVisibilityPort>) {
            state.port = action.payload;
        },

        updateCategoryByFilters(state, action: PayloadAction<IUpdateCategoryByFilters>) {
            const currenciesDiapason =
                action.payload.filterName === FILTER_CURRENCY_NAME
                    ? state.facetCurrenciesState?.[action.payload.category]
                    : getFacetPresetsFilters(Object.values(action.payload.bundlesList))?.currenciesDiapason;

            state.bundleCategory = {
                ...state.bundleCategory,
                [action.payload.category]: action.payload.bundles,
            };
            state.facetCurrenciesState = {
                ...(state.facetCurrenciesState || {}),
                [action.payload.category]: currenciesDiapason,
            };
        },

        changeVisibilityPopup(state, action: PayloadAction<{ name: null | string | typeof HIDE_ALL_POPUPS; isVisible?: boolean; data?: any; forcedClosurePopupNames?: POPUPS_NAME[] }>) {
            if (state.port?.isVisible && state?.port?.showTTX) {
                changeTTXVisibility(!action.payload.isVisible);
            }

            const filteredPopups = (popups: IPopup[]) => {
                if (action.payload.forcedClosurePopupNames) {
                    return popups.filter((popup) => !action.payload.forcedClosurePopupNames.includes(popup.name as POPUPS_NAME));
                }
                return popups;
            };

            if (!action.payload.isVisible) {
                let popups: IPopup[] = [];

                if (action.payload.name && action.payload.name === HIDE_ALL_POPUPS) {
                    popups = [];
                } else if (action.payload.name) {
                    popups = state.popups.filter((popup) => popup.name !== action.payload.name);
                } else {
                    popups = state.popups.slice(0, state.popups.length - 1);
                }

                if (!state.port?.isVisible) {
                    updateBrowserControlState(!!popups.length);
                }

                state.popups = filteredPopups(popups);
                state.popupActive = popups.slice(-1)[0] || null;
                state.isBlurView = action.payload.isVisible;
                return;
            }

            const popup: IPopup = {
                name: action.payload.name,
                data: action.payload.data,
            };

            state.popups = filteredPopups([...state.popups, popup]);
            state.popupActive = popup;
            state.isBlurView = action.payload.isVisible;

            if (!state.port?.isVisible) {
                updateBrowserControlState(!!state.popups.length);
            }
        },

        changeMultiplyFilters(state: IAppState, action: PayloadAction<IChangeMultiplyFilters>) {
            const result = getStateAfterChangeMultipleFilters(state, action.payload);
            state.facetState = result.facetState;
            state.filters = result.filters;
        },

        changeFilter(state, action: PayloadAction<{ category: ICategoryList; name: FILTER_INFO_NAME; value: string }>) {
            const result = getStateAfterChangedFilter(state as IAppState, action.payload);
            state.facetState = result.facetState;
            state.filters = result.filters;
            state.filtersDiapasonCurrency = result.filtersDiapasonCurrency;
        },

        resetFilter(state: IAppState) {
            state.filters = {};
            state.filtersDiapasonCurrency = {};
            state.facetState = {};
            state.bundleCategory = getBundlesAndCategoriesMap(state.bundles, state.categories);
        },

        resetCategoryFilter(state: IAppState, action: PayloadAction<{ category: ICategoryList; withoutUpdateHistory?: boolean }>) {
            const result = getStateAfterResetFilter(state, action.payload.category, action.payload.withoutUpdateHistory);
            state.bundleCategory = result.bundleCategory;
            state.categoryBundlesPricesInfo = result.categoryBundlesPricesInfo;
            state.facetCurrenciesState = result.facetCurrenciesState;
            state.facetState = result.facetState;
            state.filters = result.filters;
            state.filtersDiapasonCurrency = result.filtersDiapasonCurrency;
        },

        setFiltersByQuery(
            state: IAppState,
            action: PayloadAction<{ category: ICategoryList; filters?: FILTERS; coupons?: ICoupon[]; currencyDiapason?: ChangeMultipleDiapasonOfCurrencies; presetName?: string }>,
        ) {
            const result = getStateFromSetFiltersByUrl(state, action.payload.category, action.payload);
            state.activePreset = result.activePreset;
            state.bundleCategory = result.bundleCategory;
            state.facetState = result.facetState;
            state.filters = result.filters;
            state.filtersDiapasonCurrency = result.filtersDiapasonCurrency;
        },

        updateQuantity(state, action: PayloadAction<{ bundleId: number; quantity?: number; quantityData?: any }>) {
            const quantityData = action.payload.quantity
                ? {
                      quantity: action.payload.quantity,
                      ...action.payload.quantityData,
                  }
                : null;

            state.bundles = {
                ...state.bundles,
                [action.payload.bundleId]: {
                    ...state.bundles[action.payload.bundleId],
                    quantityData,
                },
            };
        },

        disableCategory(state, action: PayloadAction<string>) {
            state.disabledCategories = [...(state.disabledCategories || []), action.payload];
        },

        setFetching(state, action: PayloadAction<boolean>) {
            state.isFetching = action.payload;
        },

        setVisibleInfoScreen(state, action: PayloadAction<{ isVisible: boolean; name?: string; isNotTriggerClientEvent?: boolean }>) {
            if (!state.port?.isVisible && !action.payload.isNotTriggerClientEvent) {
                if (action.payload.isVisible) {
                    updateBrowserControlState(true);
                } else if (state.popups.length <= 1) {
                    updateBrowserControlState(false);
                }
            }

            state.infoScreen = {
                isVisible: action.payload.isVisible,
                name: action.payload.name,
            };
        },

        changeViewBackground(state, action: PayloadAction<{ className?: string; background?: string }>) {
            state.viewClassName = action.payload.className;
            state.viewBackground = action.payload.background;
        },

        setVisibleTopPanel(state, action: PayloadAction<boolean>) {
            state.isVisibleTopPanel = action.payload;
        },

        showParallaxAnimationShip(state, action: PayloadAction<number>) {
            state.parallaxAnimationShip = action.payload;
        },

        onSearch(state, action: PayloadAction<string>) {
            state.searchResults = getSearchResults(action.payload, state.bundles as IBundleList, state.categories);
        },

        resetSearch(state) {
            state.searchResults = null;
        },

        startVideo(state, action: PayloadAction<{ name: VIDEO_NAMES | string; url?: string; onFinish?: DefaultFunction; fadeOutVideoInSeconds?: number; volume?: number }>) {
            state.isStartedVideo = true;
            state.currentVideoName = action.payload.name;
            state.currentVideoUrl = action.payload.url;
            state.onFinishVideoCallback = action.payload.onFinish;
            state.fadeOutVideoInSeconds = action.payload.fadeOutVideoInSeconds;
            state.volume = action.payload.volume;
        },

        finishVideo(state) {
            state.isStartedVideo = false;
            state.currentVideoName = null;
            state.currentVideoUrl = null;
            state.fadeOutVideoInSeconds = null;
            state.onFinishVideoCallback = null;
        },

        changeMultipleFilter(state: IAppState, action: PayloadAction<IChangeMultiplyFilters>) {
            const result = getStateAfterChangeMultipleFilters(state, action.payload);
            state.facetState = result.facetState;
            state.filters = result.filters;
        },

        triggerGuide(state, action: PayloadAction<GUIDE_NAMES>) {
            state.guide = { name: action.payload };
        },

        blurView(state, action: PayloadAction<boolean>) {
            state.isBlurView = action.payload;
        },

        hiddenMobileNavigate(state, action: PayloadAction<boolean>) {
            state.isHiddenMobileNavigate = action.payload;
        },

        selectGroupBundle(state, action: PayloadAction<{ group: string; id: number }>) {
            state.groupBundles = {
                ...(state.groupBundles || {}),
                [action.payload.group]: action.payload.id,
            };
        },

        setMenu(state, action: PayloadAction<IMenuMap>) {
            state.menu = action.payload;
        },

        hiddenMenuItem(state, action: PayloadAction<string>) {
            state.menu = {
                ...state.menu,
                [action.payload]: {
                    ...state.menu[action.payload],
                    isHidden: true,
                },
            };
        },

        showMenuItem(state, action: PayloadAction<string>) {
            state.menu = {
                ...state.menu,
                [action.payload]: {
                    ...state.menu[action.payload],
                    isHidden: false,
                },
            };
        },

        removeClientSource(state) {
            state.clientSource = null;
        },

        setCurrentNotificationName(state, action: PayloadAction<string>) {
            state.currentNotificationVisibleName = action.payload;
        },

        changeCurrenciesDiapasonFilter(state: IAppState, action: PayloadAction<{ category: ICategoryList; currency: string; max: number; min: number }>) {
            const diapason = {
                ...state.filtersDiapasonCurrency,
                [action.payload.category]: {
                    ...(state.filtersDiapasonCurrency[action.payload.category] || {}),
                    [action.payload.currency]: {
                        min: action.payload.min,
                        max: action.payload.max,
                    },
                },
            };

            addFiltersToLocation(action.payload.category, { [action.payload.currency]: [action.payload.min, action.payload.max] });

            const result = getStateAfterChangedCurrencyDiapason(state, diapason[action.payload.category]);

            state.filtersDiapasonCurrency = diapason;
            state.facetState = result.facetState;
        },

        updateActiveFilterPreset(state: IAppState, action: PayloadAction<{ preset: string; withoutUpdateHistory?: boolean }>) {
            const result = getStateAfterChangedPreset(state, action.payload?.preset, action.payload?.withoutUpdateHistory);
            state.activePreset = result.activePreset;
            state.bundleCategory = result.bundleCategory;
            state.categoryBundlesPricesInfo = result.categoryBundlesPricesInfo;
            state.facetState = result.facetState;
            state.filters = result.filters;
            state.filtersDiapasonCurrency = result.filtersDiapasonCurrency;
        },

        setSortMethod(state, action: PayloadAction<{ category: ICategoryList; name?: SortNames; method?: ISortMethods }>) {
            state.sortedBy = {
                ...(state.sortedBy || {}),
                [action.payload.category]: {
                    name: action.payload.name,
                    method: action.payload.method,
                },
            };
        },

        resetSortCategory(state, action: PayloadAction<ICategoryList>) {
            state.sortedBy = {
                ...(state.sortedBy || {}),
                [action.payload]: null,
            };
        },

        changeFiltersPosition(state, action: PayloadAction<FilterPosition>) {
            state.filterPosition = action.payload;
        },

        changeMultipleCurrencyDiapason(state, action: PayloadAction<{ category: ICategoryList; values: ChangeMultipleDiapasonOfCurrencies }>) {
            state.filtersDiapasonCurrency = {
                ...state.filtersDiapasonCurrency,
                [action.payload.category]: {
                    ...(state.filtersDiapasonCurrency[action.payload.category] || {}),
                    ...action.payload.values,
                },
            };
        },

        setFiltersByQueryParams(state: IAppState, action: PayloadAction<{ category: ICategoryList; params: FiltersByQuery }>) {
            const result = getStateFromSetFiltersByUrl(state, action.payload.category, action.payload.params);
            state.activePreset = result.activePreset;
            state.bundleCategory = result.bundleCategory;
            state.facetState = result.facetState;
            state.filters = result.filters;
            state.filtersDiapasonCurrency = result.filtersDiapasonCurrency;
        },

        setIframePopupName(state, action: PayloadAction<string>) {
            state.iframePopupName = action.payload;
        },

        setTrusted(state) {
            state.isTrusted = true;
        },

        updateAnimationStatusCategory(state, action: PayloadAction<{ categoryName: ICategoryList; status: boolean }>) {
            state.categoriesAnimationStatuses = {
                ...(state.categoriesAnimationStatuses || {}),
                [action.payload.categoryName]: action.payload.status,
            };
        },

        updateSoundStatus(state, action: PayloadAction<{ soundKey: string; status: boolean }>) {
            state.soundStatus = {
                ...(state.soundStatus || {}),
                [action.payload.soundKey]: action.payload.status,
            };
        },

        setMenuVisibility(state, action: PayloadAction<boolean>) {
            state.isVisibleMenu = action.payload;
        },

        setFullscreen(state, action: PayloadAction<boolean>) {
            state.isVisibleMenu = !action.payload;
            state.isVisibleTopPanel = !action.payload;
            state.isFullscreen = action.payload;
        },

        updateBundleList(state, action: PayloadAction<IBundleList>) {
            state.bundles = action.payload;
        },

        setFocusPreset(state, action: PayloadAction<Nullable<ICategoryPresets>>) {
            state.focusPreset = action.payload;
        },
    },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;

export const updateCategoryByFilters = (category: ICategoryList, filterName?: FILTER_INFO_NAME, filterValue?: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const { app, account } = getState();
    const filters = app.filters?.[category];
    let bundles: number[] = getBundlesAndCategoriesMap(app.bundles, app.categories)[category];
    if (app.activePreset) {
        const config = getPresetByName(app.categories[category].filters.presets, app.activePreset);
        if (config?.type !== PRESETS.ALL) {
            bundles = config.bundles || [];
        }
    }

    const filteredBundles = filteredByFilters(bundles, filters, app.bundles, app.filtersDiapasonCurrency?.[category], account?.coupons || []);
    const availableBundles =
        filteredBundles
            ?.map((bundleId) => app.bundles[bundleId])
            .reduce((state: IBundleList, bundle) => {
                state[bundle.id] = bundle;
                return state;
            }, {}) || {};

    dispatch(appActions.updateCategoryByFilters({ category, bundles: filteredBundles, bundlesList: availableBundles, filterName, filterValue }));
};
