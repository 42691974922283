import * as React from 'react';
import styles from './InventoryCounter.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip, { IDefaultTooltip } from '~/components/Tooltip/DefaultTooltip';
import { interpolate, t } from '~/utils/localization';
import { formatNumber } from '~/utils/utils';
import classNames from 'classnames';

export enum InventoryCounterSize {
    FULL = 'full',
    SHORT = 'short',
}

const inventoryStrings: Record<string, string> = {
    emptySlots: t('Свободных слотов: {b}{count}{end_b}'),
};

interface IInventoryCounter {
    count: number;
    size?: InventoryCounterSize;
    className?: string;
    type: string;
}

const InventoryCounter = ({ count, size, className, type }: IInventoryCounter) => {
    const timeoutText = t('Информация о количестве может обновляться с задержкой');
    const defaultString = t('У вас в наличии в имуществе: {b}{count}{end_b}');
    const text = interpolate(inventoryStrings[type] || defaultString, {
        count: formatNumber(count),
        b: '<b>',
        end_b: '</b>',
    });

    const classes = classNames(styles.wrapper, className);

    const isShort = !size || size === InventoryCounterSize.SHORT;

    const props: IDefaultTooltip = {};

    if (isShort) {
        props.text = text;
        props.footer = timeoutText;
    } else {
        props.text = timeoutText;
    }

    if (!count) {
        props.text = timeoutText;
        props.footer = null;
    }

    return (
        <DivTooltip className={classes} tooltipBody={<DefaultTooltip {...props} />}>
            <span dangerouslySetInnerHTML={{ __html: isShort ? formatNumber(count) : text }} />
        </DivTooltip>
    );
};

export default InventoryCounter;
