import equal from 'fast-deep-equal/react';
import InventoryCounter, { InventoryCounterSize } from '~/components/InventoryCounter/InventoryCounter';
import { isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import { ALTERNATIVE_ENTITY_TYPE_FOR_INVENTORY } from '~/const';
import Account from '~/account/Account';
import { RootState, useAppSelector } from '~/Store';

interface IInventoryCounterContainer {
    item: IItemCommonData;
    size?: InventoryCounterSize;
    className?: string;
}

const stateSelector = (state: RootState) => {
    return {
        accountId: state.account.id,
        inventory: state.account.inventory,
    };
};

const InventoryCounterContainer = ({ item, size, className }: IInventoryCounterContainer) => {
    const state = useAppSelector(stateSelector, equal);
    if (!state.accountId) {
        return null;
    }

    if (!item) {
        return null;
    }

    if (item.isUnique) {
        return null;
    }

    if (isPermanentCamouflage(item.type) && item.customisation?.shipId) {
        return null;
    }

    const type = ALTERNATIVE_ENTITY_TYPE_FOR_INVENTORY[item.type] || item.type;
    const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(item?.type, item?.identifier, item.customisation?.shipId);
    if (!isExistsItemInInventory || !inventoryCount) {
        return null;
    }

    return <InventoryCounter count={inventoryCount} size={size} className={className} type={type} />;
};

export default InventoryCounterContainer;
