import { shallowEqual, useDispatch } from 'react-redux';
import Account from '~/account/Account';
import RewardPopup from '~/components/Popups/Reward/RewardPopup';
import ErrorBoundary from '~/components/ErrorBoundary/ErrorBoundary';
import { RootState, useAppSelector } from '~/Store';
import { accountActions } from '~/Store/accountSlice';

const stateSelector = (state: RootState) => {
    return {
        rewards: state.account.rewards?.rewards,
        isVisible: state.account.rewards?.isVisible,
    };
};

const RewardPopupManager = () => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, shallowEqual);

    if (!Account.getAccount() || !state.isVisible || !state.rewards?.length) {
        return null;
    }

    const onClose = () => {
        dispatch(accountActions.markRewardAsViewed(state.rewards[0]));
    };

    const reward = state.rewards[0];
    if (!reward) {
        return null;
    }

    return (
        <ErrorBoundary>
            <RewardPopup key={reward.id} onClose={onClose} {...reward} />
        </ErrorBoundary>
    );
};

export default RewardPopupManager;
