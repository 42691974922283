import { LocalStorageHelper } from '~/utils/storage';
import { LOCAL_STORAGE_COUNT_ALL_BUNDLES, LOCAL_STORAGE_VORTEX_DB } from '~/utils/keys';
import { SectionNames } from './ManagerData';
import { Crew, Currency, Doll, Item, LootBox, Nation, Permoflages, Vehicle, VehicleType } from '~/vortex';

type ItemTypes = Crew[] | Doll[] | Item[] | Nation[] | Permoflages[] | Vehicle[] | VehicleType[] | LootBox[];

export type IVortexData = Record<SectionNames, ItemTypes>;

interface IData {
    lang: string;
    version: string;
    data: IVortexData;
}

export type IMergeData = Record<SectionNames, ItemTypes>;

export type ITEM_DATA = Crew | Currency | Doll | Item | Nation | Permoflages | Vehicle | VehicleType;

class VortexDataStorage {
    private allData: IData;

    public getKey() {
        return LOCAL_STORAGE_VORTEX_DB;
    }

    public getDataBySection(type: SectionNames) {
        return this.hasData() ? this.getAll().data[type] : {};
    }

    public getAll() {
        if (!this.allData) {
            this.allData = LocalStorageHelper.get(this.getKey());
        }

        return this.allData;
    }

    public add(options: IData) {
        this.upgrade(options);
        this.allData = options;
    }

    public isEqualsCountBundles(count: number) {
        return LocalStorageHelper.get(LOCAL_STORAGE_COUNT_ALL_BUNDLES) === count;
    }

    public updateCountBundles(count: number) {
        LocalStorageHelper.set(LOCAL_STORAGE_COUNT_ALL_BUNDLES, count);
    }

    public clear() {
        LocalStorageHelper.remove(this.getKey());
    }

    public merge(data: IMergeData) {
        const allData = this.getAll().data;

        Object.keys(data).forEach((key: SectionNames) => {
            data[key]?.forEach((dataItem: any) => {
                const isAlreadyExists = allData[key]?.some((itemData: any) => {
                    return String(itemData.id) === String(dataItem.id);
                });

                if (isAlreadyExists) {
                    return;
                }

                if (!allData[key]) {
                    allData[key] = [];
                }

                allData[key].push(dataItem);
            });
        });

        const options: IData = {
            ...this.getAll(),
            data: allData,
        };

        this.upgrade(options);
    }

    private upgrade(options: IData) {
        LocalStorageHelper.set(this.getKey(), {
            lang: options.lang,
            version: options.version,
            data: options.data,
        });
    }

    public isDataRelevant(version: string, lang: string) {
        const data: IData = this.getAll();
        if (!data) {
            return false;
        }

        return data.version === version && data.lang === lang;
    }

    public hasData() {
        return !!this.getAll();
    }

    public getVehicles() {
        return this.allData.data.vehicles;
    }
}

export default new VortexDataStorage();
