import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { getParentRandomBundleByChild, getSinglePurchaseLimitTextForRandom } from '~/utils/bundles';
import styles from '~/components/Popups/popups.scss';
import RandomBundleTitle from '~/components/RandomBundleTitle/RandomBundleTitle';
import { isMobileOrTabletWindow } from '~/utils/utils';
import classNames from 'classnames';
import { IQuantityTooltips } from '~/components/Quantity/Quantity';
import { isNotEnabledCoupon } from '~/utils/coupons';
import Notice from '~/components/Notice/Notice';
import { t, interpolate } from '~/utils/localization';
import { getCompensationText } from '~/Layouts/BundlePage/helpers';
import { openUrl } from '~/utils/settings';
import RandomBundleFullPurchaseButton from '~/components/RandomBundleFullPurchaseButton/RandomBundleFullPurchaseButton';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { BUNDLE_LIMIT_ERROR_TEXTS } from '~/components/BundleLimitWidget/Tooltip/BundleLimitTooltip';
import { REASON_FAIL_PURCHASE_CONTAINER } from '~/components/BundleLimitWidget/settings';
import CouponWrapper from '~/components/CouponWrapper/CouponWrapper';
import { PriceMapItem } from '~/hooks/useRandomBundleData';
import RandomBundleBeneficialOffer from '~/components/RandomBundleBeneficialOffer/RandomBundleBeneficialOffer';
import QuantityContainer from '~/containers/QuantityContainer/QuantityContainer';
import { RootState, useAppSelector } from '~/Store';

interface IRandomBundleContent {
    bundle: IBundle;
}

export const RandomBundleContent = (props: IRandomBundleContent): React.ReactChild | any => {
    const { bundles } = useAppSelector((state: RootState) => {
        return { bundles: state.app.bundles };
    }, shallowEqual);
    const parentBundleId = getParentRandomBundleByChild(bundles, props.bundle.id);

    if (!props.bundle.isRandom) {
        return null;
    }

    return (
        <div className={styles.randomBundleContent}>
            <RandomBundleTitle bundle={bundles[parentBundleId]} isInfinity={props.bundle.randomIsInfinite} withoutTooltip />
        </div>
    );
};

interface INoticeForWholePurchaseRandomBundle {
    bundle: IBundle;
    availableAmountForFullPurchase: number;
    hasRestriction?: boolean;
}

export const NoticeForWholePurchaseRandomBundle = ({ bundle, availableAmountForFullPurchase, hasRestriction }: INoticeForWholePurchaseRandomBundle) => {
    const currentQuantity = bundle?.quantityData?.quantity || 1;
    if (hasRestriction || !bundle.isRandom || bundle.randomIsInfinite || currentQuantity <= 1 || currentQuantity !== availableAmountForFullPurchase) {
        return null;
    }

    return (
        <div className={styles.noticeForRandomBundle}>
            {interpolate(t('Приобретая все случайные наборы "{name}", вы гарантированно получаете всё имущество, которое в них содержится.'), {
                name: bundle.title,
            })}
        </div>
    );
};

interface IPurchaseContent {
    canChangeQuantity: boolean;
    isEnabledCoupon: boolean;
    bundle: IBundle;
    isActiveCoupon: boolean;
    popup: IPopup;
    offerLimit: number;
    availableAmountForFullPurchase: number;
    isAvailableFullPurchase: boolean;
    onFullPurchaseHandler: (amount: number) => void;
    hasRestrictionPurchaseForRandomBundle: boolean;
    canIncrementRandomBundleAmount: boolean;
    isRandomBundle: boolean;
    bundlesInTransaction: number[];
    beneficialOffer: PriceMapItem;
    balance: IBalance;
    isActiveTransaction: boolean;
    reasonCantBoughtCode: number;
}

export const PurchaseContent = ({
    canChangeQuantity,
    isEnabledCoupon,
    bundle,
    isActiveCoupon,
    popup,
    offerLimit,
    isAvailableFullPurchase,
    availableAmountForFullPurchase,
    onFullPurchaseHandler,
    hasRestrictionPurchaseForRandomBundle,
    canIncrementRandomBundleAmount,
    isRandomBundle,
    bundlesInTransaction,
    beneficialOffer,
    balance,
    isActiveTransaction,
    reasonCantBoughtCode,
}: IPurchaseContent) => {
    const classesPurchaseInfo = classNames(styles.purchasePopupInfoItem, styles.purchasePopupItem, {
        [styles.fullRurchaseRandom]: isAvailableFullPurchase,
    });

    let lockedPlusButtonTooltip = hasRestrictionPurchaseForRandomBundle && <DefaultTooltip text={BUNDLE_LIMIT_ERROR_TEXTS[REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE]} />;
    if (!lockedPlusButtonTooltip && bundle.singlePurchaseMaxQuantity && bundle.singlePurchaseMaxQuantity < availableAmountForFullPurchase) {
        lockedPlusButtonTooltip = <DefaultTooltip text={getSinglePurchaseLimitTextForRandom(bundle.singlePurchaseMaxQuantity)} />;
    }
    const tooltips: Partial<IQuantityTooltips> = {};

    let disableInput = false;
    if (isRandomBundle) {
        disableInput = !canIncrementRandomBundleAmount;
        tooltips.lockedPlusButton = lockedPlusButtonTooltip;
    } else if (isActiveCoupon) {
        disableInput = true;
    }

    const classesQuantityWidget = classNames(styles.purchaseQuantityWidget, {
        [styles.isDisabled]: bundlesInTransaction?.includes(bundle.id),
    });

    const classesWrapper = classNames(styles.purchasePopupInfoItem, styles.purchasePopupMobile, {
        [styles.isLockInterface]: isActiveTransaction,
    });

    return (
        (canChangeQuantity || (isEnabledCoupon && !isMobileOrTabletWindow)) && (
            <div className={classesWrapper}>
                {canChangeQuantity && (
                    <div className={classesPurchaseInfo}>
                        <div className={classesQuantityWidget}>
                            <QuantityContainer bundle={bundle} offersLimit={offerLimit} fromPopup tooltips={tooltips} idDisabled={disableInput} />
                        </div>
                        {isAvailableFullPurchase && (
                            <div className={styles.fullPurchaseButton}>
                                <RandomBundleFullPurchaseButton
                                    bundle={bundle}
                                    availableAmountForPurchase={availableAmountForFullPurchase}
                                    onClick={() => {
                                        onFullPurchaseHandler?.(availableAmountForFullPurchase);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
                {beneficialOffer && (
                    <div className={styles.beneficialOffer}>
                        <RandomBundleBeneficialOffer
                            balance={balance}
                            discount={beneficialOffer.discount}
                            beneficialQuantity={beneficialOffer.quantity}
                            currentQuantity={bundle.quantityData?.quantity || 1}
                            bundleId={bundle.id}
                            bundle={bundle}
                        />
                    </div>
                )}
                {isEnabledCoupon && !isMobileOrTabletWindow && (
                    <div className={classNames(styles.couponWrapper, styles.purchasePopupInfoItem)}>
                        <CouponWrapper bundle={bundle} isDisableMultiplePurchaseWithCoupon={isNotEnabledCoupon(bundle)} />
                    </div>
                )}
            </div>
        )
    );
};

interface ICopyrightContent {
    isNeedToShowCopyrightFromContainer?: boolean;
    isNeedToShowCopyrightFromRandom?: boolean;
    isNeedToHideDisclaimerAppearance?: boolean;
    isNeedToHideCompensationText?: boolean;
    isNeedToShowMysteryBundleCopyright?: boolean;
}

export const CopyrightContent = ({
    isNeedToShowCopyrightFromContainer,
    isNeedToShowCopyrightFromRandom,
    isNeedToHideDisclaimerAppearance,
    isNeedToShowMysteryBundleCopyright,
    isNeedToHideCompensationText,
}: ICopyrightContent) => {
    const compensationRef = React.useRef(null);

    const goToCompensationPage = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        let url = null;
        const target = event.target as HTMLDivElement;
        const parentNode = target.parentNode as HTMLLinkElement;

        if (parentNode.tagName.toLowerCase() === 'a') {
            url = parentNode.getAttribute('href');
        } else if (target.tagName.toLowerCase() === 'a') {
            url = target.getAttribute('href');
        }

        url && openUrl(url);
    };

    return (
        <React.Fragment>
            {isNeedToShowCopyrightFromContainer && (
                <div className={classNames(styles.copyrightText, styles.copyrightContainer)}>
                    <Notice
                        label={t(
                            'Открытие контейнера не гарантирует получение конкретных внутриигровых предметов — с определённой вероятностью вы можете получить любую награду из списка. Вы всегда получаете содержимое стоимостью не менее затраченной на покупку суммы. Пожалуйста, внимательно следите за своими расходами, совершая покупки в World of Warships.',
                        )}
                    />
                </div>
            )}
            {isNeedToShowMysteryBundleCopyright && (
                <div className={classNames(styles.copyrightText, styles.copyrightContainer)}>
                    <Notice
                        label={t(
                            'Совершение попытки не гарантирует получение конкретных внутриигровых предметов — с определённой вероятностью вы можете получить любую награду из списка. Вы всегда получаете содержимое стоимостью не менее суммы, затраченной на совершение попытки. Пожалуйста, внимательно следите за своими расходами, совершая покупки в World of Warships.',
                        )}
                    />
                </div>
            )}
            {isNeedToShowCopyrightFromRandom && (
                <div className={classNames(styles.copyrightText, styles.copyrightRandomBundle)}>
                    <Notice
                        label={t(
                            'Наборы расположены в случайном порядке. Только приобретя все случайные наборы вы гарантированно получите все перечисленные награды. Вы всегда получаете внутриигровые предметы стоимостью не менее затраченной на покупку суммы. Пожалуйста, внимательно следите за своими расходами, совершая покупки в World of Warships.',
                        )}
                    />
                </div>
            )}
            {!isNeedToHideDisclaimerAppearance && (
                <div className={classNames(styles.copyrightText, styles.defaultCopyright)}>
                    {t('Обратите внимание: внешний вид и описание объектов в Адмиралтействе могут иметь отличия от представленных в клиенте игры.')}
                </div>
            )}
            {!isNeedToHideCompensationText && (
                <div
                    className={classNames(styles.copyrightText, styles.defaultCopyright)}
                    dangerouslySetInnerHTML={{ __html: getCompensationText() }}
                    ref={compensationRef}
                    onClick={goToCompensationPage}
                />
            )}
        </React.Fragment>
    );
};
