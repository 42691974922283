import store from '~/Store';
import { getUserId } from '~/utils/settings';
import { LocalStorageHelper } from '~/utils/storage';
import { arrayToObjectByKey, isMobileOrTabletWindow } from '~/utils/utils';
import { NotificationDefault, NotificationPopup } from '~/components/Notifications/Notifications';
import NotificationSpecial from '~/components/Notifications/NotificationSpecial/NotificationSpecial';
import { ErrorBoundary } from '@sentry/react';
import { NotificationDisplay } from '~/types/notifications';

export const getNotificationNameFromLocalStorage = (name: string) => {
    const userId = getUserId();

    return `notification_${name}_${userId}`;
};

export const getNotification = (name: string) => {
    const accountState = store.getState().account;
    const infoScreens = arrayToObjectByKey(accountState.notifications || [], 'name');

    return infoScreens[name];
};

export const getNotificationToShowIfNeeded = (): Nullable<string> => {
    const accountState = store.getState().account;
    const infoScreens = accountState.notifications;

    if (isMobileOrTabletWindow) {
        return null;
    }

    if (!accountState.id || !infoScreens || !infoScreens.length) {
        return null;
    }

    let infoScreenName: Nullable<string> = null;

    for (let i = 0; i < infoScreens.length; i++) {
        const infoScreen = infoScreens[i];

        if (accountState.viewedNotifications && accountState.viewedNotifications.includes(infoScreen.name)) {
            continue;
        }

        if (!LocalStorageHelper.get(getNotificationNameFromLocalStorage(infoScreen.name))) {
            infoScreenName = infoScreen.name;
            break;
        }
    }

    return infoScreenName;
};

interface INotificationManager {
    name: string;
    notifications: INotification[];
}

const NotificationManager = ({ name, notifications }: INotificationManager) => {
    const _notifications = arrayToObjectByKey(notifications, 'name');
    const notification = _notifications[name];

    switch (notification.display) {
        case NotificationDisplay.POPUP:
            return (
                <ErrorBoundary>
                    <NotificationPopup data={notification} isInfoscreen={true} />
                </ErrorBoundary>
            );

        case NotificationDisplay.SPECIAL:
            return (
                <ErrorBoundary>
                    <NotificationSpecial notification={notification} />
                </ErrorBoundary>
            );

        case NotificationDisplay.NOTIFICATION:
        default:
            return (
                <ErrorBoundary>
                    <NotificationDefault data={notification} />
                </ErrorBoundary>
            );
    }
};

export default NotificationManager;
