import * as React from 'react';
import styles from './SerialBundlePage.scss';
import equal from 'fast-deep-equal/react';
import classNames from 'classnames';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import useDelegateContainerScroll from '~/hooks/useDelegateContainerScroll';
import SerialItemsSmallCarousel from '~/components/SerialItemsSmallCarousel/SerialItemsSmallCarousel';
import { getAvailabelSerialBundleForPurchase, getSerialBundlesById } from '~/utils/bundles';
import BundlePageLabels from '~/Layouts/BundlePage/BundlePageLabels/BundlePageLabels';
import BundlePageHeader from '~/Layouts/BundlePage/BundlePageHeader/BundlePageHeader';
import BundlePageDescription from '~/Layouts/BundlePage/BundlePageDescription/BundlePageDescription';
import SerialBundleFooter from './SerialBundleFooter';
import { BundleLimitContainerWidget } from '../BundlePage/BundlePageHeader/content';
import { RootState, useAppSelector } from '~/Store';

interface ISerialBundlePage {
    bundle: IBundle;
}

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        accountId: state.account?.id,
        restrictedLootboxesPurchaseCount: state.account.restrictedLootboxesPurchaseCount,
        bundles: state.app.bundles,
        activeCouponId: state.account.activeCouponId,
        coupons: state.account.coupons,
        disabledCategories: state.app.disabledCategories,
        purchasedLimitedBundles: state.account.purchasedLimitedBundles,
        port: state.app.port,
        categories: state.app.categories,
        balance: state.account.balance,
        bundlesInTransaction: state.account.bundlesInTransaction,
    };
};

const SerialBundlePage = ({ bundle }: ISerialBundlePage) => {
    const state = useAppSelector(stateSelector, equal);
    const refContent = React.useRef<HTMLDivElement>(null);
    const sequence = getSerialBundlesById(state.bundles, bundle.id);

    useMaskScrollEffect(refContent);
    useDelegateContainerScroll(refContent);

    const availableBundle = getAvailabelSerialBundleForPurchase(sequence, state.purchasedLimitedBundles);

    return (
        <div className={classNames(styles.container, 'armory__auto--bundle-page_wrapper')} data-bundle-id={bundle.id}>
            <div className={styles.carousel}>
                <SerialItemsSmallCarousel bundle={bundle} sequence={sequence} availableBundle={availableBundle} />
            </div>
            <div className={styles.content} ref={refContent}>
                <div className={styles.bundleContent}>
                    <BundlePageLabels bundle={bundle} />
                    <BundlePageHeader bundle={bundle} bundles={state.bundles} renderPreviewContent={true} />
                    <BundleLimitContainerWidget bundle={bundle} className={styles.limitWidget} />
                    <div className={styles.mobileImage}>
                        <img src={bundle.icons?.big} alt={bundle.title} />
                    </div>
                    <BundlePageDescription bundle={bundle} key={`serial_bundle_desc_${bundle.id}`} />
                </div>
            </div>
            <SerialBundleFooter
                sequence={sequence}
                currentPage={state.currentPage}
                isAuthorized={!!state.accountId}
                bundle={bundle}
                purchasedLimitedBundles={state.purchasedLimitedBundles}
                restrictedLootboxesPurchaseCount={state.restrictedLootboxesPurchaseCount}
                balance={state.balance}
                bundlesInTransaction={state.bundlesInTransaction || []}
                bundles={state.bundles}
            />
        </div>
    );
};

export default SerialBundlePage;
