import * as React from 'react';
import styles from './BundleLimitWidget.scss';
import classNames from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import { t } from '~/utils/localization';
import BundleLimitTooltip from '~/components/BundleLimitWidget/Tooltip/BundleLimitTooltip';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import { useDispatch } from 'react-redux';
import { POPUPS_NAME } from '~/components/PopupManager';
import { appActions } from '~/Store/appSlice';

export enum BundleLimitSize {
    SMALL = 'small',
    BIG = 'big',
}

export interface IBundleLimitInfo {
    title: string;
    amount: number;
}

interface IBundleLimitWidget {
    size?: BundleLimitSize;
    bundlesInfo: IBundleLimitInfo[];
    total: number;
    className?: string;
}

const BundleLimitWidget = ({ size, bundlesInfo, total, className }: IBundleLimitWidget) => {
    const dispatch = useDispatch();
    const isBigTemplate = size === BundleLimitSize.BIG;

    const classesWrapper = classNames(
        styles.wrapper,
        {
            [styles.big]: isBigTemplate,
        },
        className,
    );

    const onClick = (event: React.MouseEvent) => {
        if (!isMobileOrTabletDeviceByAgent()) {
            return;
        }

        event.stopPropagation();

        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.BUNDLE_LIMIT_INFO,
                isVisible: true,
                data: {
                    bundlesInfo,
                    total,
                },
            }),
        );
    };

    return (
        <div className={styles.tooltipWrapper}>
            <DivTooltip tooltipBody={<BundleLimitTooltip total={total} bundlesInfo={bundlesInfo} />}>
                <div className={classesWrapper} onClick={onClick}>
                    {isBigTemplate && <span className={styles.label}>{t('Ограничения на приобретение')}</span>}
                </div>
            </DivTooltip>
        </div>
    );
};

export default BundleLimitWidget;
