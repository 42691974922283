import * as React from 'react';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import styles from './GiftPopup.scss';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { useDispatch } from 'react-redux';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { takeGift } from '~/api/gifts';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';
import Attention from '~/components/Attention/Attention';
import Button, { ButtonVariant } from '~/components/Button/Button';
import { t } from '~/utils/localization';
import { POPUPS_NAME } from '~/components/PopupManager';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { PresentationStyles } from '@wg/wows-entities/const';
import classNames from 'classnames';
import { FadeInDiv } from '~/components/Transitions';
import Account from '~/account/Account';
import { redirectToLogin } from '~/utils/utils';
import { appActions } from '~/Store/appSlice';
import { accountActions } from '~/Store/accountSlice';

interface IGiftPopup {
    data: {
        gift: IGift;
        showRewards?: boolean;
    };
}

enum GiftStatus {
    INITIAL,
    IN_PROGRESS,
    ERROR,
    SUCCESS,
}

const GiftPopup = ({ data }: IGiftPopup) => {
    const dispatch = useDispatch();
    const { gift, showRewards } = data;
    const [status, setStatus] = React.useState<number>(GiftStatus.INITIAL);

    const close = () => {
        updateBrowserControlState(false);
        dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.GIFT, isVisible: false }));
    };

    const takeMyGift = () => {
        if (!Account?.getAccount()?.id) {
            return redirectToLogin();
        }
        setStatus(GiftStatus.IN_PROGRESS);
        takeGift(gift.id)
            .then(() => {
                updateBrowserControlState(true);
                setStatus(GiftStatus.SUCCESS);
                dispatch(accountActions.obtainGift(gift.id));
                const timeout = setTimeout(() => {
                    clearTimeout(timeout);
                    close();
                }, 3000);
            })
            .catch(() => {
                updateBrowserControlState(true);
                setStatus(GiftStatus.ERROR);
            });
    };

    const label = status === GiftStatus.IN_PROGRESS ? t('Обработка') : t('Забрать подарок');
    const isDisabled = status !== GiftStatus.INITIAL && status !== GiftStatus.ERROR;

    useKeyDown(() => {
        if (status === GiftStatus.ERROR || status === GiftStatus.SUCCESS) {
            playButtonClickSound();
            close();
        }
    }, [KEYS_CODE.ESC]);

    const classesWrapper = classNames(styles.wrapper, {
        [styles.withoutReward]: !showRewards,
    });

    return (
        <FadeInDiv className={classesWrapper} style={{ backgroundImage: `url(${gift.icons.innerBackground})` }}>
            {(status === GiftStatus.ERROR || status === GiftStatus.SUCCESS) && (
                <div className={styles.close}>
                    <ButtonEsc onClick={close} />
                </div>
            )}
            <div className={styles.content}>
                <div className={styles.main}>
                    <div className={styles.title}>{gift.title}</div>
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: gift.description }} />
                    {showRewards && (
                        <div className={styles.rewards}>
                            {gift.entitlements.map((item, index) => {
                                return (
                                    <div className={styles.rewardItem} key={`gift_reward_${item.type}_${index}`}>
                                        <WoWSEntity
                                            type={item.type}
                                            id={item.identifier}
                                            amount={item.amount}
                                            className={styles.weReward}
                                            presentation={{
                                                withTooltip: true,
                                                style: PresentationStyles.WIDGET,
                                                withText: false,
                                                renderWithoutFade: true,
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {status !== GiftStatus.SUCCESS && (
                        <div className={styles.buttonWrapper}>
                            <Button variant={ButtonVariant.orange} label={label} onClick={takeMyGift} disabled={isDisabled} className={styles.button} />
                        </div>
                    )}
                    <div className={styles.status}>
                        {status === GiftStatus.ERROR && <Attention label={t('Произошла ошибка. Повторите попытку или заберите подарок позднее.')} level={'error'} className={styles.attention} />}
                        {status === GiftStatus.SUCCESS && <Attention label={t('Подарок успешно получен')} level={'done'} className={styles.attention} />}
                    </div>
                </div>
            </div>
        </FadeInDiv>
    );
};

export default GiftPopup;
