import * as React from 'react';
import classNames from 'classnames';
import styles from './RandomBundleTitle.scss';
import { interpolate, t } from '~/utils/localization';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { shallowEqual } from 'react-redux';
import { getCountRandomBundleChildren, isPeriodicBundle } from '~/utils/bundles';
import Account from '~/account/Account';
import { useAppSelector } from '~/Store';

interface IRandomBundleTitle {
    bundle?: IBundle;
    withoutText?: boolean;
    withoutTooltip?: boolean;
    isInfinity?: boolean;
    isShort?: boolean;
    onlyCounter?: boolean;
}

const RandomBundleTitle = (props: IRandomBundleTitle) => {
    const randomBundlesHistory = useAppSelector((state) => state.account.randomBundlesHistory, shallowEqual);

    const totalCount = props.bundle && getCountRandomBundleChildren(props.bundle);
    const purchasedCount = (Account.getAccount() && randomBundlesHistory[props.bundle?.id]?.length) || 0;
    const isPeriodic = props.bundle && isPeriodicBundle(props.bundle);

    const titleText = isPeriodic ? t('Периодичный случайный набор. Получено: {count} / {total}') : t('Случайный набор. Получено: {count} / {total}');

    const text = interpolate(props.isInfinity ? t('Случайный набор. Получено: {count}') : titleText, {
        count: purchasedCount,
        total: totalCount,
    });

    let tooltipContent: React.ReactNode = (
        <DefaultTooltip text={[isPeriodic ? t('Периодичный случайный набор') : t('Случайный набор'), interpolate(t('Получено: {count}'), { count: purchasedCount })]} />
    );

    if (props.withoutTooltip) {
        tooltipContent = null;
    }

    const classNamesTitle = classNames(styles.title, {
        [styles.isShort]: props.isShort,
        [styles.periodic]: isPeriodic,
    });

    if (props.isShort) {
        return (
            <DivTooltip tooltipBody={tooltipContent}>
                <div className={classNamesTitle} data-purchase-count={purchasedCount} data-total-count={totalCount}>
                    {!props.withoutText ? (
                        <span>
                            {purchasedCount}/{totalCount}
                        </span>
                    ) : (
                        <span>&nbsp;</span>
                    )}
                </div>
            </DivTooltip>
        );
    }

    if (props.onlyCounter) {
        const _classNames = classNames(styles.title, styles.right, {
            [styles.periodic]: isPeriodic,
        });
        return (
            <DivTooltip tooltipBody={tooltipContent}>
                <div className={_classNames} data-purchase-count={purchasedCount} data-total-count={totalCount}>
                    {purchasedCount}/{totalCount}
                </div>
            </DivTooltip>
        );
    }

    const titleClassNames = classNames(styles.title, {
        [styles.periodic]: isPeriodic,
    });

    return (
        <DivTooltip tooltipBody={tooltipContent}>
            <div className={titleClassNames} data-purchase-count={purchasedCount} data-total-count={totalCount}>
                {!props.withoutText && text}
            </div>
        </DivTooltip>
    );
};

export default RandomBundleTitle;
