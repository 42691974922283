import store from '~/Store';
import { getUserId, settings, armoryState } from '~/utils/settings';
import { hasUniqueItems } from '~/utils/bundles';
import { POPUPS_NAME } from '~/components/PopupManager';
import { openBundleById, resetCouponFiltersIfNeeded } from '~/utils/category';
import { logInfo } from '~/utils/logging';
import dwhExport, { EventTypes } from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { prepareCouponsFromState } from '~/utils/coupons';
import { prepareBalanceFromState } from '~/utils/balance';
import { ITradeInResult, TradeInStatus } from '~/types/tradein';
import { findTarget, markItemsAsUnAvailableAfterTransaction } from '~/utils/tradein';
import { accountActions } from '~/Store/accountSlice';
import { HIDE_ALL_POPUPS, appActions } from '~/Store/appSlice';
import { tradeinActions } from '~/Store/tradeinSlice';
import { BUNDLE_DECORATION } from '~/types/bundle';
import { IRewardPopupData } from '~/components/Popups/RewardsPopup/RewardsPopup';
import events, { CustomEventNames } from '~/utils/eventEmitter';
import { arrayToObjectByKey } from '~/utils/utils';

export const ACCOUNT_STATE_UPDATE = 'ACCOUNT_STATE_UPDATE';
export const PURCHASE_SUCCESS = 'PURCHASE_SUCCESS';
export const PURCHASE_ERROR = 'PURCHASE_ERROR';
export const PURCHASE_RANDOM_BUNDLE_SUCCESS = 'PURCHASE_RANDOM_BUNDLE_SUCCESS';
export const RANDOM_BUNDLE_SELECTED = 'RANDOM_BUNDLE_SELECTED';
export const PURCHASE_SERIAL_BUNDLES_SUCCESS = 'PURCHASE_SERIAL_BUNDLES_SUCCESS';
export const TRADEIN_SUCCESS = 'TRADEIN_SUCCESS';
export const TRADEIN_ERROR = 'TRADEIN_ERROR';

class Socket {
    private ws: WebSocket;

    private FREEZE = 10000;

    private timeout: any;

    private countAttempt = 0;

    private maxCountAttempt = 10;

    constructor() {
        if (!getUserId()) {
            return;
        }

        this.openConnect();
    }

    openConnect(): void {
        this.ws = new WebSocket(settings.urls.ws.split('{}').join(armoryState.account.id.toString()));

        this.ws.onopen = () => {
            clearTimeout(this.timeout);
            logInfo('server socket init');
        };

        this.ws.onmessage = (message: any) => {
            const data = JSON.parse(message.data);
            this.onMessage(data);
        };

        this.ws.onclose = () => {
            if (this.countAttempt > this.maxCountAttempt) {
                clearTimeout(this.timeout);
                return;
            }
            this.tryToOpenConnect();
        };
    }

    tryToOpenConnect(): void {
        this.timeout = setTimeout(() => {
            this.countAttempt++;
            logInfo('attempt to open a socket connection');
            this.openConnect();
        }, this.FREEZE);
    }

    onMessage(message: ISocketResponse): void {
        const popupData: Partial<IPopup> = store.getState().app.popupActive || {};
        const account = store.getState().account;
        const { bundles, currentPage, clientSource } = store.getState().app;

        switch (message.action) {
            case ACCOUNT_STATE_UPDATE:
                if (account.ts > message.data.ts) {
                    return;
                }
                const coupons = prepareCouponsFromState(account.coupons, message.data.usedCoupons);
                store.dispatch(
                    accountActions.updateAccountState({
                        data: {
                            ...message.data,
                            balance: arrayToObjectByKey(prepareBalanceFromState(message.data.balance), 'currency'),
                        },
                        coupons,
                    }),
                );
                store.dispatch(
                    appActions.updateBundles({
                        coupons,
                        deniedBundlesByUniqueItems: message.data.deniedBundlesByUniqueItems,
                        purchasedLimitedBundles: message.data.purchasedLimitedBundles,
                    }),
                );

                resetCouponFiltersIfNeeded();
                break;

            case PURCHASE_ERROR: {
                const bundle = store.getState().app.bundles[message?.data?.bundleId];
                if (!bundle) {
                    return;
                }

                const dwhMessage: Record<string, string | number> = {
                    bundle_id: message?.data?.bundleId,
                };

                dwhExport.send(DWH_EVENTS.PURCHASE_ERROR, dwhMessage, EventTypes.Bundle);

                store.dispatch(
                    appActions.changeVisibilityPopup({
                        name: POPUPS_NAME.ERROR_PURCHASE,
                        isVisible: true,
                        data: {
                            bundleId: message.data.bundleId,
                            errorCode: message.data.errorCode,
                        },
                        forcedClosurePopupNames: [POPUPS_NAME.CONFIRM_PURCHASE],
                    }),
                );

                store.dispatch(accountActions.removeBundleFromTransaction(message.data.bundleId));
                store.dispatch(accountActions.setPurchasingBundleId(null));
                break;
            }

            case PURCHASE_SERIAL_BUNDLES_SUCCESS: {
                const bundleIds = message?.data?.bundleIds || [];
                if (!bundleIds?.length) {
                    return;
                }

                const isExistsBundles = bundleIds.every((bundleId: number) => !!bundles[bundleId]);
                if (!isExistsBundles) {
                    return;
                }

                const isHideResult = bundleIds.some((bundleId: number) => bundles[bundleId]?.decoration?.includes(BUNDLE_DECORATION.HIDE_PURCHASE_RESULT));
                const isPurchaseAsReward = bundleIds.some((bundleId: number) => bundles[bundleId]?.decoration?.includes(BUNDLE_DECORATION.PURCHASE_AS_REWARD));

                if (!isHideResult) {
                    if (isPurchaseAsReward) {
                        store.dispatch(
                            appActions.changeVisibilityPopup({
                                name: POPUPS_NAME.REWARDS,
                                isVisible: true,
                                forcedClosurePopupNames: [POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE, POPUPS_NAME.CONFIRM_PURCHASE, POPUPS_NAME.CUSTOM_COMPONENT],
                                data: {
                                    rewards: bundleIds.map((bundleId: number) => {
                                        const bundle = bundles[bundleId];
                                        return {
                                            images: {
                                                big: bundle.icons.big,
                                                medium: bundle.icons.medium,
                                                small: bundle.icons.small,
                                            },
                                            items: bundle.entitlements as IItemCommonData[],
                                            name: bundle.name,
                                            title: bundle.title,
                                            backgroundColor: bundle.backgroundColor,
                                        };
                                    }),
                                } as IRewardPopupData,
                            }),
                        );
                    } else {
                        store.dispatch(
                            appActions.changeVisibilityPopup({
                                name: POPUPS_NAME.SERIAL_SEQUENCE_SUCCESS_PURCHASE,
                                isVisible: true,
                                data: {
                                    bundleIds,
                                },
                                forcedClosurePopupNames: [POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE, POPUPS_NAME.CONFIRM_PURCHASE, POPUPS_NAME.CUSTOM_COMPONENT],
                            }),
                        );
                    }
                } else {
                    store.dispatch(appActions.changeVisibilityPopup({ name: HIDE_ALL_POPUPS }));
                }

                if (currentPage.isBundlePage) {
                    const lastBundleId = bundleIds.at(-1);
                    if (bundles[lastBundleId]?.nextBundle) {
                        openBundleById(bundles[lastBundleId].nextBundle);
                    }
                }
                break;
            }

            case PURCHASE_SUCCESS: {
                const bundle = bundles[message?.data?.bundleId];
                if (!bundle) {
                    return;
                }

                const dwhMessage: Record<string, string | number> = {
                    bundle_id: message?.data?.bundleId,
                };

                if (clientSource) {
                    dwhMessage['client_source'] = clientSource;
                }

                dwhExport.send(DWH_EVENTS.PURCHASE_SUCCESS, dwhMessage, EventTypes.Bundle);

                popupData?.data?.callback && popupData.data.callback();

                if (hasUniqueItems(bundle.entitlements) && !bundle.allowCompensation) {
                    store.dispatch(accountActions.deniedBundlesByUniqueItems(message.data.bundleId));
                }

                const isHidePopup = bundle.decoration?.includes(BUNDLE_DECORATION.HIDE_PURCHASE_RESULT);
                const isPurchaseAsReward = bundle.decoration?.includes(BUNDLE_DECORATION.PURCHASE_AS_REWARD);

                if (!isHidePopup && message.data.bundleId === account.purchasingBundleId) {
                    if (isPurchaseAsReward) {
                        store.dispatch(
                            appActions.changeVisibilityPopup({
                                name: POPUPS_NAME.REWARDS,
                                isVisible: true,
                                forcedClosurePopupNames: [POPUPS_NAME.CONFIRM_PURCHASE, POPUPS_NAME.CUSTOM_COMPONENT],
                                data: {
                                    rewards: [
                                        {
                                            images: {
                                                big: bundle.icons.big,
                                                medium: bundle.icons.medium,
                                                small: bundle.icons.small,
                                            },
                                            items: bundle.entitlements as IItemCommonData[],
                                            name: bundle.name,
                                            title: bundle.title,
                                            backgroundColor: bundle.backgroundColor,
                                        },
                                    ],
                                } as IRewardPopupData,
                            }),
                        );
                    } else {
                        events.emit(CustomEventNames.purchaseSuccess);
                        store.dispatch(
                            appActions.changeVisibilityPopup({
                                name: POPUPS_NAME.SUCCESS_PURCHASE,
                                isVisible: true,
                                data: {
                                    bundleId: message.data.bundleId,
                                    quantity: message.data.quantity,
                                    closeCallback: () => {
                                        popupData.data?.closeCallback?.();
                                        events.emit(CustomEventNames.closedPurchaseSuccessPopup, { bundle, quantity: message.data.quantity });
                                    },
                                },
                                forcedClosurePopupNames: [POPUPS_NAME.CONFIRM_PURCHASE, POPUPS_NAME.CUSTOM_COMPONENT],
                            }),
                        );
                    }
                } else if (isHidePopup) {
                    store.dispatch(appActions.changeVisibilityPopup({ name: HIDE_ALL_POPUPS }));
                }

                if (account.activeCouponFromBundle === message.data.bundleId) {
                    store.dispatch(accountActions.resetCoupon());
                }

                store.dispatch(accountActions.removeBundleFromTransaction());
                break;
            }

            case PURCHASE_RANDOM_BUNDLE_SUCCESS: {
                const response = message.data as IPurchaseRandomBundleSuccess;
                if (!bundles[response.parentBundleId]) {
                    return;
                }

                dwhExport.send(DWH_EVENTS.PURCHASE_SUCCESS, {
                    bundle_id: message?.data?.bundleId,
                });

                popupData?.data?.callback && popupData.data.callback();

                const bundleIdsArray = Object.keys(response.bundleIds);
                const isHideResult = bundleIdsArray.some((bundleId) => bundles[bundleId]?.decoration?.includes(BUNDLE_DECORATION.HIDE_PURCHASE_RESULT));
                if (!isHideResult) {
                    if (bundleIdsArray.length === 1 && response.bundleIds[bundleIdsArray[0] as any] === 1) {
                        store.dispatch(
                            appActions.changeVisibilityPopup({
                                name: POPUPS_NAME.SUCCESS_PURCHASE,
                                isVisible: true,
                                data: {
                                    bundleId: bundleIdsArray[0],
                                    closeCallback: popupData.data?.closeCallback,
                                },
                                forcedClosurePopupNames: [POPUPS_NAME.CONFIRM_PURCHASE],
                            }),
                        );
                    } else {
                        if (bundles[message.data.parentBundleId]) {
                            store.dispatch(
                                appActions.changeVisibilityPopup({
                                    name: POPUPS_NAME.PURCHASE_RANDOM_BUNDLE_SUCCESS,
                                    isVisible: true,
                                    data: {
                                        bundleIds: response.bundleIds,
                                        parentBundleId: message.data.parentBundleId,
                                        closeCallback: popupData.data?.closeCallback,
                                    },
                                    forcedClosurePopupNames: [POPUPS_NAME.CONFIRM_PURCHASE],
                                }),
                            );
                        }
                    }
                } else {
                    store.dispatch(appActions.changeVisibilityPopup({ name: HIDE_ALL_POPUPS }));
                }

                store.dispatch(accountActions.purchaseRandomBundle({ bundleId: message.data.parentBundleId, newSelectedBundleId: message.data.newSelectedBundleId }));
                break;
            }

            case TRADEIN_ERROR: {
                const target = findTarget(store.getState().tradein.lists, (message.data as ITradeInResult).targetId);
                store.dispatch(
                    appActions.changeVisibilityPopup({
                        name: POPUPS_NAME.TRADEIN_STATUS,
                        isVisible: true,
                        data: {
                            status: TradeInStatus.ERROR,
                            item: target,
                        },
                        forcedClosurePopupNames: [POPUPS_NAME.TRADEIN_CONFIRMATION],
                    }),
                );
                break;
            }

            case TRADEIN_SUCCESS: {
                const data = message.data as ITradeInResult;
                const { lists } = store.getState().tradein;
                const target = findTarget(lists, data.targetId);
                store.dispatch(
                    appActions.changeVisibilityPopup({
                        name: POPUPS_NAME.TRADEIN_STATUS,
                        isVisible: true,
                        data: {
                            status: TradeInStatus.SUCCESS,
                            item: target,
                        },
                        forcedClosurePopupNames: [POPUPS_NAME.TRADEIN_CONFIRMATION],
                    }),
                );
                store.dispatch(tradeinActions.setTradeInLists(markItemsAsUnAvailableAfterTransaction(lists, data.sourceId, data.targetId)));
                store.dispatch(tradeinActions.setTs(data.ts));
                store.dispatch(tradeinActions.clearState());
                break;
            }

            default:
                break;
        }
    }
}

export default Socket;
