import * as React from 'react';
import classNames from 'classnames';
import styles from './BundleAmount.scss';
import { BundlePurchaseTypes } from '~/types/bundle';
import { getPrimaryItem } from '~/utils/bundles';
import { items } from '@wg/wows-entities/const';
import { isChina } from '~/utils/settings';

interface IBundleAmount {
    bundles: IBundle[];
    selectedBundleId: number;
    onChange: (bundle: IBundle) => void;
}

const BundleAmount = (props: IBundleAmount) => {
    const isCn = isChina();

    return (
        <div className={styles.wrapper}>
            {props.bundles.map((bundle) => {
                const item = getPrimaryItem(bundle.entitlements) || bundle.entitlements?.[0];
                const eventumAmount = bundle.entitlements.find((item) => item.type !== items.LOOTBOX)?.amount || 0;
                const isBundleForReal = bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY;

                if (!item) {
                    return <></>;
                }

                let hasDiscount = Boolean(bundle.discount || !!bundle.originalPrice);
                let itemAmount = item.amount;
                if (isBundleForReal) {
                    if (isCn) {
                        itemAmount = eventumAmount;
                    }
                    hasDiscount = !!bundle.originalProduct?.discount;
                }

                const itemClassNames = classNames(styles.item, {
                    [styles.selected]: props.selectedBundleId === bundle.id,
                    [styles.discount]: hasDiscount,
                });

                const onClick = (event: React.MouseEvent) => {
                    event.stopPropagation();
                    props.onChange?.(bundle);
                };

                return (
                    <div className={itemClassNames} key={bundle.id} onClick={onClick}>
                        <span className={styles.x}>&times;</span>
                        {itemAmount}
                    </div>
                );
            })}
        </div>
    );
};

export default BundleAmount;
