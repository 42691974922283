import * as React from 'react';
import filtersMap, { getFiltersInfo } from '~/settings/filtersMap';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import styles from '~/components/CategoryFilter/CategoryFilter.scss';
import prepareFiltersItems from '~/components/CategoryFilter/FilterItems';
import classNames from 'classnames';
import { FILTER_CURRENCY_NAME } from '~/const';
import { getAvailableFilters, isNeedToRenderCurrencyFilter } from '~/utils/filters';

interface IFilterItemsProps {
    category: ICategoryList;
    onChangeFilter: (name: string, value: string) => void;
    state?: Record<string, string[]>;
}

const FiltersContent = ({ category, onChangeFilter, state }: IFilterItemsProps): any => {
    const ref: React.RefObject<HTMLDivElement> = React.useRef();
    const filterNames = filtersMap[category];

    useMaskScrollEffect(ref);

    return (
        <React.Fragment>
            <div className={styles.columns} ref={ref}>
                {filterNames.map((name: FILTER_INFO_NAME, index: number) => {
                    const filter = getFiltersInfo()[name];
                    if (!filter) {
                        return null;
                    }
                    const availableItems = getAvailableFilters(category, name);

                    if (availableItems.length < 2) {
                        if (filter.isForcedDisplay && !availableItems.length) {
                            return null;
                        } else if (!filter.isForcedDisplay) {
                            if (!(filter.name === FILTER_CURRENCY_NAME && isNeedToRenderCurrencyFilter(category, availableItems[0]))) {
                                return null;
                            }
                        }
                    }

                    const classesFilterColumn = classNames(styles.filterColumn, styles[name]);

                    return (
                        <div className={classesFilterColumn} key={`${name}_${index}`}>
                            <div className={styles.filterColumnTitle}>{filter.title}</div>
                            <div className={styles.filterColumnBody}>{prepareFiltersItems(category, name, onChangeFilter, state)}</div>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default FiltersContent;
