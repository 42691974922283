import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './AuctionBidButton.scss';
import Button, { ButtonVariant } from '~/components/Button/Button';
import { t } from '~/utils/localization';
import Timer from '~/components/Timer/Timer';
import Attention from '~/components/Attention/Attention';
import { getNeededAmount, isEnoughCurrency } from '~/utils/auction';
import CurrencyShortageBlock from '~/components/CurrencyShortageBlock/CurrencyShortageBlock';
import FastGoldLinkContainer, { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import { assets } from '@wg/wows-entities/const';
import { CURRENCY } from '~/const';
import EventumCnRedirectButton from '~/components/EventumCnRedirectButton/EventumCnRedirectButton';
import { RootState, useAppSelector } from '~/Store';

interface IAuctionBidButton {
    lotId: string;
    onClick: (event?: React.MouseEvent) => void;
    isBidAlreadyPlaced?: boolean;
    isDisabled?: boolean;
    isProcessing?: boolean;
    coolDownAt?: number;
    typeButton?: 'orange' | 'default';
    isForceRenderPurchaseButton?: boolean;
    onFallbackClick?: () => void;
    renderFastGoldLink?: boolean;
    minimalBid: {
        currency: string;
        amount: number;
    };
}

const stateSelector = (state: RootState) => {
    return {
        account: state.auction.account,
        balance: state.account.balance,
        accountId: state.account.id,
    };
};

const AuctionBidButton = (props: IAuctionBidButton) => {
    const appState = useAppSelector(stateSelector, equal);
    const transaction = appState.account?.transactions || [];
    const defaultLabel = props.isBidAlreadyPlaced ? t('Изменить ставку') : t('Сделать ставку');

    let isDisabled = props.isDisabled || props.isProcessing;
    let labelText = defaultLabel;

    if (transaction.includes(props.lotId)) {
        isDisabled = true;
        labelText = t('Обработка');
    }

    const onClick = (event?: React.MouseEvent) => {
        if (isDisabled) {
            props.onFallbackClick?.();
            return;
        }
        !isDisabled && props.onClick?.(event);
    };

    const [isCoolDownEnabled, setCoolDownFlag] = React.useState<boolean>(props.coolDownAt ? props.coolDownAt > Date.now() : false);
    const [isProcessing, setProcessing] = React.useState<boolean>(props.isProcessing);

    React.useEffect(() => {
        setCoolDownFlag(props.coolDownAt > Date.now());
    }, [props.coolDownAt]);

    React.useEffect(() => {
        setProcessing(props.isProcessing);
    }, [props.isProcessing]);

    if (isProcessing) {
        return <Button variant={ButtonVariant.orange} label={t('Обработка')} disabled={true} />;
    }

    const neededAmount = getNeededAmount(props.minimalBid.currency, props.minimalBid.amount, appState.balance);
    const _isEnoughCurrency = !!appState.accountId && isEnoughCurrency(props.minimalBid.currency, props.minimalBid.amount, appState.balance);

    const FastGold = () => {
        if (!appState.accountId || !props.renderFastGoldLink) {
            return null;
        }

        if (props.minimalBid.currency === assets.GOLD) {
            return <FastGoldLinkContainer withIcon={true} className={styles.fastGoldLink} place={FAST_GOLD_PLACE.AUCTION_LOT_VIEW} goldShortage={neededAmount} />;
        }

        if (props.minimalBid.currency === CURRENCY.EVENTUM_CN) {
            return <EventumCnRedirectButton className={styles.fastGoldLink} withIcon />;
        }

        return null;
    };

    if (!props.isForceRenderPurchaseButton && appState.accountId && !_isEnoughCurrency && !props.isBidAlreadyPlaced) {
        return (
            <div className={styles.currencyShortageBlock}>
                <CurrencyShortageBlock currencies={[{ currency: props.minimalBid.currency, amount: neededAmount }]} />
                <FastGold />
            </div>
        );
    }

    if (isCoolDownEnabled) {
        return (
            <Attention
                level={'warning'}
                className={styles.attentionWrapper}
                label={
                    <div className={styles.coolDownWrapper}>
                        <span>{t('Изменить можно через:')}</span>
                        <Timer time={props.coolDownAt} className={styles.timer} timerClassName={styles.timerText} onFinishCallback={() => setCoolDownFlag(false)} hiddenZero={true} withoutIcon />
                    </div>
                }
                isInline={true}
            />
        );
    }

    if (props.isBidAlreadyPlaced) {
        return props.typeButton === 'default' ? (
            <Button label={labelText} disabled={isDisabled} onClick={onClick} />
        ) : (
            <div className={styles.currencyShortageBlock}>
                <Button variant={ButtonVariant.orange} label={labelText} disabled={isDisabled} onClick={onClick} />
                <FastGold />
            </div>
        );
    }

    return (
        <div className={styles.currencyShortageBlock}>
            <Button variant={ButtonVariant.orange} label={labelText} disabled={isDisabled} onClick={onClick} />
            <FastGold />
        </div>
    );
};

export default AuctionBidButton;
