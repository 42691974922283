import * as React from 'react';
import classNames from 'classnames';
import styles from './Tooltip.scss';
import { TooltipFooter } from '@wg/wows-react-uikit';
import { TooltipTypes } from '~/types/common';

export interface IDefaultTooltipClasses {
    header?: string;
    body?: string;
    footer?: string;
}

export interface IDefaultTooltip {
    title?: string;
    text?: string | string[] | React.ReactNode[] | React.ReactNode;
    footer?: any;
    type?: TooltipTypes;
    mouseIcon?: boolean;
    classes?: IDefaultTooltipClasses;
    onShow?: DefaultFunction;
}

const DefaultTooltip = ({ title, text, footer, type, mouseIcon, classes, onShow }: IDefaultTooltip) => {
    const isArrayTexts = Array.isArray(text);
    const timeout = React.useRef<ReturnType<typeof setTimeout>>(null);

    const classesBody = classNames(
        styles.defaultTooltipBody,
        {
            [styles.warning]: type === TooltipTypes.WARNING,
            [styles.withBorderBottom]: isArrayTexts,
        },
        classes?.body,
    );

    const classesHeader = classNames(styles.defaultTooltipHeader, classes?.header);
    const classesFooter = classNames(styles.footer, classes?.footer, {
        [styles.hiddenBorder]: title && footer && !text,
    });

    React.useEffect(() => {
        if (!timeout.current && onShow) {
            timeout.current = setTimeout(onShow, 500);
        }
        return () => {
            clearTimeout(timeout.current);
        };
    }, []);

    return (
        <React.Fragment>
            <div className={styles.defaultTooltip}>
                {title && <div className={classesHeader}>{title}</div>}
                {text && typeof text === 'string' && <div className={classesBody} dangerouslySetInnerHTML={{ __html: text }} />}
                {text && typeof text === 'object' && !isArrayTexts && <div className={classesBody}>{text}</div>}
                {text &&
                    isArrayTexts &&
                    (text as string[]).map((text, index) => {
                        if (typeof text === 'string') {
                            return <div key={`tooltip_text_item_${index}`} className={classesBody} dangerouslySetInnerHTML={{ __html: text }} />;
                        }

                        return (
                            <div key={`tooltip_text_item_${index}`} className={classesBody}>
                                {text}
                            </div>
                        );
                    })}
            </div>
            {footer && <div className={classesFooter}>{mouseIcon ? <TooltipFooter mouseIcon={'left'}>{footer}</TooltipFooter> : <div className={styles.footerText}>{footer}</div>}</div>}
        </React.Fragment>
    );
};

export default DefaultTooltip;
