import * as React from 'react';
import { SetStateAction } from 'react';
import ReactDOM from 'react-dom';
import styles from './WelcomePage.scss';
import { GUIDE_NAMES, IGuideStep, PLACEMENT, type IGuidePlacement } from '~/components/WelcomePage/steps';
import WelcomePageTooltip from '~/components/WelcomePage/WelcomePageTooltip';
import { shallowEqual } from 'react-redux';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { isInViewport } from '~/utils/dom';
import classNames from 'classnames';
import useWindowResize from '~/hooks/useWindowResize';
import { useAppSelector } from '~/Store';

interface IGuide {
    step: IGuideStep;
    run: boolean;
    target: HTMLDivElement;
    onBeaconClick?: (step: IGuideStep) => void;
    onStartGuide?: (step: IGuideStep) => void;
    onFinishGuide?: (step: IGuideStep, isNotFoundTarget?: boolean) => void;
    className?: string;
    placement?: IGuidePlacement;
    centerBeacon?: boolean;
    content?: string | React.ReactNode;
}

export type ITargetRect = Pick<DOMRect, 'x' | 'y' | 'width' | 'height'>;

interface IGuideState {
    isVisibleTooltip: boolean;
    targetRect: ITargetRect;
}

export const getRectByElement = (target: HTMLElement): ITargetRect => {
    const targetRect = target?.getBoundingClientRect();

    if (!targetRect) {
        return {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };
    }

    return {
        x: targetRect.x,
        y: targetRect.y,
        width: targetRect.width,
        height: targetRect.height,
    };
};

export const prepareCorrectPlacement = (step: IGuideStep, target: any) => {
    if (step.name === GUIDE_NAMES.guide_salvage_resources && step.placement === PLACEMENT.LEFT && target.offsetLeft <= 300) {
        step.placement = PLACEMENT.TOP;
    }
};

const Guide = ({ target, step, onFinishGuide, className, placement, centerBeacon, content }: IGuide) => {
    prepareCorrectPlacement(step, target);

    const [state, setState]: [IGuideState, SetStateAction<any>] = React.useState({
        isVisibleTooltip: false,
        targetRect: getRectByElement(target),
    });

    const onResize = () => {
        setState({
            ...state,
            targetRect: getRectByElement(target),
        });
    };

    const close = () => {
        onFinishGuide?.(step);
    };

    const onScroll = () => {
        if (step.closeAfterScroll) {
            close();
            return;
        }

        if (!step.checkVisibleInViewPort) {
            return;
        }

        const viewContainer = RefManager.getRef(RefManagerKeys.MainContainer);

        if (isInViewport(target, viewContainer)) {
            close();
        }
    };

    const ref = React.useRef<HTMLDivElement>(null);
    const currentPage = useAppSelector((state) => state.app.currentPage, shallowEqual);

    useWindowResize(onResize);

    React.useEffect(() => {
        if (!target) {
            close();
        }

        // Check layout shifts after navigation
        const newTargetRect = getRectByElement(target);
        if (newTargetRect.x !== state.targetRect.x || newTargetRect.y !== state.targetRect.y) {
            setState({
                ...state,
                targetRect: newTargetRect,
            });
        }
    }, [currentPage?.name, currentPage?.isBundlePage]);

    React.useEffect(() => {
        const viewContainer = RefManager.getRef(RefManagerKeys.MainContainer);
        viewContainer?.addEventListener('scroll', onScroll);

        return () => {
            viewContainer?.removeEventListener('scroll', onScroll);
        };
    }, []);

    step.beforeShown && step.beforeShown();

    if (centerBeacon !== undefined) {
        step.centerBeacon = centerBeacon;
    }

    if (content) {
        step.content = content;
    }

    return ReactDOM.createPortal(
        <div className={classNames(styles.guideTooltip, className)} ref={ref}>
            <WelcomePageTooltip targetRect={state.targetRect} position={placement || step.placement} closeProps={{ onClick: close }} step={step} />
        </div>,
        document.body,
    );
};

export default Guide;
