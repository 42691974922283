import Account from '~/account/Account';
import { redirectToLogin, redirectToLoginForPurchase } from '~/utils/utils';
import { isNeedToShowDownloadGamePopup, showDownloadGamePopup } from '~/components/Popups/settings';
import { isFreeBundle } from '~/utils/bundles';
import { _purchase, _purchaseRandomBundle, purchaseBundleFromPopup } from '~/utils/purchase';
import store from '~/Store';
import { POPUPS_NAME } from '~/components/PopupManager';
import { chooseRandomBundle } from '~/api/purchase';
import { appActions } from '~/Store/appSlice';
import { accountActions } from '~/Store/accountSlice';

interface IBundleProcessorParams {
    popupData?: {
        bundleId?: number;
        fromCategory?: boolean;
        preset?: ICategoryPresets;
    };
}

export default class PurchaseProcessor {
    private readonly bundle: IBundle;

    private readonly params: IBundleProcessorParams;

    constructor(bundle: IBundle, params?: IBundleProcessorParams) {
        this.bundle = bundle;
        this.params = params;
    }

    public isPurchaseAvailable(): boolean {
        if (!Account.getAccount()) {
            if (isFreeBundle(this.bundle)) {
                redirectToLogin();
            } else {
                redirectToLoginForPurchase(this.bundle.id);
            }
            return false;
        }

        if (isNeedToShowDownloadGamePopup()) {
            showDownloadGamePopup();
            return false;
        }

        return true;
    }

    public purchasePeriodicBundle(bundle: IBundle) {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        if (isFreeBundle(bundle)) {
            this.purchase(bundle);
        } else {
            purchaseBundleFromPopup(bundle, this.bundle);
        }
    }

    public purchaseRandomBundle(activeBundle: IBundle, callback?: () => void, closeCallback?: () => void, isFree?: boolean) {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        _purchaseRandomBundle(activeBundle, this.bundle, callback, closeCallback, isFree);
    }

    public async chooseRandomBundle() {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        store.dispatch(accountActions.addBundleToTransaction([this.bundle.id]));

        const responsePurchase = await chooseRandomBundle(this.bundle.id);

        store.dispatch(accountActions.removeBundleFromTransaction(this.bundle.id));
        store.dispatch(accountActions.openBundle(this.bundle.id));
        store.dispatch(accountActions.purchaseRandomBundle({ bundleId: this.bundle.id, newSelectedBundleId: responsePurchase.id }));

        return responsePurchase;
    }

    public purchase(bundle?: IBundle) {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        _purchase(bundle || this.bundle);
    }

    public purchaseBundleFromPopup(currentChildBundle: IBundle, callback?: () => void, closeCallback?: () => void, isFree?: boolean) {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        purchaseBundleFromPopup(currentChildBundle, this.bundle, callback, closeCallback, isFree);
    }

    public showPopup() {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        store.dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.CONFIRM_PURCHASE,
                isVisible: true,
                data: this.params?.popupData || { bundleId: this.bundle.id },
            }),
        );
    }
}
