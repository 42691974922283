import { LocalStorageHelper } from '~/utils/storage';
import { devModeKeyMap } from '~/utils/devMode';
import Account from '~/account/Account';
import store from '~/Store';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { isEmptyObject } from '~/utils/utils';
import { SANTA_PAGE_THEME, SNOWFALL } from '~/Layouts/Themes/ThemeManager';
import { appActions } from '~/Store/appSlice';

export const getAnimationName = (category: ICategory, bundle?: IBundle) => {
    if (category.theme === SANTA_PAGE_THEME) {
        return category.theme;
    }

    if (bundle && !isEmptyObject(bundle.videoBackground)) {
        return bundle.name;
    }

    return category.name;
};

export const isDisabledAnimationByName = (name: string) => {
    const flag = LocalStorageHelper.get(Account.getLSKey(`animation:${name}`));
    return flag === true;
};

export const hasAnimationSetting = (name: string) => {
    return LocalStorageHelper.get(Account.getLSKey(`animation:${name}`)) !== null;
};

export const updateAnimationStatusByName = (name: string, isDisabled = true) => {
    LocalStorageHelper.set(Account.getLSKey(`animation:${name}`), isDisabled);
    store.dispatch(appActions.updateAnimationStatusCategory({ categoryName: name, status: isDisabled }));
};

export const isEnabledAnimationByCategory = (category: ICategory, bundle?: IBundle) => {
    if (category?.theme === SANTA_PAGE_THEME) {
        return !bundle;
    }

    if (category?.theme === SNOWFALL) {
        return true;
    }

    return !isEmptyObject(bundle?.videoBackground || {}) || !isEmptyObject(category?.videoBackground || {});
};

export const getAnimationStatusByName = (category: ICategory, bundle?: IBundle) => {
    if (!category && !bundle) {
        return false;
    }

    return !isDisabledAnimationByName(getAnimationName(category, bundle));
};

export const updateAnimationStatusFromCategory = (category: ICategory, bundle: IBundle, isDisabled: boolean) => {
    if (!category && !bundle) {
        return false;
    }

    const meta: Record<string, string> = {
        status: !isDisabled ? 'on' : 'off',
    };

    if (bundle) {
        meta['bundle_name'] = bundle.name;
    } else {
        meta['category_name'] = category.name;
    }

    dwhExport.send(DWH_EVENTS.CHANGE_ANIMATION_STATUS, meta);

    updateAnimationStatusByName(getAnimationName(category, bundle), isDisabled);
};

export const isDisabledVideo = () => {
    return LocalStorageHelper.get(devModeKeyMap.disableVideo);
};
