import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import client from '~/client';
import { GRAPHQL_QUERIES_MAP } from '~/queries/helpers';
import { logInfo } from '~/utils/logging';
import { settings } from '~/utils/settings';
import { LocalStorageHelper } from '~/utils/storage';

export interface IShipFeatures {
    shipFeatureMap: ShipFeatureMap;
}

const initialState: Partial<IShipFeatures> = {
    shipFeatureMap: {},
};

export const fetchFeatures = createAsyncThunk('ship_features/load', async () => {
    const data: { shipFeatureMap: ShipFeatureMap } = { shipFeatureMap: {} };
    const shipFeatureStorage = LocalStorageHelper.get('shipFeatures');

    if ((shipFeatureStorage?.version === settings.version || !settings.version) && shipFeatureStorage?.lang === settings.languageCode && shipFeatureStorage?.data) {
        return shipFeatureStorage?.data;
    }

    try {
        const response = await client.get().query({
            query: GRAPHQL_QUERIES_MAP['FEATURES'],
            variables: {
                lang: settings.languageCode,
            },
        });
        if (response) {
            response.data?.features?.forEach((feature: IFeaturesItem) => {
                data.shipFeatureMap[feature.id] = feature;
            });

            LocalStorageHelper.set('shipFeatures', { version: settings.version, lang: settings.languageCode, data: data.shipFeatureMap });

            return data.shipFeatureMap;
        }
    } catch (e) {
        logInfo('response not successful for item');
    }
});

export const shipFeaturesSlice = createSlice({
    name: 'ship_features',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchFeatures.fulfilled, (state, action) => {
            state.shipFeatureMap = action.payload;
        });
    },
});

export const shipFeatures = shipFeaturesSlice.actions;

export default shipFeaturesSlice.reducer;
