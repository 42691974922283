import * as React from 'react';
import styles from './WelcomePage.scss';
import classNames from 'classnames';
import { IGuideStep, type IGuidePlacement } from '~/components/WelcomePage/steps';
import { Popover } from '@wg/wows-react-uikit';
import { playButtonClickSound } from '~/api/WoWsClient';
import type { ITargetRect } from './Guide';

const getRectStyles = ({ y, x, width, height }: ITargetRect) => ({
    top: `${y}px`,
    left: `${x}px`,
    width: `${width}px`,
    height: `${height}px`,
});

export interface IWelcomePageTooltip {
    targetRect: ITargetRect;
    position: IGuidePlacement;
    step: IGuideStep;
    closeProps: {
        onClick: (step: IGuideStep) => void;
    };
    onCallback?: () => void;
}

const WelcomePageTooltip = ({ targetRect, position, step, closeProps, onCallback }: IWelcomePageTooltip) => {
    const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
    const [isVisible, setVisible] = React.useState(false);
    const [tooltipPosition, setTooltipPosition] = React.useState(getRectStyles(targetRect));

    const wrapperClassNames = classNames(styles.tooltipWrapper, {
        [styles.noVisible]: !isVisible,
        [styles.centerBeacon]: step.centerBeacon,
    });

    React.useEffect(() => {
        setTooltipPosition(getRectStyles(targetRect));

        const timer = setTimeout(() => {
            setVisible(true);
            onCallback && onCallback();
        }, 1000);

        return () => clearTimeout(timer);
    }, [targetRect]);

    return (
        <div
            className={wrapperClassNames}
            style={{ ...tooltipPosition }}
            ref={ref}
            onClick={() => {
                if (closeProps.onClick) {
                    playButtonClickSound();
                    closeProps.onClick(step);
                }
            }}
        >
            <Popover
                className={classNames(styles.popover, 'armory__auto--popover')}
                onClose={() => {
                    // empty function
                }}
                position={position}
                isVisible={true}
                header={step.title}
                content={step.content}
                align={step.align}
                withBeacon
                centerBeacon={step.centerBeacon}
            />
        </div>
    );
};

export default WelcomePageTooltip;
