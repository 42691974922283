import * as React from 'react';
import { settings } from '~/utils/settings';
import { arrayToObjectByKey } from '~/utils/utils';
import classNames from 'classnames';
import styles from '~/components/Footer/Footer.scss';
import { t } from '~/utils/localization';
import { Realms } from '~/types/settings';

interface ILanguageFooter {
    onClose?: () => void;
}

export const LanguageFooter = (props: ILanguageFooter) => {
    const realmsConfig = settings.realmsConfig;
    const currentRealm = realmsConfig.filter((realm) => realm.realm === settings.realm)[0];
    const [selectedRealm, setRealm] = React.useState(currentRealm.realm);
    const realm = arrayToObjectByKey(settings.realmsConfig, 'realm');

    const changeLanguage = (code: string) => {
        location.href = realm[selectedRealm].changeLanguageUrl.replace('{}', code).replace('{}', location.pathname);
    };

    const changeRealm = (realmCode: Realms) => {
        setRealm(realmCode);
    };

    const languagesItems: any = [];

    realm[selectedRealm].languageCodes.forEach((code: string) => {
        languagesItems.push(
            <div
                className={classNames(styles.languageItem, {
                    [styles.active]: code === settings.languageCode && selectedRealm === currentRealm.realm,
                })}
                onClick={changeLanguage.bind(this, code)}
            >
                {settings.allLanguages[code]}
            </div>,
        );
    });

    const itemsColumns = [];
    for (let i = 0; i < languagesItems.length; i += 5) {
        const array = languagesItems.slice(i, i + 5);
        itemsColumns.push(
            <div className={styles.languageColumn} key={i}>
                {array}
            </div>,
        );
    }

    return (
        <div className={styles.languageWrapper}>
            <div
                className={styles.close}
                onClick={() => {
                    props.onClose?.();
                }}
            />
            <div className={styles.languageHeader}>
                <div className={styles.languageHeaderTitle}>{t('Выберите регион')}</div>
                <div className={styles.languageHeaderSubTitle}>{t('Выбор другого региона может привести к изменению содержимого сайта.')}</div>
            </div>
            <div className={styles.languageContent}>
                <div className={styles.realmWrapper}>
                    <div className={classNames(styles.realmContent, styles[selectedRealm])}>
                        {realmsConfig.map((realm) => {
                            const realmClassNames = classNames(styles.realmItem, {
                                [styles.active]: realm.realm === selectedRealm,
                            });

                            return (
                                <div className={realmClassNames} key={realm.realm} onClick={changeRealm.bind(this, realm.realm)}>
                                    {realm.title}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={styles.languageListContent} key={selectedRealm}>
                    {itemsColumns}
                </div>
            </div>
        </div>
    );
};
