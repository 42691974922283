import * as React from 'react';
import styles from '~/components/Popups/popups.scss';
import { useDispatch } from 'react-redux';
import { POPUPS_NAME } from '~/components/PopupManager';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { _purchase as apiPurchaseBundle, calculateBundlePrices } from '~/utils/purchase';
import PurchaseButton, { PurchaseButtonTheme } from '~/components/Button/PurchaseButton';
import useClickAway from '~/hooks/useClickAway';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import Account from '~/account/Account';
import { isInGameBrowser, isMobileOrTabletWindow, updateSearchParam } from '~/utils/utils';
import Button from '~/components/Button/Button';
import dwhExport, { EventTypes } from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import AttentionLimitWidget from '~/components/BundleLimitWidget/Attention/AttentionLimitWidget';
import { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { CopyrightContent } from '~/components/Popups/Purchase/AdditionalContent';
import PurchaseCustomizationWrapper from '~/components/Popups/Purchase/Customization/PurchaseCustomizationWrapper';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { startHeadShakeAnimation } from '~/utils/auction';
import PurchasePopupDetails from '~/components/Popups/Purchase/PurchasePopupDetails';
import { canBoughtContainer, isEnabledPurchaseLimitToContainer, REASON_FAIL_PURCHASE_CONTAINER } from '~/components/BundleLimitWidget/settings';
import useRandomBundleData from '~/hooks/useRandomBundleData';
import { playButtonClickSound } from '~/api/WoWsClient';
import {
    canBoughtRandomBundleWithMaxAmount,
    getAvailableBundlesForPurchase,
    getBundleDisclaimers,
    getDefaultExchangeCurrency,
    getProgressiveDiscountFromBundle,
    getPurchaseExchangeRates,
    getPurchaseLimit,
    getSerialBundlesById,
    hasLootboxRestrictionInSerialSequence,
    hasUniqueItems,
    isAvailableMultiplePurchaseForSerialBundle,
    isContainLootbox,
    isEnoughCurrencyForPurchaseSerialSequence,
    isFreeBundle,
    isMultiplePurchaseAvailable,
    isNeedToShowAdultCopyright,
    isPeriodicBundle,
    isSteelExchangeBundle,
} from '~/utils/bundles';
import Currency from '~/components/Currency/Currency';
import equal from 'fast-deep-equal/react';
import { SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import Link, { LinkColors } from '~/components/Link/Link';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import { accountActions } from '~/Store/accountSlice';

interface IConfirmPurchase {
    data: {
        bundleId?: number;
    };
}

const stateSelector = (state: RootState) => {
    return {
        popupActive: state.app.popupActive,
        port: state.app.port,
        activeCouponId: state.account.activeCouponId,
        balance: state.account.balance,
        bundlesInTransaction: state.account.bundlesInTransaction,
        selectedRandomBundles: state.account.selectedRandomBundles,
        bundles: state.app.bundles,
        coupons: state.account.coupons,
        restrictedLootboxesPurchaseCount: state.account.restrictedLootboxesPurchaseCount,
        activePreset: state.app.activePreset,
        currentPage: state.app.currentPage,
        isAdult: state.account.isAdult,
        categories: state.app.categories,
        accountId: state.account.id,
    };
};

const ConfirmPurchase = (props: IConfirmPurchase) => {
    const ref = React.useRef(null);
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const { popup, isPopupActive } = React.useContext(PopupManagerContext);
    const isActiveCoupon = !!state.activeCouponId;
    const popupData = popup?.data || {};
    const bundleId = props.data.bundleId ? props.data.bundleId : popupData.bundleId;
    let bundle: IBundle = structuredClone(state.bundles[bundleId]);
    const [isBlockedInterface, setIsBlockedInterface] = React.useState(false);
    let interfaceLockTimer: NodeJS.Timeout = null;
    const _isRandomBundle = bundle.isRandom;
    const _isPeriodicBundle = isPeriodicBundle(bundle);
    const randomBundleData = useRandomBundleData(bundle);
    const isMobile = !isInGameBrowser && isMobileOrTabletWindow && !state.port?.isVisible;

    if (randomBundleData.parentRandomBundle) {
        bundle = randomBundleData.currentBundle;
    }

    const isEnabledCoupon = Account.isEnabledCouponsFromBundle(state.coupons, bundle);
    const activeCoupon = Account.getActiveCoupon(state.activeCouponId, state.coupons);

    const _isEnabledPurchaseLimitToContainer = isEnabledPurchaseLimitToContainer(bundle);
    const _isFreeBundle = isFreeBundle(bundle);

    let canBoughtWithRestriction;
    let reasonCantBoughtCode;
    if (_isRandomBundle) {
        canBoughtWithRestriction = _isFreeBundle || canBoughtRandomBundleWithMaxAmount(randomBundleData.purchaseMaxCountForRandomBundle as Nullable<number>);
        reasonCantBoughtCode = canBoughtWithRestriction ? 1 : REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE;
    } else {
        canBoughtWithRestriction = canBoughtContainer(bundle, state.restrictedLootboxesPurchaseCount);
        reasonCantBoughtCode = Number(canBoughtWithRestriction);
    }

    const isActiveTransaction = state.bundlesInTransaction?.includes(bundle.id);

    React.useEffect(() => {
        updateSearchParam({ bundleId: bundleId, purchase: true });

        let dwhEventName = null;

        if (state.port?.isVisible) {
            dwhEventName = DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_PORT;
        } else if (state.currentPage?.isBundlePage) {
            dwhEventName = DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_BUNDLE;
        } else {
            dwhEventName = DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_CATEGORY;
        }

        if (dwhEventName) {
            const dwhMessage: Record<string, number | string> = { bundle_id: bundleId };
            dwhExport.send(dwhEventName, dwhMessage, EventTypes.Bundle);
        }

        return () => {
            updateSearchParam({ bundleId: null, purchase: null });

            if (!state.currentPage?.isBundlePage || popupData.fromCategory || isMobile) {
                dispatch(appActions.updateQuantity({ bundleId: bundle.parentBundleId ?? bundle.id }));
                dispatch(accountActions.resetCoupon());
            } else if (state.port?.isVisible) {
                dispatch(accountActions.resetCoupon());
            }

            if (!state.currentPage?.isBundlePage && !state.port?.isVisible) {
                dispatch(appActions.setFocusPreset(null));
            }

            interfaceLockTimer && clearTimeout(interfaceLockTimer);
        };
    }, []);

    const _canChangeQuantity = isMultiplePurchaseAvailable(bundle) || randomBundleData.isAvailableChangeQuantity;
    const _isEnabledCoupon = isEnabledCoupon && (bundle.quantityData?.quantity === 1 || !bundle.quantityData?.quantity);

    const _isNeedToShowAdultCopyright = isNeedToShowAdultCopyright(bundle);
    const _isNeedToShowCopyrightFromContainer = _isNeedToShowAdultCopyright && isContainLootbox(bundle, randomBundleData.parentRandomBundle, randomBundleData.availableAmountForFullPurchase);
    const _isNeedToShowCopyrightFromRandom = _isNeedToShowAdultCopyright && _isRandomBundle;
    const _isNeedToShownAdultCheckbox = !bundle.hideAdultCheck && (_isNeedToShowCopyrightFromContainer || _isNeedToShowCopyrightFromRandom);

    const [button, setButton] = React.useState<{ label?: string; isDisabled?: boolean; isDisabledByNotAdult?: boolean; isFetching?: boolean }>({
        label: t('Купить'),
        isDisabled: false,
        isDisabledByNotAdult: _isNeedToShownAdultCheckbox && !state.isAdult,
        isFetching: isActiveTransaction,
    });

    const changeUserAdultFlag = () => {
        setButton({ isDisabledByNotAdult: !!state.isAdult });
        Account.updateAccountSettings({ is_adult: !state.isAdult });
        dispatch(accountActions.updateUserAdultsFlag());
    };

    const onChangeQuantityForFullPurchaseRandomBundle = (amount: number) => {
        const bundle = randomBundleData.parentRandomBundle;
        const prices = calculateBundlePrices(bundle, state.balance, amount, null, getProgressiveDiscountFromBundle(bundle, amount));
        dispatch(appActions.updateQuantity({ bundleId: bundle.id, quantity: amount, quantityData: prices }));
    };

    const closePopup = function () {
        if (isBlockedInterface) {
            return;
        }

        if (state.popupActive?.name === POPUPS_NAME.BUNDLE_LIMIT_INFO) {
            return;
        }

        dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.CONFIRM_PURCHASE }));
    };

    const onClickFromDisableButton = () => {
        const restrictionRef = RefManager.getRef(RefManagerKeys.PurchaseRestriction);
        if (restrictionRef) {
            startHeadShakeAnimation(restrictionRef, styles.headShakeAnim);
        }
    };

    const _purchase = () => {
        if (_isNeedToShownAdultCheckbox && !state.isAdult) {
            return;
        }

        setIsBlockedInterface(true);
        interfaceLockTimer = setTimeout(() => {
            setIsBlockedInterface(false);
        }, 7 * 1000);

        setButton({
            isFetching: true,
            label: t('Обработка'),
        });

        apiPurchaseBundle(bundle, () => {
            if (isMobileOrTabletWindow) {
                setIsBlockedInterface(false);
                setButton({
                    isDisabled: false,
                    label: t('Купить'),
                });
            }
        });
    };

    useClickAway(
        ref,
        (event) => {
            if (!isPopupActive) {
                return;
            }
            const target = event.target as HTMLDivElement;

            if ((target.parentNode as HTMLLinkElement).tagName.toLowerCase() === 'a' || target.tagName.toLowerCase() === 'a') {
                return;
            }

            if (!isMobile) {
                closePopup();
            }
        },
        [isPopupActive],
    );

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        playButtonClickSound();
        closePopup.bind(this)();
    }, [KEYS_CODE.ESC]);

    bundle.quantityData = {
        ...bundle.quantityData,
        ...calculateBundlePrices(bundle, state.balance, bundle.quantityData?.quantity || 1, Account.getActiveCoupon(state.activeCouponId, state.coupons)?.discount),
    };

    const isNeedToShowExchangeMessage = isSteelExchangeBundle(Object.keys(bundle.quantityData.final));

    const image = { backgroundImage: `url(${bundle.icons.small})` };

    const purchaseExchangeRates = getPurchaseExchangeRates();
    const defaultExchangeCurrency = getDefaultExchangeCurrency();
    const exchangeToCurrency = purchaseExchangeRates[`${defaultExchangeCurrency}_coal`];

    const category = state.categories[state.currentPage?.name];

    const limit = state.accountId ? getPurchaseLimit(bundle) : bundle.limitedQuantity?.personalLimit;
    const isNeedToShowLimit = !_isRandomBundle && !hasUniqueItems(bundle.entitlements);

    const availableAmountForFullPurchase = randomBundleData?.maxAmountForOnePurchaseWithoutSinglePurchaseLimit;
    const customDisclaimers = getBundleDisclaimers(bundle);
    const hasCustomDisclaimers = !!customDisclaimers?.length;

    const WebCopyrightContent = React.useCallback(() => {
        return (
            <div className={styles.copyrightContent}>
                <CopyrightContent
                    isNeedToShowCopyrightFromContainer={_isNeedToShowCopyrightFromContainer}
                    isNeedToShowCopyrightFromRandom={_isNeedToShowCopyrightFromRandom}
                    isNeedToHideDisclaimerAppearance={hasCustomDisclaimers}
                />
            </div>
        );
    }, []);

    const hasRestriction = reasonCantBoughtCode < 0;

    const serualSequence = getSerialBundlesById(state.bundles, bundle.id);
    const purchasedSequence = serualSequence.filter((bundle) => bundle.isPurchased);
    const availableBundlesForPurchase = getAvailableBundlesForPurchase(state.balance, serualSequence, state.coupons);
    const hasLootboxRestrictionForSerialBundles = hasLootboxRestrictionInSerialSequence(availableBundlesForPurchase);
    const status = isEnoughCurrencyForPurchaseSerialSequence(availableBundlesForPurchase, state.balance);
    const isAvailableWholePurchase =
        isAvailableMultiplePurchaseForSerialBundle(bundle, state.bundles) &&
        purchasedSequence?.length + availableBundlesForPurchase?.length === serualSequence.length &&
        status.isEnought &&
        availableBundlesForPurchase?.length > 1 &&
        !hasLootboxRestrictionForSerialBundles;

    const openWholeSerialPurchasePopup = () => {
        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE,
                isVisible: true,
                data: {
                    sequence: availableBundlesForPurchase,
                    firstAvailableBundleId: bundle.id,
                },
            }),
        );
    };

    const confirmBackground = bundle.icons?.confirmationBackground || state.bundles[bundle.parentBundleId]?.icons?.confirmationBackground;
    const isSantaCategory = category?.theme === SANTA_PAGE_THEME;

    return (
        <PurchaseCustomizationWrapper bundle={bundle} category={category}>
            {!!confirmBackground && !isSantaCategory && (
                <div
                    className={styles.background}
                    style={{
                        backgroundImage: `url(${confirmBackground})`,
                    }}
                />
            )}
            <Popup
                renderEscButton={!isBlockedInterface}
                onClose={closePopup}
                closeIconClassName={'armory__auto--popup_close'}
                popupClassName={classNames('armory__auto--purchase_popup', styles.purchasePopupContainer, styles[category?.theme], {
                    [styles.isPort]: state.port?.isVisible,
                })}
                AfterPopupComponent={WebCopyrightContent}
                className={classNames(
                    styles.purchasePopupWrapper,
                    {
                        [styles.isMobilePage]: isMobile,
                        [styles.withBackground]: !_isRandomBundle ? !!confirmBackground : bundle?.quantityData?.quantity === 1 ? !!confirmBackground : false,
                    },
                    styles[category?.theme],
                )}
            >
                <PopupHeader
                    title={t('Подтверждение покупки')}
                    className={classNames({
                        [styles.popupTitleSanta]: isSantaCategory,
                    })}
                />
                <PopupBody>
                    <PurchasePopupDetails
                        popup={popup}
                        parentRandomBundle={randomBundleData.parentRandomBundle}
                        canChangeQuantity={_canChangeQuantity}
                        isActiveCoupon={isActiveCoupon}
                        isEnabledCoupon={_isEnabledCoupon}
                        bundle={bundle}
                        isRandomBundle={_isRandomBundle}
                        isPeriodicBundle={_isPeriodicBundle}
                        activeCoupon={activeCoupon}
                        activeCouponId={state.activeCouponId}
                        coupons={state.coupons}
                        isFreeBundle={_isFreeBundle}
                        changeUserAdultFlag={changeUserAdultFlag}
                        isAdult={state.isAdult}
                        isNeedToShownAdultCheckbox={_isNeedToShownAdultCheckbox}
                        imageStyles={image}
                        isEnabledPurchaseLimitToContainer={_isEnabledPurchaseLimitToContainer}
                        category={category}
                        limit={isNeedToShowLimit ? limit : null}
                        availableAmountForFullPurchase={availableAmountForFullPurchase}
                        hasProgressiveDiscount={randomBundleData.hasProgressiveDiscount && !!randomBundleData.maxAvailableProgressiveDiscount?.discount}
                        progressiveDiscount={randomBundleData.currentProgressiveDiscount}
                        isAvailableFullPurchase={randomBundleData.isAvailableFullPurchaseRandomBundle}
                        onFullPurchaseHandler={onChangeQuantityForFullPurchaseRandomBundle}
                        hasRestrictionPurchaseForRandomBundle={randomBundleData.hasRestrictionPurchaseForRandomBundle}
                        canPurchaseWholeBundle={randomBundleData.canPurchaseWholeBundle}
                        canIncrementRandomBundleAmount={randomBundleData.canIncrementQuantity}
                        bundlesInTransaction={state.bundlesInTransaction}
                        beneficialOffer={randomBundleData.beneficialOffer}
                        balance={state.balance}
                        hasRestriction={hasRestriction}
                        maxAmountForOnePurchaseRandomBundle={randomBundleData.maxAmountForOnePurchaseRandomBundle}
                        isActiveTransaction={isActiveTransaction}
                        sequence={serualSequence}
                        reasonCantBoughtCode={reasonCantBoughtCode}
                    />
                </PopupBody>
                <PopupFooter className={styles.purchasePopupFooter}>
                    {isNeedToShowExchangeMessage && (
                        <div className={styles.exchangeWrapper}>
                            <span>{t('У вас недостаточно угля для покупки данного товара. Разница будет компенсирована за счет стали.')}</span>
                            <span className={styles.exchangeWrapperContent}>
                                <span>{t('По курсу:')}</span>
                                <span className={styles.exchangeWrapperText}>
                                    <Currency currency={defaultExchangeCurrency} amount={1} withoutAnimation={true} className={styles.exchangeCurrency} />
                                    <span>=</span>
                                    <Currency currency={'coal'} amount={exchangeToCurrency} withoutAnimation={true} className={styles.exchangeCurrency} />
                                </span>
                            </span>
                        </div>
                    )}
                    {hasRestriction && (
                        <div className={styles.restrictionText}>
                            <AttentionLimitWidget reason={reasonCantBoughtCode} bundle={bundle} className={styles.attentionLimitWrapper} />
                        </div>
                    )}
                    <div className={styles.purchasePopupButtonsWrapper}>
                        <div className={styles.purchaseButton}>
                            {reasonCantBoughtCode >= 0 && (
                                <PurchaseButton
                                    bundle={_isRandomBundle ? randomBundleData.parentRandomBundle : bundle}
                                    parentRandomBundle={randomBundleData.parentRandomBundle}
                                    label={button.label}
                                    coupon={activeCoupon}
                                    isActiveCoupon={isActiveCoupon}
                                    isDisabled={button.isDisabled || button.isDisabledByNotAdult}
                                    isFetching={button.isFetching}
                                    onClick={_purchase}
                                    onClickFromDisableButton={onClickFromDisableButton}
                                    classNameFastGoldLink={styles.fastGoldLink}
                                    classNameChinaFastGoldLink={styles.fastGoldLink}
                                    place={FAST_GOLD_PLACE.BUNDLE_PURCHASE_POPUP}
                                    buttonClassName={'armory__auto--button_purchase'}
                                    purchaseButtonTheme={isSantaCategory && PurchaseButtonTheme.cookie}
                                />
                            )}
                            {reasonCantBoughtCode >= 0 && isAvailableWholePurchase && (
                                <div className={styles.serialWholePurchaseLink}>
                                    <Link label={t('Купить все')} onClick={openWholeSerialPurchasePopup} color={LinkColors.white} />
                                </div>
                            )}
                        </div>
                        {isMobile && (
                            <div className={styles.closeButton}>
                                <Button label={t('Закрыть')} onClick={closePopup} />
                            </div>
                        )}
                    </div>
                    {hasCustomDisclaimers && <div className={styles.disclaimer}>{customDisclaimers.join(' ')}</div>}
                    <div className={styles.copyrightMobile}>
                        <CopyrightContent
                            isNeedToShowCopyrightFromContainer={_isNeedToShowCopyrightFromContainer}
                            isNeedToShowCopyrightFromRandom={_isNeedToShowCopyrightFromRandom}
                            isNeedToHideDisclaimerAppearance={hasCustomDisclaimers}
                        />
                    </div>
                </PopupFooter>
            </Popup>
        </PurchaseCustomizationWrapper>
    );
};

export default ConfirmPurchase;
