import * as React from 'react';
import { useDispatch } from 'react-redux';
import { DependencyList } from 'react';
import { getCategoryBackground, getGlobalBackground } from '~/utils/background';
import { appActions } from '~/Store/appSlice';

const useBackground = (backgroundUrl: string, deps: DependencyList = []) => {
    const dispatch = useDispatch();
    const globalBackground = getGlobalBackground();
    const back = backgroundUrl || globalBackground;

    React.useEffect(() => {
        dispatch(
            appActions.changeViewBackground({
                className: null,
                background: back || getCategoryBackground(deps[0] as string),
            }),
        );

        return () => {
            backgroundUrl && dispatch(appActions.changeViewBackground({ className: null, background: null }));
        };
    }, deps);
};

export default useBackground;
