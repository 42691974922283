import * as React from 'react';
import styles from './SantaPage.scss';
import { IPromoPage } from '~/Layouts/Themes/GroupingPage/GroupingPage';
import { groupBundlesByName } from '~/utils/bundles';
import SantaBundle from '~/Layouts/Themes/SantaPage/SantaBundle/SantaBundle';
import SantaShowcaseSwitcher from '~/components/SantaShowcaseSwitcher/SantaShowcaseSwitcher';
import equal from 'fast-deep-equal/react';
import { isChina } from '~/utils/settings';
import { isGoldShowcase } from './settings';
import Account from '~/account/Account';
import Attention from '~/components/Attention/Attention';
import { t } from '~/utils/localization';
import { redirectToLogin } from '~/utils/utils';
import { useAppSelector } from '~/Store';

const SantaPage = (props: IPromoPage) => {
    const currentPage = useAppSelector((state) => state.app.currentPage, equal);
    const groups = groupBundlesByName(props.bundles);
    const refs = React.useRef<Map<string, HTMLDivElement>>(new Map<string, HTMLDivElement>());
    const allNames = Object.keys(groups);
    const isCn = isChina();
    const _isGoldShowcase = isGoldShowcase(currentPage?.name);
    const isHiddenProductsInfo = isCn && !_isGoldShowcase && !Account.getAccount()?.id;

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <SantaShowcaseSwitcher className={styles.switcher} />
                {allNames.map((group: string, index) => {
                    return (
                        <SantaBundle
                            groupName={group}
                            groups={groups}
                            key={`${currentPage?.name}_${group}`}
                            isLastGroup={index === allNames.length - 1}
                            ref={(ref: HTMLDivElement) => {
                                if (ref) {
                                    refs.current.set(group, ref);
                                }
                            }}
                        />
                    );
                })}
                {isHiddenProductsInfo && (
                    <div className={styles.loginRequired}>
                        <Attention label={t('Авторизуйтесь, чтобы увидеть подробную информацию о продуктах.')} buttonText={t('Авторизоваться')} onButtonClick={redirectToLogin} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SantaPage;
