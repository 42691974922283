import { items } from '@wg/wows-entities/const';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Button from '~/components/Button/Button';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { getSectionName } from '~/core/ManagerData';
import useLoadPrimaryItemData from '~/hooks/useLoadPrimaryItemData';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import getCrewsContent, { CrewsSubTitleContent } from '~/Layouts/BundlePage/Contents/CrewsContent';
import ShipsContent from '~/Layouts/BundlePage/Contents/ShipsContent';
import LootboxAutoDescriptionWrapper from '~/Layouts/Lootboxes/LootboxAutoDescriptionWrapper';
import { t } from '~/utils/localization';
import { Crew, LootBox, Vehicle } from '~/vortex';
import styles from './ItemDescriptionPopup.scss';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import classNames from 'classnames';
import { InventoryCounterSize } from '~/components/InventoryCounter/InventoryCounter';
import InventoryCounterContainer from '~/containers/InventoryCounterContainer/InventoryCounterContainer';
import PityTimerContainer from '~/containers/PityTimerContainer/PityTimerContainer';
import { appActions } from '~/Store/appSlice';
import { POPUPS_NAME } from '~/components/PopupManager';

interface IItemDescriptionPopupData {
    item: IItemCommonData;
    autoDescriptionSettings: IAutoDescriptionSettings;
}

interface IItemDescriptionPopup {
    data: IItemDescriptionPopupData;
}

function getContentByType(data: IItemDescriptionPopupData) {
    switch (data.item.type) {
        case items.LOOTBOX:
            return <LootboxAutoDescriptionWrapper id={data.item.identifier} autoDescriptionSettings={data.autoDescriptionSettings} />;
        case items.VEHICLES:
            return <ShipsContent item={data.item} renderFeatures />;
        case items.CREWS:
            return getCrewsContent({ item: data.item });
    }
}

function getSubContentByType(item: IItemCommonData, vrtxItem: Crew | Vehicle | LootBox, data: IItemDescriptionPopupData) {
    switch (item.type) {
        case items.CREWS:
            return <CrewsSubTitleContent item={item} />;
        case items.LOOTBOX:
            return (
                <div className={styles.subTitle}>
                    <div className={styles.subTitleItem}>{(vrtxItem as LootBox).isPremium ? t('Премиум контейнер') : t('Контейнер')}</div>
                    <PityTimerContainer
                        identifier={item.identifier}
                        autoDescriptionSettings={data.autoDescriptionSettings}
                        title={(vrtxItem as LootBox).shortTitle}
                        className={styles.subTitleItem}
                        fallback={<InventoryCounterContainer item={item} className={classNames(styles.subTitleItem, styles.inventoryWidgetContainer)} size={InventoryCounterSize.FULL} />}
                    />
                </div>
            );
    }
}

const ItemDescriptionPopup = ({ data }: IItemDescriptionPopup) => {
    const dispatch = useDispatch();
    const contentRef = React.useRef<HTMLDivElement>();
    const item = useLoadPrimaryItemData(data.item?.type, data.item?.identifier, getSectionName(data.item.type));
    const [isVisiblePopup, toggleVisibilityPopup] = React.useState(!!item);

    const getTitle = () => {
        if (data.item.type === items.CREWS) {
            return (item as Crew).title;
        } else if (data.item.type === items.VEHICLES) {
            return <WoWSEntity type={data.item.type} id={data.item.identifier} presentation={{ renderWithoutFade: true }} />;
        } else if (data.item.type === items.LOOTBOX) {
            return (item as LootBox).shortTitle;
        }
    };

    const close = () => {
        dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.ITEM_DESCRIPTION_POPUP }));
    };

    React.useEffect(() => {
        if (item) {
            toggleVisibilityPopup(true);
            dispatch(appActions.setFetching(false));
        } else {
            dispatch(appActions.setFetching(true));
        }
    }, [item]);

    useMaskScrollEffect(contentRef);

    if (!isVisiblePopup) {
        return <></>;
    }

    return (
        <Popup onClose={close} outsideClick={true} renderEscButton={true} className={styles.popup}>
            <PopupHeader title={t('Дополнительная информация')} />
            <PopupBody className={styles.bodyWrapper}>
                <div
                    className={styles.body}
                    ref={(_ref) => {
                        if (_ref) {
                            contentRef.current = _ref;
                        }
                    }}
                >
                    <div className={styles.header}>
                        <div className={styles.title}>{getTitle()}</div>
                        {item && getSubContentByType(data.item, item, data)}
                    </div>
                    {(item as Vehicle)?.description && <div className={styles.description}>{(item as Vehicle).description}</div>}
                    {getContentByType(data)}
                </div>
            </PopupBody>
            <PopupFooter>
                <Button label={t('Закрыть')} onClick={close} />
            </PopupFooter>
        </Popup>
    );
};

export default ItemDescriptionPopup;
