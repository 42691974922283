import store from '~/Store';
import { CATEGORIES } from '~/const';
import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import { isDockyardEnabledForAccount } from '~/api/dockyard';
import { appActions } from '~/Store/appSlice';

export default class DockyardPreloader extends AbstractPreloader {
    async load(): Promise<any> {
        isDockyardEnabledForAccount().then((isEnabled) => {
            if (isEnabled) {
                store.dispatch(appActions.showMenuItem(CATEGORIES.DOCKYARD));
            }
        });
        return Promise.resolve(1);
    }
}
