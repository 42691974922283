import { t } from '~/utils/localization';
import { sort } from 'fast-sort';
import { IDropdownItem } from '~/components/Dropdown/Dropdown';
import store from '~/Store';
import { getSearchParam, updateSearchParam } from '~/utils/utils';
import { DWH_EVENTS, QUERY_SORT_METHOD, QUERY_SORT_NAME } from '~/const';
import dwhExport from '~/api/dwhExport';
import { ISortMethods, SortNames } from '~/types/category';
import { appActions } from '~/Store/appSlice';

export const SORT_TITLES = {
    default: t('по умолчанию'),
    name: t('по названию'),
    price: t('по стоимости'),
};

export const AVAILABLE_SORT_METHODS = [ISortMethods.asc, ISortMethods.desc];

export function getSortItemsFromDropdown(sortSettings: SortNames[]): IDropdownItem[] {
    if (!sortSettings?.length) {
        return;
    }

    return Object.keys(SORT_TITLES).reduce((array: IDropdownItem[], key: keyof typeof SORT_TITLES) => {
        if (!sortSettings.includes(key as SortNames) && key !== 'default') {
            return array;
        }
        array.push({ name: key, content: SORT_TITLES[key] });
        return array;
    }, []);
}

export const reverseMethods = [ISortMethods.desc];

type SortMap_Types = {
    [name in SortNames]?: {
        default: ISortMethods;
        sort: (items: SortItemType[]) => SortItemType[];
        reverseSort: (items: SortItemType[]) => SortItemType[];
        deps: {
            [key in ISortMethods]?: ISortMethods;
        };
    };
};

export type SortItemsFields = 'price' | 'currency' | 'id' | 'identifier' | 'title' | 'normalizedTitle';
export type SortItemType = Partial<Record<SortItemsFields, string | number>>;

export const SORT_MAP: SortMap_Types = {
    name: {
        default: ISortMethods.asc,
        sort: (items: SortItemType[]) => sort(items).asc((item) => item.normalizedTitle || item.title),
        reverseSort: (items: SortItemType[]) => sort(items).desc((item) => item.normalizedTitle || item.title),
        deps: {
            [ISortMethods.asc]: ISortMethods.desc,
            [ISortMethods.desc]: ISortMethods.asc,
        },
    },
    price: {
        default: ISortMethods.asc,
        sort: (items: SortItemType[]) => sort(items).asc([(item) => item.price as number, (item) => item.currency]),
        reverseSort: (items: SortItemType[]) => sort(items).desc([(item) => item.price as number, (item) => item.currency]),
        deps: {
            [ISortMethods.asc]: ISortMethods.desc,
            [ISortMethods.desc]: ISortMethods.asc,
        },
    },
};

export const updateSortMethod = (name: SortNames, currentName: SortNames, currentMethod: ISortMethods) => {
    if (currentName === name) {
        return SORT_MAP[name].deps[currentMethod];
    } else {
        return SORT_MAP[name].default;
    }
};

export const isReverseMethod = (method: ISortMethods): boolean => method.includes('desc');

export const sortBundles = (name: SortNames, method: ISortMethods, allBundles: IBundleList, bundleIds: number[]): number[] => {
    const bundles = bundleIds.map((bundleId) => allBundles[bundleId]);
    return sortItems<IBundle[]>(name, method, bundles).map((bundle) => bundle.id as number);
};

export const sortItems = <T>(name: SortNames, method: ISortMethods, items: SortItemType[]): T => {
    const callback = isReverseMethod(method) ? SORT_MAP[name].reverseSort : SORT_MAP[name].sort;
    return callback(items) as unknown as T;
};

export const isAvailableSortCategory = (categoryName: ICategoryList) => {
    const { categories } = store.getState().app;
    const categoryConfig = categories[categoryName];

    return !(!categoryConfig || !categoryConfig.sortSettings?.length || !!categoryConfig.subCategories?.length || categoryConfig.denyReorder);
};

export const applySortByQueryParams = () => {
    const { currentPage } = store.getState().app;
    const [sortName, sortMethod] = [getSearchParam(QUERY_SORT_NAME) as SortNames, getSearchParam(QUERY_SORT_METHOD) as ISortMethods];

    if (!AVAILABLE_SORT_METHODS.includes(sortMethod)) {
        return;
    }

    if (!SORT_TITLES[sortName]) {
        return;
    }

    if (currentPage.isBundlePage) {
        return;
    }

    store.dispatch(appActions.setSortMethod({ category: currentPage.name, name: sortName, method: sortMethod }));

    dwhExport.send(DWH_EVENTS.BUNDLES_SORT, { sort_name: sortName, sort_method: sortMethod, category: currentPage.name });
};

export const resetSortQueryParams = () => {
    updateSearchParam({ [QUERY_SORT_NAME]: null, [QUERY_SORT_METHOD]: null });
};
