import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './PriceWrapper.scss';
import classNames from 'classnames';
import { isCouponEnabledForCurrency } from '~/utils/coupons';
import { calculateBundlePrices } from '~/utils/purchase';
import { getBundleCurrenciesMap, getBundleDiscount, getPriceFromAllBundles, isFreeBundle, isSteelExchangeBundle } from '~/utils/bundles';
import Discount from '~/components/Discount/Discount';
import { assets } from '@wg/wows-entities/const';
import { getCurrency } from '~/utils/currencies';
import ProgressiveDiscountTooltip from '~/components/ProgressiveDiscount/ProgressiveDiscountTooltip';
import Price from '~/components/Price/Price';
import Skeleton from '~/components/Skeleton/Skeleton';
import { BundlePurchaseTypes } from '~/types/bundle';
import { RootState, useAppSelector } from '~/Store';

export enum PRICE_WRAPPER_SIZE {
    BIG = 'big',
}

export enum PRICE_WRAPPER_PLACE {
    LAYOUT = 'layout',
    POPUP = 'popup',
}

interface IPriceWrapper {
    bundle?: IBundle;
    coupon?: ICoupon;
    size?: PRICE_WRAPPER_SIZE;
    isActiveCoupon?: boolean;
    hiddenPriceWithoutDiscount?: boolean;
    hiddenDiscount?: boolean;
    useInitialPrice?: boolean;
    showExchangePrices?: boolean;
    showOldPriceForMobileDevices?: boolean;
    className?: string;
    discountClassName?: string;
    priceClassName?: string;
    calculateAllPrice?: boolean;
    useCouponPrice?: boolean;
    place?: PRICE_WRAPPER_PLACE;
    withoutAnimation?: boolean;
    oldPriceClassName?: string;
    newPriceClassName?: string;
    wrapperClassName?: string;
    withoutTimer?: boolean;
    progressiveDiscount?: DiscountDiapason;
    withFadeDiscount?: boolean;
    availableAmountForFullPurchase?: number;
    useColumnLayoutForCoupon?: boolean;
}

const stateSelector = (state: RootState) => {
    return {
        id: state.account?.id,
        balance: state.account?.balance,
        activeCouponId: state.account?.activeCouponId,
        purchasedLimitedBundles: state.account?.purchasedLimitedBundles,
        bundles: state.app.bundles,
    };
};

interface ISteelExchangePrices {
    calculatePrice: ReturnType<typeof calculateBundlePrices>;
    className: string;
    priceClassName?: string;
    plusClassName?: string;
    isBig?: boolean;
}

export const SteelExchangePrices = ({ calculatePrice, className, isBig, priceClassName, plusClassName }: ISteelExchangePrices) => {
    const otherCurrency = Object.keys(calculatePrice.final).filter((currency) => currency !== assets.COAL && currency !== assets.STEEL)[0];

    return (
        <div className={styles.steelExchangeWrapper}>
            {!!otherCurrency && (
                <>
                    <Price
                        isBigSize={isBig}
                        className={classNames(className, styles.leftPrice, priceClassName, styles.withPlus)}
                        currency={otherCurrency}
                        amount={calculatePrice.final[otherCurrency]}
                        withoutAnimation={true}
                        showDescriptionInPriceTooltip={getCurrency(otherCurrency)?.showDescriptionInPriceTooltip}
                    />
                    <span className={classNames(styles.plus, plusClassName)}>+</span>
                </>
            )}
            {calculatePrice.final.coal > 0 && (
                <>
                    <Price
                        isBigSize={isBig}
                        className={classNames(className, styles.leftPrice, priceClassName, styles.withPlus)}
                        currency={assets.COAL}
                        amount={calculatePrice.final.coal}
                        withoutAnimation={true}
                        showDescriptionInPriceTooltip={getCurrency(calculatePrice.final.COAL)?.showDescriptionInPriceTooltip}
                    />
                    <span className={classNames(styles.plus, plusClassName)}>+</span>
                </>
            )}
            <Price isBigSize={isBig} currency={assets.STEEL} withoutAnimation className={classNames(className, priceClassName)} amount={calculatePrice.final.steel} />
        </div>
    );
};

interface IRealPrices {
    className?: string;
    newPriceClassName?: string;
    oldPriceClassName?: string;
    isBig?: boolean;
    formattedPrice: string;
    price: number;
    oldPrice: string;
    currency: string;
}

const RealPrices = ({ className, isBig, newPriceClassName, formattedPrice, oldPrice, oldPriceClassName, currency, price }: IRealPrices) => {
    return (
        <>
            <Price isBigSize={isBig} className={classNames(className, newPriceClassName)} isRealPurchase={true} formattedPrice={formattedPrice} currency={currency} isFree={price === 0} />
            {oldPrice && <Price isOld className={classNames(className, oldPriceClassName)} formattedPrice={oldPrice} currency={currency} />}
        </>
    );
};

const PriceWrapper = function (props: IPriceWrapper) {
    const state = useAppSelector(stateSelector, equal);

    if (props.bundle.isLoading) {
        return (
            <div className={styles.preloader}>
                <Skeleton />
            </div>
        );
    }

    const parentBundle = state.bundles[props.bundle?.parentBundleId];
    const isActiveCoupon = props.isActiveCoupon || state.activeCouponId ? state.activeCouponId === props.coupon?.id : false;
    const price = props.bundle.price as number;
    const prices = getBundleCurrenciesMap(props.bundle);

    let _calculatePrice: any;
    let initialPrice: any;
    let withDiscount: any;

    if (state.id) {
        _calculatePrice = calculateBundlePrices(props.bundle, state.balance, props.useCouponPrice ? 1 : props.bundle.quantityData?.quantity || 1, props.coupon?.discount, props.progressiveDiscount);
        initialPrice = _calculatePrice.initial;
        withDiscount = _calculatePrice.withDiscount;
    }

    if (props.showExchangePrices && _calculatePrice?.final) {
        Object.keys(_calculatePrice.final).forEach((currency) => {
            prices[currency] = _calculatePrice.final[currency];
        });
    }

    const isNeededToShownTimer = !props.withoutTimer && props.bundle.showCountdown && props.bundle.promoTimerActiveTill;
    const _isFreeBundle = isFreeBundle(props.bundle);
    const currencies = Object.keys(prices).map((currency) => currency);
    const isSteelExchange = isSteelExchangeBundle(currencies);
    const bundleDiscount = props.progressiveDiscount?.discount || getBundleDiscount(props.bundle);
    const isRealPurchase = props.bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY;
    const isEnabledDiscount = (!!bundleDiscount && props.bundle.showDiscountLabel) || (!!state.activeCouponId && props.useColumnLayoutForCoupon);
    const isBig = props.size === PRICE_WRAPPER_SIZE.BIG;

    const wrapperClassNames = classNames(
        styles.priceWrapper,
        {
            [styles.big]: isBig,
            [styles.columnWrapper]: isEnabledDiscount,
            [styles.progressiveDiscountPrice]: !!props.bundle.progressivePurchase?.discountDiapasons,
        },
        props.wrapperClassName,
    );

    const isColumn = isEnabledDiscount && !isSteelExchange;

    const priceClassNames = classNames(
        styles.price,
        {
            [styles.column]: isColumn,
        },
        props.priceClassName,
    );

    const tooltipBody = () => {
        if (!parentBundle?.progressivePurchase?.discountDiapasons?.length) {
            return null;
        }

        return (
            <ProgressiveDiscountTooltip
                amount={props.availableAmountForFullPurchase}
                hideFirstParagraph={true}
                parentBundleTitle={parentBundle?.title}
                childBundleTitle={props.bundle?.title}
                data={parentBundle?.progressivePurchase?.discountDiapasons}
                singleLimit={props.bundle.singlePurchaseMaxQuantity}
            />
        );
    };

    if (_isFreeBundle) {
        return <Price isFree className={props.newPriceClassName} />;
    }

    const isEnabledCoupon = isCouponEnabledForCurrency(props.coupon, props.bundle.currency);
    const quantity = props.bundle.quantityData?.quantity || 1;

    return (
        <React.Fragment>
            <div className={wrapperClassNames}>
                {!!bundleDiscount && !props.hiddenDiscount && props.bundle.showDiscountLabel && !isSteelExchange && (
                    <Discount
                        withTimer={!!isNeededToShownTimer}
                        discount={bundleDiscount}
                        activeTill={props.bundle.promoTimerActiveTill}
                        className={props.discountClassName}
                        withFade={props.withFadeDiscount}
                        tooltipBody={tooltipBody()}
                    />
                )}
                {isSteelExchange ? (
                    <div className={priceClassNames}>
                        <SteelExchangePrices calculatePrice={_calculatePrice} priceClassName={props.newPriceClassName} className={props.className} isBig={isBig} />
                    </div>
                ) : isRealPurchase ? (
                    <div className={priceClassNames}>
                        <RealPrices
                            newPriceClassName={props.newPriceClassName}
                            oldPriceClassName={props.oldPriceClassName}
                            className={props.className}
                            isBig={isBig}
                            currency={props.bundle.originalProduct?.currency}
                            formattedPrice={props.bundle.originalProduct.formattedPrice}
                            price={props.bundle.originalProduct.price}
                            oldPrice={props.bundle.originalProduct.formattedOriginalPrice}
                        />
                    </div>
                ) : (
                    Object.keys(prices).map((currency, index) => {
                        let priceWithoutDiscount: number | null = null;
                        let amount: number = prices[currency];

                        if (withDiscount && !props.showExchangePrices) {
                            amount = withDiscount[currency];
                        }

                        if (isEnabledCoupon && isActiveCoupon) {
                            priceWithoutDiscount = initialPrice ? initialPrice[currency] : amount;
                        }

                        if (props.bundle.originalPrice) {
                            priceWithoutDiscount = (props.bundle.originalPrice as number) * quantity;
                        }

                        if (props.progressiveDiscount && !!props.progressiveDiscount.discount) {
                            priceWithoutDiscount = price * quantity;
                        }

                        if (props.calculateAllPrice) {
                            amount = getPriceFromAllBundles(props.bundle, state.purchasedLimitedBundles)[currency];
                        }

                        const isNeedPlus = index + 1 !== Object.keys(prices).length;
                        const finalPrice = Number.isFinite(amount) ? amount : prices[currency];

                        return (
                            <React.Fragment key={`price_${currency}`}>
                                <div className={priceClassNames}>
                                    <Price
                                        isBigSize={isBig}
                                        className={classNames(props.className, props.newPriceClassName, {
                                            [styles.withPlus]: isNeedPlus,
                                        })}
                                        currency={currency}
                                        amount={finalPrice}
                                        withoutAnimation={props.withoutAnimation}
                                        isRealPurchase={isRealPurchase}
                                        formattedPrice={props.bundle.originalProduct?.formattedPrice}
                                        showDescriptionInPriceTooltip={getCurrency(currency)?.showDescriptionInPriceTooltip}
                                    />
                                    {isNeedPlus && !isColumn && <span className={classNames(styles.plus)}>+</span>}
                                    {priceWithoutDiscount && !props.hiddenPriceWithoutDiscount && index === 0 && (
                                        <Price
                                            isOld
                                            withoutIcon
                                            withoutTooltip
                                            currency={currency}
                                            className={classNames(props.className, props.oldPriceClassName)}
                                            amount={priceWithoutDiscount}
                                            formattedPrice={props.bundle.originalProduct?.formattedOriginalPrice || props.bundle.originalProduct?.originalPrice?.toString()}
                                            withoutAnimation={props.withoutAnimation}
                                        />
                                    )}
                                </div>
                                {isNeedPlus && isColumn && (
                                    <span className={classNames(styles.plus, styles.plusDelimiter)} key={`plus_delimiter_${index}`}>
                                        +
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    })
                )}
            </div>
        </React.Fragment>
    );
};

export default PriceWrapper;
