import * as React from 'react';
import styles from './Carousel.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Mousewheel, FreeMode } from 'swiper/modules';
import type { Swiper as SwiperInstance, SwiperModule } from 'swiper/types';
import useIntersectionObserver from '~/hooks/useIntersectionObserver';
import { BundlesObserver } from '~/components/BundlesObserver/BundlesObserver';
import classNames from 'classnames';
import debounce from '~/utils/debounce';

interface ICarousel {
    scrollToId?: number;
    items: any[];
    centeredSlides?: boolean;
    centeredSlidesBounds?: boolean;
    freeMode?: boolean;
    setSwiperRef?: (swiper: SwiperInstance) => void;
    hideControls?: boolean;
    withoutLazy?: boolean;
    mousewheel?: boolean;
    onSliderScrollProgress?: (state: number, sliderWrapper: HTMLElement) => void;
    slideChange?: () => void;
    className?: {
        item?: string;
        carouselItem?: string;
        navigationButtonPrev?: string;
        navigationButtonNext?: string;
    };
    slidesPerView?: number;
    virtualSlides?: boolean;
    breakpoints?: {
        [viewport: number]: {
            slidesPerView: number;
        };
    };
    dynamicBreakpoints?: () => {
        [viewport: number]: {
            slidesPerView: number;
        };
    };
    onNavigationNext?: (swiper: SwiperInstance) => void;
    onNavigationPrev?: (swiper: SwiperInstance) => void;
}

interface ICarouselItem {
    index: number;
    item: any;
    className?: string;
    withoutLazy?: boolean;
}

const CarouselItem = ({ className, item, withoutLazy }: ICarouselItem) => {
    const ref = React.useRef(null);
    const [isVisible, hash] = useIntersectionObserver(ref, withoutLazy);

    return (
        <div
            className={className}
            data-lazy-id={hash}
            ref={(_ref) => {
                if (_ref) {
                    ref.current = _ref;
                }
            }}
        >
            {isVisible && item}
        </div>
    );
};

const Carousel = ({
    scrollToId,
    items,
    className,
    setSwiperRef,
    centeredSlides,
    centeredSlidesBounds,
    freeMode,
    hideControls,
    withoutLazy,
    mousewheel,
    slideChange,
    onSliderScrollProgress,
    dynamicBreakpoints,
    virtualSlides,
    slidesPerView,
    onNavigationNext,
    onNavigationPrev,
}: ICarousel) => {
    const sliderWrapper: React.RefObject<HTMLDivElement> = React.useRef(null);
    const [swiper, setSwiper] = React.useState<SwiperInstance>(null);

    const swiperModules: SwiperModule[] = [];
    freeMode && swiperModules.push(FreeMode);
    mousewheel && swiperModules.push(Mousewheel);
    virtualSlides && swiperModules.push(Virtual);

    const onSliderScrollProgressHandler = (sliderState: number) => {
        if (!sliderWrapper.current) {
            return;
        }
        if (onSliderScrollProgress) {
            onSliderScrollProgress(sliderState, sliderWrapper.current);
            return;
        }
        if (sliderState > 0 && sliderState < 1) {
            sliderWrapper.current.style.webkitMaskImage =
                '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgba(0, 0, 0, 0)), color-stop(0.05, rgb(0, 0, 0)), color-stop(0.95, rgb(0, 0, 0)), color-stop(0.99, rgba(0, 0, 0, 0.145)))';
        } else if (sliderState === 0) {
            sliderWrapper.current.style.webkitMaskImage =
                '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgb(0, 0, 0)), color-stop(0.05, rgb(0, 0, 0)), color-stop(0.95, rgb(0, 0, 0)), color-stop(0.99, rgba(0, 0, 0, 0)))';
        } else if (sliderState === 1) {
            sliderWrapper.current.style.webkitMaskImage =
                '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgba(0, 0, 0, 0)), color-stop(0.05, rgb(0, 0, 0)), color-stop(0.95, rgb(0, 0, 0)), color-stop(0.99, rgb(0, 0, 0)))';
        }
    };

    React.useEffect(() => {
        swiper?.slideTo(scrollToId);
    }, [scrollToId]);

    React.useEffect(() => {
        setTimeout(() => {
            BundlesObserver.clear().init();
            swiper?.updateAutoHeight(0);
        }, 0);
    }, []);

    function onClickPrev() {
        onNavigationPrev(swiper);

        swiper?.slidePrev();
    }

    function onClickNext() {
        onNavigationNext(swiper);

        if (swiper?.snapIndex === 0) {
            swiper.slideTo(1);
        } else {
            swiper.slideNext();
        }
    }

    const specialProps: Partial<React.ComponentProps<typeof Swiper>> = {};
    // Virtual slides special props
    if (virtualSlides) {
        specialProps.autoHeight = true;
        specialProps.virtual = {
            enabled: true,
            cache: true,
            addSlidesBefore: 10,
            addSlidesAfter: 10,
        };
        specialProps.onAfterInit = (_swiper: SwiperInstance) => {
            setTimeout(() => {
                if (_swiper && !_swiper.destroyed) {
                    _swiper?.updateAutoHeight(0);
                    _swiper?.update();
                    _swiper.el.style.setProperty('min-height', `${_swiper.height}px`);
                }
            }, 0);
        };
        specialProps.onSlideChangeTransitionStart = (_swiper: SwiperInstance) => {
            _swiper.el.style.setProperty('min-height', `${_swiper.height}px`);
        };
        specialProps.onSlideChangeTransitionEnd = (_swiper: SwiperInstance) => {
            if (_swiper && !_swiper.destroyed) {
                _swiper?.updateAutoHeight(0);
                _swiper?.update();
            }
            _swiper.el.style.removeProperty('min-height');
        };
    }

    if (dynamicBreakpoints) {
        specialProps.breakpoints = dynamicBreakpoints();
    }

    React.useEffect(() => {
        function setBreakpoints() {
            if (!virtualSlides || !swiper) {
                return;
            }
            swiper.params.breakpoints = dynamicBreakpoints();
            swiper.currentBreakpoint = false;
            swiper.update();
        }
        const setBreakpointsDebounced = debounce(setBreakpoints, 200);
        window.addEventListener('resize', setBreakpointsDebounced, { passive: true });
        return () => window.removeEventListener('resize', setBreakpointsDebounced);
    }, [swiper, dynamicBreakpoints, virtualSlides]);

    return (
        <div className={styles.wrapper} ref={sliderWrapper}>
            <Swiper
                updateOnWindowResize={true}
                modules={swiperModules}
                slidesPerView={slidesPerView || 'auto'}
                freeMode={
                    freeMode
                        ? {
                              sticky: true,
                              minimumVelocity: 0.2,
                              momentumRatio: 0.75,
                              momentumVelocityRatio: 0.02,
                          }
                        : false
                }
                mousewheel={mousewheel}
                initialSlide={scrollToId || 0}
                centeredSlides={centeredSlides}
                centeredSlidesBounds={centeredSlidesBounds}
                uniqueNavElements={true}
                onSwiper={(_swiper: SwiperInstance) => {
                    setSwiperRef?.(_swiper);
                    if (!swiper || swiper?.destroyed) {
                        setSwiper(_swiper);
                    }
                }}
                onProgress={(_swiper: SwiperInstance, progress: number) => onSliderScrollProgressHandler(progress)}
                onSnapIndexChange={slideChange}
                {...specialProps}
            >
                {items.map((Component, index) => (
                    <SwiperSlide key={`Carousel_${index}`} virtualIndex={virtualSlides ? index : undefined} className={className?.item}>
                        <CarouselItem item={Component} index={index} withoutLazy={virtualSlides || withoutLazy} className={className?.carouselItem} />
                    </SwiperSlide>
                ))}
                {!hideControls && (
                    <>
                        <div
                            className={classNames(styles.prev, className?.navigationButtonPrev, {
                                [styles.disabled]: swiper?.isBeginning || (swiper && !swiper.destroyed && swiper.snapIndex === 0),
                            })}
                            onClick={onClickPrev}
                        />
                        <div
                            className={classNames(styles.next, className?.navigationButtonNext, {
                                [styles.disabled]: swiper?.isEnd || (swiper && !swiper.destroyed && swiper.snapIndex === swiper.slides.length - 1),
                            })}
                            onClick={onClickNext}
                        />
                    </>
                )}
            </Swiper>
        </div>
    );
};

export default Carousel;
