import { memo, lazy, Suspense } from 'react';
import ProgressionRewardsLoader from './Components/ProgressionRewardsLoader/ProgressionRewardsLoader';

const LazyContainer = lazy(
    () =>
        import(
            /* webpackChunkName: "progressiveRewards" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: true */
            '~/Layouts/ProgressionRewards/Container/ProgressionRewardsContainer'
        ),
);

interface ILazyProgressionRewardsContainer {
    category: ICategory;
}

const LazyProgressionRewardsContainer = ({ category }: ILazyProgressionRewardsContainer) => {
    const progressiveRewardsSettings = category.parentCategoryData?.progressionRewardSettings || category.progressionRewardSettings;
    if (!progressiveRewardsSettings || !progressiveRewardsSettings.bundles?.length) {
        return null;
    }

    return (
        <Suspense fallback={<ProgressionRewardsLoader />}>
            <LazyContainer progressiveRewardsSettings={progressiveRewardsSettings} />
        </Suspense>
    );
};

export default memo(LazyProgressionRewardsContainer);
