import { useState, useEffect, useRef } from 'react';
import { DivTooltip } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import { interpolate, t } from '~/utils/localization';
import DefaultTooltip from '../Tooltip/DefaultTooltip';
import styles from './ProgressBar.scss';
import { CSSProperties } from 'react';

export enum ProgressDisplay {
    DEFAULT,
    START,
}

export interface IAnimatedValues {
    previewValue: number;
    currentValue: number;
}

interface IProgressBar {
    maxValue: number;
    step?: number;
    minValue?: number;
    valuableIndexes?: number[];
    currentValue: number;
    separateLimit?: number;
    selectedIndex?: number;
    itemTooltips?: React.ReactNode[];
    defaultProgressTooltipText?: string;
    onClickHandler?: (index: number) => void;
    progressDisplay?: ProgressDisplay;
    hiddenPoint?: boolean;
    animatedValues?: IAnimatedValues;
    className?: {
        seperateItem?: string;
        defaultProgressBar?: string;
    };
}

function getWidth(value: number, max: number) {
    return Math.min(Math.floor((value * 100) / max), 100);
}

interface IDefaultProgressBar extends IProgressBar {
    countBars: number;
}

const DefaultProgressBar = ({ currentValue, countBars, animatedValues, className, defaultProgressTooltipText, maxValue, hiddenPoint }: IDefaultProgressBar) => {
    const [progressBarStates, setProgressBarState] = useState<Nullable<IAnimatedValues>>(null);
    const progressBarRef = useRef<HTMLDivElement>();
    const currentWidth = getWidth(currentValue, countBars);
    const resetAnimationTimer = useRef<ReturnType<typeof setTimeout>>();
    const resetAnimationRestartTimer = useRef<ReturnType<typeof setTimeout>>();

    const reset = (ms = 770) => {
        resetAnimationTimer.current = setTimeout(() => {
            setProgressBarState(null);
        }, ms);
    };

    useEffect(() => {
        return () => {
            clearTimeout(resetAnimationTimer.current);
            clearTimeout(resetAnimationRestartTimer.current);
        };
    }, []);

    useEffect(() => {
        if (animatedValues) {
            const currentWidth = getWidth(animatedValues.currentValue, countBars);
            const previewWidth = getWidth(animatedValues.previewValue, countBars);
            if (currentWidth <= previewWidth) {
                setProgressBarState({ currentValue: 100, previewValue: previewWidth });
                resetAnimationRestartTimer.current = setTimeout(() => {
                    if (progressBarRef.current) {
                        progressBarRef.current.classList.remove(styles.animation);
                        progressBarRef.current.offsetHeight;
                    }
                    progressBarRef.current.classList.add(styles.animation);
                    setProgressBarState({ currentValue: currentWidth, previewValue: 0 });
                    reset();
                }, 1000);
            } else {
                setProgressBarState({ currentValue: currentWidth, previewValue: previewWidth });
                reset();
            }
        } else {
            setProgressBarState(null);
        }
    }, [animatedValues]);

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            progressBarRef.current = _ref;
        }
    };

    const progressBarStyles = {
        width: `${progressBarStates?.previewValue >= 0 ? progressBarStates?.previewValue : currentWidth}%`,
        '--preview-width': `${progressBarStates?.previewValue || 0}%`,
        '--current-width': `${progressBarStates?.currentValue || 0}%`,
    } as CSSProperties;

    return (
        <DivTooltip
            className={classNames(styles.lineProgressBar, className?.defaultProgressBar)}
            tooltipBody={
                <DefaultTooltip
                    text={
                        defaultProgressTooltipText ||
                        interpolate(t('Приобретено наборов {amount} из {total}'), {
                            amount: currentValue,
                            total: maxValue,
                        })
                    }
                />
            }
        >
            <div
                ref={setRef}
                className={classNames(styles.progressLine, {
                    [styles.hiddenPoint]: hiddenPoint || currentWidth === 100 || !currentWidth,
                    [styles.animation]: !!progressBarStates,
                })}
                style={progressBarStyles}
            />
        </DivTooltip>
    );
};

interface ISeparateProgressBar extends IProgressBar {
    countBars: number;
}

const SeparateProgressBar = ({ countBars, itemTooltips, valuableIndexes, currentValue, selectedIndex, className, onClickHandler }: ISeparateProgressBar) => {
    return (
        <div className={styles.progressBar}>
            {new Array(countBars).fill(1).map((i, _index) => {
                const index = _index + 1;
                const tooltip = itemTooltips?.[_index];
                return (
                    <DivTooltip
                        tooltipBody={tooltip}
                        className={classNames(
                            styles.bar,
                            {
                                [styles.valuable]: valuableIndexes?.includes(index),
                                [styles.active]: index <= currentValue,
                                [styles.selected]: selectedIndex === _index,
                            },
                            className?.seperateItem,
                        )}
                        style={{ cursor: 'pointer' }}
                        key={`progress_bar_${index}`}
                        onClick={() => onClickHandler(_index)}
                    />
                );
            })}
        </div>
    );
};

const ProgressBar = ({
    maxValue,
    minValue,
    animatedValues,
    currentValue,
    separateLimit,
    step,
    onClickHandler,
    valuableIndexes,
    itemTooltips,
    selectedIndex,
    progressDisplay,
    hiddenPoint,
    defaultProgressTooltipText,
    className,
}: IProgressBar) => {
    const minimalValue = minValue != undefined ? minValue : 1;
    const _step = step || 1;
    const countBars = Math.floor(maxValue / _step);

    const isStartPosition = progressDisplay === ProgressDisplay.START;
    const classesPositions = classNames(styles.positions, {
        [styles.start]: isStartPosition,
    });

    return (
        <div className={styles.wrapper}>
            {countBars > separateLimit ? (
                <DefaultProgressBar
                    currentValue={currentValue}
                    countBars={countBars}
                    animatedValues={animatedValues}
                    className={className}
                    defaultProgressTooltipText={defaultProgressTooltipText}
                    maxValue={maxValue}
                    hiddenPoint={hiddenPoint}
                />
            ) : (
                <SeparateProgressBar
                    maxValue={maxValue}
                    countBars={countBars}
                    itemTooltips={itemTooltips}
                    valuableIndexes={valuableIndexes}
                    currentValue={currentValue}
                    selectedIndex={selectedIndex}
                    className={className}
                    onClickHandler={onClickHandler}
                />
            )}
            <div className={classesPositions}>
                {isStartPosition ? (
                    <div className={styles.startPosition}>{interpolate(t('{current}/{max}'), { current: currentValue, max: maxValue })}</div>
                ) : (
                    <>
                        <div className={styles.startPosition}>{minimalValue}</div>
                        <div className={styles.endPosition}>{maxValue}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProgressBar;
