import { getBundleUrl } from '~/utils/bundles';

export default function generateProductStruct(bundle: IBundle) {
    const bundleUrl = `https://${location.host}${getBundleUrl(bundle)}`;
    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.textContent = `
    {
        "@context":"https://schema.org",
        "@type":"Product",
        "name": "${bundle.title}",
        "url": "${bundleUrl}",
        "mainEntityOfPage": "${bundleUrl}",
        "offers": {
            "@type": "Offer",
            "url": "${bundleUrl}",
            "priceCurrency": "${bundle.originalProduct.currency}",
            "price": ${bundle.originalProduct.price}
        },
        "image": "${bundle.icons.big || bundle.icons.default}",
        "description": "${bundle.title}"
    }
    `;
    document.head.appendChild(script);
}
