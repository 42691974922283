import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './RewardsPopup.scss';
import { interpolate, t } from '~/utils/localization';
import Button from '~/components/Button/Button';
import { useDispatch } from 'react-redux';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';
import classNames from 'classnames';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { ZoomInDiv } from '~/components/Transitions';
import { UserGraphicsQualityPresetTypes } from '@wg/web2clientapi/browser/getUserGraphicsQualityPreset';
import equal from 'fast-deep-equal/react';
import { Interpolate } from '@wg/wows-react-uikit';
import Currency from '~/components/Currency/Currency';
import Account from '~/account/Account';
import { useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import Reward, { IRewardItem } from './Reward/Reward';
import { getCompensationForPrimaryItem, getPrimaryItem } from '~/utils/bundles';

export interface IRewardPopupData {
    promotionReward?: IRewardItem;
    rewards: IRewardItem[];
    compensatedItemIds: number[];
    turnOffAnimation?: boolean;
    onCloseHandler?: DefaultFunction;
}

interface IRewardPopup {
    data: IRewardPopupData;
}

const MAXIMUM_REWARDS = 5;

function chunkArray(array: Array<any>, size: number) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
}

export enum RewardTemplate {
    DEFAULT = 'default',
    MIDDLE = 'middle',
    BIG = 'big',
}

const RewardsPopup = ({ data }: IRewardPopup) => {
    const { promotionReward, rewards, onCloseHandler, compensatedItemIds, turnOffAnimation } = data;
    const dispatch = useDispatch();
    const userGraphics = useAppSelector((state) => state.account.userGraphics, equal);
    let template: RewardTemplate = RewardTemplate.BIG;
    const displayRewards = rewards;
    if (!promotionReward && rewards.length > 2) {
        template = RewardTemplate.MIDDLE;
    } else if (promotionReward) {
        template = RewardTemplate.MIDDLE;
    }

    const chunks = useMemo(() => chunkArray([...displayRewards], MAXIMUM_REWARDS), []);
    const [currentIndex, setRewardsIndex] = useState(0);
    const [currentDisplayRewardsList, setRewards] = useState(displayRewards);

    useEffect(() => {
        if (promotionReward) {
            const rewardsList = chunks[currentIndex];
            rewardsList && setRewards(rewardsList);
        }
    }, [currentIndex]);

    const close = useCallback(() => {
        if (!promotionReward || (promotionReward && currentIndex + 1 >= chunks.length)) {
            dispatch(appActions.changeVisibilityPopup({ name: null }));
            onCloseHandler?.();
        } else {
            setRewardsIndex(currentIndex + 1);
        }
    }, [currentIndex]);

    useKeyDown(() => {
        playButtonClickSound();
        close();
    }, [KEYS_CODE.ESC]);

    const classesRewardsContent = classNames(styles.rewardsContent, {
        [styles.nowrap]: template === RewardTemplate.BIG,
    });

    const classesContentWrapper = classNames(styles.contentWrapper, {
        [styles.center]: !promotionReward,
        [styles.singleLegendaryReward]: promotionReward && !displayRewards.length,
    });

    const classesSpecialRewardsWrapper = classNames(styles.specialRewardWrapper);

    const classesBackground = classNames(styles.background, {
        [styles.withBackgroundColor]: !!promotionReward,
        [styles.withAnimate]: !!promotionReward,
    });

    let style: React.CSSProperties = {};
    if (promotionReward) {
        style = {
            backgroundImage: `url(${promotionReward.images?.big})`,
            '--background-color': `${promotionReward.backgroundColor || '#0B1017'}`,
        } as React.CSSProperties;
    }

    let headerTitle = displayRewards.length > 1 ? t('Ваши награды') : t('Ваша награда');
    if (promotionReward) {
        headerTitle = !!displayRewards.length ? t('Поздравляем! Вы получили награды') : t('Поздравляем!');
    }

    const withoutAnimation = turnOffAnimation || userGraphics?.preset === UserGraphicsQualityPresetTypes.LOW;

    const primaryItem = promotionReward ? getPrimaryItem(promotionReward.items) : null;
    const [isExistsItemInInventory] = primaryItem ? Account.getInventoryInfoByType(primaryItem.type, primaryItem.identifier, primaryItem.customisation?.shipId) : [false];
    const compensation = promotionReward && getCompensationForPrimaryItem(primaryItem);
    const isCompensated = compensatedItemIds?.includes(primaryItem?.identifier);

    let buttonCloseLabel = t('Закрыть');
    if (promotionReward && currentIndex + 1 < chunks.length) {
        buttonCloseLabel = interpolate(t('еще {amount}'), { amount: chunks[currentIndex + 1].length });
    }

    return (
        <div className={styles.popup}>
            <div className={classesBackground} style={style}>
                <div className={styles.mask}></div>
            </div>
            <div className={styles.close}>
                <ButtonEsc onClick={close} />
            </div>
            <div className={classesContentWrapper}>
                {withoutAnimation ? <div className={styles.header}>{headerTitle}</div> : <ZoomInDiv className={styles.header}>{headerTitle}</ZoomInDiv>}
                <div className={styles.content}>
                    <div className={classesRewardsContent}>
                        {promotionReward && currentIndex == 0 && (
                            <div className={classesSpecialRewardsWrapper}>
                                <WoWSEntity
                                    type={promotionReward.items[0].type}
                                    id={promotionReward.items[0].identifier}
                                    presentation={{ withTooltip: true, renderWithoutFade: true }}
                                    className={styles.specialReward}
                                />
                                <div className={styles.compensationWrapper}>
                                    {isCompensated && isExistsItemInInventory && (
                                        <Interpolate
                                            str={`${t('Компенсация')} %(currency)s`}
                                            currency={<Currency currency={compensation.currency} amount={compensation.amount} className={styles.currency} />}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {currentDisplayRewardsList.map((reward, index) => {
                            return (
                                <Reward
                                    reward={reward}
                                    compensatedItems={compensatedItemIds}
                                    template={template}
                                    key={`reward_${reward.name}_${index}_${currentIndex}`}
                                    delay={0.3 + index / 10}
                                    withoutAnimation={withoutAnimation}
                                />
                            );
                        })}
                    </div>
                    <div className={styles.footer}>
                        <Button label={buttonCloseLabel} onClick={close} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RewardsPopup;
