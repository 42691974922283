import styles from './Tooltip.scss';
import equal from 'fast-deep-equal/react';
import Price from '~/components/Price/Price';
import { arrayToObjectByKey } from '~/utils/utils';
import { NotEnoughCurrencyType } from '~/components/CurrencyShortageBlock/CurrencyShortageBlock';
import classNames from 'classnames';
import { getCurrency } from '~/utils/currencies';
import { useAppSelector } from '~/Store';

interface IShortageCurrenciesTooltip {
    currencies: NotEnoughCurrencyType[];
}

const ShortageCurrenciesTooltip = ({ currencies }: IShortageCurrenciesTooltip) => {
    const currenciesFromState = useAppSelector((state) => state.app.currencies, equal);
    const shortageCurrenciesMap = arrayToObjectByKey(currencies, 'currency');

    return (
        <div className={styles.currenciesTooltip}>
            {currencies.map((item: NotEnoughCurrencyType) => {
                return (
                    <div className={styles.bundleCurrencyItem} key={item.currency}>
                        <div className={styles.currencyItemTitle}>{currenciesFromState[item.currency].title}</div>
                        <div className={styles.currencyItemDotted}></div>
                        <div className={styles.currencyItemWidget}>
                            <Price
                                amount={shortageCurrenciesMap[item.currency].amount}
                                currency={item.currency}
                                showDescriptionInPriceTooltip={getCurrency(item.currency)?.showDescriptionInPriceTooltip}
                                className={classNames(styles.currencyItemPrice, styles.shortageColor)}
                                withoutAnimation
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ShortageCurrenciesTooltip;
