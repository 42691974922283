import * as React from 'react';
import styles from './Wallet.scss';
import { t } from '~/utils/localization';
import { useDispatch } from 'react-redux';
import { WalletPopup } from '~/components/Wallet/Wallet';
import { scrollMobileContainer } from '~/utils/scrollTop';
import { appActions } from '~/Store/appSlice';

const MobileWalletCategory = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        scrollMobileContainer({ top: 0 });
        dispatch(
            appActions.changeCurrentPage({
                title: t('Кошелёк'),
                name: 'wallet',
                isBundlePage: false,
            }),
        );
    }, []);

    return (
        <div className={styles.walletContainer}>
            <WalletPopup callback={() => null} />
        </div>
    );
};

export default MobileWalletCategory;
