import { type SpinePlayer } from '@esotericsoftware/spine-player';
import store from '~/Store';
import { gachaActions } from '~/Store/gachaSlice';
import { AnimationSpinesNames, GachaBannerGroupRarity } from '~/types/gacha';
import { isMobileOrTabletWindow } from '~/utils/utils';

const spineManager: Record<string, Nullable<SpinePlayer>> = {};

export const addSpinePlayer = (bannerName: string, spine: SpinePlayer) => {
    spineManager[bannerName] = spine;
};

export const deleteSpinePlayer = (bannerName: string) => {
    spineManager[bannerName] = null;
};

export const runRollSpineAnimationByName = (animationName: AnimationSpinesNames, bannerName: string, onFinish: DefaultFunction) => {
    if (!spineManager[bannerName] || isMobileOrTabletWindow) {
        onFinish();
        return;
    }

    const animation = spineManager[bannerName].skeleton.data.animations.find((anim) => anim.name === animationName);
    if (!animation) {
        onFinish();
        return;
    }

    store.dispatch(gachaActions.updateAnimationState(true));
    spineManager[bannerName].setAnimation(animationName, false);
    spineManager[bannerName].addAnimation(AnimationSpinesNames.IDLE, true, animation.duration);

    setTimeout(() => {
        onFinish();
        store.dispatch(gachaActions.updateAnimationState(false));
    }, animation.duration * 1000);
};

const animationNamesByRariryMap: Record<GachaBannerGroupRarity, AnimationSpinesNames> = {
    [GachaBannerGroupRarity.common]: AnimationSpinesNames.COMMON_ROLL,
    [GachaBannerGroupRarity.free]: AnimationSpinesNames.COMMON_ROLL,
    [GachaBannerGroupRarity.valuable]: AnimationSpinesNames.VALUABLE_ROLL,
    [GachaBannerGroupRarity.legendary]: AnimationSpinesNames.LEGENDARY_ROLL,
};
export const getAnimationNameByRariry = (rarity: GachaBannerGroupRarity) => {
    if (!rarity) {
        return AnimationSpinesNames.COMMON_ROLL;
    }
    return animationNamesByRariryMap[rarity] || AnimationSpinesNames.COMMON_ROLL;
};
