import * as React from 'react';
import styles from './Layouts.scss';
import { default as commonStyles } from '../assets/styles/common.scss';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { t } from '~/utils/localization';
import Timer from '~/components/Timer/Timer';
import CategoryFilter from '~/components/CategoryFilter/CategoryFilter';
import BundleManager from '~/components/Bundle/BundleManager';
import { openCategoryByName } from '~/utils/category';
import Button from '~/components/Button/Button';
import { sortBundles } from '~/utils/sort/settings';
import { isNeedToRenderFilter } from '~/utils/filters';
import equal from 'fast-deep-equal/react';
import { BundleContext } from '~/components/Bundle/BundleContext';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface IDefaultLayout {
    bundles: number[];
    title?: string;
    description?: string;
    showCountdown?: boolean;
    activeTill?: string;
    limit?: number;
    categoryName?: ICategoryList;
    isNotRenderFilter?: boolean;
    withoutHeader?: boolean;
    isLazy?: boolean;
    preset?: ICategoryPresets;
    layoutClassName?: string;
    showMoreButtonTitle?: string;
    onShowMoreButtonClick?: () => void;
    isShowMoreButton?: boolean;
    disableRedirectToBundle?: boolean;
}

const stateSelector = (state: RootState) => {
    return {
        bundles: state.app.bundles,
        categories: state.app.categories,
        currentPage: state.app.currentPage,
        disabledCategories: state.app.disabledCategories,
        sortedBy: state.app.sortedBy,
    };
};

/**
 * Лаяут из 4 равных блоков шириной 25% в несколько рядов или из 3 равных блоков шириной 33.3333%
 */
const DefaultLayout = (props: IDefaultLayout) => {
    const { bundles, categories, currentPage, disabledCategories, sortedBy } = useAppSelector(stateSelector, equal);
    const dispatch = useDispatch();

    let cutBundles = [...(props.bundles || [])].splice(0, props.limit || props.bundles.length);
    const isNeedToShowMoreButton = cutBundles.length < props.bundles?.length;

    const parentCategory = categories[props.categoryName]?.parentCategoryData;

    const showMore = () => {
        if (props.onShowMoreButtonClick) {
            return props.onShowMoreButtonClick();
        }
        openCategoryByName(props.categoryName);
    };

    const category = (props.categoryName ?? currentPage?.name) as ICategoryList;

    const onFinishCallback = () => {
        dispatch(appActions.disableCategory(category));
    };

    const nowTime = Math.floor(Date.now() / 1000);
    const categoryActiveTill = Math.floor(new Date(props.activeTill).getTime() / 1000);
    const isDisabledCategory = disabledCategories.includes(category) || (props.activeTill && categoryActiveTill - nowTime <= 0);

    const activeSort = sortedBy?.[currentPage?.name];
    if (activeSort?.method) {
        cutBundles = sortBundles(activeSort.name, activeSort.method, bundles, cutBundles);
    }

    const _isNeedToRenderFilter = isNeedToRenderFilter(category);

    return (
        <div className={classNames(styles.layout, 'bundles-layout')} data-category-layout={props.categoryName} key={category}>
            {!props.withoutHeader && (
                <React.Fragment>
                    <div className={styles.layoutHeader}>
                        {props.title && (
                            <>
                                <h2 className={styles.layoutTitle}>{props.title}</h2>
                                <div className={styles.layoutBorder} />
                            </>
                        )}
                        {!props.isNotRenderFilter && _isNeedToRenderFilter && <CategoryFilter category={category} />}
                        {props.showCountdown && props.activeTill && (
                            <React.Fragment>
                                {!props.isNotRenderFilter && _isNeedToRenderFilter && <div className={styles.layoutBorder} />}
                                <div className={styles.layoutTimerWrapper}>
                                    <Timer time={props.activeTill} onFinishCallback={onFinishCallback} />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    {props.description && <div className={styles.layoutDescription}>{props.description}</div>}
                </React.Fragment>
            )}
            <div className={classNames(styles.layoutColumns, props.layoutClassName)}>
                <div className={classNames(styles.columns)}>
                    {!isDisabledCategory &&
                        cutBundles.map((bundleId, index) => {
                            const bundle = bundles[bundleId];
                            if (!bundle || (bundle && bundle.isHidden)) {
                                return null;
                            }

                            const bundleProps = {
                                ...props,
                                fromCategory: parentCategory?.name,
                            };

                            return (
                                <BundleContext.Provider value={{ preset: props.preset }} key={`offer_${index}_${bundleId}_${props.preset?.name || 'no_preset'}`}>
                                    <BundleManager bundle={bundle} preset={props.preset} isLazy={props.isLazy} {...bundleProps} />
                                </BundleContext.Provider>
                            );
                        })}
                </div>
                {!cutBundles.length && (
                    <div className={commonStyles.bigNotice}>
                        <div className={commonStyles.bigNoticeText}>{t('Ничего не найдено')}</div>
                    </div>
                )}
                {isDisabledCategory && (
                    <div className={commonStyles.bigNotice}>
                        <div className={commonStyles.bigNoticeText}>{t('Товары в этой категории более недоступны')}</div>
                    </div>
                )}
                {((!isDisabledCategory && isNeedToShowMoreButton) || props.isShowMoreButton) && (
                    <div className={styles.buttonShowMoreWrapper}>
                        <Button label={props.showMoreButtonTitle || t('Перейти в категорию')} onClick={showMore} className={[styles.buttonShowMore, 'armory__auto--button_category']} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default DefaultLayout;
