import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { AUCTION_KIND, CATEGORIES, DWH_EVENTS } from '~/const';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES, PLACEMENT } from '~/components/WelcomePage/steps';
import HelperIcon from '~/components/HelperIcon/HelperIcon';
import styles from '~/components/TopPanel/TopPanel.scss';
import dwhExport from '~/api/dwhExport';
import { POPUPS_NAME } from '../PopupManager';
import { t } from '~/utils/localization';
import { CategoryLabelAboutTypes } from '~/types/category';
import store, { RootState, useAppSelector } from '~/Store';
import DefaultTooltip from '../Tooltip/DefaultTooltip';
import { openUrl } from '~/utils/settings';
import { isDisplayRestricted } from '~/utils/bundles';
import { appActions } from '~/Store/appSlice';

interface IGuideWrapperState {
    activeAuctions: IAuction[];
    currentPage: ICurrentPage;
    menu: IMenuMap;
    categories: ICategories;
}

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        activeAuctions: state.auction.activeAuctions,
        menu: state.app.menu,
        categories: state.app.categories,
    };
};

const GuideWrapper = () => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const currentPage = state.currentPage;
    const currentCategoryConfig = state.categories[currentPage?.name];

    if (!currentCategoryConfig?.label) {
        return null;
    }

    if (isDisplayRestricted(currentCategoryConfig.label)) {
        return null;
    }

    const auctions = state.activeAuctions;
    const auctionsKind = Array.from(new Set(auctions.map((a) => a.kind))).join(', ');

    if (currentPage.name === CATEGORIES.AUCTION && auctionsKind.includes(AUCTION_KIND.SECOND_PRICE)) {
        const onClick = () => {
            dwhExport.send(DWH_EVENTS.OPEN_RULES);
            dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.AUCTION_ABOUT_POPUP, isVisible: true }));
        };
        return (
            <HelperIcon
                label={currentCategoryConfig.label.title || t('Правила')}
                onClick={onClick}
                className={styles.helperIcon}
                tooltipBody={currentCategoryConfig.label.tooltipText && <DefaultTooltip text={currentCategoryConfig.label.tooltipText} />}
            />
        );
    }

    const onClick = () => {
        dwhExport.send(DWH_EVENTS.CATEGORY_ABOUT_EVENT, { category: currentCategoryConfig.name });
        switch (currentCategoryConfig.label.type) {
            case CategoryLabelAboutTypes.IFRAME:
                store.dispatch(
                    appActions.changeVisibilityPopup({
                        name: POPUPS_NAME.IFRAME_POPUP,
                        isVisible: true,
                        data: { iframeUrl: currentCategoryConfig.label.value },
                    }),
                );
                break;

            case CategoryLabelAboutTypes.POPUP:
                const popupName = currentCategoryConfig.label.value?.toUpperCase() as keyof typeof POPUPS_NAME;
                POPUPS_NAME[popupName] && store.dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME[popupName], isVisible: true }));
                break;

            case CategoryLabelAboutTypes.LINK:
                openUrl(currentCategoryConfig.label.value);
                break;
        }
    };

    return (
        <GuideDecorator names={[GUIDE_NAMES.guide_rules_of_category]} placement={PLACEMENT.BOTTOM} centerBeacon={true} className={styles.guideWrapper}>
            <HelperIcon
                label={currentCategoryConfig.label.title || t('О cобытии')}
                onClick={onClick}
                className={styles.helperIcon}
                tooltipBody={<DefaultTooltip text={currentCategoryConfig.label.tooltipText ?? t('Читайте подробности о текущих предложениях и другую полезную информацию')} />}
            />
        </GuideDecorator>
    );
};

export default GuideWrapper;
