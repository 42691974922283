import { LocalStorageHelper } from '~/utils/storage';
import { POPUPS_NAME } from '~/components/PopupManager';
import store from '~/Store';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { isDisplayRestricted, isFreeBundle } from '~/utils/bundles';
import { settings } from '~/utils/settings';
import { sort } from 'fast-sort';
import { assets } from '@wg/wows-entities/const';
import { BundlePurchaseTypes } from '~/types/bundle';
import { appActions } from '~/Store/appSlice';
import { accountActions } from '~/Store/accountSlice';

export const hasCouponFromCategory = (coupons: ICoupon[], category: string): ICoupon | null => {
    return (
        coupons?.filter((coupon) => {
            return coupon.category === category && !coupon.isDisabled;
        })[0] || null
    );
};

export const getActiveCoupon = (activeCouponId: number, coupons: ICoupon[]): ICoupon => {
    return coupons.filter((coupon: ICoupon) => {
        return coupon.id === activeCouponId;
    })[0];
};

export const isCouponEnabledForCurrency = (coupon: ICoupon | null, currency: string): boolean => {
    if (!coupon) {
        return false;
    }

    return !coupon.deniedCurrencies.includes(currency);
};

export const prepareCouponsFromLocalStorage = (coupons: ICoupon[], withoutUpdateCounter = false) => {
    if (window.metashop.state.content.newCouponsCount) {
        return;
    }

    const couponsData = coupons.reduce((data: Record<string, number>, coupon) => {
        if (!data[coupon.category]) {
            data[coupon.category] = 0;
        }

        data[coupon.category] += coupon.counter;

        return data;
    }, {});

    window.metashop.state.content.newCouponsCount = 0;

    const prevCouponsData = LocalStorageHelper.get('couponsData');
    if (prevCouponsData) {
        Object.keys(prevCouponsData).forEach((key) => {
            if (!withoutUpdateCounter && prevCouponsData[key] < couponsData[key]) {
                window.metashop.state.content.newCouponsCount += couponsData[key] - prevCouponsData[key];
            }
        });
    }

    LocalStorageHelper.set('couponsData', couponsData);

    return couponsData;
};

export const excludeCouponsForNotExistingCategories = (coupons: ICoupon[], categories: ICategories) => {
    return coupons.filter((coupon) => !!categories[coupon.category]);
};

export const isDisabledCoupon = (coupon: ICoupon, useCoupons: Record<string, number>) => {
    const startAt = new Date(coupon.startAt).getTime();
    const validityPeriod = coupon.validityPeriod * 1000;
    const nextPeriodTime = new Date(startAt + validityPeriod).getTime();
    const maxApplyNumber = coupon.maxApplyNumber;
    const currentTime = Date.now();

    return useCoupons?.[coupon.name] === maxApplyNumber || nextPeriodTime <= currentTime;
};

export const prepareCouponsFromState = (coupons: ICoupon[], useCoupons: { [key: string]: number }, sorted = false): ICoupon[] => {
    if (!coupons || coupons.length === 0) {
        return [];
    }

    let result: ICoupon[] = [];

    coupons.forEach((coupon: ICoupon) => {
        const copiedCoupon = structuredClone(coupon);
        const startAt = new Date(copiedCoupon.startAt).getTime();
        const validityPeriod = copiedCoupon.validityPeriod * 1000;
        const nextPeriodTime = new Date(startAt + validityPeriod).getTime();
        const maxApplyNumber = copiedCoupon.maxApplyNumber;
        const currentTime = Date.now();

        if (!useCoupons?.[copiedCoupon.name] || useCoupons?.[copiedCoupon.name] < maxApplyNumber) {
            copiedCoupon.isDisabled = false;
        } else {
            copiedCoupon.isDisabled = isDisabledCoupon(copiedCoupon, useCoupons);
        }

        if (copiedCoupon.isProlongable && !copiedCoupon.isDisabled) {
            copiedCoupon.prevStartAt = startAt;
        }

        if (useCoupons && useCoupons[copiedCoupon.name]) {
            copiedCoupon.counter = copiedCoupon.maxApplyNumber - useCoupons[copiedCoupon.name];
        } else {
            copiedCoupon.counter = copiedCoupon.maxApplyNumber;
        }

        if (copiedCoupon.isProlongable && !copiedCoupon.isDisabled && nextPeriodTime <= currentTime) {
            copiedCoupon.startAt = new Date(nextPeriodTime).toISOString();
        }

        result.push(copiedCoupon);
    });

    result = result.filter((coupon) => {
        if (isDisplayRestricted(coupon) || (coupon.isDisabled && !coupon.isProlongable)) {
            return false;
        }
        return true;
    });

    if (sorted) {
        result = sort(result).asc((coupon) => {
            if (!coupon.isProlongable) {
                return 1;
            }
            if (!coupon.deniedCurrencies?.includes(assets.GOLD)) {
                return 2;
            }
            return 3;
        });
    }

    prepareCouponsFromLocalStorage(result);

    return result;
};

export const isEnabledCouponFromBundle = (coupon: ICoupon | null, bundle: IBundle): boolean => {
    if (!coupon) {
        return false;
    }

    if (bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY) {
        return false;
    }

    if (bundle.price && bundle.additionalPrice) {
        return false;
    }

    if (bundle.originalPrice) {
        return false;
    }

    if (bundle.isRandom) {
        return false;
    }

    if (isFreeBundle(bundle)) {
        return false;
    }

    if (coupon.deniedBundles.includes(bundle.id)) {
        return false;
    }

    if (coupon.allowedBundles.length) {
        return coupon.allowedBundles.includes(bundle.id);
    }

    if (coupon.isDisabled) {
        return false;
    }

    if (!bundle.categories.length || !bundle.categories?.includes(coupon.category)) {
        return false;
    }

    const currencies = [bundle.currency];
    bundle.additionalCurrency && currencies.push(bundle.additionalCurrency);

    let flag = false;

    currencies.forEach((currency) => {
        if (!coupon.deniedCurrencies.includes(currency)) {
            flag = true;
        }
    });

    return flag;
};

export const isEnabledCouponsFromBundle = (coupons: ICoupon[], bundle: IBundle): boolean => {
    return coupons.some((coupon) => isEnabledCouponFromBundle(coupon, bundle));
};

export const getAvailableCouponsFromBundle = (coupons: ICoupon[], bundle: IBundle): ICoupon[] => {
    return coupons.filter((coupon) => isEnabledCouponFromBundle(coupon, bundle));
};

export const isNotEnabledCoupon = (bundle: IBundle): boolean => {
    if (bundle.quantityData && bundle.quantityData.quantity && bundle.quantityData.quantity > 1) {
        return true;
    }
};

export const hasNewCoupons = () => {
    return !!window.metashop.state.content.newCouponsCount ? window.metashop.state.content.newCouponsCount : null;
};

export const sortedCoupons = (coupons: ICoupon[], splitCount = 1): ICoupon[] => {
    return [...coupons]
        .sort((coupon1: ICoupon, coupon2: ICoupon) => {
            const discount1 = coupon1.discount * 100;
            const discount2 = coupon2.discount * 100;

            if (discount1 > discount2) {
                return -1;
            } else if (discount2 > discount1) {
                return 1;
            }

            return 0;
        })
        .splice(0, splitCount);
};

export const showMoreCouponsPopup = (bundle: IBundle) => {
    store.dispatch(
        appActions.changeVisibilityPopup({
            name: POPUPS_NAME.CHOOSE_COUPON_POPUP,
            isVisible: true,
            data: {
                bundle: bundle,
                onClick: (couponId: number): void => {
                    store.dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.CHOOSE_COUPON_POPUP }));
                    store.dispatch(accountActions.activateCoupon({ id: couponId, bundleId: bundle.id }));
                },
            },
        }),
    );
};

export const selectCouponFromBundle = (coupons: ICoupon[], coupon: ICoupon | null, bundle: IBundle) => {
    if (coupons.length === 1 || coupon?.id) {
        store.dispatch(accountActions.activateCoupon({ id: coupon?.id, bundleId: bundle.id }));
    } else {
        dwhExport.send(DWH_EVENTS.OPEN_BUNDLE_COUPONS_POPUP, {
            bundle_id: bundle.id,
        });
        showMoreCouponsPopup(bundle);
    }
};

export const hasCoupons = () => {
    return window.metashop.state?.content?.coupons?.length > 0;
};

export const isCouponsTabHidden = () => {
    return settings.isCouponsTabHidden;
};

export const updateCouponByNameFromList = (coupons: ICoupon[], couponName: string, handler: (coupon: Partial<ICoupon>) => Partial<ICoupon>) => {
    return coupons.map((coupon) => {
        const _coupon = coupon;
        if (_coupon.name === couponName) {
            Object.assign(_coupon, handler(coupon));
        }
        return _coupon;
    });
};
