import * as React from 'react';
import styles from '~/components/Auction/Auction.scss';
import { items } from '@wg/wows-entities/const';
import Ship from '~/components/Ship/Ship';
import { CommanderName } from '~/components/Commanders/Commanders';

interface IAuctionLotHeader extends ILot {
    crewClassName?: string;
}

const AuctionLotHeader = (props: IAuctionLotHeader) => {
    const commonLotType = props.entitlements?.[0]?.type;

    if (props.denyTitleModification) {
        return <div className={styles.title}>{props.title}</div>;
    }

    switch (commonLotType) {
        case items.VEHICLES:
            const ship = props.entitlements[0];

            return <Ship shipId={Number(ship.id)} fallback={props.title} />;

        case items.CREWS:
            const crew = props.entitlements[0];

            return <CommanderName name={props.title} id={Number(crew.id)} className={props.crewClassName} />;

        default:
            return <div className={styles.title}>{props.title}</div>;
    }
};

export default AuctionLotHeader;
