import * as React from 'react';
import styles from './PossibleOptionsRandomBundle.scss';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { items } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { getPrimaryItem } from '~/utils/bundles';
import equal from 'fast-deep-equal/react';
import Account from '~/account/Account';
import { playTabClickSound } from '~/api/WoWsClient';

interface IPossibleOptionsRandomBundle {
    parentRandomBundle: IBundle;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    currentBundle?: IBundle;
    selectedBundle?: IBundle;
    onClick?: (bundle: IBundle) => void;
    isEnableFirstOpen?: boolean;
}

interface IItemTitleProps {
    title: string;
    denyModification: boolean;
    primaryItem: IBundleEntity;
}

const ItemTitle = ({ title, denyModification, primaryItem }: IItemTitleProps) => {
    if (denyModification) {
        return <>{title}</>;
    }

    switch (primaryItem?.type) {
        case items.VEHICLES:
            return (
                <WoWSEntity
                    type={primaryItem.type}
                    id={primaryItem.identifier}
                    className={styles.entityWrapper}
                    fallback={(element: Element) => {
                        element.textContent = title;
                    }}
                />
            );

        case items.PERMOFLAGES:
        case items.CAMOUFLAGE:
        case items.SKIN:
        case items.STYLE:
        case items.MSKIN:
            return (
                <WoWSEntity
                    type={primaryItem.type}
                    id={primaryItem.identifier}
                    className={styles.permoflageItem}
                    presentation={{ withText: true }}
                    customisation={primaryItem?.customisation}
                    fallback={(element: Element) => {
                        element.textContent = title;
                    }}
                />
            );

        default:
            return <>{title}</>;
    }
};

const ItemTitleMemo = React.memo(ItemTitle, equal);

const PossibleOptionsRandomBundle = ({ parentRandomBundle, purchasedLimitedBundles, onClick, currentBundle, selectedBundle, isEnableFirstOpen }: IPossibleOptionsRandomBundle) => {
    const isAllowedClick = !(Account.getAccount()?.id && !isEnableFirstOpen);

    return (
        <div className={styles.content}>
            <div className={styles.contentTitle}>{t('Возможные варианты случайных наборов')}</div>
            <div className={styles.body}>
                {parentRandomBundle.randomBundleChildren?.map((child, index) => {
                    const alreadyCountItem = purchasedLimitedBundles?.[child.id] || 0;
                    const bundle = Object.assign({}, parentRandomBundle, child);
                    const primaryItem = getPrimaryItem(bundle.entitlements);

                    const itemClassNames = classNames(styles.randomBundleItem, {
                        [styles.current]: currentBundle.id === bundle.id,
                        [styles.select]: selectedBundle?.id === bundle.id,
                        [styles.isGuest]: isAllowedClick,
                    });

                    return (
                        <div
                            className={itemClassNames}
                            key={child.id + '_' + index}
                            onClick={() => {
                                playTabClickSound();
                                !isAllowedClick && onClick?.(bundle);
                            }}
                        >
                            {!isAllowedClick && <div className={styles.randomBundleItemCircle} />}
                            <div className={styles.randomBundleItemTitle}>
                                <div className={styles.randomBundleItemText}>
                                    <ItemTitleMemo title={child.title} denyModification={bundle.denyTitleModification} primaryItem={primaryItem as IBundleEntity} />
                                </div>
                            </div>
                            {!bundle.randomIsInfinite && (
                                <div className={styles.randomBundleItemCount}>
                                    <span>
                                        {alreadyCountItem}/{child.limitedQuantity?.personalLimit}
                                    </span>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            {!parentRandomBundle.randomHideEqualProbabilityNotice && <div className={styles.chance}>{t('Вероятность выпадения каждого отдельного набора одинаковая.')}</div>}
        </div>
    );
};

export default PossibleOptionsRandomBundle;
