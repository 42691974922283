import { IGachaBanner, IGachaBannerInvoiceItem, IGachaBannerReward, IGachaBannerRewardsGroup } from '~/types/gacha';
import { MercatorMysteryContentState } from '~/types/mercator';
import { arrayToObjectByKey } from '~/utils/utils';

export function getRecivedRewardsByNames(names: string[], banner: IGachaBanner): IGachaBannerReward[] {
    const rewards: IGachaBannerReward[] = [];
    const freeRewardsMap = arrayToObjectByKey(banner.freeRewards.rewardBundles as IGachaBannerReward[], 'name');

    names.forEach((name) => {
        const paidReward = getPaidRewardByName(banner.rewardsGroups, name);
        paidReward && rewards.push(paidReward);
        freeRewardsMap[name] && rewards.push(freeRewardsMap[name]);
    });

    return rewards;
}

export function getPaidRewardByName(groups: IGachaBannerRewardsGroup[], name: string): Nullable<IGachaBannerReward> {
    for (let i = 0; i < groups.length; i++) {
        const rewardsMap = arrayToObjectByKey(groups[i].rewardBundles, 'name');
        if (rewardsMap[name]) {
            return rewardsMap[name];
        }
    }

    return null;
}

export function prepareMysteryBundlesForState(banners: MercatorMysteryContentState) {
    Object.values(banners).forEach((banner: IGachaBanner) => {
        banner.rewardsGroups.forEach((group) => {
            group.rewardBundles.forEach((bundle) => {
                bundle.rarity = group.rarity;
            });
        });
    });
    return banners;
}

export function getPrimaryReward(items: IGachaBannerInvoiceItem[]) {
    return items.find((item) => item.isPrimary);
}
