import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './TopPanel.scss';
import TopPanelHeader from './TopPanelHeader';
import Wallet from '~/components/Wallet/Wallet';
import { getUserId } from '~/utils/settings';
import classNames from 'classnames';
import { isEmptyObject, isMobileOrTabletWindow } from '~/utils/utils';
import { RootState, useAppSelector } from '~/Store';

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        categories: state.app.categories,
        menu: state.app.menu,
        bundles: state.app.bundles,
    };
};

const TopPanel = function () {
    const state = useAppSelector(stateSelector, equal);
    const refMobileTopPanel: React.RefObject<HTMLDivElement> = React.useRef(null);
    const currentPage = state.currentPage;
    const category = state.categories[currentPage?.name];
    const currentCategoryConfig = state.menu[currentPage?.name];
    const currentBundle = state.bundles[state.currentPage?.bundleId];
    const hasVideoBackground = !isMobileOrTabletWindow && (!isEmptyObject(currentBundle?.videoBackground) || !isEmptyObject(category?.videoBackground));

    React.useEffect(() => {
        if (isMobileOrTabletWindow) {
            let pos = 0;
            let timeout: any;

            const changePosition = (isFixed?: boolean) => {
                clearTimeout(timeout);

                if (!isFixed) {
                    refMobileTopPanel.current?.classList.remove(styles.fixed, styles.visible);
                } else {
                    refMobileTopPanel.current?.classList.add(styles.fixed);
                    timeout = setTimeout(() => {
                        clearTimeout(timeout);
                        refMobileTopPanel.current?.classList.add(styles.visible);
                    }, 0);
                }
            };

            document.addEventListener('scroll', () => {
                const top: number = document.documentElement.scrollTop;
                if (!pos) {
                    if (top >= 160) {
                        pos = top;
                        changePosition();
                    }
                } else {
                    if (top < pos) {
                        changePosition(true);
                        pos = top;
                    } else {
                        changePosition();
                        pos = top;
                    }
                }

                if (top <= 160) {
                    changePosition();
                    pos = top;
                }
            });
        }
    }, []);

    if (!state.currentPage) {
        return null;
    }

    const classesTopPanel = classNames(styles.topPanel, {
        [styles.walletShadow]: hasVideoBackground,
        [styles.withLabel]: !isEmptyObject(currentCategoryConfig?.label),
    });

    return (
        <React.Fragment>
            <div className={styles.topPanelMobile} ref={refMobileTopPanel}>
                {getUserId() && <Wallet isMobile withoutAnimation />}
            </div>
            <div className={classesTopPanel}>
                <TopPanelHeader />
                <div className={styles.topPanelWallet}>{getUserId() && <Wallet />}</div>
            </div>
        </React.Fragment>
    );
};

export default TopPanel;
