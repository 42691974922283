import classNames from 'classnames';
import * as React from 'react';
import { BUNDLE_TYPE } from '~/utils/bundles';
import styles from './BundleType.scss';

interface IBundleType {
    item: IItemCommonData;
    className?: string;
    size?: 'small' | 'default';
}

const BundleType = ({ item, className, size }: IBundleType) => {
    const handler = BUNDLE_TYPE[item.type];
    if (!handler) {
        return null;
    }

    return <div className={classNames(styles.wrapper, styles[size], className)}>{handler(item)}</div>;
};

export default BundleType;
