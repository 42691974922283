import classNames from 'classnames';
import * as React from 'react';
import type { Swiper as SwiperInstance } from 'swiper/types';
import Carousel from '~/components/Carousel/Carousel';
import ProgressBar from '~/components/ProgressBar/ProgressBar';
import { getValuableBundleIndexes, isAllSequencePurchased } from '~/utils/bundles';
import { openBundleById } from '~/utils/category';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { SEPARATE_LIMIT } from '../Bundle/Serial/SerialBundleCarousel';
import PurchasedTooltip from '../Tooltip/PurchasedTooltip';
import { ItemsContainsTooltip } from '../Tooltip/Tooltips';
import styles from './SerialItemsSmallCarousel.scss';
import SerialItemsSmallCarouselItem from './SerialItemsSmallCarouselItem';
import debounce from '~/utils/debounce';
import { playTabClickSound } from '~/api/WoWsClient';

interface ISerialItemsSmallCarousel {
    bundle: IBundle;
    sequence: IBundle[];
    availableBundle: IBundle;
}

const SerialItemsSmallCarousel = ({ bundle, sequence, availableBundle }: ISerialItemsSmallCarousel) => {
    const [swiper, setSwiperRef] = React.useState<SwiperInstance>(null);
    const carouselRef = React.useRef<HTMLDivElement>(null);
    const valuableItemRef = React.useRef<HTMLDivElement>(null);
    const valuableIndexes = getValuableBundleIndexes(sequence);
    const lastBundle = sequence.at(valuableIndexes.at(-1) - 1);
    const lastValuableBundle = !isMobileOrTabletWindow && valuableIndexes?.length && !lastBundle?.nextBundle && lastBundle;

    const items = sequence.map((_bundle, index) => (
        <SerialItemsSmallCarouselItem
            key={`serial_bundle_${_bundle.id}`}
            bundle={_bundle}
            isShiny={valuableIndexes.includes(index + 1)}
            showDiscount={true}
            className={classNames(styles.carouselItem, styles.item, {
                [styles.notSelected]: _bundle.id !== bundle.id,
            })}
            onClick={(_bundle) => {
                openBundleById(_bundle.id);
            }}
        />
    ));

    const tooltips = sequence.map((_bundle) => {
        return _bundle.isPurchased ? <PurchasedTooltip bundle={_bundle} /> : <ItemsContainsTooltip bundle={_bundle} limit={3} />;
    });

    if (lastValuableBundle) {
        items.pop();
    }

    const classesCarousel = classNames(styles.carousel, {
        [styles.withValuable]: lastValuableBundle,
    });

    const _isAllSequencePurchased = isAllSequencePurchased(sequence);
    let currentProgress = availableBundle?.serialIndex || 0;
    if (_isAllSequencePurchased) {
        currentProgress = sequence.length;
    }

    React.useLayoutEffect(() => {
        function setSizes() {
            if (swiper && !!valuableItemRef?.current && items.length <= 7 && !isMobileOrTabletWindow) {
                const progressBarItem = document.querySelectorAll(`.${styles.separateItem}`)[0] as HTMLDivElement;
                valuableItemRef.current.style.width = `${progressBarItem.offsetWidth}px`;
                carouselRef.current.style.width = `calc(100% - ${progressBarItem.offsetWidth}px)`;
                swiper.updateSize();
            }
        }

        const setSizesDebounced = debounce(setSizes, 200);
        setSizes();
        window.addEventListener('resize', setSizesDebounced, { passive: true });
        return () => window.removeEventListener('resize', setSizesDebounced);
    }, [swiper]);

    return (
        <>
            <div className={styles.carouselWrapper}>
                <div
                    className={classesCarousel}
                    ref={(_ref) => {
                        if (_ref) {
                            carouselRef.current = _ref;
                        }
                    }}
                >
                    <Carousel
                        className={{ item: styles.item }}
                        centeredSlides={true}
                        scrollToId={bundle.serialIndex}
                        centeredSlidesBounds={true}
                        freeMode={true}
                        items={items}
                        hideControls
                        setSwiperRef={(swiper) => setSwiperRef(swiper)}
                        withoutLazy={items.length < 20}
                    />
                </div>
                {!!lastValuableBundle && (
                    <div
                        className={styles.valuableItem}
                        ref={(_ref) => {
                            if (_ref) {
                                valuableItemRef.current = _ref;
                            }
                        }}
                    >
                        <SerialItemsSmallCarouselItem
                            bundle={lastValuableBundle}
                            isShiny={true}
                            className={styles.item}
                            showDiscount={true}
                            onClick={(bundle) => {
                                openBundleById(bundle.id);
                            }}
                        />
                    </div>
                )}
            </div>
            <div className={styles.progressBarWrapper}>
                <ProgressBar
                    itemTooltips={tooltips}
                    currentValue={currentProgress}
                    valuableIndexes={valuableIndexes}
                    maxValue={sequence.length}
                    separateLimit={SEPARATE_LIMIT}
                    selectedIndex={bundle.serialIndex}
                    className={{
                        seperateItem: styles.separateItem,
                    }}
                    onClickHandler={(index) => {
                        playTabClickSound();
                        openBundleById(sequence[index]?.id);
                    }}
                />
            </div>
        </>
    );
};

export default SerialItemsSmallCarousel;
