import { Processing } from '@wg/wows-react-uikit';
import styles from './ProgressionRewardsLoader.scss';

const ProgressionRewardsLoader = () => {
    return (
        <div className={styles.loader}>
            <Processing isFetching />
        </div>
    );
};

export default ProgressionRewardsLoader;
