import * as React from 'react';
import styles from './CategoryFilter.scss';
import equal from 'fast-deep-equal/react';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { useDispatch } from 'react-redux';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { disableBodyScroll as addHiddenScrollToBody } from '~/utils/dom';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import FiltersPopup from '~/components/CategoryFilter/FiltersPopup';
import { changeFilterSettings } from '~/api/account';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { scrollTop } from '~/utils/scrollTop';
import { isEnabledDefaultFilterPosition, saveFilterPositionToLocalStorage } from '~/utils/filters';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import useWindowResize from '~/hooks/useWindowResize';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES, PLACEMENT } from '~/components/WelcomePage/steps';
import { FilterPosition } from '~/types/category';
import { playDropdownClickSound } from '~/api/WoWsClient';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

export interface IFilter {
    category: ICategoryList;
    onChangeVisible?: (isVisible: boolean) => void;
    withoutActiveFilters?: boolean;
}

const stateSelector = (state: RootState) => {
    return {
        categories: state.app.categories,
        activePreset: state.app.activePreset,
        filtersPosition: state.app.filterPosition,
        accountId: state.account.id,
        accountFilterPosition: state.account.filterPosition,
        disabledCategories: state.app.disabledCategories,
    };
};

const filterTitle = t('Фильтры');

let prevFilterPosition: FilterPosition = null;

const CategoryFilter = (props: IFilter) => {
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    const refFilterPopup = React.useRef(null);
    const refFilterButton = React.useRef(null);
    const state = useAppSelector(stateSelector, equal);
    const isDisplayRight = state.filtersPosition === FilterPosition.RIGHT;
    const isDisplayDefault = state.filtersPosition !== FilterPosition.RIGHT;
    const [isVisible, setVisible] = React.useState(!isDisplayDefault || prevFilterPosition === FilterPosition.RIGHT);

    const changeVisible = () => {
        if (isDisplayRight) {
            return;
        }

        if (isMobileOrTabletWindow) {
            if (!isVisible) {
                addHiddenScrollToBody(true);
                disableBodyScroll(document.querySelectorAll(`.${styles.columns}`)[0]);
            } else {
                addHiddenScrollToBody(false);
                clearAllBodyScrollLocks();
            }

            document.location.hash = !isVisible ? 'filters' : '';
        }

        setVisible(!isVisible);
        props.onChangeVisible && props.onChangeVisible(!isVisible);
    };

    const filterHeadClassNames = classNames(styles.filterHeader, {
        [styles.active]: isVisible,
    });

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            RefManager.setRef(RefManagerKeys.Filters, _ref);
            ref.current = _ref;
        }
    };

    React.useEffect(() => {
        if (isMobileOrTabletWindow) {
            window.addEventListener('popstate', (event) => {
                setVisible(false);
            });
        }

        if (state.filtersPosition === FilterPosition.RIGHT && !isEnabledDefaultFilterPosition()) {
            dispatch(appActions.changeFiltersPosition(FilterPosition.TOP));
        }

        function handleClickOutside(event?: React.KeyboardEvent) {
            const target = event.target as HTMLDivElement;
            if (target === refFilterButton.current || isDisplayRight) {
                return;
            }

            if (event.which === 1 && refFilterPopup.current && !refFilterPopup.current.contains(event.target)) {
                setVisible(false);
            }
        }

        const _callback = handleClickOutside.bind(this);

        document.addEventListener('mousedown', _callback);

        return () => {
            prevFilterPosition = null;
            document.removeEventListener('mousedown', _callback);
        };
    }, []);

    useWindowResize(() => {
        const { offsetWidth } = document.body;
        if (offsetWidth <= 1919) {
            dispatch(appActions.changeFiltersPosition(FilterPosition.TOP));
        }
    });

    const _changeFilterPosition = (position: FilterPosition) => {
        prevFilterPosition = position === FilterPosition.TOP ? FilterPosition.RIGHT : FilterPosition.TOP;

        scrollTop(0, true);

        if (state.accountId) {
            dwhExport.send(DWH_EVENTS.FILTERS_POSITION, {
                position: position,
                category: props.category,
            });
            changeFilterSettings(position);
        }

        saveFilterPositionToLocalStorage(position);
        dispatch(appActions.changeFiltersPosition(position));
    };

    const classesFilterPositionTop = classNames(styles.filterColumnTop, { [styles.active]: isDisplayDefault });
    const classesWrapper = classNames(styles.filterWrapper, { [styles.displayRight]: isDisplayRight });
    const classesFilterIcon = classNames(styles.filterIcon, { [styles.right]: isDisplayRight });
    const isDisabledCategory = state.disabledCategories.includes(props.category);

    if (isDisabledCategory) return null;

    return (
        <GuideDecorator
            names={[GUIDE_NAMES.guide_filters_and_presets]}
            placement={
                state.filtersPosition === FilterPosition.RIGHT
                    ? PLACEMENT.LEFT
                    : {
                          0: PLACEMENT.RIGHT,
                          1366: PLACEMENT.BOTTOM,
                      }
            }
            centerBeacon={state.filtersPosition !== FilterPosition.RIGHT}
            key={`decorator_${state.filtersPosition}`}
        >
            <div className={classesWrapper} ref={setRef}>
                <div className={filterHeadClassNames}>
                    <div
                        className={classesFilterIcon}
                        onClick={() => {
                            if (!isDisplayRight) {
                                playDropdownClickSound();
                            }
                            changeVisible();
                        }}
                        ref={refFilterButton}
                    />
                    {isDisplayRight && (
                        <DivTooltip tooltipBody={<DefaultTooltip text={t('Отображать фильтры сверху')} />}>
                            <div
                                className={classesFilterPositionTop}
                                onClick={(event) => {
                                    playDropdownClickSound();
                                    _changeFilterPosition(FilterPosition.TOP);
                                }}
                            />
                        </DivTooltip>
                    )}
                </div>
                {isVisible && isDisplayRight && <FiltersPopup onChangeVisible={changeVisible} category={props.category} />}
            </div>
            {isVisible && isDisplayDefault && (
                <FiltersPopup onChangeVisible={changeVisible} category={props.category} ref={refFilterPopup} isDisplayDefault={isDisplayDefault} changeFilterPosition={_changeFilterPosition} />
            )}
        </GuideDecorator>
    );
};

export default CategoryFilter;
