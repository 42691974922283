import * as React from 'react';
import styles from './IframePopup.scss';
import { useDispatch } from 'react-redux';
import IframeProxy, { ON_ESC } from '~/api/IframeProxy';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { playButtonClickSound } from '~/api/WoWsClient';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { appActions } from '~/Store/appSlice';

const IframePopup = () => {
    const { popup, isPopupActive } = React.useContext(PopupManagerContext);
    const dispatch = useDispatch();
    const ref = React.useRef<HTMLIFrameElement>(null);
    const [isVisiblePopup, setVisiblePopup] = React.useState<boolean>(false);

    const close = () => {
        dispatch(appActions.changeVisibilityPopup({ name: null }));
        dispatch(appActions.setFetching(false));
    };

    React.useEffect(() => {
        dispatch(appActions.setFetching(true));

        const _Proxy = new IframeProxy(
            dispatch,
            ref.current,
            () => {
                setVisiblePopup(true);
            },
            {
                [ON_ESC]: close,
            },
        );

        _Proxy.listen();

        return () => {
            _Proxy.remove();
        };
    }, []);

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        playButtonClickSound();
        close();
    }, [KEYS_CODE.ESC]);

    const setRef = (_ref: HTMLIFrameElement) => {
        if (_ref) {
            ref.current = _ref;
        }
    };

    const style = {
        display: isVisiblePopup ? 'block' : 'none',
    };

    if (!popup?.data?.iframeUrl) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.close}>
                <ButtonEsc onClick={close} />
            </div>
            <iframe src={popup.data.iframeUrl} ref={setRef} style={style} />
        </div>
    );
};

export default IframePopup;
