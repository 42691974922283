import * as React from 'react';
import BundlePageLabels from '~/Layouts/BundlePage/BundlePageLabels/BundlePageLabels';
import BundlePageHeader from '~/Layouts/BundlePage/BundlePageHeader/BundlePageHeader';
import styles from '~/Layouts/BundlePage/BundlePage.scss';
import BundlePageImage from '~/Layouts/BundlePage/BundlePageImage/BundlePageImage';
import BundlePageDescription from '~/Layouts/BundlePage/BundlePageDescription/BundlePageDescription';
import classNames from 'classnames';
import { changeScrollableBundleBackgroundSize } from '~/Layouts/BundlePage/ScrollableBundleBackground/ScrollableBundleBackground';
import useScroll from '~/hooks/useScroll';

interface IBundlePageLeftContent {
    bundle: IBundle;
    className?: string;
    bundles: IBundleList;
    expanded?: boolean;
    isScrollableTemplate?: boolean;
    onChangeExpand?: (value: boolean) => void;
}

const BundlePageLeftContent = React.forwardRef(({ bundle, className, bundles, expanded, isScrollableTemplate, onChangeExpand }: IBundlePageLeftContent, ref: React.RefObject<HTMLDivElement>) => {
    const [isExpanded, toExpand] = React.useState<boolean>(isScrollableTemplate ?? false);
    const hasGallery = !isScrollableTemplate && !!bundle.gallery?.length;

    React.useEffect(() => {
        if (!isScrollableTemplate) {
            return;
        }
        toExpand(expanded);
        expanded ? expand() : rollUp();
    }, [expanded]);

    React.useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                ref.current.style.transition = 'all 1.3s ease-in-out';
            }
        }, 0);
    }, []);

    useScroll(
        ref,
        (event) => {
            const descriptions = ref.current;
            if (!ref.current) {
                return;
            }

            if (descriptions.scrollHeight <= Math.ceil(descriptions.scrollTop + descriptions.offsetHeight)) {
                descriptions.classList.remove(styles.mask);
            } else {
                descriptions.classList.add(styles.mask);
            }

            descriptions.classList.add('onScroll');

            if (descriptions.scrollTop >= 1) {
                if (descriptions.offsetTop >= 15) {
                    descriptions.scrollTop = 1;
                    descriptions.style.pointerEvents = 'none';
                    setTimeout(() => {
                        toExpand(true);
                        descriptions.style.pointerEvents = 'initial';
                    }, 400);
                }
                expand();
                onChangeExpand?.(true);
            }
        },
        () => {
            ref.current?.classList.remove('onScroll');
            setTimeout(() => {
                if (ref.current && ref.current.offsetTop <= 15) {
                    ref.current.style.pointerEvents = 'initial';
                }
            }, 400);
        },
        [isScrollableTemplate],
    );

    const classesLeftContent = classNames(
        styles.leftContent,
        {
            [styles.withGallery]: hasGallery,
            [styles.initialHeight]: isScrollableTemplate,
            [styles.variablePrice]: bundle.originalProduct?.isVariablePriceProduct,
        },
        className,
    );

    function expand() {
        toExpand(true);
        changeScrollableBundleBackgroundSize(true);
    }

    function rollUp() {
        toExpand(false);
        if (ref.current) {
            ref.current.scrollTop = 0;
        }
        changeScrollableBundleBackgroundSize();
    }

    return (
        <div className={classesLeftContent} ref={ref}>
            <BundlePageLabels bundle={bundle} />
            <BundlePageHeader bundle={bundle} bundles={bundles} />
            <div className={styles.mobileImageWrapper}>
                <BundlePageImage bundle={bundle} />
            </div>
            <BundlePageDescription bundle={bundle} />
        </div>
    );
});

export default BundlePageLeftContent;
