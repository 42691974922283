import { sort } from 'fast-sort';
import { items as ITEMS_TYPES } from '@wg/wows-entities/const';
import { isCamouflage, isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import { isEnabledPortForItemInsideBundle, isEnabledPortByItem, openPortByItem, openPortFromAuction } from '~/settings/port';
import { getValueFromInventory } from '~/utils/inventory';
import Account from '~/account/Account';

export enum PortPreviewType {
    BUNDLE = 'bundle',
    CONTAINER = 'container',
    DEFAULT = 'default',
    AUCTION_LOT = 'auction_lot',
    SALVAGE = 'salvage',
    GACHA = 'gacha',
}

export interface IPortContains {
    entitlements: IItemCommonData[];
    portSubRoute: string | number; // PortPreviewType subcategory/event/id
    title?: string | JSX.Element;
    sourceUrl?: string;
}

export const ARMORY_PORT_TYPES = [PortPreviewType.BUNDLE, PortPreviewType.CONTAINER, PortPreviewType.DEFAULT];

export const isArmoryPort = (portPreviewType: PortPreviewType) => {
    return ARMORY_PORT_TYPES.includes(portPreviewType);
};

export const sortBundleItems = (bundle: IBundle): IBundleEntity[] => {
    if (!bundle.entitlements) {
        return [];
    }
    return sort([...bundle.entitlements]).by([
        {
            desc: (i) => isEnabledPortForItemInsideBundle(bundle, i),
        },
        {
            asc: (i) => bundle.entitlements.findIndex((item) => item.identifier === i.identifier),
        },
    ]);
};

export const sortLotRewardsItems = (rewards: IAuctionLotItem[]): IAuctionLotItem[] => {
    return sort(rewards).desc((item) => {
        return isEnabledPortByItem(item.type, item.id);
    });
};

export const sortCommonDataItems = (items: IItemCommonData[]): IItemCommonData[] => {
    return sort(items).desc((item) => {
        return isEnabledPortByItem(item.type, item.identifier, item.customisation?.shipId || item.shipId);
    });
};

export interface IPortContainItem {
    itemId: number;
    id: number;
    type: string;
    amount: number;
    isBonus?: boolean;
    customisation: {
        shipId: number;
        points: number;
    };
    isEnabledPortPreview?: boolean;
    portPreviewOnClick?: (portPreviewType?: PortPreviewType) => void;
}

export const prepareItems = (type: PortPreviewType, data: IBundle | ILot | IPortContains, inventory?: InventoryState): IPortContainItem[] => {
    if (!data) {
        return [];
    }

    switch (type) {
        case PortPreviewType.BUNDLE:
        case PortPreviewType.DEFAULT:
        case PortPreviewType.CONTAINER: {
            const bundle = data as IBundle;
            return sortBundleItems(bundle).map((item) => {
                const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(item?.type, item?.identifier, item.customisation?.shipId);

                return {
                    itemId: item.identifier,
                    id: item.identifier,
                    type: item.type,
                    amount: item.amount,
                    isBonus: item.isBonus,
                    customisation: {
                        points: item.customisation?.points,
                        shipId: item.customisation?.shipId,
                        inventoryCount: !item.isUnique && inventoryCount,
                        isExistsInInventory: item?.isUnique && isExistsItemInInventory,
                    },
                    isEnabledPortPreview: isEnabledPortForItemInsideBundle(bundle, item),
                    portPreviewOnClick: (portPreviewType) => {
                        openPortByItem({
                            item: { identifier: item.identifier, type: item.type, shipId: item.customisation?.shipId },
                            bundleId: bundle.id,
                            isVisiblePort: true,
                            portPreviewType,
                        });
                    },
                };
            });
        }

        case PortPreviewType.AUCTION_LOT: {
            const lot = data as ILot;
            return sortLotRewardsItems(lot.entitlements).map((reward) => {
                return {
                    itemId: reward.id,
                    id: reward.id,
                    type: reward.type,
                    amount: reward.amount,
                    customisation: {
                        shipId: reward.customisation?.ship_id,
                        points: reward.customisation?.points,
                        inventoryCount: getValueFromInventory(inventory, reward.type, reward.id),
                    },
                    isEnabledPortPreview: isEnabledPortByItem(reward.type, reward.id),
                    portPreviewOnClick: () => {
                        if (reward.type === ITEMS_TYPES.VEHICLES) {
                            openPortFromAuction(reward.type, reward.id, -1, lot.id);
                        } else if (isPermanentCamouflage(reward.type) || isCamouflage(reward.type)) {
                            openPortFromAuction(reward.type, reward.customisation?.ship_id, reward.id, lot.id);
                        }
                    },
                };
            });
        }

        case PortPreviewType.SALVAGE: {
            const portContains = data as IPortContains;
            if (!portContains?.entitlements) {
                return [];
            }

            return sortCommonDataItems(portContains.entitlements).map((item) => {
                const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(item?.type, item?.identifier, item.customisation?.shipId);
                const shipId = item.customisation?.shipId || item.shipId;

                return {
                    id: +item.identifier,
                    itemId: +item.identifier,
                    type: item.type,
                    amount: item.amount,
                    customisation: {
                        shipId: item.shipId,
                        points: item.customisation?.points,
                        inventoryCount: !item.isUnique && inventoryCount,
                        isExistsInInventory: item?.isUnique && isExistsItemInInventory,
                    },
                    isEnabledPortPreview: isEnabledPortByItem(item.type, item.identifier, shipId),
                    portPreviewOnClick: () => {
                        openPortByItem({
                            item: {
                                identifier: +item.identifier,
                                type: item.type,
                                shipId,
                            },
                            categoryExternalCode: String(portContains.portSubRoute),
                            portPreviewType: type,
                            additionalData: {
                                portContains,
                            },
                            sourceUrl: portContains.sourceUrl,
                        });
                    },
                };
            });
        }

        default:
            return null;
    }
};

export const getItemById = (items: IPortContainItem[], id: number, shipId?: number): IPortContainItem => {
    return items.find((item) => {
        const hasSameID = item.id?.toString() === id?.toString();

        if (shipId && item.customisation?.shipId) {
            return hasSameID && item.customisation?.shipId.toString() === shipId.toString();
        }
        return hasSameID;
    });
};

export const getTotalCountPreviewItems = (items: IPortContainItem[]) => {
    return items.reduce((count: number, item) => {
        if (item.isEnabledPortPreview) {
            count += 1;
        }
        return count;
    }, 0);
};
