import store from '~/Store';
import { fetchFeatures } from '~/Store/shipFeaturesSlice';
import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import { settings } from '~/utils/settings';

export default class FeaturesPreloader extends AbstractPreloader {
    async load(): Promise<any> {
        if (!settings.disableShipFeatures) {
            store.dispatch(fetchFeatures());
        }
        return Promise.resolve(1);
    }
}
