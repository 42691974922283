import * as React from 'react';
import styles from './GiftPromo.scss';
import { t } from '~/utils/localization';
import Button, { ButtonVariant } from '~/components/Button/Button';
import { useDispatch } from 'react-redux';
import { isEmptyObject, redirectToLogin } from '~/utils/utils';
import { getSupportedVideo } from '~/utils/video';
import { isNeedToShowWelcomeGiftScreen, markShownWelcomeGiftScreen } from '~/utils/gift';
import { appActions } from '~/Store/appSlice';
import Account from '~/account/Account';

interface IGiftIntroWidget {
    onClick: () => void;
    gift: IGift;
}

const GiftIntroWidget = ({ onClick, gift }: IGiftIntroWidget) => {
    const dispatch = useDispatch();
    const introContentRef = React.useRef<HTMLDivElement>();
    const isGuest = !Account?.getAccount()?.id;

    const onClickHandler = () => {
        if (isGuest) {
            markShownWelcomeGiftScreen(gift.name);
            return redirectToLogin();
        }
        if (!isEmptyObject(gift.entryVideo)) {
            const videoUrl = getSupportedVideo(gift.entryVideo);
            if (videoUrl) {
                dispatch(
                    appActions.startVideo({
                        name: `gift_${gift?.name}`,
                        url: videoUrl,
                        fadeOutVideoInSeconds: 0,
                    }),
                );
                const timeout = setTimeout(() => {
                    clearTimeout(timeout);
                    onClick?.();
                }, 1000);
                return;
            }
        }
        onClick?.();
    };

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            introContentRef.current = _ref;
        }
    };

    const onMouseEnter = () => {
        if (!introContentRef.current) {
            return;
        }
        introContentRef.current.classList.add(styles.hover);
    };

    const onMouseLeave = () => {
        if (!introContentRef.current) {
            return;
        }
        introContentRef.current.classList.remove(styles.hover);
    };

    if (isGuest && !isNeedToShowWelcomeGiftScreen(gift.name)) {
        return <></>;
    }

    return (
        <React.Fragment>
            <div className={styles.introWrapper}>
                <div className={styles.introContent} ref={setRef} style={{ backgroundImage: `url(${gift.icons.medium})` }}>
                    <div className={styles.animationWrapper}></div>
                    <div className={styles.introBody}>
                        <div className={styles.intoInfo} />
                    </div>
                </div>
            </div>
            <div className={styles.introButtons} onClick={onClickHandler} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <Button variant={ButtonVariant.orange} className={styles.introButton} label={t('Забрать подарок')} />
            </div>
        </React.Fragment>
    );
};

export default GiftIntroWidget;
