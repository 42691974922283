import { arrayToObjectByKey, isEmptyObject } from '~/utils/utils';
import { interpolate } from '~/utils/localization';
import store from '~/Store';
import VortexDataManager from '~/core/VortexDataStorage';
import { SectionNames } from '~/core/ManagerData';

export const prepareCurrenciesForState = (vortexCurrencies: IVortexCurrency[]) => {
    if (isEmptyObject(vortexCurrencies)) {
        return [];
    }

    const currencies = window.metashop.state.content.currencies;
    const map = arrayToObjectByKey(vortexCurrencies, 'name');

    const final: Currency[] = [];

    currencies.forEach((currency) => {
        const fromVortex = map[currency.name] || null;

        const description =
            currency.description &&
            interpolate(
                currency.description,
                {
                    tag_open: `<b>`,
                    tag_close: `</b>`,
                },
                true,
                false,
            );

        final.push({
            title: currency.title || fromVortex?.title,
            description: description,
            name: currency.name,
            icons: {
                small: currency.icon || fromVortex?.icons?.small,
                default: currency.icon || fromVortex?.icons?.default,
                large: fromVortex?.icons?.large,
            },
            hiddenIfAbsent: currency.hiddenIfAbsent,
            showDescriptionInPriceTooltip: currency.showDescriptionInPriceTooltip,
            isEvent: currency.isEvent,
            isDefault: currency.isDefault,
            isHidden: currency.isHidden,
            tooltipDescription: fromVortex?.tooltipDescription,
            tooltipStatusInfo: fromVortex?.tooltipStatusInfo,
            tooltipGetDescription: fromVortex?.tooltipGetDescription,
        });
    });

    return final;
};

export const getCurrency = (name: string): Currency => {
    const app = store.getState().app;
    const storageCurrencies = VortexDataManager.getDataBySection(SectionNames.currency) as IVortexCurrency[];
    const currencies = app.currencies || arrayToObjectByKey(storageCurrencies || [], 'name');

    return (currencies[name] || {
        title: null,
        description: null,
        name: null,
        icons: {
            small: null,
            default: null,
        },
    }) as Currency;
};

export const isCurrency = (currencyName: string) => {
    return !!getCurrency(currencyName)?.name;
};

export const hasCurrencyInCache = (currencyName: string) => {
    const storageCurrencies = VortexDataManager.getDataBySection(SectionNames.currency) as IVortexCurrency[];
    const currencies = arrayToObjectByKey(storageCurrencies || [], 'name');
    return !!currencies[currencyName];
};
