import * as React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { playButtonClickSound } from '~/api/WoWsClient';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS, FILTER_CURRENCY_NAME } from '~/const';
import { scrollTop } from '~/utils/scrollTop';
import { flat, isEmptyObject } from '~/utils/utils';
import ActiveFilters, { ActiveFiltersType } from '~/components/Filters/ActiveFilter';
import { RootState, useAppSelector } from '~/Store';
import { appActions, updateCategoryByFilters } from '~/Store/appSlice';

interface IActiveCategoryFilters {
    category: ICategoryList;
    className?: string;
}

const stateSelectorActiveFilters = (state: RootState) => {
    return {
        filters: state.app.filters,
        disabledCategories: state.app.disabledCategories,
        diapason: state.app.filtersDiapasonCurrency,
        pricesInfo: state.app.categoryBundlesPricesInfo,
        facetCurrenciesState: state.app.facetCurrenciesState,
    };
};

const ActiveCategoryFilters = ({ category, className }: IActiveCategoryFilters) => {
    const dispatch = useDispatch();
    const { disabledCategories, filters, diapason, facetCurrenciesState, pricesInfo } = useAppSelector(stateSelectorActiveFilters, shallowEqual);
    const isDisabledCategory = disabledCategories.includes(category);
    const activeFilters = React.useMemo<ActiveFiltersType>(() => {
        if (!filters?.[category] || !flat(Object.values(filters?.[category])).length) {
            return {};
        }
        return Object.keys(filters?.[category]).reduce((accumulator: ActiveFiltersType, filterKey: FILTER_INFO_NAME) => {
            if (!accumulator[filterKey]) {
                accumulator[filterKey] = { values: [], diapasons: {} };
            }
            filters[category][filterKey].forEach((value: string) => {
                if (filterKey === FILTER_CURRENCY_NAME) {
                    accumulator[filterKey].values.push(value);
                    const currencyDiapason = diapason?.[category]?.[value];
                    const defaultValues = pricesInfo?.[category]?.[value];
                    const facetCurrenciesDiapason = facetCurrenciesState?.[category]?.[value] as IRange;
                    let { min, max } = facetCurrenciesDiapason || defaultValues;

                    if (currencyDiapason?.min && currencyDiapason?.max) {
                        [min, max] = [currencyDiapason.min, currencyDiapason.max];
                    }

                    accumulator[filterKey].diapasons[value] = { max, min };
                } else {
                    accumulator[filterKey].values.push(value);
                }
            });
            return accumulator;
        }, {});
    }, [filters?.[category], diapason, facetCurrenciesState]);

    if (isEmptyObject(activeFilters) || isDisabledCategory) {
        return null;
    }

    const resetAll = (event: React.MouseEvent) => {
        event.stopPropagation();
        playButtonClickSound();
        dispatch(appActions.resetCategoryFilter({ category: category }));
        dwhExport.send(DWH_EVENTS.FILTERS_RESET, { category: category });
        scrollTop(0, true);
    };

    const resetFilter = (filterName: FILTER_INFO_NAME, value: any) => {
        scrollTop(0, true);
        dispatch(appActions.changeFilter({ category, name: filterName, value }));
        dispatch(updateCategoryByFilters(category, filterName, value));
    };

    return <ActiveFilters className={className} activeFilters={activeFilters} resetAll={resetAll} onClick={resetFilter} />;
};

export default ActiveCategoryFilters;
