import { GachaTransactionResponse, IGachaAccountStateResponse, IGachaPurchaseResponse } from '~/types/gacha';
import { get, post } from '~/utils/ajax';
import { getAnalyticsData } from '~/utils/analytics';
import { settings } from '~/utils/settings';

export async function roll(bundleName: string, quantity: number): Promise<IGachaPurchaseResponse> {
    return await post(settings.urls.mercator.mysteryBundlePurchaseUrl, { bundleName, quantity, source: getAnalyticsData() }, {}, false, true, true, {
        credentials: 'include',
    });
}

export async function getTransaction(transactionId: string): Promise<GachaTransactionResponse> {
    return await get(settings.urls.mercator.mysteryBundleTransactionsUrl.replace('%id%', transactionId), null, false, true, true, {
        credentials: 'include',
    });
}

export async function getAccountState(): Promise<IGachaAccountStateResponse> {
    return await get(settings.urls.mercator.mysteryBundleAccountState, null, false, true, true, {
        credentials: 'include',
    });
}
