import styles from './MainFeaturesLayout.scss';
import classNames from 'classnames';
import equal from 'fast-deep-equal/react';
import { useAppSelector } from '~/Store';

interface IMainFeaturesLayout {
    shipId?: number;
    className?: string;
    features?: IFeatureItem[];
    circleSize?: string;
    lineClamp?: boolean;
    detailedFeatures?: boolean;
}

const MainFeaturesLayout = ({ shipId, className, features, circleSize, lineClamp, detailedFeatures }: IMainFeaturesLayout) => {
    const shipFeatureMap = useAppSelector((state) => state.features.shipFeatureMap, equal);
    let currentFeature;

    if (shipId) {
        currentFeature = shipFeatureMap[shipId]?.mainFeatures;
    } else if (features) {
        currentFeature = features;
    }

    if (!currentFeature) {
        return null;
    }

    return (
        <>
            {currentFeature.map((feature: IFeatureItem, key) => {
                return (
                    <div
                        className={classNames(styles.feature, className, {
                            [styles.detailedFeature]: detailedFeatures,
                        })}
                        key={key}
                    >
                        <div className={classNames(styles.featureCategory, styles[feature.category], circleSize)} />
                        <div className={styles.featureText}>
                            <div
                                className={classNames(styles.featureTitle, {
                                    [styles.lineClampOne]: lineClamp && currentFeature.length > 3,
                                    [styles.lineClampTwo]: lineClamp && currentFeature.length <= 3,
                                    [styles.detailedFeature]: detailedFeatures,
                                })}
                            >
                                {feature.title ?? feature.key}
                            </div>
                            {detailedFeatures && feature.description && <div className={styles.featureDescription}>{feature.description}</div>}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default MainFeaturesLayout;
