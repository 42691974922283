import * as React from 'react';
import styles from './Button.scss';
import classNames from 'classnames';
import SmallPreloader from '~/components/SmallPreloader/SmallPreloader';
import AnimationDecorator from '~/decorators/AnimationDecorator/AnimationDecorator';
import { playButtonClickSound, playButtonDialogClickSound } from '~/api/WoWsClient';
import { IButtonSoundset } from '~/types/soundsets';

export enum ButtonVariant {
    orange = 'orange',
    blue = 'blue',
    specialOrange = 'specialOrange',
}

export interface IButton {
    label: string | React.ReactNode;
    onClick?: (event: React.MouseEvent) => void;
    onFallback?: (event: React.MouseEvent) => void;
    disabled?: boolean;
    className?: string | string[];
    isFull?: boolean;
    isFree?: boolean;
    isWarn?: boolean;
    id?: string;
    autoFocus?: boolean;
    onMouseMove?: (event: React.MouseEvent) => void;
    isFetching?: boolean;
    linkTarget?: boolean;
    isSilent?: boolean;
    soundset?: IButtonSoundset;
    variant?: ButtonVariant;
}

const soundsetsMap: Record<IButtonSoundset, Nullable<DefaultFunction>> = {
    [IButtonSoundset.MUTED]: null,
    [IButtonSoundset.DEFAULT]: () => playButtonClickSound(),
    [IButtonSoundset.POPUP]: () => playButtonDialogClickSound(),
};

const playSoundset = (soundsetName: IButtonSoundset) => {
    soundsetsMap[soundsetName || IButtonSoundset.DEFAULT]?.();
};

const Button = function (props: IButton) {
    const isDisabled = props.disabled || props.isFetching;
    const variantClass = styles[`${props.variant}Button`];

    const buttonClassNames = classNames(
        styles.button,
        variantClass,
        {
            [styles.disabled]: isDisabled,
            [styles.isFull]: props.isFull,
            [styles.isFree]: props.isFree,
            [styles.isFetching]: props.isFetching,
        },
        props.className,
        'armory-decorator__animation',
    );

    const onClick = (event: React.MouseEvent) => {
        event.preventDefault();
        playSoundset(props.soundset);
        props.onClick && props.onClick(event);
    };

    const onFallback = (event: React.MouseEvent) => {
        props.onFallback?.(event);
    };

    const onMouseMove = (event: React.MouseEvent) => {
        props.onMouseMove?.(event);
    };

    return (
        <button tabIndex={-1} className={buttonClassNames} onMouseMove={onMouseMove} onClick={isDisabled ? onFallback : onClick} autoFocus={props.autoFocus} id={props.id}>
            <AnimationDecorator mainColor={props.isFree ? '#E13C3C' : props.variant === ButtonVariant.blue ? '#4EBCEB' : '#b23d03'} isDisabled={isDisabled}>
                {props.isFetching && <SmallPreloader className={styles.fetching} />}
                <span className={styles.label}>{props.label}</span>
                {props.linkTarget && <div className={styles.arrowTarget}></div>}
            </AnimationDecorator>
        </button>
    );
};

export default Button;
