export type GachaBundle = Pick<IBundle, 'id' | 'name'>;

export interface IGachaBannerPrice {
    currency: string;
    amount: number;
}

export interface IGachaBannerCashback {
    currency: string;
    amount: number;
}

export interface IGachaBannerImages {
    big: string;
    mobile: Nullable<string>;
}

export enum GachaBannerGroupRarity {
    free = 'free',
    common = 'common',
    valuable = 'valuable',
    legendary = 'legendary',
}

export type GachaBannerRollAnimation = Record<GachaBannerGroupRarity, IVideo>;

export type GachaBannerRewardCompensation = Record<string, number>;

export interface IGachaBannerInvoiceItem extends IItemCommonData {
    id: number;
    isPrimary?: boolean;
    compensation?: Nullable<GachaBannerRewardCompensation>;
}

export interface IGachaBannerRewardImages {
    medium: string;
    big: string;
    small: string;
}

export interface IGachaBannerDefaultReward {
    name: string;
    title: string;
    items: IGachaBannerInvoiceItem[];
    images: IGachaBannerRewardImages;
    backgroundColor?: string;
    cashback?: Nullable<IGachaBannerCashback>;
    introVideo?: IVideo;
}

export interface IGachaBannerFreeRewards {
    savePoint: number;
    rewardBundles: IGachaBannerDefaultReward[];
}

export interface IGachaBannerReward extends IGachaBannerDefaultReward {
    probability: string;
    rarity?: GachaBannerGroupRarity; // FE field
}

export interface IGachaBannerRewardsGroup {
    title: string;
    description: Nullable<string>;
    savePoint: number;
    probability: string;
    name: string;
    rarity: GachaBannerGroupRarity;
    rewardBundles: IGachaBannerReward[];
    isAllowedCompensation: boolean;
}

export interface IGachaBannerRewardsPreview {
    rarity: GachaBannerGroupRarity;
    reward: IGachaBannerInvoiceItem;
}

export interface IGachaSpine {
    jsonUrl: string;
    atlasUrl: string;
}

export interface IGachaBanner {
    name: string;
    title: string;
    description: string;
    rewardsPreview: IGachaBannerRewardsPreview[];
    price: IGachaBannerPrice;
    cashback: string;
    images: IGachaBannerImages;
    spine: IGachaSpine;
    rollAnimation: GachaBannerRollAnimation;
    quantitySelector: number[];
    freeRewards: IGachaBannerFreeRewards;
    rewardsGroups: IGachaBannerRewardsGroup[];
}

export type GachaCounterState = Record<string, number>;
export type GachaRecievedRewardsState = string[];
export type GachaCompensatedItemIds = number[];

export interface IGachaCurrentFreeReward {
    name: string;
    currentProgress: number;
}

export type GachaAccountFreeRewardState = Record<string, number>;

export interface IGachaAccountState {
    timers: GachaCounterState;
    currentFreeReward: GachaAccountFreeRewardState;
    receivedRewards: GachaRecievedRewardsState;
    compensatedItems: GachaCompensatedItemIds;
}

export type GachaAccountState = Record<string, IGachaAccountState>;
export interface IGachaAccountStateResponse {
    state: GachaAccountState;
    ts: number;
}

export enum TransactionStatus {
    pending = 'pending',
    success = 'success',
    error = 'error',
}

export interface IGachaPendingTransaction {
    status: TransactionStatus.pending;
    ts: number;
}

export interface IGachaRollSuccess {
    status: TransactionStatus.success;
    bundleName: string;
    rewards: string[];
    compensatedItems: GachaCompensatedItemIds;
}

export interface IGachaRollError {
    status: TransactionStatus.error;
    bundleName: string;
}

export type GachaTransactionResponse = IGachaRollSuccess | IGachaRollError | IGachaPendingTransaction;

export interface IGachaPurchaseResponse {
    transactionId: string;
}

export enum AnimationSpinesNames {
    IDLE = 'idle',
    COMMON_ROLL = 'common_roll',
    VALUABLE_ROLL = 'valuable_roll',
    LEGENDARY_ROLL = 'legendary_roll',
}
