import * as React from 'react';
import styles from './CategoryFilter.scss';
import { NumberFormatBase, type OnValueChange } from 'react-number-format';
import { formatNumber, toInt } from '~/utils/utils';
import { playInputSound } from '~/api/WoWsClient';

interface IFilterCurrencyRange {
    min: number;
    max: number;
    currentMin: number;
    currentMax: number;
    onChangeComplete: (value: IRange) => void;
    onFilterApply?: (value: IRange) => void;
    isChecked?: boolean;
    isDisabled: boolean;
}

const CurrencyInputsRange = ({ min, max, currentMax, currentMin, onChangeComplete, isDisabled, isChecked, onFilterApply }: IFilterCurrencyRange) => {
    const refInputMin = React.useRef<HTMLInputElement>(null);
    const refInputMax = React.useRef<HTMLInputElement>(null);
    const [val, setVal] = React.useState<IRange>({ min: currentMin, max: currentMax });
    const typingState = React.useRef({ min: false, max: false });

    const onComplete = React.useCallback(
        (min: number, max: number) => {
            setVal({ min, max });
            if (!isChecked) {
                onFilterApply?.({ min, max });
            } else {
                onChangeComplete({ min, max });
            }

            typingState.current.min = false;
            typingState.current.max = false;
        },
        [isChecked, onChangeComplete, onFilterApply],
    );

    const onBlurMinValue = React.useCallback(() => {
        if (!typingState.current.min) {
            return;
        }

        const value = toInt(refInputMin?.current?.value || '');
        if (!value) {
            setVal({ min, max: currentMax });
        }

        if (value === currentMin) {
            return;
        }

        const maxValue = currentMax || max;

        if (value < min) {
            onComplete(min, maxValue);
        } else if (value > maxValue) {
            onComplete(maxValue, maxValue);
        } else {
            onComplete(value, maxValue);
        }
    }, [currentMax, currentMin, max, min, onComplete]);

    const onBlurMaxValue = React.useCallback(() => {
        if (!typingState.current.max) {
            return;
        }

        const value = toInt(refInputMax?.current?.value || '');
        if (!value) {
            setVal({ min: currentMin, max: max });
        }

        if (value === currentMax) {
            return;
        }

        const minValue = currentMin || min;

        if (value > max) {
            onComplete(minValue, max);
        } else if (value < minValue) {
            onComplete(minValue, minValue);
        } else {
            onComplete(minValue, value);
        }
    }, [currentMax, currentMin, max, min, onComplete]);

    const onMinValueChange: OnValueChange = React.useCallback(
        (values) => {
            typingState.current.min = true;
            setVal({ min: toInt(values.value), max: currentMax });
        },
        [currentMax],
    );

    const onMaxValueChange: OnValueChange = React.useCallback(
        (values) => {
            typingState.current.max = true;
            setVal({ min: currentMin, max: toInt(values.value) });
        },
        [currentMin],
    );

    return (
        <div className={styles.currencyInputsRange}>
            <div className={styles.currencyInputWrapper}>
                <NumberFormatBase
                    value={val.min}
                    disabled={isDisabled}
                    getInputRef={refInputMin}
                    onValueChange={onMinValueChange}
                    className={styles.currencyInput}
                    placeholder={formatNumber(min)}
                    format={(value) => {
                        return !value ? '' : formatNumber(+value);
                    }}
                    onBlur={onBlurMinValue}
                    onKeyDown={(event) => {
                        if (event.key !== 'Unidentified') {
                            playInputSound();
                        }
                    }}
                    // @ts-ignore
                    noimesupport={'true'}
                />
            </div>
            <div className={styles.currencyInputDelimiter} />
            <div className={styles.currencyInputWrapper}>
                <NumberFormatBase
                    value={val.max}
                    disabled={isDisabled}
                    getInputRef={refInputMax}
                    onValueChange={onMaxValueChange}
                    className={styles.currencyInput}
                    placeholder={formatNumber(max)}
                    format={(value) => {
                        return !value ? '' : formatNumber(+value);
                    }}
                    onBlur={onBlurMaxValue}
                    onKeyDown={(event) => {
                        if (event.key !== 'Unidentified') {
                            playInputSound();
                        }
                    }}
                    // @ts-ignore
                    noimesupport={'true'}
                />
            </div>
        </div>
    );
};

export default CurrencyInputsRange;
