import AuctionStorage from '~/core/AuctionStorage';
import store from '~/Store';
import { CATEGORIES } from '~/const';
import AuctionProcessor from '~/processors/AuctionProcessor';
import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import { urlsMap } from '~/utils/menu';
import { appActions } from '~/Store/appSlice';

export default class AuctionPreloader extends AbstractPreloader {
    async load(): Promise<any> {
        if (location.pathname.includes(urlsMap.auction)) {
            await AuctionStorage.preloadedState();
            return this.postLoadPreparation();
        } else {
            AuctionStorage.preloadedState().then(this.postLoadPreparation);
            return Promise.resolve(1);
        }
    }

    postLoadPreparation() {
        return new Promise((resolve) => {
            const auctionState = store.getState().auction;
            if (AuctionStorage.isAuctionCategoryEnabled) {
                AuctionStorage.loadVortexInfoIfNeeded();
                AuctionStorage.createScheduledTask();
                store.dispatch(appActions.showMenuItem(CATEGORIES.AUCTION));
            }
            AuctionProcessor.startSyncCheckBidStatusIfNeeded();
            if (auctionState?.account?.activeBids) {
                AuctionProcessor.checkWonBids(auctionState?.account?.activeBids || []);
            }
            resolve(1);
        });
    }
}
