import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './AuctionLotPage.scss';
import classNames from 'classnames';
import { RootState, useAppSelector } from '~/Store';

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        activeBackgroundLotUrl4K: state.auction.activeBackgroundLotUrl4K,
        activeBackgroundLotUrl: state.auction.activeBackgroundLotUrl,
        activeBackgroundColorLot: state.auction.activeBackgroundColorLot,
        activeAuctions: state.auction.activeAuctions,
        zoomLotBackground: state.auction.zoomLotBackground,
        shipFeatureMap: state.features.shipFeatureMap,
    };
};

const AuctionBackground = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const state = useAppSelector(stateSelector, equal);
    const lot = state.activeAuctions[0]?.lots?.find((lot) => lot.id === state.currentPage?.lotId);

    if (!state.activeBackgroundLotUrl) {
        return null;
    }

    return (
        <div
            className={classNames(styles.auctionBackground, {
                [styles.lotWithFeatures]: !lot?.disableShipFeatures,
                [styles.art4k]: !!state.activeBackgroundLotUrl4K,
                [styles.zoomLotBackground]: state.zoomLotBackground,
            })}
            ref={ref}
            style={
                {
                    backgroundImage: `url(${state.activeBackgroundLotUrl})`,
                    backgroundColor: state.activeBackgroundColorLot || 'initial',
                    '--lot-background-4k': `url(${state.activeBackgroundLotUrl4K})`,
                    '--lot-default-background': `url(${state.activeBackgroundLotUrl})`,
                } as React.CSSProperties
            }
        />
    );
};

export default AuctionBackground;
