import styles from './CategoryTimer.scss';
import { t } from '~/utils/localization';
import classNames from 'classnames';
import CountDown from '~/components/CountDown/CountDown';

interface ICategoryTimer {
    category: ICategory;
    title?: string;
    onComplete: DefaultFunction;
}

const CategoryTimer = ({ category, title, onComplete }: ICategoryTimer) => {
    return (
        <div className={classNames(styles.wrapper, styles[category.theme], styles[category.type])} key={category.name}>
            <div className={styles.title}>
                <span className={styles.label}>{title || t('До конца события')}</span>&nbsp;
            </div>
            <div className={styles.timer}>
                <CountDown date={category?.activeTill} isLarge={true} onComplete={onComplete} />
            </div>
        </div>
    );
};

export default CategoryTimer;
