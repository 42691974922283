import * as React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { POPUPS_NAME } from '~/components/PopupManager';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import styles from './ChanceLabel.scss';
import { interpolate, t } from '~/utils/localization';
import { formatNumber } from '~/utils/utils';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { appActions } from '~/Store/appSlice';

interface IChanceLabel {
    className?: string;
    chance?: number;
    isContinuousRewards?: boolean;
    tooltipContent?: string[] | React.ReactChild[];
    withoutTooltip?: boolean;
}

const ChanceLabel = ({ className, chance, isContinuousRewards, tooltipContent, withoutTooltip }: IChanceLabel) => {
    const dispatch = useDispatch();
    const classesLabel = classNames(
        styles.label,
        {
            [styles.continuousRewards]: isContinuousRewards,
        },
        className,
    );

    const content = [...(tooltipContent || [])];

    if (Number.getCountNumbersAfterComma(chance) === 2) {
        const text = t('Вероятность получения награды округлена с точностью до сотых по стандартным математическим правилам.');
        content.push(text);
    }

    const tooltipBody = <DefaultTooltip text={content} />;

    const Div = ({ children, className, onClick }: { children: any; className?: string; onClick: () => void }) => {
        return (
            <div className={className} onClick={onClick}>
                {children}
            </div>
        );
    };

    const Component = content.length && !withoutTooltip ? DivTooltip : Div;

    const onClick = () => {
        if (!isMobileOrTabletDeviceByAgent() || !content?.length) {
            return;
        }
        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.MOBILE_TOOLTIP_POPUP,
                isVisible: true,
                data: {
                    contents: content,
                },
            }),
        );
    };

    return (
        <Component className={classesLabel} tooltipBody={tooltipBody} onClick={onClick}>
            {interpolate(t('{percent}%'), { percent: formatNumber(chance) })}
        </Component>
    );
};

export default ChanceLabel;
