import * as React from 'react';
import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { ErrorBoundary } from '@sentry/react';
import CommonLayout from '~/Layouts/Lootboxes/Views/CommonLayout/CommonLayout';
import { useAppSelector } from '~/Store';
import { fetchLootbox } from '~/Store/lootboxSlice';

interface ILootboxAutoDescription {
    id: number;
    autoDescriptionSettings: IAutoDescriptionSettings;
}

const LootboxAutoDescriptionWrapper = ({ id, autoDescriptionSettings }: ILootboxAutoDescription) => {
    const dispatch = useDispatch();
    const lootboxes = useAppSelector((state) => state.lootbox.lootboxes, equal);
    const currentLootbox = lootboxes[id];

    React.useEffect(() => {
        if (!currentLootbox) {
            dispatch(fetchLootbox(id));
        }
    }, [currentLootbox]);

    if (currentLootbox) {
        return (
            <ErrorBoundary>
                <CommonLayout lootbox={currentLootbox} lootboxAutoDescriptionSettings={autoDescriptionSettings} />
            </ErrorBoundary>
        );
    }

    return null;
};

export default React.memo(LootboxAutoDescriptionWrapper);
