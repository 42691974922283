import styles from './Tooltip.scss';
import equal from 'fast-deep-equal/react';
import Price from '~/components/Price/Price';
import Discount, { DiscountSize } from '../Discount/Discount';
import { useAppSelector } from '~/Store';

export type CurrencySecuenceItem = Record<string, { value: number; originalValue: number; discount: number; withExchange?: boolean; isSum?: boolean }>;

interface IBundleCurrencySequenceTooltip {
    currencies: CurrencySecuenceItem;
}

const BundleCurrencySequenceTooltip = ({ currencies }: IBundleCurrencySequenceTooltip) => {
    const currenciesFromState = useAppSelector((state) => state.app.currencies, equal);

    return (
        <div className={styles.currenciesTooltip}>
            {Object.keys(currencies).map((currency) => {
                return (
                    <div className={styles.bundleCurrencyItem} key={currency}>
                        <div className={styles.currencyItemTitle}>{currenciesFromState[currency]?.title}</div>
                        <div className={styles.currencyItemDotted}></div>
                        <div className={styles.currencyItemWidget}>
                            {currencies[currency].discount ? (
                                <div className={styles.currencyItemPriceWithDiscountWrapper}>
                                    <Discount discount={currencies[currency].discount} className={styles.currencyItemPriceDiscount} size={DiscountSize.SMALL} />
                                    <div className={styles.currencyItemPrices}>
                                        <Price
                                            amount={currencies[currency]?.originalValue}
                                            isOld
                                            currency={currency}
                                            className={styles.currencyItemOldPriceWithDiscount}
                                            withoutAnimation
                                            withoutIcon
                                        />
                                        <Price amount={currencies[currency]?.value} currency={currency} className={styles.currencyItemPriceWithDiscount} withoutAnimation />
                                    </div>
                                </div>
                            ) : (
                                <Price amount={currencies[currency]?.value} currency={currency} className={styles.currencyItemPrice} withoutAnimation />
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default BundleCurrencySequenceTooltip;
