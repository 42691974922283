import equal from 'fast-deep-equal/react';
import * as React from 'react';
import { RootState, useAppSelector } from '~/Store';

export default function useArmoryBackground() {
    const viewClassName = useAppSelector((state) => state.app.viewClassName, equal);

    React.useEffect(() => {
        const body = document.body;

        if (viewClassName) {
            body.classList.add(viewClassName);
        } else {
            body.className = [...body.classList].filter((name) => name.includes('transparent') || (viewClassName && name === viewClassName)).join(' ');
        }
    });
}
