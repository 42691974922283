import * as React from 'react';
import styles from './Coupon.scss';
import { t } from '~/utils/localization';
import { couponTip } from '~/components/Popups/Coupon/CouponPopup';
import Coupon, { CouponSize } from '~/components/Coupon/Coupon';
import { openCategoryByName } from '~/utils/category';

interface ICouponsCategory {
    coupons: ICoupon[];
    extraCoupons: ICoupon[];
}

const MobileCouponsCategory = (props: ICouponsCategory) => {
    return (
        <div className={styles.couponCategoryWrapper}>
            <div className={styles.couponCategoryTitle}>{t('Купоны')}</div>
            <div className={styles.couponCategoryDescription}>
                {t('Доступны специальные купоны, которые предоставляют скидку на предметы определенной категории в Адмиралтействе.')}
                <br />
                <br />
                {couponTip}
            </div>
            <div className={styles.couponCategoryContents}>
                {!!props.extraCoupons.length && (
                    <>
                        <div className={styles.couponCategoryContent}>
                            {props.extraCoupons.map((coupon) => {
                                return (
                                    <div className={styles.couponMargin} key={coupon.id}>
                                        <Coupon
                                            coupon={coupon}
                                            key={coupon.id}
                                            size={CouponSize.BIG}
                                            onClick={() => {
                                                openCategoryByName(coupon.category, {
                                                    place: 'coupon',
                                                    couponId: coupon.id,
                                                });
                                            }}
                                            shownCurrencies
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
                <div className={styles.couponCategoryContent}>
                    {props.coupons.map((coupon) => {
                        return (
                            <div className={styles.couponMargin} key={coupon.id}>
                                <Coupon
                                    coupon={coupon}
                                    key={coupon.id}
                                    size={CouponSize.BIG}
                                    onClick={() => {
                                        openCategoryByName(coupon.category, {
                                            place: 'coupon',
                                            couponId: coupon.id,
                                        });
                                    }}
                                    shownCurrencies
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MobileCouponsCategory;
