import { isInGameBrowser, isPortPreviewEnabled } from '~/utils/utils';
import { items as ITEMS_TYPES, items as ITEM_TYPES, items } from '@wg/wows-entities/const';
import { isCamouflage, isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import { settings } from '~/utils/settings';
import { openPort } from '~/api/WoWsClient';
import { PortPreviewType, sortBundleItems, sortLotRewardsItems } from '~/components/Port/settings';
import { ManagerData } from '~/core/ManagerData';

export const isEnabledPortForItemInsideBundle = (bundle: IBundle, item: IBundleEntity) => {
    if (!isPortPreviewEnabled) {
        return false;
    }

    if (bundle.disablePortPreview) {
        return false;
    }

    if (item.type === items.VEHICLES) {
        return true;
    }

    if (isPermanentCamouflage(item.type) && getShipIdForStylePreview(item.identifier)) {
        return true;
    }

    if (isPermanentCamouflage(item.type) && item.customisation?.shipId) {
        return true;
    }

    return !!(isCamouflage(item.type) && settings.camouflagePreviewDefaultShip);
};

export type ItemForPreviewPortType = {
    identifier: number;
    type: string;
    shipId?: number;
};

type OpenPortByItemBase = {
    item: ItemForPreviewPortType;
    isVisiblePort?: boolean;
    portPreviewType?: PortPreviewType;
    additionalData?: Record<string, any>;
    sourceUrl?: string;
};
export type IOpenPortByItemFromSpecialCategory = OpenPortByItemBase & { categoryExternalCode: string };
export type IOpenPortByItemFromBundle = OpenPortByItemBase & { bundleId: number };
export type IOpenPortByItem = StrictUnion<IOpenPortByItemFromBundle | IOpenPortByItemFromSpecialCategory>;

export const openPortByItem = (props: IOpenPortByItem) => {
    const { item, isVisiblePort = false, portPreviewType = PortPreviewType.BUNDLE, additionalData = {}, sourceUrl = location.pathname } = props;
    const bundleId = props.bundleId || props.categoryExternalCode;

    if (item.type === items.VEHICLES) {
        openPort(item.identifier, -1, item.type, portPreviewType, bundleId, isVisiblePort, true, sourceUrl, additionalData);
        return;
    }

    if (isPermanentCamouflage(item.type) && item.shipId) {
        openPort(item?.shipId, item.identifier, item.type, portPreviewType, bundleId, isVisiblePort, true, sourceUrl, additionalData);
        return;
    }

    const shipIdForStylePreview = Number(getShipIdForStylePreview(item.identifier));
    if (isPermanentCamouflage(item.type) && shipIdForStylePreview) {
        openPort(shipIdForStylePreview, item.identifier, item.type, portPreviewType, bundleId, isVisiblePort, true, sourceUrl, additionalData);
        return;
    }

    if (isCamouflage(item.type) && settings.camouflagePreviewDefaultShip) {
        openPort(settings.camouflagePreviewDefaultShip, item.identifier, item.type, portPreviewType, bundleId, isVisiblePort, true, sourceUrl, additionalData);
        return;
    }
};

export const isEnabledPortByItem = (type: string, id: number, shipId?: number) => {
    if (isPermanentCamouflage(type) && (getShipIdForStylePreview(id) || shipId)) {
        return true;
    }

    if (isCamouflage(type) && settings.camouflagePreviewDefaultShip) {
        return true;
    }

    if (type === items.VEHICLES) {
        return true;
    }

    return false;
};

export const openPortFromAuction = (type: string, shipId: number, exteriorId = -1, displayId: string) => {
    if (type === items.VEHICLES) {
        return openPort(shipId, exteriorId, type, PortPreviewType.AUCTION_LOT, displayId, true);
    }

    const shipIdForStylePreview = shipId || Number(getShipIdForStylePreview(exteriorId));
    if (isPermanentCamouflage(type) && shipIdForStylePreview) {
        return openPort(shipIdForStylePreview, exteriorId, items.PERMOFLAGES, PortPreviewType.AUCTION_LOT, displayId, true);
    }

    if (isCamouflage(type) && settings.camouflagePreviewDefaultShip) {
        return openPort(settings.camouflagePreviewDefaultShip, exteriorId, items.CAMOUFLAGE, PortPreviewType.AUCTION_LOT, displayId);
    }
};

export const openPortForFirstBundleItem = (bundle: IBundle, type: PortPreviewType = PortPreviewType.BUNDLE) => {
    const sortedBundleItems = sortBundleItems(bundle);
    const firstItem = sortedBundleItems[0];
    firstItem &&
        openPortByItem({
            item: { identifier: firstItem.identifier, type: firstItem.type, shipId: firstItem.customisation?.shipId },
            bundleId: bundle.id,
            portPreviewType: type,
        });
};

export const openPortForFirstReward = (lot: ILot) => {
    const items = sortLotRewardsItems(lot.entitlements);
    const firstItem = items[0];
    firstItem && openPortByReward(firstItem, lot.displayId);
};

export const openPortByReward = (reward: IAuctionLotItem, displayId: string) => {
    if (reward.type === ITEMS_TYPES.VEHICLES) {
        openPortFromAuction(reward.type, reward.id, -1, displayId);
    } else if (isPermanentCamouflage(reward.type) || isCamouflage(reward.type)) {
        openPortFromAuction(reward.type, reward.customisation?.ship_id, reward.id, displayId);
    }
};

export const isEnabledPortByLot = (lot: ILot) => {
    return isInGameBrowser && !!lot.enablePortPreview;
};

export const isEnabledPortByBundle = (bundle: IBundle, isEnableForRandomBundle = false, isPrimary = false) => {
    const sortedBundleItems = sortBundleItems(bundle);

    if (!isPortPreviewEnabled || bundle.disablePortPreview) {
        return false;
    }

    const firstItem = sortedBundleItems[0];
    if (!firstItem) {
        return false;
    }

    if (isPrimary && !firstItem.isPrimary) {
        return false;
    }

    if (!isPermanentCamouflage(firstItem.type) && !isCamouflage(firstItem.type) && firstItem.type !== ITEM_TYPES.VEHICLES) {
        return false;
    }

    if (bundle.isRandom && !isEnableForRandomBundle) {
        return false;
    }

    if (isPermanentCamouflage(firstItem.type) && !getShipIdForStylePreview(firstItem.identifier)) {
        return false;
    }

    return true;
};

export const getShipIdForStylePreview = (permoflageId: number): number | string => {
    const stylePreviewDefaultShips = settings.stylePreviewDefaultShips || [];

    if (!stylePreviewDefaultShips.length) {
        return null;
    }

    const permoflageInfo = ManagerData.getPermoflage(permoflageId);

    if (!permoflageInfo?.id) {
        // хак для тайлофляжей
        return settings.camouflagePreviewDefaultShip;
    }

    if (!permoflageInfo.restrictions) {
        return null;
    }

    const isAllowedField = (array: number[] | string[], field: number | string) => {
        const stringArray: string[] = [...(array || [])].map((item: number | string) => item.toString());

        return !stringArray.length || stringArray.includes(field.toString());
    };

    for (const index in stylePreviewDefaultShips) {
        const shipId = stylePreviewDefaultShips[index];
        const ship = ManagerData.getShip(Number(shipId));

        if (permoflageInfo.restrictions.specificShips?.includes(shipId.toString())) {
            return shipId;
        }

        if (permoflageInfo.restrictions.forbiddenShips?.includes(shipId.toString())) {
            continue;
        }

        if (
            isAllowedField(permoflageInfo.restrictions.types, ship.type.name) &&
            isAllowedField(permoflageInfo.restrictions.nations, ship.nation.name) &&
            isAllowedField(permoflageInfo.restrictions.levels, ship.level)
        ) {
            return shipId;
        }
    }

    return null;
};
