import RefManager, { RefManagerKeys } from '~/RefManager';
import { isMobileOrTabletWindow } from '~/utils/utils';

type Options = Record<string, number>;

export const scrollMobileContainer = (options: Options) => {
    document.documentElement.scrollTop = options.top;
};

export const scrollTop = (top = 0, withoutAnimation = false): void => {
    const container = RefManager.getRef(RefManagerKeys.MainContainer);

    if (!container) {
        return;
    }

    const options: Options = {
        top,
    };

    if (isMobileOrTabletWindow) {
        scrollMobileContainer(options);
        return;
    }

    if (withoutAnimation) {
        if (container.scrollTo) {
            container.scrollTo(options);
        } else {
            container.scrollTop = options.top;
        }
    } else {
        scrollTo(top, 300);
    }
};

function scrollTo(to: number, duration: number) {
    if (duration <= 0) {
        return;
    }

    const container = RefManager.getRef(RefManagerKeys.MainContainer);
    const difference = to - container.scrollTop;
    const perTick = (difference / duration) * 10;

    requestAnimationFrame(function () {
        container.scrollTop = container.scrollTop + perTick;
        if (container.scrollTop === to) {
            return;
        }
        scrollTo(to, duration - 10);
    });
}

export const scrollToBundleIfNeeded = () => {
    setTimeout(() => {
        const Url = new URLSearchParams(document.location.search);
        const bundleId = Url.get('toBundle');
        if (!bundleId) {
            return;
        }

        const target = document.querySelectorAll(`[data-id="${bundleId}"]`)?.[0] as HTMLDivElement;
        if (!target) {
            return;
        }

        const wrapper = RefManager.getRef(RefManagerKeys.MainContainer);

        scrollToTarget(target, wrapper);
    }, 60);
};

export const getScrollTopContainer = () => {
    if (isMobileOrTabletWindow) {
        return document.body.scrollTop || document.documentElement.scrollTop;
    }

    const mainContainer = RefManager.getRef(RefManagerKeys.MainContainer);
    return mainContainer?.scrollTop || 0;
};

export const scrollToTarget = (target: HTMLDivElement, wrapper: HTMLDivElement) => {
    const targetCoords = target.getBoundingClientRect();
    const wrapperCoords = wrapper.getBoundingClientRect();
    const wrapperBottomPosition = wrapperCoords.top + wrapper.offsetHeight;
    const targetBottomPosition = targetCoords.top + target.offsetHeight;
    let top = null;

    if (targetBottomPosition > wrapperBottomPosition || targetCoords.top < wrapperCoords.top) {
        top = wrapper.scrollTop + targetCoords.top - wrapperCoords.top - wrapperCoords.height / 2;
    }

    if (top === null) {
        return;
    }

    if (wrapper.scrollTo) {
        wrapper.scrollTo({
            top: top,
            left: 0,
        });
    } else {
        wrapper.scrollTop = top;
    }
};
