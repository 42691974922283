import store from '~/Store';
import { isDisplayRestricted } from '~/utils/bundles';
import { getUrl, urlsMap } from '~/utils/menu';
import { arrayToObjectByKey, updateSearchParam } from '~/utils/utils';
import Account from '~/account/Account';
import { FEATURING_TEMPLATES } from '~/Layouts/FeaturingLayout';
import dwhExport from '~/api/dwhExport';
import { CATEGORIES, DWH_EVENTS, FILTER_ALLOWED_BUNDLES_NAME, FILTER_CURRENCY_NAME, FILTER_DISCOUNT_NAME, FILTER_DISCOUNT_VALUES } from '~/const';
import { isEnabledCouponsFromBundle } from '~/utils/coupons';
import { SANTA_PAGE_THEME, SINGLE_BUNDLE_PAGE } from '~/Layouts/Themes/ThemeManager';
import { t } from '~/utils/localization';
import { getAvailableFilters, hasCouponFilterFromCategory, isNeedToRenderFilter } from '~/utils/filters';
import { armoryState, getPlatform, getUserId, isChina, settings } from '~/utils/settings';
import { CategoryType, PRESETS } from '~/types/category';
import { BundlePurchaseTypes } from '~/types/bundle';
import History from '~/utils/history';
import { appActions, updateCategoryByFilters } from '~/Store/appSlice';

export const prepareChinesNyCategoriesForGuests = () => {
    if (!isChina() || Account.getAccount()?.id) {
        return;
    }

    const categoryForGolds = armoryState.content.categories.find((category) => category.name === CATEGORIES.SANTA_DOUBLOONS_CONTAINERS);
    const categoryForRealMoney = armoryState.content.categories.find((category) => category.name === CATEGORIES.SANTA_CONTAINERS);
    if (!categoryForGolds) {
        return;
    }

    categoryForRealMoney.bundles = categoryForGolds.bundles;

    armoryState.content.categories.push(categoryForRealMoney);
};

export const prepareCategoriesForState = (bundles: IBundleList) => {
    prepareChinesNyCategoriesForGuests(); // WSCLANS-9589 - fix china ny category for guests, because the real money products aren't displayed
    const categories = [...armoryState.content.categories];
    const maps = arrayToObjectByKey(categories, 'name');

    if (!Array.isArray(categories)) {
        return {};
    }

    const subCategoryNames: string[] = [];

    const result = categories.reduce((state: Record<string, Partial<ICategory>>, category) => {
        if (category.parentCategory && isCategoryDisplayRestricted(maps[category.parentCategory], bundles)) {
            category.parentCategory = null;
        }

        if (isCategoryDisplayRestricted(category, bundles)) {
            return state;
        }

        if (category.subCategories?.length) {
            category.subCategories = category.subCategories.filter((subCategoryName) => {
                return !isCategoryDisplayRestricted(maps[subCategoryName], bundles);
            });
            subCategoryNames.push(...category.subCategories);
        }

        category.isContainBundlesForReal = category.bundles.some((bundleId: number) => {
            return !!bundles[bundleId]?.productCode;
        });

        state[category.name] = category;
        return state;
    }, {});

    subCategoryNames.forEach((subCategoryName) => {
        result[subCategoryName].parentCategoryData = result[result[subCategoryName].parentCategory];
    });

    result[CATEGORIES.FEATURED] = Object.assign(result[CATEGORIES.FEATURED] || {}, {
        url: urlsMap.home,
        name: CATEGORIES.FEATURED,
        title: t('Рекомендованное: главная'),
        subCategories: [],
    });

    return result as ICategories;
};

export const openBundleById = (bundleId: number, preset?: ICategoryPresets) => {
    const bundle = store.getState().app.bundles[bundleId];
    openBundleByUrl(bundle.categories[0], bundleId, preset);
};

export const openBundleFromCurrentPage = (currentPageName: ICategoryList, bundle: IBundle) => {
    let categoryLinkName = currentPageName;
    if (bundle.categories.length && !bundle.categories.includes(currentPageName)) {
        categoryLinkName = bundle.categories[0];
    }
    openBundleByUrl(categoryLinkName || CATEGORIES.FEATURED, bundle.id);
};

export const openBundleByUrl = (categoryName: ICategoryList, bundleId: number, preset?: ICategoryPresets, replace?: boolean) => {
    const { categories, activePreset } = store.getState().app;
    let params;

    if (categoryName) {
        const parentCategoryName = categories?.[categoryName]?.parentCategory as ICategoryList;
        const parentCategory = categories[parentCategoryName];
        params = { categoryName: parentCategory ? parentCategory.name : categoryName, id: bundleId };
    } else {
        params = { categoryName: CATEGORIES.FEATURED, id: bundleId };
    }

    const meta: Record<string, number | string | boolean> = {
        bundle_id: bundleId,
    };

    if (categoryName) {
        meta['from_category'] = categoryName;
    }

    if (activePreset) {
        meta['from_filter_preset'] = activePreset;
    }

    if (preset?.type === PRESETS.RECOMMENDED) {
        meta['from_recommendations'] = true;
    }

    dwhExport.send(DWH_EVENTS.OPEN_BUNDLE, meta);

    const url = getUrl(urlsMap.bundle, params);
    History.navigate(url, replace ? { replace: true } : undefined);

    if (preset) {
        store.dispatch(appActions.setFocusPreset(preset));
    }
};

export const openSalvageByExternalCode = (externalCode: string, port: IPort) => {
    const state = store.getState().app;

    const salvageCategory = getCategoryByTypeAndExternalCode(state.categories, CATEGORIES.SALVAGE, externalCode);
    const salvageUrl = port.sourceUrl?.indexOf(salvageCategory?.url) >= 0 ? port.sourceUrl : salvageCategory?.url;
    return History.navigate(salvageUrl || urlsMap.home);
};

export type COUPON_PLACE = 'coupon';
export type PURCHASE_PLACE = 'purchase';

export const prepareCategoryFromCoupon = (couponId: number) => {
    const state = store.getState();
    const appState = state.app;
    const accountState = state.account;
    const coupons = arrayToObjectByKey(accountState.coupons, 'id');
    const coupon = coupons[couponId];

    const availableCurrenciesFilters = getAvailableFilters(coupon.category, FILTER_CURRENCY_NAME);
    const availableDiscountFilters = getAvailableFilters(coupon.category, FILTER_DISCOUNT_NAME);

    const availableCurrencies = appState.categoryCurrencies[coupon.category]?.filter((currency: string) => {
        return !coupon.deniedCurrencies.includes(currency) && availableCurrenciesFilters.includes(currency);
    });

    const filtersData: Record<string, string[] | number[]> = {};

    let triggerFilterName: FILTER_INFO_NAME = FILTER_CURRENCY_NAME;

    if (availableCurrenciesFilters.length > 1) {
        filtersData[FILTER_CURRENCY_NAME] = availableCurrencies;
    }

    if (!coupon.isDisabled && availableDiscountFilters.includes('coupon')) {
        triggerFilterName = FILTER_DISCOUNT_NAME;
        filtersData[FILTER_DISCOUNT_NAME] = [FILTER_DISCOUNT_VALUES.COUPON];
    }

    if (coupon.allowedBundles?.length) {
        filtersData[FILTER_ALLOWED_BUNDLES_NAME] = coupon.allowedBundles;
    }

    if (!Object.keys(filtersData).length) {
        return;
    }

    if (appState.activePreset) {
        store.dispatch(appActions.updateActiveFilterPreset(null));
    }

    store.dispatch(appActions.changeMultipleFilter({ data: filtersData, triggerFilterName, category: coupon.category }));
    store.dispatch(updateCategoryByFilters(coupon.category));
};

export const resetCouponFiltersIfNeeded = () => {
    const state = store.getState();
    const appState = state.app;
    const accountState = state.account;
    const categoryName = appState.currentPage?.name as ICategoryList;
    const isEnabledCouponForCategory = Account.hasCouponFromCategory(accountState.coupons, categoryName);
    const isAvailableCouponForCategoryBundles = appState.bundleCategory[categoryName]?.some((bundleId: number) => {
        if (!appState.bundles[bundleId]) {
            return false;
        }
        return isEnabledCouponsFromBundle(accountState.coupons, appState.bundles[bundleId]);
    });

    const filters = { ...(appState.filters?.[categoryName] || {}) };
    const hasDiscountType = Array.isArray(filters?.discount_type);

    if (hasDiscountType && (!isEnabledCouponForCategory || !isAvailableCouponForCategoryBundles)) {
        filters.discount_type = filters.discount_type.filter((filter: string) => filter !== 'coupon') || [];
        updateSearchParam({ [FILTER_DISCOUNT_NAME]: filters.discount_type[0] });
        if (!filters.discount_type.length) {
            delete filters.discount_type;
        }
        store.dispatch(appActions.changeMultipleFilter({ data: filters, triggerFilterName: FILTER_CURRENCY_NAME }));
    } else {
        store.dispatch(appActions.changeMultipleFilter({ data: filters, triggerFilterName: hasDiscountType ? FILTER_DISCOUNT_NAME : FILTER_CURRENCY_NAME }));
    }

    store.dispatch(updateCategoryByFilters(categoryName));
};

export type IOptions = {
    place?: COUPON_PLACE | PURCHASE_PLACE | null;
    couponId: number;
};

export const openCategoryByName = (categoryName?: string, options?: IOptions) => {
    if (!categoryName) {
        History.navigate(urlsMap.home);
        return;
    }

    const { categories, menu } = store.getState().app;
    const categoryConfig = menu?.[categoryName];
    if (categoryConfig && categoryConfig.middleware && !categoryConfig.middleware()) {
        return;
    }
    if (categoryConfig?.isNotCategory) {
        History.navigate(categoryConfig.url);
        return;
    }

    const parentCategoryName = categories?.[categoryName as ICategoryList]?.parentCategory as ICategoryList;
    const parentCategory = categories?.[parentCategoryName];
    let params;
    let url;

    if (!parentCategory) {
        url = urlsMap.categories;
        params = { categoryName: categoryName };
    } else {
        url = urlsMap.subCategory;
        params = { categoryName: parentCategory.name, subCategoryName: categoryName };
    }

    History.navigate(getUrl(url, params));

    const isValidPlaceForFilters = options?.couponId && (options?.place === 'coupon' || options?.place === 'purchase');
    if (isNeedToRenderFilter(categoryName) && hasCouponFilterFromCategory(categories[categoryName]) && isValidPlaceForFilters) {
        prepareCategoryFromCoupon(options.couponId);
    }
};

export const getCategoryCurrencies = (bundles: IBundleList, categories: ICategories): Record<string, string[]> => {
    return Object.keys(categories).reduce((result: Record<string, string[]>, categoryName: string) => {
        let categoryBundles: number[] = [];

        if (categoryName === CATEGORIES.FEATURED) {
            const featuring = armoryState.content.featuring;
            categoryBundles = featuring.reduce((array: number[], content: any) => {
                content.contentBlocks.forEach((block: IContentBlock) => {
                    const data = block.data as IContentBlockData;
                    if (block.type !== FEATURING_TEMPLATES.CATEGORY_PREVIEW && data.bundles) {
                        const _bundles = data.bundles.map((item) => parseInt(item.id.toString(), 10));
                        array.push(..._bundles);
                    }
                });
                return array;
            }, []);
        } else {
            if (!!categories[categoryName]?.bundles?.length) {
                categoryBundles = categories[categoryName]?.bundles || [];
            } else {
                categoryBundles =
                    categories[categoryName]?.subCategories?.reduce((array: number[], category: string) => {
                        const _bundles = categories[category]?.bundles;
                        if (!_bundles) {
                            return array;
                        }
                        array.push(..._bundles);
                        return array;
                    }, []) || [];
            }
        }

        const currencies = categoryBundles.reduce((state: any, bundleId: number) => {
            const bundle = bundles[bundleId];
            if (!bundle || bundle.price === undefined || bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY) {
                return state;
            }

            state.add(bundle.currency);
            bundle.additionalCurrency && state.add(bundle.additionalCurrency);

            if (bundle.serialSequence) {
                bundle.serialSequence.forEach((id) => {
                    const serialBundle = bundles[id];
                    if (serialBundle) {
                        state.add(serialBundle.currency);
                        serialBundle.additionalCurrency && state.add(serialBundle.additionalCurrency);
                    }
                });
            }

            return state;
        }, new Set());

        result[categoryName] = [...currencies];

        return result;
    }, {});
};

const categoryTypesWithoutBundles = [CategoryType.FEATURED, CategoryType.DOCKYARD, CategoryType.AUCTION, CategoryType.SEA_BATTLE, CategoryType.TRADEIN, CategoryType.SALVAGE, CategoryType.ENROTH];
export const isCategoryDisplayRestricted = (item: ICategory, bundles: IBundleList) => {
    if (!item) {
        return true;
    }

    if (isDisplayRestricted(item)) {
        return true;
    }

    const type = item.type as CategoryType;

    if (categoryTypesWithoutBundles.includes(type)) {
        return false;
    }

    if (item.parentCategory && !item?.bundles?.length) {
        return true;
    }

    if (item.subCategories?.length) {
        return false;
    }

    if (item.promoTimer && !item.bundles?.length) {
        return false;
    }

    if (!bundles || !item || !Array.isArray(item?.bundles)) {
        return false;
    }

    return item.bundles.every((id) => {
        return !bundles[id] || isDisplayRestricted(bundles[id]);
    });
};

export const isAllSubCategoryDisplayRestricted = (category: ICategory) => {
    const categories = store.getState().app.categories;

    if (category.type === CategoryType.TRADEIN) {
        return false;
    }

    return category?.subCategories?.every((categoryName: ICategoryList) => {
        return !categories?.[categoryName]?.bundles?.length;
    });
};

export const getAvailableSubCategories = (subCategories: ICategoryList[], categories: ICategories) => {
    return [...subCategories].filter((_subCategoryName: ICategoryList) => {
        const category = categories?.[_subCategoryName];
        if (!category) {
            return false;
        }
        if (!!category.subCategories?.length) {
            return false;
        }
        if (category.type === CategoryType.TRADEIN || category.type === CategoryType.PROGRESSION_REWARD) {
            return true;
        }
        return !!category.bundles?.length;
    });
};

export const getFirstEventCategory = (categories: ICategories) => {
    return Object.values(categories).find((category) => category.name?.includes?.('event'));
};

export const getCategoryByTypeAndExternalCode = (categories: ICategories, categoryType: string, externalCode: string) => {
    return Object.values(categories).find((category) => category.type === categoryType && category.externalCode === externalCode);
};

export const getPresetByName = (presets: ICategoryPresets[], name: string): ICategoryPresets => {
    return presets?.find((preset: ICategoryPresets) => preset.name === name);
};

export const getPresetByType = (presets: ICategoryPresets[], type: PRESETS): ICategoryPresets => {
    return presets?.find((preset: ICategoryPresets) => preset.type === type);
};

export const isSingleBundleCategory = (category: ICategory) => {
    return category?.theme === SINGLE_BUNDLE_PAGE;
};

export const isDockyardCategory = () => {
    const state = store.getState().app;

    return state.categories[state.currentPage?.name]?.type === CategoryType.DOCKYARD;
};

type ReplacementCategoryMapType = {
    [key in ICategoryList]?: {
        categoryName: ICategoryList;
        data: ICategory;
    };
};
export const getMenuCategories = (categoriesFromState: ICategories, bundles: IBundleList) => {
    const userId = getUserId();
    const replacementCategoryMap: ReplacementCategoryMapType = {};

    let categories: ICategory[] = Object.values(categoriesFromState)
        .map((category) => {
            const availableSubCategoryBundles =
                bundles && category?.parentCategory && !!category?.bundles?.length
                    ? [...(category?.bundles || [])].filter((bundleId) => {
                          const bundle = bundles[bundleId];
                          if (!bundle) {
                              return false;
                          }
                          return !isDisplayRestricted(bundle);
                      })
                    : null;

            if (Array.isArray(availableSubCategoryBundles) && !availableSubCategoryBundles.length) {
                category.bundles = [];
            }

            return category;
        })
        .map((category) => {
            if (!category.subCategories?.length) {
                return category;
            }

            const availableSubCategories = getAvailableSubCategories(category.subCategories as ICategoryList[], categoriesFromState);
            if (availableSubCategories?.length === 1 && !isAllowOneTabForParentCategory(category)) {
                replacementCategoryMap[category.name as ICategoryList] = {
                    categoryName: availableSubCategories[0],
                    data: Object.assign(categoriesFromState[availableSubCategories[0]], {
                        parentCategory: null,
                        subCategories: [],
                        icon: categoriesFromState[availableSubCategories[0]]?.icon || category.icon,
                        parentIconClassName: category.name,
                        url: getUrl(urlsMap.categories, { categoryName: availableSubCategories[0] }),
                    }),
                };
            }

            return category;
        });

    Object.keys(replacementCategoryMap).forEach((categoryName: ICategoryList) => {
        categories = categories
            .filter((category) => {
                return category.name !== replacementCategoryMap[categoryName].categoryName;
            })
            .map((category) => {
                if (category.name === categoryName) {
                    category = replacementCategoryMap[categoryName].data;
                }
                return category;
            });
    });

    const excludeCategories = categories.reduce((list: string[] | any, item) => {
        if ((userId && !armoryState.account.isParagonsEnabled && item.name === 'paragons') || (!userId && item.name === 'paragons') || isCategoryDisplayRestricted(item, bundles)) {
            list.push(item.name);
        }

        if (item.subCategories.length) {
            item.subCategories.forEach((category) => {
                if (item.name !== category) {
                    list.push(category);
                }
            });
        }

        return list;
    }, []);

    return categories
        .filter((item) => {
            return !excludeCategories.includes(item.name);
        })
        .reduce((result: any, item) => {
            result[item.name] = item;
            return result;
        }, {});
};

export const prepareSubCategories = () => {
    armoryState.content.categories.forEach((category) => {
        if (category.type !== CategoryType.PROGRESSION_REWARD && category.subCategories.length) {
            category.subCategories.unshift(category.name);
        }
    });
};

export const isRealMoneyPurchaseDisabledBySettings = () => {
    const disabledPlatforms = settings?.disableRealMoneyPurchaseForPlatforms || [];
    if (!settings.urls.wsmartUrl) {
        return true;
    }

    return settings.isRealMoneyPurchaseDisabled || disabledPlatforms.includes(getPlatform());
};

export const isRealMoneyPurchaseDisabled = () => {
    const { categories } = store.getState().app;
    if (!categories[CATEGORIES.WSMART]) {
        return true;
    }

    return isRealMoneyPurchaseDisabledBySettings();
};

export const isEnableDockyard = () => Account.isDockyardCategoryEnabled();

export const isEnabledSeaBattleByMetashop = (): boolean => !!settings.urls?.seaBattleSettings;

export const getLimitForFeaturedBundlesForSubCategory = (bundles: IBundleList, categoryBundlesIds: number[], defaultWidth = 1) => {
    let limit = 1;
    let width = 0;
    let i = 0;
    while (true) {
        const bundle = bundles[categoryBundlesIds[i]];
        if (!bundle) {
            break;
        }
        const bundleWidth = bundle.sizeInGrid;
        if (bundleWidth?.length > 1) {
            const [leftNumber, rightNumber] = bundleWidth.split('/');
            width += parseInt(leftNumber, 10) / parseInt(rightNumber, 10);
        } else {
            width += parseInt(bundleWidth, 10);
        }
        if (width >= defaultWidth) {
            break;
        }
        limit++;
        i++;
    }
    return limit;
};

export const isHiddenFooterForCategory = (category: ICategory) => {
    if (category?.theme === SANTA_PAGE_THEME) {
        return true;
    }

    return false;
};

export const ONE_TAB_CATEGORIES = [CategoryType.FORECAST, CategoryType.PROGRESSION_REWARD];
export const isAllowOneTabForParentCategory = (category: ICategory) => {
    return ONE_TAB_CATEGORIES.includes(category.type);
};
