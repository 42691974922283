import View from '~/components/View/View';
import Account from '~/account/Account';
import { isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import equal from 'fast-deep-equal/react';
import { Processing } from '@wg/wows-react-uikit';
import useArmoryBackground from '~/hooks/useArmoryBackground';
import { RootState, useAppSelector } from '~/Store';

export const viewStateSelector = (state: RootState) => {
    const isNeedToShowWelcomePage = Account.getAccount()?.id && !state.app.devMode?.disableWelcomePage && !isMobileOrTabletWindow;

    return {
        isFinishedRequest: state.app.isFinishedRequest,
        currentPage: state.app.currentPage,
        bundles: state.app.bundles,
        port: state.app.port,
        popups: state.app.popups,
        popupActive: state.app.popupActive,
        isBlurView: state.app.isBlurView,
        isHiddenMobileNav: state.app.isHiddenMobileNavigate,
        isFetching: state.app.isFetching,
        infoScreen: state.app.infoScreen,
        viewClassName: state.app.viewClassName,
        viewBackground: state.app.viewBackground,
        isVisibleTopPanel: state.app.isVisibleTopPanel,
        isVisibleMenu: state.app.isVisibleMenu,
        isFullscreen: state.app.isFullscreen,
        isNeedToShowWelcomePage: isNeedToShowWelcomePage,
        isGiftAvailable: state.account?.gifts?.filter((gift) => !gift.isSecretGift).length > 0,
        currentNotificationVisibleName: state.app.currentNotificationVisibleName,
        notifications: state.account.notifications,
        filterPosition: state.app.filterPosition,
        isVisibleRewardPopup: state.account.rewards?.isVisible,
        clientSource: state.app.clientSource,
        isStartedVideo: state.app.isStartedVideo,
        isTrusted: state.app.isTrusted,
        categories: state.app.categories,
    };
};

const ViewContainer = () => {
    const state = useAppSelector(viewStateSelector, equal);

    useArmoryBackground();

    if (!window.metashop.state?.content) {
        return <Processing isFetching={true} />;
    }

    if (!state.isFinishedRequest && (isMobileOrTabletWindow || isInGameBrowser)) {
        return <Processing isFetching={true} />;
    }

    return <View {...state} />;
};

export default ViewContainer;
