import { DivTooltip } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import Account from '~/account/Account';
import Attention from '~/components/Attention/Attention';
import AttentionLimitWidget from '~/components/BundleLimitWidget/Attention/AttentionLimitWidget';
import { canBoughtContainer } from '~/components/BundleLimitWidget/settings';
import BundlePricesSequence from '~/components/BundlePricesSequence/BundlePricesSequence';
import PurchaseButton from '~/components/Button/PurchaseButton';
import Link, { LinkColors } from '~/components/Link/Link';
import PriceWrapper, { PRICE_WRAPPER_SIZE } from '~/components/PriceWrapper/PriceWrapper';
import { PurchasedItem } from '~/components/Purchased/Purchased';
import SerialBundlePurchaseButtonWrapper from '~/components/SerialBundlePurchaseButtonWrapper/SerialBundlePurchaseButtonWrapper';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import {
    getAvailabelSerialBundleForPurchase,
    getLastAvailableSerialBundleForPurchase,
    hasLootboxRestrictionInSerialSequence,
    isAvailableMultiplePurchaseForSerialBundle,
    isFreeBundle,
} from '~/utils/bundles';
import { openBundleById } from '~/utils/category';
import { getLevelingRestrictionForBundle, getLevelingRestrictionTextForBundle } from '~/utils/levelingResctrictions';
import { interpolate, t } from '~/utils/localization';
import { getSerialPurchaseBlockedLabel } from '~/utils/purchase';
import styles from './SerialBundlePage.scss';
import { isMobileOrTabletWindow } from '~/utils/utils';

interface ISerialBundleFooter {
    bundle: IBundle;
    sequence: IBundle[];
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    isAuthorized: boolean;
    currentPage: ICurrentPage;
    restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount;
    bundlesInTransaction: number[];
    balance: IBalance;
    bundles: IBundleList;
}

const SerialBundleFooter = ({
    bundle,
    sequence,
    purchasedLimitedBundles,
    isAuthorized,
    currentPage,
    restrictedLootboxesPurchaseCount,
    balance,
    bundlesInTransaction,
    bundles,
}: ISerialBundleFooter) => {
    const isFree = isFreeBundle(bundle);
    const availableBundle = getAvailabelSerialBundleForPurchase(sequence, purchasedLimitedBundles);
    const isMustBuyPrevBundle = Account.isDisabledSerialBundle(purchasedLimitedBundles, bundle) || (bundle.serialPurchase && !isAuthorized && !bundle.serialSequence?.length);
    const levelingResctrictions = getLevelingRestrictionForBundle(bundle);
    const purchasedBundles = sequence.filter((bundle) => bundle.isPurchased);
    const isAllPurchase = bundle.isPurchased || bundle.serialIndex === 0 || bundle.serialIndex === sequence.length - 1 || bundle.id === availableBundle?.id;
    let sequenceForPurchase = [...sequence].filter((bundle) => !bundle.isPurchased);

    if (!isAllPurchase) {
        const index = sequenceForPurchase.findIndex((_bundle) => bundle.serialIndex === _bundle.serialIndex);
        sequenceForPurchase = [...sequenceForPurchase].splice(0, index + 1);
    }

    const lastAvailableSerialBundleForPurchase = getLastAvailableSerialBundleForPurchase(sequenceForPurchase, balance);

    let hiddenWholePurchaseWrapper = false;
    if (sequenceForPurchase.length === 1 && sequenceForPurchase[0].id === availableBundle?.id) {
        hiddenWholePurchaseWrapper = true;
    }

    const purchase = () => {
        if (isFree) {
            new PurchaseProcessor(bundle).purchase();
        } else {
            new PurchaseProcessor(bundle).purchaseBundleFromPopup(bundle);
        }
    };

    const goToAvailableBundle = () => {
        if (!Account.getAccount()?.id) {
            openBundleById(sequence.at(0).id);
            return;
        }
        availableBundle && openBundleById(availableBundle.id);
    };

    const hasLevelingRestriction = !!levelingResctrictions?.length;
    const canBoughtWithRestriction = canBoughtContainer(bundle, restrictedLootboxesPurchaseCount);
    const reasonCantBoughtCode = Number(canBoughtWithRestriction);
    const hasLootboxRestrictionForCurrentBundle = reasonCantBoughtCode < 0;
    const hasLootboxRestrictionForOtherBundles = hasLootboxRestrictionInSerialSequence(sequenceForPurchase);

    const hasBundleInTransaction = !!sequence.find((bundle) => bundlesInTransaction.includes(bundle.id));

    const _isAvailableMultiplePurchaseForSerialBundle = isAvailableMultiplePurchaseForSerialBundle(bundle, bundles);
    const isAvailablePartialPurchase =
        _isAvailableMultiplePurchaseForSerialBundle &&
        purchasedBundles.length !== sequence.length &&
        !hiddenWholePurchaseWrapper &&
        !hasBundleInTransaction &&
        isAuthorized &&
        !hasLootboxRestrictionForCurrentBundle &&
        !hasLootboxRestrictionForOtherBundles;

    const availableBundleIdForLink = lastAvailableSerialBundleForPurchase?.id || availableBundle?.id;

    const classesPurchaseCurrencies = classNames(styles.purchaseCurrencies, {
        [styles.isFree]: isFree,
    });

    return (
        <div className={styles.footerContent}>
            {hasLevelingRestriction && (
                <div className={styles.levelRestriction}>
                    <Attention label={getLevelingRestrictionTextForBundle(bundle, levelingResctrictions)} level="info" isInline />
                </div>
            )}
            <div className={styles.purchaseColumn}>
                <div className={styles.purchasePriceInfo}>
                    <div className={styles.purchaseWrapper}>
                        <div className={styles.purchasePriceTitle}>{t('Цена:')}</div>
                        <div className={classesPurchaseCurrencies}>
                            <PriceWrapper
                                bundle={bundle}
                                size={PRICE_WRAPPER_SIZE.BIG}
                                priceClassName={styles.priceWrapper}
                                withFadeDiscount={true}
                                wrapperClassName={styles.widgetPrice}
                                showExchangePrices
                            />
                        </div>
                    </div>
                </div>
                {hasLootboxRestrictionForCurrentBundle && (
                    <div className={styles.restrictionText}>
                        <AttentionLimitWidget reason={reasonCantBoughtCode} bundle={bundle} className={styles.attentionLimitWrapper} />
                    </div>
                )}
                {!hasLootboxRestrictionForCurrentBundle && (
                    <div className={styles.purchaseButtonWrapper}>
                        {isMustBuyPrevBundle ? (
                            <div className={styles.restrictionText}>
                                <Attention
                                    className={classNames({
                                        [styles.attentionWrapper]: !currentPage?.isBundlePage,
                                    })}
                                    label={getSerialPurchaseBlockedLabel(bundle.serialIndex)}
                                    buttonText={!_isAvailableMultiplePurchaseForSerialBundle && !isMobileOrTabletWindow && t('Перейти к доступному')}
                                    onButtonClick={goToAvailableBundle}
                                />
                                {((availableBundle && !isAvailablePartialPurchase) || (availableBundle && isMobileOrTabletWindow) || true) && (
                                    <div className={styles.mobileButtonRedirect}>
                                        <Link label={t('Перейти к доступному')} onClick={goToAvailableBundle} color={LinkColors.white} className={styles.goToNextButtonLink} />
                                    </div>
                                )}
                            </div>
                        ) : bundle.isPurchased ? (
                            <>
                                <PurchasedItem bundle={bundle} />
                                {availableBundle && !isAvailablePartialPurchase && (
                                    <Link label={t('Перейти к доступному')} onClick={goToAvailableBundle} color={LinkColors.white} className={styles.goToNextButtonLink} />
                                )}
                                {availableBundle && isMobileOrTabletWindow && (
                                    <div className={styles.mobileButtonRedirect}>
                                        <Link label={t('Перейти к доступному')} onClick={goToAvailableBundle} color={LinkColors.white} className={styles.goToNextButtonLink} />
                                    </div>
                                )}
                            </>
                        ) : (
                            <PurchaseButton
                                bundle={bundle}
                                parentRandomBundle={bundle}
                                buttonClassName={classNames(styles.purchaseButton, 'armory__auto--bundle_button_purchase')}
                                onClick={purchase}
                                classNameFastGoldLink={styles.fastGoldLink}
                                label={hasBundleInTransaction && t('Обработка')}
                                place={FAST_GOLD_PLACE.BUNDLE_VIEW_PAGE}
                                isFetching={hasBundleInTransaction}
                                isDisabled={hasBundleInTransaction}
                                shortageClassName={styles.shortageWrapper}
                                classNameChinaFastGoldLink={styles.chinaFastoGoldWrapper}
                            />
                        )}
                        {isAvailablePartialPurchase && <div className={styles.or}>{t('или')}</div>}
                    </div>
                )}
            </div>

            {isAvailablePartialPurchase && (
                <div className={classNames(styles.purchaseColumn, styles.wholePurchaseColumn)}>
                    <div className={styles.purchasePriceInfo}>
                        <div className={styles.purchaseWrapper}>
                            <DivTooltip
                                className={styles.purchasePriceTitle}
                                tooltipBody={
                                    <DefaultTooltip
                                        text={
                                            isAllPurchase
                                                ? t('Общая стоимость всех наборов серии')
                                                : interpolate(t('Стоимость включает выбранный набор, а также предыдущие неприобретённые наборы: c {amount} по {total}'), {
                                                      amount: purchasedBundles.length + 1 || 1,
                                                      total: isAllPurchase ? sequence.length : bundle.serialIndex + 1,
                                                  })
                                        }
                                    />
                                }
                            >
                                <span>{isAllPurchase ? t('Стоимость всех наборов:') : t('Стоимость с предыдущими наборами:')}</span>
                            </DivTooltip>
                            <div className={styles.purchaseCurrencies}>
                                <BundlePricesSequence bundles={sequenceForPurchase} className={styles.sequenceCurrencies} priceClassName={styles.priceWrapper} balance={balance} limit={3} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.purchaseButtonWrapper}>
                        <SerialBundlePurchaseButtonWrapper
                            sequence={sequenceForPurchase}
                            availableBundleId={availableBundleIdForLink}
                            hideLinkToAvailableBundle={availableBundleIdForLink === bundle.id}
                            label={isAllPurchase && t('Купить все')}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default SerialBundleFooter;
