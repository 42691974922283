import * as React from 'react';
import styles from './Button.scss';
import Button, { ButtonVariant } from '~/components/Button/Button';
import { t } from '~/utils/localization';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { getProgressiveDiscountFromBundle, isFreeBundle } from '~/utils/bundles';
import { calculateBundlePrices } from '~/utils/purchase';
import classNames from 'classnames';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import CurrencyShortageBlock from '~/components/CurrencyShortageBlock/CurrencyShortageBlock';
import FastGoldLinkContainer, { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import { assets } from '@wg/wows-entities/const';
import { CATEGORIES, CURRENCY } from '~/const';
import EventumCnRedirectButton from '~/components/EventumCnRedirectButton/EventumCnRedirectButton';
import { playButtonClickSound } from '~/api/WoWsClient';
import { IButtonSoundset } from '~/types/soundsets';
import CookieButton from '~/components/CookieButton/CookieButton';
import { BundleContext } from '../Bundle/BundleContext';
import { useDispatch } from 'react-redux';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import equal from 'fast-deep-equal';

export enum PurchaseButtonTheme {
    default = 'default',
    cookie = 'cookie',
}

export const purchaseButtonsMap = {
    [PurchaseButtonTheme.default]: Button,
    [PurchaseButtonTheme.cookie]: CookieButton,
};

interface IPurchaseButton {
    coupon?: ICoupon;
    bundle: IBundle;
    parentRandomBundle?: IBundle;
    isActiveCoupon?: boolean;
    tooltip?: string;
    onClick?: (event: React.MouseEvent) => void;
    onClickCallback?: (event: React.MouseEvent) => void;
    onClickFromDisableButton?: (event: React.MouseEvent) => void;
    isDisabled?: boolean;
    isFetching?: boolean;
    label?: string;
    isForceRenderPurchaseButton?: boolean;
    buttonClassName?: string | string[];
    onMouseMove?: (event: React.MouseEvent) => void;
    onClickOnSerialAttention?: (event: React.MouseEvent) => void;
    classNameFastGoldLink?: string;
    place?: FAST_GOLD_PLACE;
    customQuantity?: number;
    hideFastGoldLink?: boolean;
    shortageClassName?: string;
    purchaseButtonTheme?: PurchaseButtonTheme;
    buttonVariant?: ButtonVariant;
    classNameChinaFastGoldLink?: string;
}

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        purchasedLimitedBundles: state.account.purchasedLimitedBundles,
        activeCouponId: state.account.activeCouponId,
        bundlesInTransaction: state.account.bundlesInTransaction,
        deniedBundlesByUniqueItems: state.account.deniedBundlesByUniqueItems,
        accountId: state.account.id,
        bundles: state.app.bundles,
        coupons: state.account.coupons,
        balance: state.account.balance,
    };
};

const PurchaseButton = (props: IPurchaseButton): any => {
    const { preset } = React.useContext(BundleContext);
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const randomBundleChildInTransaction = (state.accountId && props.bundle.randomBundleChildren?.map((child) => child.id).filter((id) => state.bundlesInTransaction.includes(id))) || [];
    const bundleInTransaction = (state.bundlesInTransaction && state.bundlesInTransaction.includes(props.bundle.id)) || !!randomBundleChildInTransaction.length;
    const ButtonComponent = purchaseButtonsMap[props.purchaseButtonTheme || PurchaseButtonTheme.default];

    if (bundleInTransaction) {
        return <ButtonComponent variant={props.buttonVariant || ButtonVariant.orange} label={t('Обработка')} isFetching />;
    }

    const progressiveDiscount = getProgressiveDiscountFromBundle(props.bundle, props.customQuantity || props.parentRandomBundle?.quantityData?.quantity || 1);

    let quantityData = state.accountId ? state.bundles[props.bundle.id]?.quantityData : { lack: {} };
    if (state.accountId) {
        const quantity = props.customQuantity || quantityData?.quantity || props.parentRandomBundle?.quantityData?.quantity || 1;
        quantityData = calculateBundlePrices(props.bundle, state.balance, quantity, props.coupon?.discount, progressiveDiscount);
    }

    const shortage = quantityData?.lack || {};
    const shortageCurrencies = Object.keys(shortage);
    const isShortage = shortageCurrencies.length > 0;

    if (!isShortage || !state.accountId || props.isForceRenderPurchaseButton) {
        const _isFreeBundle = isFreeBundle(props.bundle);
        const tooltipContent = props.tooltip ? <DefaultTooltip text={props.tooltip} /> : null;
        let text = props.label || t('Купить');

        if (_isFreeBundle && !props.label) {
            text = t('Получить бесплатно');
        }

        return (
            <DivTooltip tooltipBody={tooltipContent}>
                <ButtonComponent
                    isFree={_isFreeBundle}
                    variant={props.buttonVariant || ButtonVariant.orange}
                    label={text}
                    soundset={IButtonSoundset.MUTED}
                    onMouseMove={props.onMouseMove}
                    onFallback={props.onClickFromDisableButton}
                    onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        if (props.onClick) {
                            playButtonClickSound();
                            props.onClick(event);
                        } else {
                            if (!state.currentPage?.isBundlePage) {
                                dispatch(appActions.setFocusPreset(preset));
                            }
                            if (_isFreeBundle) {
                                playButtonClickSound();
                                new PurchaseProcessor(props.bundle).purchase();
                            } else {
                                new PurchaseProcessor(props.bundle, {
                                    popupData: {
                                        bundleId: props.bundle.id,
                                        fromCategory: !state.currentPage?.isBundlePage,
                                    },
                                }).showPopup();
                            }
                        }
                    }}
                    isFetching={props.isFetching || bundleInTransaction}
                    disabled={props.isDisabled}
                    className={props.buttonClassName}
                />
            </DivTooltip>
        );
    }

    const map = shortageCurrencies.map((currency) => {
        return {
            amount: shortage[currency],
            currency,
        };
    });

    return (
        <div className={classNames(styles.shortageWrapper, props.shortageClassName)}>
            <CurrencyShortageBlock currencies={map} />
            {!props.hideFastGoldLink && !!shortage[assets.GOLD] && (
                <FastGoldLinkContainer
                    className={props.classNameFastGoldLink}
                    place={props.place}
                    goldShortage={shortage[assets.GOLD]}
                    isSantaBundle={props.bundle.categories.includes(CATEGORIES.SANTA_DOUBLOONS_CONTAINERS)}
                    groupName={props.bundle.groupName}
                    withIcon
                />
            )}
            {!props.hideFastGoldLink && !!shortage[CURRENCY.EVENTUM_CN] && <EventumCnRedirectButton className={props.classNameChinaFastGoldLink} withIcon />}
        </div>
    );
};

export default PurchaseButton;
