import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { ITradeItem } from '~/types/tradein';

interface ITradeInWeWidget {
    item: ITradeItem;
    hideNationIcon?: boolean;
}

const TradeInWeWidget = ({ item, hideNationIcon }: ITradeInWeWidget) => {
    let isNeedToHideNationIcon = true;
    if (hideNationIcon !== null && hideNationIcon !== undefined) {
        isNeedToHideNationIcon = hideNationIcon;
    }

    return (
        <WoWSEntity
            type={item.type}
            id={item.identifier}
            presentation={{ renderWithoutFade: true, hideNationIcon: isNeedToHideNationIcon }}
            disableVortexRequest={true}
            data={{
                nation: item.additionalData?.nation?.name,
                type: item.additionalData?.shipType,
                level: item.additionalData?.level.toString(),
                title: item.title,
                isPremium: item.additionalData?.tags.includes('uiPremium'),
                isSpecial: item.additionalData?.tags.includes('uiSpecial'),
            }}
        />
    );
};

export default TradeInWeWidget;
