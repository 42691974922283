class IntersectionObserverBundles {
    private intersectionObserver: IntersectionObserver;

    private entries = new Map<string, { target: HTMLElement; callback: (isIntersecting: boolean) => void }>();

    observe(target: HTMLElement, callback: (isIntersecting: boolean) => void, id: string) {
        this.entries.set(id, {
            callback,
            target,
        });
    }

    unobserve(target: HTMLElement, id: string) {
        this.entries.delete(id);
    }

    clear() {
        this.entries.forEach((entry) => {
            this.intersectionObserver?.unobserve(entry.target);
        });

        return this;
    }

    init() {
        this.intersectionObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                const target: any = entry.target;
                const id = target.dataset.lazyId;
                this.entries.get(id)?.callback(entry.isIntersecting);
            });
        });

        this.entries.forEach((entry) => {
            this.intersectionObserver.observe(entry.target);
        });
    }
}

export const BundlesObserver = new IntersectionObserverBundles();
