import * as React from 'react';
import { POPOVER_POSITIONS as PLACEMENT, type POPOVER_POSITION as IGuidePlacement, POPOVER_ALIGNS as ALIGN, type POPOVER_ALIGN as IGuideAlign } from '@wg/wows-react-uikit/components/Popover/Popover';
import WelcomePageHelper from '~/components/WelcomePage/WelcomePageHelper';
import { t } from '~/utils/localization';
import { hasNewCoupons } from '~/utils/coupons';
import { arrayToObjectByKey, isInGameBrowser } from '~/utils/utils';
import { completeActions } from '~/api/account';
import store from '~/Store';
import { armoryState } from '~/utils/settings';
import { getUnreadNotifications } from '~/utils/notifications';
import { isAuctionCategoryExists } from '~/utils/auction';
import { isRealMoneyPurchaseDisabled } from '~/utils/category';
import { CATEGORIES } from '~/const';
import { BUNDLE_TYPES } from '~/types/bundle';
import { accountActions } from '~/Store/accountSlice';

export enum GUIDE_NAMES {
    guide_wallet = 'guide_wallet',
    guide_wallet_items = 'guide_wallet_items',
    guide_coupon = 'guide_coupon',
    guide_search = 'guide_search',
    guide_filters_and_presets = 'guide_filters_and_presets',
    guide_new_coupons = 'guide_new_coupons',
    guide_premshop = 'guide_premshop',
    guide_notifications = 'guide_notifications',
    guide_ship_port = 'guide_ship_port',
    guide_camouflage_port = 'guide_camouflage_port',
    guide_events = 'guide_events',
    guide_ny2021_volume = 'guide_ny2021_volume',
    guide_auction = 'guide_auction',
    guide_rules_of_category = 'guide_rules_of_category',
    guide_crew_voice = 'guide_crew_voice',
    guide_trade_in_get_item = 'guide_trade_in_get_item',
    guide_trade_in_item = 'guide_trade_in_trade_item',
    guide_salvage_exchange = 'guide_salvage_exchange',
    guide_salvage_resources = 'guide_salvage_resources',
    guide_gacha_bonus_reward = 'guide_gacha_bonus_reward',
    guide_gacha_cashback = 'guide_gacha_cashback',
}

export { PLACEMENT, type IGuidePlacement, ALIGN, type IGuideAlign };

export interface IGuideStep {
    name: GUIDE_NAMES;
    beforeShown?: () => void;
    afterShown?: () => void;
    title?: string;
    content?: string | React.ReactNode;
    placement: IGuidePlacement;
    align?: IGuideAlign;
    isNeedToShown: boolean | (() => void);
    duration?: number;
    delay?: number;
    isQueue?: boolean;
    onlyCategories?: string[];
    checkVisibleInViewPort?: boolean;
    closeAfterScroll?: boolean;
    category?: (typeof CATEGORIES)[keyof typeof CATEGORIES];
    centerBeacon?: boolean;
}

export const steps: IGuideStep[] = [
    {
        name: GUIDE_NAMES.guide_ny2021_volume,
        title: t('Включить звук'),
        content: null,
        placement: PLACEMENT.BOTTOM,
        centerBeacon: true,
        isQueue: true,
        delay: 2,
        isNeedToShown: () => false,
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_wallet_items,
        title: t('Ресурсы и валюты'),
        content: t('Приобретайте имущество, используя накопленные вами ресурсы и валюты.'),
        placement: PLACEMENT.BOTTOM,
        centerBeacon: true,
        isQueue: true,
        isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_wallet_items),
        afterShown: () => {
            store.dispatch(accountActions.completeAction(GUIDE_NAMES.guide_wallet_items));
            completeActions([GUIDE_NAMES.guide_wallet_items]);
        },
    },
    {
        name: GUIDE_NAMES.guide_wallet,
        title: t('Кошелёк'),
        content: t('Здесь собраны все доступные ресурсы и валюты. Получить их можно из ежедневных контейнеров, за выполнение боевых задач и другие достижения.'),
        placement: {
            0: PLACEMENT.LEFT,
            1366: PLACEMENT.BOTTOM,
        },
        centerBeacon: true,
        isQueue: true,
        delay: 5,
        isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_wallet),
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_coupon,
        title: t('Купоны'),
        content: t('Приобретайте корабли и другое имущество, используя купоны. Большинство купонов регулярно обновляются.'),
        placement: PLACEMENT.BOTTOM,
        isQueue: true,
        delay: 5,
        isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_coupon),
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_search,
        title: t('Поиск'),
        content: t('Воспользуйтесь поиском. Это быстрее и удобнее, если вы знаете что ищете.'),
        placement: PLACEMENT.BOTTOM,
        isQueue: true,
        delay: 15,
        isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_search),
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_filters_and_presets,
        title: t('Фильтры и подборки'),
        content: t('Облегчают поиск и выбор имущества внутри категории.'),
        placement: PLACEMENT.BOTTOM,
        centerBeacon: true,
        isQueue: true,
        delay: 5,
        isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_filters_and_presets),
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_new_coupons,
        title: t('Обновлённые купоны'),
        content: t('Вы снова можете воспользоваться скидками. Большинство купонов регулярно обновляются.'),
        placement: PLACEMENT.BOTTOM,
        isQueue: true,
        isNeedToShown: () => {
            return WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_new_coupons) && hasNewCoupons();
        },
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_premshop,
        title: t('Дублоны и премиум'),
        content: t('Приобретайте корабельный премиум аккаунт, а также дублоны — специальную валюту World of Warships.'),
        placement: PLACEMENT.RIGHT,
        isQueue: true,
        isNeedToShown: () => {
            return !isRealMoneyPurchaseDisabled() && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_premshop);
        },
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_notifications,
        title: t('Уведомления'),
        content: t('Не пропустите информацию об обновлениях, акциях в Адмиралтействе и другие важные новости.'),
        placement: PLACEMENT.BOTTOM,
        centerBeacon: true,
        isQueue: true,
        isNeedToShown: () => {
            return WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_notifications) && getUnreadNotifications();
        },
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_events,
        title: t('Предметы события'),
        content: t('Приобретайте предметы нового события. Товары этой категории доступны в течение ограниченного времени.'),
        placement: PLACEMENT.RIGHT,
        isQueue: true,
        isNeedToShown: () => {
            const havEventsCategory = armoryState.content.categories?.find((category) => category.name?.includes?.('event'));
            return havEventsCategory && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_events);
        },
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_rules_of_category,
        title: t('Подробная информация'),
        content: t('Читайте подробности о текущих предложениях и другую полезную информацию'),
        placement: PLACEMENT.BOTTOM,
        align: {
            0: ALIGN.LEFT,
            1280: ALIGN.CENTER,
        },
        centerBeacon: true,
        isQueue: true,
        isNeedToShown: () => {
            const havGuideRules = armoryState.content.categories?.find((category) => !!category.label?.type);
            return havGuideRules && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_rules_of_category);
        },
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_ship_port,
        title: t('Предварительный просмотр корабля'),
        content: t('Вы можете посмотреть, как выглядит этот корабль в порту.'),
        placement: PLACEMENT.BOTTOM,
        centerBeacon: true,
        isQueue: true,
        checkVisibleInViewPort: true,
        isNeedToShown: () => isInGameBrowser && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_ship_port),
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_camouflage_port,
        title: t('Предварительный просмотр камуфляжа'),
        content: t('Вы можете посмотреть как выглядит этот камуфляж на корабле в порту.'),
        placement: PLACEMENT.BOTTOM,
        centerBeacon: true,
        isQueue: true,
        checkVisibleInViewPort: true,
        isNeedToShown: () => isInGameBrowser && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_camouflage_port),
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_crew_voice,
        title: t('Озвучка командира'),
        content: t('Теперь вы можете прослушать случайную реплику любого командира с оригинальной озвучкой!'),
        placement: PLACEMENT.BOTTOM,
        centerBeacon: true,
        isQueue: true,
        checkVisibleInViewPort: true,
        isNeedToShown: () => isInGameBrowser && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_crew_voice),
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_auction,
        title: t('Аукцион'),
        content: t('Не упустите возможность получить в своё распоряжение уникальное имущество и ресурсы по хорошей цене!'),
        placement: PLACEMENT.RIGHT,
        isQueue: true,
        delay: 1,
        isNeedToShown: () => isAuctionCategoryExists() && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_auction),
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_trade_in_item,
        title: t('Корабль для обмена'),
        content: t('Выберите корабль, который вы хотите обменять.'),
        placement: PLACEMENT.TOP,
        isQueue: true,
        delay: 0.5,
        closeAfterScroll: true,
        isNeedToShown: () => {
            const isCategoryEnabled = armoryState.content.categories?.find((category) => category.type === CATEGORIES.TRADEIN);
            return isCategoryEnabled && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_trade_in_item);
        },
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_trade_in_get_item,
        title: t('Корабль для получения'),
        content: t('Выберите корабль, который вы хотите получить.'),
        placement: PLACEMENT.TOP,
        isQueue: true,
        delay: 0.5,
        closeAfterScroll: true,
        isNeedToShown: () => {
            const isCategoryEnabled = armoryState.content.categories?.find((category) => category.type === CATEGORIES.TRADEIN);
            return isCategoryEnabled && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_trade_in_get_item);
        },
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_salvage_exchange,
        title: t('Salvage for victory'),
        content: t('Обменивайте ресурсы на награды'),
        placement: PLACEMENT.RIGHT,
        isQueue: true,
        delay: 0.5,
        isNeedToShown: () => {
            const isCategoryEnabled = armoryState.content.categories?.find((category) => category.type === CATEGORIES.SALVAGE);
            return isCategoryEnabled && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_salvage_exchange);
        },
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_salvage_resources,
        title: t('Salvage for victory'),
        content: t('Заполняйте шкалу прогресса, чтобы получить награды. Для заполнения шкалы используйте ресурсы.'),
        placement: PLACEMENT.LEFT,
        isQueue: true,
        delay: 1,
        isNeedToShown: () => {
            const isCategoryEnabled = armoryState.content.categories?.find((category) => category.type === CATEGORIES.SALVAGE);
            return isCategoryEnabled && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_salvage_resources);
        },
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
        category: CATEGORIES.SALVAGE,
    },
    {
        name: GUIDE_NAMES.guide_gacha_bonus_reward,
        title: t('Дополнительная награда'),
        placement: PLACEMENT.LEFT,
        isQueue: true,
        delay: 1,
        checkVisibleInViewPort: true,
        isNeedToShown: () => {
            const hasBundle = Object.values(armoryState.content.bundles)?.find((bundle) => bundle.type === BUNDLE_TYPES.mystery);
            return hasBundle && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_gacha_bonus_reward);
        },
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
    {
        name: GUIDE_NAMES.guide_gacha_cashback,
        title: t('Гарантированные жетоны'),
        placement: PLACEMENT.LEFT,
        isQueue: true,
        delay: 1,
        checkVisibleInViewPort: true,
        isNeedToShown: () => {
            const hasBundle = Object.values(armoryState.content.bundles)?.find((bundle) => bundle.type === BUNDLE_TYPES.mystery);
            return hasBundle && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_gacha_cashback);
        },
        afterShown: function () {
            store.dispatch(accountActions.completeAction(this.name));
            completeActions([this.name]);
        },
    },
];

export const getStepsFromGuide = (_steps?: IGuideStep[]): IGuideStep[] =>
    (_steps || steps).filter((step) => {
        if (!step.isQueue) {
            return false;
        }

        if (typeof step.isNeedToShown === 'function') {
            return step.isNeedToShown();
        }

        return step.isNeedToShown;
    });

const stepMaps = arrayToObjectByKey(steps, 'name');

export const getStepByName = (name: string) => {
    return { ...stepMaps[name] };
};
