import { FilterPosition } from './category';

export enum InventoryKeys {
    storage = 'storage',
    items_storage = 'items_storage',
    installed_items = 'installed_items',
    slots = 'slots',
    emptySlots = 'emptySlots',
    permoflages = 'permoflages',
    ships = 'ships',
    lootboxes = 'lootboxes',
}

declare global {
    type ICurrencyBalanceNames = 'brass' | 'coal' | 'credits' | 'eventum_1' | 'eventum_2' | 'gold' | 'molybdenum' | 'paragon_xp' | 'steel' | 'recruitment_points' | 'wows_premium' | 'premium';

    interface IBalanceData {
        currency: string;
        value: number;
        paidPart: number;
    }

    type IBalance = Record<string, IBalanceData>;

    type StorageInventory = Record<number, number>;

    type InstalledItems = Record<number, number>;

    type InventoryPermoflage = [number, number];
    type InventoryPermoflages = InventoryPermoflage[];
    type InventoryShips = number[];

    type InventoryState = {
        storage: StorageInventory;
        slots: number;
        emptySlots: number;
        permoflages: InventoryPermoflages;
        ships: InventoryShips;
        lootboxes: StorageInventory;
        lootboxes_without_dropped_rewards: StorageInventory;
        items_storage: StorageInventory;
        installed_items: InstalledItems;
    };

    type Inventory = {
        items_storage: StorageInventory;
        installed_items: InstalledItems;
        storage: StorageInventory;
        slots: number;
        empty_slots: number;
        permoflages: InventoryPermoflages;
        ships: InventoryShips;
        lootboxes: StorageInventory;
        lootboxes_without_dropped_rewards: StorageInventory;
    };

    type ResponseInventory = {
        data: Inventory;
        status: string;
    };

    type ResponseStorage = {
        data: Record<string, number>;
        status: string;
    };

    type AccountFilterSettings = {
        position: FilterPosition;
    };

    type AccountSettings = {
        isWelcomePageShown: boolean;
    };

    type AccountUsedCoupons = Record<string, number>;

    type AccountRandomBundleHistory = Record<number, number[]>;

    type AccountPurchasedLimitedBundles = Record<number, number>;

    type AccountSelectedRandomBundles = Record<string, number>;

    type AccountRestrictedLootboxesPurchaseCount = Record<number, number>;

    interface IAccountUpdateData {
        balance?: IBalance;
        coupons?: ICoupon[];
        deniedBundlesByUniqueItems: number[];
        activeCouponId?: number;
        purchasedLimitedBundles: AccountPurchasedLimitedBundles;
        usedCoupons: AccountUsedCoupons;
        selectedRandomBundles: AccountSelectedRandomBundles;
        randomBundlesHistory: AccountRandomBundleHistory;
        inventory?: Record<string, number>;
        bundlesInTransaction: number[];
        openedRandomBundles: number[];
        restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount;
        ts: number;
    }

    interface IAccountMinorRestrictions {
        isMinor: boolean;
        ageGroup: string[];
    }

    interface IAccount {
        isAdult: boolean;
        viewedNotifications: string[];
        viewedLabels: string[];
        isDeactivated: boolean;
        filtersSettings: AccountFilterSettings;
        inventory: Record<string, number>;
        filtersPresetsRecommended: number[];
        balance: IBalanceData[];
        isParagonsEnabled: boolean;
        country: Nullable<string>;
        minorRestrictions: Nullable<IAccountMinorRestrictions>;
        denied_bundles: number[];
        id: number;
        name: string;
        settings: AccountSettings;
        openedRandomBundles: number[];
        completedActions: string[];
        usedCoupons: AccountUsedCoupons;
        deniedBundlesByUniqueItems: number[];
        randomBundlesHistory: AccountRandomBundleHistory;
        purchasedLimitedBundles: AccountPurchasedLimitedBundles;
        bundlesInTransaction: number[];
        selectedRandomBundles: AccountSelectedRandomBundles;
        restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount;
        level: number;
    }
}

export default global;
