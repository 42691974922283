import { assets as ASSETS, items as ITEMS } from '@wg/wows-entities/const';
import BundleContains from '~/Layouts/BundlePage/Contains/BundleContains';
import classNames from 'classnames';
import styles from '~/Layouts/BundlePage/BundlePageDescription/BundlePageDescription.scss';
import * as React from 'react';
import { clickByLinkInDescription, parsePlaceholderInBundleDescription } from '~/Layouts/BundlePage/helpers';
import { isObject } from '~/utils/utils';
import ShipsContent, { AdditionalFeatures, MainFeatures } from '~/Layouts/BundlePage/Contents/ShipsContent';
import SignalContent from '~/Layouts/BundlePage/Contents/SignalContent';
import CrewsContent from '~/Layouts/BundlePage/Contents/CrewsContent';
import CamouflageContent from '~/Layouts/BundlePage/Contents/CamouflageContent';
import ModernizationContent from '~/Layouts/BundlePage/Contents/ModernizationContent';
import LootboxAutoDescriptionWrapper from '~/Layouts/Lootboxes/LootboxAutoDescriptionWrapper';
import BoostContent from '~/Layouts/BundlePage/Contents/BoostContent';
import BundleDescriptionSkeleton from '~/components/BundleDescriptionSkeleton/BundleDescriptionSkeleton';
import { isChina, settings } from '~/utils/settings';
import { interpolate, t } from '~/utils/localization';
import { PLATFORMS } from '@wg/wows-commerce/constants/platforms';
import { detectPlatform } from '@wg/wows-commerce';
import { BundlePurchaseTypes } from '~/types/bundle';

export function renderContainsBlock(bundle: IBundle) {
    if (!bundle.entitlements) {
        return null;
    }
    const primaryItem = bundle.primaryItem || bundle.entitlements?.length === 1 ? bundle.entitlements[0] : null;
    const isVariablePriceProduct = bundle.originalProduct?.isVariablePriceProduct;
    if (isVariablePriceProduct && bundle.entitlements.length < 2) {
        return null;
    }
    if (bundle.entitlements.length < 2 && !(Object.values(ASSETS) as string[]).includes(primaryItem?.type) && !primaryItem?.customTitle) {
        return null;
    }
    return <BundleContains bundle={bundle} className={classNames(styles.containsBlock, styles.borderBottom)} titleClassName={styles.containsTitle} />;
}

export function onClickByLink(event: React.MouseEvent) {
    event.preventDefault();
    clickByLinkInDescription(event);
}

export function renderDescriptionBlock(bundle: IBundle) {
    if (!bundle.hasOwnProperty('description')) {
        return (
            <div className={classNames(styles.descriptionText, styles.borderBottom)}>
                <BundleDescriptionSkeleton />
            </div>
        );
    }

    const description = bundle.description;

    if (!description || (!isObject(description) && !description.trim().length)) {
        return null;
    }

    const html = parsePlaceholderInBundleDescription(description);

    return <div className={classNames(styles.descriptionText, styles.borderBottom)} dangerouslySetInnerHTML={{ __html: html }} onClick={onClickByLink} />;
}

export function renderContentBlocks(bundle: IBundle) {
    switch (bundle.primaryItem?.type) {
        case ITEMS.VEHICLES:
            return <ShipsContent item={bundle.primaryItem} />;

        case ITEMS.SIGNAL:
            return <SignalContent item={bundle.primaryItem} />;

        case ITEMS.CREWS:
            return <CrewsContent item={bundle.primaryItem} />;

        case ITEMS.PERMOFLAGES:
        case ITEMS.CAMOUFLAGE:
        case ITEMS.STYLE:
        case ITEMS.SKIN:
            return <CamouflageContent item={bundle.primaryItem} />;

        case ITEMS.MODERNIZATION:
            return <ModernizationContent item={bundle.primaryItem} />;

        case ITEMS.LOOTBOX: {
            const { autoDescriptionSettings } = bundle;
            if (autoDescriptionSettings?.isDisabled) {
                return null;
            }

            const lootboxSettings = autoDescriptionSettings?.lootboxSettings?.[bundle.primaryItem.identifier];
            if (lootboxSettings?.isDisabled) {
                return null;
            }

            return <LootboxAutoDescriptionWrapper autoDescriptionSettings={bundle.autoDescriptionSettings} id={bundle.primaryItem.identifier} />;
        }

        case ITEMS.GLOBAL_BOOST:
        case ITEMS.MULTI_BOOST:
        case ITEMS.CAMO_BOOST:
            return <BoostContent item={bundle.primaryItem} />;

        default:
            return null;
    }
}

export function renderAdditionalDescription(bundle: IBundle) {
    if (!bundle.hasOwnProperty('descriptionAdditional')) {
        return (
            <div className={classNames(styles.descriptionText, styles.borderBottom)}>
                <BundleDescriptionSkeleton />
            </div>
        );
    }

    if (!bundle.descriptionAdditional) {
        return null;
    }

    return (
        <div
            className={classNames(styles.descriptionText, styles.borderBottom)}
            dangerouslySetInnerHTML={{ __html: parsePlaceholderInBundleDescription(bundle.descriptionAdditional) }}
            onClick={onClickByLink}
        />
    );
}

export function renderShipMainFeatures(bundle: IBundle) {
    return <MainFeatures shipId={bundle.primaryItem.identifier} />;
}

export function renderShipAdditionalFeatures(bundle: IBundle) {
    return <AdditionalFeatures shipId={bundle.primaryItem.identifier} />;
}

export function renderLegalDescription(bundle: IBundle) {
    if (bundle.purchaseType !== BundlePurchaseTypes.REAL_CURRENCY || isChina()) {
        return null;
    }
    const lawDocumentsOptions = {
        openLink: settings.urls.lawDocumentsUrl ? `<a class="external-link" target="_blank" href="${settings.urls.lawDocumentsUrl}"><span>` : ``,
        closeLink: settings.urls.lawDocumentsUrl ? `</span></a>` : ``,
    };
    let text = t(
        'The price includes VAT and sales tax, if applicable. The final price may vary slightly depending on the payment method. To see the final price, click on the button of your payment method. See {openLink}legal documents{closeLink} for more details. Wargaming Group Limited is an online merchant located at 105, Agion Omologiton Avenue, Nicosia 1080, Cyprus.',
    );
    if (detectPlatform() !== PLATFORMS.WEB) {
        text = t('The final cost may vary depending on the rules of the platform. Please consult platform terms and conditions for more information.');
    }
    return <div className={classNames(styles.descriptionText, styles.borderBottom)} dangerouslySetInnerHTML={{ __html: interpolate(text, lawDocumentsOptions) }} onClick={onClickByLink} />;
}
