import * as React from 'react';
import DefaultLayout from '~/Layouts/DefaultLayout';
import equal from 'fast-deep-equal/react';
import { getLimitForFeaturedBundlesForSubCategory } from '~/utils/category';
import BundlesListSubLayout from '~/Layouts/BundlesListSubLayout';
import { RootState, useAppSelector } from '~/Store';

interface ISubCategoriesLayout {
    categories: string[];
    categoryName: string;
}

const stateSelector = (state: RootState) => {
    return {
        categories: state.app.categories,
        bundleCategory: state.app.bundleCategory,
        allBundles: state.app.bundles,
    };
};

const SubCategoriesLayout = function (props: ISubCategoriesLayout): any {
    const lists = props.categories;
    const { categories, bundleCategory, allBundles } = useAppSelector(stateSelector, equal);

    return (
        <React.Fragment>
            {lists.map((categoryName: ICategoryList, index) => {
                const category = categories[categoryName];
                const parentCategory = category.parentCategoryData;
                const bundles: number[] = [];
                const purchasedBundles: number[] = [];
                let bundlesArray: number[] = [];
                let limit = 4;

                if (!category || categoryName === parentCategory?.name || !category?.bundles?.length) {
                    return null;
                }

                if (category.previewCategorySettings?.bundlesList?.length && lists.length > 2) {
                    const { bundlesList } = category.previewCategorySettings;
                    return <BundlesListSubLayout key={`${category.name}_${index}`} categoryName={category.name} bundlesList={bundlesList} title={category.title} />;
                }

                bundleCategory[categoryName]?.filter((bundleId: number) => {
                    if (category?.denyReorder) {
                        bundles.push(bundleId);
                        return;
                    }

                    const isPurchasedBundle = allBundles[bundleId].isPurchased;

                    if (isPurchasedBundle) {
                        purchasedBundles.push(bundleId);
                        return;
                    }

                    bundles.push(bundleId);
                });

                bundlesArray = bundles.concat(purchasedBundles);
                if (bundlesArray?.length) {
                    limit = getLimitForFeaturedBundlesForSubCategory(allBundles, bundlesArray);
                }

                const props: Record<string, any> = {};
                if (lists.length > 2) {
                    props['showCountdown'] = category.showCountdown;
                    props['activeTill'] = category.activeTill;
                    props['limit'] = limit;
                    props['isNotRenderFilter'] = true;
                }

                return <DefaultLayout key={index} bundles={bundlesArray} categoryName={categoryName} title={category.title} {...props} />;
            })}
        </React.Fragment>
    );
};

export default SubCategoriesLayout;
