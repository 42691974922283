import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FILTERS_TYPE } from '~/components/Filters/Filters';
import { ChooseItemFor, ITradeItem, ITradeLists } from '~/types/tradein';
import { flat } from '~/utils/utils';

export interface ITradeInState {
    lists: ITradeLists;
    selectedSourceItem: Nullable<ITradeItem>;
    selectedTargetItem: Nullable<ITradeItem>;
    choosenItemFor: ChooseItemFor;
    activeFilters: FILTERS_TYPE;
    ts: number;
}

const initialState: Partial<ITradeInState> = {
    lists: {},
    selectedTargetItem: null,
    selectedSourceItem: null,
    choosenItemFor: ChooseItemFor.TRADE,
    activeFilters: {},
    ts: null,
};

export const tradeinSlice = createSlice({
    name: 'tradein',
    initialState,
    reducers: {
        selectTargetItem(state, action: PayloadAction<ITradeItem>) {
            state.selectedTargetItem = action.payload;
        },

        selectSourceItem(state, action: PayloadAction<ITradeItem>) {
            state.selectedSourceItem = action.payload;
        },

        setTradeInLists(state, action: PayloadAction<ITradeLists>) {
            state.lists = action.payload;
        },

        chooseItemFor(state, action: PayloadAction<ChooseItemFor>) {
            state.choosenItemFor = action.payload;
            state.activeFilters = {};
        },

        clearState(state) {
            state.choosenItemFor = ChooseItemFor.TRADE;
            state.selectedSourceItem = null;
            state.selectedTargetItem = null;
            state.activeFilters = {};
        },

        updateFilter(state, action: PayloadAction<{ filterName: FILTER_INFO_NAME; value: any }>) {
            let activeFilters: FILTERS_TYPE = Object.assign({}, state.activeFilters);
            if (!activeFilters[action.payload.filterName]) {
                activeFilters[action.payload.filterName] = [];
            }

            const prepareFiltersState = (value: any) => {
                const index = activeFilters[action.payload.filterName].indexOf(value);
                if (index > -1) {
                    activeFilters[action.payload.filterName].splice(index, 1);
                    if (!flat(Object.values(activeFilters)).length) {
                        activeFilters = {};
                    }
                } else {
                    activeFilters[action.payload.filterName].push(value);
                }
            };

            if (Array.isArray(action.payload.value)) {
                action.payload.value.forEach((val) => prepareFiltersState(val));
            } else {
                prepareFiltersState(action.payload.value);
            }

            state.activeFilters = activeFilters;
        },

        clearFilters(state) {
            state.activeFilters = {};
        },

        setTs(state, action: PayloadAction<number>) {
            state.ts = action.payload;
        },
    },
});

export const tradeinActions = tradeinSlice.actions;

export default tradeinSlice.reducer;
