import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import WowsCommerce, { detectPlatform } from '@wg/wows-commerce';
import { ClientPaymentMethod, PrepareTsv } from '@wg/wows-commerce/api/platform/web';
import { BillingAddress, RegionalInfo } from '@wg/wows-commerce/api/user';
import { CategoryEntity } from '@wg/wows-commerce/core/entity/category.entity';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import { assets } from '@wg/wows-entities/const';

export interface IWsmartState {
    wowsCommerce: WowsCommerce;
    fastGold: WowsCommerce;
    products: ProductEntity[];
    fastGoldProducts: WsmartGoldProductWrapper[];
    fastGoldDefaultProducts: WsmartGoldProductWrapper[];
    categories: CategoryEntity[];
    platform: string;
    shopUnavailable: boolean;
    billingAddressSettings: BillingAddress;
    currentProductId: string;
    paymentMethod: ClientPaymentMethod;
    managementUrl: string;
    tsv: Nullable<PrepareTsv>;
    regional: Nullable<RegionalInfo>;
}

const initialState: Partial<IWsmartState> = {
    wowsCommerce: null,
    fastGold: null,
    products: [],
    categories: null,
    platform: null,
    fastGoldProducts: [],
    fastGoldDefaultProducts: [],
    shopUnavailable: false,
    currentProductId: null,
    paymentMethod: undefined,
    managementUrl: null,
    tsv: null,
    billingAddressSettings: null,
    regional: null,
};

export const wsmartSlice = createSlice({
    name: 'wsmart',
    initialState,
    reducers: {
        setWowsCommerce(state, action: PayloadAction<WowsCommerce>) {
            state.platform = detectPlatform();
            state.wowsCommerce = action.payload;
        },

        setFastGoldLib(state, action: PayloadAction<WowsCommerce>) {
            state.fastGold = action.payload;
        },

        setFastGoldProducts(state, action: PayloadAction<ProductEntity[]>) {
            const result: WsmartGoldProductWrapper[] = [];

            action.payload.forEach((product) => {
                const entitlements = product.entitlements || [];
                const goldTotal = entitlements.reduce((sum, current) => {
                    if (current.type === assets.GOLD) {
                        sum += current.amount;
                    }
                    return sum;
                }, 0);

                if (goldTotal > 0) {
                    result.push({
                        product: product,
                        totalGoldAmount: goldTotal,
                    });
                }
            });

            result.sort((a, b) => a.totalGoldAmount - b.totalGoldAmount);

            state.fastGoldProducts = result;
            state.fastGoldDefaultProducts = _getFastGoldDefaultProducts(result);
        },

        shopUnavailable(state) {
            state.shopUnavailable = true;
        },

        setBillingAddressSettings(state, action: PayloadAction<BillingAddress>) {
            state.billingAddressSettings = action.payload;
        },

        setWsmartCurrentProduct(state, action: PayloadAction<string>) {
            state.currentProductId = action.payload;
        },

        setWsmartPaymentMethod(state, action: PayloadAction<ClientPaymentMethod>) {
            state.paymentMethod = action.payload;
        },

        setTsvManagementUrl(state, action: PayloadAction<string>) {
            state.managementUrl = action.payload;
        },

        setTsv(state, action: PayloadAction<PrepareTsv>) {
            state.tsv = action.payload;
        },

        setRegionalInfo(state, action: PayloadAction<Nullable<RegionalInfo>>) {
            state.regional = action.payload;
        },
    },
});

export const wsmartActions = wsmartSlice.actions;

export default wsmartSlice.reducer;

const _getFastGoldDefaultProducts = (productWrappers: WsmartGoldProductWrapper[]) => {
    const defaults = [2500, 12500, 25000];

    let result = productWrappers.filter((value) => defaults.includes(value.totalGoldAmount));
    if (result.length === 3) {
        return result;
    }

    const len = productWrappers.length;
    if (len > 3) {
        result = [productWrappers[0], productWrappers[Math.floor(len / 2)], productWrappers[len - 1]];
    } else {
        result = productWrappers;
    }

    return result;
};
