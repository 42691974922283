import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserGraphicsQualityPreset } from '@wg/web2clientapi/browser/getUserGraphicsQualityPreset';
import { IGuideStep } from '~/components/WelcomePage/steps';
import { FilterPosition } from '~/types/category';
import { prepareBalanceFromState } from '~/utils/balance';
import { excludeCouponsForNotExistingCategories, prepareCouponsFromState } from '~/utils/coupons';
import { prepareInventory } from '~/utils/inventory';
import { prepareNotifications } from '~/utils/notifications';
import { arrayToObjectByKey, isEmptyObject } from '~/utils/utils';

export interface IDevModeParams {
    disableWelcomePage?: boolean;
    devMode?: boolean;
    disableVideo?: boolean;
    auctions?: {
        disableSync: boolean;
    };
}

export interface IAccountState {
    id: number;
    balance: IBalance;
    coupons: ICoupon[];
    activeCouponId: number;
    prevActiveCouponId: number;
    activeCouponFromBundle: number;
    deniedBundlesByUniqueItems: number[];
    bundlesInTransaction: number[];
    randomBundlesHistory: AccountRandomBundleHistory;
    completedActions: string[];
    selectedRandomBundles: AccountSelectedRandomBundles;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    inventory: InventoryState;
    purchasingBundleId: number;
    usedCoupons: AccountUsedCoupons;
    viewedNotifications: string[];
    notifications: INotification[];
    viewedLabels: string[];
    openedRandomBundles: number[];
    guideSteps: IGuideStep[];
    filterPosition: FilterPosition;
    filtersPresetsRecommended: number[];
    restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount;
    userGraphics: UserGraphicsQualityPreset;
    ts: number;
    isAdult: boolean;
    minorRestrictions: IAccountMinorRestrictions;
    gifts?: IGift[];
    rewards: {
        isVisible: boolean;
        rewards: IReward[];
    };
}

const accountState = window.metashop.state?.account;
const initialState: Partial<IAccountState> = {};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        initAccount(state) {
            state.id = accountState.id;
            state.balance = arrayToObjectByKey(prepareBalanceFromState(accountState.balance), 'currency');
            state.coupons = [] as ICoupon[];
            state.deniedBundlesByUniqueItems = accountState.deniedBundlesByUniqueItems || [];
            state.purchasedLimitedBundles = accountState.purchasedLimitedBundles || {};
            state.usedCoupons = accountState.usedCoupons;
            state.bundlesInTransaction = accountState.bundlesInTransaction;
            state.randomBundlesHistory = accountState.randomBundlesHistory;
            state.openedRandomBundles = accountState.openedRandomBundles;
            state.selectedRandomBundles = accountState.selectedRandomBundles;
            state.completedActions = accountState.completedActions;
            state.viewedNotifications = accountState.viewedNotifications || [];
            state.viewedLabels = accountState.viewedLabels || [];
            state.filterPosition = accountState.filtersSettings?.position || FilterPosition.TOP;
            state.filtersPresetsRecommended = accountState.filtersPresetsRecommended;
            state.restrictedLootboxesPurchaseCount = accountState.restrictedLootboxesPurchaseCount;
            state.isAdult = accountState.isAdult;
            state.minorRestrictions = accountState.minorRestrictions;
        },

        setCoupons(state, action: PayloadAction<{ coupons: ICoupon[]; categories: ICategories }>) {
            state.coupons = prepareCouponsFromState(excludeCouponsForNotExistingCategories(action.payload.coupons, action.payload.categories), state.usedCoupons, true);
        },

        setNotifications(state, action: PayloadAction<{ notifications: INotification[]; categories: ICategories; bundles: IBundleList }>) {
            state.notifications = prepareNotifications(action.payload.notifications, action.payload.categories, action.payload.bundles);
        },

        openBundle(state, action: PayloadAction<number>) {
            state.openedRandomBundles = [...(state.openedRandomBundles || []), action.payload];
        },

        activateCoupon(state, action: PayloadAction<{ id: number; bundleId: number }>) {
            state.activeCouponId = action.payload.id;
            state.prevActiveCouponId = action.payload.id;
            state.activeCouponFromBundle = action.payload.bundleId;
        },

        resetCoupon(state) {
            state.activeCouponId = null;
            state.activeCouponFromBundle = null;
            state.prevActiveCouponId = null;
        },

        updateAccountState(state, action: PayloadAction<{ data: IAccountUpdateData; coupons: ICoupon[] }>) {
            state.balance = action.payload.data.balance;
            state.purchasedLimitedBundles = action.payload.data.purchasedLimitedBundles;
            state.usedCoupons = action.payload.data.usedCoupons;
            state.randomBundlesHistory = action.payload.data.randomBundlesHistory;
            state.coupons = action.payload.coupons;
            state.bundlesInTransaction = action.payload.data.bundlesInTransaction;
            state.openedRandomBundles = action.payload.data.openedRandomBundles;
            state.restrictedLootboxesPurchaseCount = action.payload.data.restrictedLootboxesPurchaseCount;
            state.ts = action.payload.data.ts;
            state.purchasedLimitedBundles = { ...state.purchasedLimitedBundles, ...action.payload.data.purchasedLimitedBundles };
            state.deniedBundlesByUniqueItems = [...new Set([...state.deniedBundlesByUniqueItems, ...action.payload.data.deniedBundlesByUniqueItems])];

            if (!isEmptyObject(action.payload.data.selectedRandomBundles || {})) {
                state.selectedRandomBundles = action.payload.data.selectedRandomBundles;
            }
        },

        updateBalance(state, action: PayloadAction<IBalance>) {
            state.balance = action.payload;
        },

        removeActivatedCoupon(state) {
            state.activeCouponId = null;
        },

        purchaseBundle(state, action: PayloadAction<{ id: number; quantity: number }>) {
            state.purchasedLimitedBundles = {
                ...state.purchasedLimitedBundles,
                [action.payload.id]: state.purchasedLimitedBundles[action.payload.id] ? state.purchasedLimitedBundles[action.payload.id] + action.payload.quantity : 1,
            };
        },

        deniedBundlesByUniqueItems(state, action: PayloadAction<number>) {
            state.deniedBundlesByUniqueItems = [...new Set([...state.deniedBundlesByUniqueItems, action.payload])];
        },

        purchaseRandomBundle(state, action: PayloadAction<{ bundleId: number; newSelectedBundleId: number }>) {
            state.selectedRandomBundles = {
                ...state.selectedRandomBundles,
                [action.payload.bundleId]: action.payload.newSelectedBundleId,
            };
        },

        updateViewNotification(state, action: PayloadAction<string>) {
            state.viewedNotifications = [...(state.viewedNotifications || []), action.payload];
        },

        addBundleToTransaction(state, action: PayloadAction<number[]>) {
            state.bundlesInTransaction = [...(state.bundlesInTransaction || []), ...action.payload];
        },

        removeBundleFromTransaction(state, action: PayloadAction<number>) {
            state.bundlesInTransaction = state.bundlesInTransaction?.filter((id) => id !== action.payload) || [];
        },

        completeAction(state, action: PayloadAction<string>) {
            state.completedActions = [...state.completedActions, action.payload];
        },

        updateCoupons(state) {
            state.coupons = prepareCouponsFromState(state.coupons, state.usedCoupons);
        },

        setPurchasingBundleId(state, action: PayloadAction<Nullable<number>>) {
            state.purchasingBundleId = action.payload;
        },

        markLabelAsRead(state, action: PayloadAction<string>) {
            state.viewedLabels = [...(state.viewedLabels || []), action.payload];
        },

        setGifts(state, action: PayloadAction<IGift[]>) {
            state.gifts = action.payload;
        },

        obtainGift(state, action: PayloadAction<number>) {
            state.gifts = state.gifts.filter((gift) => gift.id !== action.payload);
        },

        pushRewardsToStack(state, action: PayloadAction<IReward[]>) {
            const rewards = [...(state.rewards?.rewards || []), ...(action.payload || [])];
            const isVisible = !!rewards.length;
            state.rewards = { isVisible, rewards };
        },

        markRewardAsViewed(state, action: PayloadAction<IReward>) {
            const rewards = state.rewards?.rewards?.filter((reward) => reward.id !== action.payload.id) || [];
            state.rewards = { isVisible: !!rewards?.length, rewards };
        },

        initGuideSteps(state, action: PayloadAction<IGuideStep[]>) {
            state.guideSteps = [...(state.guideSteps || []), ...action.payload];
        },

        unshiftGuideSteps(state, action: PayloadAction<IGuideStep>) {
            const isAlreadyExists = state.guideSteps?.filter((step) => step.name === action.payload?.name)[0];
            if (!isAlreadyExists) {
                state.guideSteps = [action.payload, ...(state.guideSteps || [])];
            }
        },

        addGuideStep(state, action: PayloadAction<IGuideStep>) {
            const isAlreadyExists = state.guideSteps?.filter((step) => step.name === action.payload?.name)[0];
            if (!isAlreadyExists) {
                state.guideSteps = [...(state.guideSteps || []), action.payload];
            }
        },

        hideGuideStep(state, action: PayloadAction<IGuideStep>) {
            const steps = state.guideSteps?.filter?.((step) => step.name !== action.payload.name);
            state.guideSteps = steps;
        },

        updateUserAdultsFlag(state) {
            state.isAdult = !state.isAdult;
        },

        setInventory(state, action: PayloadAction<Inventory>) {
            state.inventory = prepareInventory(action.payload);
        },

        setStorage(state, action: PayloadAction<StorageInventory>) {
            state.inventory.storage = action.payload;
        },

        setUserGraphics(state, action: PayloadAction<UserGraphicsQualityPreset>) {
            state.userGraphics = action.payload;
        },
    },
});

export const accountActions = accountSlice.actions;

export default accountSlice.reducer;
