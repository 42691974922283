import equal from 'fast-deep-equal/react';
import styles from './Footer.scss';
import Account from '~/account/Account';
import { NavLink, useLocation } from 'react-router-dom';
import { urlsMap } from '~/utils/menu';
import { t } from '~/utils/localization';
import { hasCoupons, isCouponsTabHidden } from '~/utils/coupons';
import { isRealMoneyPurchaseDisabled } from '~/utils/category';
import { CATEGORIES } from '~/const';
import classNames from 'classnames';
import { RootState, useAppSelector } from '~/Store';

const stateSelector = (state: RootState) => {
    return {
        shopUnavailable: state.wsmart.shopUnavailable,
        shopProducts: state.wsmart.products,
        menu: state.app.menu,
    };
};

const MobileMenuFooter = () => {
    const location = useLocation();

    const account = Account.getAccount();
    const state = useAppSelector(stateSelector, equal);
    const premiumCategory = state.menu?.[CATEGORIES.WSMART];

    const isAvailableShop = !state.shopUnavailable && !isRealMoneyPurchaseDisabled() && premiumCategory?.url;

    const menuItems = [
        {
            to: urlsMap.home,
            isAvailable: true,
            title: t('Главная'),
            codeName: 'home',
            iconClassName: styles.mobilePremiumMainIcon,
        },
        {
            to: urlsMap.mobileMenu,
            isAvailable: true,
            title: t('Категории'),
            codeName: 'categories',
            iconClassName: styles.mobileMenuIcon,
        },
        {
            to: urlsMap.coupons,
            isAvailable: !!account && hasCoupons() && !isCouponsTabHidden(),
            title: t('Купоны'),
            codeName: 'coupons',
            iconClassName: styles.userCouponsItem,
        },
        {
            to: premiumCategory?.url,
            isAvailable: isAvailableShop,
            title: t('Дублоны'),
            codeName: 'shop',
            iconClassName: styles.mobilePremiumShopIcon,
        },
        {
            to: urlsMap.wallet,
            isAvailable: !!account,
            title: t('Кошелёк'),
            item: 'wallet',
            iconClassName: styles.mobileWalletIcon,
        },
    ];

    return (
        <div className={styles.mobileStickyWrapper}>
            <div className={styles.mobileFixedMenu}>
                <div className={styles.mobileMenuContent}>
                    {menuItems.map((menuItem) => {
                        const isActiveMenu = menuItem.to === urlsMap.mobileMenu && location.pathname.includes('category');
                        return (
                            menuItem.isAvailable && (
                                <NavLink
                                    to={menuItem.to}
                                    className={({ isActive }) => {
                                        return classNames(styles.mobileItemMenu, {
                                            [styles.active]: isActive || isActiveMenu,
                                        });
                                    }}
                                    key={`mobile_menu_item_${menuItem.to}`}
                                >
                                    <div className={classNames(styles.mobileUserMenuItem, 'armory__auto--menu-footer')} data-menu-item={menuItem.codeName}>
                                        <div className={menuItem.iconClassName} />
                                        <div className={styles.mobileMenuTitle}>{menuItem.title}</div>
                                    </div>
                                </NavLink>
                            )
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MobileMenuFooter;
