import { getBundleLimitedInteractionStatus, isDisplayRestricted, isParagonBundle } from '~/utils/bundles';
import Account from '~/account/Account';
import { isAllSubCategoryDisplayRestricted } from '~/utils/category';
import { CATEGORIES } from '~/const';
import { BundleLimitedInteraction } from '~/types/bundle';

export const isBundleDisabledForUser = (bundle: IBundle, category: ICategory) => {
    if (category?.parentCategory && !category?.bundles?.length) {
        return true;
    }
    if (!bundle) {
        return true;
    }
    if (getBundleLimitedInteractionStatus(bundle) === BundleLimitedInteraction.full) {
        return true;
    }
    return isDisplayRestricted(bundle) || (!Account.isParagonsEnabled() && isParagonBundle(bundle));
};

export const isCategoryDisabledForUser = (categoryName: ICategoryList, subCategoryName: ICategoryList, categories: ICategories) => {
    categoryName = categoryName || CATEGORIES.FEATURED;
    const category = (subCategoryName ? categories[subCategoryName] : categories[categoryName]) as ICategory;

    if (category && category.subCategories?.length && isAllSubCategoryDisplayRestricted(category)) {
        return true;
    }

    return !category || (!Account.isParagonsEnabled() && category?.name === CATEGORIES.PARAGONS);
};

export const isCurrentCategory = (locationCategoryName: ICategoryList, bundleCategory: ICategory) => {
    let categoryName = CATEGORIES.FEATURED;

    if (bundleCategory) {
        const parentCategory = bundleCategory.parentCategoryData;
        categoryName = parentCategory?.name || bundleCategory.name;
        if (parentCategory && !bundleCategory.parentCategory) {
            categoryName = bundleCategory.name;
        }

        return locationCategoryName === categoryName;
    }

    return true;
};
