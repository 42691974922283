import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import equal from 'fast-deep-equal/react';
import styles from './LootboxContent.scss';
import LootboxAutoDescriptionWrapper from '~/Layouts/Lootboxes/LootboxAutoDescriptionWrapper';
import Spoiler from '~/components/Spoiler/Spoiler';
import { interpolate, t } from '~/utils/localization';
import { useAppSelector } from '~/Store';

interface ILootboxContent {
    bundle: IBundle;
    item: IBundleEntity;
    renderSpoiler?: boolean;
}

const LootboxContent = ({ bundle, item }: ILootboxContent) => {
    const lootboxes = useAppSelector((state) => state.lootbox.lootboxes, equal);
    const currentLootbox = lootboxes[item.identifier];
    const { autoDescriptionSettings, entitlements: items } = bundle;

    if (autoDescriptionSettings?.isDisabled) {
        return null;
    }

    const { lootboxSettings } = autoDescriptionSettings;
    const containerAutoDescriptionSettings = lootboxSettings?.[item.identifier];
    if (containerAutoDescriptionSettings?.isDisabled) {
        return;
    }

    if (items.length === 1) {
        return <LootboxAutoDescriptionWrapper id={item.identifier} autoDescriptionSettings={bundle.autoDescriptionSettings} />;
    }

    let title = t('Содержимое контейнера');
    if (currentLootbox?.id) {
        const containerTitle = !!currentLootbox.shortTitle?.trim().length ? currentLootbox?.shortTitle : currentLootbox?.title;
        title = interpolate(t('Содержимое контейнера "{container_name}"'), {
            container_name: containerTitle,
        });
    }

    return (
        <div className={styles.wrapper}>
            <Spoiler
                title={
                    <div className={styles.contentSpoilerTitle}>
                        <WoWSEntity
                            type={item.type}
                            id={item.identifier}
                            className={styles.weComponent}
                            presentation={{
                                withText: true,
                                withoutAssetIcon: true,
                            }}
                        />
                    </div>
                }
                titleClassName={styles.title}
                content={<LootboxAutoDescriptionWrapper id={item.identifier} autoDescriptionSettings={bundle.autoDescriptionSettings} />}
            />
        </div>
    );
};

export default LootboxContent;
