import React, { useEffect, useState } from 'react';
import { isEnabledSeaBattleByMetashop } from '~/utils/category';
import { settings } from '~/utils/settings';
import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { getStaticUrl, redirectToLogin } from '~/utils/utils';
import { setScrollover } from '~/api/WoWsClient';
import History from '~/utils/history';
import { shipyardSoundOff, shipyardSoundOn } from '@wg/web2clientapi/sound';
import { urlsMap } from '~/utils/menu';
import { showDownloadGamePopup } from '~/components/Popups/settings';
import { CATEGORIES } from '~/const';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

const SeaBattle = React.lazy(() => import('@wg/seabattle/app'));

const stateSelector = (state: RootState) => {
    return {
        isEnabled: state.seabattle.isEnabled,
        hasActiveSeason: state.seabattle.hasActiveSeason,
        isActivatedUser: state.seabattle.isActivatedUser,
        accountId: state.account.id,
    };
};

const SeaBattleContainer: React.FC<unknown> = () => {
    const { hasActiveSeason, isActivatedUser, isEnabled: isEnabledByState, accountId } = useAppSelector(stateSelector, equal);
    const [isStaticLoaded, setIsStaticLoaded] = useState(false);
    const dispatch = useDispatch();
    const settingsOverrides = { publicPath: getStaticUrl(''), languageCode: settings.languageCode };
    const isEnabled = isEnabledSeaBattleByMetashop() && isEnabledByState;
    const goBack = () => History.backHome();
    const onClose = (permanent: boolean) => {
        if (permanent) {
            dispatch(appActions.hiddenMenuItem(CATEGORIES.SEA_BATTLE));
        }
        goBack();
    };

    useEffect(() => {
        if (!accountId) {
            dispatch(appActions.setFetching(true));
            dispatch(appActions.setFullscreen(true));
            redirectToLogin();
        }

        if (isEnabled) {
            dispatch(appActions.setFetching(true));
            dispatch(appActions.setFullscreen(true));
            shipyardSoundOff();
            import('@wg/seabattle/style.css').then(() => {
                setIsStaticLoaded(true);
            });
        }

        if (accountId && hasActiveSeason && !isActivatedUser) {
            showDownloadGamePopup({ onClose: goBack });
        }

        return () => {
            shipyardSoundOn();
            dispatch(appActions.setFullscreen(false));
            setScrollover(false);
        };
    }, [hasActiveSeason, isActivatedUser, isEnabled, accountId]);

    const hideLoadingScreen = React.useCallback(() => {
        setScrollover(true);
        dispatch(appActions.setFetching(false));
    }, [dispatch]);

    if (!hasActiveSeason) {
        History.navigate(urlsMap.home);
        return;
    }

    return (
        isEnabled &&
        isStaticLoaded && (
            <React.Suspense fallback={<></>}>
                <SeaBattle url={settings.urls.seaBattleSettings} onInitialized={hideLoadingScreen} onClose={onClose} onBuyTicket={() => onClose(false)} settingsOverrides={settingsOverrides} />
            </React.Suspense>
        )
    );
};

export default SeaBattleContainer;
