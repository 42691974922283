import { POPUPS_NAME } from '~/components/PopupManager';
import WowsCommerce, { detectPlatform, PLATFORMS, PURCHASE_STATUSES } from '@wg/wows-commerce';
import store from '~/Store';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import { WoWsCommerceStorefrontNames } from '~/core/WowsCommerce/WoWsCommerceHelper';
import { CheckoutResult } from '@wg/wows-commerce/Product';
import { getAnalyticsMetaDataForBundle } from '~/utils/analytics';
import { appActions } from '~/Store/appSlice';

export default class WSMartPurchaseProcessor {
    private readonly product: ProductEntity;

    private bundle: IBundle;

    private readonly wowsCommerce: WowsCommerce;

    private readonly isWeb: boolean;

    private readonly storefrontName: WoWsCommerceStorefrontNames;

    constructor(product: ProductEntity, storefrontName: WoWsCommerceStorefrontNames = WoWsCommerceStorefrontNames.METASHOP) {
        this.product = product;
        this.isWeb = detectPlatform() === PLATFORMS.WEB;
        this.wowsCommerce = store.getState().wsmart.wowsCommerce;
        this.storefrontName = storefrontName;
    }

    setBundle(bundle: IBundle) {
        this.bundle = bundle;
    }

    dispatchSuccess() {
        store.dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.WSMART_PURCHASE_RESULT,
                isVisible: true,
                data: {
                    isSuccess: true,
                    product: this.product,
                    bundle: this.bundle,
                },
                forcedClosurePopupNames: [POPUPS_NAME.CONFIRM_PURCHASE],
            }),
        );
    }

    dispatchError(errorCode?: string) {
        store.dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.WSMART_PURCHASE_RESULT,
                isVisible: true,
                data: {
                    isSuccess: false,
                    product: this.product,
                    errorCode: errorCode,
                    bundle: this.bundle,
                },
                forcedClosurePopupNames: [POPUPS_NAME.CONFIRM_PURCHASE],
            }),
        );
    }

    beforePurchase() {
        store.dispatch(appActions.blurView(true));
        if (!this.isWeb) {
            store.dispatch(appActions.setFetching(true));
        }
    }

    afterPurchase() {
        if (!this.isWeb) {
            store.dispatch(appActions.setFetching(false));
        }
        store.dispatch(appActions.blurView(false));
    }

    checkStatus(checkoutResult: CheckoutResult) {
        if (checkoutResult.purchaseStatus === PURCHASE_STATUSES.SUCCESS) {
            this.dispatchSuccess();
        } else if (checkoutResult.purchaseStatus === PURCHASE_STATUSES.ERROR) {
            this.dispatchError(checkoutResult?.errorCode);
        }
    }

    async buy(place: string, quantity?: number) {
        this.beforePurchase();
        const analytics = getAnalyticsMetaDataForBundle({});

        this.wowsCommerce.analytics.addEvent(place, { bundle_id: this.product.externalProductId, ...analytics });

        try {
            if (!quantity) {
                quantity = 1;
            }
            const checkoutResult = await this.wowsCommerce.checkout(
                this.product.externalProductId,
                quantity,
                this.storefrontName,
                this.bundle
                    ? {
                          productImageUrl: this.bundle.icons.small,
                          title: this.bundle.title,
                      }
                    : {},
                analytics,
            );
            this.checkStatus(checkoutResult);
        } catch (err) {
            if (err.status === PURCHASE_STATUSES.ERROR) {
                this.dispatchError();
            }
        } finally {
            if (!store.getState().app.currentPage?.isBundlePage) {
                store.dispatch(appActions.setFocusPreset(null));
            }
        }

        this.afterPurchase();
    }

    async updateProductPriceByQuantity(quantity: number): Promise<ProductEntity | void> {
        try {
            if (!quantity) {
                quantity = 1;
            }
            const variablePriceData = await this.wowsCommerce.getProductVariablePrice(this.product.externalProductId, quantity, this.storefrontName);

            return this.wowsCommerce.handleChangeProductPriceByQuantity(this.product, variablePriceData, quantity, this.storefrontName);
        } catch (err) {
            if (err.status === PURCHASE_STATUSES.ERROR) {
                this.dispatchError();
            }
        }
    }
}
