import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import accountReducer from './accountSlice';
import appReducer from './appSlice';
import auctionSlide from './auctionSlide';
import featuresSlice from './shipFeaturesSlice';
import lootboxSlice from './lootboxSlice';
import sbSlice from './sbSlice';
import tradeinSlice from './tradeinSlice';
import wsmartSlice from './wsmartSlice';
import gachaSlice from './gachaSlice';
import { settings } from '~/utils/settings';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

const PRODUCTION_MODE = 'production';

const store = configureStore({
    reducer: {
        account: accountReducer,
        app: appReducer,
        auction: auctionSlide,
        features: featuresSlice,
        lootbox: lootboxSlice,
        seabattle: sbSlice,
        tradein: tradeinSlice,
        wsmart: wsmartSlice,
        gacha: gachaSlice,
    },
    middleware: (getDefaultMiddleware) => {
        const middlewares = getDefaultMiddleware({ serializableCheck: false });

        if (process.env.NODE_ENV !== PRODUCTION_MODE || settings.debug) {
            middlewares.push(logger);
        }

        return middlewares;
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector.withTypes<RootState>();
