import * as React from 'react';
import styles from './Port.scss';
import Button from '~/components/Button/Button';
import { interpolate, t } from '~/utils/localization';
import { isCamouflage, isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { settings } from '~/utils/settings';
import Contains, { IContainsItem } from '~/components/ItemContains/Contains';
import { items as ITEMS } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { DivTooltip } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import { setScrollover } from '~/api/WoWsClient';
import { getItemById, getTotalCountPreviewItems, IPortContainItem, PortPreviewType, prepareItems, type IPortContains } from '~/components/Port/settings';
import { getShipIdForStylePreview } from '~/settings/port';
import { TooltipTypes } from '~/types/common';
import { scrollToTarget } from '~/utils/scrollTop';

export interface IPortProps {
    onClose: (withoutHistoryUpdate?: boolean) => void;
    bundle?: IBundle;
    isHiddenPurchaseButton?: boolean;
    lot?: ILot;
    shipId: number;
    exteriorId: number;
    itemType?: string;
    portPreviewType: PortPreviewType;
    isDisabledPurchase?: boolean;
    onPurchaseBundle?: () => void;
    balance?: IBalance;
    coupons?: ICoupon[];
    inventory?: InventoryState;
    lootbox?: ILootboxStateItem;
    portContains?: IPortContains;
}

interface IPortState {
    selectedItemId?: number;
    shipId: number;
    exteriorId: number;
    itemType: string;
    containsItems?: IPortContainItem[];
    totalCountPreviewItems?: number;
}

const backToArmory = t('Вернуться назад');

export default class AbstractPort extends React.PureComponent<IPortProps, IPortState> {
    protected textButtonBack = t('Назад');

    protected isAvailableContainsWidget = false;

    protected isNeedToRenderTypeItem = false;

    protected containsRef: { current: HTMLDivElement } = { current: null };

    protected timeout: NodeJS.Timeout;

    protected renderDescriptionMap: Array<string> = ['renderTitle', 'renderContainsContent', 'renderCamoInfo', 'renderNoticeContent', 'renderFooterContent'];

    constructor(props: IPortProps) {
        super(props);
        const items = prepareItems(this.props.portPreviewType, this.props.bundle || this.props.lot || this.props.portContains, this.props.inventory);
        this.state = {
            shipId: this.props.shipId,
            exteriorId: this.props.exteriorId,
            itemType: this.props.itemType,
            containsItems: items,
            totalCountPreviewItems: Array.isArray(items) && getTotalCountPreviewItems(items),
        };
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    scrollToContainItem() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            clearTimeout(this.timeout);
            // dirty scroll, mazafaka
            const id = this.props.exteriorId === -1 ? this.props.shipId : this.props.exteriorId;
            const target = this.containsRef.current?.querySelectorAll(`li[data-id="${id}"]`)[0] as HTMLDivElement;
            if (target) {
                scrollToTarget(target, this.containsRef.current);
            }
        }, 60);
    }

    setRef(ref: HTMLDivElement) {
        this.containsRef.current = ref;
        this.scrollToContainItem();
    }

    protected isItemSelected(item: IPortContainItem) {
        const _isPermanentCamouflage = isPermanentCamouflage(this.props.itemType);
        const _isCamouflage = _isPermanentCamouflage || isCamouflage(this.props.itemType);

        if (_isCamouflage) {
            if (item.customisation?.shipId) {
                return Number(item.id) === Number(this.props.exteriorId) && item.customisation.shipId === this.props.shipId;
            }
            return Number(item.id) === Number(this.props.exteriorId);
        }

        return Number(item.id) === Number(this.props.shipId);
    }

    protected goTo(step: number) {
        if (!this.state.containsItems) {
            return;
        }
        let currentIndex = this.state.containsItems.findIndex((item) => {
            return this.isItemSelected(item);
        });
        if (step < 0) {
            currentIndex -= Math.abs(step);
            if (currentIndex < 0) {
                currentIndex = this.state.totalCountPreviewItems - 1;
            }
        } else {
            currentIndex += step;
            if (currentIndex >= this.state.totalCountPreviewItems) {
                currentIndex = 0;
            }
        }
        const item = this.state.containsItems[currentIndex];
        if (item) {
            item.portPreviewOnClick(this.props.portPreviewType);
        }
    }

    private renderContent(): React.ReactChild[] {
        const content: React.ReactChild[] = [];

        this.renderDescriptionMap.forEach((method: keyof AbstractPort) => {
            // @ts-ignore
            this[method] && content.push(this[method]());
        });

        return content;
    }

    render() {
        return (
            <div className={styles.wrapper}>
                {this.state.totalCountPreviewItems > 1 && (
                    <React.Fragment>
                        <DivTooltip className={styles.prevButton} onClick={() => this.goTo(-1)} tooltipBody={<DefaultTooltip text={t('Предыдущий предмет')} />} />
                        <DivTooltip className={styles.nextButton} onClick={() => this.goTo(1)} tooltipBody={<DefaultTooltip text={t('Следующий предмет')} />} />
                    </React.Fragment>
                )}
                <div className={styles.content}>{this.renderContent()}</div>
            </div>
        );
    }

    protected renderCamoInfo(): React.ReactChild {
        if (this.props.exteriorId < 0 || !this.state.containsItems) {
            return <div className={styles.camouflageWrapper} />;
        }

        const item = getItemById(this.state.containsItems, this.props.exteriorId, this.props.shipId);

        if (!item) {
            return <div className={styles.camouflageWrapper} />;
        }

        const _isPermanentCamouflage = isPermanentCamouflage(item.type);
        const _isCamouflage = _isPermanentCamouflage || isCamouflage(item.type);
        let tooltip = null;

        if (_isCamouflage) {
            const hasShipInBundle = this.state.containsItems?.some((item) => +item.itemId === +this.props.shipId);
            if (hasShipInBundle) {
                return <div className={styles.camouflageWrapper} />;
            }
            const text = interpolate(t('Камуфляж {name} начисляется без корабля'), {
                name: this.getCamoTitle(),
            });
            tooltip = <DefaultTooltip text={text} type={TooltipTypes.WARNING} />;
        }

        let shipId = item.customisation?.shipId;
        if (_isPermanentCamouflage && !shipId) {
            shipId = Number(getShipIdForStylePreview(item.id));
        }

        if (!shipId) {
            shipId = this.props.shipId || settings.camouflagePreviewDefaultShip;
        }

        return (
            <div className={styles.camouflageWrapper}>
                {this.isNeedToRenderTypeItem && <div className={styles.camouflageWrapperSubTitle}>{_isPermanentCamouflage ? t('Постоянный камуфляж') : t('Расходуемый камуфляж')}</div>}
                <DivTooltip tooltipBody={tooltip} className={styles.camouflageVehiclePresentation}>
                    <span className={styles.camouflageVehiclePresentationTitle}>{t('Камуфляж презентован на:')}</span>
                    <WoWSEntity key={`exterior_${item.id}_${shipId}`} type={ITEMS.VEHICLES} id={shipId} />
                </DivTooltip>
            </div>
        );
    }

    protected renderTitle(): React.ReactChild {
        return null;
    }

    protected renderContainsContent(): React.ReactChild {
        if (!this.isAvailableContainsWidget || !this.state.containsItems) {
            return null;
        }

        const items: IContainsItem[] = this.state.containsItems.map((item) => {
            const isSelected = this.isItemSelected(item);
            const isActive = this.state.totalCountPreviewItems > 1 && isSelected;
            return {
                isPortPreviewEnabled: this.state.totalCountPreviewItems > 1 ? item.isEnabledPortPreview : false,
                portPreviewOnClick: () => {
                    if (!isSelected) {
                        item.portPreviewOnClick?.(this.props.portPreviewType);
                    }
                },
                onClick: () => {
                    if (!isSelected) {
                        item.portPreviewOnClick?.(this.props.portPreviewType);
                    }
                },
                classNames: {
                    item: classNames(styles.containItem, {
                        [styles.isActive]: isActive || this.state.totalCountPreviewItems === 1,
                    }),
                    portPreviewIcon: classNames({
                        [styles.isActivePortIcon]: isActive,
                    }),
                },
                isBonus: item.isBonus,
                id: item.id?.toString(),
                content: (
                    <WoWSEntity
                        type={item.type}
                        id={item.id}
                        amount={!isPermanentCamouflage(item.type) ? item.amount : null}
                        customisation={item.customisation}
                        presentation={{
                            withTooltip: true,
                            iconPosition: 'left',
                        }}
                    />
                ),
            };
        });

        return (
            <div
                className={styles.contains}
                onMouseEnter={() => {
                    setScrollover(true);
                }}
                onMouseLeave={() => {
                    setScrollover(false);
                }}
                ref={(ref) => {
                    this.setRef(ref);
                }}
            >
                <Contains items={items} limit={this.state.totalCountPreviewItems} isLarge={true} />
            </div>
        );
    }

    protected renderNoticeContent(): React.ReactChild {
        return null;
    }

    protected renderFooterContent(): React.ReactChild {
        return (
            <div className={styles.footer}>
                <Button
                    label={this.textButtonBack}
                    onClick={() => {
                        this.props.onClose();
                    }}
                />
            </div>
        );
    }

    protected getCamoTitle(): string {
        return null;
    }
}
