import styles from './BundlePageImage.scss';
import { isAvailableFullscreenTemplateWithoutGallery } from '~/utils/bundles';
import classNames from 'classnames';
import PhotoViewer from '~/components/PhotoViewer/PhotoViewer';
import PaginationDots from '~/components/PaginationDots/PaginationDots';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { isAvailableBigArt, isScrollableTemplate } from '~/Layouts/BundlePage/helpers';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import equal from 'fast-deep-equal/react';

interface IBundlePageImage {
    bundle: IBundle;
}

const BundlePageImage = ({ bundle }: IBundlePageImage) => {
    const dispatch = useDispatch();
    const currentPage = useAppSelector((state) => state.app.currentPage, equal);

    if (isScrollableTemplate(bundle) || isAvailableFullscreenTemplateWithoutGallery(bundle)) {
        return null;
    }

    const gallery = !!bundle.gallery?.length ? bundle.gallery : [];

    if (!gallery.length || (isMobileOrTabletWindow && bundle.isFullscreenGallery)) {
        const imageUrl = bundle.icons.big;
        const image = { backgroundImage: `url(${imageUrl})` };
        const iconWrapper = classNames(styles.iconWrapper, {
            [styles.serialBundle]: bundle.serialPurchase,
        });
        const iconClassNames = classNames(styles.icon, {
            [styles.serialBundle]: bundle.serialPurchase,
        });

        if (isAvailableBigArt(bundle)) {
            return <div className={styles.bundleBigImage} style={image} />;
        }

        return (
            <div className={iconWrapper}>
                <img src={imageUrl} className={iconClassNames} alt={bundle.title} />
            </div>
        );
    }

    if (!isMobileOrTabletWindow && bundle.isFullscreenGallery && gallery.length > 1) {
        const onClick = (index: number) => {
            dispatch(
                appActions.changeCurrentPage({
                    ...currentPage,
                    bgGallerySlideIndex: index,
                }),
            );
        };
        return (
            <div className={styles.PaginationDots}>
                <PaginationDots items={gallery} onChange={onClick} currentIndex={currentPage?.bgGallerySlideIndex || 0} />
            </div>
        );
    }

    return (
        <div className={styles.photoViewer}>
            <PhotoViewer photos={gallery} bundleDecorations={bundle.decoration} autoScrolling className={styles.viewer} />
        </div>
    );
};

export default BundlePageImage;
