import * as React from 'react';
import classNames from 'classnames';
import styles from '../Banner.scss';
import Button, { ButtonVariant } from '~/components/Button/Button';
import { t } from '~/utils/localization';
import { isMobileDisplay, isMobileOrTabletWindow } from '~/utils/utils';
import { BannerUtils } from '~/utils/banner';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import History from '~/utils/history';
import { settings } from '~/utils/settings';
import HoverVideo from '~/customization/HoverVideo/HoverVideo';
import { IBannerButtonVisibility, BANNER_LINK_TYPE } from '~/types/contents';
import equal from 'fast-deep-equal/react';
import { useAppSelector } from '~/Store';

interface IBannerBlock {
    data: IBanner;
    id?: number;
}

const Banner = (props: IBannerBlock): any => {
    const isTrusted = useAppSelector((state) => state.app.isTrusted, equal);
    const data = props.data;

    const backgroundBundle =
        isMobileDisplay && data.mobileImage
            ? {
                  backgroundImage: `url(${data.mobileImage})`,
              }
            : {
                  backgroundImage: `url(${data.image})`,
              };

    const handleClick = (event: React.MouseEvent, linkValue: string = data.linkValue, fromButton = false, isSecondaryButton = false) => {
        event.stopPropagation();
        if (isSecondaryButton && data.linkType !== BANNER_LINK_TYPE.EXTERNAL) {
            History.navigate(`/${settings.languageCode}${linkValue}`);
        } else {
            BannerUtils.redirectTo(props.data);
        }
        dwhExport.send(DWH_EVENTS.FEATURING_BANNER_CLICK, {
            id: props.id,
            link: linkValue,
            from_button: fromButton || null,
        });
    };

    const footerClassNames = classNames(styles.bundleBlockFooter, {
        [styles.hover]: data.buttonsVisibility === IBannerButtonVisibility.HOVER,
    });

    const bannerClassNames = classNames(styles.banner, {
        [styles.hasDescription]: !!data.description?.length,
        [styles.noMobileImage]: !data.mobileImage,
    });

    return (
        <div
            className={classNames(styles.bannerContent, styles.featuringBanner)}
            data-category-name={props.data.categoryName}
            data-link-type={props.data.linkType}
            data-link-value={props.data.linkValue}
        >
            <HoverVideo video={props.data.videoHover} isDisableAnimation={isMobileOrTabletWindow} muted={!isTrusted} parentClassName={styles.bannerContent}>
                <div
                    className={bannerClassNames}
                    style={backgroundBundle}
                    onClick={(event) => {
                        handleClick(event, data.linkValue, false);
                    }}
                >
                    <div className={styles.bundleBlockContent}>
                        <div className={styles.bundleBlockHeader}>
                            <div className={styles.bundleBlockTitle}>{data.title}</div>
                            <div className={styles.bundleBlockDescription}>{data.description}</div>
                        </div>
                        <div className={footerClassNames}>
                            {props.data.buttonsVisibility !== IBannerButtonVisibility.HIDDEN && (
                                <Button
                                    variant={ButtonVariant.orange}
                                    label={data.buttonPrimaryTitle || t('Перейти')}
                                    onClick={(event) => {
                                        handleClick(event, data.linkValue, true);
                                    }}
                                    linkTarget={data.linkType === BANNER_LINK_TYPE.EXTERNAL}
                                />
                            )}
                            {data.secondaryLink && props.data.buttonsVisibility !== IBannerButtonVisibility.HIDDEN && (
                                <Button
                                    className={styles.additionalButton}
                                    label={data.buttonSecondaryTitle}
                                    onClick={(event: React.MouseEvent) => {
                                        handleClick(event, data.secondaryLink, true, true);
                                    }}
                                    linkTarget={data.linkType === BANNER_LINK_TYPE.EXTERNAL}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </HoverVideo>
        </div>
    );
};

export default Banner;
