import * as React from 'react';
import styles from './TradeInContent.scss';
import TradeInItemsSelector from '../TradeInItemsSelector/TradeInItemsSelector';
import { Interpolate } from '@wg/wows-react-uikit';
import Button, { ButtonVariant } from '~/components/Button/Button';
import Currency from '~/components/Currency/Currency';
import { t } from '~/utils/localization';
import { ChooseItemFor, ITradeItem } from '~/types/tradein';
import { useDispatch } from 'react-redux';
import { POPUPS_NAME } from '~/components/PopupManager';
import Account from '~/account/Account';
import { isEmptyObject, redirectToLogin } from '~/utils/utils';
import Filters, { FILTERS_TYPE } from '~/components/Filters/Filters';
import { assets, items } from '@wg/wows-entities/const';
import ActiveFilters, { ActiveFiltersType } from '~/components/Filters/ActiveFilter';
import CurrencyShortageBlock from '~/components/CurrencyShortageBlock/CurrencyShortageBlock';
import FastGoldLinkContainer from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import { getPrice } from '~/utils/tradein';
import { calculatePrice } from '~/utils/purchase';
import { SteelExchangePrices } from '~/components/PriceWrapper/PriceWrapper';
import { startHeadShakeAnimation } from '~/utils/auction';
import RefManager, { RefManagerKeys } from '~/RefManager';
import SortDisplay from '~/components/SortDisplay/SortDisplay';
import { appActions } from '~/Store/appSlice';
import { tradeinActions } from '~/Store/tradeinSlice';

interface ITradeInContent {
    selectedTargetItem: ITradeItem;
    selectedSourceItem: ITradeItem;
    choosenItemFor: ChooseItemFor;
    availableFilters: FILTERS_TYPE;
    activeFilters: FILTERS_TYPE;
    type: string;
    balance: IBalance;
    category: ICategory;
    onChangeFilter?: () => void;
    onItemClick?: (type: ChooseItemFor) => void;
}

const TradeInContent = ({ selectedTargetItem, selectedSourceItem, choosenItemFor, type, availableFilters, activeFilters, balance, onChangeFilter, onItemClick, category }: ITradeInContent) => {
    const dispatch = useDispatch();
    const price = getPrice(selectedSourceItem?.price, selectedTargetItem?.price);
    const pricesMap = calculatePrice({ [selectedTargetItem?.currency]: price }, balance);
    const isAuthorized = !!Account.getAccount()?.id;

    const preparedActiveFilters = React.useMemo<ActiveFiltersType>(() => {
        if (!activeFilters || isEmptyObject(activeFilters)) {
            return {};
        }
        return Object.keys(activeFilters).reduce((accumulator: ActiveFiltersType, key: FILTER_INFO_NAME) => {
            if (!accumulator[key]) {
                accumulator[key] = { values: [] };
            }
            accumulator[key].values.push(...activeFilters[key]);
            return accumulator;
        }, {});
    }, [activeFilters]);

    const onTradeIn = () => {
        if (isAuthorized) {
            dispatch(
                appActions.changeVisibilityPopup({
                    name: POPUPS_NAME.TRADEIN_CONFIRMATION,
                    isVisible: true,
                }),
            );
        } else {
            redirectToLogin();
        }
    };

    const onFallback = () => {
        if (!selectedSourceItem?.identifier) {
            startHeadShakeAnimation(RefManager.getRef(RefManagerKeys.TradeInSourceItem), styles.shakeAnimation);
        } else if (!selectedTargetItem?.identifier) {
            startHeadShakeAnimation(RefManager.getRef(RefManagerKeys.TradeInTargetItem), styles.shakeAnimation);
        }
    };

    return (
        <React.Fragment>
            <div className={styles.content}>
                <TradeInItemsSelector type={type} selectedTargetItem={selectedTargetItem} selectedSourceItem={selectedSourceItem} choosenItemFor={choosenItemFor} onItemClick={onItemClick} />

                <div className={styles.price}>
                    {selectedTargetItem?.identifier && selectedSourceItem?.identifier && (
                        <Interpolate
                            str={t('Обменять корабль за %(amount)s?')}
                            className={styles.prices}
                            amount={
                                Object.keys(pricesMap.final).length === 1 ? (
                                    <Currency currency={selectedTargetItem.currency} amount={pricesMap.final[selectedTargetItem.currency]} className={styles.currency} />
                                ) : (
                                    <SteelExchangePrices calculatePrice={pricesMap} className={styles.steelExchange} plusClassName={styles.plusClassName} priceClassName={styles.priceExchange} />
                                )
                            }
                        />
                    )}
                </div>

                <div className={styles.tradeButton}>
                    {isEmptyObject(pricesMap.lack) || !isAuthorized ? (
                        <Button
                            variant={ButtonVariant.orange}
                            label={t('Обменять')}
                            onClick={onTradeIn}
                            onFallback={onFallback}
                            disabled={!(selectedTargetItem?.identifier && selectedSourceItem?.identifier)}
                        />
                    ) : (
                        <div className={styles.shortageWrapper}>
                            <CurrencyShortageBlock currencies={[{ amount: Math.abs(pricesMap.lack[selectedSourceItem?.currency]), currency: selectedSourceItem?.currency }]} />
                            {selectedSourceItem?.currency === assets.GOLD && <FastGoldLinkContainer goldShortage={Math.abs(pricesMap.lack[assets.GOLD])} className={styles.shortageBlock} />}
                        </div>
                    )}
                </div>
            </div>

            {availableFilters && !isEmptyObject(availableFilters) && type === items.VEHICLES && (
                <div className={styles.filtersWrapper}>
                    <Filters
                        className={styles.filters}
                        iconClassName={styles.filtersIcon}
                        activeFilters={activeFilters}
                        onChangeFilter={(filterName, value) => {
                            onChangeFilter?.();
                            dispatch(tradeinActions.updateFilter({ filterName, value }));
                        }}
                        filters={availableFilters}
                        column={4}
                    >
                        <div className={styles.activeFiltersContent}>
                            <ActiveFilters
                                onClick={(filterName, value) => {
                                    onChangeFilter?.();
                                    dispatch(tradeinActions.updateFilter({ filterName, value }));
                                }}
                                resetAll={() => {
                                    onChangeFilter?.();
                                    dispatch(tradeinActions.clearFilters());
                                }}
                                activeFilters={preparedActiveFilters}
                            />
                        </div>
                    </Filters>

                    <SortDisplay category={category?.name} />
                </div>
            )}
        </React.Fragment>
    );
};

export default TradeInContent;
