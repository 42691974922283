import styles from './SantaCustomization.scss';
import { IPurchasePopupDetails } from '~/components/Popups/Purchase/PurchasePopupDetails';
import BundleLimitContainer from '~/components/BundleLimitWidget/BundleLimitContainer';
import { BundleLimitSize } from '~/components/BundleLimitWidget/BundleLimitWidget';
import { t } from '~/utils/localization';
import { CheckboxWithLabel } from '@wg/wows-react-uikit';
import PriceWrapper, { PRICE_WRAPPER_PLACE, PRICE_WRAPPER_SIZE } from '~/components/PriceWrapper/PriceWrapper';
import RefManager, { RefManagerKeys } from '~/RefManager';
import classNames from 'classnames';
import { getAmountString } from '~/Layouts/BundlePage/helpers';
import { CURRENCY } from '~/const';
import QuantityContainer from '~/containers/QuantityContainer/QuantityContainer';

const PurchasePopupSantaDetails = ({
    bundle,
    isEnabledPurchaseLimitToContainer,
    canChangeQuantity,
    activeCouponId,
    activeCoupon,
    isNeedToShownAdultCheckbox,
    isAdult,
    changeUserAdultFlag,
    hasRestriction,
}: IPurchasePopupDetails) => {
    const amount = bundle.primaryItem.amount * (bundle.quantityData?.quantity || 1);
    const text = getAmountString(amount, '<b>', '</b>');

    const setRestrictionRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            RefManager.setRef(RefManagerKeys.PurchaseRestriction, _ref);
        }
    };

    return (
        <div className={styles.purchaseDetails}>
            <div className={styles.purchaseDetailsHeader}>
                <div className={styles.purchaseDetailsTitle} dangerouslySetInnerHTML={{ __html: bundle.title }} />
                {amount > 1 && !canChangeQuantity && <div className={styles.purchaseDetailsQuantity} dangerouslySetInnerHTML={{ __html: text }} />}
                {isEnabledPurchaseLimitToContainer && (
                    <div className={styles.purchaseDetailsLimit}>
                        <BundleLimitContainer size={BundleLimitSize.BIG} className={styles.bundleLimitWrapper} bundle={bundle} />
                    </div>
                )}
            </div>
            <div className={styles.purchaseDetailsImages}>
                <img src={bundle.icons.small} className={styles.purchaseDetailsImage} alt={bundle.title} />
            </div>
            {!hasRestriction && (
                <div className={styles.purchaseAdditionalDetails}>
                    {canChangeQuantity && (
                        <div className={styles.quantityWidget}>
                            <QuantityContainer bundle={bundle} />
                        </div>
                    )}
                    <div
                        className={classNames(styles.priceContent, {
                            [styles.eventum]: bundle.currency === CURRENCY.EVENTUM_10,
                        })}
                    >
                        <div className={styles.purchasePopupPriceTitle}>{t('Цена:')}</div>
                        <PriceWrapper
                            bundle={bundle}
                            coupon={activeCoupon}
                            size={PRICE_WRAPPER_SIZE.BIG}
                            showExchangePrices
                            place={PRICE_WRAPPER_PLACE.POPUP}
                            withoutAnimation={activeCouponId === null || activeCouponId === undefined}
                            oldPriceClassName={styles.oldPrice}
                            newPriceClassName={styles.newPrice}
                            wrapperClassName={styles.priceWrapper}
                        />
                    </div>
                </div>
            )}
            {!hasRestriction && isNeedToShownAdultCheckbox && (
                <div className={styles.purchaseRestriction} ref={setRestrictionRef}>
                    <CheckboxWithLabel
                        isChecked={isAdult}
                        className={classNames({
                            [styles.warn]: !isAdult,
                        })}
                        labelText={<span className={styles.ageRestrictionLabel}>{t('Я подтверждаю, что мне исполнилось 18 лет')}</span>}
                        onChange={changeUserAdultFlag}
                    />
                </div>
            )}
        </div>
    );
};

export default PurchasePopupSantaDetails;
