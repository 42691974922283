import History from '~/utils/history';
import store from '~/Store';
import { logInfo } from '~/utils/logging';
import { settings } from '~/utils/settings';
import { getUrl, urlsMap } from '~/utils/menu';
import { isDevWoWSClient } from '~/utils/devMode';
import { PortPreviewType } from '~/components/Port/settings';
import { closePortPreview } from '@wg/web2clientapi/port/closePortPreview';
import { openShipPreviewInPort } from '@wg/web2clientapi/port/openShipPreviewInPort';
import { sendCommand } from '@wg/web2clientapi/core/command';
import { sendAction as web2clientSendAction } from '@wg/web2clientapi/core/action';
import { changeTTXVisibility } from '@wg/web2clientapi/port/changeTTXVisibility';
import { onClientStateChange } from '@wg/web2clientapi/port/onClientStateChange';
import { appActions } from '~/Store/appSlice';
import { openExternalUrl } from '@wg/web2clientapi/browser/openExternalUrl';

export {
    playCheckboxSound,
    playDropdownSound,
    playInputSound,
    playButtonClickSound,
    playButtonDialogClickSound,
    playButtonWalletClickSound,
    playButtonGoldClickSound,
    playCheckboxClickSound,
    playDropdownClickSound,
    playTabClickSound,
    playCardClickSound,
    playPaperCardClickSound,
    playExchangeSound,
} from '@wg/web2clientapi/sound';

interface IClientApiData {
    actionId: string;
    enabled?: boolean;
    shipId?: number;
    exteriorId?: number;
    showTTX?: boolean;
    browserHasControl?: boolean;
    soundsetName?: string;
    url?: string;
    visible?: boolean;
}

export const openPort = (
    shipId: number,
    exteriorId = -1,
    itemType: string,
    portPreviewType: PortPreviewType,
    id: number | string,
    isVisible = false,
    isLoading = true,
    sourceUrl: string = location.pathname,
    queryParams: Record<string, string> = {},
) => {
    let showTTX = !settings.isShipTTXPreviewDisabled;
    if (exteriorId !== -1) {
        showTTX = false;
    }

    store.dispatch(appActions.setFetching(true));
    store.dispatch(appActions.changeVisibilityPort({ isVisible, shipId, exteriorId, portPreviewType, id, isLoading, sourceUrl, showTTX, itemType, additionalData: queryParams }));

    openShipPreviewInPort(shipId, exteriorId, showTTX);

    if (isDevWoWSClient()) {
        window.onClientStateChange(JSON.stringify({ actionId: 'startShipPreview' }));
    }
};

export const closePort = () => {
    store.dispatch(appActions.changeVisibilityPort({ isVisible: false, isLoading: false }));
    changeTTXVisibility(false);
    closePortPreview();
};

export const request = (data: any) => {
    sendCommand(data);
};

export const sendAction = (params: IClientApiData) => {
    logInfo('attempt to execute a request to the client', JSON.stringify(params));
    web2clientSendAction(params);
};

export const setClientStateListener = () => {
    logInfo('client state listener init');

    onClientStateChange((data) => {
        switch (data.actionId) {
            case 'startShipPreview':
                const portData = store.getState().app.port;
                const portUrl = getUrl(urlsMap.port, {
                    id: portData.id,
                    shipId: portData.shipId,
                    exteriorId: portData.exteriorId,
                    sourceUrl: portData.sourceUrl,
                    portPreviewType: portData.portPreviewType,
                    itemType: portData.itemType,
                });

                History.navigate(portUrl);

                store.dispatch(
                    appActions.changeVisibilityPort({
                        isVisible: true,
                        shipId: portData.shipId,
                        exteriorId: portData.exteriorId,
                        portPreviewType: portData.portPreviewType,
                        id: portData.id,
                        isLoading: false,
                        sourceUrl: portData.sourceUrl,
                        showTTX: portData.showTTX,
                        itemType: portData.itemType,
                        additionalData: portData.additionalData,
                    }),
                );
                store.dispatch(appActions.setFetching(false));
                break;

            default:
                break;
        }
    });
};

export const setScrollover = (isOver: boolean) => {
    sendAction({
        actionId: 'updateBrowserOverScrollableState',
        enabled: isOver,
    });
};
