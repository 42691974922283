import store from '~/Store';
import { cancelBid as apiCancelBid, completeAction, placeBid, syncAccount } from '~/api/auction';
import { POPUPS_NAME } from '~/components/PopupManager';
import { logError } from '~/utils/logging';
import { AUCTION_ACTION_POST_PREFIX, finishedStatuses, getActionIdWithPostPrefixByType, getBidWithShipFromBids, getPreviewVideo, isLost, isWon } from '~/utils/auction';
import { DWH_EVENTS } from '~/const';
import { authorizationRequired, redirectToLoginIfNeeded } from '~/decorators/ts';
import { idleTracker, syncAccountInfo } from '~/sync';
import dwhExport from '~/api/dwhExport';
import { isMobileOrTabletWindow, redirectToLogin } from '~/utils/utils';
import { isNeedToShowDownloadGamePopup, showDownloadGamePopup } from '~/components/Popups/settings';
import Account from '~/account/Account';
import { BID_STATUSES } from '~/types/auction';
import { HIDE_ALL_POPUPS, appActions } from '~/Store/appSlice';
import { auctionActions } from '~/Store/auctionSlide';

export default class AuctionProcessor {
    private readonly auction: IAuction;

    private readonly lot: ILot;

    private readonly account: IAuctionAccountState;

    constructor(auction: IAuction, lot: ILot, account?: IAuctionAccountState) {
        this.auction = auction;
        this.lot = lot;
        this.account = account;
    }

    @authorizationRequired
    static async completeActions(id: string, auctionIds: string[]) {
        completeAction(id, auctionIds)
            .then((response) => {
                store.dispatch(auctionActions.updateAuctionAccountState(response.account));
            })
            .catch((e) => {
                logError('failed marked reward screen', e);
            });
    }

    @authorizationRequired
    static isCompletedAction(actionId: string) {
        const completedActions = (store.getState().auction.account?.completedActions || []).map((action: IAuctionAccountCompletedAction) => {
            return action.actionId;
        });

        return completedActions.includes(actionId);
    }

    @authorizationRequired
    static checkWonBids(activeBids: IActiveBids[]) {
        const auctionState = store.getState().auction;
        if (!auctionState.activeAuctions?.length) {
            return;
        }

        if (!activeBids.length) {
            return;
        }

        const state = store.getState().app;
        if (!!state.popupActive) {
            return;
        }

        activeBids = activeBids.filter((bid) => {
            const actionId = getActionIdWithPostPrefixByType(bid.lot.id, AUCTION_ACTION_POST_PREFIX.WON_POPUP);
            const isNotViewed = !AuctionProcessor.isCompletedAction(actionId);
            return isWon(bid.status) && isNotViewed;
        });

        const showWonPopup = () => {
            store.dispatch(
                appActions.changeVisibilityPopup({
                    name: POPUPS_NAME.AUCTION_WON_POPUP,
                    isVisible: true,
                    data: {
                        bids: activeBids,
                    },
                    forcedClosurePopupNames: [POPUPS_NAME.BID_PLACED_POPUP],
                }),
            );
        };

        if (!!activeBids.length) {
            const bidWithShipLot = getBidWithShipFromBids(activeBids);
            const previewVideoUrl = bidWithShipLot && getPreviewVideo(bidWithShipLot.lot);
            if (!isMobileOrTabletWindow && bidWithShipLot && previewVideoUrl) {
                store.dispatch(appActions.startVideo({ name: 'preview_auction', url: previewVideoUrl, onFinish: showWonPopup, volume: 50 }));
            } else {
                showWonPopup();
            }
        }
    }

    public async apiPlacedBid(lotId: string, bid: number) {
        return await placeBid(lotId, bid);
    }

    public async cancelBid() {
        store.dispatch(auctionActions.addLotInTransaction(this.lot.id));
        apiCancelBid(this.lot.id)
            .then((response) => {
                store.dispatch(auctionActions.setBidLot({ lotId: this.lot.id, bid: this.lot.minimalBid.amount }));
                dwhExport.send(DWH_EVENTS.BID_CANCELED, { lot_id: this.lot.id });
                store.dispatch(appActions.changeVisibilityPopup({ name: HIDE_ALL_POPUPS }));
                store.dispatch(auctionActions.updateAuctionAccountState(response.account));
                store.dispatch(auctionActions.removeLotFromTransaction(this.lot.id));
            })
            .catch((e) => {
                logError('failed cancel bid', e);
            });
    }

    public showBetPopup(lot: ILot, fromLotPage = false, closeCallback?: (...rest: any[]) => void) {
        if (!Account.getAccount()) {
            return redirectToLogin();
        }

        if (isNeedToShowDownloadGamePopup()) {
            return showDownloadGamePopup();
        }

        store.dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.BID_PLACED_POPUP,
                isVisible: true,
                data: {
                    lot: lot,
                    fromLotPage,
                    closeCallback,
                },
            }),
        );
    }

    public isDisabledChangeBid() {
        return !this.auction.bidRaisingAllowed && !this.auction.bidDropAllowed && !this.auction.bidCancellationsAllowed;
    }

    @authorizationRequired
    public static startSyncCheckBidStatusIfNeeded() {
        const auctionState = store.getState().auction;
        const activeBids = auctionState.account?.activeBids || [];
        const activeTransactions = activeBids.filter((bid: IActiveBids) => {
            return bid.status === BID_STATUSES.PLACING || bid.status === BID_STATUSES.CANCELING;
        });

        if (!activeTransactions?.length) {
            return;
        }

        activeTransactions.forEach((transaction: IActiveBids) => {
            if (!auctionState.account?.transactions?.includes(transaction.lot.id)) {
                store.dispatch(auctionActions.addLotInTransaction(transaction.lot.id));
            }
        });

        AuctionProcessor.startSyncCheckBidStatus();
    }

    static syncTimeout: NodeJS.Timeout;

    @authorizationRequired
    static checkAccountResponse(account: IAuctionAccountState) {
        const accountState = store.getState().auction?.account;
        const activeAuction = store.getState().auction?.activeAuctions || [];
        const activeTransactions = accountState?.transactions || [];
        const activeBids = accountState?.activeBids;

        const getPrevStateActiveBid = (lotId: string) => {
            return activeBids.filter((activeBid: IActiveBids) => activeBid.lot.id === lotId)[0];
        };

        account?.activeBids?.forEach((activeBid) => {
            if (activeTransactions.includes(activeBid.lot.id)) {
                const prevState = getPrevStateActiveBid(activeBid.lot.id);
                switch (activeBid.status) {
                    case BID_STATUSES.PLACED: {
                        dwhExport.send(DWH_EVENTS.BID_PLACED, { bid: activeBid.bid.amount, lot_id: activeBid.lot.id });
                        if (!prevState || prevState?.status === BID_STATUSES.CANCELED || prevState?.status === BID_STATUSES.PLACING || prevState?.status === BID_STATUSES.PLACED) {
                            const auction = activeAuction?.filter((auc: IAuction) => auc.id === activeBid.lot.auctionId)?.[0];
                            if (auction) {
                                store.dispatch(
                                    appActions.changeVisibilityPopup({
                                        name: POPUPS_NAME.BID_SUCCESSFULLY_PLACED,
                                        isVisible: true,
                                        data: {
                                            bet: activeBid.bid.amount,
                                            currency: activeBid.bid.currency,
                                            auction: auction,
                                            lot: activeBid.lot,
                                        },
                                        forcedClosurePopupNames: [POPUPS_NAME.BID_PLACED_POPUP],
                                    }),
                                );
                            }
                        }
                        store.dispatch(auctionActions.removeLotFromTransaction(activeBid.lot.id));
                        break;
                    }

                    case BID_STATUSES.PLACING_ERROR: {
                        const actionId = getActionIdWithPostPrefixByType(activeBid.id.toString(), AUCTION_ACTION_POST_PREFIX.ERROR_BID);
                        const isNotViewed = !AuctionProcessor.isCompletedAction(actionId);
                        dwhExport.send(DWH_EVENTS.BID_PLACING_ERROR, { bid: activeBid.bid.amount, lot_id: activeBid.lot.id });
                        if (isNotViewed) {
                            AuctionProcessor.completeActions(activeBid.lot.auctionId, [actionId]);
                            store.dispatch(
                                appActions.changeVisibilityPopup({
                                    name: POPUPS_NAME.BID_ERROR_PLACED,
                                    isVisible: true,
                                    forcedClosurePopupNames: [POPUPS_NAME.BID_PLACED_POPUP],
                                }),
                            );
                        }
                        break;
                    }

                    case BID_STATUSES.CANCELING_ERROR:
                    case BID_STATUSES.CANCELED: {
                        store.dispatch(auctionActions.removeLotFromTransaction(activeBid.lot.id));
                        break;
                    }
                }
            }
        });
    }

    @authorizationRequired
    static startSyncCheckBidStatus() {
        clearTimeout(AuctionProcessor.syncTimeout);

        const limitError = 10;
        let counterError = 0;

        const sync = () => {
            AuctionProcessor.syncTimeout = setTimeout(async () => {
                if (!idleTracker.isUserActive()) {
                    sync();
                    return;
                }

                const accountState = store.getState().auction?.account;
                const activeTransactions = accountState?.transactions || [];

                if (!activeTransactions?.length) {
                    clearTimeout(AuctionProcessor.syncTimeout);
                    return;
                }

                try {
                    const response = await syncAccount();

                    if (!response) {
                        if (counterError === limitError) {
                            clearTimeout(AuctionProcessor.syncTimeout);
                            return;
                        }
                        counterError++;
                        sync();
                        return;
                    }

                    AuctionProcessor.checkAccountResponse(response.account);

                    store.dispatch(auctionActions.updateAuctionAccountState(response.account));

                    sync();
                } catch (e) {
                    logError('Auction account sync error', e);
                }
            }, 2000);
        };

        sync();
    }

    @redirectToLoginIfNeeded
    public async placedBit(lotId: string, currency: string, amount: number, callback?: () => void, fromLotPage = false) {
        store.dispatch(auctionActions.addLotInTransaction(lotId));

        dwhExport.send(DWH_EVENTS.BID_PLACING, { place: fromLotPage ? 'card' : 'category', lot_id: lotId });

        this.apiPlacedBid(lotId, amount)
            .then(async (response) => {
                await AuctionProcessor.checkAccountResponse(response.account);
                store.dispatch(auctionActions.updateAuctionAccountState(response.account));
                AuctionProcessor.startSyncCheckBidStatus();
                try {
                    await syncAccountInfo();
                } catch (e) {
                    logError('Account sync error after placed bid', e);
                }
            })
            .catch(() => {
                store.dispatch(
                    appActions.changeVisibilityPopup({
                        name: POPUPS_NAME.BID_ERROR_PLACED,
                        isVisible: true,
                        forcedClosurePopupNames: [POPUPS_NAME.BID_PLACED_POPUP],
                    }),
                );
            });
    }

    public getFinishedAt() {
        return new Date(this.lot.finishedAt || this.auction?.finishedAt).getTime();
    }

    public isFinishedLot() {
        if (!this.auction) {
            return true;
        }
        return this.getFinishedAt() <= Date.now() || finishedStatuses.includes(this.auction.status);
    }

    @authorizationRequired
    public getPlacedActiveBidByLotId(lotId?: string) {
        lotId = lotId || this.lot.id;

        return this.account?.activeBids?.filter((bid) => {
            return bid.lot.id === lotId && (bid.status === BID_STATUSES.PLACED || isWon(bid.status) || isLost(bid.status));
        })[0];
    }

    @authorizationRequired
    public isBidAlreadyPlaced(lotId?: string) {
        if (!this.account?.activeBids?.length) {
            return false;
        }

        lotId = lotId || this.lot.id;

        return this.account.activeBids.some((bid) => bid.lot.id === lotId && (bid.status === BID_STATUSES.PLACED || isWon(bid.status) || isLost(bid.status)));
    }

    @authorizationRequired
    public getActiveBidByLotId(lotId?: string): IBids {
        if (!this.account?.activeBids?.length) {
            return null;
        }

        return this.getPlacedActiveBidByLotId(lotId);
    }

    @authorizationRequired
    public getBid() {
        if (!this.account?.activeBids?.length) {
            return null;
        }

        return this.account.activeBids.filter((bid) => {
            return bid.lot.id === this.lot.id;
        })[0];
    }

    public getCoolDownAt() {
        const activeBidData = this.getPlacedActiveBidByLotId();
        if (!activeBidData) {
            return null;
        }

        const placedAt = new Date(activeBidData.placedAt).getTime();
        const coolDown = this.auction.bidCoolDown * 1000;

        return new Date(placedAt + coolDown).getTime();
    }

    public isCoolDown() {
        const activeBidData = this.getPlacedActiveBidByLotId();

        if (!activeBidData || !this.auction.bidCoolDown) {
            return false;
        }

        return Date.now() < this.getCoolDownAt();
    }

    @authorizationRequired
    public getBidStatus(lotId?: string) {
        if (!this.account?.activeBids?.length) {
            return null;
        }

        const data = this.getPlacedActiveBidByLotId(lotId);
        if (!data) {
            return null;
        }

        return data.status;
    }

    @authorizationRequired
    public isBigPlacing() {
        return this.account?.activeBids?.some((bid) => {
            return bid.status === BID_STATUSES.PLACING;
        });
    }

    @authorizationRequired
    public isBigPlacingByLot() {
        return this.account?.activeBids?.some((bid) => {
            return bid.status === BID_STATUSES.PLACING && bid.lot.id === this.lot.id;
        });
    }

    @authorizationRequired
    public isBidCanceling(isCurrentLot = false) {
        return this.account?.activeBids?.some((bid) => {
            const isCanceling = bid.status === BID_STATUSES.CANCELING || bid.status === BID_STATUSES.CANCELING_ERROR;
            if (!isCurrentLot) {
                return isCanceling;
            }
            return isCanceling && bid.lot.id === this.lot.id;
        });
    }

    public isAlreadyHaveLot(lotId: string): boolean {
        const isBidAlreadyPlaced = this.isBidAlreadyPlaced(lotId);
        const isAlreadyHaveLot = this.account?.uniqueLotsInInventory?.includes(lotId);

        return !isBidAlreadyPlaced && isAlreadyHaveLot;
    }

    public canPlacedBid(lotId: string): boolean {
        if (!this.account) {
            return true;
        }

        if (!this.auction || (this.account.isStaff && !this.auction.staffAllowed)) {
            return false;
        }

        return !this.isAlreadyHaveLot(lotId);
    }

    public canCanceledBid(lotId: string): boolean {
        return this.auction.bidCancellationsAllowed && !this.isCoolDown() && this.getBidStatus(lotId) === BID_STATUSES.PLACED;
    }
}
