import * as React from 'react';
import styles from './ModernizationShips.scss';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { items } from '@wg/wows-entities/const';
import classNames from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import { interpolate, ngettext } from '~/utils/localization';
import ShipsTooltip from '../Tooltip/ShipsTooltip';

interface IModernizationShips {
    className?: string;
    shipIds: number[];
    limit?: number;
}

const getShipComponent = (id: number) => {
    return (
        <WoWSEntity
            type={items.VEHICLES}
            id={id}
            presentation={{
                withTooltip: true,
                renderWithoutFade: true,
            }}
            events={{
                tooltipVisibilityChange: (isVisibleTooltip: boolean) => {
                    isVisibleTooltip && window.tooltipProvider?.hide();
                },
            }}
        />
    );
};

const ModernizationShips = ({ className, shipIds, limit }: IModernizationShips) => {
    if (shipIds.length === 1) {
        return (
            <div className={className} onMouseEnter={() => window.tooltipProvider?.hide()}>
                {getShipComponent(shipIds[0])}
            </div>
        );
    }

    const copiedArray = [...shipIds];
    const shipsForDisplay = copiedArray.splice(0, limit || 2);

    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.lists}>
                {shipsForDisplay.map((shipId, index) => {
                    return (
                        <div className={styles.item} key={`mod_ship_${shipId}`}>
                            {getShipComponent(shipId)}
                            {index + 1 === shipsForDisplay.length && !!copiedArray.length && (
                                <DivTooltip className={styles.moreShips} tooltipBody={<ShipsTooltip shipIds={copiedArray} />}>
                                    {interpolate(ngettext('+ {amount} корабль', '+ {amount} корабля', copiedArray.length), { amount: copiedArray.length })}
                                </DivTooltip>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ModernizationShips;
