import * as React from 'react';
import styles from './Tooltip.scss';
import { interpolate, ngettext, t } from '~/utils/localization';
import { TooltipFooter } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import { TooltipTypes } from '~/types/common';

interface IAccountPremiumTooltip {
    title?: string;
    warshipsPremiumSeconds?: number;
    generalPremiumSeconds?: number;
    text?: string;
    footer?: any;
    type?: TooltipTypes;
    mouseIcon?: boolean;
}

const AccountPremiumTooltip = (props: IAccountPremiumTooltip): React.ReactChild | any => {
    const bodyClassNames = classNames(styles.defaultTooltipBody, {
        [styles.warning]: props.type === TooltipTypes.WARNING,
    });

    const getPremiumDays = (seconds: number) => {
        const daysLeft = Math.floor(seconds / 86400);
        let hoursLeft = Math.ceil((seconds / 3600) % 24);
        hoursLeft = hoursLeft > 23 ? 23 : hoursLeft;

        if (daysLeft < 1) {
            return interpolate(ngettext('{count} час', '{count} часа', hoursLeft), {
                count: hoursLeft,
            });
        }

        return interpolate(ngettext('{days} день', '{days} дня', daysLeft), { days: daysLeft }) + ' ' + interpolate(ngettext('{count} час', '{count} часа', hoursLeft), { count: hoursLeft });
    };

    const isAccountPremium = props.warshipsPremiumSeconds > 0 || props.generalPremiumSeconds > 0;

    return (
        <React.Fragment>
            <div className={styles.defaultTooltip}>
                {props.title && <div className={styles.defaultTooltipHeader}>{props.title}</div>}
                {isAccountPremium && (
                    <div className={styles.accountPremiumsWrapper}>
                        {!!props.warshipsPremiumSeconds && (
                            <div className={classNames(styles.accountPremium, styles.activeAccountPremium)}>
                                <div className={styles.accountPremiumName}>{t('Корабельный премиум:')}&nbsp;</div>
                                {getPremiumDays(props.warshipsPremiumSeconds)}
                            </div>
                        )}
                        {!!props.generalPremiumSeconds && (
                            <div className={classNames(styles.accountPremium, { [styles.activeAccountPremium]: !props.warshipsPremiumSeconds })}>
                                <div className={styles.accountPremiumName}>{t('Общий премиум:')}&nbsp;</div>
                                {getPremiumDays(props.generalPremiumSeconds)}
                            </div>
                        )}
                    </div>
                )}
                {props.text && <div className={bodyClassNames} dangerouslySetInnerHTML={{ __html: props.text }} />}
            </div>
            {props.footer && (
                <div className={styles.footer}>{props.mouseIcon ? <TooltipFooter mouseIcon={'left'}>{props.footer}</TooltipFooter> : <div className={styles.footerText}>{props.footer}</div>}</div>
            )}
        </React.Fragment>
    );
};

export default AccountPremiumTooltip;
