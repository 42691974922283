import * as React from 'react';
import styles from './HelperIcon.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import { playButtonClickSound } from '~/api/WoWsClient';

interface IHelper {
    label: string;
    tooltipBody?: React.ReactChild;
    onClick?: () => void;
    className?: string;
}

const HelperIcon = (props: IHelper) => {
    return (
        <DivTooltip tooltipBody={props.tooltipBody}>
            <div
                className={classNames(styles.aboutLabel, props.className)}
                onClick={() => {
                    playButtonClickSound();
                    props.onClick?.();
                }}
            >
                <span>{props.label}</span>
            </div>
        </DivTooltip>
    );
};

export default HelperIcon;
