import * as React from 'react';
import { PurchasedItem } from '~/components/Purchased/Purchased';
import { t } from '~/utils/localization';
import { settings } from '~/utils/settings';
import { DivTooltip } from '@wg/wows-react-uikit';
import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal';
import WSMartPurchaseProcessor from '~/processors/WSMartPurchaseProcessor';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { DWH_EVENTS as WOWS_COMMERCE_DWH_EVENTS } from '@wg/wows-commerce/constants/dwhEvents';
import { POPUPS_NAME } from '~/components/PopupManager';
import { isFreeBundle } from '~/utils/bundles';
import { PurchaseButtonTheme, purchaseButtonsMap } from './PurchaseButton';
import { BundleContext } from '../Bundle/BundleContext';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import { ButtonVariant } from './Button';

interface IRealPurchaseButton {
    bundle: IBundle;
    quantity?: number;
    buttonClassName?: string | string[];
    onMouseMove?: (event: React.MouseEvent) => void;
    isDisabled?: boolean;
    onClickFromDisableButton?: (event: React.MouseEvent) => void;
    purchaseButtonTheme?: PurchaseButtonTheme;
}

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        regional: state.wsmart.regional,
    };
};

const RealPurchaseButton = ({ bundle, buttonClassName, onMouseMove, onClickFromDisableButton, isDisabled, quantity, purchaseButtonTheme }: IRealPurchaseButton) => {
    const dispatch = useDispatch();
    const { preset } = React.useContext(BundleContext);
    const state = useAppSelector(stateSelector, equal);

    if (bundle.isPurchased) {
        return <PurchasedItem bundle={bundle} />;
    }

    const ButtonComponent = purchaseButtonsMap[purchaseButtonTheme || PurchaseButtonTheme.default];

    if (bundle.isLoading) {
        return <ButtonComponent label={t('Купить')} disabled />;
    }

    if (bundle.originalProduct?.isVariablePriceProduct && !state.currentPage?.isBundlePage) {
        return <ButtonComponent label={t('Перейти')} />;
    }

    const isFree = isFreeBundle(bundle);

    if (settings.disableRealPurchaseForTesting && !isFree) {
        return (
            <DivTooltip tooltipBody={<DefaultTooltip text={t('Покупки недоступны на этапе тестирования')} />}>
                <ButtonComponent label={t('Купить')} disabled={true} />
            </DivTooltip>
        );
    }

    const dwhEventName = state.currentPage?.isBundlePage ? WOWS_COMMERCE_DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_BUNDLE : WOWS_COMMERCE_DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_CATEGORY;

    return (
        <ButtonComponent
            label={isFree ? t('Получить') : t('Купить')}
            variant={ButtonVariant.orange}
            isFree={isFree}
            onMouseMove={onMouseMove}
            onFallback={onClickFromDisableButton}
            onClick={(event) => {
                if (!bundle.originalProduct?.isPurchasable) {
                    return;
                }
                event.stopPropagation();
                if (state.regional && !state.regional.purchaseIsAllowed) {
                    dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.FORBIDDEN_REGION, isVisible: true, data: state.regional }));
                    return;
                }
                const wsmartProcessor = new WSMartPurchaseProcessor(bundle.originalProduct);
                wsmartProcessor.setBundle(bundle);
                if (!state.currentPage?.isBundlePage) {
                    dispatch(appActions.setFocusPreset(preset));
                }
                wsmartProcessor.buy(dwhEventName, quantity || 1);
            }}
            disabled={isDisabled || !bundle.originalProduct?.isPurchasable}
            className={buttonClassName}
        />
    );
};

export default RealPurchaseButton;
