import { DivTooltip } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import { interpolate, t } from '~/utils/localization';
import { POPUPS_NAME } from '../PopupManager';
import DefaultTooltip from '../Tooltip/DefaultTooltip';
import styles from './LootboxRerollIcon.scss';
import { appActions } from '~/Store/appSlice';

interface ILootboxRerollIcon {
    enable: boolean;
    groupName: string;
}

const LootboxRerollIcon = ({ enable, groupName }: ILootboxRerollIcon) => {
    const dispatch = useDispatch();
    const texts = [
        interpolate(t('Если вам выпадет предмет из группы «{group_name}», вы получите предмет, которого у вас нет.'), {
            group_name: groupName,
        }),
    ];

    if (enable) {
        texts.push(
            interpolate(t('После однократного получения всех предметов группы «{group_name}» вы начнёте получать дубликаты случайным образом при открытии контейнера.'), {
                group_name: groupName,
            }),
        );
    }

    const onClick = () => {
        if (!isMobileOrTabletDeviceByAgent()) {
            return;
        }
        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.MOBILE_TOOLTIP_POPUP,
                isVisible: true,
                data: {
                    contents: texts,
                },
            }),
        );
    };

    return (
        <DivTooltip
            tooltipBody={<DefaultTooltip text={texts} />}
            onClick={onClick}
            className={classNames(styles.wrapper, {
                [styles.reroll]: enable,
            })}
        />
    );
};

export default LootboxRerollIcon;
