import * as React from 'react';
import { openUrl } from '~/utils/settings';

export const handleClick = (event: MouseEvent | React.MouseEvent) => {
    let target = event.target as HTMLLinkElement;
    if (target.tagName.toLowerCase() !== 'a') {
        target = target.parentNode as HTMLLinkElement;
    }
    if (target.tagName.toLowerCase() === 'a' && target.href) {
        event.stopPropagation();
        event.preventDefault();
        openUrl(target.href);
    }
};

const useDelegationClickForLinkHandler = (ref: React.RefObject<HTMLElement>, deps: React.DependencyList = []) => {
    React.useEffect(() => {
        if (!ref || !ref.current) {
            return;
        }

        ref.current.addEventListener('click', handleClick);

        return () => ref.current?.removeEventListener('click', handleClick);
    }, deps);
};

export default useDelegationClickForLinkHandler;
