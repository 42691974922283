import * as React from 'react';
import classNames from 'classnames';
import styles from './BannerBar.scss';
import { BannerUtils } from '~/utils/banner';
import { isMobileOrTabletWindow } from '~/utils/utils';
import HoverVideo from '~/customization/HoverVideo/HoverVideo';
import { BannerBarWidth } from '~/types/contents';
import equal from 'fast-deep-equal/react';
import { useAppSelector } from '~/Store';

type BannerBar_Type = {
    data: IBannerBar;
};

type BannerBarWrapper_Type = {
    children: React.ReactChild | React.ReactChild[];
};

export const BannerBarWrapper = (props: BannerBarWrapper_Type) => {
    return <div className={styles.bannerBarWrapper}>{props.children}</div>;
};

const BannerBar = (props: BannerBar_Type) => {
    const isTrusted = useAppSelector((state) => state.app.isTrusted, equal);
    const classesBannerBar = classNames(styles.bannerBar, {
        [styles.half]: props.data.width === BannerBarWidth.HALF,
        [styles.full]: props.data.width === BannerBarWidth.FULL,
        [styles.hasDescription]: !!props.data.description,
    });

    const image = isMobileOrTabletWindow ? props.data.mobileImage || props.data.image : props.data.image;
    const style = { backgroundImage: `url(${image})` };

    const handleClick = () => BannerUtils.redirectTo(props.data);

    return (
        <div className={classesBannerBar} onClick={handleClick} data-category-name={props.data.categoryName} data-link-type={props.data.linkType} data-link-value={props.data.linkValue}>
            <HoverVideo video={props.data.videoHover} isDisableAnimation={isMobileOrTabletWindow} muted={!isTrusted} parentClassName={styles.bannerBar}>
                <div className={styles.bannerBarContent} style={style}>
                    <div className={styles.bannerBarHeader}>
                        <div className={styles.bannerBarTitle}>{props.data.title}</div>
                        {!!props.data.description && <div className={styles.bannerBarDescription}>{props.data.description}</div>}
                    </div>
                </div>
            </HoverVideo>
        </div>
    );
};

export default BannerBar;
