import * as React from 'react';
import styles from './PurchaseFooter.scss';
import { t } from '~/utils/localization';
import { detectPlatform, PLATFORMS } from '@wg/wows-commerce';
import Button, { ButtonVariant } from '~/components/Button/Button';
import { ClientPaymentMethod } from '@wg/wows-commerce/api/platform/web/webPreparePurchase';
import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { assets } from '@wg/wows-entities/const';
import { DivTooltip, Interpolate } from '@wg/wows-react-uikit';
import Currency from '~/components/Currency/Currency';
import WSMartPurchaseProcessor from '~/processors/WSMartPurchaseProcessor';
import { WoWsCommerceStorefrontNames } from '~/core/WowsCommerce/WoWsCommerceHelper';
import { POPUPS_NAME } from '~/components/PopupManager';
import { DWH_EVENTS as WOWS_COMMERCE_DWH_EVENTS } from '@wg/wows-commerce/constants/dwhEvents';
import { EVENTS } from '@wg/wows-commerce';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { startHeadShakeAnimation } from '~/utils/auction';
import { settings } from '~/utils/settings';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface IPurchaseFooter {
    isAvailableImmediateDeliveryValidation: boolean;
}

const stateSelector = (state: RootState) => {
    return {
        paymentMethod: state.wsmart.paymentMethod,
        currentProductId: state.wsmart.currentProductId,
        fastGoldProductWrappers: state.wsmart.fastGoldProducts,
        isAdult: state.account.isAdult,
        WowsCommerce: state.wsmart.wowsCommerce,
        billingAddress: state.wsmart.billingAddressSettings,
        regional: state.wsmart.regional,
    };
};

const PurchaseFooter: React.FC<IPurchaseFooter> = ({ isAvailableImmediateDeliveryValidation }) => {
    const state = useAppSelector(stateSelector, equal);
    const dispatch = useDispatch();
    const platform = detectPlatform();
    const selectedProduct = state.fastGoldProductWrappers.find((productWrapper) => productWrapper.product.id === state.currentProductId)?.product;
    const [amount, setAmount] = React.useState<number>(1);
    state.WowsCommerce.subscribe(EVENTS.PRODUCT_UPDATE, ({ product, _ }) => {
        if (selectedProduct?.isVariablePriceProduct) {
            setAmount(selectedProduct?.quantity);
        }
    });

    React.useEffect(() => {
        if (selectedProduct?.isVariablePriceProduct) {
            setAmount(selectedProduct?.quantity);
        } else {
            setAmount(selectedProduct?.entitlements?.find((item) => item.type === assets.GOLD)?.amount);
        }
    }, [selectedProduct]);

    const getButtonText = (paymentMethod: ClientPaymentMethod) => {
        if (platform === PLATFORMS.WEB && !paymentMethod) {
            return t('Да');
        }

        return t('Да, оплатить');
    };

    const getPurchaseLabel = (paymentMethod: ClientPaymentMethod) => {
        if (platform === PLATFORMS.WEB && !paymentMethod) {
            return t('Выбрать метод оплаты и приобрести {component} за {price}');
        }

        return t('Приобрести {component} за {price}');
    };

    const wsmartPurchaseProcessor = new WSMartPurchaseProcessor(selectedProduct, WoWsCommerceStorefrontNames.FASTGOLD);

    const changePaymentMethod = async (event?: React.MouseEvent) => {
        await dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.WSMART_DOUBLOONS_POPUP }));
        event?.stopPropagation();
        await wsmartPurchaseProcessor.buy(WOWS_COMMERCE_DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_BUNDLE, selectedProduct?.quantity);
    };

    let isDisabledPurchaseButton = false;
    const isNeedToCompleteBillingAddress = state.billingAddress && state.billingAddress.mandatory && !state.billingAddress.address;
    if (isNeedToCompleteBillingAddress && state.paymentMethod) {
        isDisabledPurchaseButton = true;
    } else {
        isDisabledPurchaseButton = isAvailableImmediateDeliveryValidation && !state.isAdult;
    }

    const onFallbackClick = () => {
        if (isNeedToCompleteBillingAddress) {
            startHeadShakeAnimation(RefManager.getRef(RefManagerKeys.BillingAddress), styles.headShakeAnim);
            return;
        }
        if (isAvailableImmediateDeliveryValidation && !state.isAdult) {
            startHeadShakeAnimation(RefManager.getRef(RefManagerKeys.WsmartAgreement), styles.headShakeAnim);
            return;
        }
    };

    const purchase = async () => {
        if (state.regional && !state.regional.purchaseIsAllowed) {
            dispatch(
                appActions.changeVisibilityPopup({
                    name: POPUPS_NAME.FORBIDDEN_REGION,
                    isVisible: true,
                    data: state.regional,
                }),
            );
            return;
        }
        if (platform === PLATFORMS.WEB && !state.paymentMethod) {
            await changePaymentMethod();
        } else {
            await state.WowsCommerce.handlePurchaseProduct(true, WoWsCommerceStorefrontNames.FASTGOLD);
        }
    };

    const close = () => {
        dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.WSMART_DOUBLOONS_POPUP }));
    };

    return (
        <div className={styles.footerPurchase}>
            <div className={styles.footerText}>
                <Interpolate
                    str={getPurchaseLabel(state.paymentMethod).replace('{component}', '%(component)s').replace('{price}', '%(price)s')}
                    component={<Currency currency={assets.GOLD} className={styles.tileCurrency} amount={amount} withoutAnimation={true} withoutTooltip={true} />}
                    price={<span className={styles.footerPrice} dangerouslySetInnerHTML={{ __html: selectedProduct?.formattedPrice }} />}
                />
            </div>
            <div className={styles.footerButtons}>
                {settings.disableRealPurchaseForTesting ? (
                    <DivTooltip tooltipBody={<DefaultTooltip text={t('Покупки недоступны на этапе тестирования')} />}>
                        <Button variant={ButtonVariant.orange} label={t('Купить')} disabled={true} />
                    </DivTooltip>
                ) : (
                    <Button variant={ButtonVariant.orange} disabled={isDisabledPurchaseButton} label={getButtonText(state.paymentMethod)} onClick={purchase} onFallback={onFallbackClick} />
                )}
                <Button label={t('Нет')} onClick={close} />
            </div>
        </div>
    );
};

export default PurchaseFooter;
