import styles from '~/Layouts/Themes/Snowfall/SnowfallBackground.scss';
import { shallowEqual } from 'react-redux';
import { isMobileOrTabletWindow } from '~/utils/utils';
import isDisabledAnimation from '~/hooks/isDisabledAnimation';
import { GlowParticles, ParticlesAnimation } from '~/components/GlowEffect/GlowEffectDecorator';
import { useAppSelector } from '~/Store';

const SnowfallBackground = () => {
    const currentPage = useAppSelector((state) => state.app.currentPage, shallowEqual);
    const _isDisabledAnimation = isDisabledAnimation(currentPage?.name);

    if (isMobileOrTabletWindow || _isDisabledAnimation) {
        return null;
    }

    return <GlowParticles className={styles.wrapper} particles={{ value: 20, config: ParticlesAnimation.SNOWFALL }} fpsLimit={35} decorations={['snowflakes_shiny_animation']} />;
};

export default SnowfallBackground;
