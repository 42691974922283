import { memo } from 'react';
import styles from './Reward.scss';
import classNames from 'classnames';
import { DivTooltip, Interpolate } from '@wg/wows-react-uikit';
import { isEmptyObject } from '~/utils/utils';
import Currency from '~/components/Currency/Currency';
import { t } from '~/utils/localization';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { PresentationStyles } from '@wg/wows-entities/const';
import Account from '~/account/Account';
import { getCompensationForPrimaryItem, getPrimaryItem, isEnabledTooltipFromItemByType } from '~/utils/bundles';
import { getTooltipContainsContent } from '~/components/Tooltip/Tooltips';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';

export enum RewardTemplate {
    DEFAULT = 'default',
    MIDDLE = 'middle',
    BIG = 'big',
}

export interface IRewardItem {
    name: string;
    title: string;
    items: IItemCommonData[];
    images: {
        medium: string;
        big: string;
        small: string;
    };
    isShiny?: boolean;
    backgroundColor?: string;
    cashback?: {
        currency: string;
        amount: number;
    };
}

export interface IReward {
    template?: RewardTemplate;
    delay: number;
    withoutAnimation?: boolean;
    reward: IRewardItem;
    compensatedItems: number[];
}

const Reward = ({ template, delay, reward, withoutAnimation, compensatedItems }: IReward) => {
    const style = { '--delay': `${delay}s` } as React.CSSProperties;

    let tooltip = getTooltipContainsContent(reward.items);
    if (reward.items.length === 1 && isEnabledTooltipFromItemByType(reward.items[0].type)) {
        const item = reward.items[0];
        if (item.customTitle || item.customDescription) {
            tooltip = <DefaultTooltip text={item.customDescription || item.customTitle} />;
        } else {
            tooltip = (
                <WoWSEntity
                    type={item.type}
                    amount={item.amount}
                    id={item.identifier}
                    presentation={{
                        style: PresentationStyles.TOOLTIP_WIDGET,
                        withTooltip: true,
                    }}
                    customisation={item.customisation}
                />
            );
        }
    }

    const classes = classNames(styles.wrapper, styles[template], {
        [styles.withoutAnimation]: withoutAnimation,
    });

    const primaryItem = getPrimaryItem(reward.items);
    const [isExistsItemInInventory] = !!primaryItem ? Account.getInventoryInfoByType(primaryItem.type, primaryItem.identifier, primaryItem.customisation?.shipId) : [false];
    const compensation = getCompensationForPrimaryItem(primaryItem);
    const isCompensated = compensatedItems?.includes(primaryItem?.identifier);

    return (
        <div className={classes} style={style}>
            <DivTooltip tooltipBody={tooltip}>
                <div className={styles.animationWrapper}>
                    {reward.isShiny && <div className={styles.shinyAnimation} />}
                    <img draggable={false} src={template === RewardTemplate.BIG ? reward.images?.big : reward.images?.medium}></img>
                </div>
                <div className={styles.rewardInfo}>
                    <span>{reward.title}</span>
                </div>
            </DivTooltip>
            <div className={styles.additionalInfo}>
                {!isEmptyObject(reward.cashback) && (
                    <div className={styles.cashbackInfo}>
                        <span className={styles.plus}>+</span>
                        <WoWSEntity
                            type={reward.cashback.currency}
                            amount={reward.cashback.amount}
                            className={styles.currency}
                            presentation={{
                                renderWithoutFade: true,
                                iconPosition: 'left',
                                withTooltip: true,
                            }}
                        />
                    </div>
                )}
                {isCompensated && isExistsItemInInventory && (
                    <Interpolate str={`${t('Компенсация')} %(currency)s`} currency={<Currency currency={compensation.currency} amount={compensation.amount} className={styles.currency} />} />
                )}
            </div>
        </div>
    );
};

export default memo(Reward);
