export enum RefManagerKeys {
    Wallet = 'Wallet',
    Menu = 'Menu',
    PageHeader = 'PageHeader',
    Footer = 'Footer',
    TopPanel = 'TopPanel',
    MainContainer = 'MainContainer',
    MainContent = 'MainContent',
    ButtonToTop = 'ButtonToTop',
    ViewBackground = 'ViewBackground',
    ViewBackgroundPortal = 'ViewBackgroundPortal',
    Filters = 'Filters',
    ScrollableBundleBackground = 'ScrollableBundleBackground',
    SantaSmallContainer = 'SantaSmallContainer',
    SantaMediumContainer = 'SantaMediumContainer',
    SantaBigContainer = 'SantaBigContainer',
    PurchaseRestriction = 'PurchaseRestriction',
    BundlePageLeftContent = 'BundlePageLeftContent',
    WsmartAgreement = 'WsmartAgreement',
    BillingAddress = 'BillingAddress',
    TradeInSourceItem = 'TradeInSourceItem',
    TradeInTargetItem = 'TradeInTargetItem',
    RewardsProgressionFirstElement = 'RewardsProgressionFirstElement',
    RewardsProgressionActiveElement = 'RewardsProgressionActiveElement',
}

interface Refs {
    Wallet?: HTMLDivElement;
    Menu?: HTMLDivElement;
    PageHeader?: HTMLDivElement;
    Footer?: HTMLDivElement;
    TopPanel?: HTMLDivElement;
    MainContainer?: HTMLDivElement;
    ButtonToTop?: HTMLDivElement;
    ViewBackground?: HTMLDivElement;
    ViewBackgroundPortal?: HTMLDivElement;
    MainContent?: HTMLDivElement;
    Filters?: HTMLDivElement;
    ScrollableBundleBackground?: HTMLDivElement;
    SantaSmallContainer?: HTMLDivElement;
    SantaMediumContainer?: HTMLDivElement;
    SantaBigContainer?: HTMLDivElement;
    PurchaseRestriction?: HTMLDivElement;
    BundlePageLeftContent?: HTMLDivElement;
    WsmartAgreement?: HTMLDivElement;
    BillingAddress?: HTMLDivElement;
    TradeInSourceItem?: HTMLDivElement;
    TradeInTargetItem?: HTMLDivElement;
    RewardsProgressionFirstElement?: HTMLDivElement;
    RewardsProgressionActiveElement?: HTMLDivElement;
}

class RefManager {
    private refs: Refs = {};

    public setRef(key: RefManagerKeys, _ref: HTMLElement) {
        switch (key) {
            case RefManagerKeys.Menu:
                this.refs[RefManagerKeys.Menu] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.Wallet:
                this.refs[RefManagerKeys.Wallet] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.PageHeader:
                this.refs[RefManagerKeys.PageHeader] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.Footer:
                this.refs[RefManagerKeys.Footer] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.TopPanel:
                this.refs[RefManagerKeys.TopPanel] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.MainContainer:
                this.refs[RefManagerKeys.MainContainer] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.ButtonToTop:
                this.refs[RefManagerKeys.ButtonToTop] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.ViewBackground:
                this.refs[RefManagerKeys.ViewBackground] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.ViewBackgroundPortal:
                this.refs[RefManagerKeys.ViewBackgroundPortal] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.MainContent:
                this.refs[RefManagerKeys.MainContent] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.Filters:
                this.refs[RefManagerKeys.Filters] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.ScrollableBundleBackground:
                this.refs[RefManagerKeys.ScrollableBundleBackground] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.SantaBigContainer:
                this.refs[RefManagerKeys.SantaBigContainer] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.SantaMediumContainer:
                this.refs[RefManagerKeys.SantaMediumContainer] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.SantaSmallContainer:
                this.refs[RefManagerKeys.SantaSmallContainer] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.PurchaseRestriction:
                this.refs[RefManagerKeys.PurchaseRestriction] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.BundlePageLeftContent:
                this.refs[RefManagerKeys.BundlePageLeftContent] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.WsmartAgreement:
                this.refs[RefManagerKeys.WsmartAgreement] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.BillingAddress:
                this.refs[RefManagerKeys.BillingAddress] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.TradeInSourceItem:
                this.refs[RefManagerKeys.TradeInSourceItem] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.TradeInTargetItem:
                this.refs[RefManagerKeys.TradeInTargetItem] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.RewardsProgressionActiveElement:
                this.refs[RefManagerKeys.RewardsProgressionActiveElement] = _ref as HTMLDivElement;
                break;

            case RefManagerKeys.RewardsProgressionFirstElement:
                this.refs[RefManagerKeys.RewardsProgressionFirstElement] = _ref as HTMLDivElement;
                break;
        }
    }

    public getRef(key: RefManagerKeys) {
        return this.refs[key];
    }
}

export default new RefManager();
