import { useDispatch } from 'react-redux';
import { Popup, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { t } from '~/utils/localization';
import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { Processing, Button } from '@wg/wows-react-uikit';
import useClickAway from '~/hooks/useClickAway';
import { useRef } from 'react';
import styles from './SeabattleLoaderPopup.scss';
import { CATEGORIES } from '~/const';
import { openCategoryByName } from '~/utils/category';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

function stateSelector(state: RootState) {
    return {
        isEnabled: state.seabattle.isEnabled,
        isError: state.seabattle.isError,
    };
}

export default function SeabattleLoaderPopup(): React.ReactElement {
    const ref = useRef(null);
    const state = useAppSelector(stateSelector, equal);
    const dispatch = useDispatch();
    const close = () => {
        dispatch(appActions.changeVisibilityPopup({ name: null }));
    };

    useClickAway(ref, close);

    if (state.isEnabled && !state.isError) {
        openCategoryByName(CATEGORIES.SEA_BATTLE);
        close();
    }

    const errorMessage = t('Произошла ошибка. Повторите попытку позже');
    const showError = !!state.isError;
    const showLoader = !state.isEnabled && !state.isError;

    return (
        <Popup onClose={close} renderEscButton={!showLoader}>
            <div className={styles.inner}>
                <div ref={ref}>
                    {showLoader && <Processing isFetching isInside />}
                    {showError && (
                        <>
                            <PopupHeader title={errorMessage} className={styles.errorBlock} />
                            <PopupFooter className={styles.errorBlock}>
                                <Button onClick={close} isFlat>
                                    {t('Закрыть')}
                                </Button>
                            </PopupFooter>
                        </>
                    )}
                </div>
            </div>
        </Popup>
    );
}
