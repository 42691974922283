import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { getParsedTime } from '~/utils/time';
import classNames from 'classnames';
import styles from '~/components/Menu/Menu.scss';
import { isLabelNewShownForCategory } from '~/utils/labels';
import { playButtonClickSound } from '~/api/WoWsClient';
import LabelDecorator, { LABEL_TYPE } from '~/decorators/LabelDecorator/LabelDecorator';
import { NavLink } from 'react-router-dom';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import GlowEffectDecorator, { ParticlesAnimation } from '~/components/GlowEffect/GlowEffectDecorator';
import { isShinyCategory } from '~/utils/shiny/shiny';
import { useDispatch } from 'react-redux';
import { scrollTop } from '~/utils/scrollTop';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import CountDown from '~/components/CountDown/CountDown';
import { CATEGORIES } from '~/const';
import { isActiveTab } from '~/utils/menu';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface IMenuItem {
    item: ICategory;
    url: string;
    guideNames?: GUIDE_NAMES[];
    timer?: ITimerData;
}

const stateSelector = (state: RootState) => {
    return {
        viewedLabels: state.account.viewedLabels,
        categories: state.app.categories,
        currentPage: state.app.currentPage,
    };
};

const MenuItem = (props: IMenuItem) => {
    const state = useAppSelector(stateSelector, equal);
    const dispatch = useDispatch();
    const { item } = props;

    const [isLabelNewAllowed, setLabelNewAllowed] = React.useState(isLabelNewShownForCategory(item.name));

    const isTimerShown = !!(props.timer && props.timer.activeTill);
    const timerDate = isTimerShown ? props.timer.activeTill.getTime() : 0;
    const timerDaysLeft = isTimerShown ? getParsedTime(Math.floor((timerDate - Date.now()) / 1000) || 0)?.days : 0;

    const iconClassNames = classNames(
        styles.menuItemIcon,
        styles[`menu-${item.name}`] || styles[`menu-${item.parentIconClassName}`],
        styles[`${item.theme}-back`] || styles[`${item.parentIconClassName}-back`],
    );

    const descriptionClassNames = classNames(styles.categoryTooltipDescriptionContent, {
        [styles.categoryTooltipDescription]: !!props.timer,
    });

    const footer = [];
    if (props.item?.description) {
        footer.push(<div className={descriptionClassNames} dangerouslySetInnerHTML={{ __html: props.item?.description }} />);
    }

    if (isTimerShown) {
        const timerTitle = props.timer.title;

        footer.push(
            <div className={styles.timeWrapper}>
                <div className={styles.timerTitle}>
                    <span>{timerTitle}</span>
                    <CountDown date={timerDate} className={styles.timerLabel} notice={timerDaysLeft < 1} withoutIcon={true} />
                </div>
            </div>,
        );
    }

    const icon: any = item.icon ? { WebkitMaskImage: `url(${item.icon})` } : {};

    React.useEffect(() => {
        setLabelNewAllowed(isLabelNewShownForCategory(item.name));
    }, [state.viewedLabels]);

    const classesActiveTill = classNames({
        [styles.activeTill]: isTimerShown,
        [styles.activeTillAlarm]: isTimerShown && timerDaysLeft < 1,
    });

    const onClick = () => {
        scrollTop(0);
        playButtonClickSound();
        dispatch(appActions.updateActiveFilterPreset({ preset: null }));
    };

    const isShiny = isShinyCategory(item.name as ICategoryList);

    return (
        <LabelDecorator id={item.labelNewActiveTill ? item.name : null} isEnabled={isLabelNewAllowed} type={LABEL_TYPE.category}>
            <GuideDecorator names={props.guideNames}>
                <NavLink
                    to={props.url}
                    className={({ isActive }) => {
                        return classNames(styles.menuItem, styles[item.theme], 'armory__auto--menu_item', {
                            [styles.itemMoney]: item.name === CATEGORIES.WSMART,
                            [styles.isShiny]: isShiny,
                            [styles.isActive]: isActive || isActiveTab(item.name),
                        });
                    }}
                    onClick={onClick}
                    data-category-name={item.name}
                >
                    <DivTooltip
                        className={classNames(styles.tooltipContainer, styles[`${item.theme}-item`])}
                        style={{ cursor: 'pointer' }}
                        tooltipBody={<DefaultTooltip title={item.title} footer={footer.length ? footer : null} />}
                        position={'center-right'}
                        fixed
                    >
                        <GlowEffectDecorator
                            isEnabled={isShiny}
                            className={styles.particles}
                            withoutSun={true}
                            particles={{
                                value: 20,
                                config: ParticlesAnimation.CIRCLE,
                            }}
                        >
                            <div className={iconClassNames}>
                                <span style={icon} className={classNames(styles.icon, styles[`${item.theme}-icon`])} />
                            </div>
                        </GlowEffectDecorator>
                        <LabelContainer className={styles.label} isVisible={isLabelNewAllowed} />
                    </DivTooltip>
                    {isTimerShown && <div className={classesActiveTill} />}
                </NavLink>
            </GuideDecorator>
        </LabelDecorator>
    );
};

export default React.memo(MenuItem);
