import * as React from 'react';
import classNames from 'classnames';
import styles from '../common.scss';
import { t } from '~/utils/localization';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import CamouflagesPerks, { isCamouflage, isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import { items as ITEMS, PresentationStyles } from '@wg/wows-entities/const';
import { getAmountString } from '~/Layouts/BundlePage/helpers';
import { InventoryCounterSize } from '~/components/InventoryCounter/InventoryCounter';
import InventoryCounterContainer from '~/containers/InventoryCounterContainer/InventoryCounterContainer';
import Spoiler from '~/components/Spoiler/Spoiler';
import { ManagerData } from '~/core/ManagerData';
import Account from '~/account/Account';
import PortPreviewButton from '~/components/PortPreviewButton/PortPreviewButton';
import { isEnabledPortByBundle, openPortForFirstBundleItem } from '~/settings/port';

interface ICamouflageSubTitleContentProps {
    item: IBundleEntity;
    bundle?: IBundle;
    quantity: number;
    renderPreviewContent?: boolean;
}

export const CamouflageSubTitleContent = ({ item, quantity, renderPreviewContent, bundle }: ICamouflageSubTitleContentProps) => {
    const primaryItem = item;
    const amount = primaryItem.amount * (quantity || 1);
    const text = getAmountString(amount, '<b>', '</b>');
    const [isExistsItemInInventory] = Account.getInventoryInfoByType(ITEMS.VEHICLES, primaryItem.customisation.shipId);
    const isAvailablePort = !bundle.isPurchased && isEnabledPortByBundle(bundle, false, true);

    return (
        <React.Fragment>
            <div className={styles.flex}>
                <div className={styles.preBundleTitle}>
                    <CamouflagesPerks item={item} />
                </div>
                {primaryItem.customisation.shipId && (
                    <div className={classNames(styles.preBundleTitle, styles.camoTitle)} key={`camo_for_ship_${primaryItem.customisation.shipId}`}>
                        <WoWSEntity
                            type={ITEMS.VEHICLES}
                            id={primaryItem.customisation.shipId}
                            customisation={{
                                isExistsInInventory: isExistsItemInInventory,
                            }}
                            presentation={{
                                withTooltip: true,
                                hideInventoryIcon: true,
                                renderWithoutFade: true,
                            }}
                        />
                    </div>
                )}
                {renderPreviewContent && isAvailablePort && (
                    <div className={classNames(styles.preBundleTitle, styles.camoPreviewButton)}>
                        <PortPreviewButton onlyIcon onClick={() => openPortForFirstBundleItem(bundle)} />
                    </div>
                )}
            </div>
            <div className={classNames(styles.flex, styles.camouflageAdditionalInfo)}>
                {(isCamouflage(primaryItem?.type) || isPermanentCamouflage(primaryItem?.type)) && (
                    <div className={classNames(styles.preBundleTitle, styles.marginRight)} dangerouslySetInnerHTML={{ __html: text }} />
                )}
                <InventoryCounterContainer item={primaryItem} className={classNames(styles.preBundleTitle, styles.inventoryWidgetStyle)} size={InventoryCounterSize.FULL} />
            </div>
        </React.Fragment>
    );
};

interface ICamouflageContent {
    item: IBundleEntity;
    renderSpoiler?: boolean;
}

const CamouflageContent = (props: ICamouflageContent) => {
    const camoData = ManagerData.getItem(props.item.identifier);
    if (!camoData?.ttc?.length) {
        return null;
    }

    const camouflageTypeTitle = isPermanentCamouflage(props.item.type) ? t('Постоянный камуфляж') : t('Расходуемый камуфляж');

    if (props.renderSpoiler) {
        return (
            <Spoiler
                title={
                    <div className={classNames(styles.contentAdditionalBlock)}>
                        <div className={styles.contentSpoilerTitle}>
                            <WoWSEntity
                                key={props.item.identifier}
                                type={props.item.type}
                                id={props.item.identifier}
                                presentation={{
                                    withoutAssetIcon: true,
                                    withText: true,
                                }}
                            />
                        </div>
                    </div>
                }
                content={
                    <div className={classNames(styles.contentBlock, styles.borderBottom, styles.spoilerContent)}>
                        <div className={styles.spoilerSubTitle}>{t('Характеристики: ')}</div>
                        <WoWSEntity
                            type={props.item.type}
                            id={props.item.identifier}
                            className={styles.ttcText}
                            presentation={{
                                style: PresentationStyles.TTC,
                            }}
                        />
                    </div>
                }
            />
        );
    }

    return (
        <div className={styles.borderBottom}>
            <div className={classNames(styles.contentBlock, styles.borderBottom)}>
                <div className={styles.contentBlockTitle}>{t('Характеристики: ')}</div>
                <WoWSEntity
                    type={props.item.type}
                    id={props.item.identifier}
                    className={styles.ttcText}
                    presentation={{
                        style: PresentationStyles.TTC,
                    }}
                />
            </div>
        </div>
    );
};

export default CamouflageContent;
