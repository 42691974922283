import * as React from 'react';
import styles from '../Port.scss';
import AbstractPort from '~/components/Port/AbstractPort';
import AuctionLotHeader from '~/components/Auction/AuctionHeader';
import { t } from '~/utils/localization';
import { ManagerData } from '~/core/ManagerData';

export default class AuctionLotPort extends AbstractPort {
    textButtonBack = t('Описание лота');

    isAvailableContainsWidget = true;

    protected renderTitle(): React.ReactChild {
        return (
            <div className={styles.title}>
                <AuctionLotHeader {...this.props.lot} />
            </div>
        );
    }

    protected getCamoTitle(): string {
        if (this.props.exteriorId < 0) {
            return null;
        }

        const camoTitle = ManagerData.getPermoflage(this.props.exteriorId)?.title || ManagerData.getItem(this.props.exteriorId)?.title;
        return camoTitle || this.props.lot.title;
    }

    protected renderNoticeContent(): React.ReactChild {
        return <div className={styles.notice}>{t('Может быть получен при победе в аукционе')}</div>;
    }
}
