import * as React from 'react';
import styles from './GlowEffect.scss';
import type { Engine, IParticlesOptions, RecursivePartial } from 'tsparticles-engine';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';
import { getStaticUrl } from '~/utils/utils';
import classNames from 'classnames';
import { BUNDLE_GRID_SIZE } from '~/const';
import { BUNDLE_DECORATION } from '~/types/bundle';

export enum ParticlesAnimation {
    CIRCLE = 'circle',
    FREE_SHINY = 'free_shiny',
    WATER_DUST = 'water_dust',
    WATER_BUBBLES = 'water_bubbles',
    SNOWFLAKES = 'snowflakes',
    SNOWFALL = 'snowfall',
}

interface IParticles {
    value?: number;
    color?: string;
    config?: ParticlesAnimation;
}

interface IGlowEffect {
    children?: React.ReactChild | React.ReactChild[];
    withoutSun?: boolean;
    isEnabled: boolean;
    size?: string;
    className?: string;
    particles?: IParticles;
    decorations?: string[];
}

interface IGlow {
    className: string;
    particles?: IParticles;
    decorations?: string[];
    fpsLimit?: number;
}

const getFreeShinyConfig = (value?: number): RecursivePartial<IParticlesOptions> => {
    return {
        number: {
            value: value || 50,
            density: {
                enable: false,
            },
        },
        shape: {
            type: ['image'],
            image: [
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/spark_1_png2.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/spark_2_png2.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/spark_3_png2.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/spark_4_png2.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/spark_5_png2.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/spark_6_png2.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/spark_8_png2.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/spark_1_png.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/spark_2_png.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/spark_5_png.png')),
                },
            ],
        },
        color: {
            value: '#f17e47',
        },
        size: {
            value: 4,
            random: true,
            anim: {
                speed: 5,
                size_min: 0.7,
            },
        },
        line_linked: {
            enable: false,
        },
        move: {
            enable: true,
            random: true,
            speed: {
                min: 0.75,
                max: 1.2,
            },
            direction: 'top',
            out_mode: 'out',
        },
        opacity: {
            value: 0,
            random: false,
            animation: {
                enable: true,
                speed: 1,
                minimumValue: 0.75,
                sync: true,
            },
        },
    };
};

const getSnowflakesConfig = (value?: number): RecursivePartial<IParticlesOptions> => {
    return {
        number: {
            value: value || 50,
            density: {
                enable: false,
            },
        },
        shape: {
            type: ['image'],
            image: [
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/snowflake_5.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/snowflake_6.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/snowflake_7.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/spark_png_2/snowflake_8.png')),
                },
            ],
        },
        color: {
            value: '#f17e47',
        },
        size: {
            value: 6,
            random: true,
            anim: {
                speed: 5,
                size_min: 0.7,
            },
        },
        line_linked: {
            enable: false,
        },
        move: {
            enable: true,
            random: true,
            speed: {
                min: 0.75,
                max: 1.2,
            },
            direction: 'top',
            out_mode: 'out',
        },
    };
};

export const getCircleConfig = (value?: number, color?: string): RecursivePartial<IParticlesOptions> => {
    return {
        number: {
            value: value || 50,
            density: {
                enable: false,
            },
        },
        shape: {
            type: ['circle'],
        },
        color: {
            value: color || '#eec568',
        },
        size: {
            value: 3.3,
            random: true,
            anim: {
                speed: 5,
                size_min: 2.2,
            },
        },
        move: {
            enable: true,
            random: false,
            speed: {
                min: 0.75,
                max: 1.5,
            },
            direction: 'top',
            out_mode: 'out',
        },
        opacity: {
            value: 0,
            random: false,
            animation: {
                enable: true,
                speed: 1,
                minimumValue: 0.75,
                sync: true,
            },
        },
    };
};

export const getWaterDustConfig = (value?: number, color?: string): RecursivePartial<IParticlesOptions> => {
    return {
        number: {
            value: value || 50,
            density: {
                enable: false,
            },
        },
        shape: {
            type: ['image'],
            image: [
                {
                    src: getStaticUrl(require('../../assets/images/sparks/dust/dust_1.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/dust/dust_2.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/dust/dust_3.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/dust/dust_4.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/dust/dust_5.png')),
                },
            ],
        },
        color: {
            value: color || '#eec568',
        },
        size: {
            value: 5,
            random: true,
            anim: {
                speed: 6.7,
                size_min: 3.8,
            },
        },
        line_linked: {
            enable: false,
        },
        move: {
            enable: true,
            random: true,
            speed: 1,
            direction: 'top',
            out_mode: 'out',
        },
        opacity: {
            value: 0,
            random: false,
            animation: {
                enable: true,
                speed: 1,
                minimumValue: 0.75,
                sync: true,
            },
        },
    };
};

export const getSnowfallConfig = (dencityPercent?: number, color?: string): RecursivePartial<IParticlesOptions> => {
    return {
        number: {
            value: dencityPercent || 10,
            max: 300,
            density: {
                enable: true,
                area: 100,
            },
        },
        shape: {
            type: ['image'],
            image: [
                {
                    src: getStaticUrl(require('../../assets/images/sparks/dust/dust_1.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/dust/dust_2.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/dust/dust_3.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/dust/dust_4.png')),
                },
                {
                    src: getStaticUrl(require('../../assets/images/sparks/dust/dust_5.png')),
                },
            ],
        },
        color: {
            value: color || '#eec568',
        },
        size: {
            value: 5,
            random: true,
            anim: {
                speed: 6.7,
                size_min: 3.8,
            },
        },
        line_linked: {
            enable: false,
        },
        move: {
            enable: true,
            random: true,
            speed: 2,
            direction: 'bottom',
            out_mode: 'out',
            drift: 0,
            decay: 0,
            gravity: {
                acceleration: 9.81,
                enable: false,
                inverse: false,
                maxSpeed: 50,
            },
            path: {
                clamp: true,
                delay: {
                    random: {
                        enable: false,
                        minimumValue: 0,
                    },
                    value: 0,
                },
                enable: false,
                options: {},
            },
        },
        opacity: {
            value: 1,
            random: false,
            animation: {
                enable: true,
                speed: 1,
                minimumValue: 0.85,
                sync: true,
            },
        },
        rotate: {
            value: 45,
            direction: 'clockwise',
            animation: {
                enable: true,
                speed: 3,
            },
        },
    };
};

export const getWaterBubblesConfig = (value?: number, color?: string): RecursivePartial<IParticlesOptions> => {
    return {
        number: {
            value: value || 50,
            density: {
                enable: false,
            },
        },
        shape: {
            type: ['image'],
            image: [
                {
                    src: getStaticUrl(require('../../assets/images/sparks/bubbles/Bubbles.png')),
                },
            ],
        },
        color: {
            value: color || '#fff',
        },
        size: {
            value: 10,
            random: true,
            anim: {
                speed: 5.2,
                size_min: 3.8,
            },
        },
        line_linked: {
            enable: false,
        },
        move: {
            random: true,
            speed: 5,
            direction: 'top',
            out_mode: 'out',
        },
        modes: {
            bubble: {
                distance: 20,
                duration: 2,
                opacity: 1,
            },
            repulse: {
                distance: 200,
                duration: 0.4,
            },
        },
    };
};

const ParticlesConfigs = {
    [ParticlesAnimation.CIRCLE]: getCircleConfig,
    [ParticlesAnimation.FREE_SHINY]: getFreeShinyConfig,
    [ParticlesAnimation.WATER_DUST]: getWaterDustConfig,
    [ParticlesAnimation.WATER_BUBBLES]: getWaterBubblesConfig,
    [ParticlesAnimation.SNOWFLAKES]: getSnowflakesConfig,
    [ParticlesAnimation.SNOWFALL]: getSnowfallConfig,
};

export const getConfigByDecoration = (decorations: string[]) => {
    if (decorations.includes(BUNDLE_DECORATION.SNOWFLAKES_SHINY_ANIMATION)) {
        return getSnowflakesConfig;
    }
    return null;
};

export const GlowParticles = React.memo(({ className, particles, decorations, fpsLimit }: IGlow) => {
    const reactNodeId = React.useId();

    let config = ParticlesConfigs[particles?.config];
    if (!config) {
        config = getConfigByDecoration(decorations);
    }
    const funcParticlesConfig = config ?? getFreeShinyConfig;

    const particlesInit = React.useCallback(async (engine: Engine) => {
        await loadSlim(engine);
    }, []);

    return (
        <Particles
            className={className}
            init={particlesInit}
            id={`particles_${reactNodeId}`}
            options={{
                emitters: {
                    position: {
                        x: 50,
                        y: 100,
                    },
                },
                fullScreen: {
                    enable: false,
                    zIndex: 0,
                },
                fpsLimit: fpsLimit || 120,
                detectRetina: true,
                particles: funcParticlesConfig(particles?.value, particles?.color),
            }}
        />
    );
});

const GlowEffectDecorator = (props: IGlowEffect) => {
    const classes = classNames(
        styles.wrapper,
        {
            [styles.withoutSun]: props.withoutSun,
            [styles['size1-3']]: props.size === BUNDLE_GRID_SIZE.ONE_THIRD,
            [styles['size1-4']]: props.size === BUNDLE_GRID_SIZE.ONE_FOUR,
        },
        props.className,
    );

    return (
        <React.Fragment>
            {props.isEnabled && (
                <div className={classes}>
                    <GlowParticles className={styles.particles} particles={props.particles} decorations={props.decorations || []} />
                    {!props.withoutSun && <div className={styles.sparks} />}
                </div>
            )}
            {props.children}
        </React.Fragment>
    );
};

export default React.memo(GlowEffectDecorator);
