import * as React from 'react';
import classNames from 'classnames';
import styles from './VideoBackground.scss';
import useVisibilityChange from '~/hooks/useVisibilityChange';
import equal from 'fast-deep-equal/react';
import { getClientPath, isInGameBrowser } from '~/utils/utils';
import { useAppSelector } from '~/Store';

interface IVideoBackground {
    poster: string;
    video: IVideo;
    className?: string;
    posterClassName?: string;
    videoClassName?: string;
    muted?: boolean;
    children?: React.ReactChild[] | React.ReactChild;
    withMask?: boolean;
    volume?: number;
    maskClassName?: string;
}

const VideoBackground = ({ poster, video, className, posterClassName, videoClassName, muted, children, withMask, volume, maskClassName }: IVideoBackground) => {
    const isStartedVideo = useAppSelector((state) => state.app.isStartedVideo, equal);
    const [isLoadedData, setLoadData] = React.useState(false);
    const refVideo = React.useRef<HTMLVideoElement>(null);

    const classesBackground = classNames(styles.background, posterClassName, {
        [styles.withMask]: withMask,
    });

    const classesVideoWrapper = classNames(styles.videoWrapper, { [styles.show]: isLoadedData }, videoClassName);
    const backgroundStyles = poster ? { backgroundImage: `url(${poster})` } : {};
    const classesVideoBackgroundWrapper = classNames(styles.videoBackgroundWrapper, className);

    const _muted = muted === undefined || muted === null ? true : muted;
    const _volume = Number.isInteger(volume) || Number.isFloat(volume) ? volume : 1;

    useVisibilityChange(
        () => {
            refVideo.current.volume = _volume;
        },
        () => {
            refVideo.current.volume = 0;
        },
    );

    React.useEffect(() => {
        refVideo.current.volume = isStartedVideo ? 0 : _volume;
    }, [isStartedVideo]);

    React.useEffect(() => {
        if (refVideo.current) {
            refVideo.current.volume = isStartedVideo ? 0 : _volume;
        }
    }, []);

    return (
        <div className={classesVideoBackgroundWrapper}>
            <div className={classesBackground} style={backgroundStyles} />
            <div className={classesVideoWrapper}>
                <video
                    className={styles.video}
                    muted={_muted}
                    loop={true}
                    controls={false}
                    autoPlay={true}
                    preload={'metadata'}
                    ref={(_ref) => {
                        if (_ref) {
                            refVideo.current = _ref;
                        }
                    }}
                    onLoadedData={() => {
                        setLoadData(true);
                    }}
                >
                    {!!video?.clientVideoName?.trim().length && isInGameBrowser && <source type={'video/webm'} src={getClientPath(`armory/${video.clientVideoName}`)} />}
                    <source type={'video/webm'} src={video?.webm} />
                    <source type={'video/mp4'} src={video?.mp4} />
                </video>
            </div>
            {withMask && <div className={classNames(styles.videoBackgroundMask, maskClassName)} />}
            {children}
        </div>
    );
};

export default VideoBackground;
