import * as React from 'react';
import styles from './BlackFriday.scss';
import equal from 'fast-deep-equal/react';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { useAppSelector } from '~/Store';

interface IBlackFridayAnimation {
    categoryName: ICategoryList;
}

const BlackFridayAnimation = ({ categoryName }: IBlackFridayAnimation) => {
    const categories = useAppSelector((state) => state.app.categories, equal);
    const category = categories[categoryName];

    if (!category || isMobileOrTabletWindow) {
        return null;
    }

    return (
        <React.Fragment>
            <div className={styles.wrapper} />
            <div
                className={styles.background}
                style={{
                    backgroundImage: `url(${category.background})`,
                }}
            />
        </React.Fragment>
    );
};

export default BlackFridayAnimation;
