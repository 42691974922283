import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './NotificationSpecial.scss';
import classNames from 'classnames';
import CloseButton from '~/components/CloseButton/CloseButton';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { useDispatch } from 'react-redux';
import { markNotificationAsViewed } from '~/api/account';
import useClickAway from '~/hooks/useClickAway';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { redirectTo } from '~/utils/notifications';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import { NotificationBannerPosition } from '~/types/notifications';
import { playButtonClickSound } from '~/api/WoWsClient';
import Button, { ButtonVariant } from '~/components/Button/Button';
import { t } from '~/utils/localization';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import { accountActions } from '~/Store/accountSlice';

interface INotificationSpecial {
    notification: INotification;
}

const stateSelector = (state: RootState) => {
    return {
        accountId: state.account.id,
        viewedNotifications: state.account.viewedNotifications,
        currentPage: state.app.currentPage,
    };
};

const NotificationSpecial = ({ notification }: INotificationSpecial) => {
    const ref = React.useRef(null);
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const isFullscreen = notification.bannerPosition === NotificationBannerPosition.FULLSCREEN;
    const isCenter = notification.bannerPosition === NotificationBannerPosition.CENTER;
    const classesWrapper = classNames(styles.wrapper, {
        [styles.left]: notification.bannerPosition === NotificationBannerPosition.LEFT,
        [styles.right]: notification.bannerPosition === NotificationBannerPosition.RIGHT,
        [styles.center]: isCenter,
        [styles.fullscreen]: isFullscreen,
    });

    const close = (isDwhSendEvent = false) => {
        dispatch(accountActions.updateViewNotification(notification.name));
        dispatch(appActions.setCurrentNotificationName(null));
        dispatch(appActions.changeVisibilityPopup({ name: null }));

        if (isDwhSendEvent) {
            dwhExport.send(DWH_EVENTS.CLOSE_NOTIFICATION, { notification_name: notification.name });
        }

        if (state.accountId && !state.viewedNotifications?.includes(notification.name)) {
            markNotificationAsViewed(notification.name);
        }
    };

    const onClick = () => {
        close();
        dwhExport.send(DWH_EVENTS.OPEN_CATEGORY_FROM_NOTIFICATION, { notification_name: notification.name });
        if (notification) {
            redirectTo(notification);
        }
    };

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            ref.current = _ref;
        }
    };

    const [textPositionX, textPositionY] = notification.textPosition || [];

    const styleInfo = !isFullscreen
        ? {
              left: textPositionX + '%',
              top: textPositionY + '%',
          }
        : {};

    const style = {
        backgroundImage: `url(${notification.background})`,
    };

    React.useEffect(() => {
        if (isFullscreen) {
            updateBrowserControlState(true);
        }
    }, [notification.name]);

    React.useEffect(() => {
        dispatch(appActions.setVisibleInfoScreen({ name: notification.name, isVisible: true }));
    }, []);

    useClickAway(
        ref,
        () => {
            if (isCenter) {
                close();
            }
        },
        [notification.name],
    );

    useKeyDown(() => {
        if (isFullscreen) {
            playButtonClickSound();
            close();
        }
    }, [KEYS_CODE.ESC]);

    const isNeedToShownRedirectButton = (notification.redirectToCategory && state.currentPage?.name !== notification.redirectToCategory) || notification.linkHref;

    return (
        <div className={classesWrapper}>
            <div
                style={style}
                onClick={() => {
                    !isFullscreen && onClick();
                }}
                key={`notification_${notification.name}`}
                className={classNames(styles.content, styles[notification.size])}
            >
                <div className={styles.container} ref={setRef}>
                    {!isFullscreen && (
                        <div className={styles.close}>
                            <CloseButton
                                onClick={(event) => {
                                    event.stopPropagation();
                                    close(true);
                                }}
                            />
                        </div>
                    )}
                    <div className={styles.info} style={styleInfo}>
                        <div className={styles.title}>{notification.title}</div>
                        <div className={styles.description}>{notification.description}</div>
                        {isFullscreen && (
                            <div className={styles.buttons}>
                                {isNeedToShownRedirectButton && <Button variant={ButtonVariant.orange} label={t('Перейти')} onClick={onClick} />}
                                <Button label={t('Закрыть')} onClick={() => close(true)} className={styles.closeButton} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationSpecial;
