import * as React from 'react';
import { getSoundKeyForCurrentPage, isEnabledSounds } from '~/utils/sounds/settings';
import { isEmptyObject, isMobileOrTabletWindow } from '~/utils/utils';
import { Howl, Howler } from 'howler';
import { shipyardSoundOff, shipyardSoundOn } from '@wg/web2clientapi/sound';
import equal from 'fast-deep-equal/react';
import { RootState, useAppSelector } from '~/Store';

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        categories: state.app.categories,
        bundles: state.app.bundles,
        isStartedVideo: state.app.isStartedVideo,
        soundStatus: state.app.soundStatus,
        port: state.app.port,
    };
};

const MAX_VOLUME = 0.5;

const usePlayMainTheme = () => {
    const state = useAppSelector(stateSelector, equal);
    const prevSound = React.useRef<string>(null);
    const soundsMap = React.useRef<Record<string, Howl>>({});
    let timer: NodeJS.Timeout;

    function addSound(key: string, src: string, play = false) {
        if (key && src && !soundsMap.current[key]) {
            soundsMap.current[key] = new Howl({
                src: src,
                autoplay: isEnabledSounds(key),
                loop: true,
                mute: false,
                format: ['mp3'],
                onplayerror: function () {
                    soundsMap.current[key].once('unlock', function () {
                        prevSound.current === key && isEnabledSounds(key) && smoothPlay(key);
                    });
                },
                onplay: function () {
                    clearTimeout(timer);
                    shipyardSoundOff();
                    const fadeOutTime = 3000;
                    const sound = soundsMap.current[key];
                    const time = (sound.duration() - sound.seek()) * 1000 - fadeOutTime;
                    sound.fade(0, 1, fadeOutTime);
                    timer = setTimeout(function () {
                        sound.playing() && sound.fade(1, 0, fadeOutTime);
                    }, time);
                },
            });
        }
        const sound = soundsMap.current[key];
        if (play && sound && isEnabledSounds(key) && !sound.playing()) {
            smoothPlay(key);
        }
    }

    function smoothPlay(key: string) {
        soundsMap.current[key].mute(false).fade(0, 1, 3000).play();
    }

    function soundPlay(key: string) {
        const sound = soundsMap.current[key];
        if (sound && isEnabledSounds(key) && !sound.playing()) {
            smoothPlay(key);
        }
    }

    function soundStop(key: string, isTurnOnPortMusic = true) {
        if (soundsMap.current[key]) {
            isTurnOnPortMusic && shipyardSoundOn();
            clearTimeout(timer);
            soundsMap.current[key].stop();
        }
    }

    function getSoundInfo(currentPage: ICurrentPage) {
        const bundle = state.bundles?.[currentPage?.bundleId];
        const category = state.categories?.[currentPage?.name];
        const soundKey = getSoundKeyForCurrentPage(category, bundle);
        if (currentPage?.isBundlePage) {
            if (bundle && !isEmptyObject(bundle?.audioTrack)) {
                return [soundKey, bundle.audioTrack.mp3];
            }
        }
        if (category && !isEmptyObject(category?.audioTrack)) {
            return [soundKey, category.audioTrack.mp3];
        }
        return [];
    }

    const visibilitychange = () => {
        const sound = soundsMap.current[prevSound.current];
        if (!soundsMap.current[prevSound.current]) {
            return;
        }
        sound.mute(document.visibilityState === 'hidden');
    };

    React.useEffect(() => {
        const handler = state.soundStatus[prevSound.current] ? soundPlay : soundStop;
        handler(prevSound.current);
    }, [state.soundStatus]);

    React.useEffect(() => {
        const handler = state.port?.isVisible ? soundStop : soundPlay;
        handler(prevSound.current);
    }, [state.port?.isVisible]);

    React.useEffect(() => {
        const sound = soundsMap.current[prevSound.current];
        sound?.mute?.(isMobileOrTabletWindow);
    }, [isMobileOrTabletWindow]);

    React.useEffect(() => {
        const sound = soundsMap.current[prevSound.current];
        if (!sound) {
            return;
        }
        if (!state.isStartedVideo) {
            shipyardSoundOff();
            sound.fade(0, 1, 3000);
        } else {
            sound.fade(1, 0, 3000);
        }
    }, [state.isStartedVideo]);

    React.useEffect(() => {
        const [soundKey, soundTrack] = getSoundInfo(state.currentPage);
        if (!prevSound.current) {
            prevSound.current = soundKey;
        } else {
            if (soundKey !== prevSound.current) {
                soundStop(prevSound.current);
                prevSound.current = soundKey;
            }
        }
        addSound(prevSound.current, soundTrack, true);
    }, [state.currentPage]);

    React.useEffect(() => {
        if (isMobileOrTabletWindow) {
            return;
        }

        Howler.volume(MAX_VOLUME);

        const [soundKey] = getSoundInfo(state.currentPage);

        prevSound.current = soundKey;

        document.addEventListener('visibilitychange', visibilitychange);

        return () => {
            document.removeEventListener('visibilitychange', visibilitychange);
            soundStop(prevSound.current);
        };
    }, []);
};

export default usePlayMainTheme;
