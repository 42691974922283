import * as React from 'react';
import { Processing } from '@wg/wows-react-uikit';
import { useDispatch } from 'react-redux';
import { setScrollover } from '~/api/WoWsClient';
import { appActions } from '~/Store/appSlice';

interface IContainerLazyContainer<T extends React.LazyExoticComponent<React.FC<any>>> {
    Container: T;
    Fallback?: T;
    isFullscreen?: boolean;
    category: IMenuMapItem;
}

const CategoryLazyContainer = ({ Container, Fallback, category, isFullscreen }: IContainerLazyContainer<any>) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        isFullscreen && dispatch(appActions.setFullscreen(true));
        setScrollover(true);

        return () => {
            isFullscreen && dispatch(appActions.setFullscreen(false));
            setScrollover(false);
        };
    }, []);

    return (
        <React.Suspense fallback={Fallback ? Fallback : <Processing isFetching />}>
            <Container category={category} />
        </React.Suspense>
    );
};

export default CategoryLazyContainer;
