import * as React from 'react';
import classNames from 'classnames';
import styles from './FastGoldProduct.scss';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import { assets } from '@wg/wows-entities/const';
import Currency from '~/components/Currency/Currency';
import Discount from '~/components/Discount/Discount';
import equal from 'fast-deep-equal/react';
import { t } from '~/utils/localization';
import WSMartQuantity from '~/components/WSMart/Quantity/WSMartQuantity';
import WSMartPurchaseProcessor from '~/processors/WSMartPurchaseProcessor';
import SmallPreloader from '~/components/SmallPreloader/SmallPreloader';
import { WoWsCommerceStorefrontNames } from '~/core/WowsCommerce/WoWsCommerceHelper';
import { playTabClickSound } from '~/api/WoWsClient';
import { RootState, useAppSelector } from '~/Store';

interface IFastGoldProduct {
    product: ProductEntity;
}

const stateSelector = (state: RootState) => {
    return {
        currentProductId: state.wsmart.currentProductId,
        WowsCommerce: state.wsmart.wowsCommerce,
    };
};

const FastGoldProductNew = ({ product }: IFastGoldProduct) => {
    const [isProductPriceLoading, setProductPriceLoading] = React.useState<boolean>(false);

    const state = useAppSelector(stateSelector, equal);

    React.useEffect(() => {
        const loadProductPrice = async () => {
            if (product.isVariablePriceProduct) {
                await getProductPriceByQuantity(Number(product.variablePriceSettings.default));
            }
        };
        loadProductPrice();
    }, []);

    const goldItem = product.entitlements?.find((item) => item.type === assets.GOLD);
    if (!goldItem) {
        return null;
    }

    const wsmartPurchaseProcessor = new WSMartPurchaseProcessor(product, WoWsCommerceStorefrontNames.FASTGOLD);

    async function onChangeQuantityEvent(quantity: number) {
        await getProductPriceByQuantity(quantity);
    }

    async function getProductPriceByQuantity(quantity: number) {
        setProductPriceLoading(true);

        const changedProduct = await wsmartPurchaseProcessor.updateProductPriceByQuantity(quantity);
        if (!changedProduct) return;
        state.WowsCommerce.handleChangeCurrentProduct(changedProduct, WoWsCommerceStorefrontNames.FASTGOLD);

        setProductPriceLoading(false);
    }

    return (
        <div
            className={classNames(styles.tile, {
                [styles.selected]: state.currentProductId === product.id,
            })}
            onClick={() => {
                playTabClickSound();
                state.WowsCommerce.handleChangeCurrentProduct(product, WoWsCommerceStorefrontNames.FASTGOLD);
            }}
        >
            <div className={styles.tileContainer}>
                {!product.isVariablePriceProduct ? (
                    <div className={styles.tilePreview}>
                        <img src={product.tileImageUrl} alt={product.title} />
                    </div>
                ) : null}

                <div className={styles.tileTop}>
                    {product.isVariablePriceProduct ? (
                        <div>
                            <div className={styles.variablePriceTitle}>{t('Либо укажите желаемое количество дублонов')}</div>
                            <div className={styles.variablePriceQuantityWidget}>
                                <WSMartQuantity product={product} fromPopup hideControls={true} disableInput={false} onDebouncedChangeValue={onChangeQuantityEvent} />
                            </div>
                        </div>
                    ) : (
                        <Currency currency={assets.GOLD} className={styles.tileCurrency} amount={goldItem.amount} withoutAnimation={true} withoutTooltip={true} />
                    )}
                </div>
                <div className={styles.tileFooter}>
                    {isProductPriceLoading ? (
                        <div className={styles.pricePreloader}>
                            <SmallPreloader />
                        </div>
                    ) : (
                        <div
                            className={classNames(styles.classesPriceWrapper, {
                                [styles.flex]: product.discount,
                            })}
                        >
                            {product.discount && (
                                <div className={styles.discount}>
                                    <Discount discount={product.discount} />
                                </div>
                            )}
                            <div
                                className={classNames(styles.classesPrice, {
                                    [styles.column]: !!product.formattedOriginalPrice,
                                })}
                            >
                                {product?.formattedOriginalPrice && <span className={styles.originalPrice} dangerouslySetInnerHTML={{ __html: product.formattedOriginalPrice }} />}
                                <div className={styles.price} dangerouslySetInnerHTML={{ __html: product.formattedPrice }} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FastGoldProductNew;
