import * as React from 'react';
import styles from './TradeInConfirmation.scss';
import { TradeInStatus } from '~/types/tradein';
import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { POPUPS_NAME } from '~/components/PopupManager';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { interpolate, t } from '~/utils/localization';
import TradeInItemsSelector from '~/Layouts/TradeIn/components/TradeInItemsSelector/TradeInItemsSelector';
import { Interpolate } from '@wg/wows-react-uikit';
import Currency from '~/components/Currency/Currency';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import Button, { ButtonVariant } from '~/components/Button/Button';
import { getPrice, isRareItem } from '~/utils/tradein';
import Attention from '~/components/Attention/Attention';
import { tradein } from '~/api/tradein';
import { calculatePrice } from '~/utils/purchase';
import { SteelExchangePrices } from '~/components/PriceWrapper/PriceWrapper';
import classNames from 'classnames';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

const stateSelector = (state: RootState) => {
    return {
        selectedTargetItem: state.tradein.selectedTargetItem,
        selectedSourceItem: state.tradein.selectedSourceItem,
        currentPage: state.app.currentPage,
        balance: state.account.balance,
        popups: state.app.popups,
    };
};

const TradeInConfirmation = () => {
    const dispatch = useDispatch();
    const [isLockedInterface, lockInterface] = React.useState<boolean>(false);
    const { selectedTargetItem, selectedSourceItem, currentPage, balance, popups } = useAppSelector(stateSelector, equal);

    const onClose = () => {
        if (isLockedInterface) {
            return;
        }
        dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.TRADEIN_CONFIRMATION }));
    };

    const trade = () => {
        lockInterface(true);
        tradein(selectedSourceItem.identifier, selectedTargetItem.identifier, currentPage?.name).catch(() => {
            dispatch(
                appActions.changeVisibilityPopup({
                    name: POPUPS_NAME.TRADEIN_STATUS,
                    isVisible: true,
                    data: {
                        status: TradeInStatus.ERROR,
                        item: selectedTargetItem,
                    },
                }),
            );
        });
    };

    const rulesClick = (event: React.MouseEvent) => {
        if ((event.target as HTMLElement).tagName.toLowerCase() === 'a') {
            dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.TRADEIN_RULES, isVisible: true }));
        }
    };

    const price = getPrice(selectedSourceItem?.price, selectedTargetItem?.price);
    const pricesMap = calculatePrice({ [selectedTargetItem?.currency]: price }, balance);

    return (
        <Popup onClose={onClose} renderEscButton={!isLockedInterface}>
            <PopupHeader title={t('Подтверждение обмена')} />
            <PopupBody>
                <div className={styles.tradeInWidget}>
                    <TradeInItemsSelector
                        type={selectedTargetItem.type}
                        selectedTargetItem={selectedTargetItem}
                        selectedSourceItem={selectedSourceItem}
                        isNotClickable={true}
                        hideTooltipLabels={true}
                        withoutAnimation={true}
                    />
                    {isRareItem(selectedSourceItem.additionalData?.tags) && <Attention className={styles.attention} label={t('Вы обмениваете редкий корабль.')} isInline />}
                </div>
            </PopupBody>
            <PopupFooter>
                <div className={styles.footerContent}>
                    <Interpolate
                        str={t('Обменять %(item1)s на %(item2)s ?')}
                        className={styles.dynamicStr}
                        item1={<WoWSEntity type={selectedSourceItem.type} id={selectedSourceItem.identifier} presentation={{ renderWithoutFade: true, hideNationIcon: true, withShortText: true }} />}
                        item2={<WoWSEntity type={selectedTargetItem.type} id={selectedTargetItem.identifier} presentation={{ renderWithoutFade: true, hideNationIcon: true, withShortText: true }} />}
                        amount={
                            Object.keys(pricesMap.final).length > 1 ? (
                                <SteelExchangePrices calculatePrice={pricesMap} className={styles.steelExchange} plusClassName={styles.plus} priceClassName={styles.priceExchange} />
                            ) : (
                                <Currency currency={selectedTargetItem.currency} amount={pricesMap.final[selectedTargetItem.currency]} className={styles.currency} withoutAnimation />
                            )
                        }
                    />
                    <Interpolate
                        str={t('Необходимо доплатить: %(amount)s')}
                        className={classNames(styles.dynamicStr, styles.priceStr)}
                        amount={
                            Object.keys(pricesMap.final).length > 1 ? (
                                <SteelExchangePrices calculatePrice={pricesMap} className={styles.steelExchange} plusClassName={styles.plus} priceClassName={styles.priceExchange} />
                            ) : (
                                <Currency currency={selectedTargetItem.currency} amount={pricesMap.final[selectedTargetItem.currency]} className={styles.currency} withoutAnimation />
                            )
                        }
                    />
                    <div
                        className={styles.rulesText}
                        onClick={rulesClick}
                        dangerouslySetInnerHTML={{
                            __html: interpolate(t('Я подтверждаю своё согласие с {link}условиями обмена{link_end}'), {
                                link: '<a>',
                                link_end: '</a>',
                            }),
                        }}
                    />
                    <div className={styles.tradeButtonWrapper}>
                        <Button
                            variant={ButtonVariant.orange}
                            label={isLockedInterface ? t('Обработка') : t('Обменять')}
                            onClick={trade}
                            isFetching={isLockedInterface}
                            disabled={isLockedInterface}
                            className={styles.tradeInButton}
                        />
                    </div>
                </div>
            </PopupFooter>
        </Popup>
    );
};

export default TradeInConfirmation;
