import store from '~/Store';
import { appActions } from '~/Store/appSlice';
import { tradeinActions } from '~/Store/tradeinSlice';
import { tradeinStateSync } from '~/api/tradein';
import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import { CategoryType } from '~/types/category';
import { ITradeLists } from '~/types/tradein';
import { armoryState, settings } from '~/utils/settings';
import { convertLanguageCode } from '~/utils/utils';

export default class TradeInPreloader extends AbstractPreloader {
    pause = 5;

    get isExistsTradeIn() {
        return armoryState.content.categories?.some((category) => category.type === CategoryType.TRADEIN);
    }

    async load(): Promise<any> {
        if (!this.isExistsTradeIn) {
            return;
        }
        const { ts } = store.getState().tradein;
        const response = await tradeinStateSync();
        if (!response || !response.tradeinState?.length || ts + this.pause >= response.ts) {
            return;
        }

        const languageCode = convertLanguageCode(settings.languageCode);

        const lists = response.tradeinState.reduce((state: ITradeLists, listItem) => {
            if (listItem.tradeinLists.length > 0) {
                store.dispatch(appActions.showMenuItem(listItem.categoryName));
                listItem.tradeinLists.forEach((list) => {
                    list.source.forEach((item) => {
                        item.title = item.additionalData.title[languageCode];
                    });
                    list.target.forEach((item) => {
                        item.title = item.additionalData.title[languageCode];
                    });
                });
                state[listItem.categoryName] = listItem.tradeinLists;
            }
            return state;
        }, {});

        store.dispatch(tradeinActions.setTs(response.ts));
        store.dispatch(tradeinActions.setTradeInLists(lists));
    }
}
