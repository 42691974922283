import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './AuctionBidButton.scss';
import classNames from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import AuctionProcessor from '~/processors/AuctionProcessor';
import { t } from '~/utils/localization';
import { playButtonClickSound } from '~/api/WoWsClient';
import { useAppSelector } from '~/Store';

interface IProps {
    lot: ILot;
    auction?: IAuction;
    className?: string;
    isFetching?: boolean;
    isProcessing?: boolean;
}

const AuctionCancelButton = (props: IProps) => {
    const auctionAccount = useAppSelector((state) => state.auction.account, equal);
    const auctionProcessor = new AuctionProcessor(props.auction, props.lot, auctionAccount);
    const [isFetching, setFetching] = React.useState<boolean>(props.isFetching);

    const onClick = async () => {
        if (isFetching) {
            return;
        }
        playButtonClickSound();
        setFetching(true);
        await auctionProcessor.cancelBid();
    };

    React.useEffect(() => {
        setFetching(props.isFetching);
    }, [props.isFetching]);

    const classes = classNames(
        styles.cancelButton,
        {
            [styles.isFetching]: isFetching || props.isProcessing,
        },
        props.className,
    );

    return <DivTooltip className={classes} onClick={onClick} tooltipBody={<DefaultTooltip text={isFetching ? t('Обработка') : t('Отменить ставку')} />} />;
};

export default AuctionCancelButton;
