import * as React from 'react';
import styles from './SlotLayout.scss';
import classNames from 'classnames';
import PointRight from '~/components/PointRight/PointRight';
import { isEmptyObject } from '~/utils/utils';
import { getTextAboutContinuousRewardsBySlot } from '~/Layouts/Lootboxes/utils/texts';
import ChanceLabel from '~/components/ChanceLabel/ChanceLabel';
import { getTotalProbabilityForValuableRewards } from '~/Layouts/Lootboxes/utils';
import CommonRewardsLayout from '~/Layouts/Lootboxes/Views/CommonRewardsLayout/CommonRewardsLayout';
import ValuableRewardsLayout from '~/Layouts/Lootboxes/Views/ValuableRewardsLayout/ValuableRewardsLayout';

interface ISlotLayout {
    className?: string;
    slot: ILootboxStateItemSlot;
    index: number;
    lootbox: ILootboxStateItem;
    slotCount: number;
    autoDescriptionSettings: ILootboxAutoDescriptionSettings;
}

const SlotLayout = ({ slot, index, className, lootbox, slotCount, autoDescriptionSettings }: ISlotLayout) => {
    const hasValuableRewards = !isEmptyObject(slot.valuableRewards);
    const hasCommonRewards = !isEmptyObject(slot.commonRewards);
    const continuousRewardsTooltipText = slot.continuousRewards ? getTextAboutContinuousRewardsBySlot(lootbox, index) : null;
    const isNeedToRenderSlotPoint = slotCount > 1;
    const totalProbability = getTotalProbabilityForValuableRewards(slot.valuableRewards);
    const hasSlotTitle = !!slot.title?.trim().length;
    const isNeedToShowProbability = hasSlotTitle && slot.continuousRewards && totalProbability > 0;
    const isNeedToRenderHeader = hasSlotTitle || isNeedToShowProbability;

    return (
        <div className={classNames(styles.content, className)}>
            {isNeedToRenderSlotPoint && (
                <div className={styles.pointerWrapper}>
                    <PointRight label={index + 1} className={styles.pointer} />
                </div>
            )}
            <div className={styles.rewardsContent}>
                {isNeedToRenderHeader && (
                    <div className={styles.header}>
                        {hasSlotTitle && <div className={styles.slotTitle}>{slot.title}</div>}
                        {isNeedToShowProbability && (
                            <div className={styles.chanceLabel}>
                                <ChanceLabel chance={totalProbability} isContinuousRewards={true} tooltipContent={continuousRewardsTooltipText} />
                            </div>
                        )}
                    </div>
                )}
                {hasValuableRewards && (
                    <ValuableRewardsLayout
                        rewards={slot.valuableRewards}
                        slotIndex={index}
                        lootbox={lootbox}
                        continuousRewardsTooltipText={continuousRewardsTooltipText}
                        autoDescriptionSettings={autoDescriptionSettings}
                        rewardClassName={classNames({
                            [styles.withSlot]: isNeedToRenderSlotPoint,
                        })}
                    />
                )}
                {hasCommonRewards && (
                    <CommonRewardsLayout rewards={slot.commonRewards} hasValuableRewards={hasValuableRewards} slotIndex={index} autoDescriptionSettings={autoDescriptionSettings} lootbox={lootbox} />
                )}
            </div>
        </div>
    );
};

export default SlotLayout;
