import * as React from 'react';
import Contains, { IContainsItem } from '~/components/ItemContains/Contains';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import equal from 'fast-deep-equal/react';
import Account from '~/account/Account';
import useMatchMedia from '~/hooks/useMatchMedia';
import { SCREEN_WIDTH } from '~/const';
import { useAppSelector } from '~/Store';

interface IBundleContains {
    bundle: IBundle;
    limit?: number;
    className?: string;
    hiddenVoButton?: boolean;
}

export const prepareItems = (bundle: IBundle, inventory: InventoryState): IContainsItem[] => {
    return bundle.entitlements.map((item, index) => {
        let content;

        const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(item.type, item.identifier, item.customisation?.shipId);

        if (item.customTitle) {
            content = (
                <DivTooltip tooltipBody={<DefaultTooltip text={item.customDescription || item.customTitle} />}>
                    <div dangerouslySetInnerHTML={{ __html: item.customTitle }} />
                </DivTooltip>
            );
        } else {
            content = (
                <WoWSEntity
                    key={`item_${item.identifier || item.type}_${index}_${inventory ? 'loaded' : 'not_loaded'}`}
                    type={item.type}
                    id={item.identifier}
                    amount={item.amount}
                    customisation={{
                        ...(item.customisation || {}),
                        inventoryCount: !item.isUnique && inventoryCount,
                        isExistsInInventory: item.isUnique && isExistsItemInInventory,
                    }}
                    presentation={{
                        withText: false,
                        withTooltip: true,
                        renderWithoutFade: true,
                        iconPosition: 'left',
                        lineClamp: 1,
                        hiddenInlineShip: true,
                        hideInventoryIcon: true,
                    }}
                />
            );
        }
        return {
            id: (item.identifier || `${index}_${item.type}`)?.toString(),
            type: item.type as any,
            isBonus: item.isBonus,
            content,
        } as IContainsItem;
    });
};

const ShortBundleContains = ({ bundle, limit, className, hiddenVoButton }: IBundleContains) => {
    const [totalLimit, setLimit] = React.useState(window.screen.availWidth <= SCREEN_WIDTH.w1770px ? 1 : limit);
    const inventory = useAppSelector((state) => state.account.inventory, equal);
    const items = prepareItems(bundle, inventory);

    useMatchMedia(`(max-width: ${SCREEN_WIDTH.w1770px}px)`, (match) => {
        setLimit(match.matches ? 1 : limit);
    });

    return (
        <div className={className}>
            <Contains items={items} limit={totalLimit} key={`bundle_${bundle.id}_${inventory ? 'loaded' : 'not_loaded'}`} hiddenVoButton={hiddenVoButton} />
        </div>
    );
};

export default ShortBundleContains;
