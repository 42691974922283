import * as React from 'react';
import styles from './Expander.scss';
import classNames from 'classnames';
import { playButtonClickSound } from '~/api/WoWsClient';

interface IExpander {
    label: string;
    onClick?: (event?: React.MouseEvent) => void;
    isExpanded?: boolean;
    className?: string;
}

const Expander = ({ label, onClick, isExpanded, className }: IExpander) => {
    return (
        <div
            className={classNames(
                styles.wrapper,
                {
                    [styles.isExpanded]: isExpanded,
                },
                className,
            )}
            onClick={(event) => {
                playButtonClickSound();
                onClick?.(event);
            }}
        >
            {label}
        </div>
    );
};

export default Expander;
