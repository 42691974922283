import * as React from 'react';
import styles from './CommonLayout.scss';
import PreviewLayout from '~/Layouts/Lootboxes/Views/PreviewLayout/PreviewLayout';
import SlotLayout from '~/Layouts/Lootboxes/Views/SlotLayout/SlotLayout';
import classNames from 'classnames';
import { getSubTitleForCommonLayout } from '~/Layouts/Lootboxes/utils/texts';
import { t } from '~/utils/localization';
import CommonLayoutFooter from '~/Layouts/Lootboxes/Views/CommonLayout/CommonLayoutFooter';

interface ICommonLayout {
    lootbox: ILootboxStateItem;
    lootboxAutoDescriptionSettings: IAutoDescriptionSettings;
}

const CommonLayout = ({ lootbox, lootboxAutoDescriptionSettings }: ICommonLayout) => {
    const { slots } = lootbox;
    const autoDescriptionSettings = lootboxAutoDescriptionSettings?.lootboxSettings?.[lootbox.id];

    return (
        <div className={styles.wrapper}>
            {autoDescriptionSettings?.previewSettings && <PreviewLayout preview={autoDescriptionSettings.previewSettings} className={styles.block} lootbox={lootbox} />}
            {(autoDescriptionSettings?.title || autoDescriptionSettings?.description) && (
                <div className={classNames(styles.block, styles.paddingInside)}>
                    {autoDescriptionSettings?.title && <div className={styles.blockTitle}>{autoDescriptionSettings.title}</div>}
                    {autoDescriptionSettings?.description && <div className={styles.blockDescription}>{autoDescriptionSettings.description}</div>}
                </div>
            )}
            <div className={classNames(styles.containsBlock, styles.block)}>
                <div className={styles.containsAboutBlock}>
                    <div className={styles.containsAboutTitle}>{t('Контейнер:Состав')}</div>
                    <div className={styles.containsAboutSubTitle}>{getSubTitleForCommonLayout(lootbox)}</div>
                </div>
                {slots.map((slot, index) => {
                    return (
                        <SlotLayout
                            key={`container_${lootbox.id}_slot_${index}`}
                            slot={slot}
                            index={index}
                            lootbox={lootbox}
                            slotCount={slots.length}
                            autoDescriptionSettings={autoDescriptionSettings}
                        />
                    );
                })}
            </div>
            <CommonLayoutFooter lootbox={lootbox} autoDescriptionSettings={autoDescriptionSettings} />
        </div>
    );
};

export default CommonLayout;
