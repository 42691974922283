import gql from 'graphql-tag';

export const vehiclesIds = gql`
    query Vehicles($ids: [String], $lang: String) {
        vehicles(vehicleIds: $ids, lang: $lang) {
            id
            title
            titleShort
            isPremium
            isSpecial
            description
            tags
            nation {
                name
                title
                icons {
                    default
                    small
                    medium
                    large
                    tiny
                }
            }
            type {
                name
                title
                titleShort
                icons {
                    default
                    special
                    normal
                    elite
                    premium
                }
            }
            level
        }
    }
`;
