import styles from './PremiumAccountLink.scss';
import className from 'classnames';
import equal from 'fast-deep-equal/react';
import Account from '~/account/Account';
import { openCategoryByName } from '~/utils/category';
import { CATEGORIES } from '~/const';
import { RootState, useAppSelector } from '~/Store';
import { getPremiumDurationText } from '~/utils/time';

const stateSelector = (state: RootState) => {
    return {
        balance: state.account.balance,
        categories: state.app.categories,
    };
};

const MobilePremiumAccountLink = () => {
    const goToWoWSPremium = () => {
        openCategoryByName(CATEGORIES.PURCHASE_WOWS_PREMIUM);
    };

    const isAuthorizedUser = !!Account.getAccount();

    const state = useAppSelector(stateSelector, equal);
    const balance = state.balance;
    const isPremAccountCategoryEnabled = isAuthorizedUser && !!state.categories?.[CATEGORIES.PURCHASE_WOWS_PREMIUM];
    const warshipsAccountPremium = balance?.['warships_account_premium']?.value || 0;
    const generalAccountPremium = balance?.['general_account_premium']?.value || 0;
    const hasPremium = warshipsAccountPremium > 0 || generalAccountPremium > 0;
    const accountPremiumSeconds = warshipsAccountPremium || generalAccountPremium;

    const accountPremiumClassName = className(styles.accountPremiumWrapper, {
        [styles.withIcon]: hasPremium,
        [styles.generalAccountPremium]: !!generalAccountPremium && !warshipsAccountPremium,
    });

    return (
        <>
            {isPremAccountCategoryEnabled && (
                <div className={styles.mobileHeaderAccountPremium} onClick={goToWoWSPremium}>
                    {hasPremium && <div className={accountPremiumClassName}>{getPremiumDurationText(accountPremiumSeconds)}</div>}
                    {!hasPremium && <div className={styles.iconWarshipsPremiumBuy} />}
                </div>
            )}
        </>
    );
};

export default MobilePremiumAccountLink;
