import styles from './CurrencyShortageBlock.scss';
import { interpolate, t } from '~/utils/localization';
import Currency from '~/components/Currency/Currency';
import { DivTooltip } from '@wg/wows-react-uikit';
import ShortageCurrenciesTooltip from '~/components/Tooltip/ShortageCurrenciesTooltip';
import { getCurrency } from '~/utils/currencies';
import classNames from 'classnames';

export type NotEnoughCurrencyType = {
    currency: string;
    amount: number;
};

interface INotEnoughCurrency {
    currencies: NotEnoughCurrencyType[];
    className?: string;
}

const CurrencyShortageBlock = ({ currencies, className }: INotEnoughCurrency) => {
    const renderTwoCurrencies = currencies.length === 2;

    return (
        <div className={classNames(styles.wrapper, className)}>
            <span>{t('Не хватает: ')}&nbsp;</span>
            <Currency
                currency={currencies[0].currency}
                amount={currencies[0].amount}
                withoutAnimation
                className={styles.currency}
                showDescriptionTooltip={getCurrency(currencies[0].currency)?.showDescriptionInPriceTooltip}
            />
            {renderTwoCurrencies && (
                <>
                    <span className={styles.plus}>+</span>
                    <Currency currency={currencies[1].currency} amount={currencies[1].amount} withoutAnimation className={styles.currency} />
                </>
            )}
            {!renderTwoCurrencies && currencies.length > 1 && (
                <>
                    <span className={styles.plus}>+</span>
                    <DivTooltip className={styles.more} tooltipBody={<ShortageCurrenciesTooltip currencies={[...currencies].splice(1, currencies.length - 1)} />}>
                        {interpolate(t('еще {amount}'), { amount: currencies.length - 1 })}
                    </DivTooltip>
                </>
            )}
        </div>
    );
};

export default CurrencyShortageBlock;
