import * as React from 'react';
import styles from './BackendCrashContainer.scss';
import { t } from '~/utils/localization';
import Button from '~/components/Button/Button';

interface IBackendCrushContainer {
    children: React.ReactNode;
}

const BackendCrashContainer = ({ children }: IBackendCrushContainer) => {
    if (!window.metashop?.state?.content) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.title}>{t('Ошибка сервера')}</div>
                <div className={styles.subTitle}>{t('Попробуйте обновить страницу позже')}</div>
                <div className={styles.buttons}>
                    <Button
                        label={t('Обновить страницу')}
                        className={styles.button}
                        onClick={() => {
                            location.reload();
                        }}
                    />
                </div>
            </div>
        );
    }

    return <>{children}</>;
};

export default BackendCrashContainer;
