import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getLootboxApiUrl, prepareLootbox } from '~/Layouts/Lootboxes/utils';

export interface ILootboxState {
    lootboxes: ILootboxes;
}

const initialState: Partial<ILootboxState> = {
    lootboxes: {},
};

export const fetchLootbox = createAsyncThunk('lootbox/fetch', async (lootboxId: number) => {
    const response = await fetch(getLootboxApiUrl(lootboxId));
    const json: { data: ILootboxStateItem } = await response.json();

    return prepareLootbox(json.data);
});

export const lootboxSlice = createSlice({
    name: 'lootbox',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchLootbox.fulfilled, (state, action: PayloadAction<ILootboxStateItem>) => {
            state.lootboxes = {
                ...(state.lootboxes || {}),
                [action.payload.id]: action.payload,
            };
        });
    },
});

export const {} = lootboxSlice.actions;

export default lootboxSlice.reducer;
