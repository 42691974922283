import * as React from 'react';
import debounce from '~/utils/debounce';

export default function useWindowResize(callback: DefaultFunction): void {
    React.useEffect(() => {
        const _handler = debounce(callback, 200);
        window.addEventListener('resize', _handler);

        return () => {
            window.removeEventListener('resize', _handler);
        };
    }, []);
}
