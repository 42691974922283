import * as React from 'react';
import { interpolate, ngettext } from '~/utils/localization';
import WarnMessage from '~/components/WarnMessage/WarnMessage';
import styles from './RandomBundleBeneficialOffer.scss';
import { useDispatch } from 'react-redux';
import { calculateBundlePrices } from '~/utils/purchase';
import { getProgressiveDiscountFromBundle } from '~/utils/bundles';
import { appActions } from '~/Store/appSlice';

interface IRandomBundleBeneficialOffer {
    discount: number;
    beneficialQuantity: number;
    currentQuantity: number;
    bundleId: number;
    onClickHanlder?: () => void;
    bundle: IBundle;
    balance: IBalance;
}

const RandomBundleBeneficialOffer = ({ beneficialQuantity, currentQuantity, discount, bundleId, onClickHanlder, bundle, balance }: IRandomBundleBeneficialOffer) => {
    const dispatch = useDispatch();
    const titleText = interpolate(ngettext('{beneficialQuantity} набор дешевле чем {currentQuantity}', '{beneficialQuantity} набора дешевле чем {currentQuantity}', beneficialQuantity), {
        beneficialQuantity,
        currentQuantity,
    });

    const descriptionText = interpolate(
        ngettext(
            'Не упустите свою выгоду. При покупке {openTag}{beneficialQuantity} набора{closeTag} и более действует скидка {discount}%.',
            'Не упустите свою выгоду. При покупке {openTag}{beneficialQuantity} наборов{closeTag} и более действует скидка {discount}%.',
            beneficialQuantity,
        ),
        {
            beneficialQuantity,
            discount,
            openTag: '<span>',
            closeTag: '</span>',
        },
    );

    const onClick = (event: React.MouseEvent) => {
        const target = event.target as HTMLElement;
        const prices = calculateBundlePrices(bundle, balance, beneficialQuantity, null, getProgressiveDiscountFromBundle(bundle, beneficialQuantity));
        if (target.tagName.toLowerCase() === 'span') {
            dispatch(appActions.updateQuantity({ bundleId, quantity: beneficialQuantity, quantityData: prices }));
            onClickHanlder?.();
        }
    };

    return (
        <div className={styles.wrapper} onClick={onClick}>
            <WarnMessage title={titleText} description={descriptionText} />;
        </div>
    );
};

export default RandomBundleBeneficialOffer;
