import { Realms } from '~/types/settings';
import styles from './Footer.scss';
import { settings } from '~/utils/settings';

const getPtBrRating = () => {
    return (
        <a href={'#'} target={'_blank'} className={styles.ptBrRating} key={'pt-br-logo'}>
            <img src={new URL('assets/images/ratings/rating-pt-br.png', import.meta.url).href} draggable={false} />
        </a>
    );
};

const getUskRating = () => {
    return (
        <a href={'https://usk.de/'} target={'_blank'} className={styles.uskRating} key={'usk-logo'}>
            <img src={new URL('assets/images/ratings/usk_green.svg', import.meta.url).href} draggable={false} />
        </a>
    );
};

const getPegiRating = () => {
    return (
        <a href={'https://pegi.info/'} target={'_blank'} className={styles.pegiRating} key={'pegi-logo'}>
            <img src={new URL('assets/images/ratings/pegi.svg', import.meta.url).href} draggable={false} />
        </a>
    );
};

const getTwRating = () => {
    return (
        <a target={'_blank'} className={styles.twRating} key={'tw-logo'}>
            <img src={new URL('assets/images/ratings/rating-tw.svg', import.meta.url).href} draggable={false} />
        </a>
    );
};

const getEsbrRating = () => {
    return (
        <a href={'http://www.esrb.org/'} target={'_blank'} className={styles.esrbRating} key={'esbr-logo'}>
            <img src={new URL('assets/images/ratings/rating-esrb.svg', import.meta.url).href} draggable={false} />
        </a>
    );
};

const getKrRating = () => {
    return (
        <a href={'http://www.grb.or.kr/'} target={'_blank'} className={styles.krRating} key={'kr-logo'}>
            <img src={new URL('assets/images/ratings/rating-kr.svg', import.meta.url).href} draggable={false} />
        </a>
    );
};

const RatingsAndLogos = () => {
    if (!settings.showLogosAndRatings) {
        return null;
    }

    const content = [];

    if (settings.realm === Realms.eu && settings.languageCode === 'de') {
        content.push(getUskRating());
    } else if (settings.realm === Realms.eu) {
        content.push(getPegiRating());
    }

    if (settings.realm === Realms.us && settings.languageCode === 'pt-br') {
        content.push(getPtBrRating());
    } else if (settings.realm === Realms.us && settings.languageCode === 'zh-tw') {
        content.push(getTwRating());
    } else if (settings.realm === Realms.us) {
        content.push(getEsbrRating());
    }

    if (settings.realm === Realms.sg && settings.languageCode === 'ko') {
        content.push(getKrRating());
    } else if (settings.realm === Realms.sg && settings.languageCode === 'pt-br') {
        content.push(getPtBrRating());
    } else if (settings.realm === Realms.sg && settings.languageCode === 'zh-tw') {
        content.push(getTwRating());
    }

    return content;
};

export default RatingsAndLogos;
