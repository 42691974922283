import classNames from 'classnames';
import Button, { ButtonVariant } from '~/components/Button/Button';
import FastGoldLinkContainer, { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import EventumCnRedirectButton from '~/components/EventumCnRedirectButton/EventumCnRedirectButton';
import styles from './SerialBundlePurchaseButtonWrapper.scss';
import Link, { LinkColors } from '~/components/Link/Link';
import { t } from '~/utils/localization';
import { openBundleById } from '~/utils/category';
import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { isEnoughCurrencyForPurchaseSerialSequence } from '~/utils/bundles';
import CurrencyShortageBlock from '~/components/CurrencyShortageBlock/CurrencyShortageBlock';
import { assets } from '@wg/wows-entities/const';
import { arrayToObjectByKey, redirectToLogin } from '~/utils/utils';
import { CURRENCY } from '~/const';
import Account from '~/account/Account';
import { POPUPS_NAME } from '../PopupManager';
import { isNeedToShowDownloadGamePopup, showDownloadGamePopup } from '../Popups/settings';
import { useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface ISerialBundlePurchaseButtonWrapper {
    sequence: IBundle[];
    label?: string;
    availableBundleId?: number;
    hideLinkToAvailableBundle?: boolean;
}

const SerialBundlePurchaseButtonWrapper = ({ sequence, label, availableBundleId, hideLinkToAvailableBundle }: ISerialBundlePurchaseButtonWrapper) => {
    const dispatch = useDispatch();
    const balance = useAppSelector((state) => state.account.balance, equal);
    const status = isEnoughCurrencyForPurchaseSerialSequence(sequence, balance);
    const shortageCurrenciesMap = status.shortageCurrencies && arrayToObjectByKey(status.shortageCurrencies, 'currency');

    const purchase = () => {
        if (!Account.getAccount()?.id) {
            return redirectToLogin();
        }

        if (isNeedToShowDownloadGamePopup()) {
            showDownloadGamePopup();
            return;
        }

        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE,
                isVisible: true,
                data: {
                    sequence,
                    firstAvailableBundleId: availableBundleId,
                },
            }),
        );
    };

    const goToAvailableBundle = () => {
        openBundleById(availableBundleId);
    };

    if (status.isEnought) {
        return (
            <Button variant={ButtonVariant.orange} className={classNames(styles.purchaseButton, 'armory__auto--whole-purchase-serial-bundle-button')} label={label || t('Купить')} onClick={purchase} />
        );
    }

    return (
        <>
            <CurrencyShortageBlock currencies={status.shortageCurrencies as any} />
            {shortageCurrenciesMap?.[assets.GOLD] && (
                <FastGoldLinkContainer goldShortage={shortageCurrenciesMap[assets.GOLD].amount} className={styles.classNameFastGoldLink} place={FAST_GOLD_PLACE.BUNDLE_VIEW_PAGE} withIcon />
            )}
            {shortageCurrenciesMap?.[CURRENCY.EVENTUM_CN] && <EventumCnRedirectButton className={styles.classNameFastGoldLink} withIcon />}
            {!hideLinkToAvailableBundle && (
                <>
                    {(shortageCurrenciesMap?.[assets.GOLD] || shortageCurrenciesMap?.[CURRENCY.EVENTUM_CN]) && <div className={styles.or}>{t('или')}</div>}
                    <Link label={t('Перейти к доступному')} onClick={goToAvailableBundle} color={LinkColors.white} className={styles.goToNextButtonLink} />
                </>
            )}
        </>
    );
};

export default SerialBundlePurchaseButtonWrapper;
