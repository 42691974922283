import * as React from 'react';
import styles from '../Port.scss';
import AbstractPort from '~/components/Port/AbstractPort';
import { t } from '~/utils/localization';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';

export default class ContainerPort extends AbstractPort {
    textButtonBack = t('Описание контейнера');

    protected renderTitle(): React.ReactChild {
        return (
            <>
                <div className={styles.title} dangerouslySetInnerHTML={{ __html: this.props.lootbox?.shortTitle }} />
                <div className={styles.subTitle}>{this.props.lootbox?.isPremium ? t('Премиум контейнер') : t('Контейнер')}</div>
            </>
        );
    }

    protected renderContainsContent(): React.ReactChild {
        const exteriorId = this.state.exteriorId !== -1 ? this.state.exteriorId : null;

        return (
            <div className={styles.lootboxContains}>
                <div className={styles.lootboxContainsTitle}>{t('Контейнер может содержать:')}</div>
                <div className={styles.lootboxContainsItems}>
                    <WoWSEntity
                        type={this.state.itemType}
                        id={exteriorId || this.state.shipId}
                        className={styles.lootboxContainsItem}
                        customisation={{ shipId: this.state.shipId }}
                        presentation={{
                            withTooltip: true,
                            withText: true,
                        }}
                    />
                </div>
                {exteriorId && <div className={styles.aboutExterior}>{t('Камуфляж начисляется без корабля')}</div>}
            </div>
        );
    }
}
