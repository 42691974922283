import * as React from 'react';
import { useEffect } from 'react';
import equal from 'fast-deep-equal/react';
import FeaturingLayout from '~/Layouts/FeaturingLayout';
import DefaultLayout from '~/Layouts/DefaultLayout';
import SubCategoriesLayout from '~/Layouts/SubCategoriesLayout';
import { useDispatch } from 'react-redux';
import { getUrl, urlsMap } from '~/utils/menu';
import { scrollTop } from '~/utils/scrollTop';
import { t } from '~/utils/localization';
import { getPresetByName, getPresetByType, isCategoryDisplayRestricted, openCategoryByName } from '~/utils/category';
import { PreCacheImageFromCategory } from '~/utils/preCacheImage';
import { BundlesObserver } from '~/components/BundlesObserver/BundlesObserver';
import ThemeManager, { isThemeEnabled, SINGLE_BUNDLE_PAGE } from '~/Layouts/Themes/ThemeManager';
import DockyardContainer from '~/containers/DockyardContainer/DockyardContainer';
import { CATEGORIES, DWH_EVENTS, FILTER_DISCOUNT_NAME, FILTER_DISCOUNT_VALUES } from '~/const';
import dwhExport from '~/api/dwhExport';
import PresetsLayouts from '~/Layouts/PresetsLayouts';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';
import ContentLayoutDecoration from '~/decorators/ContentLayoutDecoration/ContentLayoutDecoration';
import { isActiveFilters } from '~/utils/filters';
import { getCategoryBackground } from '~/utils/background';
import useEntryVideo from '~/hooks/useEntryVideo';
import SeaBattleContainer from '~/containers/SeaBattleContainer/SeaBattleContainer';
import { FEATURING_PLACEHOLDER } from '~/types/contents';
import { CategoryType, PRESETS } from '~/types/category';
import { useDisabledAnimationByGraphics } from '~/hooks/isDisabledAnimation';
import AccountLevelingRestrictionContainer from '~/containers/AccountLevelingRestrictionContainer/AccountLevelingRestrictionContainer';
import AccountCompletion from '~/components/AccountCompletion/AccountCompletion';
import ShopUnavailable from '~/Layouts/ShopUnavailable/ShopUnavailable';
import CategoryPromoTimer from '~/Layouts/CategoryPromoTimer/CategoryPromoTimer';
import { isValidDate } from '~/utils/time';
import TradeInContainer from '~/containers/TradeInContainer/TradeInContainer';
import TradeInParentLayout from '~/Layouts/TradeIn/Layouts/TradeInParentLayout/TradeInParentLayout';
import History from '~/utils/history';
import EnrothContainer from '~/containers/EnrothContainer/EnrothContainer';
import CategoryLazyContainer from '~/containers/CategoryLazyContainer/CategoryLazyContainer';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import { accountActions } from '~/Store/accountSlice';
import LazyForecastContainer from '~/Layouts/Forecast/Containers/LazyForecastContainer';
import LazyProgressionRewardsContainer from '~/Layouts/ProgressionRewards/Container/LazyProgressionRewardsContainer';
import { Navigate } from 'react-router-dom';

interface ICategoryContainer {
    params: {
        categoryName: string;
        id: string | number;
    };
}

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        bundleCategory: state.app.bundleCategory,
        filters: state.app.filters,
        bundles: state.app.bundles,
        activePreset: state.app.activePreset,
        featuring: state.app.featuring,
        activeCouponId: state.account.activeCouponId,
        categories: state.app.categories,
        sortedBy: state.app.sortedBy,
        filtersPresetsRecommended: state.account.filtersPresetsRecommended,
        isStartedVideo: state.app.isStartedVideo,
        filterPosition: state.app.filterPosition,
        WowsCommerce: state.wsmart.wowsCommerce,
        shopUnavailable: state.wsmart.shopUnavailable,
    };
};

const isNeedToRemoveClientSource = (function () {
    let name: string;
    return function (categoryName: string) {
        if (name && name !== categoryName) {
            return true;
        }
        name = categoryName;
        return false;
    };
})();

const CategoryContainer = function (props: ICategoryContainer) {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const { categories } = state;

    const params = props.params;
    const categoryName = params.categoryName === CATEGORIES.FEATURED ? CATEGORIES.FEATURED : params.categoryName || CATEGORIES.FEATURED;
    const subCategoryName = params.id as ICategoryList;
    const category = (subCategoryName ? categories[subCategoryName] : categories[categoryName]) as IMenuMapItem;
    const bundles = (subCategoryName ? state.bundleCategory[subCategoryName] : state.bundleCategory[categoryName]) || [];
    const activePresetConfig = getPresetByName(category?.filters?.presets || [], state.activePreset);
    const presetWithTypeAllConfig = getPresetByType(category?.filters?.presets || [], PRESETS.ALL);

    let availableBundlesForPurchase: number[] = [];
    const alreadyPurchasedBundles: number[] = [];

    const filters = state.filters?.[category?.name as ICategoryList];

    bundles.forEach((bundleId: number) => {
        const bundle = state.bundles[bundleId];

        if (!bundle) {
            return;
        }

        const _isPurchased = bundle.isPurchased;
        const isSerialBundlePurchased = !!bundle.serialSequence?.length && bundle.serialSequence.every((_bundleId: number) => state.bundles[+_bundleId].isPurchased);

        if (category.denyReorder || !_isPurchased || (bundle.serialPurchase && !isSerialBundlePurchased)) {
            availableBundlesForPurchase.push(bundle.id);
        } else {
            if (!filters?.[FILTER_DISCOUNT_NAME]?.includes(FILTER_DISCOUNT_VALUES.COUPON)) {
                alreadyPurchasedBundles.push(bundle.id);
            }
        }
    });

    const _isActiveFilters = isActiveFilters(state.filters, category?.name);

    useEffect(() => {
        setTimeout(() => {
            BundlesObserver.clear().init();
        }, 0);
    }, [availableBundlesForPurchase, alreadyPurchasedBundles, state.activePreset]);

    const checkRestrictionByCountry = () => {
        state.WowsCommerce?.checkPurchaseRestrictionByCountryMismatch();
        state.WowsCommerce?.checkPurchaseRestrictionByUserCountryIsSet(true);
    };

    useEffect(() => {
        scrollTop(0, true);
        dispatch(appActions.setFocusPreset(null));

        if (!category || isCategoryDisplayRestricted(category as ICategory, state.bundles) || (typeof category?.isEnabledCategory === 'function' && !category.isEnabledCategory())) {
            History.navigate(urlsMap.home);
            dispatch(appActions.setFetching(false));
            return;
        }

        if (state.currentPage?.name !== category?.name && state.currentPage?.name !== CATEGORIES.WSMART) {
            dispatch(appActions.resetCategoryFilter({ category: state.currentPage?.name, withoutUpdateHistory: true }));
            dispatch(appActions.resetSortCategory(state.currentPage?.name));
            if (activePresetConfig?.name) {
                dispatch(appActions.updateActiveFilterPreset({ preset: null, withoutUpdateHistory: true }));
            }
        }

        if (category?.theme !== SINGLE_BUNDLE_PAGE) {
            dispatch(appActions.changeViewBackground({ background: getCategoryBackground(subCategoryName ?? categoryName), className: null }));
        }

        if (category.isContainBundlesForReal) {
            checkRestrictionByCountry();
        } else {
            if (category.subCategories) {
                const isContainBundlesForReal = category.subCategories.some((categoryName) => {
                    return categories[categoryName]?.isContainBundlesForReal;
                });
                if (isContainBundlesForReal) {
                    checkRestrictionByCountry();
                }
            }
        }

        if (category?.name) {
            dwhExport.send(DWH_EVENTS.OPEN_CATEGORY, {
                category: category.name,
            });
        }

        dispatch(
            appActions.changeCurrentPage({
                title: category.title,
                name: category.name as ICategoryList,
                isBundlePage: false,
                bundleId: null,
            }),
        );

        new PreCacheImageFromCategory().addRandomBundleToCache(category.name);

        if (state.activeCouponId) {
            dispatch(accountActions.resetCoupon());
        }

        if (isNeedToRemoveClientSource(category.parentCategory || category.name)) {
            dispatch(appActions.removeClientSource());
        }
    }, [categoryName, subCategoryName]);

    useEntryVideo(
        {
            fadeOutVideoInSeconds: 0,
            videoUrlsMap: category.entryVideo,
            isAllowedVideoForMobile: false,
            categoryName: category.name,
            isViewAccountSpecific: true,
            videoName: category?.theme,
        },
        [category?.name],
    );

    useDisabledAnimationByGraphics(category?.theme);

    const [, update] = React.useState(Date.now());

    if (category && !!category.subCategories?.length && category.type === CategoryType.PROGRESSION_REWARD) {
        const firstSubCategoryName = categories[categoryName].subCategories[0];
        return <Navigate to={getUrl(urlsMap.subCategory, { categoryName: category.name, subCategoryName: firstSubCategoryName })} />;
    }

    if (category?.type === CategoryType.PREMIUM && state.shopUnavailable) {
        return <ShopUnavailable />;
    }

    if (category.promoTimer) {
        const isValidTimer = isValidDate(category.promoTimer.timerActiveTill);
        const hasBundles = !!category.bundles.length;
        const showBundles = hasBundles && isValidTimer && category.promoTimer.showBundles;
        if (!showBundles && (!hasBundles || (hasBundles && isValidTimer))) {
            return (
                <CategoryPromoTimer
                    category={category}
                    delayCompleteHandler={100}
                    onComplete={() => {
                        if (!hasBundles) {
                            return;
                        }
                        if (category.promoTimer) {
                            category.promoTimer.timerActiveTill = null;
                        }
                        update(Date.now());
                    }}
                />
            );
        }
    }

    if (category && category.type === CategoryType.FEATURED) {
        return (
            <>
                <AccountLevelingRestrictionContainer />
                <FeaturingLayout featuring={state.featuring} key={'category_featuring'} categoryName={category.name} />
            </>
        );
    }

    if (category && category.type === CategoryType.TRADEIN && !category.subCategories?.length) {
        return <TradeInContainer />;
    }

    if (category && category.type === CategoryType.DOCKYARD) {
        return <DockyardContainer />;
    }

    if (category && category.type === CategoryType.SEA_BATTLE) {
        return <SeaBattleContainer />;
    }

    if (category && category.type === CategoryType.FORECAST) {
        return <LazyForecastContainer category={category} />;
    }

    if (category && category.type === CategoryType.SALVAGE) {
        const LazySalvageContainer = React.lazy(
            () =>
                import(
                    /* webpackChunkName: "salvage" */
                    /* webpackMode: "lazy" */
                    /* webpackPrefetch: true */
                    '~/Layouts/Salvage/container/SalvageContainer'
                ),
        );
        return <CategoryLazyContainer category={category} Container={LazySalvageContainer} isFullscreen={true} />;
    }

    if (category && category.type === CategoryType.ENROTH) {
        return <EnrothContainer />;
    }

    const subCategories = category?.subCategories;
    if (Array.isArray(subCategories) && subCategories.length) {
        return (
            <React.Fragment>
                <AccountLevelingRestrictionContainer />
                <FeaturingLayout featuring={state.featuring} key={`${category.name}_featuring_main`} categoryName={category.name} placeholder={FEATURING_PLACEHOLDER.MAIN} />
                {category.type === CategoryType.TRADEIN ? (
                    <TradeInParentLayout category={category} />
                ) : (
                    <SubCategoriesLayout key={`sub_category_${subCategoryName}`} categoryName={categoryName} categories={subCategories} />
                )}
                <FeaturingLayout featuring={state.featuring} key={`${category.name}_featuring_bottom`} categoryName={category.name} placeholder={FEATURING_PLACEHOLDER.BOTTOM} />
            </React.Fragment>
        );
    }

    if (!Array.isArray(bundles) || !category) {
        return null;
    }

    let hiddenBundlesFromPurchase = false;

    if (!availableBundlesForPurchase.length && alreadyPurchasedBundles.length && !_isActiveFilters) {
        hiddenBundlesFromPurchase = true;
    }

    if (_isActiveFilters) {
        availableBundlesForPurchase = availableBundlesForPurchase.filter((bundleId: number) => {
            const bundle: IBundle = state.bundles[+bundleId];
            return !!(!bundle.serialPurchase || (bundle.serialPurchase && bundle.serialSequence?.length));
        });
    }

    if (isThemeEnabled(category.theme)) {
        return <ThemeManager theme={category?.theme as ALLOWED_THEMES} bundles={bundles} />;
    }

    const isHiddenLayoutIfEmptyBundlesFromFilters = _isActiveFilters && !availableBundlesForPurchase.length && !!alreadyPurchasedBundles.length;
    let isNeedToShowCommonBundles = !hiddenBundlesFromPurchase && !isHiddenLayoutIfEmptyBundlesFromFilters;
    if (!activePresetConfig && presetWithTypeAllConfig && !_isActiveFilters) {
        isNeedToShowCommonBundles = false;
    }

    return (
        <ContentLayoutDecoration theme={category.theme as ALLOWED_THEMES} categoryName={category.name as ICategoryList}>
            <AccountLevelingRestrictionContainer />
            <FeaturingLayout featuring={state.featuring} key={`${category.name}_featuring_main`} categoryName={category.name} placeholder={FEATURING_PLACEHOLDER.MAIN} />
            {category.type === CategoryType.PREMIUM && <AccountCompletion />}
            {!_isActiveFilters && !state.activePreset && <PresetsLayouts categoryName={category.name as ICategoryList} />}
            <LazyProgressionRewardsContainer category={category} />
            {isNeedToShowCommonBundles && (
                <DefaultLayout
                    key={`category_${categoryName}_${activePresetConfig?.name || 'no_preset'}`}
                    bundles={availableBundlesForPurchase}
                    showCountdown={category.showCountdown && !!category.subCategories?.length}
                    activeTill={category.activeTill}
                    isLazy={!subCategoryName}
                    title={!activePresetConfig ? presetWithTypeAllConfig?.title : null}
                    description={activePresetConfig && activePresetConfig.description}
                    preset={activePresetConfig}
                    isNotRenderFilter
                />
            )}
            <FeaturingLayout featuring={state.featuring} key={`${category.name}_featuring_bottom`} categoryName={category.name} placeholder={FEATURING_PLACEHOLDER.BOTTOM} />
            {!!alreadyPurchasedBundles.length && <DefaultLayout title={t('Получено')} key={`category_${categoryName}_already_purchased`} bundles={alreadyPurchasedBundles} isNotRenderFilter isLazy />}
        </ContentLayoutDecoration>
    );
};

export default CategoryContainer;
