import BundleLimitWidget, { BundleLimitSize } from '~/components/BundleLimitWidget/BundleLimitWidget';
import equal from 'fast-deep-equal/react';
import { getSumFromArray } from '~/utils/number';
import { getContainerInfoFromTooltip } from '~/components/BundleLimitWidget/settings';
import Account from '~/account/Account';
import { RootState, useAppSelector } from '~/Store';

interface IBundleLimitContainer {
    size?: BundleLimitSize;
    className?: string;
    bundle: IBundle;
}

const stateSelector = (state: RootState) => {
    return {
        lootboxesBundlesMap: state.app.lootboxesBundlesMap,
        restrictedLootboxesPurchaseCount: state.account.restrictedLootboxesPurchaseCount,
        bundles: state.app.bundles,
    };
};

const BundleLimitContainer = ({ size, className, bundle }: IBundleLimitContainer) => {
    const isAuthorizedUser = !!Account.getAccount();
    const state = useAppSelector(stateSelector, equal);
    const restrictedLootboxesPurchaseCount = state.restrictedLootboxesPurchaseCount;
    const totalPurchased = getSumFromArray(Object.values(restrictedLootboxesPurchaseCount || []));
    const bundlesInfo = getContainerInfoFromTooltip(bundle, restrictedLootboxesPurchaseCount);

    if (!isAuthorizedUser) {
        return null;
    }

    return <BundleLimitWidget size={size} total={totalPurchased} bundlesInfo={bundlesInfo} className={className} />;
};

export default BundleLimitContainer;
