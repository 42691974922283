import equal from 'fast-deep-equal/react';
import ViewCardContainer from '~/containers/ViewCardContainer/ViewCardContainer';
import { useAppSelector } from '~/Store';

interface ISingleBundlePage {
    bundleId: number;
}

const SingleBundlePage = ({ bundleId }: ISingleBundlePage) => {
    const currentPage = useAppSelector((state) => state.app.currentPage, equal);

    if (!bundleId || !currentPage?.name) {
        return null;
    }

    return (
        <ViewCardContainer
            params={{
                id: bundleId,
                categoryName: currentPage.name,
            }}
        />
    );
};

export default SingleBundlePage;
