import * as React from 'react';
import { NotificationPopup } from '~/components/Notifications/Notifications';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';

import CouponPopup from '~/components/Popups/Coupon/CouponPopup';
import CurrenciesPopup from '~/components/Popups/CurrenciesPopup';
import ChooseCouponPopup from '~/components/Popups/ChooseCouponPopup/ChooseCouponPopup';
import ConfirmPurchase from '~/components/Popups/Purchase/ConfirmPurchase';
import PurchaseSuccessPopup from '~/components/Popups/PurchaseSuccessPopup';
import PurchaseErrorPopup from '~/components/Popups/PurchaseErrorPopup';
import DownloadGamePopup from '~/components/Popups/DownloadGamePopup';
import WSMartPurchaseResultPopup from '~/components/Popups/WSMartPurchaseResultPopup';
import AuctionAboutPopup from '~/components/Popups/Auction/AboutPopup/AuctionAboutPopup';
import BidPlacingSuccessPopup from '~/components/Popups/Auction/BidPlacingSuccessPopup/BidPlacingSuccessPopup';
import BidPlacingPopup from '~/components/Popups/Auction/BidPlacingPopup/BidPlacingPopup';
import BidPlacingErrorPopup from '~/components/Popups/Auction/BidPlacingErrorPopup/BidPlacingErrorPopup';
import IframePopup from '~/components/Popups/Iframe/IframePopup';
import BundleLimitPopup from '~/components/BundleLimitWidget/Popup/BundleLimitPopup';
import WonPopup from '~/components/Popups/Auction/WonPopup/WonPopup';
import AutoDescriptionPopup from '~/components/Popups/AutoDescription/AutoDescriptionPopup';
import WsmartDoubloonsPopup from '~/components/Popups/Wsmart/WsmartDoubloonsPopup';
import MobileTooltip from '~/components/Popups/MobileTooltip/MobileTooltip';
import RandomBundlesPurchaseSuccess from '~/components/Popups/RandomBundlePurchase/RandomBundlesPurchaseSuccess';
import ItemDescriptionPopup from './Popups/ItemDescription/ItemDescriptionPopup';
import SerialSequencePurchasePopup from '~/components/Popups/Purchase/SerialSequence/SerialSequencePurchasePopup';
import SerialSequenceSucessPurchase from './Popups/SerialSequencePurchase/SerialSequenceSucessPurchase';
import SeabattleLoaderPopup from '~/components/Popups/SeabattleLoader/SeabattleLoaderPopup';
import ForbiddenRegion from './Popups/ForbiddenRegion/ForbiddenRegion';
import TradeInConfirmation from './Popups/TradeInConfirmation/TradeInConfirmation';
import TradeInStatus from './Popups/TradeInStatus/TradeInStatus';
import TradeInRules from './Popups/TradeInRules/TradeInRules';
import GiftPopup from './Popups/Gift/GiftPopup';
import RewardsPopup from './Popups/RewardsPopup/RewardsPopup';

interface IPopupManager {
    popup: IPopup;
    isPopupActive: boolean;
}

export enum POPUPS_NAME {
    COUPON_POPUP = 'COUPON_POPUP',
    CURRENCIES_POPUP = 'CURRENCIES_POPUP',
    CHOOSE_COUPON_POPUP = 'CHOOSE_COUPON_POPUP',
    CONFIRM_PURCHASE = 'CONFIRM_PURCHASE',
    SUCCESS_PURCHASE = 'SUCCESS_PURCHASE',
    PURCHASE_RANDOM_BUNDLE_SUCCESS = 'PURCHASE_RANDOM_BUNDLE_SUCCESS',
    ERROR_PURCHASE = 'ERROR_PURCHASE',
    INFO_SCREEN_POPUP = 'INFO_SCREEN_POPUP',
    WELCOME_PAGE_POPUP = 'WELCOME_PAGE_POPUP',
    DOWNLOAD_GAME_POPUP = 'DOWNLOAD_GAME_POPUP',
    WSMART_PURCHASE_RESULT = 'WSMART_PURCHASE_RESULT',
    AUCTION_ABOUT_POPUP = 'AUCTION_ABOUT_POPUP',
    BID_SUCCESSFULLY_PLACED = 'BID_SUCCESSFULLY_PLACED',
    BID_ERROR_PLACED = 'BID_ERROR_PLACED',
    BID_PLACED_POPUP = 'BID_PLACED_POPUP',
    AUCTION_WON_POPUP = 'AUCTION_WON_POPUP',
    IFRAME_POPUP = 'IFRAME_POPUP',
    AUTO_DESCRIPTION_POPUP = 'AUTO_DESCRIPTION_POPUP',
    BUNDLE_LIMIT_INFO = 'BUNDLE_LIMIT_INFO',
    WSMART_DOUBLOONS_POPUP = 'WSMART_DOUBLOONS_POPUP',
    SANTA_AUTO_DESCRIPTION_POPUP = 'SANTA_AUTO_DESCRIPTION_POPUP',
    MOBILE_TOOLTIP_POPUP = 'MOBILE_TOOLTIP_POPUP',
    ITEM_DESCRIPTION_POPUP = 'ITEM_DESCRIPTION_POPUP',
    SERIAL_SEQUENCE_PURCHASE = 'SERIAL_SEQUENCE_PURCHASE',
    SERIAL_SEQUENCE_SUCCESS_PURCHASE = 'SERIAL_SEQUENCE_SUCCESS_PURCHASE',
    SEABATTLE_LOADER = 'SEABATTLE_LOADER',
    FORBIDDEN_REGION = 'FORBIDDEN_REGION',
    TRADEIN_CONFIRMATION = 'TRADEIN_CONFIRMATION',
    TRADEIN_STATUS = 'TRADEIN_STATUS',
    TRADEIN_RULES = 'TRADEIN_RULES',
    CUSTOM_COMPONENT = 'CUSTOM_COMPONENT',
    GIFT = 'GIFT',
    REWARDS = 'REWARDS',
}

const POPUP_COMPONENTS: Record<string, React.FC<{ data?: any }>> = {
    [POPUPS_NAME.COUPON_POPUP]: CouponPopup,
    [POPUPS_NAME.CURRENCIES_POPUP]: CurrenciesPopup,
    [POPUPS_NAME.CHOOSE_COUPON_POPUP]: ChooseCouponPopup,
    [POPUPS_NAME.CONFIRM_PURCHASE]: ConfirmPurchase,
    [POPUPS_NAME.SUCCESS_PURCHASE]: PurchaseSuccessPopup,
    [POPUPS_NAME.ERROR_PURCHASE]: PurchaseErrorPopup,
    [POPUPS_NAME.DOWNLOAD_GAME_POPUP]: DownloadGamePopup,
    [POPUPS_NAME.INFO_SCREEN_POPUP]: NotificationPopup,
    [POPUPS_NAME.WSMART_PURCHASE_RESULT]: WSMartPurchaseResultPopup,
    [POPUPS_NAME.AUCTION_ABOUT_POPUP]: AuctionAboutPopup,
    [POPUPS_NAME.BID_SUCCESSFULLY_PLACED]: BidPlacingSuccessPopup,
    [POPUPS_NAME.BID_ERROR_PLACED]: BidPlacingErrorPopup,
    [POPUPS_NAME.BID_PLACED_POPUP]: BidPlacingPopup,
    [POPUPS_NAME.IFRAME_POPUP]: IframePopup,
    [POPUPS_NAME.AUTO_DESCRIPTION_POPUP]: AutoDescriptionPopup,
    [POPUPS_NAME.BUNDLE_LIMIT_INFO]: BundleLimitPopup,
    [POPUPS_NAME.AUCTION_WON_POPUP]: WonPopup,
    [POPUPS_NAME.WSMART_DOUBLOONS_POPUP]: WsmartDoubloonsPopup,
    [POPUPS_NAME.MOBILE_TOOLTIP_POPUP]: MobileTooltip,
    [POPUPS_NAME.PURCHASE_RANDOM_BUNDLE_SUCCESS]: RandomBundlesPurchaseSuccess,
    [POPUPS_NAME.ITEM_DESCRIPTION_POPUP]: ItemDescriptionPopup,
    [POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE]: SerialSequencePurchasePopup,
    [POPUPS_NAME.SERIAL_SEQUENCE_SUCCESS_PURCHASE]: SerialSequenceSucessPurchase,
    [POPUPS_NAME.SEABATTLE_LOADER]: SeabattleLoaderPopup,
    [POPUPS_NAME.FORBIDDEN_REGION]: ForbiddenRegion,
    [POPUPS_NAME.TRADEIN_CONFIRMATION]: TradeInConfirmation,
    [POPUPS_NAME.TRADEIN_STATUS]: TradeInStatus,
    [POPUPS_NAME.TRADEIN_RULES]: TradeInRules,
    [POPUPS_NAME.GIFT]: GiftPopup,
    [POPUPS_NAME.REWARDS]: RewardsPopup,
};

const PopupManager = (props: IPopupManager) => {
    let popup: React.ReactNode;
    if (props.popup.name === POPUPS_NAME.CUSTOM_COMPONENT) {
        if (props.popup?.data?.component) {
            popup = props.popup.data.component;
        } else {
            return null;
        }
    } else {
        const Component = POPUP_COMPONENTS[props.popup.name];
        if (Component) {
            popup = <Component data={props.popup?.data} />;
        }
    }

    if (!popup) {
        return null;
    }

    return <PopupManagerContext.Provider value={props}>{popup}</PopupManagerContext.Provider>;
};

export default PopupManager;
