import * as React from 'react';
import classNames from 'classnames';
import styles from './ViewBackground.scss';
import equal from 'fast-deep-equal/react';
import { isEmptyObject } from '~/utils/utils';
import { settings } from '~/utils/settings';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { useAvailableSound } from '~/hooks/useAvailableSound';
import { RootState, useAppSelector } from '~/Store';

const stateSelector = (state: RootState) => {
    return {
        viewBackground: state.app.viewBackground,
        selectedRandomBundles: state.account.selectedRandomBundles,
        isTrusted: state.app.isTrusted,
        soundStatus: state.app.soundStatus,
        port: state.app.port,
        bundles: state.app.bundles,
        currentPage: state.app.currentPage,
    };
};

interface IVideoViewBackground {
    isBlur: boolean;
    bundle: IBundle;
    category: ICategory;
    children?: React.ReactNode;
}

const VideoViewBackground = ({ isBlur, bundle, category, children }: IVideoViewBackground) => {
    const state = useAppSelector(stateSelector, equal);
    let currentBundle = bundle;
    let bundleVideoBackground = bundle?.videoBackground;
    if (isEmptyObject(bundleVideoBackground) && bundle?.parentBundleId) {
        currentBundle = state.bundles[bundle.parentBundleId];
        bundleVideoBackground = currentBundle.videoBackground;
    }
    const categoryVideoBackground = category?.videoBackground;
    const [isBlurView, setBlur] = React.useState(false);
    const isAvailableSoundEffect = useAvailableSound(category, currentBundle);
    let muted = !isAvailableSoundEffect;
    if (!state.isTrusted) {
        muted = true;
    }

    React.useEffect(() => {
        setBlur(isBlur);
    }, [isBlur]);

    if (state.port?.isVisible) {
        return null;
    }

    const backgroundColor = bundle?.backgroundColor || category?.backgroundColor;
    const style = !!backgroundColor ? ({ '--bundle-video-background-color': backgroundColor } as React.CSSProperties) : {};

    const classesWrapper = classNames(styles.videoBackgroundWrapper, { [styles.blur]: isBlurView });

    return (
        <div className={classesWrapper} style={style}>
            <VideoBackground
                muted={muted}
                poster={state.viewBackground}
                video={isEmptyObject(bundleVideoBackground) ? categoryVideoBackground : bundleVideoBackground}
                className={classNames(state.currentPage?.name, styles[state.currentPage?.name], styles[settings.realm], {
                    [styles.videoBackground]: !!backgroundColor,
                })}
                withMask={!!backgroundColor}
            >
                {!!backgroundColor && <div className={styles.videoBackgroundMask}></div>}
            </VideoBackground>
            {children ? <div className={styles.videoBackgroundTheme}>{children}</div> : null}
        </div>
    );
};

export default React.memo(VideoViewBackground);
