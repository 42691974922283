import { isCurrency } from '~/utils/currencies';
import styles from './FillerLayout.scss';
import { t } from '~/utils/localization';
import { isEmptyObject } from '~/utils/utils';
import { Interpolate } from '@wg/wows-react-uikit';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { getGroupsList } from '../../utils/texts';

interface IFillerLayout {
    lootbox: ILootboxStateItem;
}

const FillerLayout = ({ lootbox }: IFillerLayout) => {
    const { filler, savePointForValuableGroup, uniqueGroupTitles } = lootbox;

    if (!filler || isEmptyObject(filler)) {
        return null;
    }

    let fillerText;
    if (savePointForValuableGroup?.savePoint && uniqueGroupTitles?.length === 1) {
        fillerText = t('Если у вас уже есть все предметы группы «%(groupName)s» и из контейнера «%(containerName)s» вам выпадет предмет из этой группы, вы получите:');
    } else {
        fillerText = t('Если у вас уже есть все предметы из групп: %(lists)s, – и из контейнера «%(containerName)s» вам выпадет предмет из этих групп, вы получите:');
    }

    return (
        <div className={styles.footerText}>
            <Interpolate str={fillerText} groupName={uniqueGroupTitles[0]} containerName={lootbox.shortTitle} lists={getGroupsList(lootbox)} />
            <div className={styles.filler}>
                <Interpolate
                    str={'%(filler)s'}
                    filler={
                        <WoWSEntity
                            id={filler.id}
                            type={filler.type}
                            amount={filler.amount}
                            className={styles.fillerWeComponent}
                            presentation={{
                                withText: !isCurrency(filler.type),
                                withTooltip: true,
                                iconPosition: 'left',
                            }}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default FillerLayout;
