import * as React from 'react';
import styles from './Tooltip.scss';
import ShortBundleContains from '~/components/Bundle/Contains/ShortBundleContains';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import Contains, { IContainsItem } from '../ItemContains/Contains';
import { items as ITEMS_TYPES } from '@wg/wows-entities/const';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from './DefaultTooltip';

class IItemsContainsTooltip {
    bundle: IBundle;
    limit?: number;
}

export const ItemsContainsTooltip = (props: IItemsContainsTooltip): React.ReactChild | any => {
    return (
        <div className={styles.defaultTooltip}>
            <ShortBundleContains bundle={props.bundle} className={styles.defaultTooltipBody} hiddenVoButton limit={props.limit} />
        </div>
    );
};

export const getTooltipContainsContent = (items: IItemCommonData[]) => {
    const preparedItems: IContainsItem[] = items.map((item, index) => {
        let content;
        if (item.customTitle) {
            content = (
                <DivTooltip tooltipBody={<DefaultTooltip text={item.customDescription || item.customTitle} />} key={`${index}_custom_item_${item.type}`}>
                    <div dangerouslySetInnerHTML={{ __html: item.customTitle }} />
                </DivTooltip>
            );
        } else {
            content = (
                <WoWSEntity
                    key={`${index}_${item.identifier}_item_x${item.amount}`}
                    type={item.type}
                    id={item.identifier}
                    amount={item.amount}
                    presentation={{
                        withText: true,
                        withTooltip: true,
                        iconPosition: 'left',
                        renderWithoutFade: true,
                        hiddenInlineShip: item.type === ITEMS_TYPES.CREWS,
                    }}
                />
            );
        }

        return {
            content,
            id: item.identifier?.toString(),
            type: item.type,
            isExistsInInventory: true,
        };
    });

    return (
        <div className={styles.tooltipWrapper}>
            <Contains items={preparedItems} isLarge={false} limit={3} />
        </div>
    );
};
