import * as React from 'react';
import { isMobileOrTabletWindow, updateFlagMobileOrTablet } from '~/utils/utils';
import useMatchMedia from '~/hooks/useMatchMedia';
import { MOBILE_RESOLUTION } from '~/const';

export default function (): [boolean] {
    const [isMobile, setMobile] = React.useState(isMobileOrTabletWindow);

    useMatchMedia(`(max-width: ${MOBILE_RESOLUTION}px)`, (event) => {
        updateFlagMobileOrTablet(event.matches);
        setMobile(event.matches);
    });

    return [isMobile];
}
