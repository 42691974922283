import * as React from 'react';
import styles from './AuctionAboutPopup.scss';
import classNames from 'classnames';
import { shallowEqual, useDispatch } from 'react-redux';
import { ZoomInDiv } from '~/components/Transitions';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import popupSettings from '~/components/Popups/Auction/AboutPopup/PopupSettings';
import Button from '~/components/Button/Button';
import { interpolate, t } from '~/utils/localization';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';
import { openUrl } from '~/utils/settings';
import { AUCTION_KIND } from '~/const';
import useDisableMobileBodyScroll from '~/hooks/useDisableMobileBodyScroll';
import { playButtonClickSound } from '~/api/WoWsClient';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

const onClickOnDescription = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target?.tagName && target.tagName.toLowerCase() === 'a') {
        event.preventDefault();
        openUrl(target.getAttribute('href'));
    }
};

const AuctionAboutPopup = () => {
    const { isPopupActive } = React.useContext(PopupManagerContext);
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    const auctions = useAppSelector((state) => state.auction.activeAuctions, shallowEqual);
    const auctionsKind = Array.from(new Set(auctions.map((a) => a?.kind))).join(', ');

    const close = () => dispatch(appActions.changeVisibilityPopup({ name: null }));

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        playButtonClickSound();
        close();
    }, [KEYS_CODE.ESC]);
    useDisableMobileBodyScroll();

    return (
        <ZoomInDiv className={styles.wrapper} ref={ref}>
            <div className={styles.close}>
                <ButtonEsc onClick={close} />
            </div>
            <div className={styles.header}>
                {auctionsKind.includes(AUCTION_KIND.FIRST_PRICE) && auctionsKind.includes(AUCTION_KIND.SECOND_PRICE)
                    ? popupSettings.title
                    : auctionsKind.includes(AUCTION_KIND.FIRST_PRICE)
                    ? popupSettings.titleFirstPrice
                    : popupSettings.titleSecondPrice}
            </div>
            <div className={styles.content}>
                {popupSettings.contentBlocks.map((block, index) => {
                    const description = interpolate(block?.description || '', {
                        br: '<br/>',
                    });

                    if (block.type && !auctionsKind.includes(block.type)) return;

                    return (
                        <div className={styles.block} key={index}>
                            {!!block.title && <div className={styles.title}>{block.title}</div>}
                            {!!block.description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} onClick={onClickOnDescription} />}
                            {!!block.youtubeLink && (
                                <div className={styles.youtubeVideo}>
                                    <div className={styles.video}>
                                        <iframe src={block.youtubeLink} frameBorder={'0'} allowFullScreen />
                                    </div>
                                </div>
                            )}
                            {!!block.imagesContent?.length && (
                                <div className={styles.images}>
                                    {block.imagesContent.map((imageBlock, index) => {
                                        return (
                                            <div className={classNames(styles.imageBlock, styles[imageBlock.className])} key={index}>
                                                <div className={styles.imageTitle}>{imageBlock.title}</div>
                                                <div className={styles.image} />
                                                <div className={styles.imageSubTitle}>{imageBlock.subTitle}</div>
                                                <div className={styles.imageDescription}>{imageBlock.description}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            <div className={styles.footer}>
                <Button label={t('Закрыть')} onClick={close} />
            </div>
        </ZoomInDiv>
    );
};

export default AuctionAboutPopup;
