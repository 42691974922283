import { isEmptyObject, isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import { hasAnimationSetting, isDisabledAnimationByName, updateAnimationStatusByName } from '~/utils/animations/settings';
import equal from 'fast-deep-equal/react';
import { UserGraphicsQualityPresetTypes } from '@wg/web2clientapi/browser/getUserGraphicsQualityPreset';
import React from 'react';
import { RootState, useAppSelector } from '~/Store';

const hookStateSelector = (state: RootState) => {
    return {
        userGraphics: state.account.userGraphics,
        currentPage: state.app.currentPage,
    };
};

export const useDisabledAnimationByGraphics = (name: string) => {
    const { userGraphics, currentPage } = useAppSelector(hookStateSelector, equal);

    React.useEffect(() => {
        if (!name || hasAnimationSetting(name) || !isInGameBrowser || isEmptyObject(userGraphics)) {
            return;
        }
        if (userGraphics.preset === UserGraphicsQualityPresetTypes.LOW && name) {
            updateAnimationStatusByName(name, true);
        }
    }, [name, currentPage?.name, userGraphics]);
};

export default function (name: string, isDisabledMobileAnimation: boolean = isMobileOrTabletWindow) {
    const categoriesAnimationStatuses = useAppSelector((state) => state.app.categoriesAnimationStatuses, equal);
    return isDisabledMobileAnimation || isDisabledAnimationByName(name) || !!categoriesAnimationStatuses?.[name];
}
