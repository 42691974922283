import * as React from 'react';
import styles from './CommonRewardsLayout.scss';
import { flatRewards, groupedRewardsByFields } from '~/Layouts/Lootboxes/utils';
import CommonReward from '~/Layouts/Lootboxes/Views/CommonRewardsLayout/CommonReward';
import GroupedRewardItem, { wowsEntityClassesMapByType } from '~/Layouts/Lootboxes/Views/CommonRewardsLayout/GroupedRewardItem';
import { commonRewardsTypesString } from '~/Layouts/Lootboxes/utils/texts';
import { t } from '~/utils/localization';
import equal from 'fast-deep-equal/react';
import { isInGameBrowser } from '~/utils/utils';
import { isCamouflage } from '~/components/Camouflages/Camouflages';
import { settings } from '~/utils/settings';
import { RootState, useAppSelector } from '~/Store';

interface IGroupedCommonRewardsLayout {
    lootboxId: number;
    rewards: ILootboxStateItemGroupRewards<ILootboxRewardGroup>;
    openPopup: () => void;
}

const stateSelector = (state: RootState) => {
    return {
        inventory: state.account.inventory,
        currentPage: state.app.currentPage,
    };
};

const GroupedCommonRewardsLayout = ({ rewards, openPopup, lootboxId }: IGroupedCommonRewardsLayout) => {
    const state = useAppSelector(stateSelector, equal);
    const arrayRewards = flatRewards(rewards);
    const groupedItems = groupedRewardsByFields(arrayRewards);
    const keys = Object.keys(groupedItems);
    let i = 0;

    return (
        <React.Fragment>
            {keys.map((key: string) => {
                const str = commonRewardsTypesString[key as keyof typeof commonRewardsTypesString];
                const allChanceKeys = Object.keys(groupedItems[key]);
                return allChanceKeys.map((chanceKey) => {
                    const allCountItems = Object.keys(groupedItems[key][chanceKey]);
                    return allCountItems.map((itemCountKey) => {
                        const data = groupedItems[key][chanceKey][itemCountKey]?.items as ILootboxReward[];
                        const isAvailablePortPreview = !!(+state.currentPage?.bundleId && isInGameBrowser && isCamouflage(data[0]?.type) && settings.camouflagePreviewDefaultShip);
                        if (str && data.length > 1) {
                            const totalChance = +(parseFloat(chanceKey) * (data.length || 1)).toFixed(2);
                            const isSimularRewards = data.every((reward) => reward.id === data[0].id);
                            return (
                                <GroupedRewardItem
                                    key={`grouped_reward_${key}_${chanceKey}_${itemCountKey}`}
                                    amount={itemCountKey}
                                    type={key as keyof typeof wowsEntityClassesMapByType}
                                    rewards={data}
                                    chance={totalChance}
                                    str={str}
                                    isAvailablePortPreview={isAvailablePortPreview && isSimularRewards}
                                    bundleId={+state.currentPage?.bundleId}
                                    lootboxId={lootboxId}
                                />
                            );
                        } else {
                            return data.map((reward, index) => {
                                i++;
                                return (
                                    <CommonReward
                                        key={`grouped_reward_${key}_${chanceKey}_${itemCountKey}_${reward.id}_${index}`}
                                        reward={reward}
                                        inventoryCount={state.inventory?.storage?.[reward.id]}
                                        isAvailablePortPreview={isAvailablePortPreview}
                                        bundleId={+state.currentPage?.bundleId}
                                        lootboxId={lootboxId}
                                    />
                                );
                            });
                        }
                    });
                });
            })}
            {arrayRewards.length !== i && (
                <div className={styles.footer}>
                    <span className={styles.openPopupLink} onClick={() => openPopup?.()}>
                        {t('Список:Полный список')}
                    </span>
                </div>
            )}
        </React.Fragment>
    );
};

export default GroupedCommonRewardsLayout;
