import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './MobileCoupon.scss';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { getActiveCoupon, isEnabledCouponFromBundle, selectCouponFromBundle, sortedCoupons } from '~/utils/coupons';
import { RootState, useAppSelector } from '~/Store';
import { accountActions } from '~/Store/accountSlice';

interface IMobileCouponWrapper {
    bundle: IBundle;
    isDisabled?: boolean;
}

const stateSelector = (state: RootState) => {
    return {
        activeCouponId: state.account.activeCouponId,
        prevActiveCouponId: state.account.prevActiveCouponId,
        coupons: state.account.coupons,
    };
};

const MobileCouponWrapper = (props: IMobileCouponWrapper) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const activeCouponId: number = state.activeCouponId;
    const [currentCouponId, setCurrentCouponId] = React.useState<number>(state.prevActiveCouponId);
    const coupons = state.coupons.filter((coupon: ICoupon) => isEnabledCouponFromBundle(coupon, props.bundle));

    let mappingCoupons = sortedCoupons(coupons, 2);
    const mappingCouponsIds = mappingCoupons.map((coupon) => coupon.id);

    const removeCoupon = (event: React.MouseEvent) => {
        event.preventDefault();
        dispatch(accountActions.removeActivatedCoupon());
    };

    const activeId = activeCouponId || currentCouponId;
    if (activeId && !mappingCouponsIds.includes(activeId)) {
        mappingCoupons = [getActiveCoupon(activeId, coupons), mappingCoupons[0]];
    }

    React.useEffect(() => {
        if (state.prevActiveCouponId) {
            setCurrentCouponId(state.prevActiveCouponId);
        }
    }, [state.prevActiveCouponId]);

    return (
        <div
            className={classNames(styles.couponsWrapper, {
                [styles.disabled]: props.isDisabled,
            })}
        >
            {mappingCoupons.map((coupon) => {
                if (!coupon) {
                    return null;
                }

                const discount = parseInt((coupon.discount * 100).toString(), 10);
                const isActive = activeCouponId === coupon.id;

                return (
                    <div
                        key={coupon.id}
                        className={classNames(styles.item, {
                            [styles.active]: isActive,
                        })}
                        onClick={(event: React.MouseEvent) => {
                            if (props.isDisabled) {
                                return;
                            }

                            if (isActive) {
                                removeCoupon(event);
                                return;
                            }

                            selectCouponFromBundle(coupons, coupon, props.bundle);
                        }}
                    >
                        <span>-</span>
                        <span>{discount}</span>
                        <span>%</span>
                    </div>
                );
            })}
            {coupons.length > 2 && (
                <div
                    className={classNames(styles.item, styles.add)}
                    onClick={() => {
                        selectCouponFromBundle(coupons, null, props.bundle);
                    }}
                />
            )}
        </div>
    );
};

export default MobileCouponWrapper;
