import * as React from 'react';
import styles from './FeaturedTimer.scss';
import classNames from 'classnames';
import CountDown from '~/components/CountDown/CountDown';
import Info from '~/components/Info/Info';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';

interface IFeaturedTimer {
    activeTill: string;
    color?: string;
    title: string;
    description?: string;
    className?: string;
    countDownClassName?: string;
    labelClassName?: string;
    onComplete?: () => void;
}

const FeaturedTimer = ({ activeTill, color, title, description, className, countDownClassName, labelClassName, onComplete }: IFeaturedTimer) => {
    const style = { '--featured-timer-color': color } as React.CSSProperties;
    const dropShadowFilter = { [styles.dropShadowFilter]: !color };

    return (
        <div className={classNames(styles.wrapper, className, dropShadowFilter)}>
            <div className={styles.title} style={style}>
                <span className={classNames(styles.label, labelClassName)}>{title}</span>
            </div>
            <DivTooltip className={styles.timer} style={style} tooltipBody={description && <DefaultTooltip text={description} />}>
                <CountDown date={new Date(activeTill).getTime()} isLarge={true} className={classNames(styles.fontNormal, countDownClassName)} onComplete={onComplete} />
                {description && (
                    <span className={styles.info}>
                        <Info color={color} />
                    </span>
                )}
            </DivTooltip>
        </div>
    );
};

export default FeaturedTimer;
