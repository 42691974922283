import { isSafariBrowser } from './settings';
import { getClientPath, isInGameBrowser } from './utils';

const videoFormats: Record<string, string> = {
    ogg: 'video/ogg; codecs="theora"',
    mp4: 'video/mp4; codecs="avc1.42E01E"',
    webm: 'video/webm; codecs="vp8, vorbis"',
    vp9: 'video/webm; codecs="vp9"',
};

export function isVideoTypeSupported(type: string) {
    const video = document.createElement('video');
    return video.canPlayType(videoFormats[type] || type) === 'probably';
}

export function getSupportedVideo(videoMap: Partial<IVideo>) {
    if (isInGameBrowser) {
        if (!!videoMap?.clientVideoName?.trim()?.length) {
            return getClientPath(`armory/${videoMap.clientVideoName}`);
        }
        return videoMap?.webm;
    }

    if (!isSafariBrowser && videoMap?.webm && isVideoTypeSupported('webm')) {
        return videoMap.webm;
    }

    if (videoMap?.mp4 && isVideoTypeSupported('mp4')) {
        return videoMap.mp4;
    }

    return null;
}
