import * as React from 'react';
import styles from './OfferMark.scss';
import classNames from 'classnames';
import Timer from '~/components/Timer/Timer';
import { t } from '~/utils/localization';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';

interface IOfferMarkProps {
    expiredTime?: number | string;
    limit?: number;
    isNeedToHiddenLimit?: boolean;
    isNeedToHiddenTimer?: boolean;
    label?: string;
    className?: string;
}

const OfferMark = function (props: IOfferMarkProps) {
    const purchaseLimitText = t('Вам доступно: %(number)s').replace('%(number)s', (props.limit || 0).toString());

    return (
        <div className={classNames(styles.markWrapper, props.className)}>
            {props.label && <div className={classNames(styles.blockInfo, 'armory__auto-bundle_label')}>{props.label}</div>}
            {props.expiredTime && !props.isNeedToHiddenTimer && (
                <DivTooltip className={classNames(styles.blockInfo, styles.clockTime)} tooltipBody={<DefaultTooltip text={t('Предложение ограничено по времени')} />}>
                    {<Timer time={props.expiredTime} className={styles.timerWrapper} />}
                </DivTooltip>
            )}
            {props.limit && !props.isNeedToHiddenLimit && (
                <div className={classNames(styles.blockInfo, 'armory__auto-bundle_limit')} data-limit={props.limit || 0}>
                    {purchaseLimitText}
                </div>
            )}
        </div>
    );
};

export default OfferMark;
