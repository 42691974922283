import styles from './SantaShowcaseSwitcher.scss';
import classNames from 'classnames';
import equal from 'fast-deep-equal/react';
import { CATEGORIES } from '~/const';
import { openCategoryByName } from '~/utils/category';
import { t } from '~/utils/localization';
import { isChina, settings } from '~/utils/settings';
import { isAvailableSwitcherBetweenSantaCategories } from '~/Layouts/Themes/SantaPage/settings';
import { SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import { RootState, useAppSelector } from '~/Store';

interface ISantaShowcaseSwitcher {
    className?: string;
}

const stateSelector = (state: RootState) => {
    return {
        currentPage: state.app.currentPage,
        categories: state.app.categories,
    };
};

const SantaShowcaseSwitcher = ({ className }: ISantaShowcaseSwitcher) => {
    const { currentPage, categories } = useAppSelector(stateSelector, equal);
    const isAvailableSwitcher = isAvailableSwitcherBetweenSantaCategories();
    const isGoldPage = currentPage?.name === CATEGORIES.SANTA_DOUBLOONS_CONTAINERS;
    const isCn = isChina();

    if (!isAvailableSwitcher || currentPage?.isBundlePage || categories[currentPage?.name]?.theme !== SANTA_PAGE_THEME) {
        return null;
    }

    const onClick = () => {
        if (currentPage?.name === CATEGORIES.SANTA_DOUBLOONS_CONTAINERS) {
            openCategoryByName(CATEGORIES.SANTA_CONTAINERS);
        } else {
            openCategoryByName(CATEGORIES.SANTA_DOUBLOONS_CONTAINERS);
        }
    };

    const classesWrapper = classNames(
        styles.wrapper,
        {
            [styles.isRealMoney]: !isGoldPage,
        },
        styles[settings.realm],
    );

    const bundlesForRealMoneyTitle = isCn ? t('China:Наборы за реальную валюту') : t('Наборы за реальную валюту');
    const bundlesForIngameCurrencyTitle = isCn ? t('Контейнеры за Eventum_CN') : t('Контейнеры за дублоны');

    return (
        <div className={classNames(styles.content, className)}>
            <div className={classesWrapper} onClick={onClick}>
                <span className={styles.label}>{isGoldPage ? bundlesForRealMoneyTitle : bundlesForIngameCurrencyTitle}</span>
            </div>
        </div>
    );
};

export default SantaShowcaseSwitcher;
