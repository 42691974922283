import * as React from 'react';
import styles from './LegalFooter.scss';
import { interpolate, t } from '~/utils/localization';
import equal from 'fast-deep-equal/react';
import { openUrl, settings } from '~/utils/settings';
import { detectPlatform, PLATFORMS } from '@wg/wows-commerce';
import { TSV_ACCOUNT_STATUSES } from '@wg/wows-commerce/constants/web/tsvAccountStatuses';
import { RootState, useAppSelector } from '~/Store';

const stateSelector = (state: RootState) => {
    return {
        managementUrl: state.wsmart.managementUrl,
        tsv: state.wsmart.tsv,
    };
};

const LegalFooter = () => {
    const state = useAppSelector(stateSelector, equal);
    const managementUrl = state.managementUrl;
    const platform = detectPlatform();

    const openExternalLink = (event: React.MouseEvent) => {
        event.preventDefault();
        const target = event.target as HTMLLinkElement;
        if (target.tagName.toLowerCase() === 'a') {
            openUrl(target.href);
        }
    };

    const twoFactorOptions = {
        openLink: managementUrl ? `<a class="external-link" target="_blank" href="${managementUrl}">` : '',
        closeLink: managementUrl ? `</a>` : '',
    };

    let twoFactorChangeText = interpolate(t('Для обеспечения дополнительной безопасности платежа предлагаем вам {openLink}настроить защиту платежных операций{closeLink}.'), twoFactorOptions);
    if (state.tsv?.accountStatus === TSV_ACCOUNT_STATUSES.ENABLED) {
        twoFactorChangeText = interpolate(t('{openLink}Изменить защиту платежных операций{closeLink}.'), twoFactorOptions);
    }

    const legalText = interpolate(
        t(
            'The price includes VAT and sales tax, if applicable. The final price may vary slightly depending on the payment method. To see the final price, click on the button of your payment method. See {openLink}legal documents{closeLink} for more details. Wargaming Group Limited is an online merchant located at 105, Agion Omologiton Avenue, Nicosia 1080, Cyprus.',
        ),
        { openLink: `<a class="external-link" target="_blank" href="${settings.urls.lawDocumentsUrl}">`, closeLink: `</a>` },
    );

    return (
        <div className={styles.legalFooter}>
            {platform === PLATFORMS.WEB ? (
                <>
                    <div className={styles.twoFactorChange} onClick={openExternalLink} dangerouslySetInnerHTML={{ __html: twoFactorChangeText }} />
                    <div className={styles.legalText} onClick={openExternalLink} dangerouslySetInnerHTML={{ __html: legalText }} />
                </>
            ) : (
                <div className={styles.legalText}>{t('The final cost may vary depending on the rules of the platform. Please consult platform terms and conditions for more information.')}</div>
            )}
        </div>
    );
};

export default LegalFooter;
