import GroupingPage from '~/Layouts/Themes/GroupingPage/GroupingPage';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';
import SantaPage from '~/Layouts/Themes/SantaPage/SantaPage';
import SingleBundlePage from '~/Layouts/Themes/SingleBundlePage/SingleBundlePage';

export const GROUPING_PAGE_THEME = 'grouping_page';
export const SANTA_PAGE_THEME = 'santa';
export const AUCTION_PAGE_THEME = 'auction';
export const ANNIVERSARY_PROMO_THEME = 'anniversary';
export const ANNIVERSARY_2022 = 'anniversary_2022';
export const BLACK_FRIDAY_THEME = 'black_friday';
export const SINGLE_BUNDLE_PAGE = 'single_bundle_page';
export const NY_THEME = 'ny';
export const SNOWFALL = 'snowfall';

interface IThemeManager {
    theme: ALLOWED_THEMES;
    bundles: number[];
}

export const isThemeEnabled = (themeName: string) => {
    return [GROUPING_PAGE_THEME, SANTA_PAGE_THEME, SINGLE_BUNDLE_PAGE].includes(themeName);
};

const ThemeManager = (props: IThemeManager) => {
    switch (props.theme) {
        case GROUPING_PAGE_THEME:
            return <GroupingPage bundles={props.bundles} />;

        case SANTA_PAGE_THEME:
            return <SantaPage bundles={props.bundles} />;

        case SINGLE_BUNDLE_PAGE:
            return <SingleBundlePage bundleId={props.bundles?.[0]} />;
    }

    return null;
};

export default ThemeManager;
