import * as React from 'react';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import SantaCustomization from '~/components/Popups/Purchase/Customization/Santa/SantaCustomization';

interface IPurchaseCustomizationWrapper {
    category: ICategory;
    bundle: IBundle;
    children: React.ReactNode;
}

const PurchaseCustomizationWrapper = ({ category, bundle, children }: IPurchaseCustomizationWrapper) => {
    const fallback = <>{children}</>;

    if (isMobileOrTabletWindow || !category) {
        return fallback;
    }

    switch (category.theme) {
        case SANTA_PAGE_THEME: {
            return <SantaCustomization bundle={bundle}>{children}</SantaCustomization>;
        }

        default: {
            return fallback;
        }
    }
};

export default PurchaseCustomizationWrapper;
