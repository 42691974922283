import * as React from 'react';
import styles from './RandomBundle.scss';
import classNames from 'classnames';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { FadeInDiv } from '~/components/Transitions';
import Account from '~/account/Account';

interface IRandomBundleDoors {
    isEnabled: boolean;
    isEnabledFreeOpen: boolean;
    bundle: IBundle;
    activeBundle: IBundle;
    periodicBundleNextDate: number;
    isAlreadyPurchased?: boolean;
}

const RandomBundleImage = (props: IRandomBundleDoors) => {
    const accountId = Account.getAccount()?.id;
    const itemElement: React.RefObject<HTMLDivElement> = React.useRef();

    const isParentBundle = props.activeBundle?.id === props.bundle.id;
    let itemBackgroundImage = isParentBundle ? props.activeBundle?.icons.medium || props.activeBundle?.icons.big : props.activeBundle?.icons?.medium || props.bundle.icons?.medium;

    if (!accountId || props.isAlreadyPurchased || props.isEnabledFreeOpen) {
        if (isMobileOrTabletWindow) {
            itemBackgroundImage = props.bundle.icons?.big;
        }

        return <div className={styles.defaultImageWrapper} style={{ backgroundImage: `url(${itemBackgroundImage})` }} />;
    }

    if (props.isEnabled) {
        const classesImageWrapper = classNames(styles.imageWrapper, {
            [styles.open]: accountId && !props.isEnabledFreeOpen,
            [styles.mobileImage]: isMobileOrTabletWindow,
            ...(props.bundle.decoration || []),
        });

        if (isMobileOrTabletWindow) {
            itemBackgroundImage = props.activeBundle?.icons.small || props.bundle.icons?.small;
        }

        return (
            <React.Fragment>
                {!props.isEnabledFreeOpen && (
                    <FadeInDiv
                        className={classesImageWrapper}
                        style={{
                            backgroundImage: `url(${itemBackgroundImage})`,
                        }}
                    />
                )}
            </React.Fragment>
        );
    }

    if (!props.periodicBundleNextDate) {
        return (
            <FadeInDiv
                className={styles.imageWrapper}
                ref={itemElement}
                style={{
                    backgroundImage: `url(${itemBackgroundImage})`,
                }}
            />
        );
    }

    return null;
};

export default React.memo(RandomBundleImage);
