import { ManagerData } from '~/core/ManagerData';
import { getLevelFromRomanFormat } from '~/utils/utils';
import { interpolate, t } from '~/utils/localization';
import { isLegendaryShip } from '~/components/Ship/Ship';
import { componentHelper } from '@wg/wows-entities';

export const getInlineShipInfo = (shipId: number, _level?: number, type?: string, tags?: string[]): string => {
    const ship = ManagerData.getShip(shipId);
    if (!ship && !_level && !type) {
        return null;
    }

    const shipTypeData = ManagerData.getClass(type);
    const typeTitle = shipTypeData?.title || ship.type?.title;
    const shipLevel = _level || ship.level;
    const textLevel = getLevelFromRomanFormat(shipLevel);
    const shipTags = tags || ship?.tags || [];

    if (isLegendaryShip(shipTags)) {
        return typeTitle;
    }

    if (ship.level === 11) {
        const name = componentHelper.getSuperVehicleName(ship.type?.name);
        if (name) {
            return name;
        }
        return null;
    }

    return interpolate(t('{type} {level} уровня'), {
        type: typeTitle,
        level: textLevel,
    });
};
