import * as React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import MobileCouponsCategory from '~/components/Coupon/MobileCouponsCategory';
import { t } from '~/utils/localization';
import History from '~/utils/history';
import { urlsMap } from '~/utils/menu';
import { scrollMobileContainer } from '~/utils/scrollTop';
import { hasCoupons, isCouponsTabHidden } from '~/utils/coupons';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

const stateSelector = (state: RootState) => {
    return {
        coupons: state.account.coupons,
        id: state.account.id,
    };
};

const MobileCouponsContainer = () => {
    const dispatch = useDispatch();
    const account = useAppSelector(stateSelector, shallowEqual);

    React.useEffect(() => {
        if (!account.id || isCouponsTabHidden() || !hasCoupons()) {
            History.navigate(urlsMap.home);
            return;
        }

        scrollMobileContainer({ top: 0 });
        dispatch(
            appActions.changeCurrentPage({
                title: t('Купоны'),
                name: 'coupons',
                isBundlePage: false,
            }),
        );
    }, []);

    const defaultCoupons = account.coupons?.filter((coupon: ICoupon) => coupon.isProlongable);
    const extraCoupons = account.coupons?.filter((coupon: ICoupon) => !coupon.isProlongable && !coupon.isDisabled);

    if (!account.id) {
        return null;
    }

    return <MobileCouponsCategory coupons={defaultCoupons} extraCoupons={extraCoupons} />;
};

export default MobileCouponsContainer;
