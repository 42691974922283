import classNames from 'classnames';
import styles from './CookieButton.scss';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { IButton } from '~/components/Button/Button';
import SmallPreloader from '~/components/SmallPreloader/SmallPreloader';

const CookieButton = ({ label, onClick, onFallback, onMouseMove, disabled, isFetching, className }: IButton) => {
    const onClickHandler = (event: React.MouseEvent) => {
        event.preventDefault();
        if (disabled) {
            onFallback?.(event);
            return;
        }
        playButtonClickSound();
        onClick(event);
    };

    const onMouseMoveHandler = (event: React.MouseEvent) => {
        onMouseMove?.(event);
    };

    const classesButton = classNames(styles.button, {
        [styles.fetching]: isFetching,
    });

    return (
        <button tabIndex={-1} className={classesButton} disabled={disabled} onClick={onClickHandler} onMouseMove={onMouseMoveHandler}>
            <div className={classNames(styles.mask, className)}>
                <span className={styles.background} />
                {isFetching && <SmallPreloader className={styles.loader} />}
                <span className={styles.label}>{label}</span>
            </div>
            <div className={styles.crumbs}>
                <span className={styles.crumb} />
                <span className={styles.crumb} />
                <span className={styles.crumb} />
                <span className={styles.crumb} />
                <span className={styles.crumb} />
                <span className={styles.crumb} />
            </div>
        </button>
    );
};

export default CookieButton;
