import store from '~/Store';
import ArmoryPreloader from '~/core/AppInit/ArmoryPreloader';
import AuctionPreloader from '~/core/AppInit/AuctionPreloader';
import PremiumShopPreloader from '~/core/AppInit/PremiumShopPreloader';
import FeaturesPreloader from '~/core/AppInit/FeaturesPreloader';
import SeaBattlePreloader from '~/core/AppInit/SeaBattlePreloader';
import DockyardPreloader from '~/core/AppInit/DockyardPreloader';
import TradeInPreloader from '~/core/AppInit/TradeInPreloader';
import MercatorPreloader from '~/core/AppInit/MercatorPreloader';
import { appActions } from '~/Store/appSlice';

async function AppInit(): Promise<void> {
    await new ArmoryPreloader().load();

    const promises = [
        new PremiumShopPreloader().load(),
        new AuctionPreloader().load(),
        new FeaturesPreloader().load(),
        new DockyardPreloader().load(),
        new SeaBattlePreloader().load(),
        new TradeInPreloader().load(),
        new MercatorPreloader().load(),
    ];

    await Promise.all(promises);

    store.dispatch(appActions.finishLoadingResource());
}

export default AppInit;
