import equal from 'fast-deep-equal/react';
import classNames from 'classnames';
import styles from './CommonRewardsLayout.scss';
import CommonReward from '~/Layouts/Lootboxes/Views/CommonRewardsLayout/CommonReward';
import { flatRewards } from '~/Layouts/Lootboxes/utils';
import { t } from '~/utils/localization';
import { useDispatch } from 'react-redux';
import { POPUPS_NAME } from '~/components/PopupManager';
import GroupedCommonRewardsLayout from '~/Layouts/Lootboxes/Views/CommonRewardsLayout/GroupedCommonRewardsLayout';
import { isInGameBrowser } from '~/utils/utils';
import { isCamouflage } from '~/components/Camouflages/Camouflages';
import { settings } from '~/utils/settings';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface ICommonRewardsLayout {
    rewards: ILootboxStateItemGroupRewards<ILootboxRewardGroup>;
    hasValuableRewards?: boolean;
    className?: string;
    slotIndex: number;
    lootbox: ILootboxStateItem;
    autoDescriptionSettings: ILootboxAutoDescriptionSettings;
}

const stateSelector = (state: RootState) => {
    return {
        inventory: state.account.inventory,
        currentPage: state.app.currentPage,
    };
};

const CommonRewardsLayout = ({ rewards, hasValuableRewards, className, autoDescriptionSettings, slotIndex, lootbox }: ICommonRewardsLayout) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const itemsRewards = flatRewards(rewards);
    let title = hasValuableRewards ? t('список:Либо один предмет из списка:') : t('Один предмет из списка:');
    if (itemsRewards.length === 1) {
        title = hasValuableRewards ? t('Либо один предмет:') : t('Один предмет:');
    }

    const hasFloatChance = itemsRewards.some((reward) => Number.getCountNumbersAfterComma(reward.probabilityDisplayed) === 2);

    const { isGroupedCommonRewards } = autoDescriptionSettings || {};

    const isNeedToHiddenTitle = lootbox.slots.length === 1 && !lootbox.hasValuableRewards;

    const openPopup = () => {
        dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.AUTO_DESCRIPTION_POPUP,
                isVisible: true,
                data: {
                    isCommonRewards: true,
                    containerId: lootbox.id,
                    slotIndex,
                },
            }),
        );
    };

    const classesAboutMathRules = classNames(styles.aboutMathRules, {
        [styles.groppedRewards]: isGroupedCommonRewards,
    });

    return (
        <div className={classNames(styles.content, className)}>
            {!isNeedToHiddenTitle && (
                <div className={styles.header}>
                    <div className={styles.title}>{title}</div>
                </div>
            )}
            {!isGroupedCommonRewards ? (
                itemsRewards.map((reward, index) => {
                    const isAvailablePortPreview = !!(+state.currentPage?.bundleId && isInGameBrowser && isCamouflage(reward.type) && settings.camouflagePreviewDefaultShip);
                    return (
                        <CommonReward
                            key={`reward_item_${reward.id}_${index}`}
                            reward={reward}
                            inventoryCount={state.inventory?.storage?.[reward.id]}
                            bundleId={+state.currentPage?.bundleId}
                            isAvailablePortPreview={isAvailablePortPreview}
                            lootboxId={lootbox.id}
                        />
                    );
                })
            ) : (
                <GroupedCommonRewardsLayout lootboxId={lootbox.id} rewards={rewards} openPopup={openPopup} />
            )}
            {hasFloatChance && <div className={classesAboutMathRules}>{t('Вероятность получения награды округлена с точностью до сотых по стандартным математическим правилам.')}</div>}
        </div>
    );
};

export default CommonRewardsLayout;
