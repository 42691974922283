import * as React from 'react';
import styles from './Accordion.scss';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import Button from '~/components/Button/Button';
import { PreCacheImage } from '~/utils/preCacheImage';
import { BannerUtils } from '~/utils/banner';
import { isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import { ACCORDION_SHOW_INTERVAL } from '~/Layouts/Banners/Accordion/Accordion';
import equal from 'fast-deep-equal/react';
import HoverVideo from '~/customization/HoverVideo/HoverVideo';
import { BANNER_LINK_TYPE, IBannerButtonVisibility } from '~/types/contents';
import { playCardClickSound } from '~/api/WoWsClient';
import { RootState, useAppSelector } from '~/Store';
import useResourceLoader from '~/hooks/useResourceLoader';
import { getSupportedVideo } from '~/utils/video';
import { isSafariBrowser } from '~/utils/settings';

type AccordionBannerType = {
    isSelected: boolean;
    banner: IAccordionBanner;
    onHover: () => void;
    onMouseLeave?: () => void;
};

const stateSelector = (state: RootState) => {
    return {
        categories: state.app.categories,
        isTrusted: state.app.isTrusted,
    };
};

const AccordionBanner = ({ isSelected, banner, onHover, onMouseLeave }: AccordionBannerType) => {
    const state = useAppSelector(stateSelector, equal);
    const ref = React.useRef<HTMLDivElement>(null);
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const videoUrl = getSupportedVideo(banner.videoBackground);

    const isDisabledBlobUrl = Boolean((isInGameBrowser && !!banner.videoBackground?.clientVideoName) || (isSafariBrowser && videoUrl));
    const [isFinishedLoading, resources] = useResourceLoader(videoUrl ? [videoUrl] : [], isDisabledBlobUrl, !videoUrl);

    let category;
    if (BANNER_LINK_TYPE.CATEGORY === banner.linkType) {
        category = state.categories[banner.linkValue];
    }

    const classesItem = classNames(styles.item, {
        [styles.selected]: isSelected,
        [category?.theme]: !!category?.theme,
    });

    const classesVideoWrapper = classNames(styles.videoBackground, {
        [styles.visible]: isSelected && isFinishedLoading,
    });

    const imagePosition = banner.smallImagePosition || banner.smallImagePosition == 0 ? `0 ${banner.smallImagePosition}%` : 'center';
    const backgroundStyle = {
        '--small-image': `url(${banner.smallImage})`,
        '--big-image': `url(${banner.image})`,
        backgroundImage: `var(--big-image, ${banner.image})`,
        backgroundPosition: imagePosition,
    };

    React.useEffect(() => {
        if (!isSelected && banner.smallImage && !isMobileOrTabletWindow) {
            ref.current.style.backgroundPosition = '0 0';
            ref.current.style.backgroundImage = `url(${banner.smallImage})`;
        }

        new PreCacheImage().add(banner.image);
    }, []);

    const classesFooter = classNames(styles.footer, {
        [styles.hover]: banner.buttonVisibility === IBannerButtonVisibility.HOVER,
    });

    const classesContent = classNames(styles.content, {
        [styles.hasDescription]: !!banner.description,
    });

    const handleClick = () => BannerUtils.redirectTo(banner);

    const interval = React.useRef(null);

    const mouseEnter = () => {
        if (!ref.current || !banner.smallImage) {
            onHover?.();
            return;
        }

        if (isMobileOrTabletWindow) {
            return;
        }

        interval.current = setTimeout(() => {
            ref.current.style.backgroundImage = `url(${banner.image})`;
        }, ACCORDION_SHOW_INTERVAL);

        onHover?.();
    };

    const mouseLeave = () => {
        onMouseLeave?.();
        if (!ref.current || !banner.smallImage) {
            return;
        }

        clearInterval(interval.current);
    };

    React.useEffect(() => {
        if (isMobileOrTabletWindow) {
            return;
        }

        if (banner.videoBackground && videoRef.current) {
            if (isSelected) {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }

        if (!isSelected && banner.smallImage) {
            setTimeout(() => {
                ref.current.style.backgroundPosition = '0 0';
                ref.current.style.backgroundImage = `url(${banner.smallImage})`;
            }, ACCORDION_SHOW_INTERVAL);
        }
    }, [isSelected]);

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            ref.current = _ref;
        }
    };

    return (
        <div
            className={classesItem}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            onClick={() => {
                playCardClickSound();
                handleClick();
            }}
            data-category-name={banner.categoryName}
            data-link-type={banner.linkType}
            data-link-value={banner.linkValue}
        >
            <HoverVideo video={banner.videoHover} delay={ACCORDION_SHOW_INTERVAL + 50} muted={!state.isTrusted} parentClassName={styles.item}>
                <div className={styles.image} style={backgroundStyle} ref={setRef} />
                {banner.videoBackground && !isMobileOrTabletWindow && (
                    <div className={classesVideoWrapper}>
                        <video
                            className={styles.video}
                            muted={true}
                            loop={true}
                            controls={false}
                            autoPlay={isSelected}
                            preload={'metadata'}
                            src={resources[videoUrl]}
                            ref={(_ref) => {
                                if (_ref) {
                                    videoRef.current = _ref;
                                }
                            }}
                        />
                    </div>
                )}
                <div className={classesContent}>
                    <div className={styles.header}>
                        <div className={styles.title}>{banner.title}</div>
                        {!!banner.description && <div className={styles.description}>{banner.description}</div>}
                    </div>
                    {banner.buttonVisibility !== IBannerButtonVisibility.HIDDEN && (
                        <div className={classesFooter}>
                            <Button
                                label={banner.buttonTitle ?? t('Перейти')}
                                onClick={(event: React.MouseEvent) => {
                                    event.stopPropagation();
                                    handleClick();
                                }}
                                linkTarget={banner.linkType === BANNER_LINK_TYPE.EXTERNAL}
                            />
                        </div>
                    )}
                </div>
            </HoverVideo>
        </div>
    );
};

export default AccordionBanner;
