export enum CustomEventNames {
    closedPurchaseSuccessPopup = 'purchase:success:close_popup',
    purchaseSuccess = 'purchase:success',
}

export type ClosedPurchaseSuccessPayload = { bundle: IBundle; quantity: number };
export type PurchaseSuccessPayload = never;

type EventsPayload = {
    [CustomEventNames.closedPurchaseSuccessPopup]: ClosedPurchaseSuccessPayload;
    [CustomEventNames.purchaseSuccess]: PurchaseSuccessPayload;
};

export type EventEmitterCallback<T extends CustomEventNames> = (payload: EventsPayload[T]) => void;

class EventEmitter extends EventTarget {
    private events = new Map<CustomEventNames, EventEmitterCallback<CustomEventNames>[]>();

    public on<T extends CustomEventNames>(name: T, callback: EventEmitterCallback<T>) {
        if (!this.events.has(name)) {
            this.events.set(name, []);
        }

        if (this.events.get(name).includes(callback)) {
            return;
        }

        this.events.get(name).push(callback);
    }

    public emit<T extends CustomEventNames>(name: T, payload?: EventsPayload[T]) {
        if (!this.events.has(name)) {
            return;
        }
        this.events.get(name).forEach((callback) => {
            callback(payload);
        });
    }

    public off<T extends CustomEventNames>(name: T, callback: EventEmitterCallback<T>) {
        if (!this.events.has(name)) {
            return;
        }

        const handlers = this.events.get(name);
        const filtered = handlers.filter((func) => func !== callback);

        this.events.set(name, filtered);
    }
}

const events = new EventEmitter();

export default events;
