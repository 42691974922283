import * as React from 'react';
import styles from './PurchaseBlock.scss';
import { interpolate, t } from '~/utils/localization';
import PurchaseMethod from '~/components/WSMart/PurchaseBlock/PurchaseMethod';
import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import WSMartPurchaseProcessor from '~/processors/WSMartPurchaseProcessor';
import { WoWsCommerceStorefrontNames } from '~/core/WowsCommerce/WoWsCommerceHelper';
import { POPUPS_NAME } from '~/components/PopupManager';
import { DWH_EVENTS as WOWS_COMMERCE_DWH_EVENTS } from '@wg/wows-commerce/constants/dwhEvents';
import classNames from 'classnames';
import useDelegationClickForLinkHandler from '~/hooks/useDelegationClickForLinkHandler';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { localizedDate } from '~/utils/utils';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

const stateSelector = (state: RootState) => {
    return {
        paymentMethod: state.wsmart.paymentMethod,
        currentProductId: state.wsmart.currentProductId,
        fastGoldProductWrappers: state.wsmart.fastGoldProducts,
        billingAddressSettings: state.wsmart.billingAddressSettings,
    };
};

const PurchaseBlock = () => {
    const state = useAppSelector(stateSelector, equal);
    const managementUrlRef = React.useRef<HTMLAnchorElement>();
    const dispatch = useDispatch();
    const billingAddress = state.billingAddressSettings;
    const selectedProduct = state.fastGoldProductWrappers.find((productWrapper) => productWrapper.product.id === state.currentProductId)?.product;

    const wsmartPurchaseProcessor = new WSMartPurchaseProcessor(selectedProduct, WoWsCommerceStorefrontNames.FASTGOLD);

    const changePaymentMethod = async (event?: React.MouseEvent) => {
        await dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.WSMART_DOUBLOONS_POPUP }));
        event?.stopPropagation();
        await wsmartPurchaseProcessor.buy(WOWS_COMMERCE_DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_BUNDLE, selectedProduct?.quantity);
    };

    useDelegationClickForLinkHandler(managementUrlRef);

    const mandatoryDate = billingAddress?.mandatoryDate && new Date(billingAddress.mandatoryDate);
    let mandatoryDateText = null;
    const isTimeUp = mandatoryDate && !billingAddress.address && mandatoryDate.getTime() < Date.now();
    if (mandatoryDate) {
        mandatoryDateText = interpolate(t('До {date} вам необходимо указать свой адрес.'), { date: localizedDate(mandatoryDate) });
        if (isTimeUp) {
            mandatoryDateText = t('Для совершения покупки, вам необходимо указать свой адрес.');
        }
    }

    const billingAddressText =
        !!billingAddress?.address && `${billingAddress.address.streetAddress}, ${billingAddress.address.city}, ${billingAddress.address.postalCode}, ${billingAddress.address.country}`;

    return (
        <div className={styles.purchaseWrapper}>
            <div className={styles.purchaseMethods}>
                {state.paymentMethod && (
                    <>
                        <div className={styles.purchaseTitle}>{t('Способ оплаты')}</div>
                        <div className={styles.purchaseMethod}>
                            <PurchaseMethod paymentMethod={state.paymentMethod} />
                            <div className={styles.purchaseMethodChange} onClick={changePaymentMethod}>
                                {t('Оплатить другим методом')}
                            </div>
                        </div>
                    </>
                )}
            </div>
            {billingAddress?.applicable && state.paymentMethod && (
                <div
                    className={styles.purchaseBilling}
                    ref={(_ref) => {
                        RefManager.setRef(RefManagerKeys.BillingAddress, _ref);
                    }}
                >
                    <div className={styles.billingTitle}>{t('Billing address')}</div>
                    <div className={styles.billingAddress}>
                        <span
                            className={classNames(styles.address, {
                                [styles.isTimeUp]: !billingAddress.address && isTimeUp,
                            })}
                        >
                            {billingAddress.address ? billingAddressText : mandatoryDateText}
                        </span>
                        <a className={classNames('external-link', styles.billingAddressLink)} target="_blank" href={state.billingAddressSettings.managementUrl} ref={managementUrlRef}>
                            {billingAddress.address ? t('Edit billing address') : t('Добавить адрес для выставления счёта')}
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PurchaseBlock;
