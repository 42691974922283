import * as React from 'react';
import { useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import styles from './AutoDescription.scss';
import { t } from '~/utils/localization';
import classNames from 'classnames';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import SearchInput from '~/components/SearchInput/SearchInput';
import { flatRewards } from '~/Layouts/Lootboxes/utils';
import { isEmptyObject } from '~/utils/utils';
import { onSearch, scrollToGroup } from '~/components/Popups/AutoDescription/utils';
import AutoDescriptionDefaultItem from '~/components/Popups/AutoDescription/AutoDescriptionDefaultItem';
import LootboxRewardItemCard from '~/components/LootboxRewardItemCard/LootboxRewardItemCard';
import Button from '~/components/Button/Button';
import { playTabClickSound } from '~/api/WoWsClient';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { Processing } from '@wg/wows-react-uikit';
import { RootState, useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';
import { POPUPS_NAME } from '~/components/PopupManager';

const stateSelector = (state: RootState) => {
    return {
        lootboxes: state.lootbox.lootboxes,
        inventory: state.account.inventory,
        currentPage: state.app.currentPage,
    };
};

export const AutoDescriptionPopup = () => {
    const { popup } = React.useContext(PopupManagerContext);
    const dispatch = useDispatch();
    const ref = React.useRef<HTMLDivElement>(null);
    const state = useAppSelector(stateSelector, equal);
    const { containerId, groupName, slotIndex, isCommonRewards, autoDescriptionSettings } = popup?.data || {};
    const container = state.lootboxes?.[containerId];
    const itemsRewards = isCommonRewards ? container.slots[slotIndex]?.commonRewards : container.slots[slotIndex]?.valuableRewards;
    const [searchResults, setSearchResults] = React.useState<ILootboxStateRewards>(itemsRewards);
    const result = flatRewards(searchResults);
    const groupsKeys = Object.keys(searchResults);
    const isEmptyResult = !result.length;
    const isNeedToRenderGroupTabs = groupsKeys.length > 1;
    const renderCount = React.useRef(0);
    const totalItemsCount = Object.values(searchResults).reduce((count, group) => count + (group.rewards?.length || 0), 0);
    const [isLoaded, setLoadedState] = React.useState(false);
    const timerRef = React.useRef<ReturnType<typeof setTimeout>>();

    const close = () => {
        dispatch(appActions.changeVisibilityPopup({ name: POPUPS_NAME.AUTO_DESCRIPTION_POPUP }));
    };

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            ref.current = _ref;
        }
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    useMaskScrollEffect(ref);

    const _onSearch = (value: string) => {
        if (!value.trim().length) {
            setSearchResults(itemsRewards);
            return;
        }
        setSearchResults(onSearch(value, itemsRewards));
    };

    const classesItems = classNames(styles.items, {
        [styles.grid]: !isCommonRewards,
        [styles.commonItems]: isCommonRewards,
        [styles.isLoaded]: isLoaded,
    });

    function checkLoader() {
        if (isLoaded || renderCount.current < totalItemsCount) {
            return;
        }
        timerRef.current = setTimeout(() => {
            setLoadedState(true);
            groupName && scrollToGroup(groupName, 'auto');
        }, 150);
    }

    return (
        <Popup onClose={close} outsideClick={true} renderEscButton={true} closeIconClassName={styles.popupCloseIcon} className={styles.popupWrapper}>
            <PopupHeader title={t('Содержимое контейнера')} />
            <PopupBody className={styles.body}>
                <div className={styles.content}>
                    {!isCommonRewards && (
                        <div className={styles.topPanel}>
                            <div className={styles.searchTab}>
                                <SearchInput
                                    onReset={() => {
                                        _onSearch('');
                                    }}
                                    onInput={(event) => {
                                        _onSearch((event.target as HTMLInputElement).value);
                                    }}
                                />
                            </div>
                            {isNeedToRenderGroupTabs && (
                                <div className={styles.groupsTab}>
                                    {groupsKeys.map((groupKey) => {
                                        return (
                                            <span
                                                key={`group_link_${groupKey}`}
                                                className={styles.group}
                                                data-category-link={`lootbox_reward_group_${groupKey}`}
                                                onClick={() => {
                                                    playTabClickSound();
                                                    !isEmptyResult && scrollToGroup(groupKey);
                                                }}
                                            >
                                                {searchResults[groupKey].shortTitle}
                                            </span>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                    <div className={classesItems} ref={setRef}>
                        {!isEmptyResult &&
                            groupsKeys?.map((groupKey, index) => {
                                const rewards = searchResults[groupKey];
                                if (isEmptyObject(rewards) || !rewards.rewards?.length) {
                                    return null;
                                }
                                const title = searchResults[groupKey].title;

                                return (
                                    <div key={`group_${groupKey}_${index}`}>
                                        {!isCommonRewards && isNeedToRenderGroupTabs && (
                                            <div className={styles.groupTitle} data-category-title={`lootbox_reward_group_${groupKey}`}>
                                                {title}
                                            </div>
                                        )}
                                        {rewards.rewards.map((reward, index) => {
                                            const Component = isCommonRewards ? AutoDescriptionDefaultItem : LootboxRewardItemCard;
                                            const isUniqueGroup = !isCommonRewards ? container.slots[slotIndex]?.valuableRewards?.[groupKey]?.hasUniqueRewards : false;

                                            return (
                                                <Component
                                                    key={`auto_description_content_item_${groupKey}_${index}_${reward.id}`}
                                                    className={styles.gridItem}
                                                    reward={reward}
                                                    inventory={state.inventory}
                                                    isUniqueGroup={isUniqueGroup}
                                                    bundleId={+state.currentPage?.bundleId}
                                                    goToPortHandler={close}
                                                    lootboxId={containerId}
                                                    autoDescriptionSettings={autoDescriptionSettings}
                                                    onRender={() => {
                                                        renderCount.current++;
                                                        checkLoader();
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        {isEmptyResult && <div className={styles.notFound}>{t('Ничего не найдено')}</div>}
                    </div>
                </div>
                {!isLoaded && <Processing isFetching={!isLoaded} />}
            </PopupBody>
            <PopupFooter className={styles.popupFooter}>
                <Button label={t('закрыть')} onClick={close} />
            </PopupFooter>
        </Popup>
    );
};

export default AutoDescriptionPopup;
