import loadMercatorContent from '~/api/mercator';
import AbstractPreloader from './AbstractPreloader';
import store from '~/Store';
import { GachaBundleProcessor } from '~/Layouts/GachaBanner/api/processor';
import Account from '~/account/Account';
import { gachaActions } from '~/Store/gachaSlice';
import { isEmptyObject } from '~/utils/utils';

export default class MercatorPreloader extends AbstractPreloader {
    async load(): Promise<any> {
        loadMercatorContent().then((response) => {
            if (response === 1 || !response) {
                return;
            }

            if (!isEmptyObject(response.mystery || {})) {
                store.dispatch(gachaActions.initGachaBanners(response.mystery));
                if (Account.getAccount()?.id) {
                    GachaBundleProcessor.accountStateSync();
                }
            }
        });
        return Promise.resolve(1);
    }
}
