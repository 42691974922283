import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { isMobileOrTabletWindow } from '~/utils/utils';
import styles from '~/components/View/View.scss';
import TopPanel from '~/components/TopPanel/TopPanel';
import CategoryTimer from '~/components/CategoryTimer/CategoryTimer';
import SubCategoryMenu from '~/components/SubCategoryMenu/SubCategoryMenu';
import Filter from '~/components/CategoryFilter/CategoryFilter';
import RefManager, { RefManagerKeys } from '~/RefManager';
import SortDisplay from '~/components/SortDisplay/SortDisplay';
import FilterPresets from '~/components/CategoryFilter/FilterPresets';
import { isAvailablePresetsForCategory, isNeedToRenderFilter } from '~/utils/filters';
import classNames from 'classnames';
import { isAvailableSortCategory } from '~/utils/sort/settings';
import { isDockyardCategory } from '~/utils/category';
import { CATEGORIES } from '~/const';
import { CategoryType, FilterPosition } from '~/types/category';
import ActiveCategoryFilters from '../CategoryFilter/ActiveCategoryFilters';
import TradeInSubCategories from '~/Layouts/TradeIn/components/TradeInSubCategories/TradeInSubCategories';
import { isTradeInCategory } from '~/utils/tradein';
import { RootState, useAppSelector } from '~/Store';
import { viewStateSelector } from '~/containers/ViewContainer/ViewContainer';
import { isValidDate } from '~/utils/time';
import { useDispatch } from 'react-redux';
import { appActions } from '~/Store/appSlice';
import { t } from '~/utils/localization';

interface IViewTopPanel extends ReturnType<typeof viewStateSelector> {
    onChangeFilterVisible: (visible: boolean) => void;
    currentPage: ICurrentPage;
    port: {
        isVisible: boolean;
        shipId?: number;
        exteriorId?: number;
    };
}

const stateSelector = (state: RootState) => {
    return {
        categories: state.app.categories,
        filterPosition: state.app.filterPosition,
        isBlurView: state.app.isBlurView,
        port: state.app.port,
        disabledCategories: state.app.disabledCategories,
    };
};

interface IFiltersPanel {
    isNeedToRenderFilters: boolean;
    category: ICategory;
    onChangeFilterVisible: (visible: boolean) => void;
    filterPosition: FilterPosition;
}

const FilterPanel = ({ isNeedToRenderFilters, category, onChangeFilterVisible, filterPosition }: IFiltersPanel) => {
    const _isAvailablePresetsForCategory = isAvailablePresetsForCategory(category.name);
    const isAvailableFilters = isNeedToRenderFilters && filterPosition === FilterPosition.TOP;
    const isAvailableSort = !isTradeInCategory(category) && isAvailableSortCategory(category.name);

    if (category.promoTimer && isValidDate(category.promoTimer.timerActiveTill)) {
        return null;
    }

    if (!isAvailableSort && !isAvailableFilters && !_isAvailablePresetsForCategory) {
        return null;
    }

    return (
        <div className={styles.layoutHeader}>
            <div className={styles.layoutHeaderLine}>
                <div className={styles.filtersWrapper}>
                    {isAvailableFilters && (
                        <div
                            className={classNames(styles.filterDelimiter, {
                                [styles.border]: _isAvailablePresetsForCategory,
                            })}
                        >
                            <Filter category={category.name} onChangeVisible={onChangeFilterVisible} />
                        </div>
                    )}
                    {!_isAvailablePresetsForCategory && <ActiveCategoryFilters category={category.name} className={styles.activeFiltersTop} />}
                    {_isAvailablePresetsForCategory && <FilterPresets category={category.name} />}
                </div>
                {isAvailableSort && <SortDisplay category={category.name} className={styles.sortDisplay} />}
            </div>
        </div>
    );
};

const ViewTopPanel = React.forwardRef((props: IViewTopPanel, ref: React.RefObject<HTMLDivElement>) => {
    const dispatch = useDispatch();
    const state = useAppSelector(stateSelector, equal);
    const category = state.categories[props.currentPage?.name];
    const isNeedToRenderFilters = isNeedToRenderFilter(category?.name as ICategoryList);
    const hasPromoTimer = category?.promoTimer?.timerActiveTill && isValidDate(category.promoTimer.timerActiveTill) && category.promoTimer?.showBundles;
    const hasCategoryTimer = Boolean(category?.showCountdown && category?.activeTill);
    const isNeedToRenderTimer = (hasPromoTimer || hasCategoryTimer) && !state.disabledCategories?.includes(category?.name) && !category?.useSmallTimer;

    if (!category) {
        return null;
    }

    if (props.currentPage?.name === CATEGORIES.MENU) {
        return null;
    }

    if (props.currentPage?.name === CATEGORIES.DOCKYARD && isMobileOrTabletWindow) {
        return null;
    }

    if (props.port.isVisible) {
        return null;
    }

    const isNeedToShowCategoryTimer = !props.currentPage?.isBundlePage && !state.port?.isVisible && isNeedToRenderTimer;

    const setRef = (ref: HTMLDivElement) => {
        if (ref) {
            RefManager.setRef(RefManagerKeys.TopPanel, ref);
        }
    };

    const categoryName = category?.name as ICategoryList;
    const _isAvailablePresetsForCategory = isAvailablePresetsForCategory(categoryName);
    const isAvailableSort = isAvailableSortCategory(categoryName);
    const isNeedToShowActiveFilters = ((state.filterPosition === FilterPosition.RIGHT || _isAvailablePresetsForCategory) && !isAvailableSort) || (_isAvailablePresetsForCategory && isAvailableSort);

    const topPanelClassNames = classNames(styles.topPanel, styles[category?.type], {
        [styles.blurElements]: isDockyardCategory() && state.isBlurView,
    });

    const onCompleteTimer = () => {
        if (hasCategoryTimer) {
            dispatch(appActions.disableCategory(categoryName));
        } else if (hasPromoTimer) {
            location.reload();
        }
    };

    return (
        <React.Fragment>
            <div className={topPanelClassNames} ref={setRef}>
                <TopPanel />
                {category?.type === CategoryType.TRADEIN ? <TradeInSubCategories category={category} /> : <SubCategoryMenu category={props.currentPage?.name} />}
                {!props.currentPage?.isBundlePage && category && (
                    <FilterPanel filterPosition={state.filterPosition} category={category} isNeedToRenderFilters={isNeedToRenderFilters} onChangeFilterVisible={props.onChangeFilterVisible} />
                )}
                {!props.currentPage?.isBundlePage && isNeedToShowActiveFilters && <ActiveCategoryFilters category={categoryName} />}
            </div>
            {isNeedToShowCategoryTimer && (
                <CategoryTimer category={category} title={hasPromoTimer ? category.promoTimer.timerTitle || t('Starts in:') : null} key={categoryName} onComplete={onCompleteTimer} />
            )}
        </React.Fragment>
    );
});

export default ViewTopPanel;
