import styles from './MobilePurchaseWrapper.scss';
import classNames from 'classnames';
import { shallowEqual } from 'react-redux';
import Account from '~/account/Account';
import { PurchasedItem } from '~/components/Purchased/Purchased';
import PriceWrapper from '~/components/PriceWrapper/PriceWrapper';
import { t } from '~/utils/localization';
import Button from '~/components/Button/Button';
import { getRandomBundleChild, getSerialBundlesById, isEnableOpenBundle, isFreeBundle, isPeriodicBundle } from '~/utils/bundles';
import { openBundleById, openBundleByUrl } from '~/utils/category';
import PurchaseButton, { PurchaseButtonTheme } from '~/components/Button/PurchaseButton';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import { BundlePurchaseTypes } from '~/types/bundle';
import Attention from '../Attention/Attention';
import { getLevelingRestrictionForBundle, getLevelingRestrictionTextForBundle } from '~/utils/levelingResctrictions';
import RealPurchaseButton from '../Button/RealPurchaseButton';
import { isSantaBundle } from '~/Layouts/Themes/SantaPage/settings';
import { RootState, useAppSelector } from '~/Store';

interface IMobilePurchaseWrapper {
    bundle: IBundle;
}

const stateSelector = (state: RootState) => {
    return {
        selectedRandomBundles: state.account.selectedRandomBundles,
        currentPage: state.app.currentPage,
        bundles: state.app.bundles,
        activeCouponId: state.account.activeCouponId,
        coupons: state.account.coupons,
        purchasedLimitedBundles: state.account.purchasedLimitedBundles,
        id: state.account.id,
    };
};

const MobilePurchaseWrapper = (props: IMobilePurchaseWrapper) => {
    const state = useAppSelector(stateSelector, shallowEqual);
    const activeCoupon = Account.getActiveCoupon(state.activeCouponId, state.coupons);

    if (props.bundle.isPurchased) {
        return (
            <div className={styles.purchaseMobileWrapper} id="MobilePurchaseWrapper">
                <PurchasedItem bundle={props.bundle} className={styles.background} />
            </div>
        );
    }

    const _isDisabledSerialBundle = Account.isDisabledSerialBundle(state.purchasedLimitedBundles, props.bundle);

    if ((props.bundle.serialPurchase && _isDisabledSerialBundle) || (props.bundle.serialPurchase && !state.id && !props.bundle.serialSequence?.length)) {
        const serialSequence = getSerialBundlesById(state.bundles, props.bundle.id).map((bundle) => bundle.id);
        const nextAvailableFromPurchaseSerialBundle = Account.getNextAvailableFromPurchaseSerialBundle(state.purchasedLimitedBundles, serialSequence);
        const openAvailableBundle = () => {
            if (nextAvailableFromPurchaseSerialBundle) {
                openBundleByUrl(state.currentPage?.name, nextAvailableFromPurchaseSerialBundle);
            } else if (serialSequence?.[0]) {
                openBundleById(serialSequence?.[0]);
            }
        };

        return (
            <div className={styles.purchaseMobileWrapper} id="MobilePurchaseWrapper">
                <div className={classNames(styles.wrapper)}>
                    <div className={styles.priceWrapperTitle}>{t('Цена:')}</div>
                    <PriceWrapper bundle={props.bundle} />
                    <Button label={t('Перейти к доступному для приобретения')} className={styles.buttonFullWidth} onClick={openAvailableBundle} isFull />
                </div>
            </div>
        );
    }

    const openPurchasePopup = () => {
        const bundle = state.bundles[props.bundle.id];
        const purchaseProcessor = new PurchaseProcessor(bundle);

        if (isFreeBundle(bundle)) {
            const childBundle = getRandomBundleChild(state.selectedRandomBundles, bundle);
            if (bundle.isRandom) {
                purchaseProcessor.purchaseRandomBundle(childBundle, null, null, true);
            } else {
                purchaseProcessor.purchase();
            }
            return;
        }

        purchaseProcessor.showPopup();
    };

    if (isPeriodicBundle(props.bundle) && !isEnableOpenBundle(props.bundle) && !state.selectedRandomBundles[props.bundle.id]) {
        return null;
    }

    const levelingResctrictions = getLevelingRestrictionForBundle(props.bundle);

    const _isSantaBundle = isSantaBundle(props.bundle);

    return (
        <div className={styles.purchaseMobileWrapper} id="MobilePurchaseWrapper">
            <div className={styles.wrapper}>
                {!!levelingResctrictions?.length && (
                    <div className={styles.restrictionText}>
                        <Attention label={getLevelingRestrictionTextForBundle(props.bundle, levelingResctrictions)} level="info" isInline />
                    </div>
                )}
                <div className={styles.purchaseWrapperRow}>
                    <div className={styles.purchaseColumn}>
                        <div className={styles.priceWrapperTitle}>{t('Цена:')}</div>
                        <div className={styles.priceWrapper}>
                            <PriceWrapper
                                wrapperClassName={classNames({
                                    [styles.realPurchaseWrapper]: props.bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY,
                                })}
                                bundle={props.bundle}
                                coupon={activeCoupon}
                                discountClassName={styles.discountWrapper}
                                newPriceClassName={styles.newPrice}
                                oldPriceClassName={styles.oldPrice}
                                withoutTimer={true}
                            />
                        </div>
                    </div>
                    <div className={styles.purchaseColumn}>
                        <div className={styles.purchaseButton}>
                            {props.bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY ? (
                                <RealPurchaseButton
                                    bundle={props.bundle}
                                    buttonClassName={'armory__auto--bundle_button_real_purchase'}
                                    purchaseButtonTheme={_isSantaBundle && PurchaseButtonTheme.cookie}
                                />
                            ) : (
                                <PurchaseButton
                                    bundle={props.bundle}
                                    onClick={openPurchasePopup}
                                    isForceRenderPurchaseButton
                                    buttonClassName={'armory__auto--bundle_button_purchase'}
                                    purchaseButtonTheme={_isSantaBundle && PurchaseButtonTheme.cookie}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobilePurchaseWrapper;
