import { GachaBannerGroupRarity, IGachaBannerReward, IGachaRollError, IGachaRollSuccess, TransactionStatus } from '~/types/gacha';
import { getAccountState, getTransaction, roll } from '.';
import store from '~/Store';
import { idleTracker, syncAccountInfo } from '~/sync';
import { logError } from '~/utils/logging';
import { POPUPS_NAME } from '~/components/PopupManager';
import ErrorPopup from '../Components/ErrorPopup/ErrorPopup';
import { getAnimationNameByRariry, runRollSpineAnimationByName } from '../utils/animationsState';
import { isEmptyObject } from '~/utils/utils';
import { getSupportedVideo } from '~/utils/video';
import { getRecivedRewardsByNames } from '../utils/rewards';
import preCacheImage from '~/utils/preCacheImage';
import { gachaActions } from '~/Store/gachaSlice';
import { appActions } from '~/Store/appSlice';
import { IRewardItem } from '~/components/Popups/RewardsPopup/Reward/Reward';
import { IRewardPopupData } from '~/components/Popups/RewardsPopup/RewardsPopup';
import { sort } from 'fast-sort';

class Processor {
    private TRANSACTIONS_SYNC_INTERVAL = 1;
    private ACCOUNT_STATE_SYNC_INTERVAL = 10;

    private successHandlers: Record<string, DefaultFunction> = {};

    private async waitForSeconds(seconds: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, seconds * 1000);
        });
    }

    private showError(bundleName?: string) {
        if (bundleName) {
            store.dispatch(gachaActions.updateTransactions({ bundleName, transactionId: null }));
        }
        store.dispatch(
            appActions.changeVisibilityPopup({
                name: POPUPS_NAME.CUSTOM_COMPONENT,
                isVisible: true,
                data: { component: <ErrorPopup /> },
            }),
        );
    }

    public async roll(bundleName: string, quantity: number, onSuccess?: DefaultFunction) {
        try {
            store.dispatch(gachaActions.updateTransactions({ bundleName, transactionId: bundleName }));
            const response = await roll(bundleName, quantity);
            store.dispatch(gachaActions.updateTransactions({ bundleName, transactionId: response.transactionId }));
            if (onSuccess) {
                this.successHandlers[bundleName] = onSuccess;
            }
            if (response?.transactionId) {
                this.getTransaction(response.transactionId);
            } else {
                this.showError(bundleName);
            }
        } catch (e) {
            this.showError(bundleName);
        }
    }

    private async updateAccountState() {
        const accountState = store.getState().gacha;
        const response = await getAccountState();
        if (!response || accountState?.ts > response.ts) {
            return;
        }

        store.dispatch(gachaActions.updateAccountState(response));
    }

    public async accountStateSync() {
        try {
            if (idleTracker.isUserActive()) {
                await this.updateAccountState();
            }
        } catch (e) {
            logError('[mystery] account sync error', e);
        }
        await this.waitForSeconds(this.ACCOUNT_STATE_SYNC_INTERVAL);
        this.accountStateSync();
    }

    private prepareReward(reward: IGachaBannerReward) {
        return {
            images: reward.images,
            items: reward.items.map((item) => {
                return {
                    amount: item.amount,
                    customisation: item.customisation,
                    isUnique: item.isUnique,
                    isPrimary: item.isPrimary,
                    shipId: item.customisation?.shipId,
                    type: item.type,
                    compensation: item.compensation,
                    identifier: item.id,
                };
            }) as IItemCommonData[],
            name: reward.name,
            title: reward.title,
            backgroundColor: reward.backgroundColor,
            cashback: reward.cashback,
            isShiny: reward.rarity === GachaBannerGroupRarity.legendary || reward.rarity === GachaBannerGroupRarity.valuable,
        };
    }

    private async onSuccessHandler(response: IGachaRollSuccess) {
        await this.updateAccountState();
        await syncAccountInfo();

        const { banners } = store.getState().gacha;
        const currentBanner = banners[response.bundleName];
        let receivedRewards = getRecivedRewardsByNames(response.rewards, currentBanner);

        const valuableReward = sort([...receivedRewards])
            .desc((reward) => {
                if (reward.rarity === GachaBannerGroupRarity.legendary) {
                    return 2;
                } else if (reward.rarity === GachaBannerGroupRarity.valuable) {
                    return 1;
                } else {
                    return 0;
                }
            })
            .find((reward) => {
                return reward.rarity === GachaBannerGroupRarity.legendary || reward.rarity === GachaBannerGroupRarity.valuable;
            });

        const animationName = getAnimationNameByRariry(valuableReward?.rarity);
        let introVideo: Nullable<IVideo> = null;
        if (valuableReward && !isEmptyObject(valuableReward.introVideo)) {
            introVideo = valuableReward.introVideo;
        }

        if (valuableReward) {
            receivedRewards = receivedRewards.filter((reward) => reward.name !== valuableReward?.name);
            valuableReward?.images?.big && preCacheImage.add(valuableReward.images.big);
        }

        const rewardsItems: IRewardItem[] = receivedRewards.map((reward) => this.prepareReward(reward));

        store.dispatch(gachaActions.updateTransactions({ bundleName: response.bundleName, transactionId: null }));

        const showPopup = () => {
            store.dispatch(
                appActions.changeVisibilityPopup({
                    name: POPUPS_NAME.REWARDS,
                    isVisible: true,
                    data: {
                        rewards: rewardsItems,
                        promotionReward: valuableReward ? this.prepareReward(valuableReward) : null,
                        onCloseHandler: this.successHandlers[response.bundleName],
                        compensatedItemIds: response.compensatedItems,
                    } as IRewardPopupData,
                }),
            );
        };

        runRollSpineAnimationByName(animationName, response.bundleName, () => {
            if (introVideo?.mp4 && introVideo?.webm) {
                store.dispatch(
                    appActions.startVideo({
                        name: response.bundleName,
                        url: getSupportedVideo(introVideo),
                        onFinish: showPopup,
                    }),
                );
            } else {
                showPopup();
            }
        });
    }

    private async processingTransaction(response: IGachaRollSuccess | IGachaRollError) {
        switch (response.status) {
            case TransactionStatus.success: {
                await this.onSuccessHandler(response);
                break;
            }

            default:
            case TransactionStatus.error: {
                this.showError(response.bundleName);
                break;
            }
        }
    }

    public async getTransaction(transactionId: string) {
        try {
            const response = await getTransaction(transactionId);
            if (!response || response.status === TransactionStatus.pending) {
                await this.waitForSeconds(this.TRANSACTIONS_SYNC_INTERVAL);
                this.getTransaction(transactionId);
                return;
            }
            this.processingTransaction(response as IGachaRollSuccess | IGachaRollError);
        } catch (e) {
            this.showError();
        }
    }
}

export const GachaBundleProcessor = new Processor();
