import * as React from 'react';
import styles from '~/Layouts/Layouts.scss';
import classNames from 'classnames';
import Purchased from '~/components/Purchased/Purchased';
import { isAdmiralPack, isAvailablePreviewAmountOnBundleCard, isCommanderPack, isOnlyShipBundle } from '~/utils/bundles';
import { ngettext, t } from '~/utils/localization';
import { assets, items } from '@wg/wows-entities/const';
import { isEnabledPurchaseLimitToContainer } from '~/components/BundleLimitWidget/settings';
import BundleLimitContainer from '~/components/BundleLimitWidget/BundleLimitContainer';
import { CommanderName } from '~/components/Commanders/Commanders';
import Ship from '~/components/Ship/Ship';
import ShortBundleContains from '~/components/Bundle/Contains/ShortBundleContains';
import MainFeaturesLayout from '~/Layouts/Features/Views/MainFeaturesLayout/MainFeaturesLayout';
import equal from 'fast-deep-equal/react';
import QuantityWidget from '~/components/QuantityWidget/QuantityWidget';
import BundleType from '~/components/BundleType/BundleType';
import ModernizationShips from '~/components/ModernizationShips/ModernizationShips';
import { useAppSelector } from '~/Store';
import ProgressigonRewardsBonus from '~/components/ProgressigonRewardsBonus/ProgressigonRewardsBonus';
import { getProgressionRewardBonus } from '~/utils/progressionRewards';

const renderBundleTitle = (primaryItem: IBundleEntity, bundle: IBundle) => {
    if (bundle.denyTitleModification) {
        return <span dangerouslySetInnerHTML={{ __html: bundle.title }} className={styles.entityWrapper} />;
    }

    switch (primaryItem?.type) {
        case items.VEHICLES:
            return <Ship shipId={primaryItem.identifier} className={styles.entityWrapper} fallback={bundle.title} />;

        case items.CREWS:
            return <CommanderName id={primaryItem?.identifier} name={bundle.title} className={classNames(styles.entityWrapper, styles.crewIcon)} />;
    }

    let title = bundle.title;
    if (primaryItem?.isUnique && primaryItem?.type === items.MODERNIZATION) {
        title = t('Уникальная модернизация');
    }

    return <span dangerouslySetInnerHTML={{ __html: title }} className={styles.entityWrapper} />;
};

interface IBundleHeader {
    primaryItem: IBundleEntity;
    bundle: IBundle;
}

const BundleHeader = ({ primaryItem, bundle }: IBundleHeader) => {
    return (
        <div className={styles.itemHeaderTitle}>
            <div className={styles.itemHeaderContent}>
                <div className={styles.itemHeaderContentText}>{renderBundleTitle(primaryItem, bundle)}</div>
                {bundle.entitlements?.length === 1 && primaryItem && <BundleType item={primaryItem} size={'small'} />}
            </div>
            {primaryItem?.type && isAvailablePreviewAmountOnBundleCard(primaryItem?.type) && primaryItem.amount > 1 && (
                <div className={styles.primaryItemPerks}>
                    <QuantityWidget amount={primaryItem.amount} />
                </div>
            )}
            {isEnabledPurchaseLimitToContainer(bundle) && <BundleLimitContainer bundle={bundle} className={styles.bundleLimitWidget} />}
        </div>
    );
};

interface IBundleSubHeader {
    primaryItem: IBundleEntity;
}

const BundleSubHeader = ({ primaryItem }: IBundleSubHeader) => {
    return (
        <React.Fragment>
            {items.MODERNIZATION === primaryItem?.type && !!primaryItem?.customisation?.shipIds?.length && (
                <ModernizationShips className={styles.modernizationPerks} shipIds={primaryItem.customisation.shipIds} />
            )}
        </React.Fragment>
    );
};

interface IBundleContainsInfo extends IBundleHeader {
    isAvailableContainsWidget: boolean;
    containsTitleHidden: boolean;
    isAvailableShipFeatures: boolean;
}

const BundleContainsInfo = ({ isAvailableContainsWidget, containsTitleHidden, primaryItem, bundle, isAvailableShipFeatures }: IBundleContainsInfo) => {
    return (
        <React.Fragment>
            {isAvailableShipFeatures && (
                <div className={classNames(styles.itemsContent, styles.shipFeatures)}>
                    <MainFeaturesLayout shipId={bundle.primaryItem.identifier} lineClamp={true} />
                </div>
            )}
            {isAvailableContainsWidget && !containsTitleHidden && !isAvailableShipFeatures && (
                <div className={styles.itemsCount}>{ngettext('{count} в наборе', '{count} в наборе', bundle.entitlements.length).replace('{count}', bundle.entitlements.length.toString())}</div>
            )}
            {isAvailableContainsWidget && !isAvailableShipFeatures && (
                <ShortBundleContains bundle={bundle} className={styles.itemsContent} limit={2} key={`contains_${bundle.categories.join('_')}_${primaryItem?.identifier || bundle.id}`} />
            )}
        </React.Fragment>
    );
};

interface IBundleHeaderContent {
    bundle: IBundle;
    primaryItem: IBundleEntity;
}

const BundleHeaderContent = ({ bundle, primaryItem }: IBundleHeaderContent) => {
    const _commanderPack = isCommanderPack(bundle);
    const _isAdmiralPack = isAdmiralPack(bundle);
    const decorateTitle = _commanderPack ? t('Командирский набор') : _isAdmiralPack ? t('Адмиральский набор') : null;
    const isAvailableContainsWidget = bundle.entitlements?.length > 1;
    // hide contains title for simple ships bundles
    const containsTitleHidden = (bundle.entitlements || []).map((item: IBundleEntity) => item.type).toString() == [items.VEHICLES, items.DEFAULT_CREW, assets.SLOT].toString();
    const shipFeatureMap = useAppSelector((state) => state.features.shipFeatureMap, equal);
    const isAvailableShipFeatures = !!shipFeatureMap[bundle?.primaryItem?.identifier] && isOnlyShipBundle(bundle.entitlements);
    const progressionRewardBonus = getProgressionRewardBonus(bundle.entitlements);

    return (
        <div className={styles.itemHeader}>
            {!!decorateTitle && <div className={styles.decorateTitle}>{decorateTitle}</div>}
            <BundleHeader bundle={bundle} primaryItem={primaryItem} />
            <BundleSubHeader primaryItem={primaryItem} />
            {bundle.isPurchased && (
                <div
                    className={classNames(
                        {
                            [styles.purchasedWrapper]: !(isAvailableContainsWidget && !containsTitleHidden),
                        },
                        styles.purchasedBlock,
                    )}
                >
                    <Purchased bundle={bundle} />
                </div>
            )}
            {bundle.entitlements && (
                <BundleContainsInfo
                    bundle={bundle}
                    primaryItem={primaryItem}
                    containsTitleHidden={containsTitleHidden}
                    isAvailableContainsWidget={isAvailableContainsWidget}
                    isAvailableShipFeatures={isAvailableShipFeatures}
                />
            )}
            {bundle && bundle.descriptionBrief && <div className={styles.itemHeaderAbout} dangerouslySetInnerHTML={{ __html: bundle.descriptionBrief }} />}
            {!isAvailableShipFeatures && progressionRewardBonus && (
                <ProgressigonRewardsBonus
                    currency={progressionRewardBonus.type}
                    value={progressionRewardBonus.amount}
                    className={classNames(styles.progressionRewardsWrapper, {
                        [styles.hiddenOnHover]: isAvailableContainsWidget,
                    })}
                />
            )}
        </div>
    );
};

export default BundleHeaderContent;
