import classNames from 'classnames';
import equal from 'fast-deep-equal/react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Button, { ButtonVariant } from '~/components/Button/Button';
import { Popup, PopupHeader, PopupBody, PopupFooter } from '~/components/Popup/Popup';
import SerialItemsSmallCarouselItem from '~/components/SerialItemsSmallCarousel/SerialItemsSmallCarouselItem';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import { t } from '~/utils/localization';
import styles from './SerialSequencePurchase.scss';
import { IPopupSoundset } from '~/types/soundsets';
import { useAppSelector } from '~/Store';
import { appActions } from '~/Store/appSlice';

interface ISerialSequenceSucessPurchase {
    data: {
        bundleIds: number[];
    };
}

const AnimatedDiv = ({ children, index }: { children: React.ReactNode; index: number }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        ref.current.classList.add(styles.done);
    }, []);

    return (
        <div
            className={classNames(styles.animatedDiv)}
            style={{ transitionDelay: `0.${index}s` }}
            ref={(_ref) => {
                if (_ref) {
                    ref.current = _ref;
                }
            }}
        >
            {children}
        </div>
    );
};

const SerialSequenceSucessPurchase = ({ data }: ISerialSequenceSucessPurchase) => {
    const dispatch = useDispatch();
    const bundles = useAppSelector((state) => state.app.bundles, equal);
    const itemsRef = React.useRef<HTMLDivElement>(null);
    const { bundleIds } = data;
    const sequence = bundleIds.map((bundleId) => bundles[bundleId]);

    const close = () => {
        dispatch(appActions.changeVisibilityPopup({ name: null }));
    };

    useMaskScrollEffect(itemsRef);

    return (
        <Popup
            onClose={close}
            outsideClick={false}
            renderEscButton={false}
            popupClassName={styles.popupWrapper}
            className={classNames(styles.popup, 'armory__auto--success-purchase-popup')}
            soundset={IPopupSoundset.EXCHANGE}
        >
            <PopupHeader title={t('Спасибо за покупку!')} />
            <PopupBody className={styles.body}>
                <div
                    className={styles.items}
                    ref={(_ref) => {
                        if (_ref) {
                            itemsRef.current = _ref;
                        }
                    }}
                >
                    {sequence.map((bundle, index) => {
                        return (
                            <AnimatedDiv key={`purchase_success_item_${bundle.id}_${bundle.serialIndex}`} index={index}>
                                <SerialItemsSmallCarouselItem className={styles.item} bundle={bundle} showTitle />
                            </AnimatedDiv>
                        );
                    })}
                </div>
            </PopupBody>
            <PopupFooter>
                <Button variant={ButtonVariant.orange} label={t('Закрыть')} onClick={close} className={classNames('armory__auto--success_purchase_button')} />
            </PopupFooter>
        </Popup>
    );
};

export default SerialSequenceSucessPurchase;
