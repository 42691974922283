import * as React from 'react';
import styles from './HoverVideo.scss';
import classNames from 'classnames';
import { getSupportedVideo } from '~/utils/video';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { findParentNode } from '~/utils/dom';

interface IHoverVideo {
    video: IVideo;
    className?: string;
    children: React.ReactNode | React.ReactNode[];
    isDisableAnimation?: boolean;
    delay?: number;
    muted?: boolean;
    parentClassName: string;
    [key: string]: any;
}

const HoverVideo = (props: IHoverVideo) => {
    const { video, isDisableAnimation, children, className, muted } = props;
    const [isVisibleVideo, toggleVisibilityVideo] = React.useState(false);
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const videoUrl = getSupportedVideo(video);
    const isEnabled = !isDisableAnimation && !!videoUrl && !isMobileOrTabletWindow;
    let timer: NodeJS.Timeout;

    const mouseEnter = () => {
        if (isEnabled) {
            if (videoRef.current) {
                const play = () => {
                    videoRef.current.currentTime = 0;
                    toggleVisibilityVideo(true);
                    videoRef.current.play();
                };
                if (!props.delay) {
                    play();
                } else {
                    timer = setTimeout(() => {
                        play();
                        clearTimeout(timer);
                    }, props.delay);
                }
            }
        }
    };

    const mouseLeave = () => {
        clearTimeout(timer);
        if (isEnabled) {
            if (videoRef.current) {
                toggleVisibilityVideo(false);
                videoRef.current.pause();
            }
        }
    };

    const classesVideoWrapper = classNames(styles.videoWrapper, {
        [styles.visible]: isVisibleVideo,
    });

    React.useEffect(() => {
        if (!videoRef.current) {
            return;
        }

        const element = findParentNode(props.parentClassName, videoRef.current);
        if (element) {
            element.addEventListener('mouseenter', mouseEnter);
            element.addEventListener('mouseleave', mouseLeave);
        }

        return () => {
            if (element) {
                element.removeEventListener('mouseenter', mouseEnter);
                element.removeEventListener('mouseleave', mouseLeave);
            }
        };
    }, [videoRef.current]);

    return (
        <div className={classNames(styles.wrapper, className)} {...props}>
            {children}
            {isEnabled && (
                <div className={classesVideoWrapper}>
                    <video src={videoUrl} loop={true} muted={muted === undefined ? true : muted} ref={videoRef} />
                </div>
            )}
        </div>
    );
};

export default HoverVideo;
