import * as React from 'react';
import styles from './RandomBundle.scss';
import classNames from 'classnames';
import OfferMark from '~/components/OfferMark/OfferMark';
import PriceWrapper from '~/components/PriceWrapper/PriceWrapper';
import PurchaseButton from '~/components/Button/PurchaseButton';
import { interpolate, t } from '~/utils/localization';
import Purchased from '~/components/Purchased/Purchased';
import { isFreeBundle } from '~/utils/bundles';
import { getUserId } from '~/utils/settings';
import { localizedDateTime } from '~/utils/utils';

interface IRandomBundlePurchaseWrapper {
    isEnabledFreeOpen: boolean;
    isEnablePurchase: boolean;
    isDisabledPurchasePeriodicBundle: boolean;
    isPeriodicBundle: boolean;
    isAlreadyPurchased: boolean;
    bundle: IBundle;
    purchase: (event: React.MouseEvent) => void;
    hasActiveTransaction: boolean;
}

const RandomBundlePurchaseWrapper = (props: IRandomBundlePurchaseWrapper) => {
    const userId = getUserId();
    const primaryItem = props.bundle.primaryItem;
    const _isFree = isFreeBundle(props.bundle);

    const purchaseClassNames = classNames(styles.purchase, {
        [styles.visible]: props.hasActiveTransaction,
        [styles.purchased]: !props.isEnablePurchase,
        [styles.periodicBundleDisable]: !props.isEnabledFreeOpen && props.isDisabledPurchasePeriodicBundle,
    });

    const buttonsClassNames = classNames(styles.buttons, {
        [styles.freeOpen]: props.isEnabledFreeOpen,
        [styles.visible]: props.hasActiveTransaction,
    });

    const date = localizedDateTime(new Date(props.bundle.promoTimerActiveTill));
    const text = interpolate(t('Доступен до: {date}'), {
        date: date,
    });
    const timeUpText = text + ' ' + t('Получены все доступные наборы');
    const timeUpElement = <span dangerouslySetInnerHTML={{ __html: timeUpText }} />;

    const labelForPurchase = !userId ? t('Открыть') : _isFree ? t('Получить') : t('Купить');

    const hasTimer = props.bundle.promoTimerActiveTill && props.bundle.showCountdown;
    const hasPromoLabel = props.bundle.promoLabel;

    const TimeUpElement = <div className={styles.timeUp}>{timeUpText}</div>;

    return (
        <div className={purchaseClassNames}>
            {props.isEnablePurchase ? (
                <React.Fragment>
                    {!props.bundle.isPurchased && (hasTimer || hasPromoLabel) && (
                        <div className={styles.labels}>
                            {hasPromoLabel && <OfferMark label={props.bundle.promoLabel} className={styles.bundleLabel} />}
                            {hasTimer && <OfferMark expiredTime={props.bundle.promoTimerActiveTill} className={styles.bundleLabel} />}
                        </div>
                    )}
                    <div className={styles.bundleFooter}>
                        {!props.isEnabledFreeOpen && userId && (
                            <div className={styles.priceWrapper}>
                                <PriceWrapper bundle={props.bundle} key={`price_${props.bundle.categories.join('_')}_${primaryItem?.identifier || props.bundle.id}`} useInitialPrice withoutAnimation />
                            </div>
                        )}
                        {!props.isDisabledPurchasePeriodicBundle && (
                            <div className={buttonsClassNames}>
                                {!props.isEnabledFreeOpen || !userId ? (
                                    <PurchaseButton
                                        label={labelForPurchase}
                                        bundle={props.bundle}
                                        onClick={props.purchase}
                                        isForceRenderPurchaseButton
                                        buttonClassName={['armory__auto--category_button_purchase', styles.purchaseButton]}
                                    />
                                ) : props.isEnabledFreeOpen ? (
                                    <PurchaseButton
                                        label={props.isPeriodicBundle && _isFree ? t('Бесплатно') : t('Открыть')}
                                        onClick={props.purchase}
                                        buttonClassName={styles.purchaseButton}
                                        bundle={props.bundle}
                                        isForceRenderPurchaseButton
                                    />
                                ) : null}
                            </div>
                        )}
                    </div>
                </React.Fragment>
            ) : (
                <div className={styles.footer}>{props.isAlreadyPurchased ? <Purchased /> : userId && props.isPeriodicBundle ? TimeUpElement : null}</div>
            )}
        </div>
    );
};

export default React.memo(RandomBundlePurchaseWrapper);
