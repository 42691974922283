import * as React from 'react';
import styles from './Info.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip, { IDefaultTooltipClasses } from '~/components/Tooltip/DefaultTooltip';
import classNames from 'classnames';
import { TooltipTypes } from '~/types/common';

export enum InfoSize {
    DEFAULT = 'default',
    SMALL = 'small',
}

interface IInfo {
    style?: React.CSSProperties;
    color?: string;
    className?: string;
    tooltipClasses?: IDefaultTooltipClasses;
    size?: InfoSize;
    onClick?: () => void;
    tooltip?: {
        title?: string;
        text?: string | (string | React.ReactChild)[];
        type?: TooltipTypes;
        onShow?: DefaultFunction;
    };
}

const Info = (props: IInfo): React.ReactChild | any => {
    let tooltip = null;
    if (props.tooltip) {
        tooltip = <DefaultTooltip text={props.tooltip.text} title={props.tooltip.title} type={props.tooltip.type} classes={props.tooltipClasses} onShow={props.tooltip.onShow} />;
    }

    const classesInfo = classNames(styles.info, styles[props.size]);

    const style = { '--timer-color': props.color } as React.CSSProperties;

    return (
        <DivTooltip tooltipBody={tooltip} className={props.className} onClick={() => props.onClick?.()}>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={classesInfo} style={Object.assign(props.style || {}, style)}>
                <g clipPath="url(#clip0_1363_15162)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.5 17C10.7543 17 12.9163 16.1045 14.5104 14.5104C16.1045 12.9163 17 10.7543 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0C6.24566 0 4.08365 0.895533 2.48959 2.48959C0.895533 4.08365 0 6.24566 0 8.5C0 10.7543 0.895533 12.9163 2.48959 14.5104C4.08365 16.1045 6.24566 17 8.5 17V17ZM8.5 15.22C10.2823 15.22 11.9915 14.512 13.2518 13.2518C14.512 11.9915 15.22 10.2823 15.22 8.5C15.22 6.71775 14.512 5.00849 13.2518 3.74824C11.9915 2.488 10.2823 1.78 8.5 1.78C6.71775 1.78 5.00849 2.488 3.74824 3.74824C2.488 5.00849 1.78 6.71775 1.78 8.5C1.78 10.2823 2.488 11.9915 3.74824 13.2518C5.00849 14.512 6.71775 15.22 8.5 15.22V15.22Z"
                        fill="white"
                    />
                    <path d="M9.49997 13H7.5L7.50009 7H9.49997V13Z" fill="white" />
                    <path
                        d="M7.56439 4.60922C7.51763 4.73248 7.50051 4.99634 7.50051 4.99634C7.49634 5.12804 7.51769 5.25929 7.56331 5.38251C7.60894 5.50574 7.67794 5.61849 7.76635 5.71429C7.94834 5.90476 8.19271 6 8.50047 6C8.8072 6 9.04952 5.90476 9.2264 5.71429C9.31671 5.61954 9.38743 5.50713 9.43433 5.38377C9.48124 5.26041 9.50337 5.12864 9.4994 4.99634C9.504 4.86411 9.48216 4.73231 9.43522 4.60903C9.38827 4.48576 9.31722 4.37359 9.2264 4.27943C9.04543 4.09419 8.80311 4 8.49944 4C8.1968 4 7.95448 4.09314 7.77248 4.27943C7.68195 4.37376 7.61115 4.48597 7.56439 4.60922Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1363_15162">
                        <rect width="17" height="17" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </DivTooltip>
    );
};

export default Info;
